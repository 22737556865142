import React from 'react';
import { FormattedMessage } from 'react-intl';
import { appNotification } from '../App/appNotification';
import { createLocalNotification } from '../App/utils';

const notification = createLocalNotification('SplitReservation', {
    error: (
        <FormattedMessage
            id='front-app/SplitReservation/Error:title'
            defaultMessage='Split reservation Error'
        />
    ),
    warning: (
        <FormattedMessage
            id='front-app/SplitReservation/Warning:title'
            defaultMessage='Split reservation warning'
        />
    ),
    info: (
        <FormattedMessage
            id='front-app/SplitReservation/Info:title'
            defaultMessage='Split reservation Info'
        />
    ),
});

export const splitReservationNotifications = {
    reservationSplitSuccessfully: notification.info((
        <FormattedMessage
            id='front-app/SplitReservation/Info:separatedSuccessfully'
            defaultMessage='Reservation separated successfully'
        />
    )),
    needSelectPassenger: notification.warning((
        <FormattedMessage
            id='front-app/SplitReservation/Info:needSelectPassenger'
            defaultMessage='Please select a passenger.'
        />
    )),
    cantSlitWithOnePassenger: notification.warning((
        <FormattedMessage
            id='front-app/SplitReservation/Info:cantSlitWithOnePassenger'
            defaultMessage="Can't split a reservation with one passenger."
        />
    )),
    cantSplitWithMoreInfants: notification.warning((
        <FormattedMessage
            id='front-app/SplitReservation/Info:cantSplitWithMoreInfants'
            defaultMessage="Can't separate infants from adults."
        />
    )),
    unknownError: (details?: JSX.Element | string) => (
        notification.error(appNotification.texts.unknownError(details))
    ),
};
