import { permissionMaker } from '../../permissionExpression';

const { role, every } = permissionMaker;

const createWithClosedFlight = role('ROLE_RESERVATION_CREATE_CLOSED_FLIGHT');
const updateWithClosedFlight = role('ROLE_RESERVATION_UPDATE_CLOSED_FLIGHT');
const editWithDepartedFlight = role('ROLE_RESERVATION_EDIT_DEPARTED_FLIGHT');
const createAccountFlag = role('ROLE_RESERVATION_CREATE_ACCOUNT');
const createSmsFlag = role('ROLE_RESERVATION_CREATE_SMS_FLAG');
const getFullFlightList = role('ROLE_FLIGHT_LIST');
const getAgentFlightList = role('ROLE_FLIGHT_AGENT_FLIGHT_LIST');
const selectBillingUser = every(
    'ROLE_AGENT_LIST',
    'ROLE_CUSTOMER_LIST',
    'ROLE_USER_READ_ACCOUNT',
);
const selectPassengerUserExtended = role('ROLE_RESERVATION_PASSENGER_LIST_FILTERS');
const checkSamePassengers = role('ROLE_FLIGHT_PASSENGER_LIST');
const hidePriceFlag = role('ROLE_RESERVATION_HIDE_PRICE_FLAG');
const updateFee = role('ROLE_RESERVATION_UPDATE');
const createSpecificPrice = role('ROLE_RESERVATION_INVOICE_SET_SPECIFIC_PRICE');
const createComment = role('ROLE_RESERVATION_CREATE_COMMENT');
const paymentTermsLater = role('ROLE_RESERVATION_INVOICE_SET_PAYMENT_TERMS_LATER');
const paymentTermsNearLater = role('ROLE_RESERVATION_INVOICE_SET_PAYMENT_TERMS_LATER_NEAR_TO_DEPARTURE');
const paymentTermsAirport = role('ROLE_RESERVATION_INVOICE_SET_PAYMENT_TERMS_AIRPORT');
const paymentTermsBill = role('ROLE_RESERVATION_INVOICE_SET_PAYMENT_TERMS_BILL');

export const reservationNewPermissions = {
    createWithClosedFlight,
    updateWithClosedFlight,
    editWithDepartedFlight,
    createAccountFlag,
    createSmsFlag,
    getFullFlightList,
    getAgentFlightList,
    selectBillingUser,
    selectPassengerUserExtended,
    checkSamePassengers,
    hidePriceFlag,
    updateFee,
    createSpecificPrice,
    createComment,
    paymentTermsLater,
    paymentTermsNearLater,
    paymentTermsAirport,
    paymentTermsBill,
};
