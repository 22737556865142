import { PaymentTypeItem } from '@malesia/react-components/dist/src/components/Accounting/NewTransaction/NewTransactionAdvanced';
import { put, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../../../utils/log';
import { appNotification } from '../../../../containers/App/appNotification';
import { getApiErrorMessage } from '../../../../services/ApiClient';
import { paymentFreeService, PaymentFreeTypeListItem } from '../../../../services/PaymentFree/paymentFree.service';
import { newTransactionActions } from '../slice';

const mapApiPaymentTypeListToPaymentTypeList = (v: { items: PaymentFreeTypeListItem[] }) => {
    return v.items.map((item): PaymentTypeItem => ({
        id: item.id,
        name: item.name,
        value: item.code,
    }));
};

function* loadPaymentTypeList() {
    try {
        const res = yield* paymentFreeService.getPaymentFreeTypeList();
        const items = mapApiPaymentTypeListToPaymentTypeList(res);
        yield* put(newTransactionActions.setPaymentTypeList(items));
    }
    catch (error) {
        logError({
            error,
            target: 'AccountingNewTransactionPage.loadPaymentTypeList',
        });
        const message = getApiErrorMessage(error);
        yield* put(appNotification.unknownError(message));
    }
}

export function* loadPaymentTypeListSaga() {
    yield* takeLatest(newTransactionActions.loadPaymentTypeList, loadPaymentTypeList);
}
