import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AllotmentInvoiceEditPopupWithStore } from '../../containers/AllotmentInvoicePopup/AllotmentInvoiceEditPopupWithStore';
import { AllotmentListFilterWithStore } from './components/AllotmentListFilterWithStore';
import { AllotmentListFooterWithStore } from './components/AllotmentListFooterWithStore';
import { AllotmentListWithStore } from './components/AllotmentListWithStore';
import { useInjectAllotmentList } from './store/inject';

export const AllotmentListPage: SFC = () => {
    useInjectAllotmentList();

    return (
        <PageContentBlock>
            <PageTitle
                text={
                    <FormattedMessage
                        id='front-app/AllotmentListPage:Title'
                        defaultMessage='Allotments'
                    />
                }
            />
            <AllotmentListFilterWithStore />
            <AllotmentListWithStore />
            <AllotmentListFooterWithStore />
            <AllotmentInvoiceEditPopupWithStore />
        </PageContentBlock>
    );
};
