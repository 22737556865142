import { AirportEntireList, FlightBulkOperation, FlightListItem, FlightPaginatedList, SortingParams, TariffGroupEntireList } from '@malesia/json-schema';
import { FlightListFilterData } from '@malesia/react-components/dist/src/components/Flight/List/Filter';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../../utils/@reduxjs/toolkit';
import {
    InfoType,
    FlightListPageState,
    ActivateFlightOptions,
    BookOutOptions,
} from './types';

export const initialState: FlightListPageState = {
    filterData: {
        statuses: ['created'],
    },
    table: {
        list: {
            items: [],
            total: 0,
        },
        cancelledList: {
            items: [],
            total: 0,
        },
        selectedRows: [],
        sorting: {
            sortBy: 'departure',
            sortOrder: 'ASC',
        },
        pagination: {
            page: 0,
            pageSize: 15,
        },

        initialized: {
            sorting: false,
        },
        dirty: true,
        loading: true,
    },
    airportList: {
        items: [],
    },
    tariffGroupList: {
        items: [],
    },
    flightInfo: {},
    deleteFlights: {},
};

const flightListPageSlice = createSlice({
    name: 'flightListPage',
    initialState,
    reducers: {
        getAirportList() {},
        getAirportListSuccess(state, action: PayloadAction<AirportEntireList>) {
            state.airportList = action.payload;
        },
        getAirportListError(state) {
        },
        getTariffGroupList(state) {
        },
        getTariffGroupListSuccess(
            state,
            action: PayloadAction<TariffGroupEntireList>,
        ) {
            state.tariffGroupList = action.payload;
        },
        getTariffGroupListError(state) {
        },
        setFilterData(state, action: PayloadAction<FlightListFilterData>) {
            state.filterData = action.payload;
            state.table.pagination.page = initialState.table.pagination.page;
            state.table.dirty = true;
        },
        updateFilterData(state, action: PayloadAction<FlightListFilterData>) {
            state.filterData = { ...state.filterData, ...action.payload };
            state.table.pagination.page = initialState.table.pagination.page;
            state.table.dirty = true;
        },
        resetFilterData(state) {
            state.filterData = initialState.filterData;
            state.table.pagination.page = initialState.table.pagination.page;
            state.table.sorting = initialState.table.sorting;
            state.table.dirty = true;
        },
        setPage(state, action: PayloadAction<number>) {
            state.table.pagination.page = action.payload;
            state.table.dirty = true;
        },
        setPageSize(state, action: PayloadAction<number>) {
            state.table.pagination.pageSize = action.payload;
            state.table.pagination.page = initialState.table.pagination.page;
            state.table.dirty = true;
        },
        setSorting(state, action: PayloadAction<SortingParams>) {
            state.table.sorting = action.payload;
            state.table.dirty = true;
            state.table.initialized.sorting = true;
        },
        setSelectedRows(state, action) {
            const oldSelectedRows = state.table.selectedRows;
            const newSelectedRows = action.payload;
            if (
                JSON.stringify(oldSelectedRows.map(item => item.id))
                !== JSON.stringify(newSelectedRows.map(item => item.id))
            ) {
                state.table.selectedRows = newSelectedRows;
            }
        },
        getFlightList(state) {
            state.table.loading = true;
        },
        getFlightListSuccess(
            state,
            action: PayloadAction<{ flights: FlightPaginatedList, cancelledFlights: FlightPaginatedList }>,
        ) {
            state.table.list = action.payload.flights;
            state.table.cancelledList = action.payload.cancelledFlights;
            state.table.loading = false;
            state.table.dirty = false;
        },
        getFlightListError(state) {
            state.table.loading = false;
        },
        openDeleteFlights(state) {
            state.deleteFlights.open = true;
        },
        closeDeleteFlights(state) {
            state.deleteFlights.open = false;
        },
        confirmDeleteFlights(state) {
            state.deleteFlights.open = false;
        },
        confirmDeleteFlightsSuccess(state) {
            state.table.selectedRows = [];
            state.table.dirty = true;
        },
        confirmDeleteFlightsError(state) {
        },
        activateFlight(state, action: PayloadAction<ActivateFlightOptions>) {
        },
        activateFlightsSuccess(state) {
            state.table.dirty = true;
        },
        activateFlightsError(state) {
        },
        unassignFlights(state, action: PayloadAction<FlightBulkOperation>) {
        },
        unassignFlightsSuccess(state) {
            state.table.dirty = true;
        },
        unassignFlightsError(state) {
        },
        deleteFlightsSequences(state, action: PayloadAction<FlightBulkOperation>) {
        },
        deleteFlightsSequencesSuccess(state) {
            state.table.dirty = true;
        },
        deleteFlightsSequencesError(state) {
        },
        bookOutFlight(state, action: PayloadAction<BookOutOptions>) {
        },
        bookOutFlightSuccess(state) {
            state.table.dirty = true;
        },
        bookOutFlightError(state) {
        },
        setFlightCancelled(state, action: PayloadAction<number>) {},
        setFlightCancelledSuccess(state) {
            state.table.dirty = true;
        },
        setFlightCancelledError(state) {},
        openFlightInfo(state) {
            state.flightInfo.open = true;
        },
        closeFlightInfo(state) {
            state.flightInfo = initialState.flightInfo;
        },
        getFlightInfo(state, action: PayloadAction<FlightListItem>) {
            state.flightInfo.loading = true;
        },
        getFlightInfoSuccess(state, action: PayloadAction<InfoType>) {
            state.flightInfo.details = action.payload;
            state.flightInfo.loading = false;
        },
        getFlightInfoError(state) {
            state.flightInfo = initialState.flightInfo;
        },
        downloadTurnoverList(state) {
            state.table.loading = true;
        },
        downloadTurnoverListSuccess(state) {
            state.table.loading = false;
        },
        downloadTurnoverListError(state) {
            state.table.loading = false;
        },
        downloadPassengerReport(state) {
            state.table.loading = true;
        },
        downloadPassengerReportSuccess(state) {
            state.table.loading = false;
        },
        downloadPassengerReportError(state) {
            state.table.loading = false;
        },
        unmount(state) {
            return {
                ...initialState,
                filterData: state.filterData,
                table: {
                    ...initialState.table,
                    pagination: state.table.pagination,
                    sorting: state.table.sorting,
                },
            };
        },
    },
});

export const { actions, reducer, name: sliceKey } = flightListPageSlice;
