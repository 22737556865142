import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSplitReservationState } from './selectors';
import { splitReservationActions } from './slice';
import { SplitPassengerPopup, SplitPassengerPopupProps } from './SplitPassengerPopup';
import { validateSplitReservation } from './utils';

export type SplitPassengerPopupWithStateProps = {
    refreshList: () => void,
};
export const SplitPassengerPopupWithState: SFC<SplitPassengerPopupWithStateProps> = (props) => {
    const { refreshList } = props;
    const splitReservationState = useSelector(selectSplitReservationState);
    const dispatch = useDispatch();

    const splitPassengerPopupProps: SplitPassengerPopupProps = {
        isOpen: splitReservationState.isOpen,
        isLoading: splitReservationState.isLoading,
        defaultPassengerId: splitReservationState.defaultPassengerId,
        passengers: splitReservationState.passengers,
        confirm: (passengerId) => {
            const { passengers } = splitReservationState;
            const error = validateSplitReservation(passengers, passengerId);
            if (error) {
                dispatch(error);
                return;
            }
            dispatch(splitReservationActions.splitReservation({
                passengerId,
                callback: refreshList,
            }));
        },
        close: () => {
            dispatch(splitReservationActions.closePopup());
        },
    };
    return (
        <SplitPassengerPopup {...splitPassengerPopupProps} />
    );
};
