import { useInjectReducer, useInjectSaga } from '../../../../utils/redux-injectors';
import { useInjectCashbackPdf } from '../../../containers/CashbackPdf/inject';
import { downloadOverviewPdfSaga } from './downloadOverviewPdf.saga';
import { loadTableDataSaga } from './loadTableData.saga';
import { loadInitialDataSaga, getAccountListSaga, getRelatedAccountListSaga, getCategoryListSaga } from './saga';
import { balanceLogActions, balanceLogReducer, balanceLogSliceKey } from './slice';

export const useInjectBalanceLog = () => {
    useInjectCashbackPdf();
    useInjectReducer({
        actions: balanceLogActions,
        key: balanceLogSliceKey,
        reducer: balanceLogReducer,
    });

    useInjectSaga({
        key: `${balanceLogSliceKey}/loadInitialData`,
        saga: loadInitialDataSaga,
    });
    useInjectSaga({
        key: `${balanceLogSliceKey}/downloadOverviewPDF`,
        saga: downloadOverviewPdfSaga,
    });
    useInjectSaga({
        key: `${balanceLogSliceKey}/loadTableDataSaga`,
        saga: loadTableDataSaga,
    });
    useInjectSaga({
        key: `${balanceLogSliceKey}/getAccountListSaga`,
        saga: getAccountListSaga,
    });
    useInjectSaga({
        key: `${balanceLogSliceKey}/getRelatedAccountListSaga`,
        saga: getRelatedAccountListSaga,
    });
    useInjectSaga({
        key: `${balanceLogSliceKey}/getCategoryListSaga`,
        saga: getCategoryListSaga,
    });
};
