import { Option, OptionTypeEntireList } from '@malesia/json-schema';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { OptionEditPageState, UpdateOptionOptions } from './types';

export const initialState: OptionEditPageState = {
    optionData: undefined,
    optionTypeData: {
        items: [],
    },
    loadingOption: false,
    loadingTypes: false,
};

const optionEditPageSlice = createSlice({
    name: 'optionEditPage',
    initialState,
    reducers: {
        getOptionTypeList(state) {
            state.loadingTypes = true;
        },
        getOptionTypeListSuccess(
            state,
            action: PayloadAction<OptionTypeEntireList>,
        ) {
            state.optionTypeData = {
                items: action.payload.items.filter(item => ['general', 'travel-insurance'].includes(item.name)),
            };
            state.loadingTypes = false;
        },
        getOptionTypeListError(state) {
            state.loadingTypes = false;
        },
        getOptionData(state, action: PayloadAction<string>) {
            state.loadingOption = true;
        },
        getOptionDataSuccess(state, action: PayloadAction<Option>) {
            state.optionData = action.payload;
            state.loadingOption = false;
        },
        getOptionDataError(state) {
            state.loadingOption = false;
        },
        updateOptionData(state, action: PayloadAction<UpdateOptionOptions>) {
            state.loadingOption = true;
        },
        updateOptionDataSuccess(state) {
            state.loadingOption = false;
        },
        updateOptionDataError(state) {
            state.loadingOption = false;
        },
    },
});

export const { actions, reducer, name: sliceKey } = optionEditPageSlice;
