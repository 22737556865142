import { getImageUrl } from '../getImageUrl';
import { getTopImage } from '../getTopImage';
import { CarPageContentDocument, CarPageContentQuery } from '../gql-types';
import { gqlClient } from '../gqlClient';

export async function loadCarPageContent(locale: string) {
    const { data } = await gqlClient.query<CarPageContentQuery>(
        CarPageContentDocument,
        { lang: locale },
    ).toPromise();
    return {
        topImage: getTopImage(data?.car_page?.top_image),
        description: data?.car_page?.translations?.[0]?.description ?? '',
        cards: data?.car_page?.cards?.map(card => ({
            image: getImageUrl(card?.image?.id),
            link: card?.link ?? '',
        })) ?? [],
    };
}
