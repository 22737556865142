import {
    AircraftSection,
    AircraftTemplate,
    ApiCallFlightFilters,
    Flight,
    FlightPaginatedList,
    PublicFlightListItem,
    PublicFlightPaginatedList,
} from '@malesia/json-schema';
import { AircraftSeatsPlanSection } from '@malesia/react-components/dist/src/components/AircraftSeatsPlan';
import { PassengersAges } from '@malesia/react-components/dist/src/components/Reservation/reservation-types';
import { tariffGroupIdToCode } from '@malesia/react-components/dist/src/components/TariffsTable/tariffGroupMapper';
import { AgeType } from '@malesia/react-components/dist/src/utils/ageType';
import { DATE_OUTPUT_FORMAT } from '@malesia/react-components/dist/src/utils/dateTimeFormat';
import { getObjectEntries } from '@malesia/react-components/dist/src/utils/object';
import * as queryString from 'query-string';
import { call } from 'typed-redux-saga';
import { createDateRange, weekRange } from '../../utils/reservation/flightsTabs';
import { SeatMap } from '../../utils/reservation/types';
import { flightListPermissions } from '../permissions/adminPages/flight/flightList';
import { selectUserPermissions } from '../permissions/selectUserPermissions';
import { apiClientRequest } from './ApiClient';

export type FlightListFilter = {
    origin: string,
    destination: string,
    departureFrom: string,
    departureTo: string,
    passengersAgeConfig?: string,
    sortBy: string,
    page?: number,
    pageSize?: number,
};
export type PassengerConfig = {
    ageType: AgeType,
};
export type GetFlightsOnWeekArgs = {
    departureDate: string,
    fromAirportCode: string,
    toAirportCode: string,
    passengersAges: PassengersAges,
};

const createPassengersConfig = (args: GetFlightsOnWeekArgs): PassengerConfig[] | undefined => {
    if (!args.passengersAges) return undefined;
    const result = getObjectEntries(args.passengersAges).reduce<PassengerConfig[]>((acc, [ageType, count]) => {
        const ageTypeList = Array.from({ length: count }).map<PassengerConfig>(() => ({ ageType }));
        return acc.concat(ageTypeList);
    }, []);
    return result;
};
const createFlightsRequestData = (args: GetFlightsOnWeekArgs) => {
    const { departureDate, fromAirportCode, toAirportCode } = args;
    const [from, to] = createDateRange(departureDate, weekRange);
    const passengersAgeConfigObj = createPassengersConfig(args);
    const passengersAgeConfig = passengersAgeConfigObj ? JSON.stringify(passengersAgeConfigObj) : undefined;

    const filter: FlightListFilter = {
        origin: fromAirportCode,
        destination: toAirportCode,
        departureFrom: from.format(DATE_OUTPUT_FORMAT),
        departureTo: to.format(DATE_OUTPUT_FORMAT),
        sortBy: 'departure',
        pageSize: 100, // temporary fix. TODO need add unlimited page size or do several requests if necessary
        passengersAgeConfig,
    };
    return filter;
};

export function* getApiPublicFlightsOnWeek(args: GetFlightsOnWeekArgs) {
    const query = createFlightsRequestData(args);

    const requestData = {
        requestId: 'publicFlightList',
        query,
    };
    const response: PublicFlightPaginatedList = yield* call(apiClientRequest, requestData);
    return response.items;
}

export function* getApiAgentFlightsOnWeek(args: GetFlightsOnWeekArgs) {
    const query = createFlightsRequestData(args);

    const requestData = {
        requestId: 'agentFlightList',
        query,
    };
    const response: PublicFlightPaginatedList = yield* call(apiClientRequest, requestData);
    return response.items;
}

export function* getApiFullFlightsOnWeek(args: GetFlightsOnWeekArgs) {
    const query = createFlightsRequestData(args);

    const requestData = {
        requestId: 'flightList',
        query,
    };
    const response: FlightPaginatedList = yield* call(apiClientRequest, requestData);
    return response.items;
}

const mapApiAircraftTemplateToSeatMap = (template: AircraftTemplate): SeatMap => ({
    sections: (template.sections ?? []).map((section: AircraftSection): AircraftSeatsPlanSection => ({
        rowLabels: section.rowLabels,
        columnLabels: section.columnLabels,
        seats: section.seats,
        prices: section.prices ?? [],
        rowsWithExitDoors: section.rowsWithExitDoors ?? [],
        tariffGroupCode: tariffGroupIdToCode[section.tariffGroup.id!],
        label: section.label,
    })),
});
export type GetApiFlightAircraftPassengersSeatsArgs = {
    flight?: PublicFlightListItem | Flight,
    tariffId?: number,
    reservationId?: number,
    reservationPassengerId?: number,
};
export function* getApiFlightAircraftPassengersSeats(args: GetApiFlightAircraftPassengersSeatsArgs) {
    const { flight, tariffId, reservationId, reservationPassengerId } = args;

    if (!flight?.aircraftTemplate?.id) return undefined;

    const response: AircraftTemplate = yield* call(apiClientRequest, {
        requestId: 'publicReservationPassengerSeatByFlight',
        uriParams: { id: flight.id! },
        query: {
            ...tariffId ? { tariffId } : {},
            ...reservationId ? { reservationId } : {},
            ...reservationPassengerId ? { reservationPassengerId } : {},
        },
    });
    return mapApiAircraftTemplateToSeatMap(response);
}

export function* getApiFlight(flightId: number | string) {
    const result: Flight = yield* call(apiClientRequest, {
        requestId: 'flightData',
        uriParams: { id: flightId },
    });
    return result;
}

export function* getApiFlightList(query: ApiCallFlightFilters) {
    const result: FlightPaginatedList = yield* call(apiClientRequest, {
        requestId: 'flightList',
        query: queryString.stringify(query, {
            skipNull: true, sort: false,
        }),
    });
    return result;
}

export function* getApiAgentFlightList(query: ApiCallFlightFilters) {
    const result: FlightPaginatedList = yield* call(apiClientRequest, {
        requestId: 'agentFlightList',
        query: queryString.stringify(query, {
            skipNull: true, sort: false,
        }),
    });
    return result;
}

export function* getApiPermittedFlightList(query: ApiCallFlightFilters) {
    const userPermissions = yield* selectUserPermissions();

    if (userPermissions.has(flightListPermissions.getFullList)) {
        return yield* getApiFlightList(query);
    }
    if (userPermissions.has(flightListPermissions.getAgentList)) {
        return yield* getApiAgentFlightList(query);
    }
    throw new Error('Can not get allotment list without permissions');
}
