import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { select } from 'typed-redux-saga';
import { selectUserPermissions } from '../../../permissions/selectUserPermissions';
import { useUserPermissions } from '../../../permissions/useUserPermissions';
import { selectMode, selectSelectedFlightsStatus } from '../store/selectors';
import { createEditReservationAllowed } from './edit';
import { createNewReservationAllowed } from './new';

export type ReservationAllowed = {
    travel: {
        pastDays: boolean,
        outbound: boolean,
        form: boolean,
    },
    flight: {
        closed: boolean,
        departed: boolean,
        priceWithSoldOut: boolean,
        negativeSeats: boolean,
    },
    passenger: {
        edit: boolean,
    },
    seat: {
        input: boolean,
        outbound: boolean,
        ['return']: boolean,
    },
    payment: {
        specificPrice: {
            changeable: boolean,
            visible: boolean,
        },
        hidePrice: {
            changeable: boolean,
            visible: boolean,
        },
        pay: {
            later: {
                changeable: boolean,
                visible: boolean,
            },
        },
    },
};

export const useReservationAllowed = () => {
    const userPermissions = useUserPermissions();
    const selectedFlightsStatus = useSelector(selectSelectedFlightsStatus);
    const mode = useSelector(selectMode);

    const newAllowed = useMemo(() => (
        createNewReservationAllowed(userPermissions, selectedFlightsStatus)
    ), [userPermissions, selectedFlightsStatus]);

    const editAllowed = useMemo(() => (
        createEditReservationAllowed(userPermissions, selectedFlightsStatus)
    ), [userPermissions, selectedFlightsStatus]);

    return mode === 'new' ? newAllowed : editAllowed;
};

export function* selectReservationAllowed() {
    const userPermissions = yield* selectUserPermissions();
    const selectedFlightsStatus = yield* select(selectSelectedFlightsStatus);
    const mode = yield* select(selectMode);
    const newAllowed = createNewReservationAllowed(userPermissions, selectedFlightsStatus);
    const editAllowed = createEditReservationAllowed(userPermissions, selectedFlightsStatus);
    return mode === 'new' ? newAllowed : editAllowed;
}
