import { call, put, select, takeLatest, throttle } from 'typed-redux-saga';
import { logError } from '../../../../utils/log';
import { getApiErrorMessage } from '../../../services/ApiClient';
import { getApiOwnerList } from '../../../services/owner';
import { appNotification } from '../../App/appNotification';
import { selectReservationOwnerList, selectSelectedReservationOwner } from '../selectors';
import { auxiliaryActions } from '../slice';

function* getReservationOwnerList(action: ReturnType<typeof auxiliaryActions.getReservationOwnerList>) {
    try {
        const query = action.payload;
        const result = yield* getApiOwnerList({ query });
        yield* put(auxiliaryActions.getReservationOwnerListSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'AuxiliaryData.getReservationOwnerList',
        });
        const message = getApiErrorMessage(error);
        yield* put(appNotification.unknownError(message));
        yield* put(auxiliaryActions.getReservationOwnerListError());
    }
}

export function* getReservationOwnerListSaga() {
    yield* takeLatest(auxiliaryActions.getReservationOwnerList, getReservationOwnerList);

    function* request(action: ReturnType<typeof auxiliaryActions.searchReservationOwnerList>) {
        yield* put(auxiliaryActions.getReservationOwnerList(action.payload));
    }
    yield* throttle(1000, auxiliaryActions.searchReservationOwnerList, request);
}

function* selectReservationOwner(action: ReturnType<typeof auxiliaryActions.selectReservationOwner>) {
    try {
        const userId = action.payload;

        const selected = yield* select(selectSelectedReservationOwner);
        if (selected?.id === userId) {
            yield* put(auxiliaryActions.selectReservationOwnerSuccess(selected));
            return;
        }

        if (!userId) {
            yield* put(auxiliaryActions.selectReservationOwnerSuccess(undefined));
            return;
        }

        const list = yield* select(selectReservationOwnerList);
        const item = list.find(x => x.id === userId);
        if (item) {
            yield* put(auxiliaryActions.selectReservationOwnerSuccess(item));
            return;
        }
        const items = yield* call(getApiOwnerList, { query: '', userId });
        const resultItem = items.items[0] ?? undefined;
        if (resultItem) {
            yield* put(auxiliaryActions.selectReservationOwnerSuccess(resultItem));
            return;
        }
        logError({
            error: new Error('Not found owner'),
            target: 'AuxiliaryData.selectReservationOwner.notFound',
        });
        yield* put(appNotification.notFoundOwner);
        yield* put(auxiliaryActions.selectReservationOwnerError());
    }
    catch (error) {
        logError({
            error,
            target: 'AuxiliaryData.selectReservationOwner',
        });
        const message = getApiErrorMessage(error);
        yield* put(appNotification.unknownError(message));
        yield* put(auxiliaryActions.selectReservationOwnerError());
    }
}

export function* selectReservationOwnerSaga() {
    yield* takeLatest(auxiliaryActions.selectReservationOwner, selectReservationOwner);
}
