import { validateMoneyRound } from '@malesia/react-components/dist/src/utils/roundMoney';
import { ErrorObject } from 'ajv';
import { CustomValidator } from './getValidationFunction';
import { getValueFromPath } from './getValueFromPath';

function validateDataByPath<T>(data: Partial<T>, path: string) {
    const value = getValueFromPath(data, path);
    if (value === undefined) return [];
    const isValid = validateMoneyRound(value);
    if (!isValid) {
        const error: ErrorObject = {
            // keyword is messageId - translations come from  the map src/components/ValidationErrors/index.tsx/messageById
            keyword: 'custom/rounded',
            params: {},
            schema: 'custom/rounded',
            schemaPath: 'custom/rounded',
            data,
            instancePath: path,
            message: 'should be rounded',
        };
        return [
            error,
        ];
    }
    else {
        return [];
    }
}
export function createCustomRoundValidator<T>(instancePath: string | string[]) {
    const customValidator: CustomValidator<Partial<T>> = (data) => {
        return Array.isArray(instancePath)
            ? instancePath.map(path => validateDataByPath(data, path)).flat()
            : validateDataByPath(data, instancePath).flat();
    };

    return customValidator;
}
