import { roundMoney } from '@malesia/react-components/dist/src/utils/roundMoney';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.allotmentPaymentPage || initialState;

export const selectAllotmentInvoiceLoading = createSelector(
    [selectDomain],
    root => root.allotmentInvoice.loading,
);

export const selectAllotmentInvoice = createSelector(
    [selectDomain],
    root => root.allotmentInvoice.data,
);

export const selectIsLoadingCreatePayment = createSelector(
    [selectDomain],
    root => root.payment.loading,
);

export const selectPaymentData = createSelector(
    [selectDomain],
    (root) => root.payment.data,
);

export const selectCashAccounts = createSelector(
    [selectDomain],
    root => root.cashAccounts.items,
);

export const selectTransferAccounts = createSelector(
    [selectDomain],
    root => root.transferAccounts.items,
);

export const selectPriceToPay = createSelector(
    [selectAllotmentInvoice],
    (allotmentInvoice) => {
        return roundMoney((allotmentInvoice?.totalWithDiscount ?? 0) - (allotmentInvoice?.totalPaid ?? 0));
    },
);

export const selectTransferTotal = createSelector(
    [selectPriceToPay, selectPaymentData],
    (priceToPay, data) => data.partialPayment ?? priceToPay,
);
