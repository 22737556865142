import { LocationDescriptor } from 'history';
import React, { ReactElement } from 'react';
import {
    matchPath,
    Redirect,
    Route,
    RouteProps,
    useLocation,
} from 'react-router-dom';
import { getAdminRoutePermission } from '../../permissions/adminRoutes';
import { useUserPermissions } from '../../permissions/useUserPermissions';
import { AdminRoutePath, allAdminRoutePaths } from '../Routes/allAdminRoutePaths';
import { allPublicRoutePaths } from '../Routes/allPublicRoutePaths';
import { useUser } from 'app/hooks/useUser';

const getCurrentAdminRouteName = (currentPath?: string): AdminRoutePath | undefined => {
    if (!currentPath) return undefined;
    return Object.values(allAdminRoutePaths).find(pattern => {
        const match = matchPath(currentPath, pattern);
        return match?.isExact;
    });
};

export function ProtectedRoute(props: RouteProps): ReactElement {
    const { userIsLoggedIn } = useUser();
    const currentPath = props.location?.pathname;
    const isDenyPage = allPublicRoutePaths.deny === currentPath;
    const userPermissions = useUserPermissions();
    const hasRoutePermissions = () => {
        if (!userPermissions.isAdminSiteAccess) return false;
        const linkRoute = getCurrentAdminRouteName(currentPath);
        const permission = getAdminRoutePermission(() => linkRoute);
        const result = userPermissions.has(permission);
        return result;
    };
    const isAccessible = isDenyPage || hasRoutePermissions();
    const currentLocation = useLocation();
    const pathForRedirect = userIsLoggedIn ? allPublicRoutePaths.deny : allPublicRoutePaths.login;
    const redirectTo: LocationDescriptor = {
        pathname: pathForRedirect,
        state: {
            from: currentLocation,
        },
    };
    if (!isAccessible && pathForRedirect === allPublicRoutePaths.deny) {
        console.warn(`You do not have enough permissions for this page: ${currentPath}. Schema: ${getCurrentAdminRouteName(currentPath)}`);
    }

    return (
        isAccessible ? <Route {...props} /> : <Redirect to={redirectTo} />
    );
}
