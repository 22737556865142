import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { EventLogFilterWithStore } from './components/EventLogFilterWithStore';
import { EventLogFooterWithStore } from './components/EventLogFooterWithStore';
import { EventLogWithStore } from './components/EventLogWithStore';
import { useInjectEventLog } from './store/inject';

export const EventLogPage: SFC = () => {
    useInjectEventLog();

    return (
        <PageContentBlock>
            <PageTitle
                text={
                    <FormattedMessage
                        id='front-app/EntityLogListPage:Title'
                        defaultMessage='Event log'
                    />
                }
            />
            <EventLogFilterWithStore />
            <EventLogWithStore />
            <EventLogFooterWithStore />
        </PageContentBlock>
    );
};
