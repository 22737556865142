import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { openPopupSaga, splitReservationSaga } from './saga';
import { splitReservationActions, splitReservationReducer, splitReservationSliceKey } from './slice';

export const useInjectSplitReservation = () => {
    useInjectReducer({
        actions: splitReservationActions,
        key: splitReservationSliceKey,
        reducer: splitReservationReducer,
    });
    useInjectSaga({
        key: `${splitReservationSliceKey}_openPopupSaga`,
        saga: openPopupSaga,
    });
    useInjectSaga({
        key: `${splitReservationSliceKey}_splitReservationSaga`,
        saga: splitReservationSaga,
    });
};
