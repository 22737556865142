import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { OptionListFooterWithStore } from './components/OptionListFooterWithStore';
import { OptionListHeaderWithStore } from './components/OptionListHeaderWithStore';
import { OptionListWithStore } from './components/OptionListWithStore';
import { getOptionListSaga } from './saga';
import { actions, reducer, sliceKey } from './slice';

export const OptionListPage: SFC = () => {
    useInjectReducer({ actions, key: sliceKey, reducer });
    useInjectSaga({ key: sliceKey, saga: getOptionListSaga });

    return (
        <PageContentBlock>
            <OptionListHeaderWithStore />
            <OptionListWithStore />
            <OptionListFooterWithStore />
        </PageContentBlock>
    );
};
