import { NewTransactionAdvanced, NewTransactionAdvancedFormType, NewTransactionAdvancedProps } from '@malesia/react-components/dist/src/components/Accounting/NewTransaction/NewTransactionAdvanced';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { GetAccountFreeListParams } from '../../../services/account';
import { newTransactionActions } from '../store/slice';
import { useTabInfo } from '../useTabInfo';

const debitRequest: GetAccountFreeListParams = {
};
const creditRequest: GetAccountFreeListParams = {
};

export const AdvancedTab: SFC = () => {
    const dispatch = useDispatch();

    const { validateForm, config } = useTabInfo({
        debitRequest,
        creditRequest,
    });

    const submit = useCallback((data: NewTransactionAdvancedFormType) => {
        dispatch(newTransactionActions.submitAdvancedForm(data));
    }, [dispatch]);

    const newTransactionPresetProps: NewTransactionAdvancedProps = {
        config,
        validateForm,
        submit,
    };
    return (
        <NewTransactionAdvanced {...newTransactionPresetProps} />
    );
};
