import { permissionMaker } from '../../permissionExpression';

const { role, some } = permissionMaker;

const getList = role('ROLE_ALLOTMENT_LIST');
const getAgentList = role('ROLE_ALLOTMENT_AGENT_ALLOTMENT_LIST');
const create = role('ROLE_ALLOTMENT_INVOICE_EDIT');
const pdf = some('ROLE_ALLOTMENT_INVOICE_READ', 'ROLE_ALLOTMENT_AGENT_ALLOTMENT_LIST');

export const allotmentListPermissions = {
    getList,
    getAgentList,
    create,
    pdf,
};
