import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../utils/log';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { mapAgentToPostRequest } from '../../services/agent';
import { apiClientRequest, getApiErrorMessage } from '../../services/ApiClient';
import { agentNotifications } from './notification';
import { actions } from './slice';

export function* createAgent(action: ReturnType<typeof actions.createAgent>) {
    try {
        const requestPayload = mapAgentToPostRequest(action.payload);

        yield* call(apiClientRequest, {
            requestId: 'agentCreate',
            requestPayload,
        });
        yield* put(actions.createAgentSuccess());
        yield* put(push(allAdminRoutePaths.userAgentList));
        yield* put(agentNotifications.createdSuccessfully);
    }
    catch (error) {
        logError({
            error,
            target: 'AgentNewPage.createAgent',
        });
        yield* put(actions.createAgentError());
        const message = getApiErrorMessage(error);
        yield* put(agentNotifications.unknownError(message));
    }
}

export function* createAgentSaga() {
    yield* takeLatest(actions.createAgent, createAgent);
}
