import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createLocalNotification } from './utils';

const titles = {
    error: (
        <FormattedMessage
            id='front-app/appNotification/titles:error'
            defaultMessage='Malesia Error'
        />
    ),
    warning: (
        <FormattedMessage
            id='front-app/appNotification/titles:warning'
            defaultMessage='Malesia Warning'
        />
    ),
    info: (
        <FormattedMessage
            id='front-app/appNotification/titles:info'
            defaultMessage='Malesia Info'
        />
    ),
};
const texts = {
    createdSuccessfully: (
        <FormattedMessage
            id='front-app/appNotification/texts:createdSuccessfully'
            defaultMessage='Created successfully'
        />
    ),
    updatedSuccessfully: (
        <FormattedMessage
            id='front-app/appNotification/texts:updatedSuccessfully'
            defaultMessage='Updated successfully'
        />
    ),
    checkFields: (
        <FormattedMessage
            id='front-app/appNotification/texts:checkFields'
            defaultMessage='There was an issue. Please make sure all input fields are filled out correctly.'
        />
    ),
    incorrectLogin: (
        <FormattedMessage
            id='front-app/appNotification/texts:incorrectLogin'
            defaultMessage='Please enter a correct email and password'
        />
    ),
    expiredToken: (
        <FormattedMessage
            id='front-app/appNotification/texts:expiredToken'
            defaultMessage='The session has expired. Login again'
        />
    ),
    unknownError: (details?: JSX.Element | string) => (
        <FormattedMessage
            id='front-app/appNotification/texts:unknownError'
            defaultMessage='Unknown Error. {details}'
            values={{
                details,
            }}
        />
    ),
};
const notification = createLocalNotification('App', {
    error: titles.error,
    warning: titles.warning,
    info: titles.info,
});
export const appNotification = {
    titles,
    texts,
    incorrectLogin: notification.error(texts.incorrectLogin),
    expiredToken: notification.warning(texts.expiredToken),
    notFoundOwner: notification.error(
        <FormattedMessage
            id='front-app/ReservationsListPage/confirmSendReservationListNotification:notFoundOwner'
            defaultMessage='Not found owner'
        />,
    ),
    notFoundReservator: notification.error(
        <FormattedMessage
            id='front-app/ReservationsListPage/confirmSendReservationListNotification:notFoundReservator'
            defaultMessage='Not found reservator'
        />,
    ),
    unknownError: (details?: JSX.Element | string) => notification.error(texts.unknownError(details)),
};
