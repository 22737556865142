import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.optionEditPage || initialState;

export const selectOptionTypeData = createSelector(
    [selectDomain],
    state => state.optionTypeData,
);

export const selectOptionData = createSelector(
    [selectDomain],
    state => state.optionData,
);

export const selectLoadingOption = createSelector(
    [selectDomain],
    state => state.loadingOption,
);
export const selectLoadingTypes = createSelector(
    [selectDomain],
    state => state.loadingTypes,
);
