import { AircraftSeatsConfiguration } from '@malesia/react-components/dist/src/components/Flight/AircraftSeatsConfiguration';
import { Actions } from '@malesia/react-components/dist/src/components/Flight/AircraftSeatsConfiguration/Actions';
import { PopupConfirm } from '@malesia/react-components/dist/src/components/Popup/Confirm';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useAircraftActionConfig } from './config';
import { useInjectAircraftTemplatePopup } from './inject';
import {
    selectAircraftTemplatePopupIsOpened,
    selectAircraftTemplatePopupAircraftTemplateSections,
    selectAircraftTemplatePopupSelectedSeats,
    selectAircraftTemplatePopupActionData,
} from './selectors';
import { aircraftTemplatePopupActions as actions } from './slice';

export function FlightAircraftTemplatePopup(): ReactElement {
    useInjectAircraftTemplatePopup();

    const dispatch = useDispatch();

    const isAircraftTemplatePopupOpened = useSelector(selectAircraftTemplatePopupIsOpened);
    const aircraftTemplateSections = useSelector(selectAircraftTemplatePopupAircraftTemplateSections);
    const selectedSeats = useSelector(selectAircraftTemplatePopupSelectedSeats);
    const actionData = useSelector(selectAircraftTemplatePopupActionData);

    const onSeatClick = (seatLabel: string) => {
        dispatch(actions.clickSeat(seatLabel));
    };

    const onSeatDoubleClick = (seatLabel: string) => {
        dispatch(actions.doubleClickSeat(seatLabel));
    };

    const actionConfig = useAircraftActionConfig(selectedSeats);

    const onActionChange = (data) => {
        dispatch(actions.setSeatAction(data));
    };

    const onActionSubmit = () => {
        dispatch(actions.applySeatAction());
    };

    return (
        <PopupConfirm
            isOpened={isAircraftTemplatePopupOpened}
            onClose={() => dispatch(actions.closeModal())}
            onConfirm={() => dispatch(actions.confirmModal())}
            confirmMessage=''
            confirmButtonText={
                <FormattedMessage
                    id='front-app/FlightEditPage:ConfirmSeatsConfiguration'
                    defaultMessage='Save configuration'
                />
            }
            modificators={['lg']}
        >
            {aircraftTemplateSections?.length && (
                <AircraftSeatsConfiguration
                    sections={aircraftTemplateSections}
                    onSeatClick={onSeatClick}
                    onSeatDoubleClick={onSeatDoubleClick}
                    selectedSeats={selectedSeats}
                    actionsComponent={
                        <Actions
                            data={actionData}
                            config={actionConfig}
                            onChange={onActionChange}
                            onSubmit={onActionSubmit}
                        />
                    }
                />
            )}
        </PopupConfirm>
    );
}
