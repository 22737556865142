import { FlightListInfo } from '@malesia/react-components/dist/src/components/Flight/List/Info';
import { LoadingPage } from '@malesia/react-components/dist/src/components/LoadingOverlay/Page';
import { PopupWrapper } from '@malesia/react-components/dist/src/components/Popup/PopupWrapper';
import { mapTariffGroupIdToCode, TariffGroupCode } from '@malesia/react-components/dist/src/components/TariffsTable/tariffGroupMapper';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flightListPermissions } from '../../../permissions/adminPages/flight/flightList';
import { useUserPermissions } from '../../../permissions/useUserPermissions';
import {
    selectTariffGroupList,
    selectInfoPopupOpen,
    selectInfoPopupDetails,
    selectInfoPopupLoading,
} from '../store/selectors';
import { actions } from '../store/slice';

export const FlightInfoPopup: SFC = () => {
    const tariffGroupList = useSelector(selectTariffGroupList);
    const isShowInfoPopup = useSelector(selectInfoPopupOpen);
    const loadingFlightInfo = useSelector(selectInfoPopupLoading);
    const info = useSelector(selectInfoPopupDetails);

    const dispatch = useDispatch();

    const userPermissions = useUserPermissions();
    const allotmentHidden = !userPermissions.has(flightListPermissions.flightInfoAllotment);

    const getBookingInfo = (targetCode: TariffGroupCode) => {
        if (!info?.flightBookingInfo?.byTariffGroups) return undefined;
        return info.flightBookingInfo.byTariffGroups.find(x => {
            const code = mapTariffGroupIdToCode(x.tariffGroupId);
            return targetCode === code;
        });
    };

    return (
        <PopupWrapper
            isOpened={isShowInfoPopup}
            onClose={() => dispatch(actions.closeFlightInfo())}
            modificators='sm'
        >
            <LoadingPage isLoading={loadingFlightInfo} />
            {info && !loadingFlightInfo && (
                <FlightListInfo
                    flight={info.flight}
                    flightBookingInfoEconomy={getBookingInfo('economy')}
                    flightBookingInfoBusiness={getBookingInfo('business')}
                    flightTurnoverInfo={info.flightTurnoverInfo}
                    tariffGroupList={tariffGroupList.items}
                    allotmentHidden={allotmentHidden}
                />
            )}
        </PopupWrapper>
    );
};
