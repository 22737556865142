import { PageSubTitle } from '@malesia/react-components/dist/src/components/Page/Title/Sub';
import { PopupConfirm } from '@malesia/react-components/dist/src/components/Popup/Confirm';
import { ReservationListNotification } from '@malesia/react-components/dist/src/components/Reservation/List/Notification';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectNotificationPopupLoading, selectNotificationPopupOpen } from '../store/selectors';
import { actions, initialState } from '../store/slice';
import { getValidationFunction } from 'utils/validation/getValidationFunction';

export const ReservationSmsNotificationWithStore: SFC = () => {
    const open = useSelector(selectNotificationPopupOpen);
    const loading = useSelector(selectNotificationPopupLoading);

    const dispatch = useDispatch();

    const callSMSNotificationValidate = useRef(() => false);
    const smsNotificationValidateFn = useMemo(() => getValidationFunction(
        'file://malesiareisen.com/json/schema/project/react-front-app/pages/ReservationsListPage/notification.json',
        [],
    ), []);

    const handleConfirmNotificationPopup = useCallback(() => {
        const isValid = callSMSNotificationValidate.current();
        if (isValid) {
            dispatch(actions.confirmSmsNotification());
        }
    }, [dispatch]);

    const handleCloseNotificationPopup = useCallback(() => {
        dispatch(actions.closeSmsNotificationPopup());
    }, [dispatch]);

    const handleOnChangeFormNotificationData = useCallback((data) => {
        dispatch(actions.setNotificationFilterData(data));
    }, [dispatch]);

    const handleOnSubmitFormNotificationData = useCallback(() => {
        dispatch(actions.confirmSmsNotification());
    }, [dispatch]);

    const notificationFormConfig = useMemo(() => ({
        content: { initialValue: initialState.smsNotificationPopup.formData.content, required: true },
    }), []);

    return (
        <PopupConfirm
            isOpened={open}
            isLoading={loading}
            onConfirm={handleConfirmNotificationPopup}
            onClose={handleCloseNotificationPopup}
            confirmMessage={
                <>
                    <PageSubTitle
                        text={
                            <FormattedMessage
                                id='front-app/ReservationListPage:NotificationTitle'
                                defaultMessage='Send an SMS'
                            />
                        }
                    />
                    <FormattedMessage
                        id='front-app/ReservationListPage:NotificationDescription'
                        defaultMessage='Please enter the SMS message'
                    />
                </>
            }
        >
            <ReservationListNotification
                formConfig={notificationFormConfig}
                validator={smsNotificationValidateFn}
                onSubmit={handleOnSubmitFormNotificationData}
                onChangeFormData={handleOnChangeFormNotificationData}
                callValidation={callSMSNotificationValidate}
            />
        </PopupConfirm>
    );
};
