import { put, select, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../../utils/log';
import { getApiErrorMessage } from '../../../services/ApiClient';
import { cancelApiReservation } from '../../../services/Reservation';
import { reservationsListNotifications } from './notifications';
import { selectCancelPopupReservation } from './selectors';
import { actions } from './slice';

function* confirmCancelReservation(action: ReturnType<typeof actions.confirmCancelReservation>) {
    try {
        const fee = action.payload;
        const reservation = (yield* select(selectCancelPopupReservation))!;

        yield* cancelApiReservation(reservation.id, fee);
        yield* put(actions.confirmCancelReservationSuccess());
        yield* put(reservationsListNotifications.cancelSuccess);
    }
    catch (error) {
        logError({
            error,
            target: 'ReservationsListPage.confirmCancelReservation',
        });
        yield* put(actions.confirmCancelReservationFail());
        const message = getApiErrorMessage(error);
        yield* put(reservationsListNotifications.unknownError(message));
    }
}

export function* confirmCancelReservationSaga() {
    yield* takeLatest(actions.confirmCancelReservation, confirmCancelReservation);
}
