import { permissionMaker } from '../../permissionExpression';

const { role } = permissionMaker;

const updateForm = role('ROLE_CUSTOMER_UPDATE');
const updateIsActive = role('ROLE_CUSTOMER_UPDATE_IS_ACTIVE');

export const customerEditPermissions = {
    updateForm,
    updateIsActive,
};
