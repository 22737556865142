import { ReservationCalculatePostRequest } from '@malesia/json-schema';
import { mapFlightsToCostRequest, preparePassengerOptions, preparePassengerSeats } from '../../../services/ReservationCalculate/utils';
import { AdminReservationBasket } from '../types';

// ToDo: Really don't required reservationId field?
type FixMeReservationCalculatePostRequest = ReservationCalculatePostRequest & {
    reservationId?: number,
};

export const prepareReservationCostRequest = (
    reservation: AdminReservationBasket,
): ReservationCalculatePostRequest | undefined => {
    const { passengers, selectedFlights, reservationId } = reservation;

    const options = preparePassengerOptions(passengers.map(x => x.options));
    const passengersSeats = preparePassengerSeats(
        passengers.map(x => x.seats),
        selectedFlights.outbound?.flightId!,
        selectedFlights.return?.flightId!,
    );
    const bookedFlights = mapFlightsToCostRequest(selectedFlights);
    if (passengers.length === 0 || !bookedFlights) return undefined;

    const params: FixMeReservationCalculatePostRequest = {
        reservationId,
        bookedFlights,
        passengers: passengers.map(x => x.info) as ReservationCalculatePostRequest['passengers'],
        passengersOptions: options,
        passengersSeats,
    };
    return params;
};
