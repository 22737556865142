import * as queryString from 'query-string';
import { call, debounce, put, select, takeLatest, throttle } from 'typed-redux-saga';
import { logError } from '../../../utils/log';
import { apiClientRequest, getApiErrorMessage } from '../../services/ApiClient';
import { companyListNotifications } from './notification';
import { selectInitialized, selectFilterData, selectPagination } from './selectors';
import { actions } from './slice';

export function* getFlightCompanyList() {
    try {
        const initialized = yield* select(selectInitialized);
        const can = initialized.sorting;
        if (!can) return;

        const filterData = yield* select(selectFilterData);
        const pagination = yield* select(selectPagination);

        const params = {
            query: filterData.searchText,
            isActive: filterData.isActiveOnly === true ? true : undefined,
            sortBy: filterData.sortBy,
            sortOrder: filterData.sortOrder,
            page: pagination.page,
            pageSize: pagination.pageSize,
        };

        const requestData = {
            requestId: 'flightCompanyList',
            query: queryString.stringify(params, {
                skipNull: true,
                sort: false,
            }),
        };

        const result = yield* call(apiClientRequest, requestData);
        yield* put(actions.getFlightCompanyListSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'FlightCompanyListPage.getFlightCompanyList',
        });
        yield* put(actions.getFlightCompanyListError());
        const message = getApiErrorMessage(error);
        yield* put(companyListNotifications.unknownError(message));
    }
}

export function* getFlightCompanyListSaga() {
    yield* takeLatest(actions.getFlightCompanyList, getFlightCompanyList);

    function* requestGetReservationsList() {
        yield* put(actions.getFlightCompanyList());
    }

    yield* throttle(1000, [
        actions.setPage,
        actions.setSorting,
    ], requestGetReservationsList);

    yield* debounce(1000, [
        actions.updateFilterData,
        actions.setPageSize,
    ], requestGetReservationsList);
}
