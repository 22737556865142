import { AllotmentInvoiceListItem } from '@malesia/json-schema';
import { AllotmentInvoiceEditFormData } from '@malesia/react-components/dist/src/components/Allotment/InvoiceEditPopup';
import { verifyAgent } from '@malesia/react-components/dist/src/utils/verify';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { AllotmentInvoicePopupState, OpenCreateAllotmentInvoicePopupArgs } from './types';

export const initialState: AllotmentInvoicePopupState = {
    mode: 'create',
};

const allotmentInvoicePopupSlice = createSlice({
    name: 'allotmentInvoicePopup',
    initialState,
    reducers: {
        setFormData(state, action: PayloadAction<AllotmentInvoiceEditFormData>) {
            state.formData = action.payload;
        },
        openCreatePopup(state, action: PayloadAction<OpenCreateAllotmentInvoicePopupArgs>) {
            state.mode = 'create';
            state.agent = action.payload.agent;
            state.allotments = action.payload.allotments;
            state.open = true;
        },
        openEditPopup(state, action: PayloadAction<AllotmentInvoiceListItem>) {
            state.mode = 'edit';
            state.agent = verifyAgent(action.payload.agent);
            state.formData = {
                title: action.payload.title,
                invoiceDate: action.payload.invoiceDate!,
                invoiceCompany: action.payload.invoiceCompany!,
                paymentDeadline: action.payload.paymentDeadline!,
                discountDescription: action.payload.discountDescription,
                discount: action.payload.discount,
            };
            state.invoice = action.payload;
            state.open = true;
        },
        cancelPopup() {
            return initialState;
        },
        confirmPopup(state) {
            state.loading = true;
        },
        confirmPopupSuccess(state) {
            return initialState;
        },
        confirmPopupError(state) {
            state.loading = false;
        },
    },
});

export const {
    actions: allotmentInvoicePopupActions,
    reducer: allotmentInvoicePopupReducer,
    name: allotmentInvoicePopupSliceKey,
} = allotmentInvoicePopupSlice;
