import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { downloadCashbackPdfSaga } from './saga';
import { cashbackPdfActions, cashbackPdfReducer, cashbackPdfSliceKey } from './slice';

export const useInjectCashbackPdf = () => {
    useInjectReducer({
        actions: cashbackPdfActions,
        key: cashbackPdfSliceKey,
        reducer: cashbackPdfReducer,
    });
    useInjectSaga({
        key: `${cashbackPdfSliceKey}_downloadCashbackPdfSaga`,
        saga: downloadCashbackPdfSaga,
    });
};
