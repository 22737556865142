import { LoadingPage } from '@malesia/react-components/dist/src/components/LoadingOverlay/Page';
import { DesignSummary } from '@malesia/react-components/dist/src/components/Reservation/DesignSummary';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toNumber } from '../../../utils/uriUtils';
import { AllotmentPaymentWithStore } from './components/AllotmentPaymentWithStore';
import { useAllotmentPaymentPageInject } from './store/inject';
import { selectAllotmentInvoiceLoading } from './store/selectors';
import { allotmentPaymentActions } from './store/slice';

export const AllotmentPaymentPage: SFC = () => {
    useAllotmentPaymentPageInject();

    const dispatch = useDispatch();
    const routeParams = useParams<{ allotmentInvoiceId: string }>();

    useEffect(() => {
        const id = toNumber(routeParams.allotmentInvoiceId)!;
        dispatch(allotmentPaymentActions.loadInitialData(id));
    }, [dispatch, routeParams.allotmentInvoiceId]);

    const allotmentInvoiceLoading = useSelector(selectAllotmentInvoiceLoading);

    if (allotmentInvoiceLoading) return <LoadingPage isLoading />;

    return (
        <DesignSummary
            modificators='with-sidebar'
            contentSlot={
                <AllotmentPaymentWithStore />
            }
        />
    );
};
