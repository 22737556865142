import { HomeSearchFormData } from '@malesia/react-components/dist/src/components/Front/HomeSearchForm';
import { FormConfig } from '@malesia/react-components/dist/src/hooks/useForm';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectSharedData } from '../../containers/SharedData/inject';
import { selectAvailabilityAirportsMap, selectAirportList } from '../../containers/SharedData/selectors';
import { sharedActions } from '../../containers/SharedData/slice';

export const useSearchFormSettings = () => {
    useInjectSharedData();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(sharedActions.getAirportList());
    }, [dispatch]);
    const availabilityAirportsMap = useSelector(selectAvailabilityAirportsMap);
    const airportList = useSelector(selectAirportList);

    const list = useMemo(() => {
        const filtered = airportList.filter(x => availabilityAirportsMap[x.code]);
        return filtered;
    }, [airportList, availabilityAirportsMap]);

    const [propsValues, setPropsValues] = useState<Partial<HomeSearchFormData>>({
        adult: 1,
        child: 0,
        infant: 0,
    });
    useEffect(() => {
        setPropsValues((values) => ({
            ...values,
            airportFrom: list.find(x => x.code === 'ZRH'),
            airportTo: list.find(x => x.code === 'PRN'),
        }));
    }, [list]);

    const toList = useMemo(() => {
        const from = propsValues.airportFrom?.code;
        const getAllowed = () => {
            if (!from) return undefined;
            return availabilityAirportsMap[from];
        };
        const allowed = getAllowed() ?? [];
        return list.filter(x => allowed.includes(x.code));
    }, [list, availabilityAirportsMap, propsValues.airportFrom]);

    const fixedPropsValues = useMemo<Partial<HomeSearchFormData>>(() => {
        const needDropTo = !toList.some(x => x.code === propsValues.airportTo?.code);
        return {
            ...propsValues,
            airportTo: needDropTo ? undefined : propsValues.airportTo,
        };
    }, [propsValues, toList]);

    const formConfig = useMemo((): FormConfig<HomeSearchFormData> => ({
        airportFrom: {
            items: list,
        },
        airportTo: {
            items: toList,
        },
        returnDate: {
            restrictions: {
                required: !propsValues.isOneWay,
            },
        },
    }), [list, toList, propsValues.isOneWay]);

    return { formConfig, propsValues: fixedPropsValues, setPropsValues };
};
