import { call, takeLatest, put } from 'typed-redux-saga';
import { logError } from '../../../utils/log';
import { PassengerInfo } from '../../../utils/reservation/types';
import { getApiErrorMessage } from '../../services/ApiClient';
import { getFlightsPassengers } from '../../services/FlightPassenger/flightPassenger.service';
import { reservationSamePassengersNotifications } from './notification';
import { reservationSamePassengersActions } from './slice';

export const checkSamePassenger = (first: PassengerInfo, second: PassengerInfo) => {
    return first.firstName === second.firstName
        && first.lastName === second.lastName
        && first.gender === second.gender
        && first.birthday === second.birthday;
};

function* checkSamePassengers(action: ReturnType<typeof reservationSamePassengersActions.checkSamePassengers>) {
    try {
        const { flights, passengers } = action.payload;

        const flightsPassengers = yield* call(
            getFlightsPassengers, flights.outbound?.flightId, flights.return?.flightId,
        );

        const samePassengers = passengers.filter(
            passenger => flightsPassengers.some(
                flightPassenger => checkSamePassenger(passenger, flightPassenger),
            ),
        );

        if (samePassengers.length > 0) {
            yield* put(reservationSamePassengersActions.checkSamePassengersAsk(samePassengers));
        }
        else {
            yield* put(reservationSamePassengersActions.checkSamePassengersConfirm());
        }
    }
    catch (error) {
        logError({
            error,
            target: 'ReservationSamePassengers.checkSamePassengers',
        });
        const message = getApiErrorMessage(error);

        yield* put(reservationSamePassengersNotifications.unknownError(message));
        yield* put(reservationSamePassengersActions.checkSamePassengersFail());
    }
}
export function* checkSamePassengerSaga() {
    yield* takeLatest(reservationSamePassengersActions.checkSamePassengers, checkSamePassengers);
}
