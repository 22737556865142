import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** ISO8601 Date values */
  Date: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type Query = {
  __typename?: 'Query';
  advertisements_card?: Maybe<Array<Maybe<Advertisements_Card>>>;
  advertisements_card_aggregated?: Maybe<Array<Maybe<Advertisements_Card_Aggregated>>>;
  advertisements_card_by_id?: Maybe<Advertisements_Card>;
  airport?: Maybe<Array<Maybe<Airport>>>;
  airport_aggregated?: Maybe<Array<Maybe<Airport_Aggregated>>>;
  airport_by_id?: Maybe<Airport>;
  airport_translations?: Maybe<Array<Maybe<Airport_Translations>>>;
  airport_translations_aggregated?: Maybe<Array<Maybe<Airport_Translations_Aggregated>>>;
  airport_translations_by_id?: Maybe<Airport_Translations>;
  baggage?: Maybe<Baggage>;
  baggage_translations?: Maybe<Array<Maybe<Baggage_Translations>>>;
  baggage_translations_aggregated?: Maybe<Array<Maybe<Baggage_Translations_Aggregated>>>;
  baggage_translations_by_id?: Maybe<Baggage_Translations>;
  before_traveling_page?: Maybe<Before_Traveling_Page>;
  before_traveling_page_translations?: Maybe<Array<Maybe<Before_Traveling_Page_Translations>>>;
  before_traveling_page_translations_aggregated?: Maybe<Array<Maybe<Before_Traveling_Page_Translations_Aggregated>>>;
  before_traveling_page_translations_by_id?: Maybe<Before_Traveling_Page_Translations>;
  booking_page_top_banner?: Maybe<Array<Maybe<Booking_Page_Top_Banner>>>;
  booking_page_top_banner_aggregated?: Maybe<Array<Maybe<Booking_Page_Top_Banner_Aggregated>>>;
  booking_page_top_banner_by_id?: Maybe<Booking_Page_Top_Banner>;
  booking_page_translations?: Maybe<Array<Maybe<Booking_Page_Translations>>>;
  booking_page_translations_aggregated?: Maybe<Array<Maybe<Booking_Page_Translations_Aggregated>>>;
  booking_page_translations_by_id?: Maybe<Booking_Page_Translations>;
  car_page?: Maybe<Car_Page>;
  car_page_card?: Maybe<Array<Maybe<Car_Page_Card>>>;
  car_page_card_aggregated?: Maybe<Array<Maybe<Car_Page_Card_Aggregated>>>;
  car_page_card_by_id?: Maybe<Car_Page_Card>;
  car_page_translations?: Maybe<Array<Maybe<Car_Page_Translations>>>;
  car_page_translations_aggregated?: Maybe<Array<Maybe<Car_Page_Translations_Aggregated>>>;
  car_page_translations_by_id?: Maybe<Car_Page_Translations>;
  contact_kosovo?: Maybe<Contact_Kosovo>;
  contact_kosovo_translations?: Maybe<Array<Maybe<Contact_Kosovo_Translations>>>;
  contact_kosovo_translations_aggregated?: Maybe<Array<Maybe<Contact_Kosovo_Translations_Aggregated>>>;
  contact_kosovo_translations_by_id?: Maybe<Contact_Kosovo_Translations>;
  contact_macedonia?: Maybe<Contact_Macedonia>;
  contact_macedonia_translations?: Maybe<Array<Maybe<Contact_Macedonia_Translations>>>;
  contact_macedonia_translations_aggregated?: Maybe<Array<Maybe<Contact_Macedonia_Translations_Aggregated>>>;
  contact_macedonia_translations_by_id?: Maybe<Contact_Macedonia_Translations>;
  contact_sw?: Maybe<Contact_Sw>;
  contact_sw_translations?: Maybe<Array<Maybe<Contact_Sw_Translations>>>;
  contact_sw_translations_aggregated?: Maybe<Array<Maybe<Contact_Sw_Translations_Aggregated>>>;
  contact_sw_translations_by_id?: Maybe<Contact_Sw_Translations>;
  data_protection_page?: Maybe<Data_Protection_Page>;
  data_protection_page_translations?: Maybe<Array<Maybe<Data_Protection_Page_Translations>>>;
  data_protection_page_translations_aggregated?: Maybe<Array<Maybe<Data_Protection_Page_Translations_Aggregated>>>;
  data_protection_page_translations_by_id?: Maybe<Data_Protection_Page_Translations>;
  flights_page?: Maybe<Flights_Page>;
  flights_page_translations?: Maybe<Array<Maybe<Flights_Page_Translations>>>;
  flights_page_translations_aggregated?: Maybe<Array<Maybe<Flights_Page_Translations_Aggregated>>>;
  flights_page_translations_by_id?: Maybe<Flights_Page_Translations>;
  health_page?: Maybe<Health_Page>;
  health_page_translations?: Maybe<Array<Maybe<Health_Page_Translations>>>;
  health_page_translations_aggregated?: Maybe<Array<Maybe<Health_Page_Translations_Aggregated>>>;
  health_page_translations_by_id?: Maybe<Health_Page_Translations>;
  history_card?: Maybe<Array<Maybe<History_Card>>>;
  history_card_aggregated?: Maybe<Array<Maybe<History_Card_Aggregated>>>;
  history_card_by_id?: Maybe<History_Card>;
  history_card_translations?: Maybe<Array<Maybe<History_Card_Translations>>>;
  history_card_translations_aggregated?: Maybe<Array<Maybe<History_Card_Translations_Aggregated>>>;
  history_card_translations_by_id?: Maybe<History_Card_Translations>;
  history_page?: Maybe<History_Page>;
  history_page_translations?: Maybe<Array<Maybe<History_Page_Translations>>>;
  history_page_translations_aggregated?: Maybe<Array<Maybe<History_Page_Translations_Aggregated>>>;
  history_page_translations_by_id?: Maybe<History_Page_Translations>;
  home_page_advertisements?: Maybe<Array<Maybe<Home_Page_Advertisements>>>;
  home_page_advertisements_aggregated?: Maybe<Array<Maybe<Home_Page_Advertisements_Aggregated>>>;
  home_page_advertisements_by_id?: Maybe<Home_Page_Advertisements>;
  home_page_offers_slides?: Maybe<Array<Maybe<Home_Page_Offers_Slides>>>;
  home_page_offers_slides_aggregated?: Maybe<Array<Maybe<Home_Page_Offers_Slides_Aggregated>>>;
  home_page_offers_slides_by_id?: Maybe<Home_Page_Offers_Slides>;
  hotels_page?: Maybe<Hotels_Page>;
  hotels_page_translations?: Maybe<Array<Maybe<Hotels_Page_Translations>>>;
  hotels_page_translations_aggregated?: Maybe<Array<Maybe<Hotels_Page_Translations_Aggregated>>>;
  hotels_page_translations_by_id?: Maybe<Hotels_Page_Translations>;
  imprint_page?: Maybe<Imprint_Page>;
  imprint_page_translations?: Maybe<Array<Maybe<Imprint_Page_Translations>>>;
  imprint_page_translations_aggregated?: Maybe<Array<Maybe<Imprint_Page_Translations_Aggregated>>>;
  imprint_page_translations_by_id?: Maybe<Imprint_Page_Translations>;
  languages?: Maybe<Array<Maybe<Languages>>>;
  languages_by_id?: Maybe<Languages>;
  location_page?: Maybe<Location_Page>;
  location_page_translations?: Maybe<Array<Maybe<Location_Page_Translations>>>;
  location_page_translations_aggregated?: Maybe<Array<Maybe<Location_Page_Translations_Aggregated>>>;
  location_page_translations_by_id?: Maybe<Location_Page_Translations>;
  management_card?: Maybe<Array<Maybe<Management_Card>>>;
  management_card_aggregated?: Maybe<Array<Maybe<Management_Card_Aggregated>>>;
  management_card_by_id?: Maybe<Management_Card>;
  management_card_translations?: Maybe<Array<Maybe<Management_Card_Translations>>>;
  management_card_translations_aggregated?: Maybe<Array<Maybe<Management_Card_Translations_Aggregated>>>;
  management_card_translations_by_id?: Maybe<Management_Card_Translations>;
  management_page?: Maybe<Management_Page>;
  management_page_translations?: Maybe<Array<Maybe<Management_Page_Translations>>>;
  management_page_translations_aggregated?: Maybe<Array<Maybe<Management_Page_Translations_Aggregated>>>;
  management_page_translations_by_id?: Maybe<Management_Page_Translations>;
  milestones?: Maybe<Array<Maybe<Milestones>>>;
  milestones_aggregated?: Maybe<Array<Maybe<Milestones_Aggregated>>>;
  milestones_by_id?: Maybe<Milestones>;
  news?: Maybe<Array<Maybe<News>>>;
  news_aggregated?: Maybe<Array<Maybe<News_Aggregated>>>;
  news_block?: Maybe<News_Block>;
  news_block_translations?: Maybe<Array<Maybe<News_Block_Translations>>>;
  news_block_translations_aggregated?: Maybe<Array<Maybe<News_Block_Translations_Aggregated>>>;
  news_block_translations_by_id?: Maybe<News_Block_Translations>;
  news_by_id?: Maybe<News>;
  news_translations?: Maybe<Array<Maybe<News_Translations>>>;
  news_translations_aggregated?: Maybe<Array<Maybe<News_Translations_Aggregated>>>;
  news_translations_by_id?: Maybe<News_Translations>;
  partner_card?: Maybe<Array<Maybe<Partner_Card>>>;
  partner_card_aggregated?: Maybe<Array<Maybe<Partner_Card_Aggregated>>>;
  partner_card_by_id?: Maybe<Partner_Card>;
  partners_page?: Maybe<Partners_Page>;
  partners_page_translations?: Maybe<Array<Maybe<Partners_Page_Translations>>>;
  partners_page_translations_aggregated?: Maybe<Array<Maybe<Partners_Page_Translations_Aggregated>>>;
  partners_page_translations_by_id?: Maybe<Partners_Page_Translations>;
  responsive_image?: Maybe<Array<Maybe<Responsive_Image>>>;
  responsive_image_aggregated?: Maybe<Array<Maybe<Responsive_Image_Aggregated>>>;
  responsive_image_by_id?: Maybe<Responsive_Image>;
  safety_page?: Maybe<Safety_Page>;
  safety_page_translations?: Maybe<Array<Maybe<Safety_Page_Translations>>>;
  safety_page_translations_aggregated?: Maybe<Array<Maybe<Safety_Page_Translations_Aggregated>>>;
  safety_page_translations_by_id?: Maybe<Safety_Page_Translations>;
  spesical_offer?: Maybe<Array<Maybe<Spesical_Offer>>>;
  spesical_offer_aggregated?: Maybe<Array<Maybe<Spesical_Offer_Aggregated>>>;
  spesical_offer_by_id?: Maybe<Spesical_Offer>;
  spesical_offer_translations?: Maybe<Array<Maybe<Spesical_Offer_Translations>>>;
  spesical_offer_translations_aggregated?: Maybe<Array<Maybe<Spesical_Offer_Translations_Aggregated>>>;
  spesical_offer_translations_by_id?: Maybe<Spesical_Offer_Translations>;
  terms_page?: Maybe<Terms_Page>;
  terms_page_translations?: Maybe<Array<Maybe<Terms_Page_Translations>>>;
  terms_page_translations_aggregated?: Maybe<Array<Maybe<Terms_Page_Translations_Aggregated>>>;
  terms_page_translations_by_id?: Maybe<Terms_Page_Translations>;
};


export type QueryAdvertisements_CardArgs = {
  filter?: InputMaybe<Advertisements_Card_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryAdvertisements_Card_AggregatedArgs = {
  filter?: InputMaybe<Advertisements_Card_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryAdvertisements_Card_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryAirportArgs = {
  filter?: InputMaybe<Airport_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryAirport_AggregatedArgs = {
  filter?: InputMaybe<Airport_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryAirport_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryAirport_TranslationsArgs = {
  filter?: InputMaybe<Airport_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryAirport_Translations_AggregatedArgs = {
  filter?: InputMaybe<Airport_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryAirport_Translations_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryBaggage_TranslationsArgs = {
  filter?: InputMaybe<Baggage_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryBaggage_Translations_AggregatedArgs = {
  filter?: InputMaybe<Baggage_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryBaggage_Translations_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryBefore_Traveling_Page_TranslationsArgs = {
  filter?: InputMaybe<Before_Traveling_Page_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryBefore_Traveling_Page_Translations_AggregatedArgs = {
  filter?: InputMaybe<Before_Traveling_Page_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryBefore_Traveling_Page_Translations_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryBooking_Page_Top_BannerArgs = {
  filter?: InputMaybe<Booking_Page_Top_Banner_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryBooking_Page_Top_Banner_AggregatedArgs = {
  filter?: InputMaybe<Booking_Page_Top_Banner_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryBooking_Page_Top_Banner_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryBooking_Page_TranslationsArgs = {
  filter?: InputMaybe<Booking_Page_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryBooking_Page_Translations_AggregatedArgs = {
  filter?: InputMaybe<Booking_Page_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryBooking_Page_Translations_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryCar_Page_CardArgs = {
  filter?: InputMaybe<Car_Page_Card_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCar_Page_Card_AggregatedArgs = {
  filter?: InputMaybe<Car_Page_Card_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCar_Page_Card_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryCar_Page_TranslationsArgs = {
  filter?: InputMaybe<Car_Page_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCar_Page_Translations_AggregatedArgs = {
  filter?: InputMaybe<Car_Page_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCar_Page_Translations_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryContact_Kosovo_TranslationsArgs = {
  filter?: InputMaybe<Contact_Kosovo_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryContact_Kosovo_Translations_AggregatedArgs = {
  filter?: InputMaybe<Contact_Kosovo_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryContact_Kosovo_Translations_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryContact_Macedonia_TranslationsArgs = {
  filter?: InputMaybe<Contact_Macedonia_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryContact_Macedonia_Translations_AggregatedArgs = {
  filter?: InputMaybe<Contact_Macedonia_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryContact_Macedonia_Translations_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryContact_Sw_TranslationsArgs = {
  filter?: InputMaybe<Contact_Sw_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryContact_Sw_Translations_AggregatedArgs = {
  filter?: InputMaybe<Contact_Sw_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryContact_Sw_Translations_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryData_Protection_Page_TranslationsArgs = {
  filter?: InputMaybe<Data_Protection_Page_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryData_Protection_Page_Translations_AggregatedArgs = {
  filter?: InputMaybe<Data_Protection_Page_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryData_Protection_Page_Translations_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryFlights_Page_TranslationsArgs = {
  filter?: InputMaybe<Flights_Page_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryFlights_Page_Translations_AggregatedArgs = {
  filter?: InputMaybe<Flights_Page_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryFlights_Page_Translations_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryHealth_Page_TranslationsArgs = {
  filter?: InputMaybe<Health_Page_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryHealth_Page_Translations_AggregatedArgs = {
  filter?: InputMaybe<Health_Page_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryHealth_Page_Translations_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryHistory_CardArgs = {
  filter?: InputMaybe<History_Card_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryHistory_Card_AggregatedArgs = {
  filter?: InputMaybe<History_Card_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryHistory_Card_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryHistory_Card_TranslationsArgs = {
  filter?: InputMaybe<History_Card_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryHistory_Card_Translations_AggregatedArgs = {
  filter?: InputMaybe<History_Card_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryHistory_Card_Translations_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryHistory_Page_TranslationsArgs = {
  filter?: InputMaybe<History_Page_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryHistory_Page_Translations_AggregatedArgs = {
  filter?: InputMaybe<History_Page_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryHistory_Page_Translations_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryHome_Page_AdvertisementsArgs = {
  filter?: InputMaybe<Home_Page_Advertisements_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryHome_Page_Advertisements_AggregatedArgs = {
  filter?: InputMaybe<Home_Page_Advertisements_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryHome_Page_Advertisements_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryHome_Page_Offers_SlidesArgs = {
  filter?: InputMaybe<Home_Page_Offers_Slides_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryHome_Page_Offers_Slides_AggregatedArgs = {
  filter?: InputMaybe<Home_Page_Offers_Slides_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryHome_Page_Offers_Slides_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryHotels_Page_TranslationsArgs = {
  filter?: InputMaybe<Hotels_Page_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryHotels_Page_Translations_AggregatedArgs = {
  filter?: InputMaybe<Hotels_Page_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryHotels_Page_Translations_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryImprint_Page_TranslationsArgs = {
  filter?: InputMaybe<Imprint_Page_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryImprint_Page_Translations_AggregatedArgs = {
  filter?: InputMaybe<Imprint_Page_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryImprint_Page_Translations_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryLanguagesArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryLanguages_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryLocation_Page_TranslationsArgs = {
  filter?: InputMaybe<Location_Page_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryLocation_Page_Translations_AggregatedArgs = {
  filter?: InputMaybe<Location_Page_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryLocation_Page_Translations_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryManagement_CardArgs = {
  filter?: InputMaybe<Management_Card_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryManagement_Card_AggregatedArgs = {
  filter?: InputMaybe<Management_Card_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryManagement_Card_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryManagement_Card_TranslationsArgs = {
  filter?: InputMaybe<Management_Card_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryManagement_Card_Translations_AggregatedArgs = {
  filter?: InputMaybe<Management_Card_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryManagement_Card_Translations_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryManagement_Page_TranslationsArgs = {
  filter?: InputMaybe<Management_Page_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryManagement_Page_Translations_AggregatedArgs = {
  filter?: InputMaybe<Management_Page_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryManagement_Page_Translations_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryMilestonesArgs = {
  filter?: InputMaybe<Milestones_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryMilestones_AggregatedArgs = {
  filter?: InputMaybe<Milestones_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryMilestones_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryNewsArgs = {
  filter?: InputMaybe<News_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryNews_AggregatedArgs = {
  filter?: InputMaybe<News_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryNews_Block_TranslationsArgs = {
  filter?: InputMaybe<News_Block_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryNews_Block_Translations_AggregatedArgs = {
  filter?: InputMaybe<News_Block_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryNews_Block_Translations_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryNews_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryNews_TranslationsArgs = {
  filter?: InputMaybe<News_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryNews_Translations_AggregatedArgs = {
  filter?: InputMaybe<News_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryNews_Translations_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryPartner_CardArgs = {
  filter?: InputMaybe<Partner_Card_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryPartner_Card_AggregatedArgs = {
  filter?: InputMaybe<Partner_Card_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryPartner_Card_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryPartners_Page_TranslationsArgs = {
  filter?: InputMaybe<Partners_Page_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryPartners_Page_Translations_AggregatedArgs = {
  filter?: InputMaybe<Partners_Page_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryPartners_Page_Translations_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryResponsive_ImageArgs = {
  filter?: InputMaybe<Responsive_Image_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryResponsive_Image_AggregatedArgs = {
  filter?: InputMaybe<Responsive_Image_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryResponsive_Image_By_IdArgs = {
  id: Scalars['ID'];
};


export type QuerySafety_Page_TranslationsArgs = {
  filter?: InputMaybe<Safety_Page_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QuerySafety_Page_Translations_AggregatedArgs = {
  filter?: InputMaybe<Safety_Page_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QuerySafety_Page_Translations_By_IdArgs = {
  id: Scalars['ID'];
};


export type QuerySpesical_OfferArgs = {
  filter?: InputMaybe<Spesical_Offer_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QuerySpesical_Offer_AggregatedArgs = {
  filter?: InputMaybe<Spesical_Offer_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QuerySpesical_Offer_By_IdArgs = {
  id: Scalars['ID'];
};


export type QuerySpesical_Offer_TranslationsArgs = {
  filter?: InputMaybe<Spesical_Offer_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QuerySpesical_Offer_Translations_AggregatedArgs = {
  filter?: InputMaybe<Spesical_Offer_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QuerySpesical_Offer_Translations_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryTerms_Page_TranslationsArgs = {
  filter?: InputMaybe<Terms_Page_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryTerms_Page_Translations_AggregatedArgs = {
  filter?: InputMaybe<Terms_Page_Translations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryTerms_Page_Translations_By_IdArgs = {
  id: Scalars['ID'];
};

export type Advertisements_Card = {
  __typename?: 'advertisements_card';
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Directus_Files>;
  link?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
};


export type Advertisements_CardImageArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Advertisements_Card_Aggregated = {
  __typename?: 'advertisements_card_aggregated';
  avg?: Maybe<Advertisements_Card_Aggregated_Fields>;
  avgDistinct?: Maybe<Advertisements_Card_Aggregated_Fields>;
  count?: Maybe<Advertisements_Card_Aggregated_Fields>;
  countDistinct?: Maybe<Advertisements_Card_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Advertisements_Card_Aggregated_Fields>;
  min?: Maybe<Advertisements_Card_Aggregated_Fields>;
  sum?: Maybe<Advertisements_Card_Aggregated_Fields>;
  sumDistinct?: Maybe<Advertisements_Card_Aggregated_Fields>;
};

export type Advertisements_Card_Aggregated_Fields = {
  __typename?: 'advertisements_card_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

export type Advertisements_Card_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Advertisements_Card_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Advertisements_Card_Filter>>>;
  id?: InputMaybe<Number_Filter_Operators>;
  image?: InputMaybe<Directus_Files_Filter>;
  link?: InputMaybe<String_Filter_Operators>;
  sort?: InputMaybe<Number_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
};

export type Airport = {
  __typename?: 'airport';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  status: Scalars['String'];
  translations?: Maybe<Array<Maybe<Airport_Translations>>>;
};


export type AirportTranslationsArgs = {
  filter?: InputMaybe<Airport_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Airport_Aggregated = {
  __typename?: 'airport_aggregated';
  avg?: Maybe<Airport_Aggregated_Fields>;
  avgDistinct?: Maybe<Airport_Aggregated_Fields>;
  count?: Maybe<Airport_Aggregated_Fields>;
  countDistinct?: Maybe<Airport_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Airport_Aggregated_Fields>;
  min?: Maybe<Airport_Aggregated_Fields>;
  sum?: Maybe<Airport_Aggregated_Fields>;
  sumDistinct?: Maybe<Airport_Aggregated_Fields>;
};

export type Airport_Aggregated_Fields = {
  __typename?: 'airport_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Airport_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Airport_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Airport_Filter>>>;
  code?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  translations?: InputMaybe<Airport_Translations_Filter>;
};

export type Airport_Translations = {
  __typename?: 'airport_translations';
  airport_id?: Maybe<Airport>;
  id?: Maybe<Scalars['ID']>;
  languages_id?: Maybe<Languages>;
  title?: Maybe<Scalars['String']>;
};


export type Airport_TranslationsAirport_IdArgs = {
  filter?: InputMaybe<Airport_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Airport_TranslationsLanguages_IdArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Airport_Translations_Aggregated = {
  __typename?: 'airport_translations_aggregated';
  avg?: Maybe<Airport_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<Airport_Translations_Aggregated_Fields>;
  count?: Maybe<Airport_Translations_Aggregated_Fields>;
  countDistinct?: Maybe<Airport_Translations_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Airport_Translations_Aggregated_Fields>;
  min?: Maybe<Airport_Translations_Aggregated_Fields>;
  sum?: Maybe<Airport_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<Airport_Translations_Aggregated_Fields>;
};

export type Airport_Translations_Aggregated_Fields = {
  __typename?: 'airport_translations_aggregated_fields';
  airport_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Airport_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Airport_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Airport_Translations_Filter>>>;
  airport_id?: InputMaybe<Airport_Filter>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_id?: InputMaybe<Languages_Filter>;
  title?: InputMaybe<String_Filter_Operators>;
};

export type Baggage = {
  __typename?: 'baggage';
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  id?: Maybe<Scalars['ID']>;
  top_image?: Maybe<Directus_Files>;
  translations?: Maybe<Array<Maybe<Baggage_Translations>>>;
  user_updated?: Maybe<Scalars['String']>;
};


export type BaggageTop_ImageArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type BaggageTranslationsArgs = {
  filter?: InputMaybe<Baggage_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Baggage_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Baggage_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Baggage_Filter>>>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  top_image?: InputMaybe<Directus_Files_Filter>;
  translations?: InputMaybe<Baggage_Translations_Filter>;
  user_updated?: InputMaybe<String_Filter_Operators>;
};

export type Baggage_Translations = {
  __typename?: 'baggage_translations';
  baggage_id?: Maybe<Baggage>;
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  languages_code?: Maybe<Languages>;
};


export type Baggage_TranslationsBaggage_IdArgs = {
  filter?: InputMaybe<Baggage_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Baggage_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Baggage_Translations_Aggregated = {
  __typename?: 'baggage_translations_aggregated';
  avg?: Maybe<Baggage_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<Baggage_Translations_Aggregated_Fields>;
  count?: Maybe<Baggage_Translations_Aggregated_Fields>;
  countDistinct?: Maybe<Baggage_Translations_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Baggage_Translations_Aggregated_Fields>;
  min?: Maybe<Baggage_Translations_Aggregated_Fields>;
  sum?: Maybe<Baggage_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<Baggage_Translations_Aggregated_Fields>;
};

export type Baggage_Translations_Aggregated_Fields = {
  __typename?: 'baggage_translations_aggregated_fields';
  baggage_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Baggage_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Baggage_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Baggage_Translations_Filter>>>;
  baggage_id?: InputMaybe<Baggage_Filter>;
  content?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
};

export type Before_Traveling_Page = {
  __typename?: 'before_traveling_page';
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  id?: Maybe<Scalars['ID']>;
  top_image?: Maybe<Directus_Files>;
  translations?: Maybe<Array<Maybe<Before_Traveling_Page_Translations>>>;
  user_updated?: Maybe<Scalars['String']>;
};


export type Before_Traveling_PageTop_ImageArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Before_Traveling_PageTranslationsArgs = {
  filter?: InputMaybe<Before_Traveling_Page_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Before_Traveling_Page_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Before_Traveling_Page_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Before_Traveling_Page_Filter>>>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  top_image?: InputMaybe<Directus_Files_Filter>;
  translations?: InputMaybe<Before_Traveling_Page_Translations_Filter>;
  user_updated?: InputMaybe<String_Filter_Operators>;
};

export type Before_Traveling_Page_Translations = {
  __typename?: 'before_traveling_page_translations';
  before_traveling_page_id?: Maybe<Before_Traveling_Page>;
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  languages_code?: Maybe<Languages>;
};


export type Before_Traveling_Page_TranslationsBefore_Traveling_Page_IdArgs = {
  filter?: InputMaybe<Before_Traveling_Page_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Before_Traveling_Page_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Before_Traveling_Page_Translations_Aggregated = {
  __typename?: 'before_traveling_page_translations_aggregated';
  avg?: Maybe<Before_Traveling_Page_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<Before_Traveling_Page_Translations_Aggregated_Fields>;
  count?: Maybe<Before_Traveling_Page_Translations_Aggregated_Fields>;
  countDistinct?: Maybe<Before_Traveling_Page_Translations_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Before_Traveling_Page_Translations_Aggregated_Fields>;
  min?: Maybe<Before_Traveling_Page_Translations_Aggregated_Fields>;
  sum?: Maybe<Before_Traveling_Page_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<Before_Traveling_Page_Translations_Aggregated_Fields>;
};

export type Before_Traveling_Page_Translations_Aggregated_Fields = {
  __typename?: 'before_traveling_page_translations_aggregated_fields';
  before_traveling_page_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Before_Traveling_Page_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Before_Traveling_Page_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Before_Traveling_Page_Translations_Filter>>>;
  before_traveling_page_id?: InputMaybe<Before_Traveling_Page_Filter>;
  content?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
};

export type Booking_Page_Top_Banner = {
  __typename?: 'booking_page_top_banner';
  collection?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  item?: Maybe<Booking_Page_Top_Banner_Item_Union>;
};

export type Booking_Page_Top_Banner_Aggregated = {
  __typename?: 'booking_page_top_banner_aggregated';
  avg?: Maybe<Booking_Page_Top_Banner_Aggregated_Fields>;
  avgDistinct?: Maybe<Booking_Page_Top_Banner_Aggregated_Fields>;
  count?: Maybe<Booking_Page_Top_Banner_Aggregated_Fields>;
  countDistinct?: Maybe<Booking_Page_Top_Banner_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Booking_Page_Top_Banner_Aggregated_Fields>;
  min?: Maybe<Booking_Page_Top_Banner_Aggregated_Fields>;
  sum?: Maybe<Booking_Page_Top_Banner_Aggregated_Fields>;
  sumDistinct?: Maybe<Booking_Page_Top_Banner_Aggregated_Fields>;
};

export type Booking_Page_Top_Banner_Aggregated_Fields = {
  __typename?: 'booking_page_top_banner_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Booking_Page_Top_Banner_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Booking_Page_Top_Banner_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Booking_Page_Top_Banner_Filter>>>;
  collection?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  item?: InputMaybe<String_Filter_Operators>;
};

export type Booking_Page_Top_Banner_Item_Union = Responsive_Image;

export type Booking_Page_Translations = {
  __typename?: 'booking_page_translations';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  languages_code?: Maybe<Languages>;
  title?: Maybe<Scalars['String']>;
};


export type Booking_Page_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Booking_Page_Translations_Aggregated = {
  __typename?: 'booking_page_translations_aggregated';
  avg?: Maybe<Booking_Page_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<Booking_Page_Translations_Aggregated_Fields>;
  count?: Maybe<Booking_Page_Translations_Aggregated_Fields>;
  countDistinct?: Maybe<Booking_Page_Translations_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Booking_Page_Translations_Aggregated_Fields>;
  min?: Maybe<Booking_Page_Translations_Aggregated_Fields>;
  sum?: Maybe<Booking_Page_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<Booking_Page_Translations_Aggregated_Fields>;
};

export type Booking_Page_Translations_Aggregated_Fields = {
  __typename?: 'booking_page_translations_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Booking_Page_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Booking_Page_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Booking_Page_Translations_Filter>>>;
  description?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
  title?: InputMaybe<String_Filter_Operators>;
};

export type Car_Page = {
  __typename?: 'car_page';
  cards?: Maybe<Array<Maybe<Car_Page_Card>>>;
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  id?: Maybe<Scalars['ID']>;
  top_image?: Maybe<Directus_Files>;
  translations?: Maybe<Array<Maybe<Car_Page_Translations>>>;
  user_updated?: Maybe<Scalars['String']>;
};


export type Car_PageCardsArgs = {
  filter?: InputMaybe<Car_Page_Card_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Car_PageTop_ImageArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Car_PageTranslationsArgs = {
  filter?: InputMaybe<Car_Page_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Car_Page_Card = {
  __typename?: 'car_page_card';
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Directus_Files>;
  link?: Maybe<Scalars['String']>;
  page_id?: Maybe<Car_Page>;
  sort?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  user_updated?: Maybe<Scalars['String']>;
};


export type Car_Page_CardImageArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Car_Page_CardPage_IdArgs = {
  filter?: InputMaybe<Car_Page_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Car_Page_Card_Aggregated = {
  __typename?: 'car_page_card_aggregated';
  avg?: Maybe<Car_Page_Card_Aggregated_Fields>;
  avgDistinct?: Maybe<Car_Page_Card_Aggregated_Fields>;
  count?: Maybe<Car_Page_Card_Aggregated_Fields>;
  countDistinct?: Maybe<Car_Page_Card_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Car_Page_Card_Aggregated_Fields>;
  min?: Maybe<Car_Page_Card_Aggregated_Fields>;
  sum?: Maybe<Car_Page_Card_Aggregated_Fields>;
  sumDistinct?: Maybe<Car_Page_Card_Aggregated_Fields>;
};

export type Car_Page_Card_Aggregated_Fields = {
  __typename?: 'car_page_card_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

export type Car_Page_Card_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Car_Page_Card_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Car_Page_Card_Filter>>>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  image?: InputMaybe<Directus_Files_Filter>;
  link?: InputMaybe<String_Filter_Operators>;
  page_id?: InputMaybe<Car_Page_Filter>;
  sort?: InputMaybe<Number_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  user_updated?: InputMaybe<String_Filter_Operators>;
};

export type Car_Page_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Car_Page_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Car_Page_Filter>>>;
  cards?: InputMaybe<Car_Page_Card_Filter>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  top_image?: InputMaybe<Directus_Files_Filter>;
  translations?: InputMaybe<Car_Page_Translations_Filter>;
  user_updated?: InputMaybe<String_Filter_Operators>;
};

export type Car_Page_Translations = {
  __typename?: 'car_page_translations';
  car_page_id?: Maybe<Car_Page>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  languages_code?: Maybe<Languages>;
};


export type Car_Page_TranslationsCar_Page_IdArgs = {
  filter?: InputMaybe<Car_Page_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Car_Page_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Car_Page_Translations_Aggregated = {
  __typename?: 'car_page_translations_aggregated';
  avg?: Maybe<Car_Page_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<Car_Page_Translations_Aggregated_Fields>;
  count?: Maybe<Car_Page_Translations_Aggregated_Fields>;
  countDistinct?: Maybe<Car_Page_Translations_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Car_Page_Translations_Aggregated_Fields>;
  min?: Maybe<Car_Page_Translations_Aggregated_Fields>;
  sum?: Maybe<Car_Page_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<Car_Page_Translations_Aggregated_Fields>;
};

export type Car_Page_Translations_Aggregated_Fields = {
  __typename?: 'car_page_translations_aggregated_fields';
  car_page_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Car_Page_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Car_Page_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Car_Page_Translations_Filter>>>;
  car_page_id?: InputMaybe<Car_Page_Filter>;
  description?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
};

export type Contact_Kosovo = {
  __typename?: 'contact_kosovo';
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  id?: Maybe<Scalars['ID']>;
  top_image?: Maybe<Directus_Files>;
  translations?: Maybe<Array<Maybe<Contact_Kosovo_Translations>>>;
  user_updated?: Maybe<Scalars['String']>;
};


export type Contact_KosovoTop_ImageArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Contact_KosovoTranslationsArgs = {
  filter?: InputMaybe<Contact_Kosovo_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Contact_Kosovo_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Contact_Kosovo_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Contact_Kosovo_Filter>>>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  top_image?: InputMaybe<Directus_Files_Filter>;
  translations?: InputMaybe<Contact_Kosovo_Translations_Filter>;
  user_updated?: InputMaybe<String_Filter_Operators>;
};

export type Contact_Kosovo_Translations = {
  __typename?: 'contact_kosovo_translations';
  contact_kosovo_id?: Maybe<Contact_Kosovo>;
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  languages_code?: Maybe<Languages>;
};


export type Contact_Kosovo_TranslationsContact_Kosovo_IdArgs = {
  filter?: InputMaybe<Contact_Kosovo_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Contact_Kosovo_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Contact_Kosovo_Translations_Aggregated = {
  __typename?: 'contact_kosovo_translations_aggregated';
  avg?: Maybe<Contact_Kosovo_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<Contact_Kosovo_Translations_Aggregated_Fields>;
  count?: Maybe<Contact_Kosovo_Translations_Aggregated_Fields>;
  countDistinct?: Maybe<Contact_Kosovo_Translations_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Contact_Kosovo_Translations_Aggregated_Fields>;
  min?: Maybe<Contact_Kosovo_Translations_Aggregated_Fields>;
  sum?: Maybe<Contact_Kosovo_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<Contact_Kosovo_Translations_Aggregated_Fields>;
};

export type Contact_Kosovo_Translations_Aggregated_Fields = {
  __typename?: 'contact_kosovo_translations_aggregated_fields';
  contact_kosovo_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Contact_Kosovo_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Contact_Kosovo_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Contact_Kosovo_Translations_Filter>>>;
  contact_kosovo_id?: InputMaybe<Contact_Kosovo_Filter>;
  content?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
};

export type Contact_Macedonia = {
  __typename?: 'contact_macedonia';
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  id?: Maybe<Scalars['ID']>;
  top_image?: Maybe<Directus_Files>;
  translations?: Maybe<Array<Maybe<Contact_Macedonia_Translations>>>;
  user_updated?: Maybe<Scalars['String']>;
};


export type Contact_MacedoniaTop_ImageArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Contact_MacedoniaTranslationsArgs = {
  filter?: InputMaybe<Contact_Macedonia_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Contact_Macedonia_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Contact_Macedonia_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Contact_Macedonia_Filter>>>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  top_image?: InputMaybe<Directus_Files_Filter>;
  translations?: InputMaybe<Contact_Macedonia_Translations_Filter>;
  user_updated?: InputMaybe<String_Filter_Operators>;
};

export type Contact_Macedonia_Translations = {
  __typename?: 'contact_macedonia_translations';
  contact_macedonia_id?: Maybe<Contact_Macedonia>;
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  languages_code?: Maybe<Languages>;
};


export type Contact_Macedonia_TranslationsContact_Macedonia_IdArgs = {
  filter?: InputMaybe<Contact_Macedonia_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Contact_Macedonia_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Contact_Macedonia_Translations_Aggregated = {
  __typename?: 'contact_macedonia_translations_aggregated';
  avg?: Maybe<Contact_Macedonia_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<Contact_Macedonia_Translations_Aggregated_Fields>;
  count?: Maybe<Contact_Macedonia_Translations_Aggregated_Fields>;
  countDistinct?: Maybe<Contact_Macedonia_Translations_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Contact_Macedonia_Translations_Aggregated_Fields>;
  min?: Maybe<Contact_Macedonia_Translations_Aggregated_Fields>;
  sum?: Maybe<Contact_Macedonia_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<Contact_Macedonia_Translations_Aggregated_Fields>;
};

export type Contact_Macedonia_Translations_Aggregated_Fields = {
  __typename?: 'contact_macedonia_translations_aggregated_fields';
  contact_macedonia_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Contact_Macedonia_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Contact_Macedonia_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Contact_Macedonia_Translations_Filter>>>;
  contact_macedonia_id?: InputMaybe<Contact_Macedonia_Filter>;
  content?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
};

export type Contact_Sw = {
  __typename?: 'contact_sw';
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  id?: Maybe<Scalars['ID']>;
  top_image?: Maybe<Directus_Files>;
  translations?: Maybe<Array<Maybe<Contact_Sw_Translations>>>;
  user_updated?: Maybe<Scalars['String']>;
};


export type Contact_SwTop_ImageArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Contact_SwTranslationsArgs = {
  filter?: InputMaybe<Contact_Sw_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Contact_Sw_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Contact_Sw_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Contact_Sw_Filter>>>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  top_image?: InputMaybe<Directus_Files_Filter>;
  translations?: InputMaybe<Contact_Sw_Translations_Filter>;
  user_updated?: InputMaybe<String_Filter_Operators>;
};

export type Contact_Sw_Translations = {
  __typename?: 'contact_sw_translations';
  contact_sw_id?: Maybe<Contact_Sw>;
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  languages_code?: Maybe<Languages>;
};


export type Contact_Sw_TranslationsContact_Sw_IdArgs = {
  filter?: InputMaybe<Contact_Sw_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Contact_Sw_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Contact_Sw_Translations_Aggregated = {
  __typename?: 'contact_sw_translations_aggregated';
  avg?: Maybe<Contact_Sw_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<Contact_Sw_Translations_Aggregated_Fields>;
  count?: Maybe<Contact_Sw_Translations_Aggregated_Fields>;
  countDistinct?: Maybe<Contact_Sw_Translations_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Contact_Sw_Translations_Aggregated_Fields>;
  min?: Maybe<Contact_Sw_Translations_Aggregated_Fields>;
  sum?: Maybe<Contact_Sw_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<Contact_Sw_Translations_Aggregated_Fields>;
};

export type Contact_Sw_Translations_Aggregated_Fields = {
  __typename?: 'contact_sw_translations_aggregated_fields';
  contact_sw_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Contact_Sw_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Contact_Sw_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Contact_Sw_Translations_Filter>>>;
  contact_sw_id?: InputMaybe<Contact_Sw_Filter>;
  content?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
};

export type Data_Protection_Page = {
  __typename?: 'data_protection_page';
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  id?: Maybe<Scalars['ID']>;
  top_image?: Maybe<Directus_Files>;
  translations?: Maybe<Array<Maybe<Data_Protection_Page_Translations>>>;
  user_updated?: Maybe<Scalars['String']>;
};


export type Data_Protection_PageTop_ImageArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Data_Protection_PageTranslationsArgs = {
  filter?: InputMaybe<Data_Protection_Page_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Data_Protection_Page_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Data_Protection_Page_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Data_Protection_Page_Filter>>>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  top_image?: InputMaybe<Directus_Files_Filter>;
  translations?: InputMaybe<Data_Protection_Page_Translations_Filter>;
  user_updated?: InputMaybe<String_Filter_Operators>;
};

export type Data_Protection_Page_Translations = {
  __typename?: 'data_protection_page_translations';
  content?: Maybe<Scalars['String']>;
  data_protection_page_id?: Maybe<Data_Protection_Page>;
  id?: Maybe<Scalars['ID']>;
  languages_code?: Maybe<Languages>;
};


export type Data_Protection_Page_TranslationsData_Protection_Page_IdArgs = {
  filter?: InputMaybe<Data_Protection_Page_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Data_Protection_Page_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Data_Protection_Page_Translations_Aggregated = {
  __typename?: 'data_protection_page_translations_aggregated';
  avg?: Maybe<Data_Protection_Page_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<Data_Protection_Page_Translations_Aggregated_Fields>;
  count?: Maybe<Data_Protection_Page_Translations_Aggregated_Fields>;
  countDistinct?: Maybe<Data_Protection_Page_Translations_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Data_Protection_Page_Translations_Aggregated_Fields>;
  min?: Maybe<Data_Protection_Page_Translations_Aggregated_Fields>;
  sum?: Maybe<Data_Protection_Page_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<Data_Protection_Page_Translations_Aggregated_Fields>;
};

export type Data_Protection_Page_Translations_Aggregated_Fields = {
  __typename?: 'data_protection_page_translations_aggregated_fields';
  data_protection_page_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Data_Protection_Page_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Data_Protection_Page_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Data_Protection_Page_Translations_Filter>>>;
  content?: InputMaybe<String_Filter_Operators>;
  data_protection_page_id?: InputMaybe<Data_Protection_Page_Filter>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
};

export type Date_Filter_Operators = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  _nnull?: InputMaybe<Scalars['Boolean']>;
  _null?: InputMaybe<Scalars['Boolean']>;
};

export type Datetime_Function_Filter_Operators = {
  day?: InputMaybe<Number_Filter_Operators>;
  hour?: InputMaybe<Number_Filter_Operators>;
  minute?: InputMaybe<Number_Filter_Operators>;
  month?: InputMaybe<Number_Filter_Operators>;
  second?: InputMaybe<Number_Filter_Operators>;
  week?: InputMaybe<Number_Filter_Operators>;
  weekday?: InputMaybe<Number_Filter_Operators>;
  year?: InputMaybe<Number_Filter_Operators>;
};

export type Datetime_Functions = {
  __typename?: 'datetime_functions';
  day?: Maybe<Scalars['Int']>;
  hour?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  second?: Maybe<Scalars['Int']>;
  week?: Maybe<Scalars['Int']>;
  weekday?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type Directus_Files = {
  __typename?: 'directus_files';
  charset?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  embed?: Maybe<Scalars['String']>;
  filename_disk?: Maybe<Scalars['String']>;
  filename_download: Scalars['String'];
  filesize?: Maybe<Scalars['Int']>;
  folder?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Array<Maybe<Responsive_Image>>>;
  location?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
  modified_by?: Maybe<Scalars['String']>;
  modified_on: Scalars['Date'];
  modified_on_func?: Maybe<Datetime_Functions>;
  storage: Scalars['String'];
  tags?: Maybe<Scalars['JSON']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uploaded_by?: Maybe<Scalars['String']>;
  uploaded_on: Scalars['Date'];
  uploaded_on_func?: Maybe<Datetime_Functions>;
  width?: Maybe<Scalars['Int']>;
};


export type Directus_FilesImageArgs = {
  filter?: InputMaybe<Responsive_Image_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Directus_Files_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Directus_Files_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Directus_Files_Filter>>>;
  charset?: InputMaybe<String_Filter_Operators>;
  description?: InputMaybe<String_Filter_Operators>;
  duration?: InputMaybe<Number_Filter_Operators>;
  embed?: InputMaybe<String_Filter_Operators>;
  filename_disk?: InputMaybe<String_Filter_Operators>;
  filename_download?: InputMaybe<String_Filter_Operators>;
  filesize?: InputMaybe<Number_Filter_Operators>;
  folder?: InputMaybe<String_Filter_Operators>;
  height?: InputMaybe<Number_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  image?: InputMaybe<Responsive_Image_Filter>;
  location?: InputMaybe<String_Filter_Operators>;
  metadata?: InputMaybe<String_Filter_Operators>;
  modified_by?: InputMaybe<String_Filter_Operators>;
  modified_on?: InputMaybe<Date_Filter_Operators>;
  modified_on_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  storage?: InputMaybe<String_Filter_Operators>;
  tags?: InputMaybe<String_Filter_Operators>;
  title?: InputMaybe<String_Filter_Operators>;
  type?: InputMaybe<String_Filter_Operators>;
  uploaded_by?: InputMaybe<String_Filter_Operators>;
  uploaded_on?: InputMaybe<Date_Filter_Operators>;
  uploaded_on_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  width?: InputMaybe<Number_Filter_Operators>;
};

export type Flights_Page = {
  __typename?: 'flights_page';
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  id?: Maybe<Scalars['ID']>;
  top_image?: Maybe<Directus_Files>;
  translations?: Maybe<Array<Maybe<Flights_Page_Translations>>>;
  user_updated?: Maybe<Scalars['String']>;
};


export type Flights_PageTop_ImageArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Flights_PageTranslationsArgs = {
  filter?: InputMaybe<Flights_Page_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Flights_Page_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Flights_Page_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Flights_Page_Filter>>>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  top_image?: InputMaybe<Directus_Files_Filter>;
  translations?: InputMaybe<Flights_Page_Translations_Filter>;
  user_updated?: InputMaybe<String_Filter_Operators>;
};

export type Flights_Page_Translations = {
  __typename?: 'flights_page_translations';
  content?: Maybe<Scalars['String']>;
  flights_page_id?: Maybe<Flights_Page>;
  id?: Maybe<Scalars['ID']>;
  languages_code?: Maybe<Languages>;
};


export type Flights_Page_TranslationsFlights_Page_IdArgs = {
  filter?: InputMaybe<Flights_Page_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Flights_Page_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Flights_Page_Translations_Aggregated = {
  __typename?: 'flights_page_translations_aggregated';
  avg?: Maybe<Flights_Page_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<Flights_Page_Translations_Aggregated_Fields>;
  count?: Maybe<Flights_Page_Translations_Aggregated_Fields>;
  countDistinct?: Maybe<Flights_Page_Translations_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Flights_Page_Translations_Aggregated_Fields>;
  min?: Maybe<Flights_Page_Translations_Aggregated_Fields>;
  sum?: Maybe<Flights_Page_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<Flights_Page_Translations_Aggregated_Fields>;
};

export type Flights_Page_Translations_Aggregated_Fields = {
  __typename?: 'flights_page_translations_aggregated_fields';
  flights_page_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Flights_Page_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Flights_Page_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Flights_Page_Translations_Filter>>>;
  content?: InputMaybe<String_Filter_Operators>;
  flights_page_id?: InputMaybe<Flights_Page_Filter>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
};

export type Health_Page = {
  __typename?: 'health_page';
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  id?: Maybe<Scalars['ID']>;
  top_image?: Maybe<Directus_Files>;
  translations?: Maybe<Array<Maybe<Health_Page_Translations>>>;
  user_updated?: Maybe<Scalars['String']>;
};


export type Health_PageTop_ImageArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Health_PageTranslationsArgs = {
  filter?: InputMaybe<Health_Page_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Health_Page_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Health_Page_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Health_Page_Filter>>>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  top_image?: InputMaybe<Directus_Files_Filter>;
  translations?: InputMaybe<Health_Page_Translations_Filter>;
  user_updated?: InputMaybe<String_Filter_Operators>;
};

export type Health_Page_Translations = {
  __typename?: 'health_page_translations';
  content?: Maybe<Scalars['String']>;
  health_page_id?: Maybe<Health_Page>;
  id?: Maybe<Scalars['ID']>;
  languages_code?: Maybe<Languages>;
};


export type Health_Page_TranslationsHealth_Page_IdArgs = {
  filter?: InputMaybe<Health_Page_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Health_Page_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Health_Page_Translations_Aggregated = {
  __typename?: 'health_page_translations_aggregated';
  avg?: Maybe<Health_Page_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<Health_Page_Translations_Aggregated_Fields>;
  count?: Maybe<Health_Page_Translations_Aggregated_Fields>;
  countDistinct?: Maybe<Health_Page_Translations_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Health_Page_Translations_Aggregated_Fields>;
  min?: Maybe<Health_Page_Translations_Aggregated_Fields>;
  sum?: Maybe<Health_Page_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<Health_Page_Translations_Aggregated_Fields>;
};

export type Health_Page_Translations_Aggregated_Fields = {
  __typename?: 'health_page_translations_aggregated_fields';
  health_page_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Health_Page_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Health_Page_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Health_Page_Translations_Filter>>>;
  content?: InputMaybe<String_Filter_Operators>;
  health_page_id?: InputMaybe<Health_Page_Filter>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
};

export type History_Card = {
  __typename?: 'history_card';
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  id?: Maybe<Scalars['ID']>;
  photo?: Maybe<Directus_Files>;
  sort_year?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  translations?: Maybe<Array<Maybe<History_Card_Translations>>>;
  user_updated?: Maybe<Scalars['String']>;
};


export type History_CardPhotoArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type History_CardTranslationsArgs = {
  filter?: InputMaybe<History_Card_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type History_Card_Aggregated = {
  __typename?: 'history_card_aggregated';
  avg?: Maybe<History_Card_Aggregated_Fields>;
  avgDistinct?: Maybe<History_Card_Aggregated_Fields>;
  count?: Maybe<History_Card_Aggregated_Fields>;
  countDistinct?: Maybe<History_Card_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<History_Card_Aggregated_Fields>;
  min?: Maybe<History_Card_Aggregated_Fields>;
  sum?: Maybe<History_Card_Aggregated_Fields>;
  sumDistinct?: Maybe<History_Card_Aggregated_Fields>;
};

export type History_Card_Aggregated_Fields = {
  __typename?: 'history_card_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  sort_year?: Maybe<Scalars['Float']>;
};

export type History_Card_Filter = {
  _and?: InputMaybe<Array<InputMaybe<History_Card_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<History_Card_Filter>>>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  photo?: InputMaybe<Directus_Files_Filter>;
  sort_year?: InputMaybe<Number_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  title?: InputMaybe<String_Filter_Operators>;
  translations?: InputMaybe<History_Card_Translations_Filter>;
  user_updated?: InputMaybe<String_Filter_Operators>;
};

export type History_Card_Translations = {
  __typename?: 'history_card_translations';
  direction?: Maybe<Scalars['String']>;
  history_card_id?: Maybe<History_Card>;
  id?: Maybe<Scalars['ID']>;
  languages_code?: Maybe<Languages>;
  period?: Maybe<Scalars['String']>;
};


export type History_Card_TranslationsHistory_Card_IdArgs = {
  filter?: InputMaybe<History_Card_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type History_Card_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type History_Card_Translations_Aggregated = {
  __typename?: 'history_card_translations_aggregated';
  avg?: Maybe<History_Card_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<History_Card_Translations_Aggregated_Fields>;
  count?: Maybe<History_Card_Translations_Aggregated_Fields>;
  countDistinct?: Maybe<History_Card_Translations_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<History_Card_Translations_Aggregated_Fields>;
  min?: Maybe<History_Card_Translations_Aggregated_Fields>;
  sum?: Maybe<History_Card_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<History_Card_Translations_Aggregated_Fields>;
};

export type History_Card_Translations_Aggregated_Fields = {
  __typename?: 'history_card_translations_aggregated_fields';
  history_card_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type History_Card_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<History_Card_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<History_Card_Translations_Filter>>>;
  direction?: InputMaybe<String_Filter_Operators>;
  history_card_id?: InputMaybe<History_Card_Filter>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
  period?: InputMaybe<String_Filter_Operators>;
};

export type History_Page = {
  __typename?: 'history_page';
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  id?: Maybe<Scalars['ID']>;
  top_image?: Maybe<Directus_Files>;
  translations?: Maybe<Array<Maybe<History_Page_Translations>>>;
  user_updated?: Maybe<Scalars['String']>;
};


export type History_PageTop_ImageArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type History_PageTranslationsArgs = {
  filter?: InputMaybe<History_Page_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type History_Page_Filter = {
  _and?: InputMaybe<Array<InputMaybe<History_Page_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<History_Page_Filter>>>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  top_image?: InputMaybe<Directus_Files_Filter>;
  translations?: InputMaybe<History_Page_Translations_Filter>;
  user_updated?: InputMaybe<String_Filter_Operators>;
};

export type History_Page_Translations = {
  __typename?: 'history_page_translations';
  content?: Maybe<Scalars['String']>;
  history_page_id?: Maybe<History_Page>;
  id?: Maybe<Scalars['ID']>;
  languages_code?: Maybe<Languages>;
};


export type History_Page_TranslationsHistory_Page_IdArgs = {
  filter?: InputMaybe<History_Page_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type History_Page_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type History_Page_Translations_Aggregated = {
  __typename?: 'history_page_translations_aggregated';
  avg?: Maybe<History_Page_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<History_Page_Translations_Aggregated_Fields>;
  count?: Maybe<History_Page_Translations_Aggregated_Fields>;
  countDistinct?: Maybe<History_Page_Translations_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<History_Page_Translations_Aggregated_Fields>;
  min?: Maybe<History_Page_Translations_Aggregated_Fields>;
  sum?: Maybe<History_Page_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<History_Page_Translations_Aggregated_Fields>;
};

export type History_Page_Translations_Aggregated_Fields = {
  __typename?: 'history_page_translations_aggregated_fields';
  history_page_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type History_Page_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<History_Page_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<History_Page_Translations_Filter>>>;
  content?: InputMaybe<String_Filter_Operators>;
  history_page_id?: InputMaybe<History_Page_Filter>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
};

export type Home_Page_Advertisements = {
  __typename?: 'home_page_advertisements';
  collection?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  item?: Maybe<Home_Page_Advertisements_Item_Union>;
};

export type Home_Page_Advertisements_Aggregated = {
  __typename?: 'home_page_advertisements_aggregated';
  avg?: Maybe<Home_Page_Advertisements_Aggregated_Fields>;
  avgDistinct?: Maybe<Home_Page_Advertisements_Aggregated_Fields>;
  count?: Maybe<Home_Page_Advertisements_Aggregated_Fields>;
  countDistinct?: Maybe<Home_Page_Advertisements_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Home_Page_Advertisements_Aggregated_Fields>;
  min?: Maybe<Home_Page_Advertisements_Aggregated_Fields>;
  sum?: Maybe<Home_Page_Advertisements_Aggregated_Fields>;
  sumDistinct?: Maybe<Home_Page_Advertisements_Aggregated_Fields>;
};

export type Home_Page_Advertisements_Aggregated_Fields = {
  __typename?: 'home_page_advertisements_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Home_Page_Advertisements_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Home_Page_Advertisements_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Home_Page_Advertisements_Filter>>>;
  collection?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  item?: InputMaybe<String_Filter_Operators>;
};

export type Home_Page_Advertisements_Item_Union = Advertisements_Card;

export type Home_Page_Offers_Slides = {
  __typename?: 'home_page_offers_slides';
  collection?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  item?: Maybe<Home_Page_Offers_Slides_Item_Union>;
};

export type Home_Page_Offers_Slides_Aggregated = {
  __typename?: 'home_page_offers_slides_aggregated';
  avg?: Maybe<Home_Page_Offers_Slides_Aggregated_Fields>;
  avgDistinct?: Maybe<Home_Page_Offers_Slides_Aggregated_Fields>;
  count?: Maybe<Home_Page_Offers_Slides_Aggregated_Fields>;
  countDistinct?: Maybe<Home_Page_Offers_Slides_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Home_Page_Offers_Slides_Aggregated_Fields>;
  min?: Maybe<Home_Page_Offers_Slides_Aggregated_Fields>;
  sum?: Maybe<Home_Page_Offers_Slides_Aggregated_Fields>;
  sumDistinct?: Maybe<Home_Page_Offers_Slides_Aggregated_Fields>;
};

export type Home_Page_Offers_Slides_Aggregated_Fields = {
  __typename?: 'home_page_offers_slides_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Home_Page_Offers_Slides_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Home_Page_Offers_Slides_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Home_Page_Offers_Slides_Filter>>>;
  collection?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  item?: InputMaybe<String_Filter_Operators>;
};

export type Home_Page_Offers_Slides_Item_Union = Spesical_Offer;

export type Hotels_Page = {
  __typename?: 'hotels_page';
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  id?: Maybe<Scalars['ID']>;
  top_image?: Maybe<Directus_Files>;
  translations?: Maybe<Array<Maybe<Hotels_Page_Translations>>>;
  user_updated?: Maybe<Scalars['String']>;
};


export type Hotels_PageTop_ImageArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Hotels_PageTranslationsArgs = {
  filter?: InputMaybe<Hotels_Page_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Hotels_Page_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Hotels_Page_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Hotels_Page_Filter>>>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  top_image?: InputMaybe<Directus_Files_Filter>;
  translations?: InputMaybe<Hotels_Page_Translations_Filter>;
  user_updated?: InputMaybe<String_Filter_Operators>;
};

export type Hotels_Page_Translations = {
  __typename?: 'hotels_page_translations';
  content?: Maybe<Scalars['String']>;
  hotels_page_id?: Maybe<Hotels_Page>;
  id?: Maybe<Scalars['ID']>;
  languages_code?: Maybe<Languages>;
};


export type Hotels_Page_TranslationsHotels_Page_IdArgs = {
  filter?: InputMaybe<Hotels_Page_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Hotels_Page_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Hotels_Page_Translations_Aggregated = {
  __typename?: 'hotels_page_translations_aggregated';
  avg?: Maybe<Hotels_Page_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<Hotels_Page_Translations_Aggregated_Fields>;
  count?: Maybe<Hotels_Page_Translations_Aggregated_Fields>;
  countDistinct?: Maybe<Hotels_Page_Translations_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Hotels_Page_Translations_Aggregated_Fields>;
  min?: Maybe<Hotels_Page_Translations_Aggregated_Fields>;
  sum?: Maybe<Hotels_Page_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<Hotels_Page_Translations_Aggregated_Fields>;
};

export type Hotels_Page_Translations_Aggregated_Fields = {
  __typename?: 'hotels_page_translations_aggregated_fields';
  hotels_page_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Hotels_Page_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Hotels_Page_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Hotels_Page_Translations_Filter>>>;
  content?: InputMaybe<String_Filter_Operators>;
  hotels_page_id?: InputMaybe<Hotels_Page_Filter>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
};

export type Imprint_Page = {
  __typename?: 'imprint_page';
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  id?: Maybe<Scalars['ID']>;
  top_image?: Maybe<Directus_Files>;
  translations?: Maybe<Array<Maybe<Imprint_Page_Translations>>>;
  user_updated?: Maybe<Scalars['String']>;
};


export type Imprint_PageTop_ImageArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Imprint_PageTranslationsArgs = {
  filter?: InputMaybe<Imprint_Page_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Imprint_Page_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Imprint_Page_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Imprint_Page_Filter>>>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  top_image?: InputMaybe<Directus_Files_Filter>;
  translations?: InputMaybe<Imprint_Page_Translations_Filter>;
  user_updated?: InputMaybe<String_Filter_Operators>;
};

export type Imprint_Page_Translations = {
  __typename?: 'imprint_page_translations';
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  imprint_page_id?: Maybe<Imprint_Page>;
  languages_code?: Maybe<Languages>;
};


export type Imprint_Page_TranslationsImprint_Page_IdArgs = {
  filter?: InputMaybe<Imprint_Page_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Imprint_Page_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Imprint_Page_Translations_Aggregated = {
  __typename?: 'imprint_page_translations_aggregated';
  avg?: Maybe<Imprint_Page_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<Imprint_Page_Translations_Aggregated_Fields>;
  count?: Maybe<Imprint_Page_Translations_Aggregated_Fields>;
  countDistinct?: Maybe<Imprint_Page_Translations_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Imprint_Page_Translations_Aggregated_Fields>;
  min?: Maybe<Imprint_Page_Translations_Aggregated_Fields>;
  sum?: Maybe<Imprint_Page_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<Imprint_Page_Translations_Aggregated_Fields>;
};

export type Imprint_Page_Translations_Aggregated_Fields = {
  __typename?: 'imprint_page_translations_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  imprint_page_id?: Maybe<Scalars['Float']>;
};

export type Imprint_Page_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Imprint_Page_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Imprint_Page_Translations_Filter>>>;
  content?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  imprint_page_id?: InputMaybe<Imprint_Page_Filter>;
  languages_code?: InputMaybe<Languages_Filter>;
};

export type Languages = {
  __typename?: 'languages';
  code?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type Languages_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Languages_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Languages_Filter>>>;
  code?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Location_Page = {
  __typename?: 'location_page';
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  id?: Maybe<Scalars['ID']>;
  top_image?: Maybe<Directus_Files>;
  translations?: Maybe<Array<Maybe<Location_Page_Translations>>>;
  user_updated?: Maybe<Scalars['String']>;
};


export type Location_PageTop_ImageArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Location_PageTranslationsArgs = {
  filter?: InputMaybe<Location_Page_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Location_Page_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Location_Page_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Location_Page_Filter>>>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  top_image?: InputMaybe<Directus_Files_Filter>;
  translations?: InputMaybe<Location_Page_Translations_Filter>;
  user_updated?: InputMaybe<String_Filter_Operators>;
};

export type Location_Page_Translations = {
  __typename?: 'location_page_translations';
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  languages_code?: Maybe<Languages>;
  location_page_id?: Maybe<Location_Page>;
};


export type Location_Page_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Location_Page_TranslationsLocation_Page_IdArgs = {
  filter?: InputMaybe<Location_Page_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Location_Page_Translations_Aggregated = {
  __typename?: 'location_page_translations_aggregated';
  avg?: Maybe<Location_Page_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<Location_Page_Translations_Aggregated_Fields>;
  count?: Maybe<Location_Page_Translations_Aggregated_Fields>;
  countDistinct?: Maybe<Location_Page_Translations_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Location_Page_Translations_Aggregated_Fields>;
  min?: Maybe<Location_Page_Translations_Aggregated_Fields>;
  sum?: Maybe<Location_Page_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<Location_Page_Translations_Aggregated_Fields>;
};

export type Location_Page_Translations_Aggregated_Fields = {
  __typename?: 'location_page_translations_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  location_page_id?: Maybe<Scalars['Float']>;
};

export type Location_Page_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Location_Page_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Location_Page_Translations_Filter>>>;
  content?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
  location_page_id?: InputMaybe<Location_Page_Filter>;
};

export type Management_Card = {
  __typename?: 'management_card';
  avatar?: Maybe<Directus_Files>;
  display_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  phone?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  translations?: Maybe<Array<Maybe<Management_Card_Translations>>>;
};


export type Management_CardAvatarArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Management_CardTranslationsArgs = {
  filter?: InputMaybe<Management_Card_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Management_Card_Aggregated = {
  __typename?: 'management_card_aggregated';
  avg?: Maybe<Management_Card_Aggregated_Fields>;
  avgDistinct?: Maybe<Management_Card_Aggregated_Fields>;
  count?: Maybe<Management_Card_Aggregated_Fields>;
  countDistinct?: Maybe<Management_Card_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Management_Card_Aggregated_Fields>;
  min?: Maybe<Management_Card_Aggregated_Fields>;
  sum?: Maybe<Management_Card_Aggregated_Fields>;
  sumDistinct?: Maybe<Management_Card_Aggregated_Fields>;
};

export type Management_Card_Aggregated_Fields = {
  __typename?: 'management_card_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

export type Management_Card_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Management_Card_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Management_Card_Filter>>>;
  avatar?: InputMaybe<Directus_Files_Filter>;
  display_name?: InputMaybe<String_Filter_Operators>;
  email?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  phone?: InputMaybe<String_Filter_Operators>;
  sort?: InputMaybe<Number_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  translations?: InputMaybe<Management_Card_Translations_Filter>;
};

export type Management_Card_Translations = {
  __typename?: 'management_card_translations';
  email_title?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  languages_code?: Maybe<Languages>;
  management_card_id?: Maybe<Management_Card>;
  phone_title?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};


export type Management_Card_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Management_Card_TranslationsManagement_Card_IdArgs = {
  filter?: InputMaybe<Management_Card_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Management_Card_Translations_Aggregated = {
  __typename?: 'management_card_translations_aggregated';
  avg?: Maybe<Management_Card_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<Management_Card_Translations_Aggregated_Fields>;
  count?: Maybe<Management_Card_Translations_Aggregated_Fields>;
  countDistinct?: Maybe<Management_Card_Translations_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Management_Card_Translations_Aggregated_Fields>;
  min?: Maybe<Management_Card_Translations_Aggregated_Fields>;
  sum?: Maybe<Management_Card_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<Management_Card_Translations_Aggregated_Fields>;
};

export type Management_Card_Translations_Aggregated_Fields = {
  __typename?: 'management_card_translations_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  management_card_id?: Maybe<Scalars['Float']>;
};

export type Management_Card_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Management_Card_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Management_Card_Translations_Filter>>>;
  email_title?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
  management_card_id?: InputMaybe<Management_Card_Filter>;
  phone_title?: InputMaybe<String_Filter_Operators>;
  role?: InputMaybe<String_Filter_Operators>;
};

export type Management_Page = {
  __typename?: 'management_page';
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  id?: Maybe<Scalars['ID']>;
  status: Scalars['String'];
  top_image?: Maybe<Directus_Files>;
  translations?: Maybe<Array<Maybe<Management_Page_Translations>>>;
  user_updated?: Maybe<Scalars['String']>;
};


export type Management_PageTop_ImageArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Management_PageTranslationsArgs = {
  filter?: InputMaybe<Management_Page_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Management_Page_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Management_Page_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Management_Page_Filter>>>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  top_image?: InputMaybe<Directus_Files_Filter>;
  translations?: InputMaybe<Management_Page_Translations_Filter>;
  user_updated?: InputMaybe<String_Filter_Operators>;
};

export type Management_Page_Translations = {
  __typename?: 'management_page_translations';
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  languages_code?: Maybe<Languages>;
  management_page_id?: Maybe<Management_Page>;
};


export type Management_Page_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Management_Page_TranslationsManagement_Page_IdArgs = {
  filter?: InputMaybe<Management_Page_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Management_Page_Translations_Aggregated = {
  __typename?: 'management_page_translations_aggregated';
  avg?: Maybe<Management_Page_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<Management_Page_Translations_Aggregated_Fields>;
  count?: Maybe<Management_Page_Translations_Aggregated_Fields>;
  countDistinct?: Maybe<Management_Page_Translations_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Management_Page_Translations_Aggregated_Fields>;
  min?: Maybe<Management_Page_Translations_Aggregated_Fields>;
  sum?: Maybe<Management_Page_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<Management_Page_Translations_Aggregated_Fields>;
};

export type Management_Page_Translations_Aggregated_Fields = {
  __typename?: 'management_page_translations_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  management_page_id?: Maybe<Scalars['Float']>;
};

export type Management_Page_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Management_Page_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Management_Page_Translations_Filter>>>;
  content?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
  management_page_id?: InputMaybe<Management_Page_Filter>;
};

export type Milestones = {
  __typename?: 'milestones';
  id?: Maybe<Scalars['ID']>;
  value?: Maybe<Scalars['Int']>;
};

export type Milestones_Aggregated = {
  __typename?: 'milestones_aggregated';
  avg?: Maybe<Milestones_Aggregated_Fields>;
  avgDistinct?: Maybe<Milestones_Aggregated_Fields>;
  count?: Maybe<Milestones_Aggregated_Fields>;
  countDistinct?: Maybe<Milestones_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Milestones_Aggregated_Fields>;
  min?: Maybe<Milestones_Aggregated_Fields>;
  sum?: Maybe<Milestones_Aggregated_Fields>;
  sumDistinct?: Maybe<Milestones_Aggregated_Fields>;
};

export type Milestones_Aggregated_Fields = {
  __typename?: 'milestones_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

export type Milestones_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Milestones_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Milestones_Filter>>>;
  id?: InputMaybe<Number_Filter_Operators>;
  value?: InputMaybe<Number_Filter_Operators>;
};

export type News = {
  __typename?: 'news';
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  expiredAt?: Maybe<Scalars['Date']>;
  expiredAt_func?: Maybe<Datetime_Functions>;
  id?: Maybe<Scalars['ID']>;
  status: Scalars['String'];
  translations?: Maybe<Array<Maybe<News_Translations>>>;
  user_created?: Maybe<Scalars['String']>;
  user_updated?: Maybe<Scalars['String']>;
};


export type NewsTranslationsArgs = {
  filter?: InputMaybe<News_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type News_Aggregated = {
  __typename?: 'news_aggregated';
  avg?: Maybe<News_Aggregated_Fields>;
  avgDistinct?: Maybe<News_Aggregated_Fields>;
  count?: Maybe<News_Aggregated_Fields>;
  countDistinct?: Maybe<News_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<News_Aggregated_Fields>;
  min?: Maybe<News_Aggregated_Fields>;
  sum?: Maybe<News_Aggregated_Fields>;
  sumDistinct?: Maybe<News_Aggregated_Fields>;
};

export type News_Aggregated_Fields = {
  __typename?: 'news_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
};

export type News_Block = {
  __typename?: 'news_block';
  active_news?: Maybe<News>;
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  id?: Maybe<Scalars['ID']>;
  translations?: Maybe<Array<Maybe<News_Block_Translations>>>;
  user_updated?: Maybe<Scalars['String']>;
};


export type News_BlockActive_NewsArgs = {
  filter?: InputMaybe<News_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type News_BlockTranslationsArgs = {
  filter?: InputMaybe<News_Block_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type News_Block_Filter = {
  _and?: InputMaybe<Array<InputMaybe<News_Block_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<News_Block_Filter>>>;
  active_news?: InputMaybe<News_Filter>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  translations?: InputMaybe<News_Block_Translations_Filter>;
  user_updated?: InputMaybe<String_Filter_Operators>;
};

export type News_Block_Translations = {
  __typename?: 'news_block_translations';
  id?: Maybe<Scalars['ID']>;
  languages_code?: Maybe<Languages>;
  news_block_id?: Maybe<News_Block>;
  title?: Maybe<Scalars['String']>;
};


export type News_Block_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type News_Block_TranslationsNews_Block_IdArgs = {
  filter?: InputMaybe<News_Block_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type News_Block_Translations_Aggregated = {
  __typename?: 'news_block_translations_aggregated';
  avg?: Maybe<News_Block_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<News_Block_Translations_Aggregated_Fields>;
  count?: Maybe<News_Block_Translations_Aggregated_Fields>;
  countDistinct?: Maybe<News_Block_Translations_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<News_Block_Translations_Aggregated_Fields>;
  min?: Maybe<News_Block_Translations_Aggregated_Fields>;
  sum?: Maybe<News_Block_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<News_Block_Translations_Aggregated_Fields>;
};

export type News_Block_Translations_Aggregated_Fields = {
  __typename?: 'news_block_translations_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  news_block_id?: Maybe<Scalars['Float']>;
};

export type News_Block_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<News_Block_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<News_Block_Translations_Filter>>>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
  news_block_id?: InputMaybe<News_Block_Filter>;
  title?: InputMaybe<String_Filter_Operators>;
};

export type News_Filter = {
  _and?: InputMaybe<Array<InputMaybe<News_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<News_Filter>>>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  expiredAt?: InputMaybe<Date_Filter_Operators>;
  expiredAt_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  translations?: InputMaybe<News_Translations_Filter>;
  user_created?: InputMaybe<String_Filter_Operators>;
  user_updated?: InputMaybe<String_Filter_Operators>;
};

export type News_Translations = {
  __typename?: 'news_translations';
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  languages_code?: Maybe<Languages>;
  news_id?: Maybe<News>;
  title?: Maybe<Scalars['String']>;
};


export type News_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type News_TranslationsNews_IdArgs = {
  filter?: InputMaybe<News_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type News_Translations_Aggregated = {
  __typename?: 'news_translations_aggregated';
  avg?: Maybe<News_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<News_Translations_Aggregated_Fields>;
  count?: Maybe<News_Translations_Aggregated_Fields>;
  countDistinct?: Maybe<News_Translations_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<News_Translations_Aggregated_Fields>;
  min?: Maybe<News_Translations_Aggregated_Fields>;
  sum?: Maybe<News_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<News_Translations_Aggregated_Fields>;
};

export type News_Translations_Aggregated_Fields = {
  __typename?: 'news_translations_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  news_id?: Maybe<Scalars['Float']>;
};

export type News_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<News_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<News_Translations_Filter>>>;
  content?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
  news_id?: InputMaybe<News_Filter>;
  title?: InputMaybe<String_Filter_Operators>;
};

export type Number_Filter_Operators = {
  _eq?: InputMaybe<Scalars['Float']>;
  _gt?: InputMaybe<Scalars['Float']>;
  _gte?: InputMaybe<Scalars['Float']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  _lt?: InputMaybe<Scalars['Float']>;
  _lte?: InputMaybe<Scalars['Float']>;
  _neq?: InputMaybe<Scalars['Float']>;
  _nin?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  _nnull?: InputMaybe<Scalars['Boolean']>;
  _null?: InputMaybe<Scalars['Boolean']>;
};

export type Partner_Card = {
  __typename?: 'partner_card';
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Directus_Files>;
  sort?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  user_updated?: Maybe<Scalars['String']>;
};


export type Partner_CardImageArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Partner_Card_Aggregated = {
  __typename?: 'partner_card_aggregated';
  avg?: Maybe<Partner_Card_Aggregated_Fields>;
  avgDistinct?: Maybe<Partner_Card_Aggregated_Fields>;
  count?: Maybe<Partner_Card_Aggregated_Fields>;
  countDistinct?: Maybe<Partner_Card_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Partner_Card_Aggregated_Fields>;
  min?: Maybe<Partner_Card_Aggregated_Fields>;
  sum?: Maybe<Partner_Card_Aggregated_Fields>;
  sumDistinct?: Maybe<Partner_Card_Aggregated_Fields>;
};

export type Partner_Card_Aggregated_Fields = {
  __typename?: 'partner_card_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

export type Partner_Card_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Partner_Card_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Partner_Card_Filter>>>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  image?: InputMaybe<Directus_Files_Filter>;
  sort?: InputMaybe<Number_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  title?: InputMaybe<String_Filter_Operators>;
  user_updated?: InputMaybe<String_Filter_Operators>;
};

export type Partners_Page = {
  __typename?: 'partners_page';
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  id?: Maybe<Scalars['ID']>;
  top_image?: Maybe<Directus_Files>;
  translations?: Maybe<Array<Maybe<Partners_Page_Translations>>>;
  user_updated?: Maybe<Scalars['String']>;
};


export type Partners_PageTop_ImageArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Partners_PageTranslationsArgs = {
  filter?: InputMaybe<Partners_Page_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Partners_Page_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Partners_Page_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Partners_Page_Filter>>>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  top_image?: InputMaybe<Directus_Files_Filter>;
  translations?: InputMaybe<Partners_Page_Translations_Filter>;
  user_updated?: InputMaybe<String_Filter_Operators>;
};

export type Partners_Page_Translations = {
  __typename?: 'partners_page_translations';
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  languages_code?: Maybe<Languages>;
  partners_page_id?: Maybe<Partners_Page>;
};


export type Partners_Page_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Partners_Page_TranslationsPartners_Page_IdArgs = {
  filter?: InputMaybe<Partners_Page_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Partners_Page_Translations_Aggregated = {
  __typename?: 'partners_page_translations_aggregated';
  avg?: Maybe<Partners_Page_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<Partners_Page_Translations_Aggregated_Fields>;
  count?: Maybe<Partners_Page_Translations_Aggregated_Fields>;
  countDistinct?: Maybe<Partners_Page_Translations_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Partners_Page_Translations_Aggregated_Fields>;
  min?: Maybe<Partners_Page_Translations_Aggregated_Fields>;
  sum?: Maybe<Partners_Page_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<Partners_Page_Translations_Aggregated_Fields>;
};

export type Partners_Page_Translations_Aggregated_Fields = {
  __typename?: 'partners_page_translations_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  partners_page_id?: Maybe<Scalars['Float']>;
};

export type Partners_Page_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Partners_Page_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Partners_Page_Translations_Filter>>>;
  content?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
  partners_page_id?: InputMaybe<Partners_Page_Filter>;
};

export type Responsive_Image = {
  __typename?: 'responsive_image';
  desktop?: Maybe<Directus_Files>;
  id?: Maybe<Scalars['ID']>;
  mobile?: Maybe<Directus_Files>;
  tablet?: Maybe<Directus_Files>;
};


export type Responsive_ImageDesktopArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Responsive_ImageMobileArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Responsive_ImageTabletArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Responsive_Image_Aggregated = {
  __typename?: 'responsive_image_aggregated';
  avg?: Maybe<Responsive_Image_Aggregated_Fields>;
  avgDistinct?: Maybe<Responsive_Image_Aggregated_Fields>;
  count?: Maybe<Responsive_Image_Aggregated_Fields>;
  countDistinct?: Maybe<Responsive_Image_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Responsive_Image_Aggregated_Fields>;
  min?: Maybe<Responsive_Image_Aggregated_Fields>;
  sum?: Maybe<Responsive_Image_Aggregated_Fields>;
  sumDistinct?: Maybe<Responsive_Image_Aggregated_Fields>;
};

export type Responsive_Image_Aggregated_Fields = {
  __typename?: 'responsive_image_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Responsive_Image_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Responsive_Image_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Responsive_Image_Filter>>>;
  desktop?: InputMaybe<Directus_Files_Filter>;
  id?: InputMaybe<Number_Filter_Operators>;
  mobile?: InputMaybe<Directus_Files_Filter>;
  tablet?: InputMaybe<Directus_Files_Filter>;
};

export type Safety_Page = {
  __typename?: 'safety_page';
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  id?: Maybe<Scalars['ID']>;
  top_image?: Maybe<Directus_Files>;
  translations?: Maybe<Array<Maybe<Safety_Page_Translations>>>;
  user_updated?: Maybe<Scalars['String']>;
};


export type Safety_PageTop_ImageArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Safety_PageTranslationsArgs = {
  filter?: InputMaybe<Safety_Page_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Safety_Page_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Safety_Page_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Safety_Page_Filter>>>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  top_image?: InputMaybe<Directus_Files_Filter>;
  translations?: InputMaybe<Safety_Page_Translations_Filter>;
  user_updated?: InputMaybe<String_Filter_Operators>;
};

export type Safety_Page_Translations = {
  __typename?: 'safety_page_translations';
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  languages_code?: Maybe<Languages>;
  safety_page_id?: Maybe<Safety_Page>;
};


export type Safety_Page_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Safety_Page_TranslationsSafety_Page_IdArgs = {
  filter?: InputMaybe<Safety_Page_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Safety_Page_Translations_Aggregated = {
  __typename?: 'safety_page_translations_aggregated';
  avg?: Maybe<Safety_Page_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<Safety_Page_Translations_Aggregated_Fields>;
  count?: Maybe<Safety_Page_Translations_Aggregated_Fields>;
  countDistinct?: Maybe<Safety_Page_Translations_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Safety_Page_Translations_Aggregated_Fields>;
  min?: Maybe<Safety_Page_Translations_Aggregated_Fields>;
  sum?: Maybe<Safety_Page_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<Safety_Page_Translations_Aggregated_Fields>;
};

export type Safety_Page_Translations_Aggregated_Fields = {
  __typename?: 'safety_page_translations_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  safety_page_id?: Maybe<Scalars['Float']>;
};

export type Safety_Page_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Safety_Page_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Safety_Page_Translations_Filter>>>;
  content?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
  safety_page_id?: InputMaybe<Safety_Page_Filter>;
};

export type Spesical_Offer = {
  __typename?: 'spesical_offer';
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  destination?: Maybe<Airport>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Directus_Files>;
  origin?: Maybe<Airport>;
  sort?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  translations?: Maybe<Array<Maybe<Spesical_Offer_Translations>>>;
  user_created?: Maybe<Scalars['String']>;
  user_updated?: Maybe<Scalars['String']>;
};


export type Spesical_OfferDestinationArgs = {
  filter?: InputMaybe<Airport_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Spesical_OfferImageArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Spesical_OfferOriginArgs = {
  filter?: InputMaybe<Airport_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Spesical_OfferTranslationsArgs = {
  filter?: InputMaybe<Spesical_Offer_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Spesical_Offer_Aggregated = {
  __typename?: 'spesical_offer_aggregated';
  avg?: Maybe<Spesical_Offer_Aggregated_Fields>;
  avgDistinct?: Maybe<Spesical_Offer_Aggregated_Fields>;
  count?: Maybe<Spesical_Offer_Aggregated_Fields>;
  countDistinct?: Maybe<Spesical_Offer_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Spesical_Offer_Aggregated_Fields>;
  min?: Maybe<Spesical_Offer_Aggregated_Fields>;
  sum?: Maybe<Spesical_Offer_Aggregated_Fields>;
  sumDistinct?: Maybe<Spesical_Offer_Aggregated_Fields>;
};

export type Spesical_Offer_Aggregated_Fields = {
  __typename?: 'spesical_offer_aggregated_fields';
  destination?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  origin?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

export type Spesical_Offer_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Spesical_Offer_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Spesical_Offer_Filter>>>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  destination?: InputMaybe<Airport_Filter>;
  id?: InputMaybe<Number_Filter_Operators>;
  image?: InputMaybe<Directus_Files_Filter>;
  origin?: InputMaybe<Airport_Filter>;
  sort?: InputMaybe<Number_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  translations?: InputMaybe<Spesical_Offer_Translations_Filter>;
  user_created?: InputMaybe<String_Filter_Operators>;
  user_updated?: InputMaybe<String_Filter_Operators>;
};

export type Spesical_Offer_Translations = {
  __typename?: 'spesical_offer_translations';
  id?: Maybe<Scalars['ID']>;
  languages_code?: Maybe<Languages>;
  price_offer?: Maybe<Scalars['String']>;
  spesical_offer_id?: Maybe<Spesical_Offer>;
};


export type Spesical_Offer_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Spesical_Offer_TranslationsSpesical_Offer_IdArgs = {
  filter?: InputMaybe<Spesical_Offer_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Spesical_Offer_Translations_Aggregated = {
  __typename?: 'spesical_offer_translations_aggregated';
  avg?: Maybe<Spesical_Offer_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<Spesical_Offer_Translations_Aggregated_Fields>;
  count?: Maybe<Spesical_Offer_Translations_Aggregated_Fields>;
  countDistinct?: Maybe<Spesical_Offer_Translations_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Spesical_Offer_Translations_Aggregated_Fields>;
  min?: Maybe<Spesical_Offer_Translations_Aggregated_Fields>;
  sum?: Maybe<Spesical_Offer_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<Spesical_Offer_Translations_Aggregated_Fields>;
};

export type Spesical_Offer_Translations_Aggregated_Fields = {
  __typename?: 'spesical_offer_translations_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  spesical_offer_id?: Maybe<Scalars['Float']>;
};

export type Spesical_Offer_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Spesical_Offer_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Spesical_Offer_Translations_Filter>>>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
  price_offer?: InputMaybe<String_Filter_Operators>;
  spesical_offer_id?: InputMaybe<Spesical_Offer_Filter>;
};

export type String_Filter_Operators = {
  _contains?: InputMaybe<Scalars['String']>;
  _empty?: InputMaybe<Scalars['Boolean']>;
  _ends_with?: InputMaybe<Scalars['String']>;
  _eq?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _ncontains?: InputMaybe<Scalars['String']>;
  _nempty?: InputMaybe<Scalars['Boolean']>;
  _nends_with?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _nnull?: InputMaybe<Scalars['Boolean']>;
  _nstarts_with?: InputMaybe<Scalars['String']>;
  _null?: InputMaybe<Scalars['Boolean']>;
  _starts_with?: InputMaybe<Scalars['String']>;
};

export type Terms_Page = {
  __typename?: 'terms_page';
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  id?: Maybe<Scalars['ID']>;
  top_image?: Maybe<Directus_Files>;
  translations?: Maybe<Array<Maybe<Terms_Page_Translations>>>;
  user_updated?: Maybe<Scalars['String']>;
};


export type Terms_PageTop_ImageArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Terms_PageTranslationsArgs = {
  filter?: InputMaybe<Terms_Page_Translations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Terms_Page_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Terms_Page_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Terms_Page_Filter>>>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  top_image?: InputMaybe<Directus_Files_Filter>;
  translations?: InputMaybe<Terms_Page_Translations_Filter>;
  user_updated?: InputMaybe<String_Filter_Operators>;
};

export type Terms_Page_Translations = {
  __typename?: 'terms_page_translations';
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  languages_code?: Maybe<Languages>;
  terms_page_id?: Maybe<Terms_Page>;
};


export type Terms_Page_TranslationsLanguages_CodeArgs = {
  filter?: InputMaybe<Languages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Terms_Page_TranslationsTerms_Page_IdArgs = {
  filter?: InputMaybe<Terms_Page_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Terms_Page_Translations_Aggregated = {
  __typename?: 'terms_page_translations_aggregated';
  avg?: Maybe<Terms_Page_Translations_Aggregated_Fields>;
  avgDistinct?: Maybe<Terms_Page_Translations_Aggregated_Fields>;
  count?: Maybe<Terms_Page_Translations_Aggregated_Fields>;
  countDistinct?: Maybe<Terms_Page_Translations_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Terms_Page_Translations_Aggregated_Fields>;
  min?: Maybe<Terms_Page_Translations_Aggregated_Fields>;
  sum?: Maybe<Terms_Page_Translations_Aggregated_Fields>;
  sumDistinct?: Maybe<Terms_Page_Translations_Aggregated_Fields>;
};

export type Terms_Page_Translations_Aggregated_Fields = {
  __typename?: 'terms_page_translations_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  terms_page_id?: Maybe<Scalars['Float']>;
};

export type Terms_Page_Translations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Terms_Page_Translations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Terms_Page_Translations_Filter>>>;
  content?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  languages_code?: InputMaybe<Languages_Filter>;
  terms_page_id?: InputMaybe<Terms_Page_Filter>;
};

export const AirportFragmentDoc = gql`
    fragment airport on airport {
  code
  translations(filter: {languages_id: {code: {_contains: $lang}}}) {
    title
  }
}
    `;
export const BaggagePageDocument = gql`
    query baggagePage($lang: String) {
  baggage {
    top_image {
      id
    }
    translations(filter: {languages_code: {code: {_contains: $lang}}}) {
      content
    }
  }
}
    `;

export function useBaggagePageQuery(options: Omit<Urql.UseQueryArgs<BaggagePageQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<BaggagePageQuery>({ query: BaggagePageDocument, ...options });
};
export const BeforeTravelingPageDocument = gql`
    query beforeTravelingPage($lang: String) {
  before_traveling_page {
    top_image {
      id
    }
    translations(filter: {languages_code: {code: {_contains: $lang}}}) {
      content
    }
  }
}
    `;

export function useBeforeTravelingPageQuery(options: Omit<Urql.UseQueryArgs<BeforeTravelingPageQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<BeforeTravelingPageQuery>({ query: BeforeTravelingPageDocument, ...options });
};
export const CarPageContentDocument = gql`
    query CarPageContent($lang: String) {
  car_page {
    top_image {
      id
    }
    translations(filter: {languages_code: {code: {_contains: $lang}}}) {
      description
    }
    cards {
      image {
        id
      }
      link
    }
  }
}
    `;

export function useCarPageContentQuery(options: Omit<Urql.UseQueryArgs<CarPageContentQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<CarPageContentQuery>({ query: CarPageContentDocument, ...options });
};
export const ContactKosovoPageDocument = gql`
    query contactKosovoPage($lang: String) {
  contact_kosovo {
    top_image {
      id
    }
    translations(filter: {languages_code: {code: {_contains: $lang}}}) {
      content
    }
  }
}
    `;

export function useContactKosovoPageQuery(options: Omit<Urql.UseQueryArgs<ContactKosovoPageQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ContactKosovoPageQuery>({ query: ContactKosovoPageDocument, ...options });
};
export const ContactMacedoniaPageDocument = gql`
    query contactMacedoniaPage($lang: String) {
  contact_macedonia {
    top_image {
      id
    }
    translations(filter: {languages_code: {code: {_contains: $lang}}}) {
      content
    }
  }
}
    `;

export function useContactMacedoniaPageQuery(options: Omit<Urql.UseQueryArgs<ContactMacedoniaPageQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ContactMacedoniaPageQuery>({ query: ContactMacedoniaPageDocument, ...options });
};
export const ContactSwPageDocument = gql`
    query contactSwPage($lang: String) {
  contact_sw {
    top_image {
      id
    }
    translations(filter: {languages_code: {code: {_contains: $lang}}}) {
      content
    }
  }
}
    `;

export function useContactSwPageQuery(options: Omit<Urql.UseQueryArgs<ContactSwPageQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ContactSwPageQuery>({ query: ContactSwPageDocument, ...options });
};
export const DataProtectionPageDocument = gql`
    query dataProtectionPage($lang: String) {
  data_protection_page {
    top_image {
      id
    }
    translations(filter: {languages_code: {code: {_contains: $lang}}}) {
      content
    }
  }
}
    `;

export function useDataProtectionPageQuery(options: Omit<Urql.UseQueryArgs<DataProtectionPageQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<DataProtectionPageQuery>({ query: DataProtectionPageDocument, ...options });
};
export const FlightsPageDocument = gql`
    query flightsPage($lang: String) {
  flights_page {
    top_image {
      id
    }
    translations(filter: {languages_code: {code: {_contains: $lang}}}) {
      content
    }
  }
}
    `;

export function useFlightsPageQuery(options: Omit<Urql.UseQueryArgs<FlightsPageQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<FlightsPageQuery>({ query: FlightsPageDocument, ...options });
};
export const HealthPageDocument = gql`
    query healthPage($lang: String) {
  health_page {
    top_image {
      id
    }
    translations(filter: {languages_code: {code: {_contains: $lang}}}) {
      content
    }
  }
}
    `;

export function useHealthPageQuery(options: Omit<Urql.UseQueryArgs<HealthPageQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<HealthPageQuery>({ query: HealthPageDocument, ...options });
};
export const HistoryPageDocument = gql`
    query HistoryPage($lang: String) {
  history_page {
    top_image {
      id
    }
    translations(filter: {languages_code: {code: {_contains: $lang}}}) {
      content
    }
  }
  history_card(filter: {status: {_eq: "published"}}) {
    photo {
      id
    }
    title
    sort_year
    translations(filter: {languages_code: {code: {_contains: $lang}}}) {
      direction
      period
    }
  }
  milestones {
    value
  }
}
    `;

export function useHistoryPageQuery(options: Omit<Urql.UseQueryArgs<HistoryPageQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<HistoryPageQuery>({ query: HistoryPageDocument, ...options });
};
export const HomePageContentDocument = gql`
    query homePageContent($lang: String) {
  spesical_offer(filter: {status: {_eq: "published"}}) {
    sort
    origin {
      code
      ...airport
    }
    destination {
      code
      ...airport
    }
    translations(filter: {languages_code: {code: {_contains: $lang}}}) {
      price_offer
    }
    image {
      id
    }
  }
  news_block {
    translations(filter: {languages_code: {code: {_contains: $lang}}}) {
      title
    }
    active_news {
      expiredAt
      translations(filter: {languages_code: {code: {_contains: $lang}}}) {
        title
        content
      }
    }
  }
  advertisements_card(filter: {status: {_eq: "published"}}) {
    sort
    image {
      id
    }
    link
  }
}
    ${AirportFragmentDoc}`;

export function useHomePageContentQuery(options: Omit<Urql.UseQueryArgs<HomePageContentQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<HomePageContentQuery>({ query: HomePageContentDocument, ...options });
};
export const HotelPageContentDocument = gql`
    query hotelPageContent($lang: String) {
  hotels_page {
    top_image {
      id
    }
    translations(filter: {languages_code: {code: {_contains: $lang}}}) {
      content
    }
  }
}
    `;

export function useHotelPageContentQuery(options: Omit<Urql.UseQueryArgs<HotelPageContentQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<HotelPageContentQuery>({ query: HotelPageContentDocument, ...options });
};
export const HotelsPageDocument = gql`
    query HotelsPage($lang: String) {
  hotels_page {
    top_image {
      id
    }
    translations(filter: {languages_code: {code: {_contains: $lang}}}) {
      content
    }
  }
}
    `;

export function useHotelsPageQuery(options: Omit<Urql.UseQueryArgs<HotelsPageQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<HotelsPageQuery>({ query: HotelsPageDocument, ...options });
};
export const ImprintPageDocument = gql`
    query imprintPage($lang: String) {
  imprint_page {
    top_image {
      id
    }
    translations(filter: {languages_code: {code: {_contains: $lang}}}) {
      content
    }
  }
}
    `;

export function useImprintPageQuery(options: Omit<Urql.UseQueryArgs<ImprintPageQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ImprintPageQuery>({ query: ImprintPageDocument, ...options });
};
export const LocationPageDocument = gql`
    query locationPage($lang: String) {
  location_page {
    top_image {
      id
    }
    translations(filter: {languages_code: {code: {_contains: $lang}}}) {
      content
    }
  }
}
    `;

export function useLocationPageQuery(options: Omit<Urql.UseQueryArgs<LocationPageQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<LocationPageQuery>({ query: LocationPageDocument, ...options });
};
export const ManagementPageDocument = gql`
    query managementPage($lang: String) {
  management_page {
    top_image {
      id
    }
    translations(filter: {languages_code: {code: {_contains: $lang}}}) {
      content
    }
  }
  management_card(filter: {status: {_eq: "published"}}) {
    avatar {
      id
    }
    display_name
    translations(filter: {languages_code: {code: {_contains: $lang}}}) {
      role
      phone_title
      email_title
    }
    sort
    phone
    email
  }
}
    `;

export function useManagementPageQuery(options: Omit<Urql.UseQueryArgs<ManagementPageQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ManagementPageQuery>({ query: ManagementPageDocument, ...options });
};
export const PartnersPageDocument = gql`
    query partnersPage($lang: String) {
  partners_page {
    top_image {
      id
    }
    translations(filter: {languages_code: {code: {_contains: $lang}}}) {
      content
    }
  }
  partner_card(filter: {status: {_eq: "published"}}) {
    image {
      id
    }
    sort
    title
  }
}
    `;

export function usePartnersPageQuery(options: Omit<Urql.UseQueryArgs<PartnersPageQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<PartnersPageQuery>({ query: PartnersPageDocument, ...options });
};
export const SafetyPageDocument = gql`
    query safetyPage($lang: String) {
  safety_page {
    top_image {
      id
    }
    translations(filter: {languages_code: {code: {_contains: $lang}}}) {
      content
    }
  }
}
    `;

export function useSafetyPageQuery(options: Omit<Urql.UseQueryArgs<SafetyPageQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<SafetyPageQuery>({ query: SafetyPageDocument, ...options });
};
export const TermsPageDocument = gql`
    query termsPage($lang: String) {
  terms_page {
    top_image {
      id
    }
    translations(filter: {languages_code: {code: {_contains: $lang}}}) {
      content
    }
  }
}
    `;

export function useTermsPageQuery(options: Omit<Urql.UseQueryArgs<TermsPageQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<TermsPageQuery>({ query: TermsPageDocument, ...options });
};
export type BaggagePageQueryVariables = Exact<{
  lang?: InputMaybe<Scalars['String']>;
}>;


export type BaggagePageQuery = { __typename?: 'Query', baggage?: { __typename?: 'baggage', top_image?: { __typename?: 'directus_files', id?: string | null | undefined } | null | undefined, translations?: Array<{ __typename?: 'baggage_translations', content?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type BeforeTravelingPageQueryVariables = Exact<{
  lang?: InputMaybe<Scalars['String']>;
}>;


export type BeforeTravelingPageQuery = { __typename?: 'Query', before_traveling_page?: { __typename?: 'before_traveling_page', top_image?: { __typename?: 'directus_files', id?: string | null | undefined } | null | undefined, translations?: Array<{ __typename?: 'before_traveling_page_translations', content?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type CarPageContentQueryVariables = Exact<{
  lang?: InputMaybe<Scalars['String']>;
}>;


export type CarPageContentQuery = { __typename?: 'Query', car_page?: { __typename?: 'car_page', top_image?: { __typename?: 'directus_files', id?: string | null | undefined } | null | undefined, translations?: Array<{ __typename?: 'car_page_translations', description?: string | null | undefined } | null | undefined> | null | undefined, cards?: Array<{ __typename?: 'car_page_card', link?: string | null | undefined, image?: { __typename?: 'directus_files', id?: string | null | undefined } | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type ContactKosovoPageQueryVariables = Exact<{
  lang?: InputMaybe<Scalars['String']>;
}>;


export type ContactKosovoPageQuery = { __typename?: 'Query', contact_kosovo?: { __typename?: 'contact_kosovo', top_image?: { __typename?: 'directus_files', id?: string | null | undefined } | null | undefined, translations?: Array<{ __typename?: 'contact_kosovo_translations', content?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type ContactMacedoniaPageQueryVariables = Exact<{
  lang?: InputMaybe<Scalars['String']>;
}>;


export type ContactMacedoniaPageQuery = { __typename?: 'Query', contact_macedonia?: { __typename?: 'contact_macedonia', top_image?: { __typename?: 'directus_files', id?: string | null | undefined } | null | undefined, translations?: Array<{ __typename?: 'contact_macedonia_translations', content?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type ContactSwPageQueryVariables = Exact<{
  lang?: InputMaybe<Scalars['String']>;
}>;


export type ContactSwPageQuery = { __typename?: 'Query', contact_sw?: { __typename?: 'contact_sw', top_image?: { __typename?: 'directus_files', id?: string | null | undefined } | null | undefined, translations?: Array<{ __typename?: 'contact_sw_translations', content?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type DataProtectionPageQueryVariables = Exact<{
  lang?: InputMaybe<Scalars['String']>;
}>;


export type DataProtectionPageQuery = { __typename?: 'Query', data_protection_page?: { __typename?: 'data_protection_page', top_image?: { __typename?: 'directus_files', id?: string | null | undefined } | null | undefined, translations?: Array<{ __typename?: 'data_protection_page_translations', content?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type FlightsPageQueryVariables = Exact<{
  lang?: InputMaybe<Scalars['String']>;
}>;


export type FlightsPageQuery = { __typename?: 'Query', flights_page?: { __typename?: 'flights_page', top_image?: { __typename?: 'directus_files', id?: string | null | undefined } | null | undefined, translations?: Array<{ __typename?: 'flights_page_translations', content?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type HealthPageQueryVariables = Exact<{
  lang?: InputMaybe<Scalars['String']>;
}>;


export type HealthPageQuery = { __typename?: 'Query', health_page?: { __typename?: 'health_page', top_image?: { __typename?: 'directus_files', id?: string | null | undefined } | null | undefined, translations?: Array<{ __typename?: 'health_page_translations', content?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type HistoryPageQueryVariables = Exact<{
  lang?: InputMaybe<Scalars['String']>;
}>;


export type HistoryPageQuery = { __typename?: 'Query', history_page?: { __typename?: 'history_page', top_image?: { __typename?: 'directus_files', id?: string | null | undefined } | null | undefined, translations?: Array<{ __typename?: 'history_page_translations', content?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined, history_card?: Array<{ __typename?: 'history_card', title?: string | null | undefined, sort_year?: number | null | undefined, photo?: { __typename?: 'directus_files', id?: string | null | undefined } | null | undefined, translations?: Array<{ __typename?: 'history_card_translations', direction?: string | null | undefined, period?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined> | null | undefined, milestones?: Array<{ __typename?: 'milestones', value?: number | null | undefined } | null | undefined> | null | undefined };

export type HomePageContentQueryVariables = Exact<{
  lang?: InputMaybe<Scalars['String']>;
}>;


export type HomePageContentQuery = { __typename?: 'Query', spesical_offer?: Array<{ __typename?: 'spesical_offer', sort?: number | null | undefined, origin?: { __typename?: 'airport', code?: string | null | undefined, translations?: Array<{ __typename?: 'airport_translations', title?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined, destination?: { __typename?: 'airport', code?: string | null | undefined, translations?: Array<{ __typename?: 'airport_translations', title?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined, translations?: Array<{ __typename?: 'spesical_offer_translations', price_offer?: string | null | undefined } | null | undefined> | null | undefined, image?: { __typename?: 'directus_files', id?: string | null | undefined } | null | undefined } | null | undefined> | null | undefined, news_block?: { __typename?: 'news_block', translations?: Array<{ __typename?: 'news_block_translations', title?: string | null | undefined } | null | undefined> | null | undefined, active_news?: { __typename?: 'news', expiredAt?: any | null | undefined, translations?: Array<{ __typename?: 'news_translations', title?: string | null | undefined, content?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined } | null | undefined, advertisements_card?: Array<{ __typename?: 'advertisements_card', sort?: number | null | undefined, link?: string | null | undefined, image?: { __typename?: 'directus_files', id?: string | null | undefined } | null | undefined } | null | undefined> | null | undefined };

export type AirportFragment = { __typename?: 'airport', code?: string | null | undefined, translations?: Array<{ __typename?: 'airport_translations', title?: string | null | undefined } | null | undefined> | null | undefined };

export type HotelPageContentQueryVariables = Exact<{
  lang?: InputMaybe<Scalars['String']>;
}>;


export type HotelPageContentQuery = { __typename?: 'Query', hotels_page?: { __typename?: 'hotels_page', top_image?: { __typename?: 'directus_files', id?: string | null | undefined } | null | undefined, translations?: Array<{ __typename?: 'hotels_page_translations', content?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type HotelsPageQueryVariables = Exact<{
  lang?: InputMaybe<Scalars['String']>;
}>;


export type HotelsPageQuery = { __typename?: 'Query', hotels_page?: { __typename?: 'hotels_page', top_image?: { __typename?: 'directus_files', id?: string | null | undefined } | null | undefined, translations?: Array<{ __typename?: 'hotels_page_translations', content?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type ImprintPageQueryVariables = Exact<{
  lang?: InputMaybe<Scalars['String']>;
}>;


export type ImprintPageQuery = { __typename?: 'Query', imprint_page?: { __typename?: 'imprint_page', top_image?: { __typename?: 'directus_files', id?: string | null | undefined } | null | undefined, translations?: Array<{ __typename?: 'imprint_page_translations', content?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type LocationPageQueryVariables = Exact<{
  lang?: InputMaybe<Scalars['String']>;
}>;


export type LocationPageQuery = { __typename?: 'Query', location_page?: { __typename?: 'location_page', top_image?: { __typename?: 'directus_files', id?: string | null | undefined } | null | undefined, translations?: Array<{ __typename?: 'location_page_translations', content?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type ManagementPageQueryVariables = Exact<{
  lang?: InputMaybe<Scalars['String']>;
}>;


export type ManagementPageQuery = { __typename?: 'Query', management_page?: { __typename?: 'management_page', top_image?: { __typename?: 'directus_files', id?: string | null | undefined } | null | undefined, translations?: Array<{ __typename?: 'management_page_translations', content?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined, management_card?: Array<{ __typename?: 'management_card', display_name?: string | null | undefined, sort?: number | null | undefined, phone?: string | null | undefined, email?: string | null | undefined, avatar?: { __typename?: 'directus_files', id?: string | null | undefined } | null | undefined, translations?: Array<{ __typename?: 'management_card_translations', role?: string | null | undefined, phone_title?: string | null | undefined, email_title?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined> | null | undefined };

export type PartnersPageQueryVariables = Exact<{
  lang?: InputMaybe<Scalars['String']>;
}>;


export type PartnersPageQuery = { __typename?: 'Query', partners_page?: { __typename?: 'partners_page', top_image?: { __typename?: 'directus_files', id?: string | null | undefined } | null | undefined, translations?: Array<{ __typename?: 'partners_page_translations', content?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined, partner_card?: Array<{ __typename?: 'partner_card', sort?: number | null | undefined, title?: string | null | undefined, image?: { __typename?: 'directus_files', id?: string | null | undefined } | null | undefined } | null | undefined> | null | undefined };

export type SafetyPageQueryVariables = Exact<{
  lang?: InputMaybe<Scalars['String']>;
}>;


export type SafetyPageQuery = { __typename?: 'Query', safety_page?: { __typename?: 'safety_page', top_image?: { __typename?: 'directus_files', id?: string | null | undefined } | null | undefined, translations?: Array<{ __typename?: 'safety_page_translations', content?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type TermsPageQueryVariables = Exact<{
  lang?: InputMaybe<Scalars['String']>;
}>;


export type TermsPageQuery = { __typename?: 'Query', terms_page?: { __typename?: 'terms_page', top_image?: { __typename?: 'directus_files', id?: string | null | undefined } | null | undefined, translations?: Array<{ __typename?: 'terms_page_translations', content?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };
