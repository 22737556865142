import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { confirmCreateInvoicePopupSaga } from './saga';
import { allotmentInvoicePopupActions, allotmentInvoicePopupReducer, allotmentInvoicePopupSliceKey } from './slice';

export const useInjectAllotmentInvoicePopup = () => {
    useInjectReducer({
        actions: allotmentInvoicePopupActions,
        key: allotmentInvoicePopupSliceKey,
        reducer: allotmentInvoicePopupReducer,
    });
    useInjectSaga({
        key: `${allotmentInvoicePopupSliceKey}confirmCreateInvoicePopupSaga`,
        saga: confirmCreateInvoicePopupSaga,
    });
};
