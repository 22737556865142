import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.splitReservation || initialState;

export const selectSplitReservationState = createSelector(
    [selectDomain],
    state => state,
);
