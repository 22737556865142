import React from 'react';
import { FormattedMessage } from 'react-intl';
import { appNotification } from '../../containers/App/appNotification';
import { createLocalNotification } from '../../containers/App/utils';

const notification = createLocalNotification('Rate', {
    error: (
        <FormattedMessage
            id='front-app/Rate/Notification/Error:title'
            defaultMessage='Rate Error'
        />
    ),
    warning: (
        <FormattedMessage
            id='front-app/Rate/Notification/Warning:title'
            defaultMessage='Rate Warning'
        />
    ),
    info: (
        <FormattedMessage
            id='front-app/Rate/Notification/Info:title'
            defaultMessage='Rate Info'
        />
    ),
    success: (
        <FormattedMessage
            id='front-app/Rate/Notification/Info:title'
            defaultMessage='Rate Info'
        />
    ),
});

export const rateNotifications = {
    createdSuccessfully: notification.success(appNotification.texts.createdSuccessfully),
    updatedSuccessfully: notification.success(appNotification.texts.updatedSuccessfully),
    unknownError: (details?: JSX.Element | string) => (
        notification.error(appNotification.texts.unknownError(details))
    ),
};
