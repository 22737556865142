import { addQueryParameters, getQueryParameters, makePath } from '../../../../utils/uriUtils';
import { FlightListQueryParameters } from '../../../pages/FlightListPage/queryParameters';
import { allAdminRoutePaths } from '../allAdminRoutePaths';

export type FlightQueryParameters = (
    | { source: 'flightList' }
    | { source: 'allotmentList' }
);

const defaultBackUrl = allAdminRoutePaths.flightList;
const getBackUrl = (params: FlightQueryParameters): string | undefined => {
    switch (params.source) {
        case 'flightList': {
            return undefined;
        }
        case 'allotmentList': {
            return allAdminRoutePaths.allotmentList;
        }
        default: {
            return undefined;
        }
    }
};

export const flight = {
    forward: (flightId: number | undefined, parameters: FlightQueryParameters): string => {
        const path = !flightId ? allAdminRoutePaths.flightNew : makePath(allAdminRoutePaths.flightEdit, {
            flightId,
        });
        const result = addQueryParameters(path, parameters);
        return result;
    },
    back: (listQueryParameters: FlightListQueryParameters): string => {
        const params = getQueryParameters<FlightQueryParameters>({
            source: 'string',
        });
        const path = getBackUrl(params) ?? defaultBackUrl;
        const result = addQueryParameters(path, listQueryParameters);
        return result;
    },
};
