import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../utils/log';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { optionNotifications } from './notification';
import { actions } from './slice';
import { apiClientRequest, getApiErrorMessage } from 'app/services/ApiClient';

export function* getOptionTypeListData() {
    try {
        const result = yield* call(apiClientRequest, {
            requestId: 'optionTypeEntireList',
        });
        yield* put(actions.getOptionTypeListDataSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'OptionNewPage.createOption',
        });
        const message = getApiErrorMessage(error);
        yield* put(optionNotifications.unknownError(message));
    }
}

export function* getOptionTypeListDataSaga() {
    yield* takeLatest(actions.getOptionTypeListData, getOptionTypeListData);
}

export function* createOption(action: ReturnType<typeof actions.createOption>) {
    try {
        const optionData = action.payload;

        yield* call(apiClientRequest, {
            requestId: 'optionCreate',
            requestPayload: optionData,
        });
        yield* put(actions.createOptionSuccess());
        yield* put(push(allAdminRoutePaths.rateOptionList));
        yield* put(optionNotifications.createdSuccessfully);
    }
    catch (error) {
        logError({
            error,
            target: 'OptionNewPage.createOption',
        });
        const message = getApiErrorMessage(error);
        yield* put(optionNotifications.unknownError(message));
    }
}

export function* createOptionSaga() {
    yield* takeLatest(actions.createOption, createOption);
}
