import { RouteItem } from '@malesia/react-components/dist/src/components/types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { allPublicRoutePaths } from './allPublicRoutePaths';

const aboutSubItems: RouteItem[] = [
    {
        routeKey: 'management',
        linkRoute: allPublicRoutePaths.aboutUs,
        LinkComponent: () => (
            <Link to={allPublicRoutePaths.aboutUs}>
                <FormattedMessage
                    id='front-app/Routes/MANAGEMENT:Title'
                    defaultMessage='Management'
                />
            </Link>
        ),
    },
    {
        routeKey: 'history',
        linkRoute: allPublicRoutePaths.history,
        LinkComponent: () => (
            <Link to={allPublicRoutePaths.history}>
                <FormattedMessage
                    id='front-app/Routes/HISTORY:Title'
                    defaultMessage='History'
                />
            </Link>
        ),
    },
    {
        routeKey: 'partners',
        linkRoute: allPublicRoutePaths.partners,
        LinkComponent: () => (
            <Link to={allPublicRoutePaths.partners}>
                <FormattedMessage
                    id='front-app/Routes/PARTNERS:Title'
                    defaultMessage='Partners'
                />
            </Link>
        ),
    },
    {
        routeKey: 'imprint',
        linkRoute: allPublicRoutePaths.imprint,
        LinkComponent: () => (
            <Link to={allPublicRoutePaths.imprint}>
                <FormattedMessage
                    id='front-app/Routes/IMPRINT:Title'
                    defaultMessage='Imprint'
                />
            </Link>
        ),
    },
    {
        routeKey: 'location',
        linkRoute: allPublicRoutePaths.location,
        LinkComponent: () => (
            <Link to={allPublicRoutePaths.location}>
                <FormattedMessage
                    id='front-app/Routes/LOCATION:Title'
                    defaultMessage='Location'
                />
            </Link>
        ),
    },
    {
        routeKey: 'terms_conditions',
        linkRoute: allPublicRoutePaths.termsConditions,
        LinkComponent: () => (
            <Link to={allPublicRoutePaths.termsConditions}>
                <FormattedMessage
                    id='front-app/Routes/TERMS_CONDITIONS:Title'
                    defaultMessage='Terms & Conditions'
                />
            </Link>
        ),
    },
    {
        routeKey: 'data_protection',
        linkRoute: allPublicRoutePaths.dataProtection,
        LinkComponent: () => (
            <Link to={allPublicRoutePaths.dataProtection}>
                <FormattedMessage
                    id='front-app/Routes/DATA_PROTECTION:Title'
                    defaultMessage='Data protection'
                />
            </Link>
        ),
    },
];

const bookingSubItems: RouteItem[] = [
    {
        routeKey: 'flight',
        linkRoute: allPublicRoutePaths.bookingFlightTravelDates,
        LinkComponent: () => (
            <Link to={allPublicRoutePaths.bookingFlightTravelDates}>
                <FormattedMessage
                    id='front-app/Routes/BOOKING_FLIGHT:Title'
                    defaultMessage='Flight'
                />
            </Link>
        ),
    },
    {
        routeKey: 'car',
        linkRoute: allPublicRoutePaths.bookingCar,
        LinkComponent: () => (
            <Link to={allPublicRoutePaths.bookingCar}>
                <FormattedMessage
                    id='front-app/Routes/BOOKING_CAR:Title'
                    defaultMessage='Car'
                />
            </Link>
        ),
    },
    {
        routeKey: 'hotel',
        linkRoute: allPublicRoutePaths.bookingHotel,
        LinkComponent: () => (
            <Link to={allPublicRoutePaths.bookingHotel}>
                <FormattedMessage
                    id='front-app/Routes/BOOKING_HOTEL:Title'
                    defaultMessage='Hotel'
                />
            </Link>
        ),
    },
];
const informationSubItems: RouteItem[] = [
    {
        routeKey: 'beforeTraveling',
        linkRoute: allPublicRoutePaths.information,
        LinkComponent: () => (
            <Link to={allPublicRoutePaths.information}>
                <FormattedMessage
                    id='front-app/Routes/BEFORE_TRAVELING:Title'
                    defaultMessage='Before traveling'
                />
            </Link>
        ),
    },
    {
        routeKey: 'safety',
        linkRoute: allPublicRoutePaths.safety,
        LinkComponent: () => (
            <Link to={allPublicRoutePaths.safety}>
                <FormattedMessage
                    id='front-app/Routes/SAFETY:Title'
                    defaultMessage='Safety'
                />
            </Link>
        ),
    },
    {
        routeKey: 'baggage',
        linkRoute: allPublicRoutePaths.baggage,
        LinkComponent: () => (
            <Link to={allPublicRoutePaths.baggage}>
                <FormattedMessage
                    id='front-app/Routes/BAGGAGE:Title'
                    defaultMessage='Baggage'
                />
            </Link>
        ),
    },
    {
        routeKey: 'health',
        linkRoute: allPublicRoutePaths.health,
        LinkComponent: () => (
            <Link to={allPublicRoutePaths.health}>
                <FormattedMessage
                    id='front-app/Routes/HEALTH:Title'
                    defaultMessage='Health'
                />
            </Link>
        ),
    },
];
const contactSubItems: RouteItem[] = [
    {
        routeKey: 'switzerland',
        linkRoute: allPublicRoutePaths.contact,
        LinkComponent: () => (
            <Link to={allPublicRoutePaths.contact}>
                <FormattedMessage
                    id='front-app/Routes/CONTACT_SWITZERLAND:Title'
                    defaultMessage='Switzerland'
                />
            </Link>
        ),
    },
    {
        routeKey: 'kosovo',
        linkRoute: allPublicRoutePaths.contactKosovo,
        LinkComponent: () => (
            <Link to={allPublicRoutePaths.contactKosovo}>
                <FormattedMessage
                    id='front-app/Routes/CONTACT_KOSOVO:Title'
                    defaultMessage='Kosovo'
                />
            </Link>
        ),
    },
    {
        routeKey: 'macedonia',
        linkRoute: allPublicRoutePaths.contactMacedonia,
        LinkComponent: () => (
            <Link to={allPublicRoutePaths.contactMacedonia}>
                <FormattedMessage
                    id='front-app/Routes/CONTACT_MACEDONIA:Title'
                    defaultMessage='Macedonia'
                />
            </Link>
        ),
    },
];
const accountSubItems: RouteItem[] = [
    {
        routeKey: 'accountReservations',
        linkRoute: allPublicRoutePaths.accountReservations,
        LinkComponent: () => (
            <Link to={allPublicRoutePaths.accountReservations}>
                <FormattedMessage
                    id='front-app/Routes/ACCOUNT_RESERVATIONS:Title'
                    defaultMessage='Reservations'
                />
            </Link>
        ),
    },
    {
        routeKey: 'accountDetails',
        linkRoute: allPublicRoutePaths.accountDetails,
        LinkComponent: () => (
            <Link to={allPublicRoutePaths.accountDetails}>
                <FormattedMessage
                    id='front-app/Routes/ACCOUNT_DETAILS:Title'
                    defaultMessage='Account Details'
                />
            </Link>
        ),
    },
];

export const appPublicRoutes: RouteItem[] = [
    {
        routeKey: 'booking',
        linkRoute: allPublicRoutePaths.bookingFlightTravelDates,
        LinkComponent: () => (
            <Link to={allPublicRoutePaths.bookingFlightTravelDates}>
                <FormattedMessage
                    id='front-app/Routes/BOOKING_FLIGHT_TRAVEL_DATES:Title'
                    defaultMessage='Booking'
                />
            </Link>
        ),
        subItems: bookingSubItems,
    },
    {
        routeKey: 'about',
        linkRoute: allPublicRoutePaths.aboutUs,
        LinkComponent: () => (
            <Link to={allPublicRoutePaths.aboutUs}>
                <FormattedMessage
                    id='front-app/Routes/ABOUT_US:Title'
                    defaultMessage='About Us'
                />
            </Link>
        ),
        subItems: aboutSubItems,
    },
    {
        routeKey: 'information',
        linkRoute: allPublicRoutePaths.information,
        LinkComponent: () => (
            <Link to={allPublicRoutePaths.information}>
                <FormattedMessage
                    id='front-app/Routes/INFORMATION:Title'
                    defaultMessage='Information'
                />
            </Link>
        ),
        subItems: informationSubItems,
    },
    {
        routeKey: 'flights',
        linkRoute: allPublicRoutePaths.flights,
        LinkComponent: () => (
            <Link to={allPublicRoutePaths.flights}>
                <FormattedMessage
                    id='front-app/Routes/FLIGHTS:Title'
                    defaultMessage='Flights'
                />
            </Link>
        ),
    },
    {
        routeKey: 'contact',
        linkRoute: allPublicRoutePaths.contact,
        LinkComponent: () => (
            <Link to={allPublicRoutePaths.contact}>
                <FormattedMessage
                    id='front-app/Routes/CONTACT:Title'
                    defaultMessage='Contact'
                />
            </Link>
        ),
        subItems: contactSubItems,
    },
    {
        routeKey: 'account',
        linkRoute: allPublicRoutePaths.accountReservations,
        LinkComponent: () => (
            <Link to={allPublicRoutePaths.accountReservations}>
                <FormattedMessage
                    id='front-app/Routes/ACCOUNT:Title'
                    defaultMessage='Account'
                />
            </Link>
        ),
        subItems: accountSubItems,
    },
];

export const tabRoutesPublicSiteMap: Record<string, string> = {};
