import { PassengerItemDataType } from '@malesia/react-components/dist/src/components/Reservation/PassengersList/Item';
import { FormErrorType } from '@malesia/react-components/dist/src/components/ValidationErrors';
import { coercionDataToSchema } from '../../../../../utils/validation/coercion';
import { validatePassengersByAge } from '../../../../../utils/validation/validatePassengersByAge/validatePassengersByAge';
import { formatSchemaValidationErrors } from '../../../../containers/ValidationErrorMessage';

export type SetErrors = (errors: (Record<string, FormErrorType[]> | undefined)[]) => void;

export const useValidators = (setFormErrors: SetErrors, schemaId: string) => {
    const getFormValidationResults = (formData: any) => coercionDataToSchema(schemaId, formData || {});

    const validateForm = (passengersData) => {
        const formErrors: (Record<string, FormErrorType[]> | undefined)[] = [];
        let errorCount = 0;
        for (const passenger of passengersData) {
            const [, , errors] = getFormValidationResults(passenger.formData || {});
            if (errors && errors.length > 0) {
                errorCount++;
                formErrors.push(formatSchemaValidationErrors(errors));
            }
            else {
                formErrors.push(undefined);
            }
        }
        setFormErrors(formErrors);
        return !errorCount;
    };

    const validatePassengersAge = (passengerData: PassengerItemDataType[]): boolean => (
        validatePassengersByAge(passengerData.map(passenger => passenger.formData))
    );

    return { validateForm, validatePassengersAge };
};
