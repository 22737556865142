import { AirportCode } from '@malesia/react-components/dist/src/types';
import { useMemo } from 'react';
import { AirportWithLocaleName } from '../../../../types';
import { FlightAirports } from '../types';

export function useAirportsFromToText(
    flightAirports: FlightAirports | undefined,
    airportsByCodeMap: Record<AirportCode, AirportWithLocaleName>,
) {
    return useMemo(() => {
        const originAirportName = airportsByCodeMap[flightAirports?.originAirportCode ?? '']?.name ?? '';
        const destinationAirportName = airportsByCodeMap[flightAirports?.destinationAirportCode ?? '']?.name ?? '';
        return `${originAirportName} ${destinationAirportName}`;
    }, [airportsByCodeMap, flightAirports]);
}
