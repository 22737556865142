import { ReservationUserSearchFilters, UserProfilePaginatedList } from '@malesia/json-schema';
import { call } from 'typed-redux-saga';
import { apiClientRequest } from './ApiClient';

export function* getApiReservatorList(options: Pick<ReservationUserSearchFilters, 'query' | 'userId'>) {
    const {
        query,
        userId,
    } = options;

    // ToDo: May by sortBy? isActive is required?
    const requestPayload: ReservationUserSearchFilters & { orderBy?: string, isActive?: boolean } = {
        query,
        userId,
        orderBy: 'name',
        sortOrder: 'ASC',
        isActive: true,
        isReservator: true,
    };
    const result: UserProfilePaginatedList = yield* call(apiClientRequest, {
        requestId: 'reservationUserList',
        requestPayload,
    });
    return result;
}
