import { Account, ReservationCalculatePostResponse } from '@malesia/json-schema';
import { SearchPassengerItemData } from '@malesia/react-components/dist/src/components/Form/Field/SearchPassenger/SearchPassengerItem';
import { AdminPaymentFormData } from '@malesia/react-components/dist/src/components/Reservation/AdminPayment';
import { AdminBillingFormData } from '@malesia/react-components/dist/src/components/Reservation/BillingInformation/AdminBillingInformation';
import { ReservationPassengerOptionChangeData } from '@malesia/react-components/dist/src/components/Reservation/PassengersList';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../../utils/@reduxjs/toolkit';
import { removeFromArray, replaceArrayItemByIndex } from '../../../../utils/arrayFunctions';
import { removeBookedSeatMaps } from '../../../../utils/reservation/seatMap';
import { ResetDirectionSeatsOptions, ResetSeatOptions, SelectSeatOptions } from '../../../../utils/reservation/seats/useSeatsSelectionPopup';
import { SelectedFlightWithDirection } from '../../../../utils/reservation/selectedFlight';
import { PassengerBasket, SeatMaps } from '../../../../utils/reservation/types';
import { auxiliaryActions } from '../../../containers/AuxiliaryData/slice';
import { UpdatePassengerAtPositionType } from '../../../containers/ReservationBasket/reservationBasket.slice';
import { mapApiCostToBasketCost } from '../../../services/ReservationCalculate/utils';
import {
    AdminReservationBasket,
    ReservationNewPageState,
    Step,
    SearchedPassengerLists,
    FlightLists,
    CreateReservationOptions,
} from '../types';

export const initialState: ReservationNewPageState = {
    mode: 'new',
    editable: true,
    isReservationLoading: true,
    reservation: {
        travelInfo: {
            outbound: {},
            ['return']: {},
        },
        selectedFlights: {},
        bookedFlights: {},
        bookedOptionList: [],
        passengers: [
            {
                info: {
                    ageType: 'adult',
                },
                seats: {},
                options: [],
            },
        ],
        passengersAges: {
            adult: 1,
            child: 0,
            infant: 0,
        },
        payment: {
            existingPayments: [],
            paymentTerms: null,
        },
    },
    activeStep: 'travelDates',
    flightLists: {
        isLoading: false,
        outbound: [],
        ['return']: [],
    },
    searchedPassengerLists: {
        own: [],
        all: [],
    },
    seatMaps: {},
};

const reservationNewPageSlice = createSlice({
    name: 'reservationNewPage',
    initialState,
    reducers: {
        loadNewReservation(state) {
            state.isReservationLoading = true;
            state.mode = 'new';
            state.reservationId = undefined;
        },
        loadNewReservationSuccess(state, action: PayloadAction<AdminReservationBasket>) {
            state.isReservationLoading = false;
            state.reservation = action.payload;
        },
        loadNewReservationError(state) {
            state.isReservationLoading = false;
        },
        loadEditReservation(state, action: PayloadAction<number>) {
            state.isReservationLoading = true;
            state.mode = 'edit';
            state.reservationId = action.payload;
        },
        loadEditReservationSuccess(state, action: PayloadAction<AdminReservationBasket>) {
            state.isReservationLoading = false;
            state.reservation = action.payload;
        },
        loadEditCanceledReservationSuccess(state) {
            state.isReservationLoading = false;
            state.editable = false;
        },
        loadEditReservationError(state) {
            state.isReservationLoading = false;
        },
        updateReservationState(state, action: PayloadAction<AdminReservationBasket>) {
            state.reservation = {
                ...state.reservation,
                ...action.payload,
            };
            state.seatMaps = removeBookedSeatMaps({
                seatMaps: state.seatMaps,
                bookedFlights: state.reservation.bookedFlights,
                selectedFlights: state.reservation.selectedFlights,
            });
        },
        goToNextStep(state, actions: PayloadAction<Step>) {
            const currentStep = actions.payload;
            let nextStep: Step;
            switch (currentStep) {
                case 'travelDates':
                    nextStep = state.reservation.travelInfo.type === 'one-way' ? 'selectOneWayFlight' : 'selectFlights';
                    break;
                case 'selectFlights':
                    nextStep = 'billingInformation';
                    break;
                case 'selectOneWayFlight':
                    nextStep = 'billingInformation';
                    break;
                case 'billingInformation':
                    nextStep = 'passengers';
                    break;
                default:
                    nextStep = state.activeStep;
            }
            state.activeStep = nextStep;
        },
        selectFlight(state, action: PayloadAction<SelectedFlightWithDirection>) {
            const { flightDirection, ...flight } = action.payload;
            const { reservation } = state;
            reservation.passengers.forEach(x => {
                x.seats = {
                    ...x.seats,
                    [flightDirection]: undefined,
                };
                x.options = {};
            });
            reservation.selectedFlights[flightDirection] = flight;
        },
        getFlightLists(state) {
            state.flightLists.isLoading = true;
        },
        getFlightListsSuccess(state, action: PayloadAction<Omit<FlightLists, 'isLoading'>>) {
            state.flightLists = {
                ...action.payload,
                isLoading: false,
            };
            const { selectedFlights } = state.reservation;
            const hasOutbound = state.flightLists.outbound.some(x => x.id === selectedFlights.outbound?.flightId);
            const hasReturn = state.flightLists.return.some(x => x.id === selectedFlights.return?.flightId);
            state.reservation.selectedFlights = {
                outbound: hasOutbound ? selectedFlights.outbound : undefined,
                ['return']: hasReturn ? selectedFlights.return : undefined,
            };
        },
        getFlightListsError(state) {
            state.flightLists = {
                outbound: [],
                ['return']: [],
                isLoading: false,
            };
        },
        getSelectedOwnerAccountSuccess(state, action: PayloadAction<Account>) {
            state.selectedOwnerAccount = action.payload;
        },
        getSelectedOwnerAccountError(state) {
            state.selectedOwnerAccount = undefined;
        },
        setReservationComment(state, action: PayloadAction<string | undefined>) {
            state.reservation.comment = action.payload;
        },
        searchPassenger(state, action: PayloadAction<string>) {
        },
        searchPassengerSuccess(state, action: PayloadAction<SearchedPassengerLists>) {
            state.searchedPassengerLists = action.payload;
        },
        setSelectedPassenger(state, action: PayloadAction<SearchPassengerItemData>) {
        },
        addPassenger(state) {
            const { passengers } = state.reservation;
            const passenger: PassengerBasket = {
                info: {
                    ageType: 'adult',
                    firstName: '',
                    lastName: '',
                    birthday: '',
                },
                seats: {},
                options: {},
            };
            state.reservation.passengers = [...passengers, passenger];
        },
        updatePassengerAtPosition(
            state,
            action: PayloadAction<UpdatePassengerAtPositionType>,
        ) {
            const { passengerData, position } = action.payload;
            const { passengers } = state.reservation;
            const passenger = passengers[position];
            state.reservation.passengers = replaceArrayItemByIndex(
                passengers,
                position,
                {
                    ...passenger,
                    info: passengerData,
                    seats: passengerData.ageType === 'infant' ? {} : passenger.seats,
                },
            );
        },
        removePassengerAtPosition(state, action: PayloadAction<number>) {
            const position = action.payload;
            const { passengers } = state.reservation;

            state.reservation.passengers = removeFromArray(passengers, position);
        },
        setPassengerOptions(
            state,
            action: PayloadAction<ReservationPassengerOptionChangeData>,
        ) {
            const { isChecked, optionId, passengerIdx } = action.payload;
            const { passengers } = state.reservation;
            const passenger = passengers[passengerIdx];

            const options = {
                ...passenger.options,
                [optionId]: isChecked,
            };

            state.reservation.passengers = replaceArrayItemByIndex(
                passengers,
                passengerIdx,
                {
                    ...passenger,
                    options,
                },
            );
        },
        getSeatMaps() {
        },
        getSeatMapsSuccess(state, action: PayloadAction<SeatMaps>) {
            state.seatMaps = removeBookedSeatMaps({
                seatMaps: action.payload,
                bookedFlights: state.reservation.bookedFlights,
                selectedFlights: state.reservation.selectedFlights,
            });
        },
        getSeatMapsError(state) {
            state.seatMaps = {};
        },
        setPaymentData(state, action: PayloadAction<Partial<AdminPaymentFormData>>) {
            state.reservation.payment = {
                ...state.reservation.payment,
                ...action.payload,
            };
        },
        resetSeat(state, action: PayloadAction<ResetSeatOptions>) {
            const { passengerIdx, flightDirection } = action.payload;
            const { passengers } = state.reservation;

            const passenger = passengers[passengerIdx] ?? [];
            const seats = {
                ...passenger.seats,
                [flightDirection]: undefined,
            };

            state.reservation.passengers = replaceArrayItemByIndex(
                passengers,
                passengerIdx,
                { ...passenger, seats },
            );
        },
        resetDirectionSeats(state, action: PayloadAction<ResetDirectionSeatsOptions>) {
            const { flightDirection } = action.payload;
            const { passengers } = state.reservation;

            passengers.forEach(passenger => {
                passenger.seats = {
                    ...passenger.seats,
                    [flightDirection]: undefined,
                };
            });
        },
        selectPassengerSeat(state, action: PayloadAction<SelectSeatOptions>) {
            const { passengerIdx, flightDirection, seatLabel } = action.payload;
            const { passengers } = state.reservation;
            const passenger = passengers[passengerIdx];

            const result: PassengerBasket = {
                ...passenger,
                seats: {
                    ...passenger.seats,
                    [flightDirection]: seatLabel,
                },
            };
            state.reservation.passengers = replaceArrayItemByIndex(
                passengers,
                passengerIdx,
                result,
            );
        },
        requestCalculateCost(state) {
            state.isCostDirty = true;
            state.isCostRequested = true;
        },
        calculateCost(state) {
            state.isCostRequested = false;
            state.isCostLoading = true;
        },
        calculateCostSuccess(state, action: PayloadAction<ReservationCalculatePostResponse>) {
            state.cost = mapApiCostToBasketCost(action.payload.cost!);
            state.reservation.passengers.forEach((x, i) => {
                const item = action.payload.cost!.passengers[i];
                if (!item) return;
                const { passenger } = item;
                x.info.ageType = passenger.ageType;
            });
            state.isCostLoading = false;
            if (!state.isCostRequested) {
                state.isCostDirty = false;
            }
        },
        calculateCostError(state) {
            state.isCostLoading = false;
        },
        openDeadlinePopup(state) {
            state.isOpenPaymentDeadlinePopup = true;
        },
        closeDeadlinePopup(state) {
            state.isOpenPaymentDeadlinePopup = false;
        },
        createReservation(state, action: PayloadAction<CreateReservationOptions>) {
            state.isUpdateLoading = true;
        },
        createReservationSuccess(state) {
            state.isUpdateLoading = false;
        },
        createReservationError(state) {
            state.isUpdateLoading = false;
        },
        createReservationCancel(state) {
            state.isUpdateLoading = false;
        },
        setBillingInformation(state, action: PayloadAction<AdminBillingFormData>) {
            state.reservation.billingInformation = action.payload;
        },
        updateReservation(state) {
            state.isUpdateLoading = true;
        },
        updateReservationSuccess(state) {
            state.isUpdateLoading = false;
        },
        updateReservationError(state) {
            state.isUpdateLoading = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(auxiliaryActions.selectReservationOwnerSuccess, (state, action) => {
            if (!action.payload) return;
            const owner = action.payload;
            if (owner.type !== 'customer') return;

            const { passengers } = state.reservation;
            const passenger: PassengerBasket = {
                info: {
                    firstName: owner.firstName,
                    lastName: owner.lastName,
                    birthday: owner.birthday,
                    gender: owner.salutation === 'mr' ? 'male' : 'female',
                },
                seats: {},
                options: {},
            };
            if (state.reservation.passengers.length < 2) {
                state.reservation.passengers = [passenger, ...passengers.slice(1)];
            }
        });
    },
});

export const {
    actions: reservationNewPageActions,
    reducer: reservationNewPageReducer,
    name: reservationNewPageSliceName,
} = reservationNewPageSlice;
