import { getTopImage } from '../getTopImage';
import { LocationPageDocument, LocationPageQuery } from '../gql-types';
import { gqlClient } from '../gqlClient';

export async function loadLocationPageContent(locale: string) {
    const { data } = await gqlClient.query<LocationPageQuery>(
        LocationPageDocument,
        { lang: locale },
    ).toPromise();
    return {
        topImage: getTopImage(data?.location_page?.top_image),
        content: data?.location_page?.translations?.[0]?.content ?? '',
    };
}
