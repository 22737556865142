import { AdminReservationSummary } from '@malesia/react-components/dist/src/components/Reservation/Summary/Admin';
import { PriceItemType } from '@malesia/react-components/dist/src/components/Reservation/Summary/Info/PriceBlock';
import { StickyContent } from '@malesia/react-components/dist/src/components/StickyContent/StickyContent';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import { localizeMoney } from '@malesia/react-components/dist/src/utils/roundMoney';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { messages } from '../messages';
import {
    selectSummaryFlights,
    selectSummaryOptions,
    selectTicketsPriceByAge,
    selectTotalPrice,
    selectTotalSeatsCost,
    selectTotalSeatsCount,
} from '../store/selectors';
import { useCommentFormConfig } from './AdminReservationSummaryConfig';

export const AdminReservationSummaryWithStore: SFC = () => {
    const { locale, formatMessage } = useIntl();

    const flights = useSelector(selectSummaryFlights);
    const passengersTickets = useSelector(selectTicketsPriceByAge);
    const costTotal = useSelector(selectTotalPrice);
    const bookingOptions = useSelector(selectSummaryOptions);
    const totalSeatsCost = useSelector(selectTotalSeatsCost);
    const totalSeatsCount = useSelector(selectTotalSeatsCount);

    const totalSeats = useMemo((): PriceItemType[] => totalSeatsCost ? [{
        labelText: formatMessage(messages.seatsLabel, { total: totalSeatsCount }),
        priceText: `CHF ${localizeMoney(totalSeatsCost, locale, 'ReservationPaymentPage/AdminReservationSummaryWithStore/totalSeats')}`,
    }] : [], [formatMessage, totalSeatsCost, totalSeatsCount, locale]);

    const formConfig = useCommentFormConfig();

    return (
        <StickyContent>
            <AdminReservationSummary
                formConfig={formConfig}
                bookingOptions={bookingOptions}
                flights={flights}
                passengersTickets={passengersTickets}
                seats={totalSeats}
                totalPriceText={localizeMoney(costTotal, locale, 'ReservationPaymentPage/AdminReservationSummaryWithStore/totalPriceText')}
            />
        </StickyContent>
    );
};
