import { PopupConfirm } from '@malesia/react-components/dist/src/components/Popup/Confirm';
import { Table, TableColumn, TableProps } from '@malesia/react-components/dist/src/components/Table';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import { translateAgeType } from '@malesia/react-components/dist/src/utils/ageType';
import { translateGender } from '@malesia/react-components/dist/src/utils/gender';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { PassengerInfo } from '../../../utils/reservation/types';

type Passenger = PassengerInfo & {
    selected?: boolean,
};
type SplitReservationColumnsOptions = {
    selectPassenger: (passenger: Passenger) => void,
};
const splitReservationColumns = (
    options: SplitReservationColumnsOptions,
): TableColumn<PassengerInfo>[] => [
    {
        Header: (
            <div />
        ),
        id: 'selection',
        disableSortBy: true,
        Cell: ({ row }) => {
            const passenger: Passenger = row.original;
            return (
                <input
                    type='checkbox'
                    checked={passenger.selected}
                    onChange={() => options.selectPassenger(passenger)}
                />
            );
        },
        style: {
            width: '40px',
        },
    },
    {
        Header: (
            <FormattedMessage
                id='front-app/SplitPassengerPopup/List:FirstName'
                defaultMessage='First name'
            />
        ),
        accessor: 'firstName',
        disableSortBy: true,
    },
    {
        Header: (
            <FormattedMessage
                id='front-app/SplitPassengerPopup/List:LastName'
                defaultMessage='Last name'
            />
        ),
        accessor: 'lastName',
        disableSortBy: true,
    },
    {
        Header: (
            <FormattedMessage
                id='front-app/SplitPassengerPopup/List:Gender'
                defaultMessage='Gender'
            />
        ),
        id: 'gender',
        disableSortBy: true,
        Cell: ({ row }) => {
            const { gender } = row.original;
            return translateGender(gender) ?? null;
        },
    },
    {
        Header: (
            <FormattedMessage
                id='front-app/SplitPassengerPopup/List:AgeType'
                defaultMessage='Age type'
            />
        ),
        id: 'ageType',
        disableSortBy: true,
        Cell: ({ row }) => {
            const { ageType } = row.original;
            return translateAgeType(ageType) ?? null;
        },
    },
    {
        Header: (
            <FormattedMessage
                id='front-app/SplitPassengerPopup/List:BirthDate'
                defaultMessage='Birth date'
            />
        ),
        id: 'birthday',
        disableSortBy: true,
        Cell: ({ row }) => {
            return row.original.birthday ?? null;
        },
        style: {
            width: '120px',
        },
    },
];

export type SplitPassengerPopupProps = {
    isOpen: boolean,
    isLoading: boolean,
    defaultPassengerId?: number,
    passengers: PassengerInfo[],
    confirm: (selectedId: number) => void,
    close: () => void,
};
export const SplitPassengerPopup: SFC<SplitPassengerPopupProps, { result: 'optional' }> = (props) => {
    const { isOpen, isLoading, defaultPassengerId, passengers, confirm, close } = props;

    const [selectedId, setSelectedId] = useState<number | undefined>();
    useEffect(() => setSelectedId(defaultPassengerId), [defaultPassengerId]);

    const columns = useMemo(() => splitReservationColumns({
        selectPassenger: (passenger) => {
            const { selected, id } = passenger;
            const value = selected ? undefined : id;
            setSelectedId(value);
        },
    }), []);
    const data = useMemo(() => passengers.map<Passenger>(x => ({
        ...x,
        selected: x.id === selectedId,
    })), [selectedId, passengers]);

    if (!isOpen) return null;

    const tableProps: TableProps<PassengerInfo> = {
        columns,
        data,
    };

    return (
        <PopupConfirm
            isOpened={true}
            isLoading={isLoading}
            onConfirm={() => confirm(selectedId!)}
            onClose={close}
            isConfirmDisabled={!selectedId}
            modificators='lg'
            confirmMessage={
                <FormattedMessage
                    id='front-app/FlightPassengerListPage/SplitPassengerPopup:Title'
                    defaultMessage='Split reservation'
                />
            }
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                }}
            >
                <FormattedMessage
                    id='front-app/FlightPassengerListPage/SplitPassengerPopup:Description'
                    defaultMessage='Select the passenger you want to extract from this reservation.'
                />
                <Table {...tableProps} />
            </div>
        </PopupConfirm>
    );
};
