import { debounce, put } from 'typed-redux-saga';
import { logError } from '../../../../../utils/log';
import { appNotification } from '../../../../containers/App/appNotification';
import { getApiPermittedAccountFreeList } from '../../../../services/account';
import { getApiErrorMessage } from '../../../../services/ApiClient';
import { newTransactionActions } from '../slice';

function* searchCreditAccount(action: ReturnType<typeof newTransactionActions.searchCreditAccount>) {
    try {
        const res = yield* getApiPermittedAccountFreeList(action.payload);
        yield* put(newTransactionActions.setCreditAccountList(res));
    }
    catch (error) {
        logError({
            error,
            target: 'AccountingNewTransactionPage.searchCreditAccount',
        });
        const message = getApiErrorMessage(error);
        yield* put(appNotification.unknownError(message));
    }
}

export function* searchCreditAccountSaga() {
    yield* debounce(
        500,
        newTransactionActions.searchCreditAccount,
        searchCreditAccount,
    );
}
