import { permissionMaker } from '../../permissionExpression';

const { role, some, every } = permissionMaker;

const getFullList = role('ROLE_BALANCE_LOG_RECORD_LIST');
const getMyList = role('ROLE_BALANCE_LOG_RECORD_USER_RECORDS_LIST');
const accountFilter = every(
    getFullList,
    'ROLE_ACCOUNT_LIST',
);
const relatedAccountFilter = every(
    'ROLE_BALANCE_LOG_RECORD_USER_RECORDS_LIST_FILTERS',
    accountFilter,
);
const categoryFilter = every(
    'ROLE_BALANCE_LOG_RECORD_TYPE_LIST',
    'ROLE_BALANCE_LOG_RECORD_USER_RECORDS_LIST_FILTERS',
);
const getFullBalanceInfo = role('ROLE_BALANCE_LOG_RECORD_SUMMARY_INFO');
const getMyBalanceInfo = role('ROLE_BALANCE_LOG_RECORD_USER_RECORDS_LIST');
const getAnyBalanceInfo = some(
    getFullBalanceInfo,
    getMyBalanceInfo,
);
const overviewPdf = role('ROLE_BALANCE_LOG_RECORD_OVERVIEW_PDF');
const cashbackPdf = role('ROLE_BALANCE_LOG_RECORD_BASIC_PAYMENT_CASHBACK');

export const balanceLogPermissions = {
    getFullList,
    getMyList,
    accountFilter,
    relatedAccountFilter,
    categoryFilter,
    getFullBalanceInfo,
    getMyBalanceInfo,
    getAnyBalanceInfo,
    overviewPdf,
    cashbackPdf,
};
