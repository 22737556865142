import { useInjectReducer, useInjectSaga } from '../../../../utils/redux-injectors';
import { useInjectAuxiliaryData } from '../../../containers/AuxiliaryData/inject';
import { useInjectReservationPdf } from '../../../containers/ReservationPdf/inject';
import { useInjectReservationSamePassengers } from '../../../containers/ReservationSamePassengers/inject';
import { useInjectSharedData } from '../../../containers/SharedData/inject';
import { getPublicCountryListSaga } from '../../../containers/SharedData/saga';
import { setGetSelectedOwnerAccountSaga } from './getSelectedOwnerAccount.saga';
import { loadEditReservationSaga } from './loadEditReservation.saga';
import { loadNewReservationSaga } from './loadNewReservation.saga';
import {
    calculateCostSaga,
    getFlightListsSaga,
    getSeatMapsSaga,
    searchPassengerSaga,
    createReservationSaga,
    updateReservationSaga,
} from './saga';
import { setSelectedPassengerSaga } from './setSelectedPassenger.saga';
import { reservationNewPageActions, reservationNewPageReducer, reservationNewPageSliceName } from './slice';

export const useInjectReservationPageStore = () => {
    useInjectSharedData();
    useInjectAuxiliaryData();
    useInjectReservationPdf();
    useInjectReservationSamePassengers();
    useInjectReducer({
        actions: reservationNewPageActions,
        key: reservationNewPageSliceName,
        reducer: reservationNewPageReducer,
    });
    useInjectSaga({
        key: `${reservationNewPageSliceName}loadNewReservation`,
        saga: loadNewReservationSaga,
    });
    useInjectSaga({
        key: `${reservationNewPageSliceName}loadReservationSaga`,
        saga: loadEditReservationSaga,
    });
    useInjectSaga({
        key: `${reservationNewPageSliceName}getFlightLists`,
        saga: getFlightListsSaga,
    });
    useInjectSaga({
        key: `${reservationNewPageSliceName}searchPassengerSaga`,
        saga: searchPassengerSaga,
    });
    useInjectSaga({
        key: `${reservationNewPageSliceName}proceedGetCountryListDataSaga`,
        saga: getPublicCountryListSaga,
    });

    useInjectSaga({
        key: `${reservationNewPageSliceName}getSeatMapsSaga`,
        saga: getSeatMapsSaga,
    });
    useInjectSaga({
        key: `${reservationNewPageSliceName}calculateCostSaga`,
        saga: calculateCostSaga,
    });
    useInjectSaga({
        key: `${reservationNewPageSliceName}submitSaga`,
        saga: createReservationSaga,
    });
    useInjectSaga({
        key: 'updateReservationSaga',
        saga: updateReservationSaga,
    });
    useInjectSaga({
        key: 'setSelectedOwnerByIdSaga',
        saga: setGetSelectedOwnerAccountSaga,
    });
    useInjectSaga({
        key: 'setSelectedPassengerSaga',
        saga: setSelectedPassengerSaga,
    });
};
