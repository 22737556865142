import { AdminStepTravelDate, AdminStepTravelDateForm, AdminStepTravelDateProps } from '@malesia/react-components/dist/src/components/Reservation/StepTravelDate/AdminStepTravelDate';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReservationAllowed } from '../../permissions';
import { selectBookedFlightsDate, selectFlightLists, selectPassengersAges, selectReservation, selectTravelType } from '../../store/selectors';
import { reservationNewPageActions } from '../../store/slice';
import { useAdminStepTravelDateForm } from './useAdminStepTravelDateForm';
import { createAdminReservationBasketByTravel } from './utils';

export const StepTravelDate: SFC = () => {
    const dispatch = useDispatch();
    const allowed = useReservationAllowed();
    const bookedFlightsDate = useSelector(selectBookedFlightsDate);
    const flightLists = useSelector(selectFlightLists);
    const passengersAges = useSelector(selectPassengersAges);

    const {
        config,
        setTravelType,
        travelType,
        validator,
        airportToList,
        airportFromList,
        otherAirportFromList,
        otherAirportToList,
    } = useAdminStepTravelDateForm();

    const initialTravelType = useSelector(selectTravelType);

    const prevReservation = useSelector(selectReservation);

    const submit = useCallback((formData: AdminStepTravelDateForm) => {
        const reservation = createAdminReservationBasketByTravel(formData, travelType, prevReservation);
        dispatch(reservationNewPageActions.updateReservationState(reservation));
        dispatch(reservationNewPageActions.getFlightLists());
        dispatch(reservationNewPageActions.goToNextStep('travelDates'));
    }, [dispatch, travelType, prevReservation]);

    const adminStepTravelDateProps: AdminStepTravelDateProps = {
        airportFromList,
        airportToList,
        otherAirportToList,
        otherAirportFromList,
        formConfig: config,
        validator,
        onChangeTab: setTravelType,
        submit,
        initialTravelType,
        bookedFlightsDate,
        canSelectPastDays: allowed.travel.pastDays,
        outboundDisabled: !allowed.travel.outbound,
        disabled: (
            flightLists.isLoading
            || !allowed.travel.form
        ),
    };
    return (
        <AdminStepTravelDate
            key={JSON.stringify(passengersAges)}
            {...adminStepTravelDateProps}
        />
    );
};
