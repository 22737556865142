import { OptionEntireList, Rate, TariffEntireList } from '@malesia/json-schema';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { RateEditPageState, UpdateRateOptions } from './types';

export const initialState: RateEditPageState = {
    rateData: undefined,
    tariffData: {
        items: [],
    },
    optionData: {
        items: [],
    },
    loading: false,
    loadingTariffs: false,
    loadingOptions: false,
};

const rateEditPageSlice = createSlice({
    name: 'rateEditPage',
    initialState,
    reducers: {
        getTariffList(state) {
            state.loadingTariffs = true;
        },
        getTariffListSuccess(state, action: PayloadAction<TariffEntireList>) {
            state.tariffData = action.payload;
            state.loadingTariffs = false;
        },
        getTariffListError(state) {
            state.loadingTariffs = false;
        },
        getOptionList(state) {
            state.loadingOptions = true;
        },
        getOptionListSuccess(state, action: PayloadAction<OptionEntireList>) {
            state.optionData = action.payload;
            state.loadingOptions = false;
        },
        getOptionListError(state) {
            state.loadingOptions = false;
        },
        getRateData(state, action: PayloadAction<string>) {
            state.loading = true;
        },
        getRateDataSuccess(state, action: PayloadAction<Rate>) {
            state.rateData = action.payload;
            state.loading = false;
        },
        getRateDataError(state) {
            state.loading = false;
        },
        updateRateData(state, action: PayloadAction<UpdateRateOptions>) {
            state.loading = true;
        },
        updateRateDataSuccess(state) {
            state.loading = false;
        },
        updateRateDataError(state) {
            state.loading = false;
        },
    },
});

export const { actions, reducer, name: sliceKey } = rateEditPageSlice;
