import { PopupConfirm, PopupConfirmProps } from '@malesia/react-components/dist/src/components/Popup/Confirm';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import { toInputDateString } from '@malesia/react-components/dist/src/utils/dateTime';
import { createFullName } from '@malesia/react-components/dist/src/utils/fullName';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectSamePassengers, selectIsOpen } from './selectors';
import { reservationSamePassengersActions } from './slice';

export const SamePassengersPopup: SFC = () => {
    const dispatch = useDispatch();

    const isOpen = useSelector(selectIsOpen);
    const samePassengers = useSelector(selectSamePassengers);

    const popupConfirmProps: PopupConfirmProps = {
        isOpened: isOpen,
        onConfirm: () => dispatch(reservationSamePassengersActions.checkSamePassengersConfirm()),
        onClose: () => dispatch(reservationSamePassengersActions.checkSamePassengersCancel()),
        confirmMessage: (
            <FormattedMessage
                id='front-app/ReservationNewPage/SamePassengerPopup:Title'
                defaultMessage='Warning: There already exists a passenger with the same data for this flight.'
            />
        ),
    };

    const renderPassengerList = (): ReactElement | null => {
        if (samePassengers.length === 0) return null;

        return (
            <ul>
                {samePassengers.map((passenger, idx) => (
                    <li key={idx}>
                        {`${createFullName(passenger.firstName, passenger.lastName)} (${toInputDateString(passenger.birthday!)})`}
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <PopupConfirm {...popupConfirmProps}>
            {renderPassengerList()}
        </PopupConfirm>
    );
};
