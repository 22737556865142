import { getTopImage } from '../getTopImage';
import {
    ContactMacedoniaPageDocument, ContactMacedoniaPageQuery,
} from '../gql-types';
import { gqlClient } from '../gqlClient';

export async function loadContactMacedoniaPageContent(locale: string) {
    const { data } = await gqlClient.query<ContactMacedoniaPageQuery>(
        ContactMacedoniaPageDocument,
        { lang: locale },
    ).toPromise();
    return {
        topImage: getTopImage(data?.contact_macedonia?.top_image),
        content: data?.contact_macedonia?.translations?.[0]?.content ?? '',
    };
}
