import { Manager } from '@malesia/json-schema';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { ManagerEditPageState, UpdateManagerOptions } from './types';

export const initialState: ManagerEditPageState = {
    managerData: undefined,
    loadingManager: false,
};

const managerEditPageSlice = createSlice({
    name: 'managerEditPage',
    initialState,
    reducers: {
        getManagerData(state, action: PayloadAction<string>) {
            state.loadingManager = true;
        },
        getManagerDataSuccess(state, action: PayloadAction<Manager>) {
            state.managerData = action.payload;
            state.loadingManager = false;
        },
        getManagerDataError(state, action: PayloadAction<any>) {
            state.loadingManager = false;
        },
        updateManagerData(state, action: PayloadAction<UpdateManagerOptions>) {
            state.loadingManager = true;
        },
        updateManagerDataSuccess(state) {
            state.loadingManager = false;
        },
        updateManagerDataError(state) {
            state.loadingManager = false;
        },
    },
});

export const { actions, reducer, name: sliceKey } = managerEditPageSlice;
