import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { permissionMaker } from '../permissionExpression';
import { PaginatedAdminRoutePermissions } from '.';

const { some, every } = permissionMaker;

const allotmentList = some(
    'ROLE_ALLOTMENT_LIST',
    'ROLE_ALLOTMENT_AGENT_ALLOTMENT_LIST',
);
const allotmentPayment = every(
    'ROLE_ALLOTMENT_LIST',
    'ROLE_ALLOTMENT_INVOICE_EDIT',
);

export const allotment: PaginatedAdminRoutePermissions['allotment'] = {
    [allAdminRoutePaths.allotmentList]: allotmentList,
    [allAdminRoutePaths.allotmentInvoiceList]: allotmentList,
    [allAdminRoutePaths.allotmentPayment]: allotmentPayment,
    [allAdminRoutePaths.allotment]: allotmentList,
};
