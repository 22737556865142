import { put, select, takeLatest } from 'typed-redux-saga';
import { getSeatIndexes } from '../../pages/FlightEditPage/utils';
import {
    selectAircraftTemplatePopupSelectedSeats,
    selectAircraftTemplatePopupAircraftTemplateSections,
    selectAircraftTemplatePopupActionData,
} from './selectors';
import { aircraftTemplatePopupActions as actions } from './slice';

function* applySeatAction() {
    const selectedSeats = yield* select(selectAircraftTemplatePopupSelectedSeats);
    const seatActionData = yield* select(selectAircraftTemplatePopupActionData);

    if (selectedSeats.length > 0) {
        for (const seatLabel of selectedSeats) {
            const sections = yield* select(selectAircraftTemplatePopupAircraftTemplateSections);
            if (sections) {
                const { sectionIndex, rowIndex, columnIndex } = getSeatIndexes(sections, seatLabel);

                if (sectionIndex !== undefined && rowIndex !== undefined && columnIndex !== undefined) {
                    yield* put(actions.setSeatPrice({
                        sectionIndex, rowIndex, columnIndex, price: seatActionData.price,
                    }));
                }
            }
        }

        yield* put(actions.clearSelectedSeats());
        yield* put(actions.resetSeatAction());
    }
}

export function* applySeatActionSaga() {
    yield* takeLatest(actions.applySeatAction, applySeatAction);
}
