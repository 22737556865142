import { PassengersSeatsOnFlights, PassengersSeatsOnFlightsProps } from '@malesia/react-components/dist/src/components/Reservation/PassengersSeatsOnFlights';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import { localizeMoney } from '@malesia/react-components/dist/src/utils/roundMoney';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useSeatsByPassenger } from '../../../../../utils/reservation/seats/useSeatsByPassenger';
import { useSeatsSelectionPopup } from '../../../../../utils/reservation/seats/useSeatsSelectionPopup';
import { selectPassengers, selectSelectedFlightVariants } from '../../../../containers/ReservationBasket/reservationBasket.selectors';
import { reservationBasketActions } from '../../../../containers/ReservationBasket/reservationBasket.slice';
import { selectSeatMapForDirection, selectSeatPriceDictionary, selectSumSeatsPrice } from '../../store/selectors';
import { bookingFlightActions } from '../../store/slice';
import { useStepSeatsReservationRequirements } from './useStepSeatsReservationRequirements';

type StepSeatReservationProps = {
    handleStepSubmit: () => void,
};
export const StepSeatReservation: SFC<StepSeatReservationProps, { result: 'optional' }> = (props) => {
    const { handleStepSubmit } = props;
    const dispatch = useDispatch();

    const totalSeatsCost = useSelector(selectSumSeatsPrice);
    const { locale } = useIntl();

    useEffect(() => {
        dispatch(bookingFlightActions.getSeatMaps());
    }, [dispatch]);

    const isStepFitRequirements = useStepSeatsReservationRequirements();

    const { seatsByPassenger } = useSeatsByPassenger({
        selectSelectedFlightVariants,
        selectPassengers,
        selectSeatPriceDictionary,
        canSeatInput: false,
    });

    const { renderSeatsSelectionPopup, openSeatsSelectionPopup, resetSeat } = useSeatsSelectionPopup({
        selectPassengers,
        selectSeatMapForDirection,
        resetSeat: reservationBasketActions.resetSeat,
        resetDirectionSeats: reservationBasketActions.resetDirectionSeats,
        selectSeat: reservationBasketActions.selectSeat,
    });

    const passengersSeatsOnFlightsProps: PassengersSeatsOnFlightsProps = {
        resetSeat,
        seatsByPassenger,
        totalPrice: localizeMoney(totalSeatsCost, locale, 'BookingFlightPage/Steps/StepSeatReservation'),
        openSeatsSelectionPopup,
        submit: handleStepSubmit,
    };
    if (!isStepFitRequirements) return null;
    return (
        <>
            <PassengersSeatsOnFlights {...passengersSeatsOnFlightsProps} />
            {renderSeatsSelectionPopup()}
        </>
    );
};
