import { FlightTab } from '@malesia/react-components/dist/src/components/Tabs/Flight';
import { verifyRate } from '@malesia/react-components/dist/src/utils/verify';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { createFlightsTabs, weekRange } from '../../../../../utils/reservation/flightsTabs';
import { SelectedFlight } from '../../../../../utils/reservation/selectedFlight';
import { selectReservationBasket } from '../../../../containers/ReservationBasket/reservationBasket.selectors';
import { reservationBasketActions } from '../../../../containers/ReservationBasket/reservationBasket.slice';
import { useReservationAllowed } from '../../../ReservationNewPage/permissions';
import { createFlightTab } from '../../StepsFlightsBlocks/createFlightTab';
import { selectOutboundFlightListData } from '../../store/selectors';

export const useFlightTab = () => {
    const dispatch = useDispatch();

    const {
        travelType,
        dateRange,
        selectedFlights,
        selectedPrices,
    } = useSelector(selectReservationBasket);
    const { locale } = useIntl();
    const allowed = useReservationAllowed();

    const isRoundTrip = travelType === 'return';

    const outboundFlightListData = useSelector(selectOutboundFlightListData);

    const flightsTabs = createFlightsTabs({
        dateISO: dateRange?.from,
        rangeOptions: weekRange,
        flightList: outboundFlightListData,
        allowed,
    });

    const selectFlight = (selectedFlight: SelectedFlight) => {
        const flight = outboundFlightListData.find(x => x.id === selectedFlight.flightId)!;
        const price = verifyRate(flight.rate)!.priceList!.find(x => x.tariff?.id === selectedFlight.tariffId)!;
        dispatch(reservationBasketActions.setSelectedFlights({
            ...(selectedFlights ?? {}),
            outbound: flight,
        }));
        dispatch(reservationBasketActions.setSelectedPrices({
            ...(selectedPrices ?? {}),
            outbound: price,
        }));
    };

    const tabsOutboundFlight = flightsTabs.reduce<FlightTab[]>((acc, tab) => {
        const getSelectedFlight = (): SelectedFlight | undefined => {
            const flight = selectedFlights?.outbound;
            const price = selectedPrices?.outbound;
            if (!flight || !price) return undefined;
            return {
                flightId: flight.id!,
                tariffId: price.tariff!.id!,
            };
        };
        const selectedFlight = getSelectedFlight();

        const dateTab = createFlightTab({
            flightsByDate: tab.flights,
            isRoundTrip,
            selectFlight,
            selectedFlight,
            rangeIndexDate: tab.date,
            flightCardWithoutDetail: false,
            allowed,
            locale,
        });

        acc.push(dateTab);
        return acc;
    }, []);

    return {
        tabsOutboundFlight,
    };
};
