import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { CashbackPdfState as CashbackPdfState, DownloadActionType } from './types';

export const initialState: CashbackPdfState = {
    isLoadingMap: {},
};

const cashbackPdfSlice = createSlice({
    name: 'cashbackPdf',
    initialState,
    reducers: {
        downloadCashbackPdf(state, action: PayloadAction<DownloadActionType>) {
            state.isLoadingMap[action.payload.id] = true;
        },
        downloadCashbackPdfSuccess(state, action: PayloadAction<string | number>) {
            const transactionId = action.payload;
            state.isLoadingMap[transactionId] = false;
        },
        downloadCashbackPdfFail(state, action: PayloadAction<string | number>) {
            const transactionId = action.payload;
            state.isLoadingMap[transactionId] = false;
        },
        unmount() {
            // Not reset. It's global loading.
        },
    },
});

export const {
    actions: cashbackPdfActions,
    reducer: cashbackPdfReducer,
    name: cashbackPdfSliceKey,
} = cashbackPdfSlice;
