import { RouteItem } from '@malesia/react-components/dist/src/components/types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { allAdminRoutePaths } from './allAdminRoutePaths';

export const appAdminRoutes: RouteItem[] = [
    {
        routeKey: 'balance',
        linkRoute: allAdminRoutePaths.invoice,
        linkSubject: (
            <FormattedMessage
                id='front-app/Routes/BALANCE_LOG:Title'
                defaultMessage='Invoices'
            />
        ),
        iconName: 'bill',
        LinkComponent: (props: React.PropsWithChildren<unknown>) => (
            <Link to={allAdminRoutePaths.invoice}>
                {React.Children.only(props.children)}
            </Link>
        ),
    },
    {
        routeKey: 'flight',
        linkRoute: allAdminRoutePaths.flight,
        linkSubject: (
            <FormattedMessage
                id='front-app/Routes/FLIGHT:Title'
                defaultMessage='Flights'
            />
        ),
        iconName: 'airplane',
        LinkComponent: (props: React.PropsWithChildren<unknown>) => (
            <Link to={allAdminRoutePaths.flight}>
                {React.Children.only(props.children)}
            </Link>
        ),
    },
    {
        routeKey: 'reservationList',
        linkRoute: allAdminRoutePaths.reservation,
        linkSubject: (
            <FormattedMessage
                id='front-app/Routes/RESERVATION_STEP_TRAVEL_DATES:Title'
                defaultMessage='Reservations'
            />
        ),
        iconName: 'seat',
        LinkComponent: (props: React.PropsWithChildren<unknown>) => (
            <Link to={allAdminRoutePaths.reservation}>
                {React.Children.only(props.children)}
            </Link>
        ),
    },
    {
        routeKey: 'user',
        linkRoute: allAdminRoutePaths.user,
        linkSubject: (
            <FormattedMessage
                id='front-app/Routes/USERS:Title'
                defaultMessage='Users'
            />
        ),
        iconName: 'customer',
        LinkComponent: (props: React.PropsWithChildren<unknown>) => (
            <Link to={allAdminRoutePaths.user}>
                {React.Children.only(props.children)}
            </Link>
        ),
    },
    {
        routeKey: 'allotment',
        linkRoute: allAdminRoutePaths.allotment,
        linkSubject: (
            <FormattedMessage
                id='front-app/Routes/Allotment:Title'
                defaultMessage='Allotments'
            />
        ),
        iconName: 'iconmonstr-retweet-1-2',
        LinkComponent: (props: React.PropsWithChildren<unknown>) => (
            <Link to={allAdminRoutePaths.allotment}>
                {React.Children.only(props.children)}
            </Link>
        ),
    },
    {
        routeKey: 'rate',
        linkRoute: allAdminRoutePaths.rate,
        linkSubject: (
            <FormattedMessage
                id='front-app/Routes/RATE:Title'
                defaultMessage='Rates'
            />
        ),
        iconName: 'money',
        LinkComponent: (props: React.PropsWithChildren<unknown>) => (
            <Link to={allAdminRoutePaths.rate}>
                {React.Children.only(props.children)}
            </Link>
        ),
    },
    {
        routeKey: 'settings',
        linkRoute: allAdminRoutePaths.settings,
        linkSubject: (
            <FormattedMessage
                id='front-app/Routes/SETTINGS:Title'
                defaultMessage='Settings'
            />
        ),
        iconName: 'settings',
        LinkComponent: (props: React.PropsWithChildren<unknown>) => (
            <Link to={allAdminRoutePaths.settings}>
                {React.Children.only(props.children)}
            </Link>
        ),
    },
];

export const tabRoutesAdminSiteMap: Record<string, string> = {
    [allAdminRoutePaths.reservationNew]: allAdminRoutePaths.reservationList,
};
