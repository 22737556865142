import { FrontPageContentBlock } from '@malesia/react-components/dist/src/components/Front/Page/ContentBlock';
import { FrontPageImage } from '@malesia/react-components/dist/src/components/Front/Page/MainImage';
import { FrontPageTitle } from '@malesia/react-components/dist/src/components/Front/Page/Title';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { loginSaga } from '../../containers/UserLogin/saga';
import { loginSliceKey } from '../../containers/UserLogin/slice';
import { recoverPasswordModalActions } from '../../modals/RecoverPasswordModal/slice';
import { AppLoginForm } from '../../sharedComponents/AppLoginForm';
import messages from './messages';
import { reducer, sliceKey } from './slice';

export const MalesiaLoginPage: SFC = () => {
    useInjectReducer({ key: sliceKey, reducer });
    useInjectSaga({
        key: `${loginSliceKey}Login`,
        saga: loginSaga,
    });

    const dispatch = useDispatch();

    /**
   * Handle forgot button
   * Close this modal and open forgot modal
   */
    const handleForgot = () => {
        dispatch(recoverPasswordModalActions.openModal());
    };

    return (
        <>
            <FrontPageImage src='/images/front-page-image-history.jpg' />
            <FrontPageContentBlock>
                <FrontPageTitle
                    text={
                        <FormattedMessage
                            {...messages.header}
                            defaultMessage='Login'
                        />
                    }
                />
                <AppLoginForm handleForgot={handleForgot} />
            </FrontPageContentBlock>
        </>
    );
};
