import {
    ReservationCalculatePostRequest,
    ReservationCalculatePostResponse,
} from '@malesia/json-schema';
import { call } from 'typed-redux-saga';
import { apiClientRequest } from '../ApiClient';

export function* calculateApiReservationCost(params: ReservationCalculatePostRequest) {
    const response: ReservationCalculatePostResponse = yield* call(
        apiClientRequest, {
            requestId: 'reservationCalculate',
            requestPayload: params,
        });
    return response;
}
