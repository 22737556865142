import React from 'react';
import { FormattedMessage } from 'react-intl';
import { appNotification } from '../../../containers/App/appNotification';
import { createLocalNotification } from '../../../containers/App/utils';

const notification = createLocalNotification('AllotmentList', {
    error: (
        <FormattedMessage
            id='front-app/AllotmentList/Notification/Error:title'
            defaultMessage='Allotment List Error'
        />
    ),
    info: (
        <FormattedMessage
            id='front-app/AllotmentList/Notification/Info:title'
            defaultMessage='Allotment List Info'
        />
    ),
    success: (
        <FormattedMessage
            id='front-app/AllotmentList/Notification/Info:title'
            defaultMessage='Allotment List Info'
        />
    ),
});

export const allotmentListNotifications = {
    unknownError: (details?: JSX.Element | string) => (
        notification.error(appNotification.texts.unknownError(details))
    ),
};
