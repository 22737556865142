import { FlightCompanyPaginatedList } from '@malesia/json-schema';
import { SortingDataType } from '@malesia/react-components/dist/src/components/Table';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { FlightCompanyFilterData, FlightCompanyListPageState } from './types';

export const initialState: FlightCompanyListPageState = {
    initialized: {
        sorting: false,
    },
    isDirty: true,
    isLoading: true,

    flightCompanyList: {
        items: [],
        total: 0,
    },
    filterData: {
        searchText: undefined,
        isActiveOnly: true,
        sortBy: 'name',
        sortOrder: 'ASC',
    },
    pagination: {
        page: 0,
        pageSize: 15,
    },
};

const flightCompanyListPageSlice = createSlice({
    name: 'flightCompanyListPage',
    initialState,
    reducers: {
        updateFilterData(state, action: PayloadAction<FlightCompanyFilterData>) {
            state.filterData = { ...state.filterData, ...action.payload };
            state.pagination.page = initialState.pagination.page;
            state.isDirty = true;
        },
        setPage(state, action: PayloadAction<number>) {
            state.pagination.page = action.payload;
            state.isDirty = true;
        },
        setPageSize(state, action: PayloadAction<number>) {
            state.pagination.pageSize = action.payload;
            state.pagination.page = initialState.pagination.page;
            state.isDirty = true;
        },
        setSorting(state, action: PayloadAction<SortingDataType>) {
            const data = action.payload;
            state.filterData = {
                ...state.filterData,
                sortBy: data.sortBy,
                sortOrder: data.sortOrder,
            };
            state.isDirty = true;
            state.initialized.sorting = true;
        },
        getFlightCompanyList(state) {
            state.isLoading = true;
        },
        getFlightCompanyListSuccess(state, action: PayloadAction<FlightCompanyPaginatedList>) {
            state.flightCompanyList = action.payload;
            state.isLoading = false;
            state.isDirty = false;
        },
        getFlightCompanyListError(state) {
            state.isLoading = false;
        },
    },
});

export const { actions, reducer, name: sliceKey } = flightCompanyListPageSlice;
