import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../utils/log';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { rateNotifications } from './notification';
import { actions } from './slice';
import { apiClientRequest, getApiErrorMessage } from 'app/services/ApiClient';

export function* getTariffListData() {
    try {
        const result = yield* call(apiClientRequest, {
            requestId: 'tariffEntireList',
        });
        yield* put(actions.getTariffListDataSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'RateNewPage.getOptionListData',
        });
        yield* put(actions.getTariffListDataError());
        const message = getApiErrorMessage(error);
        yield* put(rateNotifications.unknownError(message));
    }
}

export function* getTariffListDataSaga() {
    yield* takeLatest(actions.getTariffListData, getTariffListData);
}

export function* getOptionListData() {
    try {
        const result = yield* call(apiClientRequest, {
            requestId: 'optionEntireList',
        });
        yield* put(actions.getOptionListDataSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'RateNewPage.getOptionListData',
        });
        yield* put(actions.getOptionListDataError());
        const message = getApiErrorMessage(error);
        yield* put(rateNotifications.unknownError(message));
    }
}

export function* getOptionListDataSaga() {
    yield* takeLatest(actions.getOptionListData, getOptionListData);
}

export function* createRate(action: ReturnType<typeof actions.createRate>) {
    try {
        const rateData = action.payload;

        yield* call(apiClientRequest, {
            requestId: 'rateCreate',
            requestPayload: rateData,
        });
        yield* put(actions.createRateSuccess());
        yield* put(push(allAdminRoutePaths.rateList));
        yield* put(rateNotifications.createdSuccessfully);
    }
    catch (error) {
        logError({
            error,
            target: 'RateNewPage.createRate',
        });
        yield* put(actions.createRateError());
        const message = getApiErrorMessage(error);
        yield* put(rateNotifications.unknownError(message));
    }
}

export function* createRateSaga() {
    yield* takeLatest(actions.createRate, createRate);
}
