import {
    ConfirmDatatransTransactionPostRequest,
    DatatransTransactionForAgentPostRequest as DatatransTransactionForAgentPostRequestApi,
    DatatransTransactionForAgentPostResponse as DatatransTransactionForAgentPostResponseApi,
    DatatransTransactionForReservationPostRequest,
    Reservation,
} from '@malesia/json-schema';
import { call } from 'typed-redux-saga';
import { apiClientRequest } from '../ApiClient';

export type InitTransactionParams = NonNullable<Pick<DatatransTransactionForReservationPostRequest, 'reservationId' | 'source' | 'amount' | 'additionalProp'>>;
export type InitReservationSource = InitTransactionParams['source'];
// export type Transaction = Pick<DatatransTransactionStatusResponse, 'transactionId' | 'reservationId' | 'agentId' | 'refNo' | 'amount' | 'currency' | 'fee' | 'paymentMethod' | 'createdAt' | 'updatedAt' | 'status'>
export type Transaction = any;
export type DatatransTransactionForAgentPostRequest = NonNullable<Pick<DatatransTransactionForAgentPostRequestApi, 'agentId' | 'amount' | 'source' | 'additionalProp'>>;
export type DatatransTransactionForAgentPostResponse = DatatransTransactionForAgentPostResponseApi;

function* paymentDatatransReservationInit(data: InitTransactionParams) {
    const requestData = {
        requestId: 'paymentDatatransReservationInit',
        requestPayload: data,
    };
    const response: Transaction = yield* call(apiClientRequest, requestData);
    return response;
}

function* getDatatransTransaction(id: number) {
    const requestData = {
        requestId: 'paymentDatatransTransaction',
        uriParams: { id },
    };
    const response: Transaction = yield* call(apiClientRequest, requestData);
    return response;
}

async function showDatatransPopup(transactionId: string) {
    return import(/*webpackChunkName: 'datatrans'*/ '../../../utils/datatrans.js')
        .then(datatrans => new Promise((resolve, reject) => {
            if (datatrans) {
                datatrans.startPayment({
                    transactionId,
                    opened () {
                        console.log('payment-form opened');
                    },
                    loaded () {
                        console.log('payment-form loaded');
                    },
                    closed () {
                        resolve('closed');
                    },
                    error (e) {
                        reject(e);
                    },
                });
            }
            else {
                reject('datatrans not imported');
            }
        }));
}

function* paymentDatatransTransactionUpdate(data: Pick<ConfirmDatatransTransactionPostRequest, 'transactionId'>) {
    const requestData = {
        requestId: 'paymentDatatransTransactionUpdate',
        requestPayload: data,
    };
    const response: Transaction = yield* call(apiClientRequest, requestData);
    return response;
}

function* paymentDatatransReservationConfirm(data: Pick<NonNullable<ConfirmDatatransTransactionPostRequest>, 'transactionId'>) {
    const requestData = {
        requestId: 'paymentDatatransTransactionConfirm',
        requestPayload: data,
    };
    const response: Transaction = yield* call(apiClientRequest, requestData);
    return response;
}

function* getDatatransTransactionReservation(transactionId: string) {
    const requestData = {
        requestId: 'transactionReservation',
        uriParams: { transactionId },
    };
    const response: Reservation = yield* call(apiClientRequest, requestData);
    return response;
}

function* paymentDatatransAgentInit(data: DatatransTransactionForAgentPostRequest) {
    const requestData = {
        requestId: 'paymentDatatransAgentInit',
        requestPayload: data,
    };
    const response: DatatransTransactionForAgentPostResponse = yield* call(apiClientRequest, requestData);
    return response;
}

export const datatransService = {
    getDatatransTransactionReservation,
    paymentDatatransReservationInit,
    getDatatransTransaction,
    showDatatransPopup,
    paymentDatatransTransactionUpdate,
    paymentDatatransReservationConfirm,
    paymentDatatransAgentInit,
};
