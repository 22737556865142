import { PublicFlightListItem } from '@malesia/json-schema';
import { DATE_FORMAT } from '@malesia/react-components/dist/src/utils/dateTimeFormat';
import moment, { Moment } from 'moment';
import { FlightPlanColumn } from './types';

const getFlightByDate = (flightList: PublicFlightListItem[], date: Moment): PublicFlightListItem[] => {
    return flightList.filter(flight => moment.parseZone(flight.departure).isSame(date, 'day'));
};

const getColumnWithDateInTitle = (flightList: PublicFlightListItem[], date: Moment): FlightPlanColumn => {
    return {
        title: date.format(`dd ${DATE_FORMAT}`),
        items: getFlightByDate(flightList, date),
        withDate: false,
    };
};

export const createColumns = (flightList: PublicFlightListItem[], today: Moment): FlightPlanColumn[] => {
    const tomorrow = today.clone().add(1, 'day');

    const todayColumn: FlightPlanColumn = {
        title: 'Today',
        items: getFlightByDate(flightList, today),
    };
    const tomorrowColumn: FlightPlanColumn = {
        title: 'Tomorrow',
        items: getFlightByDate(flightList, tomorrow),
    };

    const otherColumns = Array.from({ length: 5 }).map((_, index) => {
        return getColumnWithDateInTitle(flightList, today.clone().add(index + 2, 'day'));
    });

    return [todayColumn, tomorrowColumn, ...otherColumns];
};
