import { ReservationListItem, ReservationPaginatedList, UserProfile, FileMalesiareisenComJsonSchemaProjectApiCallReservationSortingParamsDefJson } from '@malesia/json-schema';
import { ReservationListFilterData } from '@malesia/react-components/dist/src/components/Reservation/List/Filter/types';
import { NotificationFormData } from '@malesia/react-components/dist/src/components/Reservation/List/Notification';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../../utils/@reduxjs/toolkit';
import { ReservationListPageState } from './types';

export const initialState: ReservationListPageState = {
    filterData: {
        isActive: true,
        reservationStatus: ['created', 'confirmed', 'reserved'],
    },
    table: {
        list: {
            total: 0,
            items: [],
        },
        selectedRows: [],
        pagination: {
            page: 0,
            pageSize: 15,
        },
        sorting: {
            sortBy: 'createdAt',
            sortOrder: 'DESC',
        },

        initialized: {
            reservator: false,
            owner: false,
            sorting: false,
        },
        dirty: true,
        loading: true,
    },
    smsNotificationPopup: {
        formData: {
            content: '',
        },
    },
    cancelPopup: {},
};

const reservationListPageSlice = createSlice({
    name: 'reservationListPage',
    initialState,
    reducers: {
        getReservationList(state) {
            state.table.loading = true;
        },
        getReservationListSuccess(state, action: PayloadAction<ReservationPaginatedList>) {
            state.table.list = action.payload;
            state.table.dirty = false;
            state.table.loading = false;
        },
        getReservationListFail(state) {
            state.table.loading = false;
        },
        setReservator(state, action: PayloadAction<UserProfile | undefined>) {
            state.table.dirty = true;
            state.table.initialized.reservator = true;
            state.filterData.reservator = action.payload;
        },
        setOwner(state, action: PayloadAction<UserProfile | undefined>) {
            state.table.dirty = true;
            state.table.initialized.owner = true;
            state.filterData.owner = action.payload;
        },
        setFilterData(state, action: PayloadAction<ReservationListFilterData>) {
            state.table.dirty = true;
            state.table.pagination.page = initialState.table.pagination.page;
            state.filterData = action.payload;
        },
        resetFilterData(state) {
            state.filterData = initialState.filterData;
            state.table.pagination.page = initialState.table.pagination.page;
            state.table.sorting = initialState.table.sorting;
            state.table.dirty = true;
        },
        setNotificationFilterData(state, action: PayloadAction<NotificationFormData>) {
            if (JSON.stringify(action.payload) !== JSON.stringify(state.smsNotificationPopup.formData)) {
                state.smsNotificationPopup.formData = action.payload;
            }
        },
        setSelectedRows(state, action: PayloadAction<ReservationListItem[]>) {
            if (JSON.stringify(state.table.selectedRows) === JSON.stringify(action.payload)) return;
            state.table.selectedRows = action.payload;
        },
        // eslint-disable-next-line max-len
        setSorting(state, action: PayloadAction<FileMalesiareisenComJsonSchemaProjectApiCallReservationSortingParamsDefJson>) {
            state.table.sorting = action.payload;
            state.table.dirty = true;
            state.table.initialized.sorting = true;
        },
        setPage(state, action: PayloadAction<number>) {
            state.table.pagination.page = action.payload;
            state.table.dirty = true;
        },
        setPageSize(state, action: PayloadAction<number>) {
            state.table.pagination.pageSize = action.payload;
            state.table.pagination.page = initialState.table.pagination.page;
            state.table.dirty = true;
        },
        openCancelReservation(state, action: PayloadAction<ReservationListItem>) {
            state.cancelPopup.reservation = action.payload;
            state.cancelPopup.open = true;
        },
        closeCancelReservation(state) {
            state.cancelPopup = initialState.cancelPopup;
        },
        confirmCancelReservation(state, action: PayloadAction<number>) {
            state.cancelPopup.loading = true;
        },
        confirmCancelReservationSuccess(state) {
            state.cancelPopup = initialState.cancelPopup;
        },
        confirmCancelReservationFail(state) {
            state.cancelPopup.loading = false;
        },
        openSmsNotificationPopup(state) {
            state.smsNotificationPopup.open = true;
        },
        closeSmsNotificationPopup(state) {
            state.smsNotificationPopup = initialState.smsNotificationPopup;
        },
        confirmSmsNotification(state) {
            state.smsNotificationPopup.loading = true;
        },
        confirmSmsNotificationSuccess(state) {
            state.smsNotificationPopup = initialState.smsNotificationPopup;
        },
        confirmSmsNotificationFail(state) {
            state.smsNotificationPopup.loading = false;
        },
        unmount(state) {
            return {
                ...initialState,
                filterData: state.filterData,
                table: {
                    ...initialState.table,
                    pagination: state.table.pagination,
                    sorting: state.table.sorting,
                },
            };
        },
    },
});

export const { actions, reducer, name: sliceKey } = reservationListPageSlice;
