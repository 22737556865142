import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => {
    return state.reservationPdf || initialState;
};
export const selectIsLoadingOverviewMap = createSelector(
    [selectDomain],
    state => state.isLoadingOverviewMap,
);

export const selectIsLoadingInvoiceMap = createSelector(
    [selectDomain],
    state => state.isLoadingInvoiceMap,
);
