import { FlightPassengerEntireList, FlightPassengerListItem } from '@malesia/json-schema';
import { call } from 'typed-redux-saga';
import { apiClientRequest } from '../ApiClient';

export function* getFlightPassengerList(flightId: number) {
    const response: FlightPassengerEntireList = yield* call(apiClientRequest, {
        requestId: 'flightPassengerList',
        uriParams: { flightId },
    });

    return response;
}

export function* getFlightsPassengers(outboundFlightId?: number, returnFlightId?: number) {
    const flightPassengers: FlightPassengerListItem[] = [];

    if (outboundFlightId) {
        const existingOutboundPassengers = yield* call(getFlightPassengerList, outboundFlightId);
        flightPassengers.push(...existingOutboundPassengers.items);
    }

    if (returnFlightId) {
        const existingReturnPassengers = yield* call(getFlightPassengerList, returnFlightId);
        flightPassengers.push(...existingReturnPassengers.items);
    }

    return flightPassengers;
}
