import { Notifications } from '@malesia/react-components/dist/src/components/Page/Notifications/Notifications';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectNotifications } from '../App/selectors';
import { appActions } from '../App/slice';

export const AppNotifications: SFC<object, { result: 'optional' }> = () => {
    const dispatch = useDispatch();

    const notifications = useSelector(selectNotifications);
    const onDeleteNotification = useCallback((notificationId: string) => {
        dispatch(appActions.deleteNotification(notificationId));
    }, [dispatch]);

    const root = document.getElementById('notifications');

    if (root === null) return null;

    return ReactDOM.createPortal(
        (
            <Notifications
                notifications={notifications}
                onDeleteNotification={onDeleteNotification}
            />
        ),
        root,
    );
};
