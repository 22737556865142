import * as queryString from 'query-string';
import { call, cancelled, select } from 'typed-redux-saga';
import { proceedRequestFor } from '../../../api/malesia';
import { checkInvalidCredentials } from '../../../api/malesia/ApiResponseError';
import JsonSchemaValidationError from '../../../api/malesia/JsonSchemaValidationError';
import { ApiRequestMapType, ProceedRequestType } from '../../../api/malesia/types';
import { logError } from '../../../utils/log';
import { handleExpireToken } from '../../containers/UserLogin/saga';
import { selectUserLoginAuthToken } from '../../containers/UserLogin/selectors';

const removeTmpIdForRequest = (obj: unknown) => {
    const removeTmpId = (obj: unknown) => {
        if (typeof obj !== 'object' || !obj) return obj;

        if (Array.isArray(obj)) {
            obj.forEach(removeTmpId);
        }
        else {
            Object.keys(obj).forEach((key) => removeTmpId(obj[key]));
            if ('tmpId' in obj) {
                (obj as any).tmpId = undefined;
            }
        }

        return obj;
    };

    if (typeof obj !== 'object' || !obj) return obj;
    return removeTmpId(JSON.parse(JSON.stringify(obj)));
};

export type ApiClient = typeof apiClientRequest;

export function* apiClientRequest(
    requestData: ProceedRequestType<ApiRequestMapType>,
) {
    const authToken = yield* select(selectUserLoginAuthToken);
    let query = requestData.query;
    const requestDataWithQuery = Object.assign({}, requestData);
    requestDataWithQuery.requestPayload = removeTmpIdForRequest(requestDataWithQuery.requestPayload);
    if (typeof query === 'object') {
        query = queryString.stringify(query, {
            skipNull: true,
            arrayFormat: 'bracket',
        });
        requestDataWithQuery.query = query;
    }
    const abortController = new AbortController();
    const requestDataWithToken: ProceedRequestType<ApiRequestMapType> = {
        ...requestDataWithQuery,
        authToken,
        signal: abortController.signal,
    };

    try {
        return yield* call(proceedRequestFor, requestDataWithToken) as any;
    }
    catch (error) {
        if (error instanceof JsonSchemaValidationError) {
            // Schema validation error on sending or receiving stage is a bug
            logError({
                error,
                target: 'apiClientRequest.Schema',
            });
        }
        if (checkInvalidCredentials(error) && !requestData.skipAutoLogout) {
            yield* call(handleExpireToken);
        }
        throw error;
    }
    finally {
        const isCancelled = yield* cancelled();
        if (isCancelled) {
            abortController.abort();
        }
    }
}

export type ApiErrorResponse = {
    code: number,
    message: string,
    errors: {
        error: string,
        details: any,
    }[],
};

export type ApiError = {
    response: ApiErrorResponse,
};
export const checkApiError = (error: unknown): error is ApiError => (
    typeof error === 'object' && !!error && 'response' in error
);
export const getApiErrorMessage = (error: unknown) => {
    if (!checkApiError(error)) return undefined;
    return error.response.message;
};
