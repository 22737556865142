import { createSlice } from '../../../../utils/@reduxjs/toolkit';
import { AgentPaymentState } from './AgentPayment.types';

export const initialState: AgentPaymentState = {};

const agentNewPageSlice = createSlice({
    name: 'agentPaymentPage',
    initialState,
    reducers: {
        onLoad() {},
    },
});

export const { actions: agentPaymentActions, reducer: agentPaymentReducer } = agentNewPageSlice;
