import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { permissionMaker } from '../permissionExpression';
import { PaginatedAdminRoutePermissions } from '.';

const { some, role } = permissionMaker;

const userAgentEdit = role('ROLE_AGENT_READ');
const userAgentList = role('ROLE_AGENT_LIST');
const userAgentNew = role('ROLE_AGENT_CREATE');
const userCustomerEdit = role('ROLE_CUSTOMER_READ');
const userCustomerList = role('ROLE_CUSTOMER_LIST');
const userCustomerNew = role('ROLE_CUSTOMER_CREATE');
const userManagerEdit = role('ROLE_MANAGER_READ');
const userManagerList = role('ROLE_MANAGER_LIST');
const userManagerNew = role('ROLE_MANAGER_EDIT');

export const user: PaginatedAdminRoutePermissions['user'] = {
    [allAdminRoutePaths.userAgentEdit]: userAgentEdit,
    [allAdminRoutePaths.userAgentList]: userAgentList,
    [allAdminRoutePaths.userAgentNew]: userAgentNew,
    [allAdminRoutePaths.userCustomerEdit]: userCustomerEdit,
    [allAdminRoutePaths.userCustomerList]: userCustomerList,
    [allAdminRoutePaths.userCustomerNew]: userCustomerNew,
    [allAdminRoutePaths.userManagerEdit]: userManagerEdit,
    [allAdminRoutePaths.userManagerList]: userManagerList,
    [allAdminRoutePaths.userManagerNew]: userManagerNew,
    [allAdminRoutePaths.user]: some(
        userAgentList,
        userCustomerList,
        userManagerList,
    ),
};
