import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../utils/log';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { mapAgentToPutRequest } from '../../services/agent';
import { apiClientRequest, getApiErrorMessage } from '../../services/ApiClient';
import { agentNotifications } from '../AgentNewPage/notification';
import { actions } from './slice';

export function* getAgentData(action: ReturnType<typeof actions.getAgentData>) {
    try {
        const agentId = action.payload;

        const result = yield* call(apiClientRequest, {
            requestId: 'agentData',
            uriParams: { id: agentId },
        });
        yield* put(actions.getAgentDataSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'AgentEditPage.getAgentData',
        });
        yield* put(actions.getAgentDataError());
        const message = getApiErrorMessage(error);
        yield* put(agentNotifications.unknownError(message));
    }
}

export function* getAgentDataSaga() {
    yield* takeLatest(actions.getAgentData, getAgentData);
}

export function* updateAgentData(action: ReturnType<typeof actions.updateAgentData>) {
    try {
        const { agentId, agentData } = action.payload;

        const requestPayload = mapAgentToPutRequest(agentData);
        yield* call(apiClientRequest, {
            requestId: 'agentDataUpdate',
            requestPayload,
            uriParams: { id: agentId },
        });
        yield* put(actions.updateAgentDataSuccess());
        yield* put(push(allAdminRoutePaths.userAgentList));
        yield* put(agentNotifications.updatedSuccessfully);
    }
    catch (error) {
        logError({
            error,
            target: 'AgentEditPage.updateAgentData',
        });
        yield* put(actions.updateAgentDataError());
        const message = getApiErrorMessage(error);
        yield* put(agentNotifications.unknownError(message));
    }
}

export function* updateAgentDataSaga() {
    yield* takeLatest(actions.updateAgentData, updateAgentData);
}
