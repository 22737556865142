import {
    CustomerPostRequest,
    CustomerPutRequest,
    CustomerPaginatedList,
} from '@malesia/json-schema';
import { CustomerFormType } from '@malesia/react-components/dist/src/components/Customer/Edit';
import { call } from 'typed-redux-saga';
import { apiClientRequest } from './ApiClient';

export type GetCustomerListParams = {
    query?: string,
    name?: string,
    page?: number,
    pageSize?: number,
    isActive?: boolean,
    sortBy?: string,
    sortOrder?: string,
};
export function* getApiCustomerList(params: GetCustomerListParams) {
    const requestData = {
        requestId: 'customerList',
        query: params,
    };
    const response: CustomerPaginatedList = yield* call(apiClientRequest, requestData);
    return response;
}

export function* updateApiCustomer(user: CustomerPutRequest) {
    const requestData = {
        requestId: 'customerDataUpdate',
        uriParams: { id: user.id! },
        requestPayload: user,
    };

    return yield* call(apiClientRequest, requestData);
}

export const mapCustomerToPostRequest = (data: CustomerFormType): CustomerPostRequest => {
    return {
        email: data.email,
        language: data.language!,
        password: data.password!,
        birthday: data.birthday,
        firstName: data.firstName,
        lastName: data.lastName,
        company: data.company,
        phoneMobile: data.phoneMobile,
        phoneNumberFirst: data.phoneNumberFirst,
        phoneNumberSecond: data.phoneNumberSecond,
        street: data.street,
        zip: data.zip,
        city: data.city,
        country: data.country,
        salutation: data.salutation,
        isActive: data.isActive,
    };
};

export const mapCustomerToPutRequest = (data: CustomerFormType): CustomerPutRequest => {
    return {
        email: data.email,
        language: data.language!,
        ...data.password ? { password: data.password } : {},
        birthday: data.birthday,
        firstName: data.firstName,
        lastName: data.lastName,
        company: data.company,
        phoneMobile: data.phoneMobile!,
        phoneNumberFirst: data.phoneNumberFirst,
        phoneNumberSecond: data.phoneNumberSecond,
        street: data.street!,
        zip: data.zip!,
        city: data.city!,
        country: data.country!,
        salutation: data.salutation!,
        isActive: data.isActive,
    };
};
