import { getAdminRoutePermission } from '../../adminRoutes';
import { permissionMaker } from '../../permissionExpression';
import { balanceLogPermissions } from '../balance/balanceLog';
import { reservationListPermissions } from '../reservation/reservationList';

const { every } = permissionMaker;

const newLink = getAdminRoutePermission((x) => x.userCustomerNew);
const editLink = getAdminRoutePermission((x) => x.userCustomerEdit);
const balanceLogLink = every(
    getAdminRoutePermission(x => x.balanceLog),
    balanceLogPermissions.accountFilter,
);
const reservationsByOwnerLink = every(
    getAdminRoutePermission((x) => x.reservationList),
    reservationListPermissions.ownerFilter,
);

export const customerListPermissions = {
    newLink,
    editLink,
    balanceLogLink,
    reservationsByOwnerLink,
};
