import { allotmentPayment } from './allotmentPayment';
import { flight } from './flight';
import { reservation } from './reservation';
import { reservationPayment } from './reservationPayment';

export const backLinks = {
    allotmentPayment,
    flight,
    reservation,
    reservationPayment,
};
