import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => {
    return state.reservationListPage || initialState;
};

export const selectFilterData = createSelector(
    [selectDomain],
    state => state.filterData,
);

export const selectInitialized = createSelector(
    [selectDomain],
    state => state.table.initialized,
);

export const selectList = createSelector(
    [selectDomain],
    state => state.table.list,
);

export const selectLoading = createSelector(
    [selectDomain],
    state => state.table.loading,
);

export const selectDirty = createSelector(
    [selectDomain],
    state => state.table.dirty,
);

export const selectPagination = createSelector(
    [selectDomain],
    state => state.table.pagination,
);

export const selectSorting = createSelector(
    [selectDomain],
    state => state.table.sorting,
);

export const selectSelectedRows = createSelector(
    [selectDomain],
    state => state.table.selectedRows,
);

export const selectNotificationPopupFormData = createSelector(
    [selectDomain],
    state => state.smsNotificationPopup.formData,
);

export const selectNotificationPopupOpen = createSelector(
    [selectDomain],
    state => state.smsNotificationPopup.open,
);

export const selectNotificationPopupLoading = createSelector(
    [selectDomain],
    state => state.smsNotificationPopup.loading,
);

export const selectCancelPopupReservation = createSelector(
    [selectDomain],
    state => state.cancelPopup.reservation,
);

export const selectCancelPopupOpen = createSelector(
    [selectDomain],
    state => state.cancelPopup.open,
);

export const selectCancelPopupLoading = createSelector(
    [selectDomain],
    state => state.cancelPopup.loading,
);
