import { StepSelectFlight } from '@malesia/react-components/dist/src/components/Reservation/StepSelectFlight';
import { FlightTab } from '@malesia/react-components/dist/src/components/Tabs/Flight';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import { verifyRate } from '@malesia/react-components/dist/src/utils/verify';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { createFlightsTabs, weekRange } from '../../../../../utils/reservation/flightsTabs';
import { SelectedFlight } from '../../../../../utils/reservation/selectedFlight';
import { selectPassengersAges, selectReservationBasket } from '../../../../containers/ReservationBasket/reservationBasket.selectors';
import { reservationBasketActions } from '../../../../containers/ReservationBasket/reservationBasket.slice';
import { BasketSelectedPrices } from '../../../../containers/ReservationBasket/reservationBasket.types';
import { selectDefaultMainAirport } from '../../../../containers/SharedData/selectors';
import { useReservationAllowed } from '../../../ReservationNewPage/permissions';
import { createFlightTab } from '../../StepsFlightsBlocks/createFlightTab';
import { selectReturnFlightActiveTab, selectReturnFlightListData, selectReturnFlightListLoading } from '../../store/selectors';
import { bookingFlightActions } from '../../store/slice';

type Props = {
    handleStepSubmit: () => void,
};

export const StepFlightReturn: SFC<Props, { result: 'optional' }> = (props) => {
    const { handleStepSubmit } = props;
    const reservationBasket = useSelector(selectReservationBasket);

    const { locale } = useIntl();
    const allowed = useReservationAllowed();

    const defaultMainAirport = useSelector(selectDefaultMainAirport);
    const {
        dateRange,
        mainAirports = defaultMainAirport,
        otherAirports,
        selectedFlights,
        selectedPrices,
        travelType,
    } = reservationBasket;

    // step requirements
    const stepRequiredReservationValues = [
        dateRange?.from,
        mainAirports?.from,
        mainAirports?.to,
        travelType,
    ];
    const isStepFitRequirements = stepRequiredReservationValues.indexOf(undefined) === -1;

    const isReturnFlightEnable = travelType !== 'one-way';
    const isRoundTrip = travelType === 'return';

    const dispatch = useDispatch();

    const returnFlightListData = useSelector(selectReturnFlightListData);
    const returnFlightListLoading = useSelector(selectReturnFlightListLoading);
    const returnFlightActiveTab = useSelector(selectReturnFlightActiveTab);

    const secondFlightAirports = travelType === 'other-return'
        ? otherAirports
        : {
            from: mainAirports?.to,
            to: mainAirports?.from,
        };
    const returnAirportsFromToText = `${secondFlightAirports?.from?.name?.[locale]} - ${secondFlightAirports?.to?.name?.[locale]}`;

    const passengersAges = useSelector(selectPassengersAges);

    useEffect(() => {
        if (
            isStepFitRequirements
            && isReturnFlightEnable
            && secondFlightAirports?.from
            && secondFlightAirports?.to
            && dateRange?.to
        ) {
            dispatch(bookingFlightActions.getReturnFlightList({
                departureDate: dateRange.to,
                fromAirportCode: secondFlightAirports.from.code,
                toAirportCode: secondFlightAirports.to.code,
                passengersAges,
            }));
        }
    }, [
        dispatch,
        dateRange?.to,
        secondFlightAirports?.to,
        secondFlightAirports?.from,
        isReturnFlightEnable,
        isStepFitRequirements,
        passengersAges,
    ]);

    const flightsTabs = createFlightsTabs({
        dateISO: dateRange?.to,
        rangeOptions: weekRange,
        flightList: returnFlightListData,
        allowed,
    });

    const selectFlight = (selectedFlight: SelectedFlight) => {
        const flight = returnFlightListData.find(x => x.id === selectedFlight.flightId)!;
        const price = verifyRate(flight.rate)!.priceList!.find(x => x.tariff?.id === selectedFlight.tariffId)!;
        dispatch(reservationBasketActions.setSelectedFlights({
            ...(selectedFlights ?? {}),
            'return': flight,
        }));
        dispatch(reservationBasketActions.setSelectedPrices({
            ...(selectedPrices ?? {}),
            'return': price,
        } as BasketSelectedPrices));
    };

    const tabsReturnFlight = flightsTabs.reduce<FlightTab[]>((acc, tab) => {
        if (!selectedFlights || !selectedPrices) return acc;
        const getSelectedFlight = (): SelectedFlight | undefined => {
            const flight = selectedFlights?.return;
            const price = selectedPrices?.return;
            if (!flight || !price) return undefined;
            return {
                flightId: flight.id!,
                tariffId: price.tariff!.id!,
            };
        };
        const dateTab = createFlightTab({
            flightsByDate: tab.flights,
            isRoundTrip,
            selectFlight,
            selectedFlight: getSelectedFlight(),
            rangeIndexDate: tab.date,
            flightCardWithoutDetail: false,
            allowed,
            locale,
        });

        acc.push(dateTab);
        return acc;
    }, []);

    const isSubmitButtonDisabled = (
        !selectedFlights?.outbound
        || !selectedFlights.return
        || !selectedPrices?.outbound
        || !selectedPrices.return
    );

    if (!isStepFitRequirements) return null;
    return (
        <StepSelectFlight
            flightDirection='return'
            isSubmitButtonDisabled={isSubmitButtonDisabled}
            onSubmit={handleStepSubmit}
            activeTab={returnFlightActiveTab}
            airportsFromToText={returnAirportsFromToText}
            flightLoading={!!returnFlightListLoading}
            flightTabs={tabsReturnFlight}
        />
    );
};
