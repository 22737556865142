import { getAdminRoutePermission } from '../../adminRoutes';
import { permissionMaker } from '../../permissionExpression';

const { role, every, some } = permissionMaker;

const newLink = getAdminRoutePermission((x) => x.flightNew);
const editLink = getAdminRoutePermission((x) => x.flightEdit);
const flightPassengerListLink = getAdminRoutePermission((x) => x.flightPassengerList);

const getFullList = role('ROLE_FLIGHT_LIST');
const getAgentList = role('ROLE_FLIGHT_AGENT_FLIGHT_LIST');
/** Not for agent flight list */
const statusFilter = getFullList;
const activeFilter = getFullList;
const readTurnoverInfo = role('ROLE_FLIGHT_READ_TURNOVER_INFO');
const readInfo = role('ROLE_FLIGHT_READ');
const activate = role('ROLE_FLIGHT_ACTIVE');
const updateStatus = role('ROLE_FLIGHT_STATUS');
const markBookedOut = role('ROLE_FLIGHT_BOOKED_OUT');
const deleteFlight = role('ROLE_FLIGHT_DELETE');
const turnoverPdf = role('ROLE_ADMIN');
const passengerReport = role('ROLE_FLIGHT_PASSENGER_LIST_BULK_ACTIONS');
const flightInfoAllotment = every(
    role('ROLE_ALLOTMENT_READ'),
    role('ROLE_ALLOTMENT_READ_COST_PER_SEAT'),
);
const bulkActions = some(
    deleteFlight,
    turnoverPdf,
    passengerReport,
);
/** Not for agent flight list */
const totalColumn = getFullList;

export const flightListPermissions = {
    newLink,
    editLink,
    flightPassengerListLink,
    getFullList,
    getAgentList,
    statusFilter,
    activeFilter,
    readTurnoverInfo,
    readInfo,
    activate,
    updateStatus,
    markBookedOut,
    deleteFlight,
    turnoverPdf,
    passengerReport,
    flightInfoAllotment,
    bulkActions,
    totalColumn,
};
