import { ReservationInvoicePaymentPostRequest } from '@malesia/json-schema';
import { push } from 'connected-react-router';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../../../utils/log';
import { getQueryParameters } from '../../../../../utils/uriUtils';
import { reservationPdfActions } from '../../../../containers/ReservationPdf/slice';
import { allAdminRoutePaths } from '../../../../containers/Routes/allAdminRoutePaths';
import { backLinks } from '../../../../containers/Routes/backLinks';
import { ReservationPaymentQueryParameters } from '../../../../containers/Routes/backLinks/reservationPayment';
import { InitTransactionParams } from '../../../../services/Datatrance/datatrans.service';
import { createApiReservationPaymentInvoice } from '../../../../services/payment';
import { getApiReservationById } from '../../../../services/Reservation';
import { paymentNotifications } from '../notification';
import { payByDatatrans } from '../payByDatatrans';
import { selectOwner, selectPaymentData, selectReservation, selectTransferTotal } from '../selectors';
import { reservationPaymentActions } from '../slice';

function* createPayment() {
    try {
        const data = yield* select(selectPaymentData);
        const transferTotal = yield* select(selectTransferTotal);
        const reservation = yield* select(selectReservation);
        const owner = yield* select(selectOwner);
        const searchParams = getQueryParameters<ReservationPaymentQueryParameters>({
            source: 'string',
            additionalProp: 'number',
        });
        const reservationId = reservation.id!;

        let paymentTypeCode: string | undefined;
        let accountId: number | undefined;
        switch (data.paymentMethod) {
            case 'account':
                paymentTypeCode = 'account';
                accountId = owner!.id;
                break;
            case 'cash':
                paymentTypeCode = 'cash';
                accountId = data.cash!.id;
                break;
            case 'bank':
                paymentTypeCode = data.bank!.virtualAccount!.code!;
                accountId = data.bank!.id;
                break;
            case 'transfer':
                paymentTypeCode = 'transfer';
                accountId = data.transfer!.id;
                break;
            case 'datatrans': {
                const params: InitTransactionParams = {
                    reservationId,
                    amount: transferTotal,
                    source: searchParams.source!,
                    additionalProp: (
                        ('additionalProp' in searchParams && searchParams.additionalProp)
                            ? `${searchParams.additionalProp}`
                            : undefined
                    ),
                };
                const res = yield* call(
                    payByDatatrans,
                    params,
                );

                if (res === 'closed') {
                    yield* put(reservationPaymentActions.createPaymentCancel());
                    return;
                }

                yield* put(push(allAdminRoutePaths.reservationList));
                yield* put(reservationPaymentActions.createPaymentSuccess());
                return;
            }
            default:
                throw new Error('Unknown paymentMethod');
        }

        const requestData: ReservationInvoicePaymentPostRequest = {
            amount: transferTotal,
            paymentTypeCode,
            reservationInvoiceId: reservation.invoice!.id,
            accountId: accountId!,
        };

        yield* call(createApiReservationPaymentInvoice, requestData);
        yield* put(paymentNotifications.paymentSuccess);
        yield* put(reservationPaymentActions.createPaymentSuccess());
        const back = backLinks.reservationPayment.back(reservation.id);
        yield* put(push(back));

        const result = yield* call(getApiReservationById, reservationId);
        if (result.status === 'confirmed') {
            yield* put(reservationPdfActions.downloadOverviewPdf({
                reservationId,
                callback: () => {},
            }));
        }
    }
    catch (error) {
        logError({
            error,
            target: 'ReservationPaymentPage.createPayment',
        });
        yield* put(paymentNotifications.issueWithPayNow);
        yield* put(reservationPaymentActions.createPaymentError());
    }
}

export function* createPaymentSaga() {
    yield* takeLatest(reservationPaymentActions.createPayment, createPayment);
}
