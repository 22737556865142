import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { MalesiaLoginPageState } from './types';

export const initialState: MalesiaLoginPageState = {
    login: '',
    password: '',
    //
    loading: false,
    error: false,
};

const malesiaLoginPageSlice = createSlice({
    name: 'malesiaLoginPage',
    initialState,
    reducers: {},
});

export const { actions, reducer, name: sliceKey } = malesiaLoginPageSlice;
