import { AllotmentInvoiceStatusType } from '@malesia/json-schema';
import { BackLink } from '@malesia/react-components/dist/src/components/BackLink';
import { LoadingForm } from '@malesia/react-components/dist/src/components/LoadingOverlay/Form';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { PaymentForm, PaymentFormProps, paymentFormConfig } from '@malesia/react-components/dist/src/components/Payment/Form';
import { AdminAddReservationLayout } from '@malesia/react-components/dist/src/components/Reservation/AdminAddReservationLayout/AdminAddReservationLayout';
import { useValueForm } from '@malesia/react-components/dist/src/hooks/valueForm';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { backLinks } from '../../../containers/Routes/backLinks';
import { reservationPaymentPermissions } from '../../../permissions/adminPages/reservation/reservationPayment';
import { useUserPermissions } from '../../../permissions/useUserPermissions';
import {
    selectCashAccounts,
    selectTransferAccounts,
    selectIsLoadingCreatePayment,
    selectPriceToPay,
    selectPaymentData,
    selectTransferTotal,
    selectAllotmentInvoice,
} from '../store/selectors';
import { allotmentPaymentActions } from '../store/slice';
import { useValidatePaymentForm } from '../useValidatePaymentForm';

const paidStatuses: AllotmentInvoiceStatusType[] = [
    'paid',
    'overpaid',
];

export const AllotmentPaymentWithStore: SFC = () => {
    const dispatch = useDispatch();
    const value = useSelector(selectPaymentData);
    const cashAccounts = useSelector(selectCashAccounts);
    const transferAccounts = useSelector(selectTransferAccounts);
    const priceToPay = useSelector(selectPriceToPay);
    const transferTotal = useSelector(selectTransferTotal);
    const allotmentInvoice = useSelector(selectAllotmentInvoice);
    const isLoadingCreatePayment = useSelector(selectIsLoadingCreatePayment);
    const userPermissions = useUserPermissions();
    const validate = useValidatePaymentForm();

    const allotmentInvoiceStatus = allotmentInvoice?.status;
    const isPaidStatus = allotmentInvoiceStatus && paidStatuses.includes(allotmentInvoiceStatus);

    const {
        field,
        isContainsError,
        validateAll,
    } = useValueForm({
        value,
        config: [paymentFormConfig, () => ({
            shape: {
                partialPayment: {
                    maxNumber: priceToPay,
                    hidden: !userPermissions.has(reservationPaymentPermissions.partialPayment),
                },
                cash: {
                    items: cashAccounts,
                    hidden: !userPermissions.has(reservationPaymentPermissions.cash) || !cashAccounts.length,
                },
                bank: {
                    hidden: true,
                },
                datatrans: {
                    hidden: true,
                },
                transfer: {
                    items: transferAccounts,
                    hidden: !userPermissions.has(reservationPaymentPermissions.transfer) || !transferAccounts.length,
                },
                account: {
                    hidden: true,
                },
            },
        })],
        onChange: (data) => {
            dispatch(allotmentPaymentActions.setPaymentData(data));
        },
    });

    const paymentProps: PaymentFormProps = {
        priceToPay,
        transferTotal,
        field,
        confirm: () => {
            if (!validateAll()) return;
            if (!validate(value, transferTotal)) return;
            dispatch(allotmentPaymentActions.createPayment());
        },
        isConfirmDisabled: value.paymentMethod === undefined || isContainsError || isPaidStatus,
    };

    return (
        <LoadingForm isLoading={isLoadingCreatePayment}>
            <header>
                <PageTitle
                    text={
                        <FormattedMessage
                            id='front-app/AllotmentPayment:PageTitle'
                            defaultMessage='Allotment Invoice Payment - {allotmentInvoice}'
                            values={{
                                allotmentInvoice: allotmentInvoice?.title,
                            }}
                        />
                    }
                />
                <BackLink to={backLinks.allotmentPayment.back()} />
            </header>
            <br />
            <AdminAddReservationLayout>
                {!paidStatuses.includes(allotmentInvoice?.status!) && (
                    <PaymentForm {...paymentProps} />
                )}
            </AdminAddReservationLayout>
        </LoadingForm>
    );
};
