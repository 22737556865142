import { Agent } from '@malesia/json-schema';
import { TableColumn } from '@malesia/react-components/dist/src/components/Table';
import { balanceColumn } from '@malesia/react-components/dist/src/components/Table/columns/balanceColumn';
import { idColumn } from '@malesia/react-components/dist/src/components/Table/columns/idColumn';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { agentListPermissions } from '../../permissions/adminPages/user/agentList';
import { useUserPermissions } from '../../permissions/useUserPermissions';
import { agentActionsColumn } from './actionsColumn';

export const useTableColumns = () => {
    const history = useHistory();
    const userPermissions = useUserPermissions();

    const result = useMemo((): TableColumn<Agent>[] => ([
        idColumn(),
        {
            Header: (
                <FormattedMessage
                    id='front-app/AgentListPage:ColumnName'
                    defaultMessage='Name'
                />
            ),
            accessor: 'displayName',
            Cell: ({ row }) => {
                const agent = row.original;
                const { displayName } = agent;

                return <span>{displayName}</span>;
            },
        },
        {
            Header: (
                <FormattedMessage
                    id='front-app/AgentListPage:ColumnEmail'
                    defaultMessage='Email'
                />
            ),
            accessor: 'email',
        },
        {
            Header: (
                <FormattedMessage
                    id='front-app/AgentListPage:ColumnPhone'
                    defaultMessage='Phone'
                />
            ),
            accessor: 'phoneNumberFirst',
            disableSortBy: true,
            disableShowForDevice: true,
        },
        {
            Header: (
                <FormattedMessage
                    id='front-app/AgentListPage:ColumnCreditLimit'
                    defaultMessage='Credit Limit'
                />
            ),
            id: 'creditLimitTotalAmount',
            canSort: true,
            Cell: ({ row }) => <span>{row.original.creditLimitTotalAmount} CHF</span>,
        },
        {
            Header: (
                <FormattedMessage
                    id='front-app/AgentListPage:ColumnMargin'
                    defaultMessage='Margin'
                />
            ),
            id: 'margin',
            Cell: ({ row }) => {
                const agent = row.original;
                const value = agent.marginType === 'percent'
                    ? `${agent.percentMargin} %`
                    : `${agent.moneyMargin} CHF`;

                return <span>{value}</span>;
            },
            disableSortBy: true,
        },
        balanceColumn({
            disableSortBy: true,
        }),
        agentActionsColumn({
            editAgent: {
                click: (agent) => {
                    const path = generatePath(allAdminRoutePaths.userAgentEdit, { agentId: agent.id! });
                    history.push(path);
                },
                notAllowed: !userPermissions.has(agentListPermissions.editAgentLink),
            },
            balanceLog: {
                click: (agent) => {
                    const path = generatePath(allAdminRoutePaths.balanceLog) + `?accountId=${agent.id!}`;
                    history.push(path);
                },
                notAllowed: !userPermissions.has(agentListPermissions.balanceLogLink),
            },
            reservationList: {
                click: (agent) => {
                    const path = generatePath(allAdminRoutePaths.reservationList) + `?reservatorId=${agent.id}`;
                    history.push(path);
                },
                notAllowed: !userPermissions.has(agentListPermissions.reservationsByReservatorLink),
            },
        }),
    ]), [
        history,
        userPermissions,
    ]);
    return result;
};
