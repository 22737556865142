import { PublicFlightPaginatedList } from '@malesia/json-schema';
import {
    DATE_FORMAT,
    DATE_OUTPUT_FORMAT,
} from '@malesia/react-components/dist/src/utils/dateTimeFormat';
import moment from 'moment';
import * as queryString from 'query-string';
import { call, put, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../utils/log';
import { actions } from './slice';
import { flightListToFlightColumns, flightsWithPriceItemListHasAvailableSeats } from './utils';
import { apiClientRequest } from 'app/services/ApiClient';

export function* loadFlights() {
    try {
        const today = moment.parseZone(new Date(), DATE_FORMAT);
        const tomorrow = today.clone().add(1, 'day');
        const nextWeek = today.clone().add(7, 'days');
        const queryParams = {
            departureFrom: tomorrow.format(DATE_OUTPUT_FORMAT),
            departureTo: nextWeek.format(DATE_OUTPUT_FORMAT),
            pageSize: 100,
        };

        const result: PublicFlightPaginatedList = yield* call(apiClientRequest, {
            requestId: 'publicFlightList',
            query: queryString.stringify(queryParams, {
                skipNull: true,
            }),
        });

        const onlyAvailableSeats = flightsWithPriceItemListHasAvailableSeats(result.items);
        const columns = flightListToFlightColumns(onlyAvailableSeats, today.clone());
        yield* put(actions.getFlightsSuccess(columns));
    }
    catch (error) {
        logError({
            error,
            target: 'FlightOverview.loadFlights',
        });
        yield* put(actions.getFlightsError());
    }
}

export function* getFlightsSaga() {
    yield* takeLatest(actions.getFlights, loadFlights);
}
