import { FrontPageImage } from '@malesia/react-components/dist/src/components/Front/Page/MainImage';
import { FrontPageTitle } from '@malesia/react-components/dist/src/components/Front/Page/Title';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { messages } from './messages';

export const NotFoundPage: SFC = () => {
    const intl = useIntl();

    return (
        <>
            <FrontPageImage src='/images/front-page-image-history.jpg' />
            <FrontPageTitle text={intl.formatMessage(messages.header)} />
        </>
    );
};
