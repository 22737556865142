import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SettingsPermissionsListFooterWithStore } from './components/SettingsPermissionsListFooterWithStore';
import { SettingsPermissionsListWithStore } from './components/SettingsPermissionsListWithStore';
import { useInjectUserPermissionsListPageStore } from './store/useInjectUserPermissionsListPageStore';

export const SettingsPermissionsList: SFC = () => {
    useInjectUserPermissionsListPageStore();

    return (
        <PageContentBlock>
            <PageTitle
                text={
                    <FormattedMessage
                        id='front-app/SettingsPermissionsList:title'
                        defaultMessage='Permissions'
                    />
                }
            />
            <SettingsPermissionsListWithStore />
            <SettingsPermissionsListFooterWithStore />
        </PageContentBlock>
    );
};
