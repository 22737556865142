import { ApiCallAllotmentInvoiceSortingParams } from '@malesia/json-schema';
import { AllotmentInvoiceList, AllotmentInvoiceListProps } from '@malesia/react-components/dist/src/components/Allotment/InvoiceList';
import { LoadingTable } from '@malesia/react-components/dist/src/components/LoadingOverlay/Table';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParameters } from '../queryParameters';
import {
    selectList,
    selectDirty,
    selectLoading,
    selectSorting,
} from '../store/selectors';
import { actions } from '../store/slice';
import { useTableColumns } from '../tableColumns';

export const AllotmentInvoiceListWithStore: SFC = () => {
    const tableData = useSelector(selectList);
    const sorting = useSelector(selectSorting);
    const isLoading = useSelector(selectLoading);
    const isDirty = useSelector(selectDirty);
    const dispatch = useDispatch();

    const { highlightAllotmentInvoiceId } = useQueryParameters();
    const tableColumns = useTableColumns();

    const setSort = useCallback((data: ApiCallAllotmentInvoiceSortingParams) => {
        dispatch(actions.setSorting(data));
    }, [dispatch]);

    const allotmentInvoiceListProps: AllotmentInvoiceListProps = {
        tableColumns,
        tableData: tableData.items,
        sorting,
        setSort,
        highlightAllotmentInvoiceId,
    };

    return (
        <LoadingTable
            isLoading={isLoading}
            isDirty={isDirty}
        >
            <AllotmentInvoiceList {...allotmentInvoiceListProps} />
        </LoadingTable>
    );
};
