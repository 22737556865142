import { useInjectReducer, useInjectSaga } from '../../../../utils/redux-injectors';
import { onLoadSaga } from './sagas/onLoad.saga';
import { onSubmitSaga } from './sagas/onSubmit.saga';
import {
    permissionsPageReducer,
    permissionsPageSliceName,
    permissionsPageActions,
} from './slice';

export const useInjectUserPermissions = () => {
    useInjectReducer({
        actions: permissionsPageActions,
        key: permissionsPageSliceName,
        reducer: permissionsPageReducer,
    });
    useInjectSaga({ key: permissionsPageSliceName + 'onLoadSaga', saga: onLoadSaga });
    useInjectSaga({ key: permissionsPageSliceName + 'onSubmitSaga', saga: onSubmitSaga });
};
