import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { useInjectReservationPdf } from '../../containers/ReservationPdf/inject';
import {
    downloadPaxListSaga,
    getFlightSaga,
    getFlightPassengerListSaga,
    getFlightSeatMapSaga,
    getPnlListSaga,
    getTariffGroupListDataSaga,
    setFlightDelayedSaga,
    setFlightDepartedSaga,
    updateFlightPassengerAppearedSaga,
    updateFlightPassengerNotifiedSaga,
    updateFlightPassengersAppearedSaga,
    updateFlightPassengerSeatSaga,
} from './saga';
import { actions, reducer, sliceKey } from './slice';

export const useInjectFlightPassengerList = () => {
    useInjectReservationPdf();
    useInjectReducer({ actions, key: sliceKey, reducer });
    useInjectSaga({
        key: sliceKey,
        saga: getFlightPassengerListSaga,
    });
    useInjectSaga({
        key: `${sliceKey}PAX`,
        saga: downloadPaxListSaga });
    useInjectSaga({
        key: `${sliceKey}PNL`,
        saga: getPnlListSaga,
    });
    useInjectSaga({
        key: `${sliceKey}Flight`,
        saga: getFlightSaga,
    });
    useInjectSaga({
        key: `${sliceKey}FlightDelayed`,
        saga: setFlightDelayedSaga,
    });
    useInjectSaga({
        key: `${sliceKey}FlightDeparted`,
        saga: setFlightDepartedSaga,
    });
    useInjectSaga({
        key: `${sliceKey}TariffGroupList`,
        saga: getTariffGroupListDataSaga,
    });
    useInjectSaga({
        key: `${sliceKey}PassengerAppeared`,
        saga: updateFlightPassengerAppearedSaga,
    });
    useInjectSaga({
        key: `${sliceKey}PassengerSeat`,
        saga: updateFlightPassengerSeatSaga,
    });
    useInjectSaga({
        key: `${sliceKey}PassengerNotified`,
        saga: updateFlightPassengerNotifiedSaga,
    });
    useInjectSaga({
        key: `${sliceKey}SeatMap`,
        saga: getFlightSeatMapSaga,
    });
    useInjectSaga({
        key: `${sliceKey}PassengersAppeared`,
        saga: updateFlightPassengersAppearedSaga,
    });
};
