import { AuthTokenPostRequest } from '@malesia/json-schema';
import {
    LoginForm,
    LoginFormChangeEvent,
    LoginFormData,
    LoginFormErrors,
} from '@malesia/react-components/dist/src/components/Auth/LoginForm';
import { ErrorObject } from 'ajv';
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getValidationErrors } from '../../../api/malesia';
import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { coercionDataToSchema } from '../../../utils/validation/coercion';
import { loginSaga } from '../../containers/UserLogin/saga';
import { selectIsLoadingLogin } from '../../containers/UserLogin/selectors';
import { loginActions, loginSliceKey } from '../../containers/UserLogin/slice';
import { formatSchemaValidationErrors, prepareNotEmptyError } from '../../containers/ValidationErrorMessage';
import {
    selectLoginFormValidationErrors,
    selectLoginFormUntouchedFields,
} from './selectors';
import { actions, reducer, sliceKey } from './slice';

type AppLoginFormProps = {
    handleForgot: () => void,
};
export const AppLoginForm = memo(function AppLoginForm(props: AppLoginFormProps) {
    useInjectReducer({ key: sliceKey, reducer });
    useInjectSaga({
        key: `${loginSliceKey}Login`,
        saga: loginSaga,
    });

    const dispatch = useDispatch();
    const formValidationErrors = useSelector(selectLoginFormValidationErrors);
    const formUntouchedFields = useSelector(selectLoginFormUntouchedFields);
    const changedUntouchedFields = Array.from(formUntouchedFields);
    const formIsLoading = useSelector(selectIsLoadingLogin);

    /**
   * Maps validation errors from ErrorObject[] to LoginFormErrors
   * @param formValidationErrors
   */
    const mapValidationErrorsToFormErrors = (
        formValidationErrors: ErrorObject[],
    ): LoginFormErrors => {
        return formatSchemaValidationErrors(formValidationErrors);
    };

    const displayedErrors = mapValidationErrorsToFormErrors(formValidationErrors);

    /**
   * Handle forgot button
   */
    const handleForgot = props.handleForgot;

    /**
   * Validate form data
   * @param formData
   */
    const validateForm = (formData: LoginFormData) => {
        const schemaValidationErrors = getValidationErrors(
            'file://malesiareisen.com/json/schema/project/api/call/auth/token/post/request.json',
            formData,
        );
        const allErrors = [
            ...(schemaValidationErrors ?? []),
            ...prepareNotEmptyError('/login', formData),
            ...prepareNotEmptyError('/password', formData),
        ];
        const isValid = allErrors.length === 0;

        dispatch(
            actions.updateValidationStatus({
                formIsValid: isValid,
                formUntouchedFields: changedUntouchedFields,
                formValidationErrors: allErrors,
            }),
        );

        return isValid;
    };

    /**
   * Handle form field change event
   * @param event
   */
    const handleFormChange = (event: LoginFormChangeEvent) => {
    // eslint-disable-next-line no-unused-vars
        const { fieldName, formData } = event;
        // Touch field
        if (changedUntouchedFields.indexOf(fieldName) >= 0) {
            changedUntouchedFields.splice(
                changedUntouchedFields.indexOf(fieldName),
                1,
            );
        }
    // Validation on typing disabled
    // validateForm(formData);
    };

    /**
   * Handle form submit
   * @param formData
   */
    const handleFormSubmit = (formData: LoginFormData) => {
        if (!validateForm(formData)) {
            return;
        }
        const { login, password } = formData;
        const postData: AuthTokenPostRequest = {
            login,
            password,
        };

        const [isValid, updatedData] = coercionDataToSchema(
            'file://malesiareisen.com/json/schema/project/api/call/auth/token/post/request.json',
            postData,
        );

        if (isValid) {
            dispatch(loginActions.login(updatedData));
        }
    };

    return (
        <LoginForm
            formErrors={displayedErrors}
            isSubmitButtonDisabled={formIsLoading}
            onChange={handleFormChange}
            onSubmit={handleFormSubmit}
            onForgot={handleForgot}
        />
    );
});
