import { ReservationOverviewInfoFlight } from '@malesia/react-components/dist/src/components/Reservation/StepReservationOverview/ReservationOverviewInfo/ReservationOverviewInfoFlights/ReservationOverviewInfoFlightsCard';
import { mapTariffGroupIdToCode } from '@malesia/react-components/dist/src/components/TariffsTable/tariffGroupMapper';
import { DATETIME_FORMAT } from '@malesia/react-components/dist/src/utils/dateTimeFormat';
import { verifyTariff } from '@malesia/react-components/dist/src/utils/verify';
import moment from 'moment';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectSelectedFlights, selectSelectedPrices } from '../../../../containers/ReservationBasket/reservationBasket.selectors';

export const usePrepareFlights = () => {
    const { locale } = useIntl();

    const selectedFlights = useSelector(selectSelectedFlights);
    const selectedPrises = useSelector(selectSelectedPrices);

    const flights: ReservationOverviewInfoFlight[] = useMemo(
        () => {
            const prices = Object.values(selectedPrises ?? {});
            return Object.values(selectedFlights ?? {}).map((basketFlight, idx) => ({
                flightNumber: basketFlight?.number!,
                rateTariffType: mapTariffGroupIdToCode(verifyTariff(prices?.[idx]?.tariff)?.group.id) ?? 'economy',
                originAirportName: (basketFlight?.origin!.name!)[locale],
                destinationAirportName: (basketFlight?.destination!.name!)[locale],
                departureDateTimes: moment.parseZone(basketFlight?.departure).format(DATETIME_FORMAT),
            }));
        },
        [selectedFlights, locale, selectedPrises]);

    return flights;
};
