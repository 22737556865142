import { LoadingOverlay, LoadingOverlayProps } from '@malesia/react-components/dist/src/components/LoadingOverlay';
import { AdminPayment, AdminPaymentFormData, AdminPaymentProps } from '@malesia/react-components/dist/src/components/Reservation/AdminPayment';
import { UpdatablePaymentType } from '@malesia/react-components/dist/src/components/Reservation/AdminPayment/components/SelectPayment';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import { AnySchema } from 'ajv';
import classnames from 'classnames';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ajv from '../../../../../api/malesia/ajvLoader';
import { reservationNotifications } from '../../../../../utils/reservation/notifyReservationError';
import { createCustomRoundValidator } from '../../../../../utils/validation/createCustomRoundValidator';
import { CustomValidator, getValidationFunction } from '../../../../../utils/validation/getValidationFunction';
import {
    selectPayment,
    selectIsCostDirty,
    selectIsCostLoading,
    selectDisplayPriceToPay,
    selectSelectedOwnerDebitOnAccount,
    selectTotalCost,
    selectIsUpdateLoading,
} from '../../store/selectors';
import { reservationNewPageActions } from '../../store/slice';
import { useConfig, usePaymentTermsConfig } from './config';
import { DeadlinePopup } from './DeadlinePopup';

const adminPaymentSchema: AnySchema = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    $id: 'adminPaymentSchema',
    title: 'adminPaymentSchema',
    type: 'object',
    properties: {
        specificPrice: {
            type: 'number',
        },
    },
};

ajv.addSchema(
    adminPaymentSchema,
    'adminPaymentSchema',
);

const customValidator: CustomValidator<Partial<AdminPaymentFormData>> = createCustomRoundValidator(['/specificPrice']);

const validator = getValidationFunction('adminPaymentSchema', [customValidator]);

export type StepPaymentProps = {
    className?: string,
    validatePrevSteps: () => boolean,
};
export const StepPayment: SFC<StepPaymentProps> = (props) => {
    const { className, validatePrevSteps } = props;
    const classNames = classnames('step-payment', className ?? '');

    const totalCalculated = useSelector(selectTotalCost);
    const priceToPay = useSelector(selectDisplayPriceToPay);
    const { existingPayments } = useSelector(selectPayment);
    const debitOnAccount = useSelector(selectSelectedOwnerDebitOnAccount);
    const isCostDirty = useSelector(selectIsCostDirty);
    const isCostLoading = useSelector(selectIsCostLoading);
    const isUpdateLoading = useSelector(selectIsUpdateLoading);
    const payment = useSelector(selectPayment);

    const dispatch = useDispatch();
    const formConfig = useConfig();
    const paymentTermsConfig = usePaymentTermsConfig();

    const changeData = useCallback((data: AdminPaymentFormData) => {
        dispatch(reservationNewPageActions.setPaymentData(data));
    }, [dispatch]);

    const onSubmit = useCallback((paymentType: UpdatablePaymentType) => {
        const formsIsValid = validatePrevSteps();
        if (!formsIsValid) {
            dispatch(reservationNotifications.checkFields);
            return;
        }
        if (paymentType === 'update') {
            dispatch(reservationNewPageActions.updateReservation());
        }
        else if (paymentType === 'bill') {
            dispatch(reservationNewPageActions.openDeadlinePopup());
        }
        else {
            dispatch(reservationNewPageActions.createReservation({ paymentType }));
        }
    }, [validatePrevSteps, dispatch]);

    const adminPaymentProps: AdminPaymentProps = {
        info: {
            totalOnOpenCalculated: payment.totalOnOpenPrice,
            totalCalculated,
            debit: debitOnAccount,
            priceToPay,
        },
        paymentTermsConfig,
        existingPayments,
        formConfig,
        data: payment,
        changeData,
        onSubmit,
        validator,
    };

    const loadingOverlayStepPaymentProps: LoadingOverlayProps = {
        isLoading: isUpdateLoading || isCostLoading || isCostDirty,
        hideLoader: !(isUpdateLoading || isCostLoading),
        loadingMessage: isCostLoading ? undefined : '',
    };
    return (
        <>
            <LoadingOverlay {...loadingOverlayStepPaymentProps}>
                <div className={classNames}>
                    <AdminPayment {...adminPaymentProps} />
                </div>
            </LoadingOverlay>
            <DeadlinePopup />
        </>
    );
};
