import { Option } from '@malesia/json-schema';
import { BackLink } from '@malesia/react-components/dist/src/components/BackLink';
import { LoadingPage } from '@malesia/react-components/dist/src/components/LoadingOverlay/Page';
import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { RateOptionEdit } from '@malesia/react-components/dist/src/components/Rate/Option/Edit';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useEffect, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { createOptionSaga, getOptionTypeListDataSaga } from './saga';
import {
    selectOptionTypeData,
    selectLoading,
    selectLoadingTypes,
} from './selectors';
import { reducer, sliceKey, actions } from './slice';

export const OptionNewPage: SFC = () => {
    useInjectReducer({ actions, key: sliceKey, reducer });
    useInjectSaga({
        key: `${sliceKey}OptionType`,
        saga: getOptionTypeListDataSaga,
    });
    useInjectSaga({ key: sliceKey, saga: createOptionSaga });

    const optionTypeData = useSelector(selectOptionTypeData);
    const loading = useSelector(selectLoading);
    const loadingTypes = useSelector(selectLoadingTypes);
    const isAnythingLoading = loading || loadingTypes;
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.getOptionTypeListData());
    }, [dispatch]);

    const onSubmitForm = useCallback((optionData: Option) => {
        dispatch(actions.createOption(optionData));
    }, [dispatch]);

    return (
        <PageContentBlock>
            <PageTitle
                text={
                    <FormattedMessage
                        id='front-app/OptionNewPage:Title'
                        defaultMessage='New option'
                    />
                }
            />
            <BackLink
                to={allAdminRoutePaths.rateOptionList}
                modificators='stick-to-title'
            />
            <LoadingPage isLoading={isAnythingLoading} />
            {!isAnythingLoading && (
                <RateOptionEdit
                    typeList={optionTypeData.items}
                    onSubmit={onSubmitForm}
                />
            )}
        </PageContentBlock>
    );
};
