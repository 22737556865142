import { StepSelectFlight } from '@malesia/react-components/dist/src/components/Reservation/StepSelectFlight';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectPassengersAges, selectReservationBasket } from '../../../../containers/ReservationBasket/reservationBasket.selectors';
import { selectDefaultMainAirport } from '../../../../containers/SharedData/selectors';
import { selectOutboundFlightActiveTab, selectOutboundFlightListLoading } from '../../store/selectors';
import { bookingFlightActions } from '../../store/slice';
import { useFlightTab } from './useFlightTab';

export type StepFlightOutboundProps = {
    handleStepSubmit: () => void,
};

export const StepFlightOutbound: SFC<StepFlightOutboundProps, { result: 'optional' }> = (props) => {
    const { handleStepSubmit } = props;

    const defaultMainAirport = useSelector(selectDefaultMainAirport);
    const {
        dateRange,
        mainAirports = defaultMainAirport,
        travelType,
        selectedFlights,
        selectedPrices,
    } = useSelector(selectReservationBasket);

    const { locale } = useIntl() || { locale: 'en' };

    const { tabsOutboundFlight } = useFlightTab();

    const stepRequiredReservationValues = useMemo(() => [
        dateRange?.from,
        mainAirports?.from,
        mainAirports?.to,
        travelType,
    ], [dateRange?.from, mainAirports?.from, mainAirports?.to, travelType]);

    const isStepFitRequirements = useMemo(
        () => stepRequiredReservationValues.every(v => v !== undefined),
        [stepRequiredReservationValues],
    );

    const dispatch = useDispatch();

    const outboundFlightListLoading = useSelector(selectOutboundFlightListLoading);
    const outboundFlightActiveTab = useSelector(selectOutboundFlightActiveTab);

    const outboundAirportsFromToText = useMemo(
        () => `${mainAirports?.from?.name?.[locale]} - ${mainAirports?.to?.name?.[locale]}`,
        [locale, mainAirports?.from?.name, mainAirports?.to?.name],
    );

    const passengersAges = useSelector(selectPassengersAges);

    useEffect(() => {
        if (!isStepFitRequirements) return;
        dispatch(bookingFlightActions.getOutboundFlightList({
            departureDate: dateRange?.from!,
            fromAirportCode: mainAirports.from?.code!,
            toAirportCode: mainAirports.to?.code!,
            passengersAges,
        }));
    }, [
        dispatch,
        dateRange?.from,
        mainAirports?.from,
        mainAirports?.to,
        isStepFitRequirements,
        passengersAges,
    ]);

    const isSubmitButtonDisabled = useMemo(
        () => !selectedFlights?.outbound || !selectedPrices?.outbound,
        [selectedFlights?.outbound, selectedPrices?.outbound],
    );

    if (!isStepFitRequirements) return null;
    return (
        <StepSelectFlight
            flightDirection='outbound'
            isSubmitButtonDisabled={isSubmitButtonDisabled}
            onSubmit={handleStepSubmit}
            activeTab={outboundFlightActiveTab}
            airportsFromToText={outboundAirportsFromToText}
            flightLoading={!!outboundFlightListLoading}
            flightTabs={tabsOutboundFlight}
        />
    );
};
