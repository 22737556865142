import { PublicFlightListItem, ReservationPassenger } from '@malesia/json-schema';
import { SearchPassengerItemData } from '@malesia/react-components/dist/src/components/Form/Field/SearchPassenger/SearchPassengerItem';
import { push } from 'connected-react-router';
import { produce } from 'immer';
import { all, call, put, select, take, takeLatest, throttle } from 'typed-redux-saga';
import { logError } from '../../../../utils/log';
import { parseReservationError, reservationNotifications } from '../../../../utils/reservation/notifyReservationError';
import { SeatMaps } from '../../../../utils/reservation/types';
import { selectSelectedReservationOwner } from '../../../containers/AuxiliaryData/selectors';
import { reservationPdfActions } from '../../../containers/ReservationPdf/slice';
import { checkSamePassenger } from '../../../containers/ReservationSamePassengers/saga';
import { reservationSamePassengersActions } from '../../../containers/ReservationSamePassengers/slice';
import { backLinks } from '../../../containers/Routes/backLinks';
import { reservationNewPermissions } from '../../../permissions/adminPages/reservation/reservationNew';
import { applyPermissionDataFilter } from '../../../permissions/permissionDataFilter';
import { selectUserPermissions } from '../../../permissions/selectUserPermissions';
import { UserPermissions } from '../../../permissions/userPermissions';
import { getApiErrorMessage } from '../../../services/ApiClient';
import { GetFlightsOnWeekArgs, getApiAgentFlightsOnWeek, getApiFlightAircraftPassengersSeats, getApiFullFlightsOnWeek, getApiPublicFlightsOnWeek } from '../../../services/flight';
import { createApiReservation, getApiPassengerList, updateApiReservation } from '../../../services/Reservation';
import { AdminBasket, filterUpdateBillingInformation, mapAdminReservationToPostRequest } from '../../../services/Reservation/utils';
import { calculateApiReservationCost } from '../../../services/ReservationCalculate/service';
import { TravelFlight, AdminReservationBasket } from '../types';
import {
    selectReservation,
    selectReservationId,
    selectSelectedFlightVariants,
} from './selectors';
import { reservationNewPageActions } from './slice';
import { prepareReservationCostRequest } from './utils';

const getFetcherFlightsOnWeek = (userPermissions: UserPermissions) => {
    if (!userPermissions.isAdminSiteAccess) return getApiPublicFlightsOnWeek;
    if (userPermissions.has(reservationNewPermissions.getFullFlightList)) return getApiFullFlightsOnWeek;
    if (userPermissions.has(reservationNewPermissions.getAgentFlightList)) return getApiAgentFlightsOnWeek;
    // Bug. Unexpected case.
    console.error('Can not get flights without permissions');
    return null as unknown as typeof getApiFullFlightsOnWeek;
};

function* getFlights(reservation: AdminReservationBasket) {
    const userPermissions: UserPermissions = yield* selectUserPermissions();

    const createArgs = (flight: TravelFlight): GetFlightsOnWeekArgs => ({
        departureDate: flight.date!,
        fromAirportCode: flight.airports!.originAirportCode,
        toAirportCode: flight.airports!.destinationAirportCode,
        passengersAges: reservation.passengersAges,
    });

    const { travelInfo } = reservation;
    const fetcherFlightsOnWeek = getFetcherFlightsOnWeek(userPermissions);

    if (travelInfo.type === 'one-way') {
        const outboundList = yield* call(fetcherFlightsOnWeek, createArgs(travelInfo.outbound));
        const outboundResult = [
            outboundList,
            [],
        ];
        return outboundResult;
    }

    const result = yield* all([
        call(fetcherFlightsOnWeek, createArgs(travelInfo.outbound)),
        call(fetcherFlightsOnWeek, createArgs(travelInfo.return)),
    ] as const);
    return result;
}

export function* getFlightLists() {
    try {
        const reservation: AdminReservationBasket = yield* select(selectReservation);

        const [outboundList, returnList] = yield* getFlights(reservation);
        yield* put(reservationNewPageActions.getFlightListsSuccess({
            outbound: (outboundList ?? []) as PublicFlightListItem[],
            ['return']: (returnList ?? []) as PublicFlightListItem[],
        }));
    }
    catch (error) {
        logError({
            error,
            target: 'ReservationNewPage.getFlightLists',
        });
        const message = getApiErrorMessage(error);
        yield* put(reservationNotifications.unknownError(message));
        yield* put(reservationNewPageActions.getFlightListsError());
    }
}

export function* getFlightListsSaga() {
    yield* takeLatest(reservationNewPageActions.getFlightLists, getFlightLists);
}

const toPassengerItem = (
    passenger: ReservationPassenger,
    idx: number,
): SearchPassengerItemData => ({
    id: idx,
    firstName: passenger.firstName,
    lastName: passenger.lastName,
    gender: passenger.gender,
    birthday: passenger.birthday,
});

export function* searchPassenger(action: ReturnType<typeof reservationNewPageActions.searchPassenger>) {
    try {
        const query = action.payload ?? '';
        const userPermissions = yield* selectUserPermissions();

        const owner = yield* select(selectSelectedReservationOwner);

        const allowToGetOwn = userPermissions.has(reservationNewPermissions.selectPassengerUserExtended);
        const allowToGetAll = userPermissions.has(reservationNewPermissions.selectPassengerUserExtended);

        const { ownPassengers, allPassengers } = yield* all({
            ownPassengers: allowToGetOwn ? call(getApiPassengerList, query, owner?.id) : call(() => []),
            allPassengers: allowToGetAll ? call(getApiPassengerList, query) : call(() => []),
        });

        const mappedOwnPassengers = ownPassengers.map(toPassengerItem).slice(0, 5);
        const hasOwnPassenger = (passenger: ReservationPassenger) => (
            mappedOwnPassengers.some(x => checkSamePassenger(x, passenger))
        );

        const mappedAllPassengers = allPassengers
            .filter((x) => !hasOwnPassenger(x))
            .map(toPassengerItem)
            .slice(0, 5);

        yield* put(reservationNewPageActions.searchPassengerSuccess({
            own: mappedOwnPassengers,
            all: mappedAllPassengers,
        }));
    }
    catch (error) {
        logError({
            error,
            target: 'ReservationNewPage.searchPassenger',
        });
        const message = getApiErrorMessage(error);
        yield* put(reservationNotifications.unknownError(message));
    }
}

export function* searchPassengerSaga() {
    yield* throttle(1000, reservationNewPageActions.searchPassenger, searchPassenger);
}

export function* getSeatMaps() {
    try {
        const flightVariants = yield* select(selectSelectedFlightVariants);
        const reservationId = yield* select(selectReservationId);

        const result: SeatMaps = yield* all({
            outbound: getApiFlightAircraftPassengersSeats({
                flight: flightVariants.outbound?.flight,
                tariffId: flightVariants.outbound?.price.tariff?.id,
                reservationId,
            }),
            ['return']: getApiFlightAircraftPassengersSeats({
                flight: flightVariants.return?.flight,
                tariffId: flightVariants.return?.price.tariff?.id,
                reservationId,
            }),
        });
        yield* put(reservationNewPageActions.getSeatMapsSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'ReservationNewPage.getSeatMaps',
        });
        const message = getApiErrorMessage(error);
        yield* put(reservationNotifications.unknownError(message));
        yield* put(reservationNewPageActions.getSeatMapsError());
    }
}

export function* getSeatMapsSaga() {
    yield* takeLatest(reservationNewPageActions.getSeatMaps, getSeatMaps);
}

function* calculateCost() {
    const reservation: AdminReservationBasket = yield* select(selectReservation);
    const { selectedFlights } = reservation;

    try {
        const params = prepareReservationCostRequest(reservation);
        if (!params) return;
        const response = yield* call(calculateApiReservationCost, params);
        yield* put(reservationNewPageActions.calculateCostSuccess(response));
    }
    catch (error) {
        logError({
            error,
            target: 'ReservationNewPage.calculateCost',
        });
        yield* put(reservationNewPageActions.calculateCostError());
        yield* put(parseReservationError(error, (flightId) => {
            if (flightId === undefined) return undefined;
            if (selectedFlights.outbound?.flightId === flightId) return 'outbound';
            if (selectedFlights.return?.flightId === flightId) return 'return';
            return undefined;
        }));
    }
}

export function* calculateCostSaga() {
    yield* takeLatest(reservationNewPageActions.calculateCost, calculateCost);

    function* requestCalculateCost() {
        yield* put(reservationNewPageActions.calculateCost());
    }
    yield* throttle(1000, reservationNewPageActions.requestCalculateCost, requestCalculateCost);
}

function* filterAdminBasket(requestData: AdminBasket) {
    const userPermissions: UserPermissions = yield* selectUserPermissions();

    const filteredData = produce(requestData, (draft) => {
        draft.billingInformation = !draft.billingInformation
            ? undefined
            : applyPermissionDataFilter(draft.billingInformation, {
                isCreateAccount: userPermissions.has(reservationNewPermissions.createAccountFlag),
                isBookingInformation: userPermissions.has(reservationNewPermissions.createSmsFlag),
                isPostInformation: userPermissions.has(reservationNewPermissions.createSmsFlag),
                isBankInformation: userPermissions.has(reservationNewPermissions.createSmsFlag),
            });
        draft.payment = applyPermissionDataFilter(draft.payment, {
            specificPrice: userPermissions.has(reservationNewPermissions.createSpecificPrice),
            hidePrice: userPermissions.has(reservationNewPermissions.hidePriceFlag),
            updateFee: userPermissions.has(reservationNewPermissions.updateFee),
        });
        if (!userPermissions.has(reservationNewPermissions.createComment)) {
            delete draft.comment;
        }
    });

    return filteredData;
}

function* createReservation(action: ReturnType<typeof reservationNewPageActions.createReservation>) {
    const reservationBasket = yield* select(selectReservation);

    try {
        const userPermissions = yield* selectUserPermissions();
        if (userPermissions.has(reservationNewPermissions.checkSamePassengers)) {
            yield* put(reservationSamePassengersActions.checkSamePassengers({
                flights: reservationBasket.selectedFlights,
                passengers: reservationBasket.passengers.map(passenger => passenger.info),
            }));

            const result = yield* take([
                reservationSamePassengersActions.checkSamePassengersConfirm,
                reservationSamePassengersActions.checkSamePassengersCancel,
                reservationSamePassengersActions.checkSamePassengersFail,
            ]);

            if (result.type !== reservationSamePassengersActions.checkSamePassengersConfirm.type) {
                yield* put(reservationNewPageActions.createReservationCancel());
                return;
            }
        }

        const { paymentType, paymentDeadline } = action.payload;

        const requestData: AdminBasket = {
            ...reservationBasket,
            paymentTerms: paymentType,
            paymentDeadline,
        };
        const filteredData: AdminBasket = yield* filterAdminBasket(requestData);

        const mappedData = mapAdminReservationToPostRequest(filteredData);
        const response = yield* call(createApiReservation, mappedData);
        const reservationId = response.id!;

        yield* put(reservationNewPageActions.createReservationSuccess());
        if (paymentType === 'now') {
            const link = backLinks.reservationPayment.forward(reservationId, {
                source: 'reservationList',
            });
            yield* put(push(link));
        }
        else {
            const back = backLinks.reservation.back(reservationId);
            yield* put(push(back));
        }
        yield* put(reservationNotifications.createdSuccessfully);
    }
    catch (error) {
        logError({
            error,
            target: 'ReservationNewPage.createReservation',
        });
        yield* put(reservationNewPageActions.createReservationError());
        yield* put(parseReservationError(error, (flightId) => {
            if (flightId === undefined) return undefined;
            if (reservationBasket.selectedFlights.outbound?.flightId === flightId) return 'outbound';
            if (reservationBasket.selectedFlights.return?.flightId === flightId) return 'return';
            return undefined;
        }));
    }
}

export function* createReservationSaga() {
    yield* takeLatest(reservationNewPageActions.createReservation, createReservation);
}

function* updateReservation() {
    const reservationBasket = yield* select(selectReservation);

    try {
        const reservationId = (yield* select(selectReservationId))!;
        const requestData: AdminBasket = {
            ...reservationBasket,
            paymentTerms: reservationBasket.payment.paymentTerms,
            paymentDeadline: reservationBasket.payment.paymentDeadline,
        };
        requestData.billingInformation = filterUpdateBillingInformation(requestData.billingInformation);
        const filteredData = yield* filterAdminBasket(requestData);
        const mappedData = mapAdminReservationToPostRequest(filteredData);
        const result = yield* updateApiReservation(reservationId, mappedData);
        yield* put(reservationNotifications.updatedSuccessfully);
        const back = backLinks.reservation.back(reservationId);
        yield* put(push(back));
        yield* put(reservationNewPageActions.updateReservationSuccess());

        if (result.status === 'confirmed') {
            yield* put(reservationPdfActions.downloadOverviewPdf({
                reservationId,
                callback: () => {},
            }));
        }
    }
    catch (error) {
        logError({
            error,
            target: 'ReservationNewPage.updateReservation',
        });
        yield* put(reservationNewPageActions.updateReservationError());
        yield* put(parseReservationError(error, (flightId) => {
            if (flightId === undefined) return undefined;
            if (reservationBasket.selectedFlights.outbound?.flightId === flightId) return 'outbound';
            if (reservationBasket.selectedFlights.return?.flightId === flightId) return 'return';
            return undefined;
        }));
    }
}

export function* updateReservationSaga() {
    yield* takeLatest(reservationNewPageActions.updateReservation, updateReservation);
}
