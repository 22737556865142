import { getImageUrl } from '../getImageUrl';
import { getTopImage } from '../getTopImage';
import { PartnersPageDocument, PartnersPageQuery } from '../gql-types';
import { gqlClient } from '../gqlClient';

export async function loadPartnersPageContent(locale: string) {
    const { data } = await gqlClient.query<PartnersPageQuery>(
        PartnersPageDocument,
        { lang: locale },
    ).toPromise();
    return {
        topImage: getTopImage(data?.partners_page?.top_image),
        content: data?.partners_page?.translations?.[0]?.content ?? '',
        cards: data?.partner_card?.map(card => {
            return {
                image: getImageUrl(card?.image?.id),
                sort: card?.sort ?? 0,
                name: card?.title ?? '',
            };
        }) ?? [],
    };
}
