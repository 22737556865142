import {
    useInjectReducer,
    useInjectSaga,
} from '../../../../utils/redux-injectors';
import { onChangeSaga } from './saga';
import {
    userPermissionsListPageReducer,
    userPermissionsListPageSliceName,
} from './slice';

export function useInjectUserPermissionsListPageStore() {
    useInjectReducer({ key: userPermissionsListPageSliceName, reducer: userPermissionsListPageReducer });
    useInjectSaga({ key: userPermissionsListPageSliceName + 'onChangeSaga', saga: onChangeSaga });
}
