import {
    createSlice as createSliceOriginal,
    SliceCaseReducers,
    CreateSliceOptions,
    Slice,
} from '@reduxjs/toolkit';
import { RootStateKeyType } from '../types/injector-typings';

type WithUnmount<State> = {
    unmount: (state: State) => void | State,
};

export const createSlice = <State, CaseReducers extends SliceCaseReducers<State>, Name extends RootStateKeyType>(
    options: CreateSliceOptions<State, CaseReducers, Name>,
): Slice<State, CaseReducers & WithUnmount<State>, Name> => {
    return createSliceOriginal({
        ...options,
        reducers: {
            /** Default implementation for component unmount */
            unmount() {
                return options.initialState;
            },
            ...options.reducers,
        },
    });
};
