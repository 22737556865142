import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.rateListPage || initialState;

export const selectInitialized = createSelector(
    [selectDomain],
    state => state.initialized,
);

export const selectIsDirty = createSelector(
    [selectDomain],
    state => state.isDirty,
);

export const selectIsLoading = createSelector(
    [selectDomain],
    state => state.isLoading,
);

export const selectList = createSelector(
    [selectDomain],
    state => state.rateList,
);

export const selectTariffData = createSelector(
    [selectDomain],
    state => state.tariffData,
);

export const selectFilterData = createSelector(
    [selectDomain],
    state => state.filterData,
);

export const selectPagination = createSelector(
    [selectDomain],
    state => state.pagination,
);
