import classnames from 'classnames';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { agentPaymentActions } from './store/AgentPayment.slice';
import { useAgentPaymentStore } from './store/useAgentPaymentStore';

export type AgentPaymentPageProps = {
    className?: string,
};

export function AgentPaymentPage({ className }: AgentPaymentPageProps) {
    const classNames = classnames('agent-payment-page', className ?? '');

    useAgentPaymentStore();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(agentPaymentActions.onLoad());
    }, [dispatch]);

    return (
        <div className={classNames}>
            AgentPaymentPage
        </div>
    );
}
