import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { DeleteFlightsPopup } from './components/DeleteFlightsPopup';
import { FlightInfoPopup } from './components/FlightInfoPopup';
import { FlightListFilterWithStore } from './components/FlightListFilterWithStore';
import { FlightListFooterWithStore } from './components/FlightListFooterWithStore';
import { FlightListHeaderWithStore } from './components/FlightListHeaderWithStore';
import { FlightListWithStore } from './components/FlightListWithStore';
import { useInjectFlightList } from './store/inject';

export const FlightListPage: SFC = () => {
    useInjectFlightList();

    return (
        <PageContentBlock>
            <FlightListHeaderWithStore />
            <FlightListFilterWithStore />
            <FlightListWithStore />
            <FlightListFooterWithStore />
            <FlightInfoPopup />
            <DeleteFlightsPopup />
        </PageContentBlock>
    );
};
