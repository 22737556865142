import { CustomerFormType } from '@malesia/react-components/dist/src/components/Customer/Edit';
import { LegacyFormConfig } from '@malesia/react-components/dist/src/hooks/useForm';
import { useMemo } from 'react';

type Config = LegacyFormConfig<CustomerFormType>;
const createConfig = (): Config => ({
    id: { initialValue: undefined },
    email: { initialValue: '', modifiers: ['required'] },
    password: { initialValue: '', modifiers: ['required'] },
    passwordConfirm: { initialValue: '', modifiers: ['required'] },
    salutation: { initialValue: undefined, modifiers: ['required'] },
    company: { initialValue: '' },
    firstName: { initialValue: '', modifiers: ['required'] },
    lastName: { initialValue: '', modifiers: ['required'] },
    street: { initialValue: '', modifiers: ['required'] },
    zip: { initialValue: '', modifiers: ['required'] },
    city: { initialValue: '', modifiers: ['required'] },
    country: { initialValue: undefined, modifiers: ['required'] },
    language: { initialValue: '', modifiers: ['required'] },
    birthday: { initialValue: '', modifiers: ['required'] },
    phoneMobile: { initialValue: '', modifiers: ['required'] },
    phoneNumberFirst: { initialValue: '' },
    phoneNumberSecond: { initialValue: '' },
    isActive: { initialValue: true },
});

export const useCustomerFormConfig = (): Config => {
    const result = useMemo<Config>(() => {
        const config = createConfig();
        return config;
    }, []);
    return result;
};
