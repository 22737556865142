import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { LoginModalState } from './types';

export const initialState: LoginModalState = {
    isOpened: false,
};

const loginModalSlice = createSlice({
    name: 'loginModal',
    initialState,
    reducers: {
        openModal(state) {
            state.isOpened = true;
        },
        closeModal(state) {
            return initialState;
        },
    },
});

export const {
    actions: loginModalActions,
    reducer: loginModalReducer,
    name: loginModalSliceKey,
} = loginModalSlice;
