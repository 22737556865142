import * as queryString from 'query-string';
import { call, debounce, put, select, takeLatest, throttle } from 'typed-redux-saga';
import { logError } from '../../../utils/log';
import { rateListNotifications } from './notification';
import { selectInitialized, selectFilterData, selectPagination } from './selectors';
import { actions } from './slice';
import { RateListInitialized } from './types';
import { apiClientRequest, getApiErrorMessage } from 'app/services/ApiClient';

export function* getTariffList() {
    try {
        const requestData = {
            requestId: 'tariffEntireList',
        };

        const result = yield* call(apiClientRequest, requestData);
        yield* put(actions.getTariffListSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'RateListPage.getTariffList',
        });
        yield* put(actions.getTariffListError());
        const message = getApiErrorMessage(error);
        yield* put(rateListNotifications.unknownError(message));
    }
}

export function* getTariffListSaga() {
    yield* takeLatest(actions.getTariffList, getTariffList);
}

export function* getRateList() {
    try {
        const initialized: RateListInitialized = yield* select(selectInitialized);
        const can = initialized.sorting;
        if (!can) return;

        const filterData = yield* select(selectFilterData);
        const pagination = yield* select(selectPagination);

        const params = {
            query: filterData.searchText,
            isActive: filterData.isActiveOnly === true ? true : undefined,
            sortBy: filterData.sortBy,
            sortOrder: filterData.sortOrder,
            page: pagination.page,
            pageSize: pagination.pageSize,
        };

        const requestData = {
            requestId: 'rateList',
            query: queryString.stringify(params, {
                skipNull: true,
                sort: false,
            }),
        };

        const result = yield* call(apiClientRequest, requestData);
        yield* put(actions.getRateListSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'RateListPage.getRateList',
        });
        yield* put(actions.getRateListError());
        const message = getApiErrorMessage(error);
        yield* put(rateListNotifications.unknownError(message));
    }
}

export function* getRateListSaga() {
    yield* takeLatest(actions.getRateList, getRateList);

    function* requestGetRateList() {
        yield* put(actions.getRateList());
    }

    yield* throttle(1000, [
        actions.setPage,
        actions.setSorting,
    ], requestGetRateList);

    yield* debounce(1000, [
        actions.updateFilterData,
        actions.setPageSize,
    ], requestGetRateList);
}
