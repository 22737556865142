import { PermissionsTabKey } from '@malesia/react-components/dist/src/components/PermissionsPage/Permissions/Permissions';
import { useMemo } from 'react';
import { userPermissionsPermissions } from '../../permissions/adminPages/settings/userPermissions';
import { useUserPermissions } from '../../permissions/useUserPermissions';

type Config = {
    defaultTab: PermissionsTabKey,
    disabledTabs: PermissionsTabKey[],
    hiddenTabs: PermissionsTabKey[],
    canSave: boolean,
};

export const useSettingsFormConfig = (): Config => {
    const userPermissions = useUserPermissions();
    const result = useMemo<Config>(() => {
        const rolesTab = userPermissions.has(userPermissionsPermissions.userRolesTab);
        const virtualAccountsTab = userPermissions.has(userPermissionsPermissions.userVirtualAccountsTab);
        const updateUserRoles = userPermissions.has(userPermissionsPermissions.updateUserRoles);
        const updateUserVirtualAccounts = userPermissions.has(userPermissionsPermissions.updateUserVirtualAccounts);

        const defaultTab: PermissionsTabKey = rolesTab ? 'roles' : 'virtualAccounts';

        const disabledTabs: PermissionsTabKey[] = [];
        if (!updateUserRoles) disabledTabs.push('roles');
        if (!updateUserVirtualAccounts) disabledTabs.push('virtualAccounts');

        const hiddenTabs: PermissionsTabKey[] = [];
        if (!rolesTab) hiddenTabs.push('roles');
        if (!virtualAccountsTab) hiddenTabs.push('virtualAccounts');

        const canSave = (rolesTab && updateUserRoles) || (virtualAccountsTab && updateUserVirtualAccounts);

        return {
            defaultTab,
            hiddenTabs,
            disabledTabs,
            canSave,
        };
    }, [userPermissions]);
    return result;
};
