import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReservationInvoiceListFilterWithStore } from './component/ReservationInvoiceListFilterWithStore';
import { ReservationInvoiceListFooterWithStore } from './component/ReservationInvoiceListFooterWithStore';
import { ReservationInvoiceListWithStore } from './component/ReservationInvoiceListWithStore';
import { useInjectReservationInvoiceList } from './store/inject';

export const ReservationInvoiceListPage = () => {
    useInjectReservationInvoiceList();

    return (
        <PageContentBlock>
            <PageTitle
                text={
                    <FormattedMessage
                        id='front-app/InvoiceOverviewListPage:Title'
                        defaultMessage='Invoice Overview'
                    />
                }
            />
            <ReservationInvoiceListFilterWithStore />
            <ReservationInvoiceListWithStore />
            <ReservationInvoiceListFooterWithStore />
        </PageContentBlock>
    );
};
