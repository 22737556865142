import { BackLink } from '@malesia/react-components/dist/src/components/BackLink';
import { FlightPassengerFilter, FlightPassengerFilterProps } from '@malesia/react-components/dist/src/components/Flight/Passenger/Filter';
import { FlightPassengerList, FlightPassengerListProps } from '@malesia/react-components/dist/src/components/Flight/Passenger/List';
import { FlightPassengerListHeader } from '@malesia/react-components/dist/src/components/Flight/Passenger/List/Header';
import { LoadingTable } from '@malesia/react-components/dist/src/components/LoadingOverlay/Table';
import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { SortingDataType } from '@malesia/react-components/dist/src/components/Table';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { toNumber } from '../../../utils/uriUtils';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { FlightPassengerBulkActions } from './bulkActions';
import { CommentPopup } from './components/CommentPopup';
import { NotifyingPopup } from './components/NotifyingPopup';
import { PassengerListInfo } from './components/PassengerListInfo';
import { PassengerListTitle } from './components/PassengerListTitle';
import { PassengerSeatSelectionPopup } from './components/PassengerSeatSelectionPopup';
import { PnlPopup } from './components/PnlPopup';
import { UpdateFlightDelayed } from './components/UpdateFlightDelayed';
import { useInjectFlightPassengerList } from './inject';
import { useQueryParameters } from './queryParameters';
import {
    selectTariffGroupList,
    selectFlightPassengerList,
    selectIsLoading,
    selectIsDirty,
    selectFilterData,
    selectFlight,
    selectFlightPassengersInfo,
} from './selectors';
import { actions } from './slice';
import { useTableColumns } from './tableColumns';

export type RouteParams = {
    flightId: string,
};

type FlightPassengerListPageProps = RouteComponentProps<RouteParams>;

export const FlightPassengerListPage: SFC<FlightPassengerListPageProps> = (props) => {
    useInjectFlightPassengerList();

    const filterData = useSelector(selectFilterData);
    const flight = useSelector(selectFlight);
    const tariffGroupList = useSelector(selectTariffGroupList);
    const flightPassengersInfo = useSelector(selectFlightPassengersInfo);
    const flightPassengerList = useSelector(selectFlightPassengerList);
    const isLoading = useSelector(selectIsLoading);
    const isDirty = useSelector(selectIsDirty);
    const { highlightReservationId } = useQueryParameters();

    const dispatch = useDispatch();

    const flightId = toNumber(props.match.params.flightId)!;

    useEffect(() => {
        dispatch(actions.getFlight({ flightId }));
    }, [dispatch, flightId]);

    useEffect(() => {
        dispatch(actions.getTariffGroupList());
    }, [dispatch]);

    const flightListColumns = useTableColumns({
        flight,
        flightId,
    });

    const flightPassengerFilterProps: FlightPassengerFilterProps = {
        tariffGroups: tariffGroupList.items,
        form: filterData,
        info: flightPassengersInfo,
        onChange: (data) => {
            dispatch(actions.updateSearch(data));
        },
        onSubmit: () => {
            dispatch(actions.getFlightPassengerList());
        },
        onReset: () => {
            dispatch(actions.resetSearch());
        },
        isSubmitButtonDisable: false,
        intl: undefined!,
    };

    const setSort = useCallback((data: SortingDataType) => {
        dispatch(actions.setSorting(data));
    }, [dispatch]);

    const setSelectedRows = useCallback((data) => {
        dispatch(actions.setSelectedRows(data));
    }, [dispatch]);

    const flightPassengerListProps: FlightPassengerListProps = {
        tableColumns: flightListColumns,
        tableData: flightPassengerList?.items ?? [],
        sortBy: filterData.sortBy,
        sortOrder: filterData.sortOrder,
        setSort,
        setSelectedRows,
        highlightReservationId,
        actionsSlot: <FlightPassengerBulkActions />,
    };

    return (
        <>
            <CommentPopup />
            <PassengerSeatSelectionPopup />
            <NotifyingPopup />
            <PnlPopup />
            <PageContentBlock>
                <PassengerListTitle />
                <BackLink
                    to={allAdminRoutePaths.flightList}
                    modificators='stick-to-title'
                />
                <FlightPassengerFilter {...flightPassengerFilterProps} />
                <FlightPassengerListHeader
                    leftComponent={<UpdateFlightDelayed />}
                    rightComponent={<PassengerListInfo flightId={flightId} />}
                />
                <LoadingTable
                    isLoading={isLoading}
                    isDirty={isDirty}
                >
                    <FlightPassengerList {...flightPassengerListProps} />
                </LoadingTable>
            </PageContentBlock>
        </>
    );
};
