import { AllotmentPaginatedList, ApiCallAllotmentFilters } from '@malesia/json-schema';
import { call } from 'typed-redux-saga';
import { allotmentListPermissions } from '../permissions/adminPages/allotment/allotmentList';
import { selectUserPermissions } from '../permissions/selectUserPermissions';
import { apiClientRequest } from './ApiClient';

export function* getApiAllotmentList(requestPayload: ApiCallAllotmentFilters) {
    const response: AllotmentPaginatedList = yield* call(apiClientRequest, {
        requestId: 'allotmentList',
        requestPayload,
    });
    return response;
}

export function* getApiAgentAllotmentList(requestPayload: ApiCallAllotmentFilters) {
    const response: AllotmentPaginatedList = yield* call(apiClientRequest, {
        requestId: 'agentAllotmentList',
        requestPayload,
    });
    return response;
}

export function* getApiPermittedAllotmentList(requestPayload: ApiCallAllotmentFilters) {
    const userPermissions = yield* selectUserPermissions();

    if (userPermissions.has(allotmentListPermissions.getList)) {
        return yield* getApiAllotmentList(requestPayload);
    }
    if (userPermissions.has(allotmentListPermissions.getAgentList)) {
        return yield* getApiAgentAllotmentList(requestPayload);
    }
    throw new Error('Can not get allotment list without permissions');
}
