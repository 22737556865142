import { Collapse } from '@malesia/react-components/dist/src/components/Collapse';
import { FlightsOverview } from '@malesia/react-components/dist/src/components/FlightsOverview';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { useFlightOverviewCardWithClickHandler } from '../../hooks/useFlightOverviewCardWithClickHandler/useFlightOverviewCardWithClickHandler';
import { getFlightsSaga } from './saga';
import { selectFlightColumns } from './selectors';
import { actions, reducer, sliceKey } from './slice';

export const FlightOverview: SFC = () => {
    useInjectReducer({ key: sliceKey, reducer });
    useInjectSaga({ key: 'flightOverview', saga: getFlightsSaga });
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.getFlights());
    }, [dispatch]);

    const flightColumns = useSelector(selectFlightColumns);

    const flights = useFlightOverviewCardWithClickHandler(flightColumns);
    const collapseEnabled = flights.some(column => column.items.length > 5);

    return (
        <Collapse
            collapsed={true}
            enabled={collapseEnabled}
        >
            <FlightsOverview flightColumns={flights} />
        </Collapse>
    );
};
