import { permissionMaker } from '../../permissionExpression';

const { role } = permissionMaker;

const updateForm = role('ROLE_AGENT_UPDATE');
const updateIsActive = role('ROLE_AGENT_UPDATE_IS_ACTIVE');
const updateFee = role('ROLE_AGENT_UPDATE_FEE');
const updateCreditLimit = role('ROLE_AGENT_UPDATE_CREDIT_LIMIT');
const updateMargin = role('ROLE_AGENT_UPDATE_MARGIN');

export const agentEditPermissions = {
    updateForm,
    updateIsActive,
    updateFee,
    updateCreditLimit,
    updateMargin,
};
