import { getTopImage } from '../getTopImage';
import {
    DataProtectionPageDocument,
    DataProtectionPageQuery,
} from '../gql-types';
import { gqlClient } from '../gqlClient';

export async function loadDataProtectionPageContent(locale: string) {
    const { data } = await gqlClient.query<DataProtectionPageQuery>(
        DataProtectionPageDocument,
        { lang: locale },
    ).toPromise();
    return {
        topImage: getTopImage(data?.data_protection_page?.top_image),
        content: data?.data_protection_page?.translations?.[0]?.content ?? '',
    };
}
