import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => {
    return state.recoverPasswordModal || initialState;
};

export const selectRecoverPasswordModalIsOpened = createSelector(
    [selectDomain],
    recoverPasswordModal => recoverPasswordModal.isOpened,
);

export const selectRecoverPasswordModalFormValidationErrors = createSelector(
    [selectDomain],
    recoverPasswordModal => recoverPasswordModal.formValidationErrors,
);

export const selectRecoverPasswordModalFormUntouchedFields = createSelector(
    [selectDomain],
    recoverPasswordModal => recoverPasswordModal.formUntouchedFields,
);

export const selectRecoverPasswordModalFormIsValid = createSelector(
    [selectDomain],
    recoverPasswordModal => recoverPasswordModal.formIsValid,
);

export const selectRecoverPasswordModalFormIsLoading = createSelector(
    [selectDomain],
    recoverPasswordModal => recoverPasswordModal.formIsLoading,
);

export const selectRecoverPasswordModalFormResultIsSuccessful = createSelector(
    [selectDomain],
    recoverPasswordModal => recoverPasswordModal.formResultIsSuccessful,
);
