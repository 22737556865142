import { getAdminRoutePermission } from '../../adminRoutes';
import { permissionMaker } from '../../permissionExpression';

const { role, some } = permissionMaker;

const getList = role('ROLE_ALLOTMENT_LIST');
const getAgentList = role('ROLE_ALLOTMENT_AGENT_ALLOTMENT_LIST');
const edit = role('ROLE_ALLOTMENT_INVOICE_EDIT');
const remove = role('ROLE_ALLOTMENT_INVOICE_EDIT');
const balance = role('ROLE_ALLOTMENT_INVOICE_READ');
const payment = getAdminRoutePermission((x) => x.allotmentPayment);
const pdf = some('ROLE_ALLOTMENT_INVOICE_READ', 'ROLE_ALLOTMENT_AGENT_ALLOTMENT_LIST');

export const allotmentInvoiceListPermissions = {
    getList,
    getAgentList,
    edit,
    remove,
    balance,
    payment,
    pdf,
};
