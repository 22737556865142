import { PageTabs } from '@malesia/react-components/dist/src/components/Page/Tabs';
import { RouteItem } from '@malesia/react-components/dist/src/components/types';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Link,
    Route,
    RouteComponentProps,
    Switch,
    Redirect,
} from 'react-router-dom';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { useTabsState } from '../../containers/Routes/tabsState/useTabsState';
import { useFilteredAdminRoutesByPermissions } from '../../permissions/useFilteredAdminRoutesByPermissions';
import { FlightCompanyEditPage } from '../FlightCompanyEditPage';
import { FlightCompanyListPage } from '../FlightCompanyListPage';
import { FlightCompanyNewPage } from '../FlightCompanyNewPage';
import { FlightEditPage } from '../FlightEditPage';
import { FlightListPage } from '../FlightListPage';
import { FlightNewPage } from '../FlightNewPage';
import { FlightPassengerListPage } from '../FlightPassengerListPage';
import { NotFoundPage } from '../NotFoundPage';

const FlightRouterPage: SFC<RouteComponentProps> = (props) => {

    const { location } = props;
    const { pathname } = location;

    const isRouteMatch = linkRoute => pathname === linkRoute;

    const flightRoutes: RouteItem[] = useFilteredAdminRoutesByPermissions([
        {
            routeKey: 'flightList',
            linkRoute: allAdminRoutePaths.flightList,
            LinkComponent: (props: React.PropsWithChildren<any>) => (
                <Link to={allAdminRoutePaths.flightList}>
                    {React.Children.only(props.children)}
                </Link>
            ),
            linkSubject: (
                <FormattedMessage
                    id='front-app/Routes/FLIGHT:Title'
                    defaultMessage='Flights'
                />
            ),
        },
        {
            routeKey: 'flightCompanyList',
            linkRoute: allAdminRoutePaths.flightCompanyList,
            LinkComponent: (props: React.PropsWithChildren<any>) => (
                <Link to={allAdminRoutePaths.flightCompanyList}>
                    {React.Children.only(props.children)}
                </Link>
            ),
            linkSubject: (
                <FormattedMessage
                    id='front-app/Routes/FLIGHT_COMPANY_LIST:Title'
                    defaultMessage='Airline Company'
                />
            ),
        },
    ]);

    const { firstRoute } = useTabsState(allAdminRoutePaths.flight, flightRoutes);

    return (
        <>
            <PageTabs
                isRouteMatch={isRouteMatch}
                routeItems={flightRoutes}
            >
                <Switch>
                    <Route
                        exact
                        path={allAdminRoutePaths.flightList}
                        component={FlightListPage}
                    />
                    <Route
                        exact
                        path={allAdminRoutePaths.flightNew}
                        component={FlightNewPage}
                    />
                    <Route
                        exact
                        path={allAdminRoutePaths.flightEdit}
                        component={FlightEditPage}
                    />
                    <Route
                        exact
                        path={allAdminRoutePaths.flightPassengerList}
                        component={FlightPassengerListPage}
                    />
                    <Route
                        exact
                        path={allAdminRoutePaths.flightCompanyList}
                        component={FlightCompanyListPage}
                    />
                    <Route
                        exact
                        path={allAdminRoutePaths.flightCompanyNew}
                        component={FlightCompanyNewPage}
                    />
                    <Route
                        exact
                        path={allAdminRoutePaths.flightCompanyEdit}
                        component={FlightCompanyEditPage}
                    />
                    <Route
                        exact
                        path={allAdminRoutePaths.flight}
                    >
                        <Redirect to={firstRoute} />
                    </Route>
                    <Route component={NotFoundPage} />
                </Switch>
            </PageTabs>
        </>
    );
};

const Memoized = memo(FlightRouterPage);
export {
    Memoized as FlightRouterPage,
};
