import { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../types';
import { createSlice } from '../../../../utils/@reduxjs/toolkit';

export type TabsState = Record<string, string>;

export const initialState: TabsState = {
};

const tabsStateStore = createSlice({
    name: 'tabsState',
    initialState,
    reducers: {
        add(state, action: PayloadAction<{[k: string]: string}>) {
            return {
                ...state,
                ...action.payload,
            };
        },
    },
});

export const selectTabsState = (state: RootState) => state.tabsState ?? initialState;

export const { actions: tabsStateActions, reducer: tabsStateReducer, name: sliceKey } = tabsStateStore;
