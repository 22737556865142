import { Date } from '@malesia/react-components/dist/src/components/DateTime';
import { PopupConfirm } from '@malesia/react-components/dist/src/components/Popup/Confirm';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedRows, selectDeleteFlightsOpen } from '../store/selectors';
import { actions } from '../store/slice';

export const DeleteFlightsPopup: SFC = () => {
    const selectedRows = useSelector(selectSelectedRows);
    const open = useSelector(selectDeleteFlightsOpen);

    const dispatch = useDispatch();

    const handleDeleteFlightConfirm = useCallback(() => {
        dispatch(actions.confirmDeleteFlights());
    }, [dispatch]);

    const handleDeleteFlightCancel = useCallback(() => {
        dispatch(actions.closeDeleteFlights());
    }, [dispatch]);

    return (
        <PopupConfirm
            isOpened={open}
            onClose={handleDeleteFlightCancel}
            onConfirm={handleDeleteFlightConfirm}
            confirmMessage={
                <FormattedMessage
                    id='front-app/FlightListPage:ConfirmDeleteFlights'
                    defaultMessage='Do you really want to delete the selected flights?'
                />
            }
            isConfirmRed={true}
        >
            <ul>
                {selectedRows.map((flight, idx) => (
                    <li key={idx}>
                        <span>{flight.number}</span>{' '}
                        <span>
                            ({flight.origin.code} - {flight.destination.code})
                        </span>
                        ,{' '}
                        <span>
                            <Date
                                value={flight.departure}
                                isLocalTime={true}
                            />
                        </span>
                    </li>
                ))}
            </ul>
        </PopupConfirm>
    );
};
