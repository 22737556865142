import { Option, OptionTypeEntireList } from '@malesia/json-schema';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { OptionNewPageState } from './types';

export const initialState: OptionNewPageState = {
    optionTypeData: {
        items: [],
    },
    loading: false,
    loadingTypes: false,
};

const optionNewPageSlice = createSlice({
    name: 'optionNewPage',
    initialState,
    reducers: {
        getOptionTypeListData(state) {
            state.loadingTypes = true;
        },
        getOptionTypeListDataSuccess(
            state,
            action: PayloadAction<OptionTypeEntireList>,
        ) {
            state.optionTypeData = {
                items: action.payload.items.filter(item => ['general', 'travel-insurance'].includes(item.name)),
            };
            state.loadingTypes = false;
        },
        getOptionTypeListDataError(state) {
            state.loadingTypes = false;
        },
        createOption(state, action: PayloadAction<Option>) {
            state.loading = true;
        },
        createOptionSuccess(state) {
            state.loading = false;
        },
        createOptionError(state) {
            state.loading = false;
        },
    },
});

export const { actions, reducer, name: sliceKey } = optionNewPageSlice;
