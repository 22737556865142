import { Button } from '@malesia/react-components/dist/src/components/Button';
import { FrontPageContentBlock } from '@malesia/react-components/dist/src/components/Front/Page/ContentBlock';
import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { DesignSummary } from '@malesia/react-components/dist/src/components/Reservation/DesignSummary';
import { PaymentButtonContainer } from '@malesia/react-components/dist/src/components/Reservation/PaymentButtonContainer/PaymentButtonContainer';
import { ReservationOverviewInfo } from '@malesia/react-components/dist/src/components/Reservation/StepReservationOverview/ReservationOverviewInfo';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { datatransErrorPageActions } from './store/datatransError.slice';
import { selectOverviewData } from './store/datatransErrorPage.selectors';
import { useDatatransStore } from './store/useDatatransStore';

export const MalesiaDatatransErrorPage: SFC = () => {
    useDatatransStore();
    const dispatch = useDispatch();

    const overview = useSelector(selectOverviewData);

    useEffect(() => {
        dispatch(datatransErrorPageActions.loadInitialData());
    }, [dispatch]);

    const openPopup = useCallback(() => {
        dispatch(datatransErrorPageActions.openPaymentPopup());
    }, [dispatch]);

    return (
        <FrontPageContentBlock modificators='booking'>
            {!overview && <h2>loading...</h2>}
            {overview && (
                <PageContentBlock modificators='flight-reservation'>
                    <DesignSummary
                        modifiersStep='top-padding'
                        contentSlot={
                            <>
                                <ReservationOverviewInfo
                                    flights={overview.flights}
                                    totalPrice={overview.total}
                                    passengers={overview.passengers}
                                    title={
                                        <FormattedMessage
                                            id='front-app/MalesiaDatatransErrorPage:title'
                                            defaultMessage='Payment'
                                        />
                                    }
                                />
                                <br />
                                <PaymentButtonContainer>
                                    <Button
                                        onClick={openPopup}
                                        icon='dollar-circle'
                                        text={
                                            <FormattedMessage
                                                id='front-app/MalesiaDatatransErrorPage/Button:payNow'
                                                defaultMessage='Pay now'
                                            />
                                        }
                                    />
                                </PaymentButtonContainer>
                            </>
                        }
                    />
                </PageContentBlock>
            )}
        </FrontPageContentBlock>
    );
};
