import { OptionPaginatedList } from '@malesia/json-schema';
import { call } from 'typed-redux-saga';
import { apiClientRequest } from '../ApiClient';

export type GetApiOptionListFilter = {
    rateId: number,
    tariffId: number,
};

export function* getApiOptionList(filter: GetApiOptionListFilter) {
    const { rateId, tariffId } = filter;
    const config = JSON.stringify({
        rate: { id: rateId },
        tariff: { id: tariffId },
    });
    const requestData = {
        requestId: 'publicOptionAttachList',
        query: { config },
    };
    const response: OptionPaginatedList = yield* call(apiClientRequest, requestData);
    return response;
}
