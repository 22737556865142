import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../utils/log';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { apiClientRequest, getApiErrorMessage } from '../../services/ApiClient';
import { mapCustomerToPutRequest } from '../../services/customer';
import { customerNotifications } from '../CustomerNewPage/notification';
import { actions } from './slice';

export function* getCustomerData(action: ReturnType<typeof actions.getCustomerData>) {
    try {
        const customerId = action.payload;

        const result = yield* call(apiClientRequest, {
            requestId: 'customerData',
            uriParams: { id: customerId },
        });
        yield* put(actions.getCustomerDataSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'CustomerEditPage.getCustomerData',
        });
        yield* put(actions.getCustomerDataError());
        const message = getApiErrorMessage(error);
        yield* put(customerNotifications.unknownError(message));
    }
}

export function* getCustomerDataSaga() {
    yield* takeLatest(actions.getCustomerData, getCustomerData);
}

export function* updateCustomerData(action: ReturnType<typeof actions.updateCustomerData>) {
    try {
        const { customerId, customerData } = action.payload;

        const requestPayload = mapCustomerToPutRequest(customerData);
        const result = yield* call(apiClientRequest, {
            requestId: 'customerDataUpdate',
            requestPayload,
            uriParams: { id: customerId },
        });
        yield* put(actions.updateCustomerDataSuccess(result));
        yield* put(push(allAdminRoutePaths.userCustomerList));
        yield* put(customerNotifications.updatedSuccessfully);
    }
    catch (error) {
        logError({
            error,
            target: 'CustomerEditPage.updateCustomerData',
        });
        yield* put(actions.updateCustomerDataError());
        const message = getApiErrorMessage(error);
        yield* put(customerNotifications.unknownError(message));
    }
}

export function* updateCustomerDataSaga() {
    yield* takeLatest(actions.updateCustomerData, updateCustomerData);
}
