export type PermissionDataFilters<T> = Partial<Record<keyof T, boolean>>;
export const applyPermissionDataFilter = <T extends object>(
    data: T,
    filters: PermissionDataFilters<T>,
): T => {
    const filteredData = Object.keys(data).reduce<T>((acc, key) => {
        const filter = filters[key as keyof T];
        if (filter === false) return acc;

        return {
            ...acc,
            [key]: data[key],
        };
    }, {} as T);
    return filteredData;
};
