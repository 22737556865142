import { useInjectReducer, useInjectSaga } from '../../../../utils/redux-injectors';
import { useInjectSharedData } from '../../../containers/SharedData/inject';
import { submitAgentSaga } from './submitAgentSaga';
import { submitCustomerSaga } from './submitCustomerSaga';
import { submitManagerSaga } from './submitManagerSaga';
import { userAccountPageReducer, userAccountPageSliceKey, userAccountPageActions } from './userAccount.slice';

export const useUserAccountPageStore = () => {
    useInjectSharedData();
    useInjectReducer({
        actions: userAccountPageActions,
        key: userAccountPageSliceKey,
        reducer: userAccountPageReducer,
    });
    useInjectSaga({
        key: 'submitManagerSaga',
        saga: submitManagerSaga,
    });
    useInjectSaga({
        key: 'submitAgentSaga',
        saga: submitAgentSaga,
    });
    useInjectSaga({
        key: 'submitCustomerSaga',
        saga: submitCustomerSaga,
    });
};
