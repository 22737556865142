import React from 'react';
import { FormattedMessage } from 'react-intl';
import { appNotification } from '../../../containers/App/appNotification';
import { createLocalNotification } from '../../../containers/App/utils';

const notification = createLocalNotification('ReservationsList', {
    error: (
        <FormattedMessage
            id='front-app/ReservationsList/Notification/Error:title'
            defaultMessage='Reservation List Error'
        />
    ),
    warning: (
        <FormattedMessage
            id='front-app/ReservationsList/Notification/Warning:title'
            defaultMessage='Reservation List Warning'
        />
    ),
    info: (
        <FormattedMessage
            id='front-app/ReservationsList/Notification/Info:title'
            defaultMessage='Reservation List Info'
        />
    ),
    success: (
        <FormattedMessage
            id='front-app/ReservationsList/Notification/Info:title'
            defaultMessage='Reservation List Info'
        />
    ),
});

export const reservationsListNotifications = {
    notificationSuccess: notification.success(
        <FormattedMessage
            id='front-app/ReservationsListPage/confirmSendReservationListNotification:success'
            defaultMessage='Notifications were sent.'
        />,
    ),
    notificationError: notification.error(
        <FormattedMessage
            id='front-app/ReservationsListPage/confirmSendReservationListNotification:unknownError'
            defaultMessage='Notifications were not sent.'
        />,
    ),
    cancelSuccess: notification.success(
        <FormattedMessage
            id='front-app/ReservationsListPage/confirmCancelReservationNotifications:success'
            defaultMessage='Reservation cancelled'
        />,
    ),
    unknownError: (details?: JSX.Element | string) => (
        notification.error(appNotification.texts.unknownError(details))
    ),
};
