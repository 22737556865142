import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { getAdminCountryListSaga, getAirportListSaga, getPublicCountryListSaga } from './saga';
import { sharedActions, sharedReducer, sharedSliceKey } from './slice';

export const useInjectSharedData = () => {
    useInjectReducer({
        actions: sharedActions,
        key: sharedSliceKey,
        reducer: sharedReducer,
    });

    useInjectSaga({
        key: `${sharedSliceKey}Airport`,
        saga: getAirportListSaga,
    });
    useInjectSaga({
        key: `${sharedSliceKey}PublicCountry`,
        saga: getPublicCountryListSaga,
    });
    useInjectSaga({
        key: `${sharedSliceKey}AdminCountry`,
        saga: getAdminCountryListSaga,
    });
};
