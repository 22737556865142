import { PriceListItem, PublicFlightListItem } from '@malesia/json-schema';
import { PriceVariantInfo, PriceVariantItem } from '@malesia/react-components/dist/src/components/Reservation/PriceVariant';
import { localizeMoney } from '@malesia/react-components/dist/src/utils/roundMoney';
import { verifyRate, verifyTariff } from '@malesia/react-components/dist/src/utils/verify';
import { rateIdToTariffType } from '../../app/pages/BookingFlightPage/utils/StepFlight/rate';
import { ReservationAllowed } from '../../app/pages/ReservationNewPage/permissions';
import { isCancelledFlight, isClosedFlight, isDepartedFlight } from '../reservation/flightsStatus';
import { BookedFlight, SelectedFlight } from '../reservation/selectedFlight';

export const getAvailableTariffSeats = (
    flight: PublicFlightListItem,
    priceVariant: PriceListItem,
) => (
    flight.freeSeatsByTariff
        ?.find(seat => seat.tariffGroup?.id === verifyTariff(priceVariant.tariff)?.group.id)
        ?.seatsForSale ?? 0
);

export const getPriceVariants = (
    flight: PublicFlightListItem,
    selectedFlight: SelectedFlight | undefined,
    bookedFlight: BookedFlight | undefined,
    allowed: ReservationAllowed,
    isRoundTrip,
    locale = 'en',
): PriceVariantItem[] => {
    return verifyRate(flight.rate)!.priceList!
        .filter(priceVariant => verifyTariff(priceVariant.tariff)?.isActive)
        .sort((x, y) => x.tariff!.id! - y.tariff!.id!)
        .map((priceVariant): PriceVariantItem => {
            const { tariff, priceRound, priceStandard, fuelFee, airportFee } = priceVariant;
            const info: PriceVariantInfo = {
                flightId: flight.id!,
                priceId: tariff!.id || -1,
            };
            const availableTariffSeats = getAvailableTariffSeats(flight, priceVariant);
            const isSelected = (
                selectedFlight?.flightId === flight.id
                && selectedFlight?.tariffId === tariff?.id
            );
            // ToDo: Crutch. Remove it. Can select booked variant. Backend can't fix it https://mywork.xiag.ch/search#87339
            const isBooked = (
                bookedFlight?.flightId === flight.id
                && bookedFlight?.tariffId === tariff?.id
            );
            const isDisabled = (
                (!priceVariant.hasAvailableSeats && !isBooked)
                || (flight.isActive === false)
                || isCancelledFlight(flight)
                || (
                    isDepartedFlight(flight)
                    && !allowed.flight.departed
                )
                || (
                    isClosedFlight(flight)
                    && !allowed.flight.closed
                )
            );
            const price = localizeMoney((isRoundTrip ? priceRound! : priceStandard!) + fuelFee! + airportFee!, locale, 'utils/flight/getPriceVariants');
            return {
                info,
                price,
                isDisabled,
                isSelected,
                rateTariffType: rateIdToTariffType[tariff?.id || 1],
                count: availableTariffSeats,
                allowedPriceWithSoldOut: allowed.flight.priceWithSoldOut,
                allowedNegativeCount: allowed.flight.negativeSeats,
            };
        });
};
