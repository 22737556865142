import { AdminRoutePage, AdminRoutePath, PaginatedPath, allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { PermissionExpression } from '../permissionExpression';
import { allotment } from './allotment';
import { balance } from './balance';
import { flight } from './flight';
import { rate } from './rate';
import { reservation } from './reservation';
import { settings } from './settings';
import { specific } from './specific';
import { user } from './user';

export type PaginatedAdminRoutePermissions = {
    [Page in AdminRoutePage]: (
        Record<PaginatedPath<Page>, PermissionExpression>
    );
};
const paginatedAdminRoutePermissions: PaginatedAdminRoutePermissions = {
    balance,
    flight,
    reservation,
    user,
    allotment,
    rate,
    settings,
    specific,
};

type AllPermissions = Record<AdminRoutePath, PermissionExpression>;
const allAdminRoutePermissions: AllPermissions = {
    ...paginatedAdminRoutePermissions.balance,
    ...paginatedAdminRoutePermissions.flight,
    ...paginatedAdminRoutePermissions.reservation,
    ...paginatedAdminRoutePermissions.user,
    ...paginatedAdminRoutePermissions.allotment,
    ...paginatedAdminRoutePermissions.rate,
    ...paginatedAdminRoutePermissions.settings,
    ...paginatedAdminRoutePermissions.specific,
};

export const getAdminRoutePermission = (
    callback: (all: typeof allAdminRoutePaths) => AdminRoutePath | undefined,
): PermissionExpression => {
    const linkRoute = callback(allAdminRoutePaths);
    if (!linkRoute) return undefined;
    return allAdminRoutePermissions[linkRoute];
};
