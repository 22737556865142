import { addQueryParameters, getQueryParameters, makePath } from '../../../../utils/uriUtils';
import { AllotmentInvoiceListQueryParameters } from '../../../pages/AllotmentInvoiceListPage/queryParameters';
import { allAdminRoutePaths } from '../allAdminRoutePaths';

export type AllotmentQueryParameters = (
    | { source: 'allotmentInvoiceList' }
);

const defaultBackUrl = allAdminRoutePaths.allotmentInvoiceList;
const getBackUrl = (params: AllotmentQueryParameters): string | undefined => {
    switch (params.source) {
        case 'allotmentInvoiceList': {
            return allAdminRoutePaths.allotmentInvoiceList;
        }
        default: {
            return undefined;
        }
    }
};

export const allotmentPayment = {
    forward: (allotmentInvoiceId: number, parameters: AllotmentQueryParameters): string => {
        const path = makePath(allAdminRoutePaths.allotmentPayment, {
            allotmentInvoiceId,
        });
        const result = addQueryParameters(path, parameters);
        return result;
    },
    back: (listQueryParameters?: AllotmentInvoiceListQueryParameters): string => {
        const params = getQueryParameters<AllotmentQueryParameters>({
            source: 'string',
        });
        const path = getBackUrl(params) ?? defaultBackUrl;
        const result = addQueryParameters(path, listQueryParameters);
        return result;
    },
};
