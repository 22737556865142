import { Airport } from '@malesia/json-schema';
import moment from 'moment-timezone';

export const fixFlightTimezone = ({
    origin, destination, departure, arrival,
}: {
    departure?: string, arrival?: string, origin?: Airport, destination?: Airport,
}) => {
    const toTimezone = (value?: string, timezone?: string) => {
        if (!value) return value;
        if (!timezone) return value;

        const result = moment.parseZone(value).tz(timezone, true).format();
        return result;
    };

    return {
        departure: toTimezone(departure, origin?.timezone),
        arrival: toTimezone(arrival, destination?.timezone),
    };
};
