import { PassengerSeatsType } from '@malesia/react-components/dist/src/components/Reservation/PassengersSeatsOnFlights';
import { FlightSeatInfoType } from '@malesia/react-components/dist/src/components/Reservation/PassengersSeatsOnFlights/Item';
import { createFullName } from '@malesia/react-components/dist/src/utils/fullName';
import { localizeMoney } from '@malesia/react-components/dist/src/utils/roundMoney';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Selector } from '../../../types/Selector';
import { getFlightLabelParts } from '../flightUtils';
import { FlightVariant, FlightVariants } from '../selectedFlight';
import { PassengerBasket } from '../types';

type FlightSeatsDataForDirectionParams = {
    seatPriceDictionary: Record<string, { price: number }>,
    selectedFlight: FlightVariant | undefined,
    selectedSeatLabel?: string,
    canSeatInput: boolean,
    disabled?: boolean,
    locale: string,
};
const getFlightSeatsDataForDirection = (
    params: FlightSeatsDataForDirectionParams,
): FlightSeatInfoType | undefined => {
    const {
        seatPriceDictionary,
        selectedFlight,
        selectedSeatLabel,
        canSeatInput,
        disabled,
        locale,
    } = params;

    if (!selectedFlight) return undefined;
    const flightLabelsParts = getFlightLabelParts(selectedFlight, locale);

    const infoTextData = {
        flightDate: flightLabelsParts.flightDate,
        flightTime: flightLabelsParts.flightTime,
        flightNumber: flightLabelsParts.flightNumber,
        airportsFromToByName: flightLabelsParts.airportsFromToByName,
        tariffName: flightLabelsParts.tariffName,
    };

    const price = selectedSeatLabel ? seatPriceDictionary?.[selectedSeatLabel]?.price : 0;
    const hasTemplate = !!selectedFlight.flight?.aircraftTemplate?.id;
    const forbidden = !(hasTemplate || canSeatInput);

    return {
        infoTextData,
        disabled,
        forbidden,
        price: localizeMoney(price, locale, 'utils/reservation/seats/useSeatsByPassenger'),
        tariffGroupCode: flightLabelsParts.tariffGroupCode,
        seatLabel: selectedSeatLabel,
        hiddenPrice: !hasTemplate,
    };
};

type UseSeatsByPassengerArgs = {
    selectSelectedFlightVariants: Selector<FlightVariants>,
    selectPassengers: Selector<PassengerBasket[]>,
    selectSeatPriceDictionary: Selector<{ [p: string]: Record<string, { price: number }> }>,
    canSeatInput: boolean,
    outboundDisabled?: boolean,
    returnDisabled?: boolean,
};
export const useSeatsByPassenger = (args: UseSeatsByPassengerArgs) => {
    const selectedFlights = useSelector(args.selectSelectedFlightVariants);
    const passengers = useSelector(args.selectPassengers);
    const seatPriceDictionary = useSelector(args.selectSeatPriceDictionary);

    const { locale } = useIntl();

    const outboundFlightSeatsData = (passenger: PassengerBasket) => getFlightSeatsDataForDirection({
        seatPriceDictionary: seatPriceDictionary['outbound'],
        selectedFlight: selectedFlights.outbound,
        selectedSeatLabel: passenger.seats.outbound,
        canSeatInput: args.canSeatInput,
        disabled: args.outboundDisabled,
        locale,
    });

    const returnFlightSeatsData = (passenger: PassengerBasket) => getFlightSeatsDataForDirection({
        seatPriceDictionary: seatPriceDictionary['return'],
        selectedFlight: selectedFlights.return,
        selectedSeatLabel: passenger.seats.return,
        canSeatInput: args.canSeatInput,
        disabled: args.returnDisabled,
        locale,
    });

    const seatsByPassenger = passengers.map<PassengerSeatsType | undefined>((passenger, idx) => {
        if (passenger.info.ageType === 'infant') return undefined;
        return {
            id: idx,
            ageType: passenger.info.ageType,
            passengerNameText: createFullName(passenger.info.firstName, passenger.info.lastName),
            outbound: outboundFlightSeatsData(passenger)!,
            ['return']: returnFlightSeatsData(passenger),
        };
    }).filter((x): x is PassengerSeatsType => !!x);

    return { seatsByPassenger };
};
