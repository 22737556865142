import { AircraftTemplate, Allotment, Flight, ObjectId, SeatsConfig } from '@malesia/json-schema';
import { FlightFormData } from '@malesia/react-components/dist/src/components/Flight/Edit/Info';
import { RepeatingFormData } from '@malesia/react-components/dist/src/components/Flight/Edit/Repeating';
import { ValidationFn } from '@malesia/react-components/dist/src/components/ValidationErrors';
import { FormConfig } from '@malesia/react-components/dist/src/hooks/useForm';
import { ValueConfig } from '@malesia/react-components/dist/src/hooks/valueForm';
import { useMemo } from 'react';
import { createCustomRequiredValidator } from '../../../utils/validation/createCustomRequiredValidator';
import { createCustomRoundValidator } from '../../../utils/validation/createCustomRoundValidator';
import { getValidationFunction } from '../../../utils/validation/getValidationFunction';
import { flightNewPermissions } from '../../permissions/adminPages/flight/flightNew';
import { useUserPermissions } from '../../permissions/useUserPermissions';
import { CreationFlightData } from './store/api';
import { fixFlightTimezone } from './utils';

type Config = {
    flightConfig: FormConfig<FlightFormData>,
    seatsConfig: FormConfig<SeatsConfig>,
    allotmentConfig: (value?: Allotment) => ValueConfig<Allotment>,
    repeatingConfig: FormConfig<RepeatingFormData>,
    validateFlight: ValidationFn<FlightFormData>,
    validateRepeating: ValidationFn<RepeatingFormData>,
    filterFlightData: (data: Partial<FlightFormData>) => Partial<FlightFormData>,
};

export const useFlightConfig = (mode: 'new' | 'edit', creationFlightData: CreationFlightData, initialFlightData?: Flight): Config => {
    const userPermissions = useUserPermissions();
    const result = useMemo<Config>(() => {
        const disabledSeatNumber = !userPermissions.has(flightNewPermissions.updateSeatNumber);
        const disabledCostPerSeat = !userPermissions.has(flightNewPermissions.updateCostPerSeat);

        const hiddenSeatNumber = (mode === 'new'
            ? !userPermissions.has(flightNewPermissions.createSeatNumber)
            : !userPermissions.has(flightNewPermissions.readSeatNumber)
        );
        const hiddenCostPerSeat = (mode === 'new'
            ? !userPermissions.has(flightNewPermissions.createCostPerSeat)
            : !userPermissions.has(flightNewPermissions.readCostPerSeat)
        );

        const filterFlightData = (data: Partial<FlightFormData>): Partial<FlightFormData> => {
            const seatsByTariff = creationFlightData.tariffGroupList.items.map((tariffGroup): SeatsConfig => {
                const tariff = data.seatsByTariff?.filter(
                    seatsConfig => seatsConfig.tariffGroup!.id === tariffGroup.id,
                ).pop();

                if (tariff) {
                    return {
                        ...tariff,
                        ...!hiddenSeatNumber && !disabledSeatNumber ? {
                            totalSeats: tariff.totalSeats,
                        } : {},
                        ...!hiddenCostPerSeat && !disabledCostPerSeat ? {
                            costPerSeat: tariff.costPerSeat,
                        } : {},
                    };
                }

                return {
                    tariffGroup: { id: tariffGroup.id! },
                };
            });

            const { departure, arrival } = fixFlightTimezone(data);

            return { ...data, departure, arrival, seatsByTariff };
        };

        const createFlightValidation: Config['validateFlight'] = (data: Partial<FlightFormData>) => {
            const preparedData = filterFlightData(data);
            const seatsByTariffNumberOfSeatsFields = preparedData.seatsByTariff?.map(
                (_, index) => `/seatsByTariff/${index}/numberOfSeatsToSale`,
            ) ?? [];
            const seatsByTariffCostPerSeatFields = preparedData.seatsByTariff?.map(
                (_, index) => `/seatsByTariff/${index}/costPerSeat`,
            ) ?? [];
            const seatsByTariffAgentSeatsLimit = preparedData.seatsByTariff?.map(
                (_, index) => `/seatsByTariff/${index}/agentSeatsLimit`,
            ) ?? [];
            const seatsByTariffOnlineSeatsLimit = preparedData.seatsByTariff?.map(
                (_, index) => `/seatsByTariff/${index}/onlineSeatsLimit`,
            ) ?? [];

            const errors = getValidationFunction(
                'file://malesiareisen.com/json/schema/project/api/call/flight/post/request.json',
                [
                    ... !hiddenSeatNumber && !disabledSeatNumber ? [
                        createCustomRequiredValidator(seatsByTariffNumberOfSeatsFields),
                    ] : [],
                    ... !hiddenCostPerSeat && !disabledCostPerSeat ? [
                        createCustomRequiredValidator(seatsByTariffCostPerSeatFields),
                    ] : [],
                    createCustomRequiredValidator(seatsByTariffAgentSeatsLimit),
                    createCustomRequiredValidator(seatsByTariffOnlineSeatsLimit),
                    createCustomRoundValidator(seatsByTariffCostPerSeatFields),
                ],
            )(preparedData);

            return errors;
        };

        const editFlightValidation: Config['validateFlight'] = (data: Partial<FlightFormData>) => {
            const preparedData = filterFlightData(data);
            const seatsByTariffNumberOfSeatsFields = preparedData.seatsByTariff?.map(
                (_, index) => `/seatsByTariff/${index}/numberOfSeatsToSale`,
            ) ?? [];
            const seatsByTariffCostPerSeatFields = preparedData.seatsByTariff?.map(
                (_, index) => `/seatsByTariff/${index}/costPerSeat`,
            ) ?? [];
            const seatsByTariffAgentSeatsLimit = preparedData.seatsByTariff?.map(
                (_, index) => `/seatsByTariff/${index}/agentSeatsLimit`,
            ) ?? [];
            const seatsByTariffOnlineSeatsLimit = preparedData.seatsByTariff?.map(
                (_, index) => `/seatsByTariff/${index}/onlineSeatsLimit`,
            ) ?? [];

            const errors = getValidationFunction(
                'file://malesiareisen.com/json/schema/project/api/call/flight/put/request.json',
                [
                    ... !hiddenSeatNumber && !disabledSeatNumber ? [
                        createCustomRequiredValidator(seatsByTariffNumberOfSeatsFields),
                    ] : [],
                    ... !hiddenCostPerSeat && !disabledCostPerSeat ? [
                        createCustomRequiredValidator(seatsByTariffCostPerSeatFields),
                    ] : [],
                    createCustomRequiredValidator(seatsByTariffAgentSeatsLimit),
                    createCustomRequiredValidator(seatsByTariffOnlineSeatsLimit),
                    createCustomRoundValidator(seatsByTariffCostPerSeatFields),
                ],
            )(preparedData);

            return errors;
        };

        const sequenceRepeatingValidation: Config['validateRepeating'] = (data) => {
            const errors = getValidationFunction(
                'file://malesiareisen.com/json/schema/project/api/call/flightSequence/post/request.json',
            )({ repeating: data });
            for (const errorKey in errors) {
                if (errorKey.match('/repeating')) {
                    errors[errorKey.slice(11)] = errors[errorKey];
                }
                delete errors[errorKey];
            }

            return errors;
        };

        let aircraftTemplateItems: (AircraftTemplate | ObjectId)[] = creationFlightData.aircraftTemplateList.items;
        const flightAircraftTemplate = initialFlightData?.aircraftTemplate;

        if (!!flightAircraftTemplate) {
            const isPresented = aircraftTemplateItems.some(
                aircraftTemplate => aircraftTemplate.id === flightAircraftTemplate.id,
            );
            const preparedTemplate = {
                ...flightAircraftTemplate,
                id: flightAircraftTemplate.id,
                name: `${flightAircraftTemplate.name!} *`,
            };

            aircraftTemplateItems = isPresented
                ? aircraftTemplateItems.map(
                    aircraftTemplate => aircraftTemplate.id === flightAircraftTemplate.id
                        ? preparedTemplate
                        : aircraftTemplate,
                )
                : [...aircraftTemplateItems, preparedTemplate];
        }

        return {
            flightConfig: {
                flightCompany: {
                    items: creationFlightData.companyList.items,
                    restrictions: {
                        required: true,
                    },
                },
                number: {
                    restrictions: {
                        required: true,
                    },
                },
                numberPrefix: {},
                origin: {
                    restrictions: {
                        required: true,
                    },
                    items: creationFlightData.airportList.items,
                },
                destination: {
                    restrictions: {
                        required: true,
                    },
                    items: creationFlightData.airportList.items,
                },
                departure: {
                    restrictions: {
                        required: true,
                    },
                },
                arrival: {
                    restrictions: {
                        required: true,
                    },
                },
                aircraftTemplate: { items: aircraftTemplateItems },
                isOnlineCheckInEnabled: {},
                isBookedOut: {},
                comment: {},
                isActive: {},
                rate: {
                    restrictions: {
                        required: true,
                    },
                    items: creationFlightData.rateList.items,
                },
                seatsByTariff: {},
            },
            seatsConfig: {
                tariffGroup: {
                    restrictions: {
                        required: true,
                    },
                },
                totalSeats: {
                    restrictions: {
                        required: true,
                    },
                    disabled: disabledSeatNumber,
                    hidden: hiddenSeatNumber,
                    skipValidation: disabledSeatNumber || hiddenSeatNumber,
                },
                costPerSeat: {
                    restrictions: {
                        required: true,
                    },
                    disabled: disabledCostPerSeat,
                    hidden: hiddenCostPerSeat,
                    skipValidation: disabledCostPerSeat || hiddenCostPerSeat,
                },
                agentSeatsLimit: {
                    restrictions: {
                        required: true,
                    },
                },
                onlineSeatsLimit: {
                    restrictions: {
                        required: true,
                    },
                },
            },
            allotmentConfig: (allotment) => {
                const invoiced = !!allotment?.invoice?.id;
                const disabled = invoiced || !userPermissions.has(flightNewPermissions.editAllotment);
                return {
                    shape: {
                        seatsNumber: {
                            disabled,
                        },
                        costPerSeat: {
                            disabled,
                            hidden: !userPermissions.has(flightNewPermissions.readAllotmentCost),
                            skipValidation: !userPermissions.has(flightNewPermissions.readAllotmentCost),
                        },
                        tariffGroup: {
                            disabled,
                        },
                        comment: {
                            disabled,
                        },
                    },
                };
            },
            repeatingConfig: {
                firstDate: {
                    restrictions: {
                        required: true,
                    },
                },
                lastDate: {
                    restrictions: {
                        required: true,
                    },
                },
                period: {
                    restrictions: {
                        required: true,
                    },
                },
                weekdays: {},
            },
            validateFlight: mode === 'edit' ? editFlightValidation : createFlightValidation,
            validateRepeating: sequenceRepeatingValidation,
            filterFlightData,
        } as Config;
    }, [userPermissions, creationFlightData, initialFlightData, mode]);

    return result;
};
