import { Flight } from '@malesia/json-schema';
import { push } from 'connected-react-router';
import * as queryString from 'query-string';
import { call, put, select, take, takeLatest, race } from 'typed-redux-saga';
import { logError } from '../../../../utils/log';
import { selectAircraftTemplatePopupAircraftTemplate } from '../../../containers/FlightAircraftTemplate/selectors';
import { aircraftTemplatePopupActions } from '../../../containers/FlightAircraftTemplate/slice';
import { backLinks } from '../../../containers/Routes/backLinks';
import { apiClientRequest, getApiErrorMessage } from '../../../services/ApiClient';
import { getApiFlight } from '../../../services/flight';
import { getApiCreationFlightData } from '../../FlightNewPage/store/api';
import { flightNotifications, parseFlightError } from '../../FlightNewPage/store/notification';
import { prepareFlightData } from '../../FlightNewPage/store/saga';
import {
    isNeedToAskAircraftConfirmation,
    isNeedToAskNotifying,
} from '../utils';
import {
    selectOldFlightData,
    selectNotifying,
    selectFlightData,
    selectIsAircraftSeatsConfigured,
    selectFlightAircraftTemplate,
    selectAllotments,
} from './selectors';
import { actions } from './slice';
import { UpdateFlightDataOptions } from './types';

function* getCreationFlightData() {
    try {
        const creationFlightData = yield* getApiCreationFlightData();
        yield* put(actions.getCreationFlightDataSuccess(creationFlightData));
    }
    catch (error) {
        logError({
            error,
            target: 'FlightEditPage.getCreationFlightData',
        });
        yield* put(actions.getCreationFlightDataError());
        const message = getApiErrorMessage(error);
        yield* put(flightNotifications.unknownError(message));
    }
}

export function* getCreationFlightDataSaga() {
    yield* takeLatest(actions.getCreationFlightData, getCreationFlightData);
}

export function* getFlightData(action: ReturnType<typeof actions.getFlightData>) {
    try {
        const flightId = action.payload;

        const result = yield* getApiFlight(flightId);
        yield* put(actions.getFlightDataSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'FlightEditPage.getFlightData',
        });
        yield* put(actions.getFlightDataError());
        const message = getApiErrorMessage(error);
        yield* put(flightNotifications.unknownError(message));
    }
}

export function* getFlightDataSaga() {
    yield* takeLatest(actions.getFlightData, getFlightData);
}

export function* setFlightData(action: ReturnType<typeof actions.setFlightData>) {
    if (!!action.payload.aircraftTemplate?.id && !action.payload.aircraftTemplate?.sections) {
        const requestData = {
            requestId: 'aircraftTemplate',
            uriParams: { id: action.payload.aircraftTemplate.id },
        };

        const result = yield* call(apiClientRequest, requestData);

        yield* put(actions.setFlightAircraftTemplate(result));
    }
}

export function* setFlightDataSaga() {
    yield* takeLatest(actions.setFlightData, setFlightData);
}

export function* requestUpdateFlightData(action: ReturnType<typeof actions.requestUpdateFlightData>) {
    try {
        const flightId = action.payload;

        const oldFlightData = (yield* select(selectOldFlightData))!;
        const flightData = yield* select(selectFlightData);
        const allotments = yield* select(selectAllotments);

        const isAircraftSeatsConfigured = yield* select(selectIsAircraftSeatsConfigured);
        const requestPayload = yield* prepareFlightData(flightData, allotments, isAircraftSeatsConfigured);

        const options: UpdateFlightDataOptions = {
            flightId,
            flight: requestPayload,
        };

        if (isNeedToAskAircraftConfirmation(oldFlightData, flightData)) {
            yield* put(actions.getFlightPassengers({ flightId }));

            const result = yield* take(actions.getFlightPassengersSuccess);
            const passengersWithSeats = result.payload.items.filter(item => {
                return !!item.seatLabel;
            });

            if (passengersWithSeats.length > 0) {
                yield* put(actions.setAircraftChangeConfirmationPassengers(passengersWithSeats));
                yield* put(actions.aksAircraftChangeConfirmation());
                yield* take(actions.aksAircraftChangeConfirmationConfirm);
            }
        }

        if (isNeedToAskNotifying(oldFlightData, flightData)) {
            yield* put(actions.getFlightPassengers({ flightId }));

            const result = yield* take(actions.getFlightPassengersSuccess);
            const passengers = result.payload.items;

            if (passengers.length > 0) {
                yield* put(actions.askNotifying());

                const { cancel } = yield* race({
                    confirm: take(actions.askNotifyingConfirm),
                    cancel: take(actions.askNotifyingCancel),
                });

                if (cancel) {
                    yield* put(actions.updateFlightDataError());
                    return;
                }

                const notifying = yield* select(selectNotifying);
                options.notifying = { ...notifying };
            }
        }

        yield* put(actions.updateFlightData(options));
    }
    catch (error) {
        logError({
            error,
            target: 'FlightEditPage.requestUpdateFlightData',
        });
        yield* put(actions.updateFlightDataError());
        const message = getApiErrorMessage(error);
        yield* put(flightNotifications.unknownError(message));
    }
}

export function* updateFlightData(action: ReturnType<typeof actions.updateFlightData>) {
    try {
        const { flightId, flight, notifying } = action.payload;

        const requestData = {
            requestId: 'flightDataUpdate',
            query: queryString.stringify(notifying ?? {}, {
                skipNull: true,
                sort: false,
            }),
            uriParams: { id: flightId },
            requestPayload: flight,
        };

        const result: Flight = yield* call(apiClientRequest, requestData);
        yield* put(actions.updateFlightDataSuccess());
        const back = backLinks.flight.back({
            highlightFlightId: result.id,
        });
        yield* put(push(back));
        yield* put(flightNotifications.updatedSuccessfully);
    }
    catch (error) {
        logError({
            error,
            target: 'FlightEditPage.getFlightPassengers',
        });
        yield* put(actions.updateFlightDataError());
        const message = parseFlightError(error);
        yield* put(message);
    }
}

export function* updateFlightDataSaga() {
    yield* takeLatest(actions.requestUpdateFlightData, requestUpdateFlightData);
    yield* takeLatest(actions.updateFlightData, updateFlightData);
}

export function* getFlightPassengers(action: ReturnType<typeof actions.getFlightPassengers>) {
    try {
        const { flightId } = action.payload;
        const uriParams = { flightId };
        const requestData = {
            requestId: 'flightPassengerList',
            uriParams,
        };

        const result = yield* call(apiClientRequest, requestData);
        yield* put(actions.getFlightPassengersSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'FlightEditPage.getFlightPassengers',
        });
        yield* put(actions.getFlightPassengersError());
        const message = getApiErrorMessage(error);
        yield* put(flightNotifications.unknownError(message));
    }
}

export function* getFlightPassengersSaga() {
    yield* takeLatest(actions.getFlightPassengers, getFlightPassengers);
}

function* clickSettings() {
    const currentAircraftTemplate = yield* select(selectFlightAircraftTemplate);

    if (!!currentAircraftTemplate) {
        yield* put(aircraftTemplatePopupActions.setAircraftTemplate(currentAircraftTemplate));
        yield* put(aircraftTemplatePopupActions.openModal());

        yield* take(aircraftTemplatePopupActions.confirmModal);

        const newAircraftTemplate = yield* select(selectAircraftTemplatePopupAircraftTemplate);
        if (newAircraftTemplate) {
            yield* put(actions.setFlightAircraftTemplate(newAircraftTemplate));
            yield* put(actions.setIsAircraftSeatsConfigured(true));
        }
    }
}

export function* clickSettingsSaga() {
    yield* takeLatest(actions.clickSettings, clickSettings);
}
