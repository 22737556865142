import { PassengersAges } from '@malesia/react-components/dist/src/components/Reservation/reservation-types';
import { PriceItemType } from '@malesia/react-components/dist/src/components/Reservation/Summary/Info/PriceBlock';
import { AgeType, translateAgeType } from '@malesia/react-components/dist/src/utils/ageType';
import { getObjectEntries } from '@malesia/react-components/dist/src/utils/object';
import { localizeMoney } from '@malesia/react-components/dist/src/utils/roundMoney';
import { createSelector } from '@reduxjs/toolkit';
import React from 'react';
import { RootState } from '../../../../types';
import { Selector } from '../../../../types/Selector';
import { createFlightsStatus } from '../../../../utils/reservation/flightsStatus';
import { getPassengersAgesByList } from '../../../../utils/reservation/passengerUtils';
import {
    GetSeatMapByDirection,
    seatPriceDictionaryCombiner,
    seatPriceDictionaryForDirectionCombiner,
} from '../../../../utils/reservation/seatMap';
import { findFlights } from '../../../../utils/reservation/selectedFlight';
import { createSummaryFlights } from '../../../../utils/reservation/summaryFlightCombiner';
import { selectLocale } from '../../../containers/App/selectors';
import { selectOptionList } from '../../../containers/AuxiliaryData/selectors';
import { selectNativeUserPermissions } from '../../../containers/UserLogin/selectors';
import { getRemovedOptions } from '../../../services/PassengerOption/utils';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.reservationNewPage || initialState;

export const selectMode = createSelector(
    [selectDomain],
    state => state.mode,
);

export const selectEditable = createSelector(
    [selectDomain],
    state => state.editable,
);

export const selectReservation = createSelector(
    [selectDomain],
    state => state.reservation,
);

export const selectReservationId = createSelector(
    [selectDomain],
    state => state.reservationId,
);

export const selectTravelType = createSelector(
    [selectReservation],
    reservation => reservation.travelInfo.type ?? 'return',
);

export const selectSelectedFlights = createSelector(
    [selectReservation],
    reservation => reservation.selectedFlights,
);

export const selectBookedFlights = createSelector(
    [selectReservation],
    reservation => reservation.bookedFlights,
);

export const selectFlightLists = createSelector(
    [selectDomain],
    state => state.flightLists,
);

export const selectSelectedFlightVariants = createSelector(
    [selectSelectedFlights, selectFlightLists],
    (selectedFlights, flightLists) => (
        findFlights(selectedFlights, {
            outbound: flightLists.outbound,
            ['return']: flightLists.return,
        })
    ),
);

export const selectBookedFlightsDate = createSelector(
    [selectDomain],
    (state) => ({
        departing: state.reservation.travelInfo.outbound.date,
        returning: state.reservation.travelInfo.return.date,
    }),
);

export const selectSelectedFlightsStatus = createSelector(
    [selectSelectedFlightVariants, selectTravelType],
    (flights, travelType) => {
        return createFlightsStatus(flights.outbound?.flight, flights.return?.flight, travelType !== 'one-way');
    },
);

export const selectOutboundFlightIsVisible = createSelector(
    [selectDomain],
    state => {
        const { mode, activeStep } = state;
        return mode === 'edit' || ['selectOneWayFlight', 'selectFlights'].includes(activeStep);
    },
);

export const selectReturnFlightIsVisible = createSelector(
    [selectDomain],
    state => {
        const { mode, activeStep } = state;
        const isNotOneWayTravel = state.reservation.travelInfo.type !== 'one-way';
        return isNotOneWayTravel && (mode === 'edit' || ['selectFlights'].includes(activeStep));
    },
);

export const selectBookedBillingInformation = createSelector(
    [selectDomain],
    state => state.reservation.bookedBillingInformation,
);

export const selectSearchedPassengerLists = createSelector(
    [selectDomain],
    state => state.searchedPassengerLists,
);

export const selectPassengers = createSelector(
    [selectReservation],
    reservation => reservation.passengers,
);

export const selectPassengersAges: Selector<PassengersAges> = createSelector(
    [selectPassengers],
    passengers => getPassengersAgesByList(passengers.map(x => x.info)),
);

export const selectBookedOptionList = createSelector(
    [selectReservation],
    reservation => reservation.bookedOptionList,
);

export const selectRemovedOptionList = createSelector(
    [selectBookedOptionList, selectOptionList],
    (booked, available) => getRemovedOptions(available, booked),
);

export const selectSeatMaps = createSelector(
    [selectDomain],
    state => state.seatMaps,
);

export const selectSeatMapForDirection = createSelector(
    [selectSeatMaps],
    (seatMaps): GetSeatMapByDirection => (
        (direction) => seatMaps[direction]
    ),
);

export const selectSeatPriceDictionaryForDirection = createSelector(
    [selectSeatMaps],
    seatPriceDictionaryForDirectionCombiner,
);

export const selectSeatPriceDictionary = createSelector(
    [selectSeatMaps, selectSeatPriceDictionaryForDirection],
    seatPriceDictionaryCombiner,
);

export const selectSummaryFlights = createSelector(
    [selectSelectedFlightVariants, selectLocale, selectNativeUserPermissions],
    (selectedFlights, locale, userPermissions) => (
        createSummaryFlights('admin', selectedFlights, locale, userPermissions)
    ),
);

export const selectIsCostDirty = createSelector(
    [selectDomain],
    state => state.isCostDirty,
);

export const selectIsCostLoading = createSelector(
    [selectDomain],
    state => state.isCostLoading,
);

export const selectIsUpdateLoading = createSelector(
    [selectDomain],
    state => state.isUpdateLoading,
);

export const selectCost = createSelector(
    [selectDomain],
    state => state.cost,
);

export const selectTotalCost = createSelector(
    [selectCost],
    (cost) => cost?.total ?? 0,
);

export const selectBookedCost = createSelector(
    [selectReservation],
    (reservation) => reservation.payment.bookedTotalPrice,
);

export const selectPassengersTickets: Selector<PriceItemType[]> = createSelector(
    [selectCost, selectLocale],
    (cost, locale) => {
        if (!cost) return [];
        const ticketsCostByAge: Record<AgeType, { cost: number, count: number }> = {
            adult: { cost: 0, count: 0 },
            child: { cost: 0, count: 0 },
            infant: { cost: 0, count: 0 },
        };
        for (const costForPassenger of cost.costForPassengers) {
            const ageType = costForPassenger.passenger.ageType!;
            ticketsCostByAge[ageType].count++;
            const cost = costForPassenger.flightsCosts.reduce((acc, flightCosts) => {
                const flightTotalCost = flightCosts.base + flightCosts.airportFee + flightCosts.fuelFee;
                return acc + flightTotalCost;
            }, 0);
            ticketsCostByAge[ageType].cost += cost;
        }

        return getObjectEntries(ticketsCostByAge)
            .filter(([, value]) => value.count !== 0)
            .map(([ageType, value]): PriceItemType => ({
                labelText: (
                    <>
                        {value.count}
                        {'x '}
                        {translateAgeType(ageType)}
                    </>

                ),
                priceText: `CHF ${localizeMoney(value.cost, locale, 'ReservationNewPageSummary/selectPassengersTickets')}`,
            }));
    },
);

export const selectSummaryOptions: Selector<PriceItemType[]> = createSelector(
    [selectCost, selectLocale],
    (cost, locale) => {
        if (!cost) return [];

    type OptionId = number;
    const optionsMap: Record<OptionId, { count: number, cost: number, title: string }> = {};
    cost.costForPassengers.forEach(passenger => passenger.optionsCosts?.forEach(costItem => {
        if (optionsMap[costItem.option.id]) {
            optionsMap[costItem.option.id].count++;
            optionsMap[costItem.option.id].cost += costItem.price;
        }
        else {
            optionsMap[costItem.option.id] = {
                count: 1,
                cost: costItem.price,
                title: costItem.option.configValues.title[locale] ?? costItem.option.configValues.title['en'],
            };
        }
    }));

    return Object.values(optionsMap)
        .map((option) => ({
            labelText: `${option.count}x ${option.title}`,
            priceText: `CHF ${localizeMoney(option.cost, locale, 'ReservationNewPageSummary/selectSummaryOptions')}`,
        }));
    },
);

export const selectSummaryTotalSeats: Selector<number> = createSelector(
    [selectCost],
    cost => {
        if (!cost) return 0;

        let totalCostSeats = 0;
        for (const costForPassenger of cost.costForPassengers) {
            const flightsCosts = costForPassenger.flightsCosts;
            for (const flightsCost of flightsCosts) {
                totalCostSeats += flightsCost.seat;
            }
        }
        return totalCostSeats;
    },
);

export const selectSummaryTotalSeatsCount: Selector<number> = createSelector(
    [selectReservation],
    reservation => {
        const oneIfHasSeat = (seatLabel?: string) => seatLabel ? 1 : 0;
        return reservation.passengers.reduce((total, passenger) => (
            total + oneIfHasSeat(passenger.seats.outbound) + oneIfHasSeat(passenger.seats.return)
        ), 0);
    },
);

export const selectStepBillingIsVisible: Selector<boolean> = createSelector(
    [
        selectSelectedFlightVariants,
        selectOutboundFlightIsVisible,
        selectTravelType,
    ],
    (flights, stepOutboundFlightIsVisible, travelType) => {
        const flightsIsSelected = travelType === 'one-way' ? flights.outbound : (flights.outbound && flights.return);
        return stepOutboundFlightIsVisible && !!flightsIsSelected;
    },
);

export const selectStepPassengersIsVisible: Selector<boolean> = createSelector(
    [selectStepBillingIsVisible],
    (stepBillingIsVisible) => stepBillingIsVisible,
);

export const selectStepSeatReservationIsVisible: Selector<boolean> = createSelector(
    [selectStepBillingIsVisible],
    (stepBillingIsVisible) => stepBillingIsVisible,
);

export const selectStepPaymentIsVisible: Selector<boolean> = createSelector(
    [selectStepSeatReservationIsVisible],
    (stepSeatReservationIsVisible) => {
        return stepSeatReservationIsVisible;
    },
);

export const selectIsReservationLoading = createSelector(
    [selectDomain],
    state => state.isReservationLoading,
);

export const selectTravelInfo = createSelector(
    [selectDomain],
    state => state.reservation.travelInfo,
);

export const selectPayment = createSelector(
    [selectDomain],
    (state) => state.reservation.payment,
);

export const selectSelectedOwnerDebitOnAccount = createSelector(
    [selectDomain],
    state => state.selectedOwnerAccount?.debitOnBalance ?? 0,
);

export const selectDisplayPriceToPay = createSelector(
    [
        selectTotalCost,
        selectPayment,
    ],
    (summaryTotal, payment) => {
        const { specificPrice, totalPaid } = payment;
        const diff = (specificPrice ?? summaryTotal) - (totalPaid ?? 0);
        return diff > 0 ? diff : 0;
    },
);

export const selectReservationComment = createSelector(
    [selectDomain],
    state => state.reservation.comment,
);

export const selectIsOpenPaymentDeadlinePopup = createSelector(
    [selectDomain],
    (state) => state.isOpenPaymentDeadlinePopup,
);
