import { FileMalesiareisenComJsonSchemaProjectApiCallReservationSortingParamsDefJson, ReservationListItem } from '@malesia/json-schema';
import { LoadingTable } from '@malesia/react-components/dist/src/components/LoadingOverlay/Table';
import { Table, SortingDataType, TableProps } from '@malesia/react-components/dist/src/components/Table';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParameters } from '../queryParameters';
import {
    selectLoading,
    selectDirty,
    selectSorting,
    selectList,
} from '../store/selectors';
import { actions } from '../store/slice';
import { useReservationColumns } from '../tableColumns';

export const ReservationListWithStore: SFC = () => {
    const dispatch = useDispatch();
    const loading = useSelector(selectLoading);
    const dirty = useSelector(selectDirty);
    const list = useSelector(selectList);
    const sorting = useSelector(selectSorting);
    const { highlightReservationId } = useQueryParameters();

    // eslint-disable-next-line max-len
    const handleSetSort = useCallback((data: FileMalesiareisenComJsonSchemaProjectApiCallReservationSortingParamsDefJson) => {
        dispatch(actions.setSorting({
            sortBy: data.sortBy,
            sortOrder: data.sortOrder,
        }));
    }, [dispatch]);

    const handleSetSelectedRows = useCallback((selectedRowsData: ReservationListItem[]) => {
        dispatch(actions.setSelectedRows(selectedRowsData));
    }, [dispatch]);

    const columns = useReservationColumns();

    const tableProps: TableProps<ReservationListItem> = {
        columns,
        data: list?.items ?? [],
        defaultSortBy: sorting.sortBy,
        defaultSortOrder: sorting.sortOrder,
        manualSortBy: true,
        // Todo make ReactTableFCProps['sorting'] compatible with enum'ed sortBy
        setSort: handleSetSort as (data: SortingDataType) => void,
        setSelectedRows: handleSetSelectedRows,
        tableModificators: {
            row: (row) => (
                row.original.id === highlightReservationId ? 'highlight' : undefined
            ),
        },
    };

    return (
        <LoadingTable
            isLoading={loading}
            isDirty={dirty}
        >
            <Table {...tableProps} />
        </LoadingTable>
    );
};
