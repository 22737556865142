import { AgentFormType } from '@malesia/react-components/dist/src/components/Agent/Edit';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { AgentNewPageState } from './types';

export const initialState: AgentNewPageState = {
    loading: false,
};

const agentNewPageSlice = createSlice({
    name: 'agentNewPage',
    initialState,
    reducers: {
        createAgent(state, action: PayloadAction<AgentFormType>) {
            state.loading = true;
        },
        createAgentSuccess(state) {
            state.loading = false;
        },
        createAgentError(state) {
            state.loading = false;
        },
    },
});

export const { actions, reducer, name: sliceKey } = agentNewPageSlice;
