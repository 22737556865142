import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { RateListFooterWithStore } from './components/RateListFooterWithStore';
import { RateListHeaderWithStore } from './components/RateListHeaderWithStore';
import { RateListWithStore } from './components/RateListWithStore';
import {
    getTariffListSaga,
    getRateListSaga,
} from './saga';
import { actions, reducer, sliceKey } from './slice';

export const RateListPage: SFC = () => {
    useInjectReducer({ actions, key: sliceKey, reducer });
    useInjectSaga({ key: `${sliceKey}Tariff`, saga: getTariffListSaga });
    useInjectSaga({ key: sliceKey, saga: getRateListSaga });

    return (
        <PageContentBlock>
            <RateListHeaderWithStore />
            <RateListWithStore />
            <RateListFooterWithStore />
        </PageContentBlock>
    );
};
