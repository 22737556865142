import { SearchPassenger } from '@malesia/react-components/dist/src/components/Form/Field/SearchPassenger';
import { SearchPassengerItemGroupData, SearchPassengerItemData } from '@malesia/react-components/dist/src/components/Form/Field/SearchPassenger/SearchPassengerItem';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedReservationOwner } from '../../../../containers/AuxiliaryData/selectors';
import { selectSearchedPassengerLists } from '../../store/selectors';
import { reservationNewPageActions } from '../../store/slice';

export const SelectPassenger: SFC = () => {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState('');
    const selectedOwner = useSelector(selectSelectedReservationOwner);

    useEffect(() => {
        dispatch(reservationNewPageActions.searchPassenger(searchValue));
    }, [dispatch, searchValue, selectedOwner]);

    const searchedPassengerLists = useSelector(selectSearchedPassengerLists);
    const items: SearchPassengerItemGroupData[] = useMemo(() => ([
        {
            label: (
                <FormattedMessage
                    id='front-app/ReservationNewPage/Steps/StepPassengers:OwnPassengers'
                    defaultMessage='Favourites'
                />
            ),
            items: searchedPassengerLists.own ?? [],
        },
        {
            label: (
                <FormattedMessage
                    id='front-app/ReservationNewPage/Steps/StepPassengers:AllPassengers'
                    defaultMessage='All'
                />
            ),
            items: searchedPassengerLists.all ?? [],
        },
    ]), [searchedPassengerLists]);

    const onSelect = useCallback((passenger?: SearchPassengerItemData) => {
        if (passenger) {
            dispatch(reservationNewPageActions.setSelectedPassenger(passenger));
        }
        setSearchValue('');
    }, [dispatch]);

    return (
        <SearchPassenger
            items={items}
            value={searchValue}
            onChange={setSearchValue}
            onSelect={onSelect}
            label={
                <FormattedMessage
                    id='front-app/ReservationNewPage/Steps/StepPassengers:SearchPassenger'
                    defaultMessage='Search'
                />
            }
        />
    );
};
