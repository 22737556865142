import { CommentFormType } from '@malesia/react-components/dist/src/components/Reservation/Summary/Admin';
import { LegacyFormConfig, readonlyFormConfig } from '@malesia/react-components/dist/src/hooks/useForm';
import { mapObject } from '@malesia/react-components/dist/src/utils/object';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { reservationNewPermissions } from '../../../permissions/adminPages/reservation/reservationNew';
import { UserPermissions } from '../../../permissions/userPermissions';
import { useUserPermissions } from '../../../permissions/useUserPermissions';
import { selectReservation } from '../store/selectors';

type Config = LegacyFormConfig<CommentFormType>;

const createConfig = (data: Partial<CommentFormType>): Config => ({
    comment: { initialValue: data.comment || '', disabled: true },
});

const configurePermissions = (
    config: Config,
    userPermissions: UserPermissions,
): Config => {
    const createWithComment = userPermissions.has(reservationNewPermissions.createComment);

    const hiddenMap: Partial<Record<keyof Config, boolean>> = {
        comment: !createWithComment,
    };

    let result = readonlyFormConfig(config);

    result = mapObject(result, ([key, value]) => ({
        ...value,
        hidden: hiddenMap[key] || value.hidden,
    }));
    return result;
};

export const useCommentFormConfig = (): Config => {
    const { comment } = useSelector(selectReservation);
    const userPermissions = useUserPermissions();
    return useMemo<Config>(() => {
        const data: Partial<CommentFormType> = { comment };
        const config = createConfig(data);
        return configurePermissions(config, userPermissions);
    }, [comment, userPermissions]);
};
