import { AircraftSeatsPlanSection } from '@malesia/react-components/dist/src/components/AircraftSeatsPlan';
import { checkSeatAbsent, unBookSeat } from '@malesia/react-components/dist/src/components/AircraftSeatsPlan/config';
import { FlightDirection } from '@malesia/react-components/dist/src/components/Reservation/reservation-types';
import { AdminReservationBasket } from '../../app/pages/ReservationNewPage/types';
import { BookedFlight, SelectedFlight } from './selectedFlight';
import { SeatMap, SeatMaps } from './types';

export type GetSeatMapByDirection = (direction: FlightDirection) => SeatMap | undefined;

export const removeBookedSeatSections = (
    sections: AircraftSeatsPlanSection[],
    bookedSeatLabels: (string | undefined)[],
): AircraftSeatsPlanSection[] => {
    const isBooked = (section: AircraftSeatsPlanSection, rowIndex: number, columnIndex: number) => {
        const label = `${section.rowLabels[rowIndex]}${section.columnLabels[columnIndex]}`;
        return bookedSeatLabels.some(x => x === label);
    };
    const result = sections.map<AircraftSeatsPlanSection>(section => {
        const seats = section.seats.map<number[]>((row, rowIndex) => (
            row.map((seat, columnIndex) => (
                isBooked(section, rowIndex, columnIndex) ? unBookSeat(seat) : seat
            ))
        ));
        return {
            ...section,
            seats,
        };
    });
    return result;
};

export const removeBookedSeatMap = (
    seatMap: SeatMap | undefined,
    bookedFlight: BookedFlight | undefined,
    selectedFlight: SelectedFlight | undefined,
): SeatMap | undefined => {
    if (!seatMap) return undefined;
    if (!bookedFlight) return seatMap;
    if (selectedFlight?.flightId !== bookedFlight?.flightId) return seatMap;
    const sections = removeBookedSeatSections(seatMap.sections, bookedFlight.seatLabels);
    return { sections };
};

export type RemoveBookedSeatMapsOptions = {
    seatMaps: SeatMaps,
    bookedFlights: AdminReservationBasket['bookedFlights'],
    selectedFlights: AdminReservationBasket['selectedFlights'],
};
export const removeBookedSeatMaps = (options: RemoveBookedSeatMapsOptions): SeatMaps => {
    const { seatMaps, selectedFlights, bookedFlights } = options;
    return {
        outbound: removeBookedSeatMap(seatMaps.outbound, bookedFlights.outbound, selectedFlights.outbound),
        ['return']: removeBookedSeatMap(seatMaps.return, bookedFlights.return, selectedFlights.return),
    };
};

export const seatPriceDictionaryForDirectionCombiner = (
    seatMaps: SeatMaps,
) => (direction: FlightDirection) => {
    const seatMap = seatMaps[direction];
    const result: Record<string, {price: number}> = {};
    seatMap?.sections.forEach(section => {
        section.rowLabels.forEach((rowLabel, rowIndex) => {
            section.columnLabels.forEach((columnLabel, columnIndex) => {
                const seatStatus = section.seats[rowIndex][columnIndex];
                if (checkSeatAbsent(seatStatus) || !rowLabel || !columnLabel) return;
                const seatLabel = rowLabel + columnLabel;
                const seatPrice = section.prices[rowIndex][columnIndex];
                result[seatLabel] = {
                    price: seatPrice,
                };
            });
        });
    });
    return result;
};

export const seatPriceDictionaryCombiner = (seatMaps: SeatMaps, dictionaryGetter) => (
    Object.fromEntries(
        Object.entries(seatMaps)
            .filter(([, seatMap]) => !!seatMap)
            .map(([flightDirection]) => {
                return [
                    flightDirection,
                    dictionaryGetter(flightDirection),
                ];
            }),
    )
);
