import { LegacyFormFieldSelect } from '@malesia/react-components/dist/src/components/Form/Field/Select/legacy';
import { SelectItem } from '@malesia/react-components/dist/src/components/SelectBox/legacy';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { flightListPermissions } from '../../../permissions/adminPages/flight/flightList';
import { useUserPermissions } from '../../../permissions/useUserPermissions';
import { selectSelectedRows } from '../store/selectors';
import { actions } from '../store/slice';

export const FlightListBulkActions: SFC<object, { result: 'optional' }> = () => {
    const selectedRows = useSelector(selectSelectedRows);
    const userPermissions = useUserPermissions();

    const dispatch = useDispatch();

    type Item = SelectItem & {
        action: () => void,
    };
    const items: Item[] = [
        {
            name: (
                <FormattedMessage
                    id='front-app/Flight/List/BulkActions:Select'
                    defaultMessage='Please select action...'
                />
            ),
            value: '',
            action: () => {},
            hidden: false,
        },
        {
            name: (
                <FormattedMessage
                    id='front-app/Flight/List/BulkActions:Delete'
                    defaultMessage='Delete'
                />
            ),
            value: 'delete',
            action: () => {
                dispatch(actions.openDeleteFlights());
            },
            hidden: !userPermissions.has(flightListPermissions.deleteFlight),
        },
        {
            name: (
                <FormattedMessage
                    id='front-app/Flight/List/BulkActions:Turnover'
                    defaultMessage='Create turnover sheet'
                />
            ),
            value: 'createTurnover',
            action: () => {
                dispatch(actions.downloadTurnoverList());
            },
            hidden: !userPermissions.has(flightListPermissions.turnoverPdf),
        },
        {
            name: (
                <FormattedMessage
                    id='front-app/Flight/List/BulkActions:PassengerReport'
                    defaultMessage='Create passenger report'
                />
            ),
            value: 'createPassengerReport',
            action: () => {
                dispatch(actions.downloadPassengerReport());
            },
            hidden: !userPermissions.has(flightListPermissions.passengerReport),
        },
    ];

    if (!userPermissions.has(flightListPermissions.bulkActions)) return null;

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const item = items.find(x => x.value === event.target.value);
        if (!item) return;
        item.action();
    };

    return (
        <LegacyFormFieldSelect
            value=''
            onChange={handleSelectChange}
            items={items}
            disabled={!selectedRows.length}
        />
    );
};
