import React from 'react';
import { FormattedMessage } from 'react-intl';
import { appNotification } from '../App/appNotification';
import { createLocalNotification } from '../App/utils';

const notification = createLocalNotification('CashbackPdf', {
    error: (
        <FormattedMessage
            id='front-app/CashbackPdf/Error:title'
            defaultMessage='Cashback Pdf Error'
        />
    ),
});

export const cashbackPdfNotifications = {
    unknownError: (details?: JSX.Element | string) => (
        notification.error(appNotification.texts.unknownError(details))
    ),
};
