import { allotmentColumns } from '@malesia/react-components/dist/src/components/Allotment/List/tableColumns';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectAllotmentInvoicePdfLoadingMap } from '../../containers/AllotmentInvoicePdf/selectors';
import { allotmentInvoicePdfActions } from '../../containers/AllotmentInvoicePdf/slice';
import { backLinks } from '../../containers/Routes/backLinks';
import { allotmentListPermissions } from '../../permissions/adminPages/allotment/allotmentList';
import { flightListPermissions } from '../../permissions/adminPages/flight/flightList';
import { useUserPermissions } from '../../permissions/useUserPermissions';

export const useTableColumns = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userPermissions = useUserPermissions();
    const allotmentInvoicePdfLoadingMap = useSelector(selectAllotmentInvoicePdfLoadingMap);

    const result = useMemo(() => allotmentColumns({
        select: {
            hidden: !userPermissions.has(allotmentListPermissions.create),
        },
        actions: {
            editFlight: {
                click: (allotment) => {
                    const link = backLinks.flight.forward(allotment.flight?.id, {
                        source: 'allotmentList',
                    });
                    history.push(link);
                },
                notAllowed: !userPermissions.has(flightListPermissions.editLink),
            },
            invoicePdf: {
                click: (allotment, _, tableRef) => {
                    dispatch(allotmentInvoicePdfActions.downloadAllotmentInvoicePdf({
                        id: allotment.invoice!.id!,
                        callback: () => tableRef.renderColumn('actions'),
                    }));
                    tableRef.renderColumn('actions');
                },
                disabled: (allotment) => !allotment.invoice?.id,
                isLoading: (allotment) => {
                    const id = allotment.invoice?.id;
                    if (!id) return false;
                    return allotmentInvoicePdfLoadingMap[id];
                },
                notAllowed: !userPermissions.has(allotmentListPermissions.pdf),
            },
        },
    }), [dispatch, history, userPermissions, allotmentInvoicePdfLoadingMap]);
    return result;
};
