import { permissionMaker } from '../../permissionExpression';

const { role, anyone } = permissionMaker;

const partialPayment = role('ROLE_RESERVATION_INVOICE_ADD_PARTIAL_PAYMENT');
const cash = role('ROLE_RESERVATION_INVOICE_ADD_PAYMENT_TYPE_CASH');
const bank = role('ROLE_RESERVATION_INVOICE_ADD_PAYMENT_TYPE_BANK');
const datatrans = anyone();
const transfer = role('ROLE_RESERVATION_INVOICE_ADD_PAYMENT_TYPE_TRANSFER');
const account = role('ROLE_RESERVATION_INVOICE_ADD_PAYMENT_TYPE_ACCOUNT');

export const reservationPaymentPermissions = {
    partialPayment,
    cash,
    bank,
    datatrans,
    transfer,
    account,
};
