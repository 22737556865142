import { UserProfilePaginatedList } from '@malesia/json-schema';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../../utils/@reduxjs/toolkit';
import { UserPermissionsListPageState } from './types';

export const initialState: UserPermissionsListPageState = {
    initialized: {
        sorting: false,
    },
    isDirty: true,
    isLoading: true,

    filterData: {
        searchText: '',
        isActiveOnly: true,
        userType: 'manager',
    },
    sortBy: 'timestamp',
    sortOrder: 'DESC',
    pagination: {
        page: 0,
        pageSize: 10,
    },
    userList: {
        items: [],
        total: 0,
    },
};

const userPermissionsListPageSlice = createSlice({
    name: 'userPermissionsListPageState',
    initialState,
    reducers: {
        getUserList(state) {
            state.isLoading = true;
        },
        getUserListSuccess(state, action: PayloadAction<UserProfilePaginatedList>) {
            state.userList = action.payload;
            state.isLoading = false;
            state.isDirty = false;
        },
        getUserListError(state) {
            state.isLoading = false;
        },
        setPage(state, action: PayloadAction<number>) {
            state.pagination.page = action.payload;
            state.isDirty = true;
        },
        setPageSize(state, action: PayloadAction<number>) {
            state.pagination.pageSize = action.payload;
            state.pagination.page = initialState.pagination.page;
            state.isDirty = true;
        },
        setSorting(state, action: PayloadAction<{ sortBy: string, sortOrder: string }>) {
            state.sortBy = action.payload.sortBy;
            state.sortOrder = action.payload.sortOrder;
            state.isDirty = true;
            state.initialized.sorting = true;
        },
        setFilterData(state, action: PayloadAction<UserPermissionsListPageState['filterData']>) {
            state.filterData = action.payload;
            state.pagination.page = initialState.pagination.page;
            state.isDirty = true;
        },
        dispose(state) {
            state.initialized = initialState.initialized;
            state.isLoading = true;
            state.isDirty = true;
        },
    },

});

export const {
    actions: userPermissionsListPageActions,
    reducer: userPermissionsListPageReducer,
    name: userPermissionsListPageSliceName,
} = userPermissionsListPageSlice;
