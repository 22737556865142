import { AllotmentInvoicePaymentPostRequest } from '@malesia/json-schema';
import { push } from 'connected-react-router';
import { call, put, select, takeLatest, all } from 'typed-redux-saga';
import { logError } from '../../../../utils/log';
import { allAdminRoutePaths } from '../../../containers/Routes/allAdminRoutePaths';
import { backLinks } from '../../../containers/Routes/backLinks';
import { getApiAllotmentInvoice } from '../../../services/allotmentInvoice';
import { createApiAllotmentInvoicePayment, getApiPaymentAccountList } from '../../../services/payment';
import { paymentNotifications } from './notification';
import { selectAllotmentInvoice, selectPaymentData, selectTransferTotal } from './selectors';
import { allotmentPaymentActions } from './slice';

function* loadAllotmentInvoice(allotmentInvoiceId: number) {
    try {
        const response = yield* call(getApiAllotmentInvoice, allotmentInvoiceId);
        yield* put(allotmentPaymentActions.setAllotmentInvoice(response));
    }
    catch (error) {
        logError({
            error,
            target: 'AllotmentPaymentPage.loadReservation',
        });
        yield* put(paymentNotifications.notFoundAllotmentInvoice(allotmentInvoiceId));
    }
}

function* loadPaymentAccounts() {
    try {
        const response = yield* all({
            cash: getApiPaymentAccountList({ paymentType: 'cash' }),
            transfer: getApiPaymentAccountList({ paymentType: 'transfer' }),
        });
        yield* put(allotmentPaymentActions.setCashAccounts(response.cash));
        yield* put(allotmentPaymentActions.setTransferAccounts(response.transfer));
    }
    catch (error) {
        logError({
            error,
            target: 'AllotmentPaymentPage.loadPaymentAccounts',
        });
        yield* put(paymentNotifications.notLoadedPaymentAccounts);
        throw error;
    }
}

function* loadInitialData(action: ReturnType<typeof allotmentPaymentActions.loadInitialData>) {
    const allotmentInvoiceId = action.payload;
    try {
        yield* put(allotmentPaymentActions.setAllotmentInvoiceLoading(true));

        yield* all([
            call(loadAllotmentInvoice, allotmentInvoiceId),
            call(loadPaymentAccounts),
        ]);
    }
    catch (error) {
        logError({
            error,
            target: 'AllotmentPaymentPage.loadInitialData',
        });
        yield* put(push(allAdminRoutePaths.reservationList));
    }
    finally {
        yield* put(allotmentPaymentActions.setAllotmentInvoiceLoading(false));
    }
}

export function* loadInitialDataSaga() {
    yield* takeLatest(allotmentPaymentActions.loadInitialData, loadInitialData);
}

function* createPayment() {
    try {
        const data = yield* select(selectPaymentData);
        const transferTotal = yield* select(selectTransferTotal);
        const allotmentInvoice = yield* select(selectAllotmentInvoice);

        let paymentTypeCode: string | undefined;
        switch (data.paymentMethod) {
            case 'cash':
                paymentTypeCode = 'cash';
                break;
            case 'transfer':
                paymentTypeCode = 'transfer';
                break;
            default:
                throw new Error('Unknown paymentMethod');
        }

        const allotmentInvoiceId = allotmentInvoice!.id;
        const requestData: AllotmentInvoicePaymentPostRequest = {
            amount: transferTotal,
            paymentTypeCode,
            allotmentInvoiceId,
        };

        yield* call(createApiAllotmentInvoicePayment, requestData);
        yield* put(paymentNotifications.paymentSuccess);
        yield* put(allotmentPaymentActions.createPaymentSuccess());
        const back = backLinks.allotmentPayment.back({
            highlightAllotmentInvoiceId: allotmentInvoiceId,
        });
        yield* put(push(back));
    }
    catch (error) {
        logError({
            error,
            target: 'AllotmentPaymentPage.createPayment',
        });
        yield* put(paymentNotifications.issueWithPayNow);
        yield* put(allotmentPaymentActions.createPaymentError());
    }
}

export function* createPaymentSaga() {
    yield* takeLatest(allotmentPaymentActions.createPayment, createPayment);
}
