import { permissionMaker } from '../../permissionExpression';

const { role } = permissionMaker;

const cashbackPayment = role('ROLE_BALANCE_LOG_RECORD_BASIC_PAYMENT_CASHBACK');
const chargeAccountPayment = role('ROLE_BALANCE_LOG_RECORD_BASIC_PAYMENT_PREPAID_DEPOSIT');
const depositsPayment = role('ROLE_BALANCE_LOG_RECORD_BASIC_PAYMENT_SETTLEMENT');
const advancedPayment = role('ROLE_BALANCE_LOG_RECORD_ADVANCED_PAYMENT');

const accountVirtualList = role('ROLE_ACCOUNT_VIRTUAL_LIST_FREE');
const accountManagerList = role('ROLE_ACCOUNT_LIST_FREE_EXTENDED');
const accountListAccountant = role('ROLE_ACCOUNT_LIST_ACCOUNTANT');

export const newTransactionPermissions = {
    cashbackPayment,
    chargeAccountPayment,
    depositsPayment,
    advancedPayment,
    accountVirtualList,
    accountManagerList,
    accountListAccountant,
};
