import { TariffGroupEntireList } from '@malesia/json-schema';
import { call } from 'typed-redux-saga';
import { apiClientRequest } from './ApiClient';

export function* getApiTariffGroupList() {
    const response: TariffGroupEntireList = yield* call(apiClientRequest, {
        requestId: 'tariffGroupEntireList',
    });
    return response;
}
