import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { AircraftTemplatePopupState } from './types';

export const initialState: AircraftTemplatePopupState = {
    isOpened: false,
    aircraftTemplate: undefined,
    selectedSeats: [],
    actionData: {
        price: 0,
        action: 'select',
    },
};

const aircraftTemplatePopupSlice = createSlice({
    name: 'aircraftTemplatePopup',
    initialState,
    reducers: {
        openModal(state) {
            state.isOpened = true;
        },
        closeModal(state) {
            state.isOpened = false;
        },
        confirmModal(state) {
            state.isOpened = false;
        },
        setAircraftTemplate(state, action: PayloadAction<AircraftTemplatePopupState['aircraftTemplate']>) {
            state.aircraftTemplate = action.payload;
        },
        clickSeat(state, action: PayloadAction<string>) {},
        doubleClickSeat(state, action: PayloadAction<string>) {},
        toggleSelectedSeat(state, action: PayloadAction<string>) {
            state.selectedSeats = state.selectedSeats.includes(action.payload)
                ? state.selectedSeats.filter(seatLabel => seatLabel !== action.payload)
                : [...state.selectedSeats, action.payload];
        },
        selectSeat(state, action: PayloadAction<string>) {
            state.selectedSeats = state.selectedSeats.includes(action.payload)
                ? state.selectedSeats
                : [...state.selectedSeats, action.payload];
        },
        unSelectSeat(state, action: PayloadAction<string>) {
            state.selectedSeats = state.selectedSeats.includes(action.payload)
                ? state.selectedSeats.filter(seatLabel => seatLabel !== action.payload)
                : state.selectedSeats;
        },
        clearSelectedSeats(state) {
            state.selectedSeats = [];
        },
        toggleSeatValue(
            state,
            action: PayloadAction<{sectionIndex: number, rowIndex: number, columnIndex: number, value: number}>,
        ) {
            const sectionIndex = action.payload.sectionIndex;
            const rowIndex = action.payload.rowIndex;
            const columnIndex = action.payload.columnIndex;
            const value = action.payload.value;

            const oldValue = state.aircraftTemplate?.sections?.[sectionIndex]['seats'][rowIndex][columnIndex];
            if (oldValue !== undefined) {
                const newValue = !!(oldValue & value) ? oldValue & ~value : value;
                state.aircraftTemplate!.sections![sectionIndex]['seats'][rowIndex][columnIndex] = newValue;
            }
        },
        setSeatAction(state, action: PayloadAction<AircraftTemplatePopupState['actionData']>) {
            if (state.actionData.action !== action.payload.action) {
                state.actionData.action = action.payload.action;
                state.selectedSeats = [];
            }
            if (state.actionData.price !== action.payload.price) {
                state.actionData.price = action.payload.price;
            }
        },
        applySeatAction() {},
        resetSeatAction(state) {
            state.actionData = initialState.actionData;
        },
        setSeatPrice(
            state,
            action: PayloadAction<{sectionIndex: number, rowIndex: number, columnIndex: number, price: number}>,
        ) {
            const sectionIndex = action.payload.sectionIndex;
            const rowIndex = action.payload.rowIndex;
            const columnIndex = action.payload.columnIndex;
            const price = action.payload.price;

            state.aircraftTemplate!.sections![sectionIndex]!['prices']![rowIndex]![columnIndex] = price;
        },
    },
});

export const {
    actions: aircraftTemplatePopupActions,
    reducer: aircraftTemplatePopupReducer,
    name: aircraftTemplatePopupSliceKey,
} = aircraftTemplatePopupSlice;
