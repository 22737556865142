import { ReservationPaginatedList, FileMalesiareisenComJsonSchemaProjectApiCallReservationSortingParamsDefJson } from '@malesia/json-schema';
import { ReservationInvoiceListFilterData } from '@malesia/react-components/dist/src/components/Reservation/InvoiceList/Filter/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../../utils/@reduxjs/toolkit';
import { InvoiceOverviewListPageState } from './types';

export const initialState: InvoiceOverviewListPageState = {
    filterData: {
        paymentStatus: ['unpaid'],
    },
    table: {
        list: {
            total: 0,
            items: [],
        },
        pagination: {
            page: 0,
            pageSize: 15,
        },
        sorting: {
            sortBy: 'paymentDeadline',
            sortOrder: 'ASC',
        },

        initialized: {
            sorting: false,
        },
        dirty: true,
        loading: true,
    },
};

const invoiceOverviewListPageSlice = createSlice({
    name: 'invoiceOverviewListPage',
    initialState,
    reducers: {
        getReservationList(state) {
            state.table.loading = true;
        },
        getReservationListSuccess(state, action: PayloadAction<ReservationPaginatedList>) {
            state.table.list = action.payload;
            state.table.dirty = false;
            state.table.loading = false;
        },
        getReservationListFail(state) {
            state.table.loading = false;
        },
        setFilterData(state, action: PayloadAction<ReservationInvoiceListFilterData>) {
            if (JSON.stringify(action.payload) !== JSON.stringify(state.filterData)) {
                state.table.dirty = true;
                state.table.pagination.page = initialState.table.pagination.page;
                state.filterData = {
                    ...state.filterData,
                    ...action.payload,
                };
            }
        },
        // eslint-disable-next-line max-len
        setSorting(state, action: PayloadAction<FileMalesiareisenComJsonSchemaProjectApiCallReservationSortingParamsDefJson>) {
            state.table.sorting = action.payload;
            state.table.dirty = true;
            state.table.initialized.sorting = true;
        },
        setPage(state, action: PayloadAction<number>) {
            state.table.pagination.page = action.payload;
            state.table.dirty = true;
        },
        setPageSize(state, action: PayloadAction<number>) {
            state.table.pagination.pageSize = action.payload;
            state.table.pagination.page = initialState.table.pagination.page;
            state.table.dirty = true;
        },
    },
});

export const { actions, reducer, name: sliceKey } = invoiceOverviewListPageSlice;
