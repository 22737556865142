import { ApiCallEntityLogSortingParams, EntityLogEntityTypeEntireList, EntityLogOperationTypeEntireList, EntityLogPaginatedList } from '@malesia/json-schema';
import { EventLogFilterData } from '@malesia/react-components/dist/src/components/EventLog/List/Filter';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../../utils/@reduxjs/toolkit';
import { EntityLogListPageState } from './types';

export const initialState: EntityLogListPageState = {
    filterData: {
        search: undefined,
        fromDate: undefined,
        toDate: undefined,
        entityType: undefined,
        operationType: undefined,
    },
    table: {
        list: {
            items: [],
            total: 0,
        },
        sorting: {
            sortBy: 'timestamp',
            sortOrder: 'DESC',
        },
        pagination: {
            page: 0,
            pageSize: 15,
        },

        initialized: {
            sorting: false,
        },
        dirty: true,
        loading: true,
    },

    entityTypeList: {
        items: [],
    },
    operationTypeList: {
        items: [],
    },
};

const entityLogListPageSlice = createSlice({
    name: 'entityLogListPage',
    initialState,
    reducers: {
        setFilterData(state, action: PayloadAction<Partial<EventLogFilterData>>) {
            state.filterData = {
                ...state.filterData,
                ...action.payload,
            };
            state.table.dirty = true;
            state.table.pagination.page = 0;
        },
        setPage(state, action: PayloadAction<number>) {
            state.table.pagination.page = action.payload;
            state.table.dirty = true;
        },
        setPageSize(state, action: PayloadAction<number>) {
            state.table.pagination.pageSize = action.payload;
            state.table.pagination.page = initialState.table.pagination.page;
            state.table.dirty = true;
        },
        setSorting(state, action: PayloadAction<ApiCallEntityLogSortingParams>) {
            state.table.sorting = action.payload;
            state.table.dirty = true;
            state.table.initialized.sorting = true;
        },
        getEntityTypeList() {},
        getEntityTypeListSuccess(state, action: PayloadAction<EntityLogEntityTypeEntireList>) {
            state.entityTypeList = action.payload;
        },
        getEntityTypeListError() {
        },
        getOperationTypeList() {},
        getOperationTypeListSuccess(state, action: PayloadAction<EntityLogOperationTypeEntireList>) {
            state.operationTypeList = action.payload;
        },
        getOperationTypeListError() {
        },
        getEntityLogList(state) {
            state.table.loading = true;
        },
        getEntityLogListSuccess(state, action: PayloadAction<EntityLogPaginatedList>) {
            state.table.list = action.payload;
            state.table.loading = false;
            state.table.dirty = false;
        },
        getEntityLogListError(state) {
            state.table.loading = false;
        },
        unmount(state) {
            return {
                ...initialState,
                filterData: state.filterData,
            };
        },
    },
});

export const { actions, reducer, name: sliceKey } = entityLogListPageSlice;
