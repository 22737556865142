import { permissionMaker } from '../../permissionExpression';

const { every, role } = permissionMaker;

const readUser = role('ROLE_ADMIN');
const readUserRoles = role('ROLE_ADMIN');
const updateUserRoles = role('ROLE_ADMIN');
const readUserVirtualAccounts = every(
    'ROLE_ACCOUNT_LIST',
    'ROLE_USER_VIRTUAL_ACCOUNTS_LIST',
    readUser,
);
const updateUserVirtualAccounts = role('ROLE_ADMIN');
const userRolesTab = readUserRoles;
const userVirtualAccountsTab = readUserVirtualAccounts;

export const userPermissionsPermissions = {
    readUserRoles,
    readUserVirtualAccounts,
    updateUserRoles,
    updateUserVirtualAccounts,
    userRolesTab,
    userVirtualAccountsTab,
};
