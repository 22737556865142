import React from 'react';
import { FormattedMessage } from 'react-intl';
import { appNotification } from '../App/appNotification';
import { createLocalNotification } from '../App/utils';

const notification = createLocalNotification('AllotmentInvoicePdf', {
    error: (
        <FormattedMessage
            id='front-app/AllotmentInvoicePdf/Error:title'
            defaultMessage='Allotment Invoice Pdf Error'
        />
    ),
});

export const allotmentInvoicePdfNotifications = {
    unknownError: (details?: JSX.Element | string) => (
        notification.error(appNotification.texts.unknownError(details))
    ),
};
