import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { AllotmentInvoicePdfState, DownloadActionType } from './types';

export const initialState: AllotmentInvoicePdfState = {
    isLoadingMap: {},
};

const cashbackPdfSlice = createSlice({
    name: 'allotmentInvoicePdf',
    initialState,
    reducers: {
        downloadAllotmentInvoicePdf(state, action: PayloadAction<DownloadActionType>) {
            state.isLoadingMap[action.payload.id] = true;
        },
        downloadAllotmentInvoicePdfSuccess(state, action: PayloadAction<number>) {
            const transactionId = action.payload;
            state.isLoadingMap[transactionId] = false;
        },
        downloadAllotmentInvoicePdfFail(state, action: PayloadAction<number>) {
            const transactionId = action.payload;
            state.isLoadingMap[transactionId] = false;
        },
        unmount() {
            // Not reset. It's global loading.
        },
    },
});

export const {
    actions: allotmentInvoicePdfActions,
    reducer: allotmentInvoicePdfReducer,
    name: allotmentInvoicePdfSliceKey,
} = cashbackPdfSlice;
