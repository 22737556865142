import { BackLink } from '@malesia/react-components/dist/src/components/BackLink';
import { LoadingPage } from '@malesia/react-components/dist/src/components/LoadingOverlay/Page';
import { ManagerEdit, ManagerFormType } from '@malesia/react-components/dist/src/components/Manager/Edit';
import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { filterDisabledData } from '@malesia/react-components/dist/src/hooks/useForm';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { createCustomPasswordConfirmValidator } from '../../../utils/validation/createCustomPasswordConfirmValidator';
import { getValidationFunction } from '../../../utils/validation/getValidationFunction';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { useInjectSharedData } from '../../containers/SharedData/inject';
import { selectAdminCountryList, selectAdminCountryLoading } from '../../containers/SharedData/selectors';
import { sharedActions } from '../../containers/SharedData/slice';
import { useManagerFormConfig } from './config';
import { createManagerSaga } from './saga';
import { selectLoading } from './selectors';
import { actions, reducer, sliceKey } from './slice';

export const ManagerNewPage: SFC = () => {
    useInjectSharedData();
    useInjectReducer({ actions, key: sliceKey, reducer });
    useInjectSaga({
        key: `${sliceKey}CreateManager`,
        saga: createManagerSaga,
    });

    const countryData = useSelector(selectAdminCountryList);
    const loading = useSelector(selectLoading);
    const loadingCountries = useSelector(selectAdminCountryLoading);
    const isAnythingLoading = loading || loadingCountries;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(sharedActions.getAdminCountryList());
    }, [dispatch]);

    const formConfig = useManagerFormConfig();

    const onSubmitForm = useCallback((data: ManagerFormType) => {
        const managerData = filterDisabledData(data, formConfig);
        dispatch(actions.createManager(managerData));
    }, [formConfig, dispatch]);

    const validationFn = useMemo(() => (
        getValidationFunction(
            'file://malesiareisen.com/json/schema/project/react-front-app/pages/ManagerNewPage/managerNewForm.json',
            [
                createCustomPasswordConfirmValidator<ManagerFormType>('/password', '/passwordConfirm'),
            ],
        )
    ), []);

    return (
        <PageContentBlock>
            <PageTitle
                text={
                    <FormattedMessage
                        id='front-app/ManagerNewPage:Title'
                        defaultMessage='New manager'
                    />
                }
            />
            <BackLink
                to={allAdminRoutePaths.userManagerList}
                modificators='stick-to-title'
            />
            <LoadingPage isLoading={isAnythingLoading} />
            {!isAnythingLoading && (
                <ManagerEdit
                    countryList={countryData.items}
                    formConfig={formConfig}
                    validator={validationFn}
                    onSubmit={onSubmitForm}
                />
            )}
        </PageContentBlock>
    );
};
