import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'typed-redux-saga';
import { getDatatransSearchParams } from '../../../../../utils/datatrans/getDatatransSearchParams';
import { logError } from '../../../../../utils/log';
import { allAdminRoutePaths } from '../../../../containers/Routes/allAdminRoutePaths';
import { refreshUserAccount } from '../../../../containers/UserLogin/saga';
import { datatransService } from '../../../../services/Datatrance/datatrans.service';
import { paymentNotifications } from '../../../ReservationPaymentPage/store/notification';
import { agentPaymentActions } from '../AgentPayment.slice';

function* onLoad() {
    const { transactionId, status, additionalProp } = getTransactionFromSearchString();
    const routeIsExist = additionalProp && additionalProp in allAdminRoutePaths;
    if (!routeIsExist || !status || !transactionId) {
        yield* put(paymentNotifications.issueWithPayNow);
        yield* put(push(allAdminRoutePaths.reservationList));
        return;
    }

    const url = allAdminRoutePaths[additionalProp];

    switch (status) {

        case 'error':
            yield* put(paymentNotifications.datatransError());
            break;

        case 'success': {
            yield* call(confirmTransaction, transactionId);
            yield* call(refreshUserAccount);
            break;
        }
        case 'cancel':
            yield* put(paymentNotifications.notCompleted);
            break;

        default:
            yield* put(paymentNotifications.datatransError());
    }
    yield* put(push(url));
}

export function* onLoadSaga() {
    yield* takeLatest(agentPaymentActions.onLoad, onLoad);
}

function getTransactionFromSearchString() {
    const search = new URLSearchParams(window.location.search);
    const additionalProp = search.get('additionalProp');
    const { status, transactionId } = getDatatransSearchParams();
    return { transactionId, status, additionalProp };
}

function* confirmTransaction(transactionId: string) {
    try {
        yield* call(datatransService.paymentDatatransReservationConfirm, { transactionId });
        yield* put(paymentNotifications.paymentSuccess);
    }
    catch (error) {
        logError({
            error,
            target: 'AgentPayment.confirmTransaction',
        });
        yield* put(paymentNotifications.notConfirmedTransaction);
    }
}
