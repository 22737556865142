import { Agent } from '@malesia/json-schema';
import { createActionsColumn } from '@malesia/react-components/dist/src/components/Table/columns/actionsColumn';
import * as layouts from './actions';

export const agentActionsColumn = createActionsColumn<Agent, typeof layouts>({
    columnsNumber: 3,
    rowsNumber: 1,
    layouts,
    order: [
        'editAgent',
        'balanceLog',
        'reservationList',
    ],
});
export type AgentActionsConfig = Parameters<typeof agentActionsColumn>[0];
