import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { SelectedFlights } from '../../../utils/reservation/selectedFlight';
import { PassengerInfo } from '../../../utils/reservation/types';
import { ReservationSamePassengersState } from './types';

export const initialState: ReservationSamePassengersState = {
    isLoading: false,
    isOpen: false,
    samePassengers: [],
};

export type CheckPassengersActionType = {
    flights: SelectedFlights,
    passengers: PassengerInfo[],
};

const reservationSamePassengersSlice = createSlice({
    name: 'reservationSamePassengers',
    initialState,
    reducers: {
        checkSamePassengers(state, action: PayloadAction<CheckPassengersActionType>) {
            state.isLoading = true;
        },
        checkSamePassengersAsk(state, action: PayloadAction<PassengerInfo[]>) {
            state.isOpen = true;
            state.isLoading = false;
            state.samePassengers = action.payload;
        },
        checkSamePassengersCancel(state) {
            state.isOpen = false;
            state.samePassengers = [];
        },
        checkSamePassengersConfirm(state) {
            state.isOpen = false;
            state.samePassengers = [];
        },
        checkSamePassengersFail(state) {
            state.isOpen = false;
            state.samePassengers = [];
        },
    },
});

export const {
    actions: reservationSamePassengersActions,
    reducer: reservationSamePassengersReducer,
    name: reservationSamePassengersSliceKey,
} = reservationSamePassengersSlice;
