import { call, put, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../../utils/log';
import { getApiErrorMessage } from '../../../services/ApiClient';
import { getApiOptionList } from '../../../services/PassengerOption';
import { appNotification } from '../../App/appNotification';
import { auxiliaryActions } from '../slice';

function* getOptionsList(action: ReturnType<typeof auxiliaryActions.getOptionsList>) {
    try {
        const { rateId, tariffId } = action.payload;
        const list = yield* call(getApiOptionList, { rateId, tariffId });

        yield* put(auxiliaryActions.getOptionsListSuccess(list));
    }
    catch (error) {
        logError({
            error,
            target: 'AuxiliaryData.getOptionsList',
        });
        const message = getApiErrorMessage(error);
        yield* put(appNotification.unknownError(message));
        yield* put(auxiliaryActions.getOptionsListError());
    }
}

export function* getOptionsListSaga() {
    yield* takeLatest(auxiliaryActions.getOptionsList, getOptionsList);
}
