import { UserGroup, UserPutRequest } from '@malesia/json-schema';
import { push } from 'connected-react-router';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../../../utils/log';
import { allAdminRoutePaths } from '../../../../containers/Routes/allAdminRoutePaths';
import { userPermissionsPermissions } from '../../../../permissions/adminPages/settings/userPermissions';
import { RoleCode } from '../../../../permissions/roleCode';
import { selectUserPermissions } from '../../../../permissions/selectUserPermissions';
import { UserPermissions } from '../../../../permissions/userPermissions';
import { getApiErrorMessage } from '../../../../services/ApiClient';
import { assignApiVirtualAccounts, updateApiUser } from '../../../../services/user';
import { userPermissionsNotifications } from '../../notification';
import {
    selectActiveGroup,
    selectActiveGroupId,
    selectCurrentRoles,
    selectCurrentUser, selectCurrentVirtualAccounts,
} from '../selectors';
import { permissionsPageActions } from '../slice';

const createAllowedRoles = (userGroup: UserGroup, currentRoles: RoleCode[]): string[] => {
    const groupRoles = userGroup.roles ?? [];
    return currentRoles.filter(role => !groupRoles.includes(role));
};

const createDeniedRoles = (userGroup: UserGroup, currentRoles: RoleCode[]): string[] => {
    const groupRoles = userGroup.roles ?? [];
    return groupRoles.filter(role => !currentRoles.includes(role as RoleCode));
};

function* onSubmit() {
    try {
        const userPermissions: UserPermissions = yield* selectUserPermissions();

        const currentUser = (yield* select(selectCurrentUser))!;
        const currentRoles = yield* select(selectCurrentRoles);
        const activeGroupId = yield* select(selectActiveGroupId);
        const activeGroup = yield* select(selectActiveGroup);
        const currentVirtualAccounts = yield* select(selectCurrentVirtualAccounts);

        if (userPermissions.has(userPermissionsPermissions.updateUserRoles)) {
            const userGroup = activeGroup ? activeGroup : currentUser.group as UserGroup;
            const allowedSpecialRoles = createAllowedRoles(userGroup, currentRoles);
            const deniedSpecialRoles = createDeniedRoles(userGroup, currentRoles);

            const putUserObject: UserPutRequest = {
                id: currentUser.id,
                email: currentUser.email,
                group: { id: activeGroupId },
                allowedSpecialRoles,
                deniedSpecialRoles,
            };

            yield* call(updateApiUser, putUserObject);
        }
        if (userPermissions.has(userPermissionsPermissions.updateUserVirtualAccounts)) {
            yield* call(assignApiVirtualAccounts, currentUser.id!, currentVirtualAccounts);
        }
        yield* put(permissionsPageActions.onSubmitSuccess());
        yield* put(push(allAdminRoutePaths.settingsPermissions));
        yield* put(userPermissionsNotifications.updatedSuccessfully);
    }
    catch (error) {
        logError({
            error,
            target: 'SettingsUserPermissionsPage.onSubmit',
        });
        yield* put(permissionsPageActions.onSubmitError());
        const message = getApiErrorMessage(error);
        yield* put(userPermissionsNotifications.unknownError(message));
    }
}

export function* onSubmitSaga() {
    yield* takeLatest(permissionsPageActions.onSubmit, onSubmit);
}
