import { PublicFlightPaginatedList } from '@malesia/json-schema';
import {
    DATE_FORMAT,
    DATE_OUTPUT_FORMAT,
} from '@malesia/react-components/dist/src/utils/dateTimeFormat';
import moment from 'moment';
import { call, put, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../utils/log';
import { flightsWithPriceItemListHasAvailableSeats } from '../../containers/FlightOverview/utils';
import { apiClientRequest } from '../../services/ApiClient';
import { actions } from './slice';
import { createColumns } from './utils';

export function* loadFlights() {
    try {
        const today = moment.parseZone(new Date(), DATE_FORMAT);
        const nextWeek = today.clone().add(7, 'days');
        const queryParams = {
            departureFrom: today.format(DATE_OUTPUT_FORMAT),
            departureTo: nextWeek.format(DATE_OUTPUT_FORMAT),
            pageSize: 100,
        };

        const requestData = {
            requestId: 'publicFlightList',
            query: queryParams,
        };

        const result: PublicFlightPaginatedList = yield* call(apiClientRequest, requestData);
        const columns = createColumns(
            flightsWithPriceItemListHasAvailableSeats(result.items),
            today.clone());
        yield* put(actions.getFlightListSuccess(columns));
    }
    catch (error) {
        logError({
            error,
            target: 'MalesiaFlightsPage.loadFlights',
        });
    }
}

export function* getFlightListSaga() {
    yield* takeLatest(actions.getFlightList, loadFlights);
}
