import { FlightListItem, PriceListItem, PublicFlightListItem, RateTariffType, SeatsConfig } from '@malesia/json-schema';
import { DATETIME_FORMAT } from '@malesia/react-components/dist/src/utils/dateTimeFormat';
import { verifyTariff } from '@malesia/react-components/dist/src/utils/verify';
import moment from 'moment';
import {
    DEFAULT_RATE_TARIFF_TYPE,
    rateIdToTariffType,
} from '../../app/pages/BookingFlightPage/utils/StepFlight/rate';
import { UserPermissions } from '../../app/permissions/userPermissions';

export type SummaryFlightVariant = {
    flight: PublicFlightListItem & Partial<FlightListItem>,
    price: Pick<PriceListItem, 'tariff'>,
};
export type SummaryFlightVariants = {
    outbound?: SummaryFlightVariant,
    return?: SummaryFlightVariant,
};

export type SummaryFlight = {
    airportsTitle: string,
    flightSummary: string,
    rateTariffType: RateTariffType,
    availableSeats: number | undefined,
};
export const createSummaryFlight = (
    viewType: 'admin' | 'public',
    flightVariant: SummaryFlightVariant | undefined,
    locale: string,
    userPermissions: UserPermissions,
): SummaryFlight | undefined => {
    if (!flightVariant) return undefined;
    const { flight, price } = flightVariant;
    const { tariff } = price;
    const info = viewType === 'admin' ? flight : undefined;

    const origin = (flight.origin.name!)[locale] ?? (flight.origin.name!)['en'];
    const destination = (flight.destination.name!)[locale] ?? (flight.destination.name!)['en'];

    const airportsTitle = `${origin} - ${destination}`;

    const date = moment.parseZone(flight.departure).format(DATETIME_FORMAT);
    const flightSummary = `${flight.number} ${date}`;

    const tariffGroupId = verifyTariff(tariff)?.group?.id;
    const getNumberOfSeatsToSale = (tariffs?: SeatsConfig[]) => (
        tariffs?.find(tariff => tariff.tariffGroup?.id === tariffGroupId)?.seatsForSale ?? 0
    );
    const allSeatsForTariff = getNumberOfSeatsToSale(info?.seatsByTariff);
    const bookedSeatsForTariff = getNumberOfSeatsToSale(info?.bookedSeatsByTariff);
    const availableSeats = (allSeatsForTariff ?? 0) - (bookedSeatsForTariff ?? 0);
    const tariffId = tariff?.id;
    const rateTariffType = tariffId
        ? rateIdToTariffType[tariffId]
        : DEFAULT_RATE_TARIFF_TYPE;

    return {
        airportsTitle,
        flightSummary,
        rateTariffType,
        // #86430
        availableSeats: userPermissions.userType !== 'agent' ? availableSeats : undefined,
    };
};

export const createSummaryFlights = (
    viewType: 'admin' | 'public',
    flights: SummaryFlightVariants,
    locale: string,
    userPermissions: UserPermissions,
): SummaryFlight[] => {
    const result: SummaryFlight[] = [];
    const o = createSummaryFlight(viewType, flights.outbound, locale, userPermissions);
    if (o) result.push(o);
    const r = createSummaryFlight(viewType, flights.return, locale, userPermissions);
    if (r) result.push(r);
    return result;
};
