import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.rateNewPage || initialState;

export const selectTariffData = createSelector(
    [selectDomain],
    state => state.tariffData,
);

export const selectOptionData = createSelector(
    [selectDomain],
    state => state.optionData,
);

export const selectLoading = createSelector(
    [selectDomain],
    state => state.loading,
);

export const selectLoadingTariffs = createSelector(
    [selectDomain],
    state => state.loadingTariffs,
);

export const selectLoadingOptions = createSelector(
    [selectDomain],
    state => state.loadingOptions,
);
