import { Agent } from '@malesia/json-schema';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { AgentEditPageState, UpdateAgentOptions } from './types';

export const initialState: AgentEditPageState = {
    agentData: undefined,
    loadingAgent: false,
};

const agentEditPageSlice = createSlice({
    name: 'agentEditPage',
    initialState,
    reducers: {
        getAgentData(state, action: PayloadAction<string>) {
            state.loadingAgent = true;
        },
        getAgentDataSuccess(state, action: PayloadAction<Agent>) {
            state.agentData = action.payload;
            state.loadingAgent = false;
        },
        getAgentDataError(state) {
            state.loadingAgent = false;
        },
        updateAgentData(state, action: PayloadAction<UpdateAgentOptions>) {
            state.loadingAgent = true;
        },
        updateAgentDataSuccess(state) {
            state.loadingAgent = false;
        },
        updateAgentDataError(state) {
            state.loadingAgent = false;
        },
    },
});

export const { actions, reducer, name: sliceKey } = agentEditPageSlice;
