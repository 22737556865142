import {
    configureStore,
    getDefaultMiddleware,
    Middleware, StoreEnhancer,
} from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { createInjectorsEnhancer, forceReducerReload } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';
import { RootState } from '../types';
import { logError } from '../utils/log';
import { createReducer } from './reducers';

//__COMMIT_HASH__ is hash of current comment - see craco.config.js
// @ts-ignore
const commitHash = typeof __COMMIT_HASH__ === 'string' ? __COMMIT_HASH__ : '_hash_';

const createLocaleState = <T>(name: string, version: string) => {
    return {
        get: (): T | undefined => {
            const text = localStorage.getItem(name);
            if (text === null) return undefined;
            const store = JSON.parse(text);
            if (store.version !== version) return undefined;
            return store.state;
        },
        set: (state: T) => {
            const text = JSON.stringify({
                version,
                state,
            });
            localStorage.setItem(name, text);
        },
    };
};

const userLoginStore = createLocaleState<RootState['userLoginContainer']>('userLogin', '0.1');
type AppLocaleState = Pick<RootState, 'reservationBasket' | 'bookingFlightPage'>;
const appStore = createLocaleState<AppLocaleState>('app', commitHash);

export function configureAppStore(history?: History) {
    const reduxSagaMonitorOptions = {
        onError(err) {
            Sentry.captureException(err);
        },
    };
    const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
    const { run: runSaga } = sagaMiddleware;

    // Create the store with two middlewares
    // 1. sagaMiddleware: Makes redux-sagas work
    // 2. routerMiddleware: Syncs the location/URL path to the state
    const middlewares = [sagaMiddleware] as Middleware[];
    if (history) {
        middlewares.push(routerMiddleware(history));
    }

    const enhancers = [
        createInjectorsEnhancer({
            createReducer,
            runSaga,
        }),
    ] as StoreEnhancer<{}, {}>[];

    const loadStateFromLocalStorage = (): Partial<RootState> => {
        try {
            const userLoginContainer = userLoginStore.get();
            if (userLoginContainer) {
                // Wait refresh user info and logout if token is expired
                // This don't allow request any app data before refresh user info
                userLoginContainer.isLoadingUserData = true;
            }
            const appState = appStore.get() ?? {};
            return {
                ...appState,
                userLoginContainer,
            };
        }
        catch (error) {
            logError({
                error,
                target: 'configureStore.loadStateFromLocalStorage',
            });
            return {};
        }
    };

    const saveStateToLocalStorage = (state: RootState) => {
        try {
            const {
                userLoginContainer,
                reservationBasket,
                bookingFlightPage,
            } = state;

            const {
                isLoadingLogin,
                isLoadingUserData,
                ...fixedUserLoginContainer
            } = userLoginContainer;

            userLoginStore.set(fixedUserLoginContainer);
            appStore.set({
                reservationBasket,
                bookingFlightPage: !bookingFlightPage ? undefined : {
                    ...bookingFlightPage,
                    queryApplied: undefined,
                },
            });
        }
        catch (error) {
            logError({
                error,
                target: 'configureStore.saveStateToLocalStorage',
            });
        }
    };

    const store = configureStore({
        reducer: createReducer(),
        middleware: [...getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
            thunk: true,
        }), ...middlewares],
        devTools: process.env.NODE_ENV !== 'production',
        preloadedState: loadStateFromLocalStorage(),
        enhancers,
    });

    store.subscribe(() => {
        saveStateToLocalStorage(store.getState() as unknown as RootState);
    });

    // Make reducers hot reloadable, see http://mxs.is/googmo
    /* istanbul ignore next */
    if (module.hot) {
        module.hot.accept('./reducers', () => {
            forceReducerReload(store);
        });
    }

    return store;
}
