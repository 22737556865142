import { PageSubTitle } from '@malesia/react-components/dist/src/components/Page/Title/Sub';
import { AdminBillingInformationForm, AdminBillingFormData, AdminBillingInformationFormProps } from '@malesia/react-components/dist/src/components/Reservation/BillingInformation/AdminBillingInformation';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getValidationFunction } from '../../../../../utils/validation/getValidationFunction';
import { selectPublicCountryList } from '../../../../containers/SharedData/selectors';
import { sharedActions } from '../../../../containers/SharedData/slice';
import { reservationNewPermissions } from '../../../../permissions/adminPages/reservation/reservationNew';
import { useUserPermissions } from '../../../../permissions/useUserPermissions';
import { selectMode } from '../../store/selectors';
import { reservationNewPageActions } from '../../store/slice';
import { useConfig } from './config';
import { SelectUser } from './SelectUser';

const className = 'step-billing-information';

export type StepBillingInformationProps = {
    validateFormRef: React.MutableRefObject<() => boolean>,
};
export const StepBillingInformation: SFC<StepBillingInformationProps> = (props) => {
    const { validateFormRef } = props;

    const { locale } = useIntl();
    const userPermissions = useUserPermissions();
    const formConfig = useConfig();
    const mode = useSelector(selectMode);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(sharedActions.getPublicCountryList());
    }, [dispatch]);

    const formKey = useMemo(() => JSON.stringify(formConfig), [formConfig]);

    const validator = useMemo(
        () => getValidationFunction('file://malesiareisen.com/json/schema/project/react-front-app/pages/reservation/add/billingInformation.json'),
        [],
    );

    const selectUser = (
        (userPermissions.has(reservationNewPermissions.selectBillingUser) && mode === 'new')
            ? <SelectUser />
            : null
    );

    const header = (
        <>
            <PageSubTitle
                icon='address'
                text={
                    <FormattedMessage
                        id='front-app/Reservation/BillingInformation/Page:Title'
                        defaultMessage='Billing address'
                    />
                }
            />
            {selectUser}
        </>
    );

    const onChangeFormData = useCallback((formData: Partial<AdminBillingFormData>) => {
        dispatch(reservationNewPageActions.setBillingInformation(formData as AdminBillingFormData));
    }, [dispatch]);

    const countryList = useSelector(selectPublicCountryList);

    const countries = useMemo(() => countryList.items.map(country => ({
        name: country.name?.[locale] ?? '',
        value: country.code,
    })), [countryList, locale]);

    const adminBillingInformationFormProps: AdminBillingInformationFormProps = {
        onChangeFormData,
        formConfig,
        formValidator: validator,
        headerSlot: header,
        countryList: countries,
        validateFormRef,
    };
    return (
        <div className={className}>
            <AdminBillingInformationForm
                key={formKey}
                {...adminBillingInformationFormProps}
            />
        </div>
    );
};
