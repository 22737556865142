import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.flightPassengerListPage || initialState;

export const selectInitialized = createSelector(
    [selectDomain],
    state => state.initialized,
);

export const selectIsDirty = createSelector(
    [selectDomain],
    state => state.isDirty,
);

export const selectIsLoading = createSelector(
    [selectDomain],
    state => state.isLoading,
);

export const selectIsLoadingAppearingMap = createSelector(
    [selectDomain],
    state => state.isLoadingAppearingMap,
);

export const selectFlightListPage = createSelector(
    [selectDomain],
    flightListPageState => flightListPageState,
);

export const selectFlightId = createSelector(
    [selectDomain],
    state => state.flightId,
);

export const selectFlight = createSelector(
    [selectDomain],
    state => state.flight,
);

export const selectFlightPassengerList = createSelector(
    [selectDomain],
    state => state.flightPassengerList,
);

export const selectFlightBookingInfo = createSelector(
    [selectDomain],
    state => state.flightBookingInfo,
);

export const selectFlightPassengersInfo = createSelector(
    [selectDomain],
    state => state.flightPassengersInfo,
);

export const selectTariffGroupList = createSelector(
    [selectDomain],
    state => state.tariffGroupList,
);

export const selectFilterData = createSelector(
    [selectDomain],
    state => state.filterData,
);

export const selectAircraftTemplate = createSelector(
    [selectDomain],
    state => state.aircraftTemplate,
);

export const selectPnl = createSelector(
    [selectDomain],
    state => state.pnl,
);

export const selectIsShowCommentPopup = createSelector(
    [selectDomain],
    state => state.isShowCommentPopup,
);

export const selectComment = createSelector(
    [selectDomain],
    state => state.comment,
);

export const selectIsShowSeatPopup = createSelector(
    [selectDomain],
    state => state.isShowSeatPopup,
);

export const selectSeatPopupInfo = createSelector(
    [selectDomain],
    state => state.seatPopupInfo,
);

export const selectNotifying = createSelector(
    [selectDomain],
    state => state.notifying,
);

export const selectIsShowNotifyingPopup = createSelector(
    [selectDomain],
    state => state.isShowNotifyingPopup,
);

export const selectSelectedRows = createSelector(
    [selectDomain],
    state => state.selectedRows,
);

export const selectIsPNLDownloading = createSelector(
    [selectDomain],
    state => state.isPNLDownloading,
);

export const selectIsPAXDownloading = createSelector(
    [selectDomain],
    state => state.isPAXDownloading,
);
