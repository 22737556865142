import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.flightListPage || initialState;

export const selectFilterData = createSelector(
    [selectDomain],
    state => state.filterData,
);

export const selectList = createSelector(
    [selectDomain],
    state => state.table.list,
);

export const selectCancelledList = createSelector(
    [selectDomain],
    state => state.table.cancelledList,
);

export const selectSelectedRows = createSelector(
    [selectDomain],
    state => state.table.selectedRows,
);

export const selectPagination = createSelector(
    [selectDomain],
    state => state.table.pagination,
);

export const selectSorting = createSelector(
    [selectDomain],
    state => state.table.sorting,
);

export const selectInitialized = createSelector(
    [selectDomain],
    state => state.table.initialized,
);

export const selectLoading = createSelector(
    [selectDomain],
    state => state.table.loading,
);

export const selectDirty = createSelector(
    [selectDomain],
    state => state.table.dirty,
);

export const selectAirportList = createSelector(
    [selectDomain],
    state => state.airportList,
);

export const selectTariffGroupList = createSelector(
    [selectDomain],
    state => state.tariffGroupList,
);

export const selectInfoPopupDetails = createSelector(
    [selectDomain],
    state => state.flightInfo.details,
);

export const selectInfoPopupOpen = createSelector(
    [selectDomain],
    state => state.flightInfo.open,
);

export const selectInfoPopupLoading = createSelector(
    [selectDomain],
    state => state.flightInfo.loading,
);

export const selectDeleteFlightsOpen = createSelector(
    [selectDomain],
    state => state.deleteFlights.open,
);
