import { FlightCompany } from '@malesia/json-schema';
import { TableColumn } from '@malesia/react-components/dist/src/components/Table';
import { idColumn } from '@malesia/react-components/dist/src/components/Table/columns/idColumn';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { flightCompanyListPermissions } from '../../permissions/adminPages/flight/flightCompanyList';
import { UserPermissions } from '../../permissions/userPermissions';
import { useUserPermissions } from '../../permissions/useUserPermissions';
import { flightCompanyActionsColumn } from './actionsColumn';

type TableColumnsOptions = {
    history: ReturnType<typeof useHistory>,
    userPermissions: UserPermissions,
};
export const tableColumns = (
    { history, userPermissions }: TableColumnsOptions,
): TableColumn<FlightCompany>[] => ([
    idColumn(),
    {
        Header: (
            <FormattedMessage
                id='front-app/FlightCompanyListPage:ColumnName'
                defaultMessage='Name'
            />
        ),
        accessor: 'name',
    },
    flightCompanyActionsColumn({
        editFlightCompany: {
            click: (flightCompany) => {
                const path = generatePath(allAdminRoutePaths.flightCompanyEdit, {
                    flightCompanyId: flightCompany.id!,
                });
                history.push(path);
            },
            notAllowed: !userPermissions.has(flightCompanyListPermissions.editLink),
        },
    }),
]);

export const useTableColumns = (): TableColumn<FlightCompany>[] => {
    const history = useHistory();
    const userPermissions = useUserPermissions();

    const result = useMemo(() => tableColumns({
        history,
        userPermissions,
    }), [
        history,
        userPermissions,
    ]);

    return result;
};
