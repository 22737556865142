import { AllotmentInvoiceStatusType } from '@malesia/json-schema';
import { allotmentInvoiceColumns } from '@malesia/react-components/dist/src/components/Allotment/InvoiceList/tableColumns';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectAllotmentInvoicePdfLoadingMap } from '../../containers/AllotmentInvoicePdf/selectors';
import { allotmentInvoicePdfActions } from '../../containers/AllotmentInvoicePdf/slice';
import { allotmentInvoicePopupActions } from '../../containers/AllotmentInvoicePopup/slice';
import { backLinks } from '../../containers/Routes/backLinks';
import { allotmentInvoiceListPermissions } from '../../permissions/adminPages/allotment/allotmentInvoiceList';
import { useUserPermissions } from '../../permissions/useUserPermissions';
import { actions } from './store/slice';

const paidStatuses: AllotmentInvoiceStatusType[] = [
    'paid',
    'overpaid',
];

export const useTableColumns = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userPermissions = useUserPermissions();
    const allotmentInvoicePdfLoadingMap = useSelector(selectAllotmentInvoicePdfLoadingMap);

    const result = useMemo(() => allotmentInvoiceColumns({
        actions: {
            payInvoice: {
                click: (invoice) => {
                    const link = backLinks.allotmentPayment.forward(invoice?.id, {
                        source: 'allotmentInvoiceList',
                    });
                    history.push(link);
                },
                disabled: (invoice) => (
                    paidStatuses.includes(invoice.status)
                ),
                notAllowed: !userPermissions.has(allotmentInvoiceListPermissions.payment),
            },
            editInvoice: {
                click: (invoice) => {
                    dispatch(allotmentInvoicePopupActions.openEditPopup(invoice));
                },
                disabled: (invoice) => (
                    invoice.status === 'paid'
                ),
                notAllowed: !userPermissions.has(allotmentInvoiceListPermissions.edit),
            },
            deleteInvoice: {
                click: (invoice) => {
                    dispatch(actions.openDeleteInvoicePopup(invoice));
                },
                disabled: (invoice) => (
                    !!invoice.totalPaid
                ),
                notAllowed: !userPermissions.has(allotmentInvoiceListPermissions.remove),
            },
            invoicePdf: {
                click: (invoice, _, tableRef) => {
                    dispatch(allotmentInvoicePdfActions.downloadAllotmentInvoicePdf({
                        id: invoice.id!,
                        callback: () => tableRef.renderColumn('actions'),
                    }));
                    tableRef.renderColumn('actions');
                },
                isLoading: (invoice) => {
                    const id = invoice.id;
                    if (!id) return false;
                    return allotmentInvoicePdfLoadingMap[id];
                },
                notAllowed: !userPermissions.has(allotmentInvoiceListPermissions.pdf),
            },
        },
    }), [dispatch, history, userPermissions, allotmentInvoicePdfLoadingMap]);
    return result;
};
