import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FlightVariants } from '../../../../../utils/reservation/selectedFlight';
import { GetOptionsListOptions } from '../../../../containers/AuxiliaryData/types';

export const useStartFetchOptionsList = (
    selectedFlights: FlightVariants,
    getOptionsListAction: ActionCreatorWithPayload<GetOptionsListOptions, string>,
) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedFlights.outbound) {
            dispatch(getOptionsListAction({
                rateId: selectedFlights.outbound.flight.rate.id!,
                tariffId: selectedFlights.outbound.price.tariff!.id!,
            }));
        }
    }, [dispatch, getOptionsListAction, selectedFlights.outbound]);
};
