import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { allPublicRoutePaths } from '../../containers/Routes/allPublicRoutePaths';
import { AgentPaymentPage } from '../../pages/AgentPayment';
import { AllotmentRouterPage } from '../../pages/AllotmentRouterPage';
import { BookingFlightRouterPage } from '../../pages/BookingFlightRouterPage';
import { DenyPage } from '../../pages/DenyPage';
import { FlightRouterPage } from '../../pages/FlightRouterPage';
import { InvoiceRouterPage } from '../../pages/InvoiceRouterPage';
import { MalesiaAboutUsPage } from '../../pages/MalesiaAboutUsPage';
import { MalesiaBaggagePage } from '../../pages/MalesiaBaggagePage';
import { MalesiaBookingCarPage } from '../../pages/MalesiaBookingCarPage';
import { MalesiaBookingHotelPage } from '../../pages/MalesiaBookingHotelPage';
import { MalesiaContactKosovoPage } from '../../pages/MalesiaContactKosovoPage';
import { MalesiaContactMacedoniaPage } from '../../pages/MalesiaContactMacedoniaPage';
import { MalesiaContactPage } from '../../pages/MalesiaContactPage';
import { MalesiaDataProtectionPage } from '../../pages/MalesiaDataProtectionPage';
import { MalesiaDatatransErrorPage } from '../../pages/MalesiaDatatransErrorPage';
import { MalesiaDatatransSuccessPage } from '../../pages/MalesiaDatatransSucessPage';
import { MalesiaFlightsPage } from '../../pages/MalesiaFlightsPage';
import { MalesiaHealthPage } from '../../pages/MalesiaHealthPage';
import { MalesiaHistoryPage } from '../../pages/MalesiaHistoryPage';
import { MalesiaHomePage } from '../../pages/MalesiaHomePage';
import { MalesiaImprintPage } from '../../pages/MalesiaImprintPage';
import { MalesiaInfoPage } from '../../pages/MalesiaInfoPage';
import { MalesiaLocationPage } from '../../pages/MalesiaLocationPage';
import { MalesiaLoginPage } from '../../pages/MalesiaLoginPage';
import { MalesiaPartnersPage } from '../../pages/MalesiaPartnersPage';
import { MalesiaSafetyPage } from '../../pages/MalesiaSafetyPage';
import { MalesiaTermsConditionsPage } from '../../pages/MalesiaTermsConditionsPage';
import { MalesiaUpdatePasswordPage } from '../../pages/MalesiaUpdatePasswordPage';
import { NotFoundPage } from '../../pages/NotFoundPage';
import { RateRouterPage } from '../../pages/RateRouterPage';
import { PublicReservationListPage } from '../../pages/ReservationListPage';
import { ReservationRouterPage } from '../../pages/ReservationRouterPage';
import { SettingsRouterPage } from '../../pages/SettingsRouterPage';
import { PublicUserAccountPage } from '../../pages/UserAccountPage';
import { UserRouterPage } from '../../pages/UserRouterPage';
import { OnlyPublicRoute } from '../UserLogin/OnlyPublicRoute';
import { ProtectedRoute } from '../UserLogin/ProtectedRoute';

export function AppRoutes(): ReactElement {
    return (
        <Switch>
            {/* PUBLIC ROUTES */}
            <OnlyPublicRoute
                exact
                path={allPublicRoutePaths.aboutUs}
                component={MalesiaAboutUsPage}
            />
            <OnlyPublicRoute
                exact
                path={allPublicRoutePaths.baggage}
                component={MalesiaBaggagePage}
            />
            <OnlyPublicRoute
                exact
                path={allPublicRoutePaths.bookingCar}
                component={MalesiaBookingCarPage}
            />
            <OnlyPublicRoute
                exact
                path={allPublicRoutePaths.bookingHotel}
                component={MalesiaBookingHotelPage}
            />
            <OnlyPublicRoute
                exact
                path={allPublicRoutePaths.contact}
                component={MalesiaContactPage}
            />
            <OnlyPublicRoute
                exact
                path={allPublicRoutePaths.contactKosovo}
                component={MalesiaContactKosovoPage}
            />
            <OnlyPublicRoute
                exact
                path={allPublicRoutePaths.contactMacedonia}
                component={MalesiaContactMacedoniaPage}
            />
            <OnlyPublicRoute
                exact
                path={allPublicRoutePaths.accountReservations}
                component={PublicReservationListPage}
            />
            <OnlyPublicRoute
                exact
                path={allPublicRoutePaths.accountDetails}
                component={PublicUserAccountPage}
            />
            <OnlyPublicRoute
                exact
                path={allPublicRoutePaths.dataProtection}
                component={MalesiaDataProtectionPage}
            />
            <OnlyPublicRoute
                path={allPublicRoutePaths.bookingFlight}
                component={BookingFlightRouterPage}
            />
            <OnlyPublicRoute
                exact
                path={allPublicRoutePaths.flights}
                component={MalesiaFlightsPage}
            />
            <OnlyPublicRoute
                exact
                path={allPublicRoutePaths.health}
                component={MalesiaHealthPage}
            />
            <OnlyPublicRoute
                exact
                path={allPublicRoutePaths.history}
                component={MalesiaHistoryPage}
            />
            <OnlyPublicRoute
                exact
                path={allPublicRoutePaths.home}
                component={MalesiaHomePage}
            />
            <OnlyPublicRoute
                exact
                path={allPublicRoutePaths.imprint}
                component={MalesiaImprintPage}
            />
            <OnlyPublicRoute
                exact
                path={allPublicRoutePaths.information}
                component={MalesiaInfoPage}
            />
            <OnlyPublicRoute
                exact
                path={allPublicRoutePaths.location}
                component={MalesiaLocationPage}
            />
            <OnlyPublicRoute
                exact
                path={allPublicRoutePaths.partners}
                component={MalesiaPartnersPage}
            />
            <OnlyPublicRoute
                exact
                path={allPublicRoutePaths.termsConditions}
                component={MalesiaTermsConditionsPage}
            />
            <OnlyPublicRoute
                exact
                path={allPublicRoutePaths.safety}
                component={MalesiaSafetyPage}
            />
            <OnlyPublicRoute
                exact
                path={allPublicRoutePaths.datatransError}
                component={MalesiaDatatransErrorPage}
            />
            <OnlyPublicRoute
                exact
                path={allPublicRoutePaths.datatransCancel}
                component={MalesiaDatatransErrorPage}
            />
            <OnlyPublicRoute
                exact
                path={allPublicRoutePaths.datatransSuccess}
                component={MalesiaDatatransSuccessPage}
            />
            <OnlyPublicRoute
                exact
                path={allPublicRoutePaths.login}
                component={MalesiaLoginPage}
            />
            {/* ADMIN ROUTES */}
            <ProtectedRoute
                path={allAdminRoutePaths.flight}
                component={FlightRouterPage}
            />
            <ProtectedRoute
                path={allAdminRoutePaths.reservation}
                component={ReservationRouterPage}
            />
            <ProtectedRoute
                path={allAdminRoutePaths.user}
                component={UserRouterPage}
            />
            <ProtectedRoute
                path={allAdminRoutePaths.invoice}
                component={InvoiceRouterPage}
            />
            <ProtectedRoute
                path={allAdminRoutePaths.allotment}
                component={AllotmentRouterPage}
            />
            <ProtectedRoute
                path={allAdminRoutePaths.rate}
                component={RateRouterPage}
            />
            <ProtectedRoute
                path={allAdminRoutePaths.settings}
                component={SettingsRouterPage}
            />
            <ProtectedRoute
                path={allAdminRoutePaths.agentPayment}
                component={AgentPaymentPage}
            />
            {/* SYSTEM ROUTES */}
            <Route
                exact
                path={allAdminRoutePaths.updatePassword}
                component={MalesiaUpdatePasswordPage}
            />
            <Route
                path={allPublicRoutePaths.deny}
                component={DenyPage}
            />
            <Route
                path=''
                component={NotFoundPage}
            />
        </Switch>
    );
}
