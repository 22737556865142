import { BackLink } from '@malesia/react-components/dist/src/components/BackLink';
import { CustomerEdit, CustomerFormType } from '@malesia/react-components/dist/src/components/Customer/Edit';
import { LoadingPage } from '@malesia/react-components/dist/src/components/LoadingOverlay/Page';
import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { filterDisabledData } from '@malesia/react-components/dist/src/hooks/useForm';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { createCustomPasswordConfirmValidator } from '../../../utils/validation/createCustomPasswordConfirmValidator';
import { getValidationFunction } from '../../../utils/validation/getValidationFunction';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { useInjectSharedData } from '../../containers/SharedData/inject';
import { selectAdminCountryList, selectAdminCountryLoading } from '../../containers/SharedData/selectors';
import { sharedActions } from '../../containers/SharedData/slice';
import { useCustomerFormConfig } from './config';
import { getCustomerDataSaga, updateCustomerDataSaga } from './saga';
import { selectCustomerData, selectLoading } from './selectors';
import { actions, reducer, sliceKey } from './slice';

type RouteParams = {
    customerId: string,
};

type Props = RouteComponentProps<RouteParams>;

export const CustomerEditPage: SFC<Props> = (props) => {
    useInjectSharedData();
    useInjectReducer({ actions, key: sliceKey, reducer });
    useInjectSaga({
        key: `${sliceKey}GetCustomerData`,
        saga: getCustomerDataSaga,
    });
    useInjectSaga({
        key: `${sliceKey}UpdateCustomerData`,
        saga: updateCustomerDataSaga,
    });

    const customerData = useSelector(selectCustomerData);
    const countryData = useSelector(selectAdminCountryList);
    const loading = useSelector(selectLoading);
    const loadingCountries = useSelector(selectAdminCountryLoading);
    const isAnythingLoading = loading || loadingCountries;
    const dispatch = useDispatch();
    const { match } = props;
    const { params } = match;
    const { customerId } = params;

    useEffect(() => {
        if (!customerId) return;
        dispatch(actions.getCustomerData(customerId));
    }, [customerId, dispatch]);

    useEffect(() => {
        dispatch(sharedActions.getAdminCountryList());
    }, [dispatch]);

    const formConfig = useCustomerFormConfig(customerData);

    const onSubmitForm = useCallback((data: CustomerFormType) => {
        if (!customerId) return;
        dispatch(actions.updateCustomerData({
            customerId,
            customerData: filterDisabledData(data, formConfig),
        }));
    }, [formConfig, dispatch, customerId]);

    const validationFn = useMemo(() => (
        getValidationFunction(
            'file://malesiareisen.com/json/schema/project/react-front-app/pages/CustomerEditPage/customerEditForm.json',
            [
                createCustomPasswordConfirmValidator<CustomerFormType>('/password', '/passwordConfirm'),
            ],
        )
    ), []);

    return (
        <PageContentBlock>
            <PageTitle
                text={
                    <FormattedMessage
                        id='front-app/CustomerEditPage:Title'
                        defaultMessage='Edit customer'
                    />
                }
            />
            <BackLink
                to={allAdminRoutePaths.userCustomerList}
                modificators='stick-to-title'
            />
            <LoadingPage isLoading={isAnythingLoading} />
            {customerData && !isAnythingLoading && (
                <CustomerEdit
                    countryList={countryData.items}
                    formConfig={formConfig}
                    validator={validationFn}
                    onSubmit={onSubmitForm}
                />
            )}
        </PageContentBlock>
    );
};
