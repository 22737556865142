import {
    AircraftTemplate,
    Allotment,
    Flight,
    FlightPassengerEntireList, FlightPassengerListItem,
} from '@malesia/json-schema';
import { FlightFormData } from '@malesia/react-components/dist/src/components/Flight/Edit/Info';
import { addTmpId } from '@malesia/react-components/dist/src/utils/tmpId';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../../utils/@reduxjs/toolkit';
import { CreationFlightData } from '../../FlightNewPage/store/api';
import { FlightEditPageState, UpdateFlightDataOptions } from './types';

export type GetFlightPassengersType = {
    flightId: string | number,
};

export const initialState: FlightEditPageState = {
    flightData: undefined,
    allotments: [],
    oldFlightData: undefined,
    creationFlightData: {
        airportList: {
            items: [],
        },
        aircraftTemplateList: {
            items: [],
            total: 0,
        },
        companyList: {
            items: [],
        },
        rateList: {
            items: [],
        },
        tariffGroupList: {
            items: [],
        },
    },
    loading: true,
    isLoadingCreationFlightData: false,
    showNotifyingPopup: false,
    notifying: { isEmailNotifyEnabled: true },
    isLoadingAircraftData: false,
    isAircraftSeatsConfigured: false,
    showAircraftChangeConfirmationPopup: false,
    aircraftChangeConfirmationPassengers: [],
};

const flightEditPageSlice = createSlice({
    name: 'flightEditPage',
    initialState,
    reducers: {
        getCreationFlightData(state) {
            state.isLoadingCreationFlightData = true;
        },
        getCreationFlightDataSuccess(state, action: PayloadAction<CreationFlightData>) {
            state.creationFlightData = action.payload;
            state.isLoadingCreationFlightData = false;
        },
        getCreationFlightDataError(state) {
            state.isLoadingCreationFlightData = false;
        },
        getFlightData(
            state,
            action: PayloadAction<string>,
        ) {
            state.loading = true;
        },
        getFlightDataSuccess(state, action: PayloadAction<Flight>) {
            const flight = action.payload;
            state.oldFlightData = flight;

            const parts = flight.number?.match(/^(\S+)\s(\d+)$/);
            const number = parts ? parts[2] : undefined;
            const numberPrefix = parts ? parts[1] : undefined;

            state.flightData = {
                ...flight,
                number,
                numberPrefix,
            } as FlightFormData;
            const allotments = flight.allotments ?? [];
            state.allotments = allotments.map(addTmpId);
            state.loading = false;
        },
        getFlightDataError(state) {
            state.loading = false;
        },
        setFlightData(state, action: PayloadAction<Partial<FlightFormData>>) {
            state.flightData = action.payload;
            state.flightData.numberPrefix = action.payload.flightCompany ? action.payload.flightCompany.code : 'XX';
        },
        setFlightAircraftTemplate(state, action: PayloadAction<AircraftTemplate>) {
            state.flightData = {
                ...state.flightData,
                aircraftTemplate: action.payload as FlightFormData['aircraftTemplate'],
            };
        },
        setAllotmentData(state, action: PayloadAction<Allotment[]>) {
            state.allotments = action.payload.map(addTmpId);
        },
        requestUpdateFlightData(state, action: PayloadAction<string>) {
            state.loading = true;
        },
        updateFlightData(state, action: PayloadAction<UpdateFlightDataOptions>) {
            state.loading = true;
        },
        updateFlightDataSuccess(state) {
            state.loading = false;
        },
        updateFlightDataError(state) {
            state.loading = false;
        },
        askNotifying(state) {
            state.showNotifyingPopup = true;
        },
        askNotifyingConfirm(state) {
            state.showNotifyingPopup = false;
        },
        askNotifyingCancel(state) {
            state.showNotifyingPopup = false;
        },
        updateNotifying(state, action) {
            state.notifying = action.payload;
        },
        getFlightPassengers(
            state,
            action: PayloadAction<GetFlightPassengersType>,
        ) {},
        getFlightPassengersSuccess(
            state,
            action: PayloadAction<FlightPassengerEntireList>,
        ) {},
        getFlightPassengersError(state) {},
        clickSettings(state) {},
        setIsAircraftSeatsConfigured(state, action: PayloadAction<boolean>) {
            state.isAircraftSeatsConfigured = action.payload;
        },
        setAircraftChangeConfirmationPassengers(state, action: PayloadAction<FlightPassengerListItem[]>) {
            state.aircraftChangeConfirmationPassengers = action.payload;
        },
        aksAircraftChangeConfirmation(state) {
            state.showAircraftChangeConfirmationPopup = true;
        },
        aksAircraftChangeConfirmationConfirm(state) {
            state.showAircraftChangeConfirmationPopup = false;
        },
        aksAircraftChangeConfirmationCancel(state) {
            state.showAircraftChangeConfirmationPopup = false;
        },
    },
});

export const { actions, reducer, name: sliceKey } = flightEditPageSlice;
