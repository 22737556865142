import { getAdminRoutePermission } from '../../adminRoutes';
import { permissionMaker } from '../../permissionExpression';
import { balanceLogPermissions } from '../balance/balanceLog';

const { every } = permissionMaker;

const editManagerLink = getAdminRoutePermission((x) => x.userManagerEdit);
const balanceLogLink = every(
    getAdminRoutePermission(x => x.balanceLog),
    balanceLogPermissions.accountFilter,
);
const newManagerLink = getAdminRoutePermission((x) => x.userManagerNew);

export const managerListPermissions = {
    editManagerLink,
    balanceLogLink,
    newManagerLink,
};
