import { AgentPaginatedList } from '@malesia/json-schema';
import { SortingDataType } from '@malesia/react-components/dist/src/components/Table';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { AgentFilterData, AgentListPageState } from './types';

export const initialState: AgentListPageState = {
    initialized: {
        sorting: false,
    },
    isDirty: true,
    isLoading: true,

    agentList: {
        items: [],
        total: 0,
    },
    filterData: {
        searchText: undefined,
        isActiveOnly: true,
        sortBy: 'displayName',
        sortOrder: 'ASC',
    },
    pagination: {
        page: 0,
        pageSize: 15,
    },
};

const agentListPageSlice = createSlice({
    name: 'agentListPage',
    initialState,
    reducers: {
        updateSearch(state, action: PayloadAction<AgentFilterData>) {
            state.filterData = { ...state.filterData, ...action.payload };
            state.isDirty = true;
            state.pagination.page = initialState.pagination.page;
        },
        setPage(state, action: PayloadAction<number>) {
            state.pagination.page = action.payload;
            state.isDirty = true;
        },
        setPageSize(state, action: PayloadAction<number>) {
            state.pagination.pageSize = action.payload;
            state.pagination.page = initialState.pagination.page;
            state.isDirty = true;
        },
        setSorting(state, action: PayloadAction<SortingDataType>) {
            const data = action.payload;
            state.filterData = {
                ...state.filterData,
                sortBy: data.sortBy,
                sortOrder: data.sortOrder,
            };
            state.isDirty = true;
            state.initialized.sorting = true;
        },
        getAgentList(state) {
            state.isLoading = true;
        },
        getAgentListSuccess(state, action: PayloadAction<AgentPaginatedList>) {
            state.agentList = action.payload;
            state.isLoading = false;
            state.isDirty = false;
        },
        getAgentListError(state) {
            state.isLoading = false;
        },
    },
});

export const { actions, reducer, name: sliceKey } = agentListPageSlice;
