import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { datatransErrorPageActions } from '../MalesiaDatatransErrorPage/store/datatransError.slice';
import { useDatatransStore } from '../MalesiaDatatransErrorPage/store/useDatatransStore';

export type MalesiaDatatransErrorPageProps = {
};

export function MalesiaDatatransSuccessPage() {
    useDatatransStore();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(datatransErrorPageActions.updateTransactionStatus());
    }, [dispatch]);
    return (
        <div className='malesia-datatrans-success-page'>
            <h2>thank you for payment</h2>
            <Link to='/'> go to home page</Link>
        </div>
    );
}
