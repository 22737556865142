import { Flight } from '@malesia/json-schema';
import { AircraftSeatsPlanSection } from '@malesia/react-components/dist/src/components/AircraftSeatsPlan';
import { mapTariffGroupIdToCode } from '@malesia/react-components/dist/src/components/TariffsTable/tariffGroupMapper';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.flightEditPage || initialState;

export const selectCreationFlightData = createSelector(
    [selectDomain],
    state => state.creationFlightData,
);

export const selectFlightAircraftTemplate = createSelector(
    [selectDomain],
    state => state.flightData?.aircraftTemplate,
);

export const selectIsLoadingCreationFlightData = createSelector(
    [selectDomain],
    state => state.isLoadingCreationFlightData,
);

export const selectFlightData = createSelector(
    [selectDomain],
    (state): Flight => {
        const { numberPrefix, number, ...flightData } = state.flightData!;

        return { ...flightData, number: `${numberPrefix} ${number}` } as Flight;
    },
);

export const selectOldFlightData = createSelector(
    [selectDomain],
    state => state.oldFlightData,
);

export const selectFlightFormData = createSelector(
    [selectDomain],
    state => state.flightData,
);

export const selectAllotments = createSelector(
    [selectDomain],
    state => state.allotments,
);

export const selectLoading = createSelector(
    [selectDomain],
    state => state.loading,
);

export const selectShowNotifyingPopup = createSelector(
    [selectDomain],
    state => state.showNotifyingPopup,
);

export const selectNotifying = createSelector(
    [selectDomain],
    state => state.notifying,
);

export const selectAircraftSeatsPlanSections = createSelector(
    [selectDomain],
    state => state.flightData?.aircraftTemplate?.sections?.map((section) => {
        return {
            ...section,
            tariffGroupCode: mapTariffGroupIdToCode(section.tariffGroup.id),
            rowsWithExitDoors: section.rowsWithExitDoors ?? [],
        } as AircraftSeatsPlanSection;
    }),
);

export const selectIsLoadingAircraftData = createSelector(
    [selectDomain],
    state => state.isLoadingAircraftData,
);
export const selectShowSettingButton = createSelector(
    [selectAircraftSeatsPlanSections],
    (sections) => sections && sections.length > 0,
);

export const selectShowAircraftChangeConfirmationPopup = createSelector(
    [selectDomain],
    (state) => state.showAircraftChangeConfirmationPopup,
);

export const selectAircraftChangeConfirmationPassengers = createSelector(
    [selectDomain],
    (state) => state.aircraftChangeConfirmationPassengers,
);

export const selectIsAircraftSeatsConfigured = createSelector(
    [selectDomain],
    (state) => state.isAircraftSeatsConfigured,
);
