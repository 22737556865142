import { FlightCompany } from '@malesia/json-schema';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { GetFlightCompanyDataOptions, FlightCompanyEditPageState, UpdateFlightCompanyDataOptions } from './types';

export const initialState: FlightCompanyEditPageState = {
    flightCompanyData: undefined,
    loading: false,
};

const flightCompanyEditPageSlice = createSlice({
    name: 'flightCompanyEditPage',
    initialState,
    reducers: {
        getFlightCompanyData(state, action: PayloadAction<GetFlightCompanyDataOptions>) {
            state.loading = true;
        },
        getFlightCompanyDataSuccess(state, action: PayloadAction<FlightCompany>) {
            state.flightCompanyData = action.payload;
            state.loading = false;
        },
        getFlightCompanyDataError(state) {
            state.loading = false;
        },
        updateFlightCompanyData(state, action: PayloadAction<UpdateFlightCompanyDataOptions>) {
            state.loading = true;
        },
        updateFlightCompanyDataSuccess(state, action: PayloadAction<FlightCompany>) {
            state.flightCompanyData = action.payload;
            state.loading = false;
        },
        updateFlightCompanyDataError(state) {
            state.loading = false;
        },
    },
});

export const { actions, reducer, name: sliceKey } = flightCompanyEditPageSlice;
