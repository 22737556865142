import * as queryString from 'querystring';
import { ErrorObject } from 'ajv';
import { flatten } from 'q-flat';
import { logError, logWarning } from '../../utils/log';
import ajv from './ajvLoader';
import { ApiResponseError } from './ApiResponseError';
import { apiConfig } from './config';
import JsonSchemaValidationError from './JsonSchemaValidationError';
import { ApiRequestMapType, ProceedRequestType } from 'api/malesia/types';
import { apiMalesiaFullUri } from 'config/appConfig';

export type ApiErrorType = {
    code: number,
    message: string,
};

/**
 * Make replacement for the parameterised uri
 * @param  {string} uri template uri
 * @param  {object} uriParams params data
 *
 * @return {string}          String with replacement
 */
export const fillUriWithParams = (uri: string, uriParams: {}): string => {
    let filledUri = uri;
    Object.keys(uriParams).forEach(paramName => {
        filledUri = filledUri.replace(`{${paramName}}`, uriParams[paramName]);
    });
    return filledUri;
};

/**
 * Parses the JSON returned by a network request
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
export const parseJSON = (response: Response) => {
    if (response.status === 204 || response.status === 205) {
        return null;
    }
    return response.json();
};

/**
 * Validate data against schema
 *
 * @param { object | null } schema  Schema to test data
 * @param data  Data
 *
 * @return boolean | PromiseLike<any>;
 */
const validateSchema = (
    schema: string | null,
    data: any,
): boolean | PromiseLike<any> => {
    if (!schema) return true;
    return ajv.validate(schema, data);
};

/**
 * Return validation errors
 *
 * @param { object | null } schema  Schema to test data
 * @param data  Data
 *
 */
export const getValidationErrors = (
    schema: object | string | null,
    data: any,
): ErrorObject[] | null | undefined => {
    if (!schema) return [];
    const validate = typeof schema === 'string' ? ajv.getSchema(schema) : ajv.compile(schema);
    validate && validate(data);
    return validate && validate.errors;
};

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
const checkStatus = async (response: Response, request: unknown) => {
    if (response && response.status >= 200 && response.status < 300) {
        return response;
    }

    const error = new ApiResponseError(response, request);
    error.response = await response.json();
    throw error;
};

export type ProceedRequestForErrorType = {
    err: JsonSchemaValidationError | ApiResponseError,
};

/**
 * Requests a URL, returning a promise
 *
 * @return {object}           The response data
 * @param data
 */
export const proceedRequestFor = async <RESPONSE>(
    data: ProceedRequestType<ApiRequestMapType>,
): Promise<RESPONSE | Response | ProceedRequestForErrorType> => {
    const { query, requestId, uriParams, requestPayload, authToken, signal } = data;

    const methodConfig = apiConfig[requestId];

    // Check that request schema configured and exists
    const requestSchemaRefId = methodConfig.schema.request;

    if (requestSchemaRefId && !ajv.getSchema(requestSchemaRefId)) {
        throw new JsonSchemaValidationError(
            `REQUEST schema is missing '${requestId}'`,
        );
    }

    // Validate request payload with schema
    const isRequestValid = requestSchemaRefId
        ? validateSchema(requestSchemaRefId, requestPayload)
        : true;

    if (!isRequestValid) {
        if (process.env.NODE_ENV !== 'production') {
            console.error(getValidationErrors(requestSchemaRefId, requestPayload));
        }
        throw new JsonSchemaValidationError(
            `REQUEST schema validation error for the '${requestId}'`,
        );
    }

    // Build request uri
    const updatedUri = uriParams
        ? fillUriWithParams(methodConfig.pathUri, uriParams)
        : methodConfig.pathUri;

    const serializedQuery = methodConfig.method === 'GET' && requestPayload
        ? queryString.stringify(flatten(requestPayload))
        : query;
    const urlQuery = serializedQuery ? `?${serializedQuery}` : '';
    const url = apiMalesiaFullUri + updatedUri + urlQuery;
    const body = methodConfig.method === 'GET' ? undefined : JSON.stringify(requestPayload);

    // Build request options
    // eslint-disable-next-line no-undef
    const optionsCombined: RequestInit = {
        method: methodConfig.method,
        headers: {
            ...(body ? { 'Content-Type': 'application/json;charset=UTF-8' } : {}),
            ...(authToken ? { 'X-AUTH-TOKEN': authToken } : {}),
        },
        signal,
        ...(body ? { body } : {}),
    };

    // Fetch result
    let fetchResponse;
    try {
        fetchResponse = await fetch(url, optionsCombined);
    }
    catch (error) {
        const isStandardError = (e: unknown): e is TypeError => (
            typeof e === 'object' && !!e && 'name' in e && 'message' in e
        );
        const isCancelled = () => {
            return isStandardError(error) && error.name === 'AbortError';
        };
        /** Broken http connection */
        const isFailedToFetch = () => {
            return isStandardError(error) && error.message === 'Failed to fetch';
        };
        if (isCancelled()) {
            logWarning({
                error,
                target: 'App.proceedRequestFor.isCancelled',
            });
        }
        else if (isFailedToFetch()) {
            logWarning({
                error,
                target: 'App.proceedRequestFor.isFailedToFetch',
            });
        }
        else {
            logError({
                error,
                target: 'App.proceedRequestFor.notCancelled',
            });
        }
        throw new ApiResponseError(new Response('Response is `undefined`'), requestPayload);
    }

    const response = await checkStatus(fetchResponse, requestPayload);

    const isFileContent = () => {
        const arr = [
            'application/pdf',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ];
        const contentType = response.headers.get('Content-Type');
        return arr.some(x => x === contentType);
    };
    if (isFileContent()) {
        return response;
    }

    const responseJson = await parseJSON(response);

    // Check that response schema configured and exists
    const responseSchemaRefId = methodConfig.schema.response;
    if (responseSchemaRefId && !ajv.getSchema(responseSchemaRefId)) {
        throw new JsonSchemaValidationError(
            `RESPONSE schema is missing '${requestId}'`,
        );
    }

    // Validate response data using schema
    const isResponseValid = validateSchema(responseSchemaRefId, responseJson);
    if (!isResponseValid) {
        if (process.env.NODE_ENV !== 'production') {
            console.error(getValidationErrors(responseSchemaRefId, responseJson));
        }
        throw new JsonSchemaValidationError(
            `RESPONSE schema validation error for the '${requestId}' with schema '${responseSchemaRefId}'`,
        );
    }

    return responseJson;
};
