import { createActionLayout } from '@malesia/react-components/dist/src/components/Table/columns/actionsColumn/actionLayout';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const balanceLog = createActionLayout({
    text: (
        <FormattedMessage
            id='front-app/AgentListPage:balanceLog'
            defaultMessage='Balance Log'
        />
    ),
    iconName: 'bill',
});
