import { NotificationType, NotificationVariant } from '@malesia/react-components/dist/src/components/Page/Notifications/Notifications';
import { Entry, filterObject, mapObject } from '@malesia/react-components/dist/src/utils/object';
import { PayloadAction } from '@reduxjs/toolkit';
import { appActions } from './slice';

export const notify = (
    id: string,
    variant: NotificationVariant,
    title: string | JSX.Element,
    text: string | JSX.Element,
) => appActions.addNotification({
    id,
    timeout: 5000,
    variant,
    position: 'top-left',
    data: {
        title,
        text,
    },
});

type Notify = (text: string | JSX.Element) => PayloadAction<NotificationType>;
type Titles = Record<NotificationVariant, string | JSX.Element>;
export type LocalNotification<T extends Partial<Titles>> = {
    [Key in keyof T]: T[Key] extends undefined ? undefined : Notify
};
export const createLocalNotification = <T extends Partial<Titles>>(
    id: string,
    titles: T,
): LocalNotification<T> => {
    const filtered: Titles = filterObject(titles as Titles, (entry): entry is Entry<Titles> => !!entry[1]);
    const result = mapObject<Record<NotificationVariant, Notify>, Titles>(filtered, ([variant, title]): Notify => (
        (text) => notify(`${id}.${variant}`, variant, title, text)
    ));
    return result as LocalNotification<T>;
};
