import { put, takeLatest, throttle } from 'typed-redux-saga';
import { logError } from '../../../../utils/log';
import { GetAgentListParams, getApiAgentList } from '../../../services/agent';
import { getApiErrorMessage } from '../../../services/ApiClient';
import { appNotification } from '../../App/appNotification';
import { auxiliaryActions } from '../slice';

export function* getAgentList(action: ReturnType<typeof auxiliaryActions.getAgentList>) {
    try {
        const query = action.payload;

        const params: GetAgentListParams = {
            query,
            isActive: true,
            sortBy: 'displayName',
            sortOrder: 'ASC',
            page: 0,
            pageSize: 10,
        };
        const result = yield* getApiAgentList(params);
        yield* put(auxiliaryActions.getAgentListSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'AuxiliaryData.searchAgentList',
        });
        yield* put(auxiliaryActions.getAgentListError());
        const message = getApiErrorMessage(error);
        yield* put(appNotification.unknownError(message));
    }
}

export function* getAgentListSaga() {
    yield* takeLatest(auxiliaryActions.getAgentList, getAgentList);

    function* request(action: ReturnType<typeof auxiliaryActions.searchAgentList>) {
        yield* put(auxiliaryActions.getAgentList(action.payload));
    }
    yield* throttle(1000, auxiliaryActions.searchAgentList, request);
}
