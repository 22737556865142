import { getTopImage } from '../getTopImage';
import { HotelsPageDocument, HotelsPageQuery } from '../gql-types';
import { gqlClient } from '../gqlClient';

export async function loadHotelsPageContent(locale: string) {
    const { data } = await gqlClient.query<HotelsPageQuery>(
        HotelsPageDocument,
        { lang: locale },
    ).toPromise();
    return {
        topImage: getTopImage(data?.hotels_page?.top_image),
        content: data?.hotels_page?.translations?.[0]?.content ?? '',
    };
}
