import { Customer } from '@malesia/json-schema';
import { CustomerFormType } from '@malesia/react-components/dist/src/components/Customer/Edit';
import { LegacyFormConfig, readonlyFormConfig } from '@malesia/react-components/dist/src/hooks/useForm';
import { mapObject } from '@malesia/react-components/dist/src/utils/object';
import { useMemo } from 'react';
import { customerEditPermissions } from '../../permissions/adminPages/user/customerEdit';
import { UserPermissions } from '../../permissions/userPermissions';
import { useUserPermissions } from '../../permissions/useUserPermissions';

type Config = LegacyFormConfig<CustomerFormType>;
const createConfig = (
    customerData: Customer | undefined,
): Config => ({
    id: { initialValue: customerData?.id },
    email: { initialValue: customerData?.email ?? '', modifiers: ['required'] },
    password: { initialValue: '' },
    passwordConfirm: { initialValue: '' },
    salutation: { initialValue: customerData?.salutation, modifiers: ['required'] },
    company: { initialValue: customerData?.company },
    firstName: { initialValue: customerData?.firstName ?? '', modifiers: ['required'] },
    lastName: { initialValue: customerData?.lastName ?? '', modifiers: ['required'] },
    street: { initialValue: customerData?.street, modifiers: ['required'] },
    zip: { initialValue: customerData?.zip, modifiers: ['required'] },
    city: { initialValue: customerData?.city, modifiers: ['required'] },
    country: { initialValue: customerData?.country, modifiers: ['required'] },
    language: { initialValue: customerData?.language, modifiers: ['required'] },
    birthday: { initialValue: customerData?.birthday ?? '', modifiers: ['required'] },
    phoneMobile: { initialValue: customerData?.phoneMobile, modifiers: ['required'] },
    phoneNumberFirst: { initialValue: customerData?.phoneNumberFirst },
    phoneNumberSecond: { initialValue: customerData?.phoneNumberSecond },
    isActive: { initialValue: customerData?.isActive },
});

const configurePermissions = (
    config: Config,
    userPermissions: UserPermissions,
): Config => {
    const updateForm = userPermissions.has(customerEditPermissions.updateForm);
    const updateIsActive = userPermissions.has(customerEditPermissions.updateIsActive);

    const hiddenMap: Partial<Record<keyof Config, boolean>> = {
        isActive: !updateIsActive,
    };

    let result = config;
    if (!updateForm) {
        result = readonlyFormConfig(result);
    }
    result = mapObject(result, ([key, value]) => ({
        ...value,
        hidden: hiddenMap[key] || value.hidden,
    }));
    return result;
};

export const useCustomerFormConfig = (
    customerData: Customer | undefined,
): Config => {
    const userPermissions = useUserPermissions();
    const result = useMemo<Config>(() => {
        const config = createConfig(customerData);
        return configurePermissions(config, userPermissions);
    }, [customerData, userPermissions]);
    return result;
};
