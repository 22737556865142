import { FlightList } from '@malesia/react-components/dist/src/components/Flight/List';
import { LoadingTable } from '@malesia/react-components/dist/src/components/LoadingOverlay/Table';
import { SortingDataType } from '@malesia/react-components/dist/src/components/Table';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParameters } from '../queryParameters';
import {
    selectList,
    selectCancelledList,
    selectTariffGroupList,
    selectLoading,
    selectDirty,
    selectSorting,
} from '../store/selectors';
import { actions } from '../store/slice';
import { useTableColumns } from '../tableColumns';

export const FlightListWithStore: SFC = () => {
    const isLoading = useSelector(selectLoading);
    const isDirty = useSelector(selectDirty);
    const flightList = useSelector(selectList);
    const cancelledFlightList = useSelector(selectCancelledList);
    const tariffGroupList = useSelector(selectTariffGroupList);
    const sorting = useSelector(selectSorting);
    const { highlightFlightId, highlightSequenceFlightId } = useQueryParameters();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.getTariffGroupList());
    }, [dispatch]);

    const handleSetSort = useCallback((data: SortingDataType) => {
        dispatch(actions.setSorting(data));
    }, [dispatch]);

    const handleSetSelectedRows = useCallback((data) => {
        dispatch(actions.setSelectedRows(data));
    }, [dispatch]);

    const flightListColumns = useTableColumns({
        tariffGroupList: tariffGroupList.items,
    });

    const cancelledFlightListItemsIds = useMemo(
        () => cancelledFlightList.items.map(flight => flight.id),
        [cancelledFlightList],
    );
    const filteredFlightListItems = useMemo(
        () => flightList.items.filter(flight => !cancelledFlightListItemsIds.includes(flight.id)),
        [flightList, cancelledFlightListItemsIds],
    );

    const flightsItems = useMemo(() => [
        ...cancelledFlightList.items,
        ...filteredFlightListItems,
    ], [cancelledFlightList, filteredFlightListItems]);

    return (
        <LoadingTable
            isLoading={isLoading}
            isDirty={isDirty}
        >
            <FlightList
                tableColumns={flightListColumns}
                tableData={flightsItems}
                sortBy={sorting.sortBy}
                sortOrder={sorting.sortOrder}
                setSort={handleSetSort}
                setSelectedRows={handleSetSelectedRows}
                highlightFlightId={highlightFlightId}
                highlightSequenceFlightId={highlightSequenceFlightId}
            />
        </LoadingTable>
    );
};
