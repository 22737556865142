import { getAdminRoutePermission } from '../../adminRoutes';
import { permissionMaker } from '../../permissionExpression';
import { balanceLogPermissions } from '../balance/balanceLog';
import { reservationListPermissions } from '../reservation/reservationList';

const { every } = permissionMaker;

const newAgentLink = getAdminRoutePermission((x) => x.userAgentNew);
const editAgentLink = getAdminRoutePermission((x) => x.userAgentEdit);
const balanceLogLink = every(
    getAdminRoutePermission(x => x.balanceLog),
    balanceLogPermissions.accountFilter,
);
const reservationsByReservatorLink = every(
    getAdminRoutePermission((x) => x.reservationList),
    reservationListPermissions.reservatorFilter,
);
export const agentListPermissions = {
    newAgentLink,
    editAgentLink,
    balanceLogLink,
    reservationsByReservatorLink,
};
