import { FlightStatus } from '@malesia/json-schema';
import { FlightDirection } from '@malesia/react-components/dist/src/components/Reservation/reservation-types';
import moment from 'moment';

export type FlightWithStatus = {
    status?: FlightStatus,
    departure?: string,
};
export const isClosedFlight = (flight: FlightWithStatus): boolean => {
    return flight.status === 'closed';
};
export const isDepartedFlight = (flight: FlightWithStatus): boolean => {
    return flight.status === 'departed';
};
export const isDepartIn48HoursFlight = (flight: FlightWithStatus): boolean => {
    if (isClosedFlight(flight)) return true;
    if (isDepartedFlight(flight)) return true;
    const today = moment();
    const departure = moment(flight.departure);
    const withTwoDays = today.add(48, 'hours');
    return withTwoDays.isSameOrAfter(departure);
};
export const isCancelledFlight = (flight: FlightWithStatus): boolean => {
    return flight.status === 'cancelled';
};

export type FlightsStatusTarget = FlightDirection | 'any' | 'both' | 'last';
export type FlightsStatus = {
    closed: (target: FlightsStatusTarget) => boolean,
    departed: (target: FlightsStatusTarget) => boolean,
    departIn48Hours: (target: FlightsStatusTarget) => boolean,
    cancelled: (target: FlightsStatusTarget) => boolean,
};
export const createFlightsStatus = (
    outboundFlight: FlightWithStatus | undefined,
    returnFlight: FlightWithStatus | undefined,
    isReturnRequired?: boolean,
): FlightsStatus => {
    const check = (target: FlightsStatusTarget, predicate: (flight: FlightWithStatus) => boolean) => {
        const isOutbound = !!outboundFlight && predicate(outboundFlight);
        const isReturn = !!returnFlight && predicate(returnFlight);
        const skipReturn = !isReturnRequired && !returnFlight;
        const map: Record<FlightsStatusTarget, boolean> = {
            any: isOutbound || isReturn,
            both: isOutbound && (isReturn || skipReturn),
            outbound: isOutbound,
            ['return']: isReturn,
            last: skipReturn ? isOutbound : isReturn,
        };
        return map[target];
    };
    return {
        closed: (target) => check(target, isClosedFlight),
        departed: (target) => check(target, isDepartedFlight),
        departIn48Hours: (target) => check(target, isDepartIn48HoursFlight),
        cancelled: (target) => check(target, isCancelledFlight),
    };
};
