import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../utils/log';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { apiClientRequest, getApiErrorMessage } from '../../services/ApiClient';
import { mapManagerToPostRequest } from '../../services/Manager/manager.service';
import { managerNotifications } from './notification';
import { actions } from './slice';

function* createManager(action: ReturnType<typeof actions.createManager>) {
    try {
        const requestPayload = mapManagerToPostRequest(action.payload);

        yield* call(apiClientRequest, {
            requestId: 'managerCreate',
            requestPayload,
        });
        yield* put(actions.createManagerSuccess());
        yield* put(push(allAdminRoutePaths.userManagerList));
        yield* put(managerNotifications.createdSuccessfully);
    }
    catch (error) {
        logError({
            error,
            target: 'ManagerNewPage.createManager',
        });
        yield* put(actions.createManagerError());
        const message = getApiErrorMessage(error);
        yield* put(managerNotifications.unknownError(message));
    }
}

export function* createManagerSaga() {
    yield* takeLatest(actions.createManager, createManager);
}
