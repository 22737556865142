import { ApiCallUserListFilters } from '@malesia/json-schema';
import { getApiUserList } from './user';

export function* getApiOwnerList(options: ApiCallUserListFilters) {
    const {
        query,
        userId,
    } = options;

    const filters: ApiCallUserListFilters = {
        page: 0,
        pageSize: 10,
        isActive: true,
        query,
        userId,
        type: ['customer', 'agent'],
    };

    const result = yield* getApiUserList(filters);
    return result;
}
