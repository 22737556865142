import { put, select, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../utils/log';
import { createApiAllotmentInvoice, updateApiAllotmentInvoice } from '../../services/allotmentInvoice';
import { allotmentInvoicePdfActions } from '../AllotmentInvoicePdf/slice';
import { parseAllotmentError, allotmentInvoicePopupNotifications } from './notifications';
import { selectAllotmentInvoicePopupAgent, selectAllotmentInvoicePopupFormData, selectAllotmentInvoicePopupAllotments, selectAllotmentInvoicePopupMode, selectAllotmentInvoicePopupInvoice } from './selectors';
import { allotmentInvoicePopupActions } from './slice';

function* confirmCreateInvoicePopup() {
    try {
        const allotments = (yield* select(selectAllotmentInvoicePopupAllotments))!;
        const value = (yield* select(selectAllotmentInvoicePopupFormData))!;
        const agent = yield* select(selectAllotmentInvoicePopupAgent);

        const allotmentInvoice = yield* createApiAllotmentInvoice({
            ids: allotments.map(x => x.id!),
            agent: agent!,
            title: value.title,
            invoiceDate: value.invoiceDate,
            paymentDeadline: value.paymentDeadline,
            invoiceCompany: value.invoiceCompany,
            discountDescription: value.discountDescription,
            discount: value.discount,
        });
        yield* put(allotmentInvoicePopupActions.confirmPopupSuccess());
        yield* put(allotmentInvoicePopupNotifications.invoiceCreatedSuccessfully);
        yield* put(allotmentInvoicePdfActions.downloadAllotmentInvoicePdf({
            id: allotmentInvoice.id!,
            callback: () => {},
        }));
    }
    catch (error) {
        logError({
            error,
            target: 'AllotmentInvoicePopup.confirmCreateInvoicePopup',
        });
        yield* put(allotmentInvoicePopupActions.confirmPopupError());
        const message = parseAllotmentError(error);
        yield* put(message);
    }
}

function* confirmUpdateInvoicePopup() {
    try {
        const invoice = (yield* select(selectAllotmentInvoicePopupInvoice))!;
        const value = (yield* select(selectAllotmentInvoicePopupFormData))!;

        const allotmentInvoice = yield* updateApiAllotmentInvoice(invoice.id, {
            title: value.title,
            invoiceDate: value.invoiceDate,
            paymentDeadline: value.paymentDeadline,
            invoiceCompany: value.invoiceCompany,
            discountDescription: value.discountDescription,
            discount: value.discount,
        });
        yield* put(allotmentInvoicePopupActions.confirmPopupSuccess());
        yield* put(allotmentInvoicePopupNotifications.invoiceUpdatedSuccessfully);
        yield* put(allotmentInvoicePdfActions.downloadAllotmentInvoicePdf({
            id: allotmentInvoice.id!,
            callback: () => {},
        }));
    }
    catch (error) {
        logError({
            error,
            target: 'AllotmentInvoicePopup.confirmUpdateInvoicePopup',
        });
        yield* put(allotmentInvoicePopupActions.confirmPopupError());
        const message = parseAllotmentError(error);
        yield* put(message);
    }
}

function* confirmInvoicePopup() {
    const mode = yield* select(selectAllotmentInvoicePopupMode);
    if (mode === 'edit') {
        return yield* confirmUpdateInvoicePopup();
    }
    return yield* confirmCreateInvoicePopup();
}

export function* confirmCreateInvoicePopupSaga() {
    yield* takeLatest(allotmentInvoicePopupActions.confirmPopup, confirmInvoicePopup);
}
