import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { permissionMaker } from '../permissionExpression';
import { PaginatedAdminRoutePermissions } from '.';

const { role, every, some, anyone } = permissionMaker;

const readUser = role('ROLE_ADMIN');
const rolesTab = role('ROLE_ADMIN');
const virtualAccountsTab = every(
    'ROLE_ACCOUNT_LIST',
    'ROLE_USER_VIRTUAL_ACCOUNTS_LIST',
    readUser,
);

const settingsAccount = anyone();
const settingsPermissions = role('ROLE_USER_LIST');
const settingsUserPermissions = some(
    rolesTab,
    virtualAccountsTab,
);
const entityLogList = role('ROLE_ENTITY_LOG_LIST');

export const settings: PaginatedAdminRoutePermissions['settings'] = {
    [allAdminRoutePaths.settingsAccount]: settingsAccount,
    [allAdminRoutePaths.settingsPermissions]: settingsPermissions,
    [allAdminRoutePaths.settingsUserPermissions]: settingsUserPermissions,
    [allAdminRoutePaths.settingsEventLog]: entityLogList,
    [allAdminRoutePaths.settings]: some(
        settingsAccount,
        settingsPermissions,
        entityLogList,
    ),
};
