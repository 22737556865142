import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    useInjectReducer as useReducer,
    useInjectSaga as useSaga,
} from 'redux-injectors';
import {
    InjectReducerParams,
    InjectSagaParams,
    RootStateKeyType,
} from './types/injector-typings';

export const useInjectReducer = <Key extends RootStateKeyType>(
    params: InjectReducerParams<Key> & {
        /** Only for unmount store on unmount component */
        actions?: {
            unmount: () => {
                payload: undefined,
                type: string,
            },
        },
    },
) => {
    const { key, reducer, actions } = params;

    const result = useReducer({ key, reducer });

    const dispatch = useDispatch();
    useEffect(() => {
        return () => {
            if (!actions) return;
            dispatch(actions.unmount());
        };
    }, [actions, dispatch]);

    return result;
};

export const useInjectSaga = (params: InjectSagaParams) => {
    return useSaga(params);
};
