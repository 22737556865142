import { Pagination } from '@malesia/react-components/dist/src/components/Pagination';
import { TableFooter } from '@malesia/react-components/dist/src/components/Table/TableFooter';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPagination, selectList } from '../store/selectors';
import { userPermissionsListPageActions } from '../store/slice';

export const SettingsPermissionsListFooterWithStore: SFC = () => {
    const dispatch = useDispatch();
    const pagination = useSelector(selectPagination);
    const list = useSelector(selectList);

    return (
        <TableFooter
            paginationSlot={
                <Pagination
                    total={list.total}
                    pageTotal={list.items.length}
                    pagination={pagination}
                    maxPageSize={50}
                    setPage={(data) => {
                        dispatch(userPermissionsListPageActions.setPage(data));
                    }}
                    setPageSize={(data) => {
                        dispatch(userPermissionsListPageActions.setPageSize(data));
                    }}
                />
            }
        />
    );
};
