import { ReservationCalculatePostRequest, ReservationFlight } from '@malesia/json-schema';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery, takeLatest, throttle } from 'typed-redux-saga';
import { logError } from '../../../../../utils/log';
import { parseReservationError } from '../../../../../utils/reservation/notifyReservationError';
import { PassengerBasket } from '../../../../../utils/reservation/types';
import { selectBookedFlights, selectPassengers } from '../../../../containers/ReservationBasket/reservationBasket.selectors';
import { reservationBasketActions } from '../../../../containers/ReservationBasket/reservationBasket.slice';
import { calculateApiReservationCost } from '../../../../services/ReservationCalculate/service';
import { preparePassengerOptions, preparePassengerSeats } from '../../../../services/ReservationCalculate/utils';
import { stepPassengersActions } from './slice';

function* calculateCost(action: ReturnType<typeof stepPassengersActions.calculateCost>) {
    const bookedFlights: ReservationFlight[] = yield* select(selectBookedFlights);
    try {
        const afterCalculateCallback = action.payload;
        const passengers: PassengerBasket[] = yield* select(selectPassengers);
        const passengersOptions = preparePassengerOptions(passengers.map(x => x.options));
        const passengersSeats = preparePassengerSeats(
            passengers.map(x => x.seats),
            bookedFlights[0]?.flight?.id!,
            bookedFlights[1]?.flight?.id!,
        );
        const request: ReservationCalculatePostRequest = {
            bookedFlights: bookedFlights as ReservationCalculatePostRequest['bookedFlights'],
            passengers: passengers.map(x => x.info) as ReservationCalculatePostRequest['passengers'],
            passengersOptions,
            passengersSeats,
        };
        const response = yield* call(calculateApiReservationCost, request);
        yield* put(reservationBasketActions.updateCost(response));
        afterCalculateCallback();
    }
    catch (error) {
        logError({
            error,
            target: 'BookingFlightPage.calculateCost',
        });
        yield* put(parseReservationError(error, (flightId) => {
            if (flightId === undefined) return undefined;
            if (bookedFlights.length === 0) return undefined;
            if (bookedFlights[0].flight.id === flightId) return 'outbound';
            if (bookedFlights.length === 1) return undefined;
            if (bookedFlights[1].flight.id === flightId) return 'return';
            return undefined;
        }));
    }
}

export function* calculateCostSaga() {
    yield* takeLatest(stepPassengersActions.calculateCost, calculateCost);

    function* requestCalculateCost(action: PayloadAction<() => void>) {
        yield* put(stepPassengersActions.calculateCost(action.payload));
    }
    yield* throttle(1000, stepPassengersActions.requestCalculateCost, requestCalculateCost);
}

export function* calculationWithPassengersSaga() {
    function* requestCalculateCost() {
        yield* put(stepPassengersActions.requestCalculateCost(() => {}));
    }
    yield* takeEvery(stepPassengersActions.calculateBasketWithUpdatePassengers, requestCalculateCost);
}
