import { AnyAction, combineReducers, Reducer } from '@reduxjs/toolkit';
import { connectRouter, RouterState } from 'connected-react-router';
import { appReducer } from '../app/containers/App/slice';
import { reservationBasketReducer } from '../app/containers/ReservationBasket/reservationBasket.slice';
import { sharedReducer } from '../app/containers/SharedData/slice';
import { loginReducer } from '../app/containers/UserLogin/slice';
import { bookingFlightReducer } from '../app/pages/BookingFlightPage/store/slice';
import { history } from '../utils/history';
import { InjectedReducersType } from '../utils/types/injector-typings';

export const createReducer = (injectedReducers: InjectedReducersType = {}) => {
    return combineReducers({
        ...injectedReducers,
        router: connectRouter(history) as Reducer<RouterState, AnyAction>,
        global: appReducer,
        userLoginContainer: loginReducer,
        reservationBasket: reservationBasketReducer,
        bookingFlightPage: bookingFlightReducer,
        sharedData: sharedReducer,
    });
};
