import { useInjectReducer, useInjectSaga } from '../../../../utils/redux-injectors';
import { loadPaymentTypeListSaga } from './sagas/loadPaymentTypeList.saga';
import { searchCreditAccountSaga } from './sagas/searchCreditAccount.saga';
import { searchDebitAccountSaga } from './sagas/searchDebitAccount.saga';
import { submitAdvancedFormSaga } from './sagas/submitAdvancedForm.saga';
import {
    newTransactionActions,
    newTransactionReducer,
    newTransactionSliceKey,
} from './slice';

export const useInjectAccountingNewTransaction = () => {
    useInjectReducer({
        actions: newTransactionActions,
        key: newTransactionSliceKey,
        reducer: newTransactionReducer,
    });
    useInjectSaga({
        key: 'searchDebitAccountSaga',
        saga: searchDebitAccountSaga,
    });
    useInjectSaga({
        key: 'searchCreditAccountSaga',
        saga: searchCreditAccountSaga,
    });
    useInjectSaga({
        key: 'loadPaymentTypeListSaga',
        saga: loadPaymentTypeListSaga,
    });
    useInjectSaga({
        key: 'submitAdvancedFormSaga',
        saga: submitAdvancedFormSaga,
    });
};
