import { AccountPaginatedList, AllotmentInvoiceListItem } from '@malesia/json-schema';
import { PaymentFormData } from '@malesia/react-components/dist/src/components/Payment/Form';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../../utils/@reduxjs/toolkit';
import { AllotmentPaymentPageState } from './types';

export const initialState: AllotmentPaymentPageState = {
    allotmentInvoice: {
        loading: true,
    },
    payment: {
        loading: false,
        data: {},
    },
    cashAccounts: { total: 0, items: [] },
    bankAccounts: { total: 0, items: [] },
    datatransAccounts: { total: 0, items: [] },
    transferAccounts: { total: 0, items: [] },
};

const reservationPaymentSlice = createSlice({
    name: 'allotmentPaymentPage',
    initialState,
    reducers: {
        loadInitialData(state, action: PayloadAction<number>) {
        },
        setAllotmentInvoiceLoading(state, action: PayloadAction<boolean>) {
            state.allotmentInvoice.loading = action.payload;
        },
        setAllotmentInvoice(state, action: PayloadAction<AllotmentInvoiceListItem>) {
            state.allotmentInvoice.data = action.payload;
        },
        setPaymentData(state, action: PayloadAction<PaymentFormData>) {
            state.payment.data = action.payload;
        },
        setCashAccounts(state, action: PayloadAction<AccountPaginatedList>) {
            state.cashAccounts = action.payload;
            if (action.payload.items.length > 0) {
                state.payment.data.cash = action.payload.items[0];
            }
        },
        setTransferAccounts(state, action: PayloadAction<AccountPaginatedList>) {
            state.transferAccounts = action.payload;
            if (action.payload.items.length > 0) {
                state.payment.data.transfer = action.payload.items[0];
            }
        },
        createPayment(state) {
            state.payment.loading = true;
        },
        createPaymentSuccess(state) {
            state.payment.loading = false;
        },
        createPaymentCancel(state) {
            state.payment.loading = false;
        },
        createPaymentError(state) {
            state.payment.loading = false;
        },
    },
});

export const {
    actions: allotmentPaymentActions,
    reducer: allotmentPaymentReducer,
    name: allotmentPaymentSliceName,
} = reservationPaymentSlice;
