import { reservationInvoiceListFilterConfig, ReservationInvoiceListFilter, ReservationInvoiceListFilterProps } from '@malesia/react-components/dist/src/components/Reservation/InvoiceList/Filter';
import { ReservationInvoiceListFilterData } from '@malesia/react-components/dist/src/components/Reservation/InvoiceList/Filter/types';
import { ValueConfig, useValueForm } from '@malesia/react-components/dist/src/hooks/valueForm';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUserPermissions } from '../../../permissions/useUserPermissions';
import { getReservationListHiddenFilters } from '../../../services/Reservation/listUtils';
import { selectFilterData } from '../store/selectors';
import { actions } from '../store/slice';

export const ReservationInvoiceListFilterWithStore = () => {
    const value = useSelector(selectFilterData);

    const dispatch = useDispatch();
    const userPermissions = useUserPermissions();
    const hiddenFilters = getReservationListHiddenFilters(userPermissions);

    const {
        field,
    } = useValueForm({
        value,
        config: [reservationInvoiceListFilterConfig, (): ValueConfig<ReservationInvoiceListFilterData> => ({
            shape: {
                query: {
                    hidden: hiddenFilters.query,
                },
                paymentStatus: {
                    hidden: hiddenFilters.paymentStatus,
                },
                deadlineDateInterval: {
                    hidden: hiddenFilters.deadlineDateInterval,
                },
            },
        })],
        onChange: (v) => {
            dispatch(actions.setFilterData(v));
        },
        validationEffects: [
            {
                target: ['deadlineDateInterval', 'from'],
                dependent: [
                    ['deadlineDateInterval', 'to'],
                ],
            },
        ],
    });

    const reservationListProps: ReservationInvoiceListFilterProps = {
        field,
        submit: () => {
            dispatch(actions.getReservationList());
        },
    };

    return (
        <ReservationInvoiceListFilter {...reservationListProps} />
    );
};
