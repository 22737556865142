import { Flight, FlightPassengerEntireList, Tariff, TariffGroupEntireList } from '@malesia/json-schema';
import { FlightPassengerFilterInfo } from '@malesia/react-components/dist/src/components/Flight/Passenger/Filter';
import { FlightPassengerAppearingOption } from '@malesia/react-components/dist/src/components/Flight/Passenger/List/actions';
import { SortingDataType } from '@malesia/react-components/dist/src/components/Table';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { SeatMap } from '../../../utils/reservation/types';
import {
    FlightPassengerListPageState,
    FlightPassengerSeatPopupInfo,
    Pnl,
    FlightPassengerFilterData,
} from './types';

export type ActionByFlightIdType = {
    flightId: number,
};

export type FlightPassengerAppearing = {
    isAppeared: boolean,
    comment: string,
};
export type UpdateFlightPassengersAppearingType = ActionByFlightIdType & {
    appearing: Partial<FlightPassengerAppearing> | null,
};

export type DownloadPaxListType = ActionByFlightIdType & {
    filterData: FlightPassengerFilterData,
};

export type FlightBookingInfo = {
    adultPassengers: number,
    childPassengers: number,
    babyPassengers: number,
};

export type FlightPassengersInfo = FlightPassengerFilterInfo;

export type UpdateFlightPassengerAppearingType = {
    passengerId: string | number,
    appearing: FlightPassengerAppearingOption,
    callback: () => void,
};

export type FlightPassengerSeat = {
    seatLabel?: string,
    [key: string]: any,
};

export type FlightPassengerNotifying = {
    isNotified?: boolean,
    [key: string]: any,
};

export type UpdateFlightPassengerNotifyingType = {
    reservationPassengerId: string | number,
    notifying: Partial<FlightPassengerNotifying> | null,
};

export type UpdateFlightPassengerSeatType = {
    tariff: Tariff,
    reservationId: number,
    reservationPassengerId: number,
    seat: Partial<FlightPassengerSeat> | null,
};

export type GetFightSeatMapType = {
    flight: Flight,
    reservationId: number,
    reservationPassengerId: number,
    tariffId: number,
};

export const initialState: FlightPassengerListPageState = {
    initialized: {
        flightId: false,
        sorting: false,
    },
    isDirty: true,
    isLoading: true,
    isLoadingAppearingMap: {},

    flightId: 0,
    filterData: {
        sortBy: 'lastName',
        sortOrder: 'ASC',
        searchText: '',
        appearingStatuses: ['pending', 'not-appeared'],
        paymentStatuses: [],
        tariffGroups: [],
    },
    flightPassengerList: {
        items: [],
    },
    flightPassengersInfo: [],
    flightBookingInfo: {
        adultPassengers: 0,
        childPassengers: 0,
        babyPassengers: 0,
    },
    tariffGroupList: {
        items: [],
    },
    //
    isShowCommentPopup: false,
    seatPopupInfo: {
        tariffGroupCode: 'economy',
    },
    isShowSeatPopup: false,
    notifying: {
        isEmailNotifyEnabled: true,
        isSmsNotifyEnabled: false,
    },
    isShowNotifyingPopup: false,
    selectedRows: [] as any[],
    isPNLDownloading: false,
    isPAXDownloading: false,
    comment: {
        isCancel: true,
    },
};

const flightPassengersListPageSlice = createSlice({
    name: 'flightPassengerListPage',
    initialState,
    reducers: {
        setSorting(state, action: PayloadAction<SortingDataType>) {
            const data = action.payload;
            state.filterData = {
                ...state.filterData,
                sortBy: data.sortBy,
                sortOrder: data.sortOrder,
            };
            state.isDirty = true;
            state.initialized.sorting = true;
        },
        updateSearch(state, action: PayloadAction<Partial<FlightPassengerFilterData>>) {
            const newObject = { ...state.filterData, ...action.payload };
            if (JSON.stringify(state.filterData) !== JSON.stringify(newObject)) {
                state.filterData = newObject;
                state.isDirty = true;
            }
        },
        resetSearch(state) {
            state.filterData = initialState.filterData;
            state.isDirty = true;
        },
        getFlight(state, action: PayloadAction<ActionByFlightIdType>) {
            state.flightId = action.payload.flightId;
            state.initialized.flightId = true;
        },
        getFlightSuccess(state, action: PayloadAction<Flight>) {
            state.flight = action.payload;
        },
        getFlightError(state) {
        },
        setFlightBookingInfo(state, action: PayloadAction<FlightBookingInfo>) {
            state.flightBookingInfo = action.payload;
        },
        setFlightPassengersInfo(state, action: PayloadAction<FlightPassengersInfo>) {
            state.flightPassengersInfo = action.payload;
        },
        setFlightDelayed(state, action: PayloadAction<ActionByFlightIdType>) {
        },
        setFlightDelayedSuccess(state) {
        },
        setFlightDelayedError(state) {
        },
        setFlightDeparted(state, payload: PayloadAction<ActionByFlightIdType>) {
        },
        setFlightDepartedSuccess(state) {
        },
        setFlightDepartedError(state) {
        },
        getTariffGroupList(state) {
        },
        getTariffGroupListSuccess(
            state,
            action: PayloadAction<TariffGroupEntireList>,
        ) {
            state.tariffGroupList = action.payload;
        },
        getTariffGroupListError(state) {
        },
        getFlightPassengerList(state) {
            state.isLoading = true;
        },
        getFlightPassengerListSuccess(state) {
            state.isLoading = false;
            state.isDirty = false;
        },
        getFlightPassengerListError(state) {
            state.isLoading = false;
        },
        setFlightPassengerList(state, action: PayloadAction<FlightPassengerEntireList>) {
            state.flightPassengerList = action.payload;
        },
        updateFlightPassengerAppearing(
            state,
            action: PayloadAction<UpdateFlightPassengerAppearingType>,
        ) {
            const { passengerId, appearing } = action.payload;
            state.isLoadingAppearingMap[passengerId] = appearing;
        },
        updateFlightPassengerAppearingSuccess(state, action: PayloadAction<string | number>) {
            state.isDirty = true;
            state.isLoadingAppearingMap[action.payload] = undefined;
        },
        updateFlightPassengerAppearingError(state, action: PayloadAction<string | number>) {
            state.isLoadingAppearingMap[action.payload] = undefined;
        },
        updateFlightPassengerAppearingCancel(state, action: PayloadAction<string | number>) {
            state.isLoadingAppearingMap[action.payload] = undefined;
        },
        getFlightSeatMap(state, action: PayloadAction<GetFightSeatMapType>) {
        },
        getFlightSeatMapSuccess(state, action: PayloadAction<SeatMap | undefined>) {
            state.aircraftTemplate = action.payload;
        },
        getFlightSeatMapError(state, action) {
            state.aircraftTemplate = undefined;
        },
        updateComment(state, action: PayloadAction<string | undefined>) {
            state.comment.message = action.payload;
        },
        askComment(state) {
            state.isShowCommentPopup = true;
            state.comment = {
                isCancel: true,
            };
        },
        askCommentSuccess(state) {
            state.isShowCommentPopup = false;
            state.comment.isCancel = false;
        },
        askCommentCancel(state) {
            state.isShowCommentPopup = false;
            state.comment.isCancel = true;
        },
        setSeatPopupInfo(state, action: PayloadAction<FlightPassengerSeatPopupInfo>) {
            state.seatPopupInfo = action.payload;
        },
        setSeatLabelErrors(state, action) {
            state.seatPopupInfo.errors = action.payload;
        },
        updateFlightPassengerSeat(state, action: PayloadAction<UpdateFlightPassengerSeatType>) {
        },
        updateFlightPassengerSeatSuccess(state) {
            state.isDirty = true;
        },
        updateFlightPassengerSeatError(state) {
        },
        askSeatPopup(state) {
            state.isShowSeatPopup = true;
        },
        askSeatPopupConfirm(state, action: PayloadAction<string | undefined>) {
            state.isShowSeatPopup = false;
            state.seatPopupInfo.seatLabel = action.payload;
        },
        askSeatPopupCancel(state) {
            state.isShowSeatPopup = false;
            state.seatPopupInfo.seatLabel = undefined;
            state.seatPopupInfo.errors = {};
        },
        updateFlightPassengerNotified(
            state,
            action: PayloadAction<UpdateFlightPassengerNotifyingType>,
        ) {},
        askNotifying(state) {
            state.isShowNotifyingPopup = true;
        },
        updateNotifying(state, action) {
            state.notifying = action.payload;
        },
        askNotifyingConfirm(state) {
            state.isShowNotifyingPopup = false;
        },
        askNotifyingCancel(state) {
            state.isShowNotifyingPopup = false;
        },
        updateFlightPassengerNotifiedSuccess(state) {
            state.isDirty = true;
        },
        updateFlightPassengerNotifiedError(state) {
        },
        downloadPaxList(state, action: PayloadAction<DownloadPaxListType>) {
        },
        getPnlList(state, action: PayloadAction<ActionByFlightIdType>) {
        },
        clearPnlList(state) {
            state.pnl = undefined;
        },
        getPnlListSuccess(state, action: PayloadAction<Pnl>) {
            state.pnl = action.payload;
        },
        getPnlListError(state) {
        },
        setSelectedRows(state, action) {
            const oldSelectedRows = state.selectedRows;
            const newSelectedRows = action.payload;
            if (
                JSON.stringify(oldSelectedRows.map(item => item.id))
                !== JSON.stringify(newSelectedRows.map(item => item.id))
            ) {
                state.selectedRows = newSelectedRows;
            }
        },
        updateFlightPassengersAppearing(state, action: PayloadAction<UpdateFlightPassengersAppearingType>) {},
        updateFlightPassengersAppearingSuccess(state) {
            state.isDirty = true;
        },
        updateFlightPassengersAppearingError(state) {
        },
        setPNLDownloading(state, action: PayloadAction<boolean>) {
            state.isPNLDownloading = action.payload;
        },
        setPAXDownloading(state, action: PayloadAction<boolean>) {
            state.isPAXDownloading = action.payload;
        },
    },
});

export const {
    actions,
    reducer,
    name: sliceKey,
} = flightPassengersListPageSlice;

export const {
    actions: flightPassengersListPageActions,
    reducer: flightPassengersListPageReducer,
    name: flightPassengersListPageSliceKey,
} = flightPassengersListPageSlice;
