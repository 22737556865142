import { Customer } from '@malesia/json-schema';
import { TableColumn } from '@malesia/react-components/dist/src/components/Table';
import { balanceColumn } from '@malesia/react-components/dist/src/components/Table/columns/balanceColumn';
import { idColumn } from '@malesia/react-components/dist/src/components/Table/columns/idColumn';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { customerListPermissions } from '../../permissions/adminPages/user/customerList';
import { UserPermissions } from '../../permissions/userPermissions';
import { useUserPermissions } from '../../permissions/useUserPermissions';
import { customerActionsColumn } from './actionsColumn';

type TableColumnsOptions = {
    history: ReturnType<typeof useHistory>,
    userPermissions: UserPermissions,
};
const tableColumns = (
    options: TableColumnsOptions,
): TableColumn<Customer>[] => {
    const {
        history,
        userPermissions,
    } = options;
    return [
        idColumn(),
        {
            Header: (
                <FormattedMessage
                    id='front-app/CustomerListPage:ColumnName'
                    defaultMessage='Name'
                />
            ),
            accessor: 'displayName',
            Cell: ({ row }) => {
                const customer = row.original;
                const { displayName } = customer;

                return <span>{displayName}</span>;
            },
        },
        {
            Header: (
                <FormattedMessage
                    id='front-app/CustomerListPage:ColumnEmail'
                    defaultMessage='Email'
                />
            ),
            accessor: 'email',
        },
        {
            Header: () => (
                <FormattedMessage
                    id='front-app/CustomerListPage:ColumnPhone'
                    defaultMessage='Phone'
                />
            ),
            id: 'phone',
            Cell: ({ row }) => {
                const customer = row.original;
                const mapPhone = (phone: string | undefined, index: number) => {
                    return (
                        phone && (
                            <span
                                key={index}
                                style={{ display: 'block' }}
                            >
                                {phone}
                            </span>
                        )
                    );
                };

                return (
                    <span>
                        {[
                            customer.phoneMobile,
                            customer.phoneNumberFirst,
                            customer.phoneNumberSecond,
                        ].map(mapPhone)}
                    </span>
                );
            },
            disableShowForDevice: true,
        },
        balanceColumn({
            disableSortBy: true,
        }),
        customerActionsColumn({
            editCustomer: {
                click: (customer) => {
                    const path = generatePath(allAdminRoutePaths.userCustomerEdit, {
                        customerId: customer.id!,
                    });
                    history.push(path);
                },
                notAllowed: !userPermissions.has(customerListPermissions.editLink),
            },
            balanceLog: {
                click: (customer) => {
                    const path = generatePath(allAdminRoutePaths.balanceLog) + `?accountId=${customer.id!}`;
                    history.push(path);
                },
                notAllowed: !userPermissions.has(customerListPermissions.balanceLogLink),
            },
            reservationList: {
                click: (customer) => {
                    const path = generatePath(allAdminRoutePaths.reservationList) + `?ownerId=${customer.id}`;
                    history.push(path);
                },
                notAllowed: !userPermissions.has(customerListPermissions.reservationsByOwnerLink),
            },
        }),
    ];
};

export const useTableColumns = () => {
    const history = useHistory();
    const userPermissions = useUserPermissions();
    const result = useMemo(() => tableColumns({
        history,
        userPermissions,
    }), [
        history,
        userPermissions,
    ]);
    return result;
};
