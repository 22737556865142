import { PageTabs } from '@malesia/react-components/dist/src/components/Page/Tabs';
import { RouteItem } from '@malesia/react-components/dist/src/components/types';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Link,
    Route,
    RouteComponentProps,
    Switch,
    Redirect,
} from 'react-router-dom';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { useTabsState } from '../../containers/Routes/tabsState/useTabsState';
import { useFilteredAdminRoutesByPermissions } from '../../permissions/useFilteredAdminRoutesByPermissions';
import { EventLogPage } from '../EventLogPage';
import { NotFoundPage } from '../NotFoundPage';
import { SettingsPermissionsList } from '../SettingsPermissionsListPage';
import { SettingsUserPermissionsPage } from '../SettingsUserPermissionsPage';
import { AdminUserAccountPage } from '../UserAccountPage';

type Props = RouteComponentProps;

const SettingsRouterPage: SFC<Props> = (props) => {

    const { location } = props;
    const { pathname } = location;

    const isRouteMatch = linkRoute => pathname === linkRoute;
    const settingsRoutes: RouteItem[] = useFilteredAdminRoutesByPermissions([
        {
            routeKey: 'settingsAccount',
            linkRoute: allAdminRoutePaths.settingsAccount,
            LinkComponent: (props: React.PropsWithChildren<any>) => (
                <Link to={allAdminRoutePaths.settingsAccount}>
                    {React.Children.only(props.children)}
                </Link>
            ),
            linkSubject: (
                <FormattedMessage
                    id='front-app/Routes/USER_ME:Title'
                    defaultMessage='My account'
                />
            ),
        },
        {
            routeKey: 'entityLogList',
            linkRoute: allAdminRoutePaths.settingsEventLog,
            LinkComponent: (props: React.PropsWithChildren<any>) => (
                <Link to={allAdminRoutePaths.settingsEventLog}>
                    {React.Children.only(props.children)}
                </Link>
            ),
            linkSubject: (
                <FormattedMessage
                    id='front-app/Routes/EntityLog:Title'
                    defaultMessage='Event log'
                />
            ),
        },
        {
            routeKey: 'permissions',
            linkRoute: allAdminRoutePaths.settingsPermissions,
            LinkComponent: (props: React.PropsWithChildren<any>) => (
                <Link to={allAdminRoutePaths.settingsPermissions}>
                    {React.Children.only(props.children)}
                </Link>
            ),
            linkSubject: (
                <FormattedMessage
                    id='front-app/Routes/Settings/Permissions:Title'
                    defaultMessage='Permissions'
                />
            ),
        },
    ]);

    const { firstRoute } = useTabsState(allAdminRoutePaths.settings, settingsRoutes);

    return (
        <>
            <PageTabs
                isRouteMatch={isRouteMatch}
                routeItems={settingsRoutes}
            >
                <Switch>
                    <Route
                        path={allAdminRoutePaths.settingsAccount}
                        component={AdminUserAccountPage}
                    />
                    <Route
                        exact
                        path={allAdminRoutePaths.settingsEventLog}
                        component={EventLogPage}
                    />
                    <Route
                        exact
                        path={allAdminRoutePaths.settingsPermissions}
                        component={SettingsPermissionsList}
                    />
                    <Route
                        exact
                        path={allAdminRoutePaths.settingsUserPermissions}
                        component={SettingsUserPermissionsPage}
                    />
                    <Route
                        exact
                        path={allAdminRoutePaths.settings}
                    >
                        <Redirect to={firstRoute} />
                    </Route>
                    <Route component={NotFoundPage} />
                </Switch>
            </PageTabs>
        </>
    );
};

const Memoized = memo(SettingsRouterPage);
export {
    Memoized as SettingsRouterPage,
};
