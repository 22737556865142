import { PageTabs } from '@malesia/react-components/dist/src/components/Page/Tabs';
import { RouteItem } from '@malesia/react-components/dist/src/components/types';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Link,
    Route,
    RouteComponentProps,
    Switch,
    Redirect,
} from 'react-router-dom';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { useTabsState } from '../../containers/Routes/tabsState/useTabsState';
import { useFilteredAdminRoutesByPermissions } from '../../permissions/useFilteredAdminRoutesByPermissions';
import { NotFoundPage } from '../NotFoundPage';
import { OptionEditPage } from '../OptionEditPage';
import { OptionListPage } from '../OptionListPage';
import { OptionNewPage } from '../OptionNewPage';
import { RateEditPage } from '../RateEditPage';
import { RateListPage } from '../RateListPage';
import { RateNewPage } from '../RateNewPage';

type Props = RouteComponentProps;

export const RateRouterPage: SFC<Props> = (props: Props) => {

    const { location } = props;
    const { pathname } = location;

    const isRouteMatch = linkRoute => pathname === linkRoute;

    const rateRoutes: RouteItem[] = useFilteredAdminRoutesByPermissions([
        {
            routeKey: 'rateList',
            linkRoute: allAdminRoutePaths.rateList,
            LinkComponent: (props: React.PropsWithChildren<any>) => (
                <Link to={allAdminRoutePaths.rateList}>
                    {React.Children.only(props.children)}
                </Link>
            ),
            linkSubject: (
                <FormattedMessage
                    id='front-app/Routes/RATE:Title'
                    defaultMessage='Rates'
                />
            ),
        },
        {
            routeKey: 'optionList',
            linkRoute: allAdminRoutePaths.rateOptionList,
            LinkComponent: (props: React.PropsWithChildren<any>) => (
                <Link to={allAdminRoutePaths.rateOptionList}>
                    {React.Children.only(props.children)}
                </Link>
            ),
            linkSubject: (
                <FormattedMessage
                    id='front-app/Routes/RATE_OPTION_LIST:Title'
                    defaultMessage='Options'
                />
            ),
        },
    ]);

    const { firstRoute } = useTabsState(allAdminRoutePaths.rate, rateRoutes);

    return (
        <>
            <PageTabs
                isRouteMatch={isRouteMatch}
                routeItems={rateRoutes}
            >
                <Switch>
                    <Route
                        exact
                        path={allAdminRoutePaths.rateList}
                        component={RateListPage}
                    />
                    <Route
                        exact
                        path={allAdminRoutePaths.rateEdit}
                        component={RateEditPage}
                    />
                    <Route
                        exact
                        path={allAdminRoutePaths.rateNew}
                        component={RateNewPage}
                    />
                    <Route
                        exact
                        path={allAdminRoutePaths.rateOptionList}
                        component={OptionListPage}
                    />
                    <Route
                        exact
                        path={allAdminRoutePaths.rateOptionEdit}
                        component={OptionEditPage}
                    />
                    <Route
                        exact
                        path={allAdminRoutePaths.rateOptionNew}
                        component={OptionNewPage}
                    />
                    <Route
                        exact
                        path={allAdminRoutePaths.rate}
                    >
                        <Redirect to={firstRoute} />
                    </Route>
                    <Route component={NotFoundPage} />
                </Switch>
            </PageTabs>
        </>
    );
};
