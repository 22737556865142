import { ActionFormData } from '@malesia/react-components/dist/src/components/Flight/AircraftSeatsConfiguration/Actions';
import { FormConfig } from '@malesia/react-components/dist/src/hooks/useForm';
import { useMemo } from 'react';

export const useAircraftActionConfig = (selectedSeats): FormConfig<ActionFormData> => {
    const result = useMemo(() => {
        return {
            price: { disabled: selectedSeats.length === 0 },
            action: {},
        };
    }, [selectedSeats]);

    return result;
};
