/**
 * Do not change this file manually !!!!
 * This file is generated by script to import schemas massively.
 * Script is located at 'scripts-for-app/ajv-schema-loader-generator.js'
 */
import Ajv from 'ajv';
import addFormats from 'ajv-formats';

/* eslint-disable */
const AJV_DEFAULT_OPTIONS = {
  coerceTypes: true,
};
const ajvErrorValidationOptions = !!process.env.REACT_APP_API_SCHEMA_VALIDATION_ERRORS_REPORT_ALL
  ? {
    allErrors: true,
    verbose: true,
  }
  : {};
const ajvOptions = { ...AJV_DEFAULT_OPTIONS, ...ajvErrorValidationOptions };
const ajv = new Ajv(ajvOptions);
addFormats(ajv);

ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'));

ajv.addSchema(
  require('@malesia/json-schema/json/schema/generic.json'),
  'file://malesiareisen.com/json/schema/generic.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/accountDef.json'),
  'file://malesiareisen.com/json/schema/defs/accountDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/accountType.json'),
  'file://malesiareisen.com/json/schema/defs/accountType.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/agentInputsDef.json'),
  'file://malesiareisen.com/json/schema/defs/agentInputsDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/allotmentInvoiceCompanyType.json'),
  'file://malesiareisen.com/json/schema/defs/allotmentInvoiceCompanyType.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/allotmentInvoiceStatusType.json'),
  'file://malesiareisen.com/json/schema/defs/allotmentInvoiceStatusType.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/allotmentInvoicedStatusType.json'),
  'file://malesiareisen.com/json/schema/defs/allotmentInvoicedStatusType.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/authToken.json'),
  'file://malesiareisen.com/json/schema/defs/authToken.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/balanceLogAccount.json'),
  'file://malesiareisen.com/json/schema/defs/balanceLogAccount.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/balanceLogRecordDef.json'),
  'file://malesiareisen.com/json/schema/defs/balanceLogRecordDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/balanceLogRecordTypeDef.json'),
  'file://malesiareisen.com/json/schema/defs/balanceLogRecordTypeDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/customerInputsDef.json'),
  'file://malesiareisen.com/json/schema/defs/customerInputsDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/delayNotifyDef.json'),
  'file://malesiareisen.com/json/schema/defs/delayNotifyDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/entityLogRecordDef.json'),
  'file://malesiareisen.com/json/schema/defs/entityLogRecordDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/flightPassengerAppearingDef.json'),
  'file://malesiareisen.com/json/schema/defs/flightPassengerAppearingDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/flightPassengerNotifyingDef.json'),
  'file://malesiareisen.com/json/schema/defs/flightPassengerNotifyingDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/flightPassengerSeatDef.json'),
  'file://malesiareisen.com/json/schema/defs/flightPassengerSeatDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/managerInputsDef.json'),
  'file://malesiareisen.com/json/schema/defs/managerInputsDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/notifyDef.json'),
  'file://malesiareisen.com/json/schema/defs/notifyDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/objectId.json'),
  'file://malesiareisen.com/json/schema/defs/objectId.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/passwordDef.json'),
  'file://malesiareisen.com/json/schema/defs/passwordDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/pnlExportType.json'),
  'file://malesiareisen.com/json/schema/defs/pnlExportType.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/reservationBillingInformationDef.json'),
  'file://malesiareisen.com/json/schema/defs/reservationBillingInformationDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/reservationPassengerAgeTypeDef.json'),
  'file://malesiareisen.com/json/schema/defs/reservationPassengerAgeTypeDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/reservationPassengerDef.json'),
  'file://malesiareisen.com/json/schema/defs/reservationPassengerDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/reservationPaymentStatusType.json'),
  'file://malesiareisen.com/json/schema/defs/reservationPaymentStatusType.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/reservationPaymentTermsType.json'),
  'file://malesiareisen.com/json/schema/defs/reservationPaymentTermsType.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/reservationPaymentTypeDef.json'),
  'file://malesiareisen.com/json/schema/defs/reservationPaymentTypeDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/reservationStatusType.json'),
  'file://malesiareisen.com/json/schema/defs/reservationStatusType.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/seat.json'),
  'file://malesiareisen.com/json/schema/defs/seat.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/sortDef.json'),
  'file://malesiareisen.com/json/schema/defs/sortDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/userBalanceDef.json'),
  'file://malesiareisen.com/json/schema/defs/userBalanceDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/userDef.json'),
  'file://malesiareisen.com/json/schema/defs/userDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/userEmailDef.json'),
  'file://malesiareisen.com/json/schema/defs/userEmailDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/userGroupDef.json'),
  'file://malesiareisen.com/json/schema/defs/userGroupDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/userLoginCredentials.json'),
  'file://malesiareisen.com/json/schema/defs/userLoginCredentials.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/userProfileDef.json'),
  'file://malesiareisen.com/json/schema/defs/userProfileDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/userType.json'),
  'file://malesiareisen.com/json/schema/defs/userType.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/defs/virtualAccountDef.json'),
  'file://malesiareisen.com/json/schema/defs/virtualAccountDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/mixins/bulkOperationProperties.json'),
  'file://malesiareisen.com/json/schema/mixins/bulkOperationProperties.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/mixins/language.json'),
  'file://malesiareisen.com/json/schema/mixins/language.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/mixins/multiLanguage.json'),
  'file://malesiareisen.com/json/schema/mixins/multiLanguage.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/mixins/notifying.json'),
  'file://malesiareisen.com/json/schema/mixins/notifying.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/mixins/passwordWithConfirm.json'),
  'file://malesiareisen.com/json/schema/mixins/passwordWithConfirm.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/mixins/sorting.json'),
  'file://malesiareisen.com/json/schema/mixins/sorting.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/mixins/statusesSearchParameters.json'),
  'file://malesiareisen.com/json/schema/mixins/statusesSearchParameters.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/agent/post/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/agent/post/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/agent/put/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/agent/put/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/auth/recoverPassword/post/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/auth/recoverPassword/post/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/auth/token/post/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/auth/token/post/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/auth/token/post/response.json'),
  'file://malesiareisen.com/json/schema/project/api/call/auth/token/post/response.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/auth/updatePassword/post/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/auth/updatePassword/post/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/customer/post/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/customer/post/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/customer/put/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/customer/put/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/datatransTransaction/agent/init/post/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/datatransTransaction/agent/init/post/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/datatransTransaction/agent/init/post/response.json'),
  'file://malesiareisen.com/json/schema/project/api/call/datatransTransaction/agent/init/post/response.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/datatransTransaction/confirm/post/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/datatransTransaction/confirm/post/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/datatransTransaction/confirm/post/response.json'),
  'file://malesiareisen.com/json/schema/project/api/call/datatransTransaction/confirm/post/response.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/datatransTransaction/get/response.json'),
  'file://malesiareisen.com/json/schema/project/api/call/datatransTransaction/get/response.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/datatransTransaction/reservation/init/post/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/datatransTransaction/reservation/init/post/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/datatransTransaction/reservation/init/post/response.json'),
  'file://malesiareisen.com/json/schema/project/api/call/datatransTransaction/reservation/init/post/response.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/datatransTransaction/statusUpdate/post/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/datatransTransaction/statusUpdate/post/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/datatransTransaction/statusUpdate/post/response.json'),
  'file://malesiareisen.com/json/schema/project/api/call/datatransTransaction/statusUpdate/post/response.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/flight/post/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/flight/post/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/flight/put/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/flight/put/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/flightCompany/post/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/flightCompany/post/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/flightCompany/put/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/flightCompany/put/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/flightSequence/post/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/flightSequence/post/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/manager/post/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/manager/post/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/manager/put/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/manager/put/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/option/post/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/option/post/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/payment/reservationInvoice/post/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/payment/reservationInvoice/post/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/option/put/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/option/put/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/payment/free/post/requestAdvanced.json'),
  'file://malesiareisen.com/json/schema/project/api/call/payment/free/post/requestAdvanced.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/public/payment/datatrans/start/post/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/public/payment/datatrans/start/post/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/public/payment/datatrans/start/post/response.json'),
  'file://malesiareisen.com/json/schema/project/api/call/public/payment/datatrans/start/post/response.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/public/reservation/transaction/status/get/response.json'),
  'file://malesiareisen.com/json/schema/project/api/call/public/reservation/transaction/status/get/response.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/rate/post/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/rate/post/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/rate/put/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/rate/put/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/reservation/calculate/post/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/reservation/calculate/post/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/reservation/calculate/post/response.json'),
  'file://malesiareisen.com/json/schema/project/api/call/reservation/calculate/post/response.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/reservation/filters.json'),
  'file://malesiareisen.com/json/schema/project/api/call/reservation/filters.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/reservation/filtersDef.json'),
  'file://malesiareisen.com/json/schema/project/api/call/reservation/filtersDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/reservation/sortingParamsDef.json'),
  'file://malesiareisen.com/json/schema/project/api/call/reservation/sortingParamsDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/reservation/post/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/reservation/post/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/reservation/post/response.json'),
  'file://malesiareisen.com/json/schema/project/api/call/reservation/post/response.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/reservation/put/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/reservation/put/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/reservation/put/response.json'),
  'file://malesiareisen.com/json/schema/project/api/call/reservation/put/response.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/reservation/user/filters.json'),
  'file://malesiareisen.com/json/schema/project/api/call/reservation/user/filters.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/user/put/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/user/put/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/user/me/get/response.json'),
  'file://malesiareisen.com/json/schema/project/api/call/user/me/get/response.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/user/roles/get/response.json'),
  'file://malesiareisen.com/json/schema/project/api/call/user/roles/get/response.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/user/groups/get/response.json'),
  'file://malesiareisen.com/json/schema/project/api/call/user/groups/get/response.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/virtualAccount/list/get/response.json'),
  'file://malesiareisen.com/json/schema/project/api/call/virtualAccount/list/get/response.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/virtualAccount/post/request.json'),
  'file://malesiareisen.com/json/schema/project/api/call/virtualAccount/post/request.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/balanceLog/accountFilters.json'),
  'file://malesiareisen.com/json/schema/project/api/call/balanceLog/accountFilters.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/balanceLog/filters.json'),
  'file://malesiareisen.com/json/schema/project/api/call/balanceLog/filters.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/balanceLog/filtersDef.json'),
  'file://malesiareisen.com/json/schema/project/api/call/balanceLog/filtersDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/balanceLog/filtersWithAccount.json'),
  'file://malesiareisen.com/json/schema/project/api/call/balanceLog/filtersWithAccount.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/call/balanceLog/filtersWithoutAccount.json'),
  'file://malesiareisen.com/json/schema/project/api/call/balanceLog/filtersWithoutAccount.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/defs/dateIntervalDef.json'),
  'file://malesiareisen.com/json/schema/project/api/defs/dateIntervalDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/defs/languageDef.json'),
  'file://malesiareisen.com/json/schema/project/api/defs/languageDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/defs/paginationParamsDef.json'),
  'file://malesiareisen.com/json/schema/project/api/defs/paginationParamsDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/defs/sortingParamsDef.json'),
  'file://malesiareisen.com/json/schema/project/api/defs/sortingParamsDef.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/mixins/paginatedList.json'),
  'file://malesiareisen.com/json/schema/project/api/mixins/paginatedList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/mixins/sortedList.json'),
  'file://malesiareisen.com/json/schema/project/api/mixins/sortedList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/PNLExternal.json'),
  'file://malesiareisen.com/json/schema/project/api/model/PNLExternal.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/PNLExternalEntireList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/PNLExternalEntireList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/PNLInternal.json'),
  'file://malesiareisen.com/json/schema/project/api/model/PNLInternal.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/account.json'),
  'file://malesiareisen.com/json/schema/project/api/model/account.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/accountListItem.json'),
  'file://malesiareisen.com/json/schema/project/api/model/accountListItem.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/accountPaginatedList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/accountPaginatedList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/agent.json'),
  'file://malesiareisen.com/json/schema/project/api/model/agent.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/agentPaginatedList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/agentPaginatedList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/agentSearchQuery.json'),
  'file://malesiareisen.com/json/schema/project/api/model/agentSearchQuery.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/aircraftSection.json'),
  'file://malesiareisen.com/json/schema/project/api/model/aircraftSection.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/aircraftTemplate.json'),
  'file://malesiareisen.com/json/schema/project/api/model/aircraftTemplate.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/aircraftTemplateGenericListItem.json'),
  'file://malesiareisen.com/json/schema/project/api/model/aircraftTemplateGenericListItem.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/aircraftTemplateGenericPaginatedList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/aircraftTemplateGenericPaginatedList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/airport.json'),
  'file://malesiareisen.com/json/schema/project/api/model/airport.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/airportEntireList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/airportEntireList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/airportFlightsMap.json'),
  'file://malesiareisen.com/json/schema/project/api/model/airportFlightsMap.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/airportPaginatedList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/airportPaginatedList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/airportSearchQuery.json'),
  'file://malesiareisen.com/json/schema/project/api/model/airportSearchQuery.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/allotment.json'),
  'file://malesiareisen.com/json/schema/project/api/model/allotment.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/allotmentInvoice.json'),
  'file://malesiareisen.com/json/schema/project/api/model/allotmentInvoice.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/allotmentInvoiceBulkOperation.json'),
  'file://malesiareisen.com/json/schema/project/api/model/allotmentInvoiceBulkOperation.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/allotmentInvoicePaginatedList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/allotmentInvoicePaginatedList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/allotmentInvoicePayment.json'),
  'file://malesiareisen.com/json/schema/project/api/model/allotmentInvoicePayment.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/allotmentPaginatedList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/allotmentPaginatedList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/baggage.json'),
  'file://malesiareisen.com/json/schema/project/api/model/baggage.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/balanceLogAccountSummaryInfo.json'),
  'file://malesiareisen.com/json/schema/project/api/model/balanceLogAccountSummaryInfo.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/balanceLogPaginatedList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/balanceLogPaginatedList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/balanceLogRecord.json'),
  'file://malesiareisen.com/json/schema/project/api/model/balanceLogRecord.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/balanceLogRecordTypeEntireList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/balanceLogRecordTypeEntireList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/billingInformation.json'),
  'file://malesiareisen.com/json/schema/project/api/model/billingInformation.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/checkInType.json'),
  'file://malesiareisen.com/json/schema/project/api/model/checkInType.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/commonBulkOperation.json'),
  'file://malesiareisen.com/json/schema/project/api/model/commonBulkOperation.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/commonNotifyOperation.json'),
  'file://malesiareisen.com/json/schema/project/api/model/commonNotifyOperation.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/commonSearchQuery.json'),
  'file://malesiareisen.com/json/schema/project/api/model/commonSearchQuery.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/country.json'),
  'file://malesiareisen.com/json/schema/project/api/model/country.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/countryEntireList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/countryEntireList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/customer.json'),
  'file://malesiareisen.com/json/schema/project/api/model/customer.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/customerPaginatedList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/customerPaginatedList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/datatransTransaction.json'),
  'file://malesiareisen.com/json/schema/project/api/model/datatransTransaction.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/entityLogEntityTypeEntireList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/entityLogEntityTypeEntireList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/entityLogOperationTypeEntireList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/entityLogOperationTypeEntireList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/entityLogPaginatedList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/entityLogPaginatedList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/entityLogRecord.json'),
  'file://malesiareisen.com/json/schema/project/api/model/entityLogRecord.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/flight.json'),
  'file://malesiareisen.com/json/schema/project/api/model/flight.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/flightBookingInfo.json'),
  'file://malesiareisen.com/json/schema/project/api/model/flightBookingInfo.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/flightBulkOperation.json'),
  'file://malesiareisen.com/json/schema/project/api/model/flightBulkOperation.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/flightCompany.json'),
  'file://malesiareisen.com/json/schema/project/api/model/flightCompany.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/flightCompanyEntireList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/flightCompanyEntireList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/flightCompanyPaginatedList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/flightCompanyPaginatedList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/flightCompanySearchQuery.json'),
  'file://malesiareisen.com/json/schema/project/api/model/flightCompanySearchQuery.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/flightDelayNotifyOperation.json'),
  'file://malesiareisen.com/json/schema/project/api/model/flightDelayNotifyOperation.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/flightListItem.json'),
  'file://malesiareisen.com/json/schema/project/api/model/flightListItem.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/flightPaginatedList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/flightPaginatedList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/flightPassengerAppearing.json'),
  'file://malesiareisen.com/json/schema/project/api/model/flightPassengerAppearing.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/flightPassengerBulkOperation.json'),
  'file://malesiareisen.com/json/schema/project/api/model/flightPassengerBulkOperation.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/flightPassengerEntireList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/flightPassengerEntireList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/flightPassengerListItem.json'),
  'file://malesiareisen.com/json/schema/project/api/model/flightPassengerListItem.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/flightPassengerNotifying.json'),
  'file://malesiareisen.com/json/schema/project/api/model/flightPassengerNotifying.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/flightPassengersInfo.json'),
  'file://malesiareisen.com/json/schema/project/api/model/flightPassengersInfo.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/flightPlan.json'),
  'file://malesiareisen.com/json/schema/project/api/model/flightPlan.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/flightPlanItem.json'),
  'file://malesiareisen.com/json/schema/project/api/model/flightPlanItem.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/flightPlanSection.json'),
  'file://malesiareisen.com/json/schema/project/api/model/flightPlanSection.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/flightRepeating.json'),
  'file://malesiareisen.com/json/schema/project/api/model/flightRepeating.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/flightStatus.json'),
  'file://malesiareisen.com/json/schema/project/api/model/flightStatus.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/flightTurnoverInfo.json'),
  'file://malesiareisen.com/json/schema/project/api/model/flightTurnoverInfo.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/freePaymentTypeEntireList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/freePaymentTypeEntireList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/gender.json'),
  'file://malesiareisen.com/json/schema/project/api/model/gender.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/invoicePaymentTypeEntireList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/invoicePaymentTypeEntireList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/manager.json'),
  'file://malesiareisen.com/json/schema/project/api/model/manager.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/managerPaginatedList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/managerPaginatedList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/marginType.json'),
  'file://malesiareisen.com/json/schema/project/api/model/marginType.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/notification.json'),
  'file://malesiareisen.com/json/schema/project/api/model/notification.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/notificationTransport.json'),
  'file://malesiareisen.com/json/schema/project/api/model/notificationTransport.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/option.json'),
  'file://malesiareisen.com/json/schema/project/api/model/option.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/optionAttach.json'),
  'file://malesiareisen.com/json/schema/project/api/model/optionAttach.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/optionEntireList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/optionEntireList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/optionPaginatedList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/optionPaginatedList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/optionType.json'),
  'file://malesiareisen.com/json/schema/project/api/model/optionType.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/optionTypeConfigPassengerFixPrice.json'),
  'file://malesiareisen.com/json/schema/project/api/model/optionTypeConfigPassengerFixPrice.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/optionTypeEntireList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/optionTypeEntireList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/paymentType.json'),
  'file://malesiareisen.com/json/schema/project/api/model/paymentType.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/priceListItem.json'),
  'file://malesiareisen.com/json/schema/project/api/model/priceListItem.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/publicFlightCompany.json'),
  'file://malesiareisen.com/json/schema/project/api/model/publicFlightCompany.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/publicFlightListItem.json'),
  'file://malesiareisen.com/json/schema/project/api/model/publicFlightListItem.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/publicFlightPaginatedList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/publicFlightPaginatedList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/rate.json'),
  'file://malesiareisen.com/json/schema/project/api/model/rate.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/rateEntireList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/rateEntireList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/rateListItem.json'),
  'file://malesiareisen.com/json/schema/project/api/model/rateListItem.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/rateOption.json'),
  'file://malesiareisen.com/json/schema/project/api/model/rateOption.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/ratePaginatedList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/ratePaginatedList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/reservation.json'),
  'file://malesiareisen.com/json/schema/project/api/model/reservation.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/reservationBasket.json'),
  'file://malesiareisen.com/json/schema/project/api/model/reservationBasket.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/reservationBasketPassenger.json'),
  'file://malesiareisen.com/json/schema/project/api/model/reservationBasketPassenger.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/reservationFlight.json'),
  'file://malesiareisen.com/json/schema/project/api/model/reservationFlight.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/reservationInvoice.json'),
  'file://malesiareisen.com/json/schema/project/api/model/reservationInvoice.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/reservationInvoicePassenger.json'),
  'file://malesiareisen.com/json/schema/project/api/model/reservationInvoicePassenger.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/reservationInvoicePayment.json'),
  'file://malesiareisen.com/json/schema/project/api/model/reservationInvoicePayment.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/reservationListItem.json'),
  'file://malesiareisen.com/json/schema/project/api/model/reservationListItem.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/reservationNotificationBulkOperation.json'),
  'file://malesiareisen.com/json/schema/project/api/model/reservationNotificationBulkOperation.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/reservationPaginatedList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/reservationPaginatedList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/reservationPassenger.json'),
  'file://malesiareisen.com/json/schema/project/api/model/reservationPassenger.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/reservationPassengerFlight.json'),
  'file://malesiareisen.com/json/schema/project/api/model/reservationPassengerFlight.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/reservationPassengerPaginatedList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/reservationPassengerPaginatedList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/reservationPassengerWithAgeType.json'),
  'file://malesiareisen.com/json/schema/project/api/model/reservationPassengerWithAgeType.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/reservationPriceForPassenger.json'),
  'file://malesiareisen.com/json/schema/project/api/model/reservationPriceForPassenger.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/reservationPriceForPassengerAge.json'),
  'file://malesiareisen.com/json/schema/project/api/model/reservationPriceForPassengerAge.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/reservationPriceForPassengersOptions.json'),
  'file://malesiareisen.com/json/schema/project/api/model/reservationPriceForPassengersOptions.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/reservationPriceForPassengersSeats.json'),
  'file://malesiareisen.com/json/schema/project/api/model/reservationPriceForPassengersSeats.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/reservationSnapshotEntireList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/reservationSnapshotEntireList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/salutation.json'),
  'file://malesiareisen.com/json/schema/project/api/model/salutation.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/seatsConfig.json'),
  'file://malesiareisen.com/json/schema/project/api/model/seatsConfig.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/tariff.json'),
  'file://malesiareisen.com/json/schema/project/api/model/tariff.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/tariffEntireList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/tariffEntireList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/tariffGroup.json'),
  'file://malesiareisen.com/json/schema/project/api/model/tariffGroup.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/tariffGroupEntireList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/tariffGroupEntireList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/user.json'),
  'file://malesiareisen.com/json/schema/project/api/model/user.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/userGroup.json'),
  'file://malesiareisen.com/json/schema/project/api/model/userGroup.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/userProfile.json'),
  'file://malesiareisen.com/json/schema/project/api/model/userProfile.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/userProfilePaginatedList.json'),
  'file://malesiareisen.com/json/schema/project/api/model/userProfilePaginatedList.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/api/model/weekday.json'),
  'file://malesiareisen.com/json/schema/project/api/model/weekday.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/db/proto/protoAircraftTemplate.json'),
  'file://malesiareisen.com/json/schema/project/db/proto/protoAircraftTemplate.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/db/proto/protoAirport.json'),
  'file://malesiareisen.com/json/schema/project/db/proto/protoAirport.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/db/proto/protoFlight.json'),
  'file://malesiareisen.com/json/schema/project/db/proto/protoFlight.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/db/proto/protoFlightCompany.json'),
  'file://malesiareisen.com/json/schema/project/db/proto/protoFlightCompany.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/db/proto/protoRate.json'),
  'file://malesiareisen.com/json/schema/project/db/proto/protoRate.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/db/reflection/dbAircraftTemplate.json'),
  'file://malesiareisen.com/json/schema/project/db/reflection/dbAircraftTemplate.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/db/reflection/dbAirport.json'),
  'file://malesiareisen.com/json/schema/project/db/reflection/dbAirport.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/db/reflection/dbFlight.json'),
  'file://malesiareisen.com/json/schema/project/db/reflection/dbFlight.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/db/reflection/dbFlightCompany.json'),
  'file://malesiareisen.com/json/schema/project/db/reflection/dbFlightCompany.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/db/reflection/dbRate.json'),
  'file://malesiareisen.com/json/schema/project/db/reflection/dbRate.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-components/form/billingInformationForm.json'),
  'file://malesiareisen.com/json/schema/project/react-components/form/billingInformationForm.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-components/form/passengerListItemForm.json'),
  'file://malesiareisen.com/json/schema/project/react-components/form/passengerListItemForm.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-front-app/containers/reservation-basket/airport.json'),
  'file://malesiareisen.com/json/schema/project/react-front-app/containers/reservation-basket/airport.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-front-app/containers/reservation-basket/billingInformation.json'),
  'file://malesiareisen.com/json/schema/project/react-front-app/containers/reservation-basket/billingInformation.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-front-app/containers/reservation-basket/preCalculation.json'),
  'file://malesiareisen.com/json/schema/project/react-front-app/containers/reservation-basket/preCalculation.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-front-app/containers/reservation-basket/priceListItem.json'),
  'file://malesiareisen.com/json/schema/project/react-front-app/containers/reservation-basket/priceListItem.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-front-app/containers/reservation-basket/publicFlightItem.json'),
  'file://malesiareisen.com/json/schema/project/react-front-app/containers/reservation-basket/publicFlightItem.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-front-app/pages/ReservationsListPage/filters.json'),
  'file://malesiareisen.com/json/schema/project/react-front-app/pages/ReservationsListPage/filters.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-front-app/pages/ReservationsListPage/notification.json'),
  'file://malesiareisen.com/json/schema/project/react-front-app/pages/ReservationsListPage/notification.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-front-app/pages/BookingFlightPage/Steps/StepPassengers/passengerListItemForm.json'),
  'file://malesiareisen.com/json/schema/project/react-front-app/pages/BookingFlightPage/Steps/StepPassengers/passengerListItemForm.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-front-app/pages/MalesiaHomePage/searchForm.json'),
  'file://malesiareisen.com/json/schema/project/react-front-app/pages/MalesiaHomePage/searchForm.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-front-app/pages/ManagerNewPage/managerNewForm.json'),
  'file://malesiareisen.com/json/schema/project/react-front-app/pages/ManagerNewPage/managerNewForm.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-front-app/pages/ManagerEditPage/managerEditForm.json'),
  'file://malesiareisen.com/json/schema/project/react-front-app/pages/ManagerEditPage/managerEditForm.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-front-app/pages/AgentEditPage/agentEditForm.json'),
  'file://malesiareisen.com/json/schema/project/react-front-app/pages/AgentEditPage/agentEditForm.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-front-app/pages/AgentNewPage/agentNewForm.json'),
  'file://malesiareisen.com/json/schema/project/react-front-app/pages/AgentNewPage/agentNewForm.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-front-app/pages/CustomerNewPage/customerNewForm.json'),
  'file://malesiareisen.com/json/schema/project/react-front-app/pages/CustomerNewPage/customerNewForm.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-front-app/pages/CustomerEditPage/customerEditForm.json'),
  'file://malesiareisen.com/json/schema/project/react-front-app/pages/CustomerEditPage/customerEditForm.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-front-app/pages/BookingFlightPage/Steps/StepBillingInformation/billingForm.json'),
  'file://malesiareisen.com/json/schema/project/react-front-app/pages/BookingFlightPage/Steps/StepBillingInformation/billingForm.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-front-app/pages/reservation/add/billingInformation.json'),
  'file://malesiareisen.com/json/schema/project/react-front-app/pages/reservation/add/billingInformation.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-front-app/pages/reservation/add/seatLabel.json'),
  'file://malesiareisen.com/json/schema/project/react-front-app/pages/reservation/add/seatLabel.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-front-app/pages/reservation/add/travelDates__base.json'),
  'file://malesiareisen.com/json/schema/project/react-front-app/pages/reservation/add/travelDates__base.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-front-app/pages/reservation/add/travelDates__one-way.json'),
  'file://malesiareisen.com/json/schema/project/react-front-app/pages/reservation/add/travelDates__one-way.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-front-app/pages/reservation/add/travelDates__other-return.json'),
  'file://malesiareisen.com/json/schema/project/react-front-app/pages/reservation/add/travelDates__other-return.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-front-app/pages/reservation/add/travelDates__return.json'),
  'file://malesiareisen.com/json/schema/project/react-front-app/pages/reservation/add/travelDates__return.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-front-app/pages/AccountingNewTransactionPage/newTransactionAdvancedForm.json'),
  'file://malesiareisen.com/json/schema/project/react-front-app/pages/AccountingNewTransactionPage/newTransactionAdvancedForm.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-front-app/pages/AccountingNewTransactionPage/newTransactionSimpleForm.json'),
  'file://malesiareisen.com/json/schema/project/react-front-app/pages/AccountingNewTransactionPage/newTransactionSimpleForm.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-front-app/pages/UserAccountPage/agentForm.json'),
  'file://malesiareisen.com/json/schema/project/react-front-app/pages/UserAccountPage/agentForm.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-front-app/pages/UserAccountPage/customerForm.json'),
  'file://malesiareisen.com/json/schema/project/react-front-app/pages/UserAccountPage/customerForm.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/react-front-app/pages/UserAccountPage/managerForm.json'),
  'file://malesiareisen.com/json/schema/project/react-front-app/pages/UserAccountPage/managerForm.json',
);
ajv.addSchema(
  require('@malesia/json-schema/json/schema/project/payment/datatrans/datatransWebhookRequestData.json'),
  'file://malesiareisen.com/json/schema/project/payment/datatrans/datatransWebhookRequestData.json',
);

export default ajv;
