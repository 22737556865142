import { FlightListItem, TariffGroup } from '@malesia/json-schema';
import { flightColumns } from '@malesia/react-components/dist/src/components/Flight/List/tableColumns';
import { TableColumn } from '@malesia/react-components/dist/src/components/Table';
import { splitActionConfig } from '@malesia/react-components/dist/src/components/Table/columns/actionsColumn/splitAction';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makePath } from '../../../utils/uriUtils';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { backLinks } from '../../containers/Routes/backLinks';
import { flightListPermissions } from '../../permissions/adminPages/flight/flightList';
import { useUserPermissions } from '../../permissions/useUserPermissions';
import { actions } from './store/slice';

const getBookedTotal = (flight: FlightListItem) => (
    flight.bookedSeatsByTariff?.reduce<number>((sum, seatsConfig) => (
        sum + seatsConfig.seatsForSale!
    ), 0) ?? 0
);

type UseTableColumnsOptions = {
    tariffGroupList: TariffGroup[],
};
export const useTableColumns = (options: UseTableColumnsOptions): TableColumn<FlightListItem>[] => {
    const { tariffGroupList } = options;

    const userPermissions = useUserPermissions();
    const dispatch = useDispatch();
    const history = useHistory();

    const result = useMemo(() => flightColumns({
        common: {
            tariffGroupList,
        },
        select: {
            hidden: !userPermissions.has(flightListPermissions.bulkActions),
        },
        departure: {
            clickSequence: (flight) => {
                dispatch(actions.updateFilterData({
                    sequence: flight.sequence,
                }));
            },
        },
        totalSeats: {
            hidden: (
                !userPermissions.has(flightListPermissions.totalColumn)
                || userPermissions.userType === 'agent'
            ),
        },
        availableSeats: {
            allowedNegative: userPermissions.userType === 'manager',
        },
        actions: {
            editFlight: {
                click: (flight) => {
                    const link = backLinks.flight.forward(flight.id, {
                        source: 'flightList',
                    });
                    history.push(link);
                },
                notAllowed: !userPermissions.has(flightListPermissions.editLink),
            },
            deleteFlightAndFlightPassengerList: splitActionConfig({
                getState: (flight) => (
                    (getBookedTotal(flight) > 0 || !userPermissions.has(flightListPermissions.deleteFlight))
                        ? 'passengerList'
                        : 'deleteFlight'
                ),
                passengerList: {
                    click: (flight) => {
                        const path = makePath(allAdminRoutePaths.flightPassengerList, {
                            flightId: flight.id,
                        });
                        history.push(path);
                    },
                    disabled: (flight) => !(getBookedTotal(flight) > 0),
                    notAllowed: !userPermissions.has(flightListPermissions.flightPassengerListLink),
                },
                deleteFlight: {
                    click: (flight) => {
                        dispatch(actions.setSelectedRows([flight]));
                        dispatch(actions.openDeleteFlights());
                    },
                    notAllowed: !userPermissions.has(flightListPermissions.deleteFlight),
                },
            }),
            flightInfo: {
                click: (flight) => {
                    dispatch(actions.openFlightInfo());
                    dispatch(actions.getFlightInfo(flight));
                },
                notAllowed: !userPermissions.has(flightListPermissions.readInfo),
            },
            bookedOutFlight: {
                getState: (flight) => flight.isBookedOut ? 'mark' : 'unmark',
                click: (flight, operation) => {
                    dispatch(actions.bookOutFlight({
                        id: flight.id,
                        operation,
                    }));
                },
                notAllowed: !userPermissions.has(flightListPermissions.markBookedOut),
            },
            activateFlight: {
                getState: (flight) => flight.isActive ? 'activate' : 'deactivate',
                click: (flight, operation) => {
                    dispatch(actions.activateFlight({
                        id: flight.id,
                        operation,
                    }));
                },
                notAllowed: !userPermissions.has(flightListPermissions.activate),
            },
            cancelFlight: {
                click: (flight) => {
                    dispatch(actions.setFlightCancelled(flight.id));
                },
                notAllowed: !userPermissions.has(flightListPermissions.updateStatus),
                disabled: (flight) => (
                    flight.status !== 'created'
                ),
            },
        },
    }), [
        tariffGroupList,
        userPermissions,
        dispatch,
        history,
    ]);

    return result;
};
