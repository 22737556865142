import { AuthUpdatePasswordPostRequest } from '@malesia/json-schema';
import { PayloadAction } from '@reduxjs/toolkit';
import { ErrorObject } from 'ajv';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { MalesiaUpdatePasswordPageState } from './types';

export const initialState: MalesiaUpdatePasswordPageState = {
    formIsValid: false,
    formIsLoading: false,
    formValidationErrors: [],
    formResultIsSuccessful: false,
    formUntouchedFields: ['password', 'passwordConfirm'],
};

const malesiaUpdatePasswordPageSlice = createSlice({
    name: 'malesiaUpdatePasswordPage',
    initialState,
    reducers: {
        setInitialState(state) {
            state = initialState;
        },
        updateValidationStatus(state, action: PayloadAction<any>) {
            state.formIsValid = action.payload.formIsValid;
            state.formUntouchedFields = action.payload.formUntouchedFields;
            state.formValidationErrors = action.payload.formValidationErrors;
        },
        submitData(state, action: PayloadAction<AuthUpdatePasswordPostRequest>) {
            state.formIsLoading = true;
        },
        submitSuccess(state) {
            state.formIsLoading = false;
            state.formResultIsSuccessful = true;
        },
        submitValidationError(state, action: PayloadAction<ErrorObject[]>) {
            state.formIsLoading = false;
            state.formIsValid = false;
            state.formValidationErrors = action.payload;
        },
        submitFailed(state) {
            state.formIsLoading = false;
        },
    },
});

export const {
    actions,
    reducer,
    name: sliceKey,
} = malesiaUpdatePasswordPageSlice;
