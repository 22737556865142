import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => {
    return state.loginModal || initialState;
};

export const selectLoginModalIsOpened = createSelector(
    [selectDomain],
    loginModal => loginModal.isOpened,
);
