import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from '../../../../utils/redux-injectors';
import { useInjectReservationPdf } from '../../../containers/ReservationPdf/inject';
import { createPaymentSaga, loadInitialDataSaga } from './saga';
import { allotmentPaymentActions, allotmentPaymentReducer, allotmentPaymentSliceName } from './slice';

export const useAllotmentPaymentPageInject = () => {
    useInjectReservationPdf();
    useInjectReducer({ key: allotmentPaymentSliceName, reducer: allotmentPaymentReducer });

    useInjectSaga({
        key: `${allotmentPaymentSliceName}loadInitialDataSaga`,
        saga: loadInitialDataSaga,
    });
    useInjectSaga({
        key: `${allotmentPaymentSliceName}createPaymentSaga`,
        saga: createPaymentSaga,
    });

    const dispatch = useDispatch();
    useEffect(() => {
        return () => {
            dispatch(allotmentPaymentActions.unmount());
        };
    }, [dispatch]);
};
