import { Flight, Reservation, ReservationInvoice, ReservationPassenger, Tariff } from '@malesia/json-schema';
import { ExistingPaymentData } from '@malesia/react-components/dist/src/components/Reservation/AdminPayment/ExistingPayment/ExistingPayment';
import { AdminBillingFormData } from '@malesia/react-components/dist/src/components/Reservation/BillingInformation/AdminBillingInformation';
import { TravelType } from '@malesia/react-components/dist/src/components/Reservation/reservation-types';
import { call, put, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../../utils/log';
import { reservationNotifications } from '../../../../utils/reservation/notifyReservationError';
import { getPassengersAgesByList, toPassengerBasket } from '../../../../utils/reservation/passengerUtils';
import { BookedFlights, SelectedFlights } from '../../../../utils/reservation/selectedFlight';
import { PassengerOptions } from '../../../../utils/reservation/types';
import { getApiErrorMessage } from '../../../services/ApiClient';
import { getUsedOptions } from '../../../services/PassengerOption/utils';
import { getApiReservationById } from '../../../services/Reservation';
import { calculateApiReservationCost } from '../../../services/ReservationCalculate/service';
import { PaymentBasket, TravelInfo, AdminReservationBasket } from '../types';
import { reservationNewPageActions } from './slice';
import { prepareReservationCostRequest } from './utils';

const mapBillingInformation = (
    { phoneMobile, ...billing }: Reservation['billingInformation'],
): AdminBillingFormData => ({
    ...billing,
    country: billing.country?.code,
    phoneNumberMobile: phoneMobile,
} as AdminBillingFormData);

// ToDo: Fix schema for `id`, this is really exists.
type FixMeReservationPassenger = ReservationPassenger & {
    id?: number,
};
export const createReservation = (data: Reservation): AdminReservationBasket => {
    const outboundFlight = data.flights[0].flight as Flight;
    const returnFlight = data.flights[1]?.flight as Flight | undefined;
    const outboundTariff = data.flights[0].tariff as Tariff;
    const returnTariff = data.flights[1]?.tariff as Tariff | undefined;

    const selectedFlights: SelectedFlights = {
        outbound: {
            flightId: outboundFlight.id!,
            tariffId: outboundTariff.id!,
        },
        ['return']: returnFlight ? {
            flightId: returnFlight.id!,
            tariffId: returnTariff?.id!,
        } : undefined,
    };

    const getTravelType = (): TravelType => {
        if (!returnFlight) return 'one-way';
        const part1 = outboundFlight.destination!.code === returnFlight.origin!.code;
        const part2 = returnFlight.destination!.code === outboundFlight.origin!.code;
        if (part1 && part2) return 'return';
        return 'other-return';
    };
    const travelInfo: TravelInfo = {
        type: getTravelType(),
        outbound: {
            // ToDo: Unsafe code .split('T')[0]. Use moment to split
            date: outboundFlight.departure!.split('T')[0],
            airports: {
                originAirportCode: outboundFlight.origin!.code,
                destinationAirportCode: outboundFlight.destination!.code,
            },
        },
        ['return']: returnFlight ? {
            // ToDo: Unsafe code .split('T')[0]. Use moment to split
            date: returnFlight.departure!.split('T')[0],
            airports: {
                originAirportCode: returnFlight.origin!.code,
                destinationAirportCode: returnFlight.destination!.code,
            },
        } : {},
    };

    const passengers = toPassengerBasket(data.passengers);
    data.passengers.map((passenger: FixMeReservationPassenger, index) => {
        const passengerOptions = data.options?.filter((option: { passenger: FixMeReservationPassenger }) => (
            option.passenger.id === passenger.id
        )) ?? [];
        const passengerOptionsMap: PassengerOptions = {};
        for (const option of passengerOptions) {
            passengerOptionsMap[option.option.id!] = true;
        }
        passengers[index].options = passengerOptionsMap;
    });
    data.passengers.forEach((passenger: FixMeReservationPassenger, index) => {
        const passengerSeats = data.seats
            ?.filter(passengerSeat => passengerSeat.reservationPassenger!.id === passenger.id)
            ?? [];

        passengers[index].seats = {
            outbound: passengerSeats.find(x => x.flight?.id === selectedFlights.outbound?.flightId)?.seatLabel,
            ['return']: passengerSeats.find(x => x.flight?.id === selectedFlights.return?.flightId)?.seatLabel,
        };
    });
    const passengersAges = getPassengersAgesByList(passengers.map(x => x.info));

    const bookedFlights: BookedFlights = {};
    if (selectedFlights.outbound) {
        const seatLabels = passengers
            .map(x => x.seats.outbound)
            .filter((x): x is string => !!x);
        bookedFlights.outbound = {
            ...selectedFlights.outbound,
            seatLabels,
        };
    }
    if (selectedFlights.return) {
        const seatLabels = passengers
            .map(x => x.seats.return)
            .filter((x): x is string => !!x);
        bookedFlights.return = {
            ...selectedFlights.return,
            seatLabels,
        };
    }
    const bookedOptionList = getUsedOptions(data);

    const invoice = data.invoice as ReservationInvoice | undefined;
    const specificPrice = invoice?.isSpecificPrice ? invoice.total : undefined;
    const existingPayments = invoice?.payments?.map<ExistingPaymentData>(x => ({
        amount: x.amount,
        code: x.type.code,
        date: x.createdAt!,
    })) ?? [];

    const billingInformation = mapBillingInformation(data.billingInformation);
    const comment = data.comment;

    const payment: PaymentBasket = {
        bookedTotalPrice: invoice?.total,
        specificPrice,
        hidePrice: data.priceNeedsToBeHidden,
        totalPaid: invoice?.totalPaid,
        existingPayments,
        paymentTerms: invoice?.paymentTerms || null,
        paymentDeadline: invoice?.paymentDeadline || undefined,
    };

    return {
        reservationId: data.id,
        selectedFlights,
        bookedFlights,
        bookedOptionList,
        travelInfo,
        passengersAges,
        passengers,
        billingInformation,
        bookedBillingInformation: billingInformation,
        payment,
        comment,
    };
};

function* loadEditReservation(action: ReturnType<typeof reservationNewPageActions.loadEditReservation>) {
    const reservationId = action.payload;
    try {
        const response = yield* call(getApiReservationById, reservationId);
        const reservation = createReservation(response);
        const params = prepareReservationCostRequest(reservation);
        if (params) {
            const costResponse = yield* call(calculateApiReservationCost, params);
            const cost = costResponse.cost?.total;
            const { bookedTotalPrice, specificPrice } = reservation.payment;

            if (cost && cost !== bookedTotalPrice) {
                // Keep price by saved value
                reservation.payment.specificPrice = specificPrice ? specificPrice : bookedTotalPrice;
            }
            if (reservation.payment.specificPrice) {
                // Bind to current total price for SpecificPrice:Attention
                reservation.payment.totalOnOpenPrice = cost;
            }
        }
        if (response.status === 'cancelled') {
            yield* put(reservationNewPageActions.loadEditCanceledReservationSuccess());
            return;
        }
        yield* put(reservationNewPageActions.loadEditReservationSuccess(reservation));
        yield* put(reservationNewPageActions.getFlightLists());
    }
    catch (error) {
        logError({
            error,
            target: 'ReservationNewPage.loadEditReservation',
        });
        const message = getApiErrorMessage(error);
        yield* put(reservationNotifications.unknownError(message));
        yield* put(reservationNewPageActions.loadEditReservationError());
    }
}

export function* loadEditReservationSaga() {
    yield* takeLatest(reservationNewPageActions.loadEditReservation, loadEditReservation);
}
