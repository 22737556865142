import { ErrorObject, ValidateFunction } from 'ajv';
import ajv from '../../api/malesia/ajvLoader';

// @formatter:off
export type CoercionResultType<DATA> = [
    boolean | PromiseLike<any>,
    DATA, // Record<string, any>,
    ErrorObject[] | null | undefined,
];
// @formatter:on

export function coercionDataToSchema<DATA>(
    schemaId,
    data: DATA,
): CoercionResultType<DATA> {
    const validate = ajv.getSchema(schemaId) as ValidateFunction;
    if (!validate) {
        console.error('[MALESIA ERROR]', `Schema with ID '${schemaId}' not found.`);
    }
    const validation = validate(data);
    const { errors } = validate;
    return [validation, data, errors];
}
