import { FlightPassengerListInfo } from '@malesia/react-components/dist/src/components/Flight/Passenger/List/Info';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectFlightBookingInfo,
    selectIsPNLDownloading,
    selectIsPAXDownloading,
} from '../selectors';
import { actions } from '../slice';

export type PassengerListInfoProps = {
    flightId: number,
};
export const PassengerListInfo: SFC<PassengerListInfoProps> = (props) => {
    const { flightId } = props;
    const flightBookingInfo = useSelector(selectFlightBookingInfo);
    const isPNLDownloading = useSelector(selectIsPNLDownloading);
    const isPAXDownloading = useSelector(selectIsPAXDownloading);

    const dispatch = useDispatch();

    const handlePAXClick = useCallback(() => {
        dispatch(
            actions.downloadPaxList({
                flightId,
                filterData: {
                    appearingStatuses: ['pending', 'appeared'],
                },
            }),
        );
    }, [dispatch, flightId]);

    const handlePNLClick = useCallback(() => {
        dispatch(actions.getPnlList({ flightId }));
    }, [dispatch, flightId]);

    return (
        <FlightPassengerListInfo
            adultsCount={flightBookingInfo?.adultPassengers}
            childrenCount={flightBookingInfo?.childPassengers}
            infantsCount={flightBookingInfo?.babyPassengers}
            onPAXClick={handlePAXClick}
            onPNLClick={handlePNLClick}
            isPNLDownloading={isPNLDownloading}
            isPAXDownloading={isPAXDownloading}
        />
    );
};
