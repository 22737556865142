import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.malesiaFlightPage || initialState;

export const selectFlightList = createSelector(
    [selectDomain],
    state => state.flightList,
);
