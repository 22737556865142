import { checkInvalidCredentials } from '../api/malesia/ApiResponseError';

export type ErrorInfo = {
    error: unknown,
    target: string,
};
export const logError = ({ error, target }: ErrorInfo) => {
    if (checkInvalidCredentials(error)) return;
    console.error(error, target);
};
export const logWarning = ({ error, target }: ErrorInfo) => {
    console.warn(error, target);
};
