import { useInjectReducer, useInjectSaga } from '../../../../utils/redux-injectors';
import { useInjectAuxiliaryData } from '../../../containers/AuxiliaryData/inject';
import {
    getFlightDataSaga,
    setFlightDataSaga,
    updateFlightDataSaga,
    getCreationFlightDataSaga,
    getFlightPassengersSaga,
    clickSettingsSaga,
} from './saga';
import { actions, reducer, sliceKey } from './slice';

export const useInjectFlightEdit = () => {
    useInjectAuxiliaryData();
    useInjectReducer({ actions, key: sliceKey, reducer });
    useInjectSaga({
        key: `${sliceKey}_getCreationFlightDataSaga`,
        saga: getCreationFlightDataSaga,
    });
    useInjectSaga({
        key: `${sliceKey}Get`,
        saga: getFlightDataSaga,
    });
    useInjectSaga({
        key: `${sliceKey}Set`,
        saga: setFlightDataSaga,
    });
    useInjectSaga({
        key: `${sliceKey}UpdateFormData`,
        saga: updateFlightDataSaga,
    });
    useInjectSaga({
        key: `${sliceKey}GetFlightPassengers`,
        saga: getFlightPassengersSaga,
    });
    useInjectSaga({
        key: `${sliceKey}clickSettingsSaga`,
        saga: clickSettingsSaga,
    });
};
