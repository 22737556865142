import './wdyr';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import '@malesia/react-components/dist/src/styles/main.scss';
import './xiagReactMalesiaComponentsStyles.tsx';

import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { App } from './app/containers/App';
import * as serviceWorker from './serviceWorker';
import { configureAppStore } from './store/configureStore';
import { history } from './utils/history';
import { initSentry } from './utils/sentry';
import 'sanitize.css/sanitize.css';

initSentry(window.location.hostname);

// disable React.Intl warning messages
if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line
  const consoleError = console.error.bind(console);
    const disableMessage = '[React Intl] Could not find required `intl` object. <IntlProvider> needs to exist in the component ancestry. Using default message as fallback.';
    // eslint-disable-next-line
  console.error = (message, ...args) => {
        if (typeof message === 'string' && message.startsWith(disableMessage)) {
            return;
        }
        consoleError(message, ...args);
    };
}

// Create redux store with history
const store = configureAppStore(history);
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

type Props = {
    Component: typeof App,
};

const ConnectedApp = ({ Component }: Props) => (
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <HelmetProvider>
                <React.StrictMode>
                    <Component />
                </React.StrictMode>
            </HelmetProvider>
        </ConnectedRouter>
    </Provider>
);
const render = (Component: typeof App) => {
    ReactDOM.render(<ConnectedApp Component={Component} />, MOUNT_NODE);
};

if (module.hot) {
    // Hot reloadable translation json files and app
    // modules.hot.accept does not accept dynamic dependencies,
    // have to be constants at compile-time
    module.hot.accept(['./app/containers/App'], () => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE);
        const App = require('./app/containers/App');
        render(App);
    });
}

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
