import { FlightListFilter, FlightListFilterProps, flightListFilterConfig } from '@malesia/react-components/dist/src/components/Flight/List/Filter';
import { useValueForm } from '@malesia/react-components/dist/src/hooks/valueForm';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flightListPermissions } from '../../../permissions/adminPages/flight/flightList';
import { useUserPermissions } from '../../../permissions/useUserPermissions';
import {
    selectCancelledList,
    selectAirportList,
    selectFilterData,
} from '../store/selectors';
import { actions } from '../store/slice';

export const FlightListFilterWithStore: SFC = () => {
    const cancelledFlightList = useSelector(selectCancelledList);
    const airportList = useSelector(selectAirportList);
    const value = useSelector(selectFilterData);
    const userPermissions = useUserPermissions();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.getAirportList());
    }, [dispatch]);

    const hashRef = useRef({});
    const {
        field,
    } = useValueForm({
        value,
        config: [flightListFilterConfig, () => ({
            shape: {
                statuses: {
                    hidden: !userPermissions.has(flightListPermissions.statusFilter),
                },
                origin: {
                    items: airportList.items,
                },
                destination: {
                    items: airportList.items,
                },
            },
        })],
        onChange: (v) => {
            dispatch(actions.setFilterData(v));
        },
        validationEffects: [
            {
                target: ['departure', 'from'],
                dependent: [
                    ['departure', 'to'],
                ],
            },
        ],
        hash: hashRef.current,
    });

    const flightListFilterProps: FlightListFilterProps = {
        field,
        cancelledCount: cancelledFlightList.items.length,
        submit: () => {
            dispatch(actions.getFlightList());
        },
        reset: () => {
            dispatch(actions.resetFilterData());
            hashRef.current = {};
        },
    };

    return (
        <FlightListFilter {...flightListFilterProps} />
    );
};
