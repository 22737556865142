import { ReservationSnapshot } from '@malesia/react-components/dist/src/components/Reservation/HistoryJson';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.reservationHistoryPage || initialState;

export const selectLoadingStatus = createSelector(
    [selectDomain],
    root => root.loadingStatus,
);

export const selectReservation = createSelector(
    [selectDomain],
    root => root.reservation,
);

export const selectReservationSnapshotList = createSelector(
    [selectDomain],
    root => root.reservationSnapshotList,
);

export const selectReservationSnapshots = createSelector(
    [selectDomain],
    root => root.reservationSnapshotList.items.length > 0
        ? root.reservationSnapshotList.items.map(item => ({
            snapshot: item.snapshot,
            version: item.version,
            createdAt: item.datetime,
            user: item.user ?? undefined,
        } as ReservationSnapshot)) : [],
);
