import { init, makeXHRTransport, makeFetchTransport } from '@sentry/browser';
import { CaptureConsole, ExtraErrorData } from '@sentry/integrations';
import { BrowserTracing } from '@sentry/tracing';
import { supportsFetch } from '@sentry/utils';

const originalTransport = supportsFetch() ? makeFetchTransport : makeXHRTransport;

const transport = (options) => {
    return originalTransport({
        ...options,
        // masking sentry_key because adblockers use it for blocking sentry
        url: options.url.replace(/sentry_key/, 'xauth'),
    });
};

export function initSentry(serverHostname: string): void {
    if (!isSentryEnabled()) {
        return;
    }
    init({
        transport,
        dsn: `https://token@${serverHostname}/_report/1`,
        release: window.config.REACT_APP_RELEASE_VERSION,
        integrations: [
            new ExtraErrorData({ depth: 10 }),
            new BrowserTracing(),
            new CaptureConsole({
                levels: ['error'],
            }),
        ],
        tracesSampleRate: 1.0,
        environment: serverHostname,
    });
}

function isSentryEnabled() {
    return window.config.REACT_APP_SENTRY_ENABLED;
}
