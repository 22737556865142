import { ApiCallEntityLogFilters, EntityLogEntityTypeEntireList, EntityLogOperationTypeEntireList, EntityLogPaginatedList } from '@malesia/json-schema';
import { call } from 'typed-redux-saga';
import { apiClientRequest } from './ApiClient';

export function* getApiEntityLog(requestPayload: ApiCallEntityLogFilters) {
    const response: EntityLogPaginatedList = yield* call(apiClientRequest, {
        requestId: 'entityLogList',
        requestPayload,
    });
    return response;
}

export function* getApiEntityTypeList() {
    const response: EntityLogEntityTypeEntireList = yield* call(apiClientRequest, {
        requestId: 'entityLogEntityTypeList',
    });
    return response;
}

export function* getApiOperationTypeList() {
    const response: EntityLogOperationTypeEntireList = yield* call(apiClientRequest, {
        requestId: 'entityLogOperationTypeList',
    });
    return response;
}
