import { ReservationListCustomerFilter } from '@malesia/react-components/dist/src/components/Reservation/List/CustomerFilter';
import { ReservationListFilter, ReservationListFilterProps, reservationListFilterConfig } from '@malesia/react-components/dist/src/components/Reservation/List/Filter';
import { ReservationListFilterData } from '@malesia/react-components/dist/src/components/Reservation/List/Filter/types';
import { ValueConfig, useValueForm } from '@malesia/react-components/dist/src/hooks/valueForm';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectReservationOwnerList, selectReservationOwnerQuery, selectReservationReservatorList, selectReservationReservatorQuery } from '../../../containers/AuxiliaryData/selectors';
import { auxiliaryActions } from '../../../containers/AuxiliaryData/slice';
import { reservationListPermissions } from '../../../permissions/adminPages/reservation/reservationList';
import { useUserPermissions } from '../../../permissions/useUserPermissions';
import { getReservationListHiddenFilters } from '../../../services/Reservation/listUtils';
import { selectFilterData } from '../store/selectors';
import { actions } from '../store/slice';

export const ReservationListFilterWithStore: SFC = () => {
    const value = useSelector(selectFilterData);
    const reservatorQuery = useSelector(selectReservationReservatorQuery);
    const reservatorList = useSelector(selectReservationReservatorList);
    const ownerQuery = useSelector(selectReservationOwnerQuery);
    const ownerList = useSelector(selectReservationOwnerList);

    const dispatch = useDispatch();
    const userPermissions = useUserPermissions();
    const hiddenFilters = getReservationListHiddenFilters(userPermissions);

    useEffect(() => {
        if (userPermissions.has(reservationListPermissions.reservatorFilter)) {
            dispatch(auxiliaryActions.searchReservationReservatorList(''));
        }
        if (userPermissions.has(reservationListPermissions.ownerFilter)) {
            dispatch(auxiliaryActions.searchReservationOwnerList(''));
        }
    }, [userPermissions, dispatch]);

    const hashRef = useRef({});

    const {
        field,
    } = useValueForm({
        value,
        config: [reservationListFilterConfig, (): ValueConfig<ReservationListFilterData> => ({
            shape: {
                query: {
                    hidden: hiddenFilters.query,
                },
                isActive: {
                    hidden: hiddenFilters.isActive,
                },
                reservationStatus: {
                    hidden: hiddenFilters.reservationStatus,
                },
                paymentStatus: {
                    hidden: hiddenFilters.paymentStatus,
                },
                flightDateInterval: {
                    hidden: hiddenFilters.flightDateInterval,
                },
                createdAtInterval: {
                    hidden: hiddenFilters.createdAtInterval,
                },
                paymentDateInterval: {
                    hidden: true, // hidden in MAL-314,
                },
                reservator: {
                    hidden: hiddenFilters.reservatorId,
                    items: reservatorList,
                    query: reservatorQuery,
                    setQuery: (value) => {
                        dispatch(auxiliaryActions.searchReservationReservatorList(value));
                    },
                },
                owner: {
                    hidden: hiddenFilters.ownerId,
                    items: ownerList,
                    query: ownerQuery,
                    setQuery: (value) => {
                        dispatch(auxiliaryActions.searchReservationOwnerList(value));
                    },
                },
            },
        })],
        onChange: (v) => {
            dispatch(actions.setFilterData(v));
        },
        validationEffects: [
            {
                target: ['createdAtInterval', 'from'],
                dependent: [
                    ['createdAtInterval', 'to'],
                ],
            },
            {
                target: ['paymentDateInterval', 'from'],
                dependent: [
                    ['paymentDateInterval', 'to'],
                ],
            },
        ],
        hash: hashRef.current,
    });

    const reservationListProps: ReservationListFilterProps = {
        field,
        submit: () => {
            dispatch(actions.getReservationList());
        },
        reset: () => {
            dispatch(actions.resetFilterData());
            if (userPermissions.has(reservationListPermissions.reservatorFilter)) {
                dispatch(auxiliaryActions.searchReservationReservatorList(''));
            }
            if (userPermissions.has(reservationListPermissions.ownerFilter)) {
                dispatch(auxiliaryActions.searchReservationOwnerList(''));
            }
            hashRef.current = {};
        },
    };

    return (
        userPermissions.userType === 'customer'
            ? <ReservationListCustomerFilter {...reservationListProps} />
            : <ReservationListFilter {...reservationListProps} />
    );
};
