import { all, call, put, select, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../utils/log';
import { getApiAirportList } from '../../services/airport';
import { getApiErrorMessage } from '../../services/ApiClient';
import { getApiAdminCountyList, getApiPublicCountyList, sortFavoriteCountries } from '../../services/country';
import { getApiFlightsMap } from '../../services/flightsMap';
import { appNotification } from '../App/appNotification';
import { selectAdminCountryList, selectAirportList, selectPublicCountryList } from './selectors';
import { sharedActions } from './slice';

function* getAirportList() {
    try {
        const prevAirportList = yield* select(selectAirportList);
        if (prevAirportList.length > 0) return;

        const [airportList, airportAvailabilityMap] = yield* all([
            call(getApiAirportList),
            call(getApiFlightsMap),
        ] as const);
        yield* put(sharedActions.getAirportListSuccess({
            airportList,
            airportAvailabilityMap,
        }));
    }
    catch (error) {
        logError({
            error,
            target: 'SharedData.getAirportListData',
        });
        const message = getApiErrorMessage(error);
        yield* put(appNotification.unknownError(message));
        yield* put(sharedActions.getAirportListError());
    }
}

export function* getAirportListSaga() {
    yield* takeLatest(sharedActions.getAirportList, getAirportList);
}

function* getPublicCountryList() {
    const countryList = yield* select(selectPublicCountryList);
    if (countryList.items.length > 0) {
        yield* put(sharedActions.getPublicCountryListSuccess(countryList));
        return;
    }

    try {
        const result = yield* getApiPublicCountyList();
        result.items = sortFavoriteCountries(result.items);
        yield* put(sharedActions.getPublicCountryListSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'SharedData.getPublicCountryList',
        });
        const message = getApiErrorMessage(error);
        yield* put(appNotification.unknownError(message));
        yield* put(sharedActions.getPublicCountryListError());
    }
}

export function* getPublicCountryListSaga() {
    yield* takeLatest(sharedActions.getPublicCountryList, getPublicCountryList);
}

function* getAdminCountryList() {
    const countryList = yield* select(selectAdminCountryList);
    if (countryList.items.length > 0) {
        yield* put(sharedActions.getAdminCountryListSuccess(countryList));
        return;
    }

    try {
        const result = yield* getApiAdminCountyList();
        result.items = sortFavoriteCountries(result.items);
        yield* put(sharedActions.getAdminCountryListSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'SharedData.getAdminCountryList',
        });
        const message = getApiErrorMessage(error);
        yield* put(appNotification.unknownError(message));
        yield* put(sharedActions.getAdminCountryListError());
    }
}

export function* getAdminCountryListSaga() {
    yield* takeLatest(sharedActions.getAdminCountryList, getAdminCountryList);
}
