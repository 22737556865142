import { User, UserGroup } from '@malesia/json-schema';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../../utils/@reduxjs/toolkit';
import { RoleCode } from '../../../permissions/roleCode';
import { VirtualAccount } from '../../../services/user';

export type PermissionsPageState = {
    isLoading: boolean,
    userGroups: Record<number, UserGroup>,
    availableRoles: RoleCode[],
    activeUserGroupId?: number,
    currentRoles: RoleCode[],
    currentUser?: User,
    virtualAccounts: VirtualAccount[],
    currentVirtualAccounts: number[],
};

export const initialState: PermissionsPageState = {
    isLoading: false,
    userGroups: {},
    activeUserGroupId: undefined,
    availableRoles: [],
    currentRoles: [],
    virtualAccounts: [],
    currentVirtualAccounts: [],
};

const permissionsPageSlice = createSlice({
    name: 'permissionsPageState',
    initialState,
    reducers: {
        onLoad(state, _action: PayloadAction<number>) {
            state.isLoading = true;
        },
        onLoadSuccess(state) {
            state.isLoading = false;
        },
        onLoadError(state) {
            state.isLoading = false;
        },
        onSubmit(state) {
            state.isLoading = true;
        },
        onSubmitSuccess(state) {
            state.isLoading = false;
        },
        onSubmitError(state) {
            state.isLoading = false;
        },
        setUserGroups(state, action: PayloadAction<PermissionsPageState['userGroups']>) {
            state.userGroups = action.payload;
        },
        setAvailableRoles(state, action: PayloadAction<PermissionsPageState['availableRoles']>) {
            state.availableRoles = action.payload;
        },
        setCurrentRoles(state, action: PayloadAction<PermissionsPageState['currentRoles']>) {
            state.currentRoles = action.payload;
        },
        addCurrentRole(state, action: PayloadAction<RoleCode>) {
            const isExist = state.currentRoles.includes(action.payload);
            if (!isExist) state.currentRoles.push(action.payload);
        },
        deleteCurrentRole(state, action: PayloadAction<RoleCode>) {
            const index = state.currentRoles.indexOf(action.payload);
            if (index !== -1) state.currentRoles.splice(index, 1);
        },
        setActiveUserGroupId(state, action: PayloadAction<PermissionsPageState['activeUserGroupId']>) {
            state.activeUserGroupId = action.payload;
            if (action.payload) {
                const userGroup = state.userGroups[action.payload];
                state.currentVirtualAccounts = userGroup.virtualAccounts || [];
                state.currentRoles = userGroup.roles as RoleCode[];
            }
            else {
                state.currentRoles = [];
            }
        },
        setCurrentUser(state, action: PayloadAction<PermissionsPageState['currentUser']>) {
            state.currentUser = action.payload;
        },
        restore(state) {
            if (state.activeUserGroupId)
                state.currentRoles = state.userGroups[state.activeUserGroupId].roles as RoleCode[];
        },
        setVirtualAccounts(state, action: PayloadAction<PermissionsPageState['virtualAccounts']>) {
            state.virtualAccounts = action.payload;
        },
        setCurrentVirtualAccounts(state, action: PayloadAction<PermissionsPageState['currentVirtualAccounts']>) {
            state.currentVirtualAccounts = action.payload;
        },
        addCurrentVirtualAccount(state, action: PayloadAction<number>) {
            const isExist = state.currentVirtualAccounts.includes(action.payload);
            if (!isExist) state.currentVirtualAccounts.push(action.payload);
        },
        deleteCurrentVirtualAccount(state, action: PayloadAction<number>) {
            const index = state.currentVirtualAccounts.indexOf(action.payload);
            if (index !== -1) state.currentVirtualAccounts.splice(index, 1);
        },
    },

});

export const {
    actions: permissionsPageActions,
    reducer: permissionsPageReducer,
    name: permissionsPageSliceName,
} = permissionsPageSlice;
