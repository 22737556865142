import { UserType } from '@malesia/json-schema';
import { PermissionExpression } from './permissionExpression';
import { RoleCode } from './roleCode';

const hasRole = (userRoles: RoleCode[], permission: RoleCode) => (
    userRoles.includes(permission)
);
const hasPermission = (userRoles: RoleCode[], permissionExpression: PermissionExpression) => {
    if (permissionExpression === undefined) return false;
    if (typeof permissionExpression === 'string') return hasRole(userRoles, permissionExpression);
    if (permissionExpression.type === 'anyone') return true;
    if (permissionExpression.type === 'some') {
        return permissionExpression.permissions.some(x => hasPermission(userRoles, x));
    }
    if (permissionExpression.type === 'every') {
        return permissionExpression.permissions.every(x => hasPermission(userRoles, x));
    }
    return false;
};
const hasRoleAdmin = (userRoles: RoleCode[]): boolean => (
    userRoles.includes('ROLE_ADMIN')
);
const hasAdminSiteAccess = (userRoles: RoleCode[], userType?: UserType) => {
    if (userType === 'manager') return true;
    if (userType === 'agent') return true;
    if (userType === 'customer') return false;
    if (hasRoleAdmin(userRoles)) return true;
    return false;
};

export type UserPermissions = {
    userRoles: RoleCode[],
    isRoleAdmin: boolean,
    isAdminSiteAccess: boolean,
    userType: UserType | undefined,
    userId: number | undefined,
    has: (permission: PermissionExpression) => boolean,
};
export const createUserPermissions = (
    userRoles: RoleCode[],
    userType: UserType | undefined,
    userId: number | undefined,
): UserPermissions => {
    const isRoleAdmin = hasRoleAdmin(userRoles);
    const isAdminSiteAccess = hasAdminSiteAccess(userRoles, userType);
    const userPermissions: UserPermissions = {
        userRoles,
        isRoleAdmin,
        isAdminSiteAccess,
        userType: userType ?? 'customer',
        userId,
        has: (permission) => {
            if (isRoleAdmin) return true;
            const result = hasPermission(userRoles, permission);
            return result;
        },
    };
    return userPermissions;
};
