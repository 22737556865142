import { debounce, put, select, takeLatest, throttle } from 'typed-redux-saga';
import { propertyToCountryMap } from '../../../config/appConfig';
import { logError } from '../../../utils/log';
import { GetAgentListParams, getApiAgentList } from '../../services/agent';
import { getApiErrorMessage } from '../../services/ApiClient';
import { agentListNotifications } from './notification';
import { selectInitialized, selectPagination, selectFilterData } from './selectors';
import { actions } from './slice';
import { AgentListInitialized } from './types';

export function* getAgentList() {
    try {
        const initialized: AgentListInitialized = yield* select(selectInitialized);
        const can = initialized.sorting;
        if (!can) return;

        const filterData = yield* select(selectFilterData);
        const pagination = yield* select(selectPagination);

        const countries = Object.keys(filterData)
            .map(property => (filterData[property] && propertyToCountryMap[property]) || undefined)
            .filter(value => !!value);

        const params: GetAgentListParams = {
            query: filterData.searchText,
            isActive: filterData.isActiveOnly === true ? true : undefined,
            sortBy: filterData.sortBy,
            sortOrder: filterData.sortOrder,
            page: pagination.page,
            pageSize: pagination.pageSize,
            countries,
        };
        const result = yield* getApiAgentList(params);
        yield* put(actions.getAgentListSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'AgentListPage.getAgentList',
        });
        yield* put(actions.getAgentListError());
        const message = getApiErrorMessage(error);
        yield* put(agentListNotifications.unknownError(message));
    }
}

export function* getAgentListSaga() {
    yield* takeLatest(actions.getAgentList, getAgentList);

    function* requestGetFlightPassengerList() {
        yield* put(actions.getAgentList());
    }

    yield* throttle(1000, [
        actions.setPage,
        actions.setSorting,
    ], requestGetFlightPassengerList);

    yield* debounce(1000, [
        actions.updateSearch,
        actions.setPageSize,
    ], requestGetFlightPassengerList);
}
