import { AllotmentInvoiceListItem, AllotmentInvoicePaginatedList, AllotmentInvoicePostRequest, AllotmentInvoicePostResponse, AllotmentInvoicePutRequest, AllotmentInvoicePutResponse, ApiCallAllotmentInvoiceFilters } from '@malesia/json-schema';
import { call, select } from 'typed-redux-saga';
import { selectLocale } from '../containers/App/selectors';
import { allotmentInvoiceListPermissions } from '../permissions/adminPages/allotment/allotmentInvoiceList';
import { selectUserPermissions } from '../permissions/selectUserPermissions';
import { apiClientRequest } from './ApiClient';

export function* getApiAllotmentInvoiceList(requestPayload: ApiCallAllotmentInvoiceFilters) {
    const response: AllotmentInvoicePaginatedList = yield* call(apiClientRequest, {
        requestId: 'allotmentInvoiceList',
        requestPayload,
    });
    return response;
}

export function* getApiAgentAllotmentInvoiceList(requestPayload: ApiCallAllotmentInvoiceFilters) {
    const response: AllotmentInvoicePaginatedList = yield* call(apiClientRequest, {
        requestId: 'agentAllotmentInvoiceList',
        requestPayload,
    });
    return response;
}

export function* getApiPermittedAllotmentInvoiceList(requestPayload: ApiCallAllotmentInvoiceFilters) {
    const userPermissions = yield* selectUserPermissions();

    if (userPermissions.has(allotmentInvoiceListPermissions.getList)) {
        return yield* getApiAllotmentInvoiceList(requestPayload);
    }
    if (userPermissions.has(allotmentInvoiceListPermissions.getAgentList)) {
        return yield* getApiAgentAllotmentInvoiceList(requestPayload);
    }
    throw new Error('Can not get allotment invoice list without permissions');
}

export function* createApiAllotmentInvoice(requestPayload: AllotmentInvoicePostRequest) {
    const response: AllotmentInvoicePostResponse = yield* call(apiClientRequest, {
        requestId: 'allotmentInvoiceCreate',
        requestPayload,
    });
    return response;
}

export function* updateApiAllotmentInvoice(invoiceId: number, requestPayload: AllotmentInvoicePutRequest) {
    const response: AllotmentInvoicePutResponse = yield* call(apiClientRequest, {
        requestId: 'allotmentInvoiceUpdate',
        requestPayload,
        uriParams: { invoiceId },
    });
    return response;
}

export function* deleteApiAllotmentInvoice(invoiceId: number) {
    yield* call(apiClientRequest, {
        requestId: 'allotmentInvoiceDelete',
        uriParams: { invoiceId },
    });
}

export function* getApiAllotmentInvoicePdf(invoiceId: number) {
    const locale = yield* select(selectLocale);

    const response = yield* call(apiClientRequest, {
        requestId: 'allotmentInvoicePdf',
        uriParams: { invoiceId, locale },
    });
    return response;
}

export function* getApiAgentAllotmentInvoicePdf(invoiceId: number) {
    const locale = yield* select(selectLocale);

    const response = yield* call(apiClientRequest, {
        requestId: 'agentAllotmentInvoicePdf',
        uriParams: { invoiceId, locale },
    });
    return response;
}

export function* getApiPermittedAllotmentInvoicePdf(invoiceId: number) {
    const userPermissions = yield* selectUserPermissions();

    if (userPermissions.has(allotmentInvoiceListPermissions.getList)) {
        return yield* getApiAllotmentInvoicePdf(invoiceId);
    }
    if (userPermissions.has(allotmentInvoiceListPermissions.getAgentList)) {
        return yield* getApiAgentAllotmentInvoicePdf(invoiceId);
    }
    throw new Error('Can not get allotment invoice pdf without permissions');
}

export function* getApiAllotmentAgentBalance(agentId: number) {
    const response: number = yield* call(apiClientRequest, {
        requestId: 'allotmentAgentBalance',
        uriParams: { agentId },
    });
    return response;
}

export function* getApiAgentAllotmentAgentBalance() {
    const response: number = yield* call(apiClientRequest, {
        requestId: 'agentAllotmentAgentBalance',
    });
    return response;
}

export function* getApiAllotmentInvoice(allotmentInvoiceId: number) {
    const response: AllotmentInvoiceListItem = yield* call(apiClientRequest, {
        requestId: 'allotmentInvoice',
        uriParams: { invoiceId: allotmentInvoiceId },
    });
    return response;
}
