import { AdminPaymentFormData } from '@malesia/react-components/dist/src/components/Reservation/AdminPayment';
import { PaymentTermsConfig } from '@malesia/react-components/dist/src/components/Reservation/AdminPayment/components/SelectPayment';
import { FormConfig } from '@malesia/react-components/dist/src/hooks/useForm';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { reservationNewPermissions } from '../../../../permissions/adminPages/reservation/reservationNew';
import { useUserPermissions } from '../../../../permissions/useUserPermissions';
import { useReservationAllowed } from '../../permissions';
import { selectMode } from '../../store/selectors';

export const useConfig = (): FormConfig<AdminPaymentFormData> => {
    const mode = useSelector(selectMode);
    const allowed = useReservationAllowed();
    const userPermissions = useUserPermissions();

    const formConfig = useMemo((): FormConfig<AdminPaymentFormData> => {
        return {
            specificPrice: {
                disabled: !allowed.payment.specificPrice.changeable,
                hidden: !allowed.payment.specificPrice.visible,
            },
            hidePrice: {
                disabled: !userPermissions.has(reservationNewPermissions.hidePriceFlag),
                hidden: !userPermissions.has(reservationNewPermissions.hidePriceFlag),
            },
            updateFee: {
                // Remove hardcode `true`. Hidden temporary https://mywork.xiag.ch/search#89599
                hidden: !userPermissions.has(reservationNewPermissions.updateFee) || mode === 'new' || true,
            },
        };
    }, [mode, userPermissions, allowed]);

    return formConfig;
};

export const usePaymentTermsConfig = () => {
    const userPermissions = useUserPermissions();
    const allowed = useReservationAllowed();
    const mode = useSelector(selectMode);

    const paymentTermsConfig = useMemo<PaymentTermsConfig>(() => ({
        mode,
        later: {
            disabled: !allowed.payment.pay.later.changeable,
            hidden: !allowed.payment.pay.later.visible,
        },
        airport: {
            hidden: !userPermissions.has(reservationNewPermissions.paymentTermsAirport),
        },
        bill: {
            hidden: !userPermissions.has(reservationNewPermissions.paymentTermsBill),
        },
    }), [userPermissions, mode, allowed]);

    return paymentTermsConfig;
};
