import * as queryString from 'query-string';
import { call, debounce, put, select, takeLatest, throttle } from 'typed-redux-saga';
import { logError } from '../../../utils/log';
import { managerListNotifications } from './notification';
import { selectInitialized, selectFilterData, selectPagination } from './selectors';
import { actions } from './slice';
import { apiClientRequest, getApiErrorMessage } from 'app/services/ApiClient';

export function* getManagerList() {
    try {
        const initialized = yield* select(selectInitialized);
        const can = initialized.sorting;
        if (!can) return;

        const filterData = yield* select(selectFilterData);
        const pagination = yield* select(selectPagination);

        const params = {
            query: filterData.searchText,
            isActive: filterData.isActiveOnly === true ? true : undefined,
            sortBy: filterData.sortBy,
            sortOrder: filterData.sortOrder,
            page: pagination.page,
            pageSize: pagination.pageSize,
        };
        const requestData = {
            requestId: 'managerList',
            query: queryString.stringify(params, {
                skipNull: true,
                sort: false,
            }),
        };

        const result = yield* call(apiClientRequest, requestData);
        yield* put(actions.getManagerListSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'ManagerListPage.getManagerList',
        });
        yield* put(actions.getManagerListError());
        const message = getApiErrorMessage(error);
        yield* put(managerListNotifications.unknownError(message));
    }
}

export function* getManagerListSaga() {
    yield* takeLatest(actions.getManagerList, getManagerList);

    function* requestGetManagerList() {
        yield* put(actions.getManagerList());
    }

    yield* throttle(1000, [
        actions.setPage,
        actions.setSorting,
    ], requestGetManagerList);

    yield* debounce(1000, [
        actions.updateFilterData,
        actions.setPageSize,
    ], requestGetManagerList);
}
