import React from 'react';
import { FormattedMessage } from 'react-intl';
import { appNotification } from '../../../containers/App/appNotification';
import { createLocalNotification } from '../../../containers/App/utils';

const notification = createLocalNotification('NewTransaction', {
    error: (
        <FormattedMessage
            id='front-app/NewTransaction/Error:title'
            defaultMessage='New transaction Error'
        />
    ),
    warning: (
        <FormattedMessage
            id='front-app/NewTransaction/Warning:title'
            defaultMessage='New transaction warning'
        />
    ),
    info: (
        <FormattedMessage
            id='front-app/NewTransaction/Info:title'
            defaultMessage='New transaction Info'
        />
    ),
    success: (
        <FormattedMessage
            id='front-app/NewTransaction/Info:title'
            defaultMessage='New transaction Info'
        />
    ),
});

export const newTransactionNotifications = {
    paymentDone: notification.success((
        <FormattedMessage
            id='front-app/NewTransaction/Info:PaymentDone'
            defaultMessage='Payment done'
        />
    )),
    unknownError: (details?: JSX.Element | string) => (
        notification.error(appNotification.texts.unknownError(details))
    ),
};
