import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.entityLogListPage || initialState;

export const selectFilterData = createSelector(
    [selectDomain],
    state => state.filterData,
);

export const selectList = createSelector(
    [selectDomain],
    state => state.table.list,
);

export const selectPagination = createSelector(
    [selectDomain],
    state => state.table.pagination,
);

export const selectSorting = createSelector(
    [selectDomain],
    state => state.table.sorting,
);

export const selectInitialized = createSelector(
    [selectDomain],
    state => state.table.initialized,
);

export const selectDirty = createSelector(
    [selectDomain],
    state => state.table.dirty,
);

export const selectLoading = createSelector(
    [selectDomain],
    state => state.table.loading,
);

export const selectEntityTypeList = createSelector(
    [selectDomain],
    state => state.entityTypeList,
);

export const selectOperationTypeList = createSelector(
    [selectDomain],
    state => state.operationTypeList,
);
