import { FlightDelayNotifyingSettings } from '@malesia/react-components/dist/src/components/Flight/DelayNotifyingSettings';
import { PopupConfirm, PopupConfirmProps } from '@malesia/react-components/dist/src/components/Popup/Confirm';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsShowNotifyingPopup, selectNotifying } from '../selectors';
import { actions } from '../slice';

export function NotifyingPopup(): ReactElement | null {
    const isShowNotifyingPopup = useSelector(selectIsShowNotifyingPopup);
    const notifying = useSelector(selectNotifying);

    const dispatch = useDispatch();

    const popupConfirmProps: PopupConfirmProps = {
        isOpened: isShowNotifyingPopup,
        onConfirm: () => {
            dispatch(actions.askNotifyingConfirm());
        },
        onClose: () => {
            dispatch(actions.askNotifyingCancel());
        },
        confirmMessage: (
            <FormattedMessage
                id='front-app/FlightPassengerListPage:ConfirmNotifying'
                defaultMessage='Would you like to inform your passengers about this change?'
            />
        ),
    };
    return (
        <PopupConfirm {...popupConfirmProps}>
            <FlightDelayNotifyingSettings
                notifying={notifying}
                onChange={(data) => dispatch(actions.updateNotifying(data))}
            />
        </PopupConfirm>
    );
}
