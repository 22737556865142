import { PageTabs } from '@malesia/react-components/dist/src/components/Page/Tabs';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Link,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { useTabsState } from '../../containers/Routes/tabsState/useTabsState';
import { useFilteredAdminRoutesByPermissions } from '../../permissions/useFilteredAdminRoutesByPermissions';
import { AllotmentInvoiceListPage } from '../AllotmentInvoiceListPage';
import { AllotmentListPage } from '../AllotmentListPage';
import { AllotmentPaymentPage } from '../AllotmentPaymentPage';
import { NotFoundPage } from '../NotFoundPage';

export const AllotmentRouterPage: SFC = () => {
    const { pathname } = location;

    const isRouteMatch = (linkRoute) => pathname === linkRoute;

    const tabsRoutes = useFilteredAdminRoutesByPermissions([
        {
            routeKey: 'allotmentList',
            linkRoute: allAdminRoutePaths.allotmentList,
            LinkComponent: (props: React.PropsWithChildren<unknown>) => (
                <Link to={allAdminRoutePaths.allotmentList}>
                    {React.Children.only(props.children)}
                </Link>
            ),
            linkSubject: (
                <FormattedMessage
                    id='front-app/AllotmentRouterPage/Allotments:Title'
                    defaultMessage='Allotments'
                />
            ),
        },
        {
            routeKey: 'allotmentInvoiceList',
            linkRoute: allAdminRoutePaths.allotmentInvoiceList,
            LinkComponent: (props: React.PropsWithChildren<unknown>) => (
                <Link to={allAdminRoutePaths.allotmentInvoiceList}>
                    {React.Children.only(props.children)}
                </Link>
            ),
            linkSubject: (
                <FormattedMessage
                    id='front-app/AllotmentRouterPage/AllotmentInvoices:Title'
                    defaultMessage='Allotment Invoices'
                />
            ),
        },
    ]);

    const { firstRoute } = useTabsState(allAdminRoutePaths.allotment, tabsRoutes);

    return (
        <PageTabs
            isRouteMatch={isRouteMatch}
            routeItems={tabsRoutes}
        >
            <Switch>
                <Route
                    exact
                    path={allAdminRoutePaths.allotmentList}
                    component={AllotmentListPage}
                />
                <Route
                    exact
                    path={allAdminRoutePaths.allotmentInvoiceList}
                    component={AllotmentInvoiceListPage}
                />
                <Route
                    exact
                    path={allAdminRoutePaths.allotmentPayment}
                    component={AllotmentPaymentPage}
                />
                <Route
                    exact
                    path={allAdminRoutePaths.allotment}
                >
                    <Redirect to={firstRoute} />
                </Route>
                <Route component={NotFoundPage} />
            </Switch>
        </PageTabs>
    );
};
