import { RouteItem } from '@malesia/react-components/dist/src/components/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useInjectReducer } from '../../../../utils/redux-injectors';
import {
    selectTabsState,
    tabsStateActions,
    tabsStateReducer,
} from './tabsState.store';

export const useTabsState = (rootRoute: string, routeItems: RouteItem[]) => {
    useInjectReducer({ key: 'tabsState', reducer: tabsStateReducer });

    const { pathname } = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const state = useSelector(
        selectTabsState,
        (prev, current) => prev[rootRoute] === current[rootRoute],
    );
    const lastRoute = state[rootRoute];

    useEffect(() => {
        const tabRoutes = routeItems.map(t => t.linkRoute ?? '/');
        const isTabRoute = tabRoutes.includes(pathname);
        if (isTabRoute && lastRoute !== pathname) {
            dispatch(tabsStateActions.add({ [rootRoute]: pathname }));
        }
        if (!!lastRoute && pathname === rootRoute) {
            if (!!lastRoute) {
                history.push(lastRoute);
            }
        }
    }, [dispatch, history, lastRoute, pathname, rootRoute, routeItems]);

    return { firstRoute: routeItems[0]?.linkRoute ?? '/' };
};
