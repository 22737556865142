import { ManagerFormType } from '@malesia/react-components/dist/src/components/Manager/Edit';
import { LegacyFormConfig } from '@malesia/react-components/dist/src/hooks/useForm';
import { useMemo } from 'react';

type Config = LegacyFormConfig<ManagerFormType>;
const createConfig = (): Config => ({
    id: { initialValue: undefined },
    email: { initialValue: '', modifiers: ['required'] },
    password: { initialValue: '', modifiers: ['required'] },
    passwordConfirm: { initialValue: '', modifiers: ['required'] },
    displayName: { initialValue: '', modifiers: ['required'] },
    country: { initialValue: undefined, modifiers: ['required'] },
    language: { initialValue: '', modifiers: ['required'] },
    creditLimitTotalAmount: { initialValue: 0 },
    isActive: { initialValue: true },
});

export const useManagerFormConfig = (): Config => {
    const result = useMemo<Config>(() => {
        const config = createConfig();
        return config;
    }, []);
    return result;
};
