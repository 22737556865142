import { PassengersList } from '@malesia/react-components/dist/src/components/Reservation/PassengersList';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import { validatePassengersAges } from '@malesia/react-components/dist/src/utils/reservation';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reservationNotifications } from '../../../../../utils/reservation/notifyReservationError';
import { getPassengersAgesByList } from '../../../../../utils/reservation/passengerUtils';
import { usePassengerForm } from '../../../../../utils/reservation/usePassengerForm';
import { selectLocale } from '../../../../containers/App/selectors';
import { selectOptionList } from '../../../../containers/AuxiliaryData/selectors';
import { auxiliaryActions } from '../../../../containers/AuxiliaryData/slice';
import { SamePassengersPopup } from '../../../../containers/ReservationSamePassengers/SamePassengersPopup';
import { reservationNewPermissions } from '../../../../permissions/adminPages/reservation/reservationNew';
import { useUserPermissions } from '../../../../permissions/useUserPermissions';
import { useInjectStepSagas } from '../../../BookingFlightPage/Steps/StepPassengers/inject';
import { useStartFetchOptionsList } from '../../../BookingFlightPage/Steps/StepPassengers/useStartFetchOptionsList';
import { useValidators } from '../../../BookingFlightPage/Steps/StepPassengers/useValidators';
import { useReservationAllowed } from '../../permissions';
import {
    selectIsCostDirty,
    selectPassengers,
    selectRemovedOptionList,
    selectSelectedFlightVariants,
} from '../../store/selectors';
import { reservationNewPageActions } from '../../store/slice';
import { SelectPassenger } from './SelectPassenger';

const className = 'step-passengers';

export type StepPassengersProps = {
    validateFormRef: React.MutableRefObject<() => boolean>,
};

export const StepPassengers: SFC<StepPassengersProps> = (props) => {
    const { validateFormRef } = props;

    useInjectStepSagas();

    const dispatch = useDispatch();
    const userPermissions = useUserPermissions();
    const allowed = useReservationAllowed();

    const removedOptionList = useSelector(selectRemovedOptionList);
    const selectedFlights = useSelector(selectSelectedFlightVariants);
    const isCostDirty = useSelector(selectIsCostDirty);
    useStartFetchOptionsList(
        selectedFlights,
        auxiliaryActions.getOptionsList,
    );

    const {
        setFormErrors,
        passengersDataWithErrors,
        passengersData,
        handleFormDataChange,
        handleAddPassengerClick,
        handleRemovePassengerClick,
        handlePassengerOptionChange,
    } = usePassengerForm({
        localeSelector: selectLocale,
        passengersSelector: selectPassengers,
        selectOptionList,
        removedOptionList,
        updatePassengerAtPositionAction: reservationNewPageActions.updatePassengerAtPosition,
        addPassengerAction: reservationNewPageActions.addPassenger,
        removePassengerAtPositionAction: reservationNewPageActions.removePassengerAtPosition,
        setPassengerOptionsAction: reservationNewPageActions.setPassengerOptions,
    });

    const { validateForm } = useValidators(
        setFormErrors,
        'file://malesiareisen.com/json/schema/project/react-front-app/pages/BookingFlightPage/Steps/StepPassengers/passengerListItemForm.json',
    );

    const handlePassengersListConfirm = () => {
        const isValid = validateForm(passengersData);
        if (!isValid) return false;

        const agesError = validatePassengersAges(getPassengersAgesByList(passengersData.map(x => x.formData)));
        if (agesError) {
            dispatch(reservationNotifications.customError(agesError));
            return false;
        }

        return true;
    };

    if (validateFormRef) {
        validateFormRef.current = handlePassengersListConfirm;
    }

    const canSelectPassenger = allowed.passenger.edit;
    const selectPassenger = canSelectPassenger ? <SelectPassenger /> : undefined;

    const canCheckSamePassengers = userPermissions.has(reservationNewPermissions.checkSamePassengers);

    return (
        <div className={className}>
            {canCheckSamePassengers && (
                <SamePassengersPopup />
            )}
            <PassengersList
                headerSlot={selectPassenger}
                isSubmitButtonDisabled={false}
                onAddPassengerClick={handleAddPassengerClick}
                onPassengerDataChange={handleFormDataChange}
                onPassengerOptionChange={handlePassengerOptionChange}
                onPassengersListConfirm={handlePassengersListConfirm}
                onRemovePassengerClick={handleRemovePassengerClick}
                passengersData={passengersDataWithErrors}
                isSubmitButtonHidden={true}
                isAgeTypeLoading={isCostDirty}
                disabled={!allowed.passenger.edit}
            />
        </div>
    );
};
