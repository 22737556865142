import { getTopImage } from '../getTopImage';
import { TermsPageDocument, TermsPageQuery } from '../gql-types';
import { gqlClient } from '../gqlClient';

export async function loadTermsPageContent(locale: string) {
    const { data } = await gqlClient.query<TermsPageQuery>(
        TermsPageDocument,
        { lang: locale },
    ).toPromise();
    return {
        topImage: getTopImage(data?.terms_page?.top_image),
        content: data?.terms_page?.translations?.[0]?.content ?? '',
    };
}
