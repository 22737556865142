import { ReservationInvoice } from '@malesia/json-schema';
import { BackLink } from '@malesia/react-components/dist/src/components/BackLink';
import { LoadingForm } from '@malesia/react-components/dist/src/components/LoadingOverlay/Form';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { PaymentForm, PaymentFormProps, paymentFormConfig } from '@malesia/react-components/dist/src/components/Payment/Form';
import { AdminAddReservationLayout } from '@malesia/react-components/dist/src/components/Reservation/AdminAddReservationLayout/AdminAddReservationLayout';
import { useValueForm } from '@malesia/react-components/dist/src/hooks/valueForm';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { backLinks } from '../../../containers/Routes/backLinks';
import { reservationPaymentPermissions } from '../../../permissions/adminPages/reservation/reservationPayment';
import { useUserPermissions } from '../../../permissions/useUserPermissions';
import {
    selectBankAccounts,
    selectCashAccounts,
    selectDatatransAccounts,
    selectTransferAccounts,
    selectPaymentLoading,
    selectPriceToPay,
    selectPaymentData,
    selectTransferTotal,
    selectReservation,
} from '../store/selectors';
import { reservationPaymentActions } from '../store/slice';
import { useValidatePaymentForm } from '../useValidatePaymentForm';

const paidStatuses: ReservationInvoice['status'][] = [
    'paid',
    'overpaid',
];
export const ReservationPaymentWithStore: SFC = () => {
    const dispatch = useDispatch();
    const value = useSelector(selectPaymentData);
    const cashAccounts = useSelector(selectCashAccounts);
    const bankAccounts = useSelector(selectBankAccounts);
    const datatransAccounts = useSelector(selectDatatransAccounts);
    const transferAccounts = useSelector(selectTransferAccounts);
    const priceToPay = useSelector(selectPriceToPay);
    const transferTotal = useSelector(selectTransferTotal);
    const reservation = useSelector(selectReservation);
    const paymentLoading = useSelector(selectPaymentLoading);
    const userPermissions = useUserPermissions();
    const validate = useValidatePaymentForm();

    const {
        field,
        isContainsError,
        validateAll,
    } = useValueForm({
        value,
        config: [paymentFormConfig, (data) => ({
            shape: {
                partialPayment: {
                    maxNumber: priceToPay,
                    hidden: !userPermissions.has(reservationPaymentPermissions.partialPayment),
                },
                cash: {
                    items: cashAccounts,
                    hidden: !userPermissions.has(reservationPaymentPermissions.cash) || !cashAccounts.length,
                },
                bank: {
                    items: bankAccounts,
                    hidden: !userPermissions.has(reservationPaymentPermissions.bank) || !bankAccounts.length,
                },
                datatrans: {
                    hidden: !userPermissions.has(reservationPaymentPermissions.datatrans) || !datatransAccounts.length,
                },
                transfer: {
                    items: transferAccounts,
                    hidden: !userPermissions.has(reservationPaymentPermissions.transfer) || !transferAccounts.length,
                },
                account: {
                    disabled: (
                        !userPermissions.has(reservationPaymentPermissions.partialPayment)
                            ? (data?.account ?? 0) < priceToPay
                            : (data?.account ?? 0) <= 0
                    ),
                    hidden: !userPermissions.has(reservationPaymentPermissions.account),
                },
            },
        })],
        onChange: (data) => {
            dispatch(reservationPaymentActions.setPaymentData(data));
        },
    });

    const paymentProps: PaymentFormProps = {
        priceToPay,
        transferTotal,
        field,
        confirm: () => {
            if (!validateAll()) return;
            if (!validate(value, transferTotal)) return;
            dispatch(reservationPaymentActions.createPayment());
        },
        isConfirmDisabled: value.paymentMethod === undefined || isContainsError,
    };

    return (
        <LoadingForm isLoading={paymentLoading}>
            <header>
                <PageTitle
                    text={
                        <FormattedMessage
                            id='front-app/ReservationPayment:PageTitle'
                            defaultMessage='Payment'
                        />
                    }
                />
                <BackLink to={backLinks.reservationPayment.back()} />
            </header>
            <br />
            <AdminAddReservationLayout>
                {!paidStatuses.includes(reservation.invoice?.status!) && (
                    <PaymentForm {...paymentProps} />
                )}
            </AdminAddReservationLayout>
        </LoadingForm>
    );
};
