import { AccountListItem, UserType } from '@malesia/json-schema';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { RoleCode } from '../../permissions/roleCode';
import { createUserPermissions, UserPermissions } from '../../permissions/userPermissions';
import { initialState } from './slice';

const selectDomain = (state: RootState) => {
    return state.userLoginContainer || initialState;
};

export const selectIsLoadingLogin = createSelector(
    [selectDomain],
    state => state.isLoadingLogin,
);

export const selectIsLoadingUserInfo = createSelector(
    [selectDomain],
    state => state.isLoadingUserData,
);

export const selectUserLoginAuthToken = createSelector(
    [selectDomain],
    state => state.authToken,
);

export const selectUserLoginIsAuthorized = createSelector(
    [selectDomain],
    state => (
        !!state.authToken && !state.isLoadingLogin
    ),
);

export const selectUserLoginId = createSelector(
    [selectDomain],
    state => !!state.userInfo ? state.userInfo.id : undefined,
);

export const selectUserLoginUserInfo = createSelector(
    [selectDomain],
    state => state.userInfo,
);

export const selectUserLoginAccount = createSelector(
    [selectDomain],
    state => state.account,
);

export const selectMyAccountToListItem = createSelector(
    [selectUserLoginUserInfo, selectUserLoginAccount],
    (info, account): AccountListItem => {
        const result: AccountListItem = {
            id: account?.id ?? 0,
            balance: account?.balance ?? 0,
            debitOnBalance: account?.debitOnBalance,
            type: account?.type,
            user: {
                id: info?.id!,
                type: account?.user?.type!,
                displayName: info?.displayName!,
                email: info?.email!,
                birthday: info?.birthday,
            },
        };
        return result;
    },
);

export type SelectedPermissionsData = {
    userRoles: RoleCode[],
    userType?: UserType,
    userId?: number,
};
export const selectPermissionsData = createSelector(
    [selectDomain],
    (state): SelectedPermissionsData => {
        const userRoles = state.userGroupRoles;
        const userType = state.userInfo?.type;
        const userId = state.userInfo?.id;
        return {
            userRoles,
            userType,
            userId,
        };
    },
);

export const selectNativeUserPermissions = createSelector(
    [selectPermissionsData],
    (data): UserPermissions => createUserPermissions(data.userRoles, data.userType, data.userId),
);
