import { all, call, put, select, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../../utils/log';
import { SeatMaps } from '../../../../utils/reservation/types';
import { selectSelectedFlightVariants } from '../../../containers/ReservationBasket/reservationBasket.selectors';
import { getApiFlightAircraftPassengersSeats, getApiPublicFlightsOnWeek } from '../../../services/flight';
import { bookingFlightActions } from './slice';

export function* getOutboundFlightList(action: ReturnType<typeof bookingFlightActions.getOutboundFlightList>) {
    try {
        const { payload } = action;
        const result = yield* call(getApiPublicFlightsOnWeek, payload);
        yield* put(bookingFlightActions.getOutboundFlightListSuccess(result ?? []));
        const searchPeriodDays = 7;
        yield* put(
            bookingFlightActions.setOutboundFlightActiveTab({
                outboundFlightListData: [],
                outboundFlightActiveTab: Math.floor(searchPeriodDays / 2),
            }),
        );
    }
    catch (error) {
        logError({
            error,
            target: 'BookingFlightPage.getOutboundFlightList',
        });
        yield* put(bookingFlightActions.getOutboundFlightListError());
    }
}

export function* getOutboundFlightListSaga() {
    yield* takeLatest(bookingFlightActions.getOutboundFlightList, getOutboundFlightList);
}

export function* getReturnFlightList(action: ReturnType<typeof bookingFlightActions.getReturnFlightList>) {
    try {
        const { payload } = action;
        const result = yield* call(getApiPublicFlightsOnWeek, payload);
        yield* put(bookingFlightActions.getReturnFlightListSuccess(result ?? []));
    }
    catch (error) {
        logError({
            error,
            target: 'BookingFlightPage.getReturnFlightList',
        });
        yield* put(bookingFlightActions.getReturnFlightListError());
    }
}

export function* getReturnFlightListSaga() {
    yield* takeLatest(bookingFlightActions.getReturnFlightList, getReturnFlightList);
}

export function* getSeatMaps() {
    try {
        const flightVariants = yield* select(selectSelectedFlightVariants);

        const result: SeatMaps = yield* all({
            outbound: getApiFlightAircraftPassengersSeats({
                flight: flightVariants.outbound?.flight,
                tariffId: flightVariants.outbound?.price.tariff?.id,
            }),
            ['return']: getApiFlightAircraftPassengersSeats({
                flight: flightVariants.return?.flight,
                tariffId: flightVariants.return?.price.tariff?.id,
            }),
        });
        yield* put(bookingFlightActions.setSeatMaps(result));
    }
    catch (error) {
        logError({
            error,
            target: 'BookingFlightPage.getSeatMaps',
        });
    }
}

export function* getSeatMapsSaga() {
    yield* takeLatest(bookingFlightActions.getSeatMaps, getSeatMaps);
}
