import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.cashbackPdf || initialState;

export const selectCashbackPdfState = createSelector(
    [selectDomain],
    state => state,
);
