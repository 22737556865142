import { FlightCompany } from '@malesia/json-schema';
import { BackLink } from '@malesia/react-components/dist/src/components/BackLink';
import { FlightCompanyEdit } from '@malesia/react-components/dist/src/components/Flight/Company/Edit';
import { LoadingPage } from '@malesia/react-components/dist/src/components/LoadingOverlay/Page';
import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { createFlightCompanySaga } from './saga';
import { selectLoading } from './selectors';
import { reducer, sliceKey, actions } from './slice';

export const FlightCompanyNewPage: SFC = () => {
    useInjectReducer({ actions, key: sliceKey, reducer });
    useInjectSaga({ key: sliceKey, saga: createFlightCompanySaga });

    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();

    const onSubmitForm = (data: FlightCompany) => {
        dispatch(actions.createFlightCompany(data));
    };

    return (
        <PageContentBlock>
            <PageTitle
                text={
                    <FormattedMessage
                        id='front-app/FlightCompanyNewPage:Title'
                        defaultMessage='Add Airline Company'
                    />
                }
            />
            <BackLink
                to={allAdminRoutePaths.flightCompanyList}
                modificators='stick-to-title'
            />
            <LoadingPage isLoading={loading} />
            {!loading && (
                <FlightCompanyEdit
                    onSubmit={onSubmitForm}
                />
            )}
        </PageContentBlock>
    );
};
