import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.flightOverview || initialState;

export const selectFlightOverview = createSelector(
    [selectDomain],
    malesiaHomePageState => malesiaHomePageState,
);

export const selectLoading = createSelector(
    [selectDomain],
    state => state.loading,
);

export const selectFlightColumns = createSelector(
    [selectDomain],
    state => state.columns,
);
