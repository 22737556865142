import { ApiCallFlightFilters, FlightBookingInfo, FlightBulkOperation, FlightStatus, FlightTurnoverInfo, SortingParams } from '@malesia/json-schema';
import { FlightListFilterData } from '@malesia/react-components/dist/src/components/Flight/List/Filter';
import { PaginationData } from '@malesia/react-components/dist/src/components/Pagination';
import * as queryString from 'query-string';
import { all, call, debounce, put, select, takeLatest, throttle } from 'typed-redux-saga';
import { downloadFile } from '../../../../utils/downloadFile';
import { logError } from '../../../../utils/log';
import { selectLocale } from '../../../containers/App/selectors';
import { flightListPermissions } from '../../../permissions/adminPages/flight/flightList';
import { applyPermissionDataFilter } from '../../../permissions/permissionDataFilter';
import { selectUserPermissions } from '../../../permissions/selectUserPermissions';
import { getApiAirportList } from '../../../services/airport';
import { apiClientRequest, getApiErrorMessage } from '../../../services/ApiClient';
import { getApiFlight, getApiPermittedFlightList } from '../../../services/flight';
import { getApiTariffGroupList } from '../../../services/tariffGroup';
import { flightListNotifications } from '../notification';
import { selectSelectedRows, selectFilterData, selectInitialized, selectPagination, selectSorting } from './selectors';
import { actions } from './slice';

export const getQueryParamsFromSearchForm = (
    filterData: FlightListFilterData,
    pagination: PaginationData,
    sorting: SortingParams,
): ApiCallFlightFilters => {
    const hasCreated = filterData.statuses?.includes('created');
    const hasClosed = filterData.statuses?.includes('closed');

    return {
        ...pagination,
        ...sorting,
        query: filterData.query,
        isActive: filterData.isActiveOnly === true ? true : undefined,
        ['status[]' as string]: [...filterData.statuses || [], ...(hasCreated && !hasClosed ? ['closed'] : [])],
        departureFrom: filterData.departure?.from || undefined,
        departureTo: filterData.departure?.to || undefined,
        origin: filterData.origin?.code ?? undefined,
        destination: filterData.destination?.code ?? undefined,
        ['weekday[]' as string]: filterData.weekdays,
        sequence: filterData.sequence,
    };
};

export const getQueryParamsFromBulkOperation = (
    bulkOperation: FlightBulkOperation,
) => {
    return {
    // TODO need to replace on `prepareQueryParamsForPHP(filterParams);`
        'ids[]': 'ids' in bulkOperation && bulkOperation.ids,
        'excluded': (bulkOperation as any).excluded,
        // ToDo: Use this lint instead 'bulkOperation.excluded' field thats every time undefined
        // 'excluded': 'exclude' in bulkOperation && bulkOperation.exclude,
    };
};

export function* getAirportList() {
    try {
        const result = yield* getApiAirportList();
        yield* put(actions.getAirportListSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'FlightListPage.getAirportList',
        });
        yield* put(actions.getAirportListError());
        const message = getApiErrorMessage(error);
        yield* put(flightListNotifications.unknownError(message));
    }
}

export function* getAirportListSaga() {
    yield* takeLatest(actions.getAirportList, getAirportList);
}

export function* getTariffGroupList() {
    try {
        const result = yield* getApiTariffGroupList();
        yield* put(actions.getTariffGroupListSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'FlightListPage.getTariffGroupList',
        });
        yield* put(actions.getTariffGroupListError());
        const message = getApiErrorMessage(error);
        yield* put(flightListNotifications.unknownError(message));
    }
}

export function* getTariffGroupListSaga() {
    yield* takeLatest(actions.getTariffGroupList, getTariffGroupList);
}

export function* getFlightList() {
    try {
        const initialized = yield* select(selectInitialized);
        const can = initialized.sorting;
        if (!can) return;

        const searchForm = yield* select(selectFilterData);
        const pagination = yield* select(selectPagination);
        const sorting = yield* select(selectSorting);
        const userPermissions = yield* selectUserPermissions();

        const getRequestId = () => {
            if (userPermissions.has(flightListPermissions.getFullList)) {
                return 'flightList';
            }
            if (userPermissions.has(flightListPermissions.getAgentList)) {
                return 'agentFlightList';
            }
            console.error('Can not get flight list without permissions');
            return '';
        };
        const requestId = getRequestId();

        const filteredPayload = applyPermissionDataFilter(searchForm, {
            isActiveOnly: userPermissions.has(flightListPermissions.activeFilter),
            statuses: userPermissions.has(flightListPermissions.statusFilter),
        });

        const requestData = getQueryParamsFromSearchForm(filteredPayload, pagination, sorting);

        const requestCancelledData: ApiCallFlightFilters = {
            status: 'cancelled',
            hasPassengers: true,
        };

        const requests = [getApiPermittedFlightList(requestData)];
        if (requestId === 'flightList') requests.push(getApiPermittedFlightList(requestCancelledData));
        const [flights, cancelledFlights] = yield* all(requests);

        yield* put(actions.getFlightListSuccess({
            flights,
            cancelledFlights: cancelledFlights ?? { items: [], total: 0 },
        }));
    }
    catch (error) {
        logError({
            error,
            target: 'FlightListPage.getFlightList',
        });
        yield* put(actions.getFlightListError());
        const message = getApiErrorMessage(error);
        yield* put(flightListNotifications.unknownError(message));
    }
}

export function* getFlightListSaga() {
    yield* takeLatest(actions.getFlightList, getFlightList);

    function* requestGetFlightList() {
        yield* put(actions.getFlightList());
    }

    yield* throttle(1000, [
        actions.setPage,
        actions.setSorting,
        actions.resetFilterData,
        actions.confirmDeleteFlightsSuccess,
        actions.activateFlightsSuccess,
        actions.unassignFlightsSuccess,
        actions.deleteFlightsSequencesSuccess,
        actions.bookOutFlightSuccess,
        actions.setFlightCancelledSuccess,
    ], requestGetFlightList);

    yield* debounce(1000, [
        actions.setFilterData,
        actions.updateFilterData,
        actions.setPageSize,
    ], requestGetFlightList);
}

export function* deleteFlights() {
    try {
        const selectedFlights = yield* select(selectSelectedRows);

        const payload = {
            ids: selectedFlights.map(flight => flight.id),
        };

        const requestData = {
            requestId: 'flightListDelete',
            requestPayload: payload,
        };

        const result = yield* call(apiClientRequest, requestData);
        if (result.ids && result.ids.length > 0) {
            yield* put(flightListNotifications.notDeletedFlights);
        }

        yield* put(actions.confirmDeleteFlightsSuccess());
    }
    catch (error) {
        logError({
            error,
            target: 'FlightListPage.deleteFlights',
        });
        yield* put(actions.confirmDeleteFlightsError());
        const message = getApiErrorMessage(error);
        yield* put(flightListNotifications.unknownError(message));
    }
}

export function* deleteFlightsSaga() {
    yield* takeLatest(actions.confirmDeleteFlights, deleteFlights);
}

export function* activateFlight(action: ReturnType<typeof actions.activateFlight>) {
    try {
        const searchForm = yield* select(selectFilterData);
        const pagination = yield* select(selectPagination);
        const sorting = yield* select(selectSorting);

        const { operation, id } = action.payload;
        const payload = {
            ...getQueryParamsFromSearchForm(searchForm, pagination, sorting),
            ...getQueryParamsFromBulkOperation({ ids: [id] }),
            // TODO improve, remove duplications
            sortBy: undefined,
            sortOrder: undefined,
            page: undefined,
            pageSize: undefined,
        };

        const requestData = {
            requestId: operation === 'activate' ? 'flightListActivate' : 'flightListDeactivate',
            query: queryString.stringify(payload, { skipNull: true, sort: false }),
            requestPayload: payload,
        };

        yield* call(apiClientRequest, requestData);
        yield* put(actions.activateFlightsSuccess());
    }
    catch (error) {
        logError({
            error,
            target: 'FlightListPage.activateFlight',
        });
        yield* put(actions.activateFlightsError());
        const message = getApiErrorMessage(error);
        yield* put(flightListNotifications.unknownError(message));
    }
}

export function* activateFlightSaga() {
    yield* takeLatest(actions.activateFlight, activateFlight);
}

export function* unassignFlights(action: ReturnType<typeof actions.unassignFlights>) {
    try {
        const searchForm = yield* select(selectFilterData);
        const pagination = yield* select(selectPagination);
        const sorting = yield* select(selectSorting);

        const payload = {
            ...getQueryParamsFromSearchForm(searchForm, pagination, sorting),
            ...getQueryParamsFromBulkOperation(action.payload),
            sortBy: undefined,
            sortOrder: undefined,
        };

        const requestData = {
            requestId: 'flightListUnassign',
            query: queryString.stringify(payload, { skipNull: true, sort: false }),
        };

        yield* call(apiClientRequest, requestData);
        yield* put(actions.unassignFlightsSuccess());
    }
    catch (error) {
        logError({
            error,
            target: 'FlightListPage.unassignFlights',
        });
        yield* put(actions.unassignFlightsError());
        const message = getApiErrorMessage(error);
        yield* put(flightListNotifications.unknownError(message));
    }
}

export function* unassignFlightsSaga() {
    yield* takeLatest(actions.unassignFlights, unassignFlights);
}

export function* deleteFlightsSequences(action: ReturnType<typeof actions.deleteFlightsSequences>) {
    try {
        const searchForm = yield* select(selectFilterData);
        const pagination = yield* select(selectPagination);
        const sorting = yield* select(selectSorting);

        const payload = {
            ...getQueryParamsFromSearchForm(searchForm, pagination, sorting),
            ...getQueryParamsFromBulkOperation(action.payload),
            sortBy: undefined,
            sortOrder: undefined,
        };

        const requestData = {
            requestId: 'flightListDeleteSequence',
            query: queryString.stringify(payload, { skipNull: true, sort: false }),
        };

        yield* call(apiClientRequest, requestData);
        yield* put(actions.deleteFlightsSequencesSuccess());
    }
    catch (error) {
        logError({
            error,
            target: 'FlightListPage.deleteFlightsSequences',
        });
        yield* put(actions.deleteFlightsSequencesError());
        const message = getApiErrorMessage(error);
        yield* put(flightListNotifications.unknownError(message));
    }
}

export function* deleteFlightsSequencesSaga() {
    yield* takeLatest(actions.deleteFlightsSequences, deleteFlightsSequences);
}

export function* bookOutFlight(action: ReturnType<typeof actions.bookOutFlight>) {
    try {
        const { operation, id } = action.payload;

        const requestData = {
            requestId: operation === 'mark' ? 'flightSetBookedOut' : 'flightUnsetBookedOut',
            uriParams: { id },
        };

        yield* call(apiClientRequest, requestData);
        yield* put(actions.bookOutFlightSuccess());
    }
    catch (error) {
        logError({
            error,
            target: 'FlightListPage.bookOutFlight',
        });
        yield* put(actions.bookOutFlightError());
        const message = getApiErrorMessage(error);
        yield* put(flightListNotifications.unknownError(message));
    }
}

export function* bookOutFlightSaga() {
    yield* takeLatest(actions.bookOutFlight, bookOutFlight);
}

export function* setFlightCancelled(action: ReturnType<typeof actions.setFlightCancelled>) {
    try {
        const flightId = action.payload;
        const status = 'cancelled' as FlightStatus;

        const uriParams = { id: flightId, status };

        const requestData = {
            requestId: 'flightSetStatus',
            uriParams,
        };

        yield* call(apiClientRequest, requestData);
        yield* put(actions.setFlightCancelledSuccess());
    }
    catch (error) {
        logError({
            error,
            target: 'FlightListPage.setFlightCancelled',
        });
        yield* put(actions.setFlightCancelledError());
        const message = getApiErrorMessage(error);
        yield* put(flightListNotifications.unknownError(message));
    }
}

export function* setFlightCancelledSaga() {
    yield* takeLatest(actions.setFlightCancelled, setFlightCancelled);
}

function* getFlightBookingInfo(id: number) {
    const userPermissions = yield* selectUserPermissions();
    if (!userPermissions.has(flightListPermissions.readInfo)) return undefined;

    const result: FlightBookingInfo = yield* call(apiClientRequest, {
        requestId: 'flightBookingInfoData',
        uriParams: { id },
    });
    return result;
}
function* getFlightTurnoverInfo(id: number) {
    const userPermissions = yield* selectUserPermissions();
    if (!userPermissions.has(flightListPermissions.readTurnoverInfo)) return undefined;

    const result: FlightTurnoverInfo = yield* call(apiClientRequest, {
        requestId: 'flightTurnoverInfo',
        uriParams: { id },
    });
    return result;
}
export function* loadFlightInfo(action: ReturnType<typeof actions.getFlightInfo>) {
    try {
        const { id } = action.payload;

        const { flight, flightBookingInfo, flightTurnoverInfo } = yield* all({
            flight: call(getApiFlight, id),
            flightBookingInfo: call(getFlightBookingInfo, id),
            flightTurnoverInfo: call(getFlightTurnoverInfo, id),
        });

        yield* put(
            actions.getFlightInfoSuccess({
                flight,
                flightBookingInfo,
                flightTurnoverInfo,
            }),
        );
    }
    catch (error) {
        logError({
            error,
            target: 'FlightListPage.loadFlightInfo',
        });
        yield* put(actions.getFlightInfoError());
        const message = getApiErrorMessage(error);
        yield* put(flightListNotifications.unknownError(message));
    }
}

export function* loadFlightInfoSaga() {
    yield* takeLatest(actions.getFlightInfo, loadFlightInfo);
}

export function* downloadTurnoverList() {
    try {
        const selectedFlights = yield* select(selectSelectedRows);

        const payload = {
            ids: selectedFlights.map(flight => flight.id),
        };

        const requestData = {
            requestId: 'flightTurnoverListPDF',
            requestPayload: payload,
        };

        const response = yield* call(apiClientRequest, requestData);

        const filename = response.headers
            .get('Content-Disposition')
            ?.match(/filename="(.*?)"/)?.[1] || 'TurnoverList.pdf';
        yield* downloadFile(response, filename);
        yield* put(actions.downloadTurnoverListSuccess());
    }
    catch (error) {
        logError({
            error,
            target: 'FlightListPage.downloadTurnoverList',
        });
        yield* put(actions.downloadTurnoverListError());
        const message = getApiErrorMessage(error);
        yield* put(flightListNotifications.unknownError(message));
    }
}

export function* downloadTurnoverListSaga() {
    yield* takeLatest(actions.downloadTurnoverList, downloadTurnoverList);
}

function* downloadPassengerReport() {
    try {
        const locale: string = yield* select(selectLocale);
        const selectedFlights = yield* select(selectSelectedRows);

        const payload = {
            ids: selectedFlights.map(flight => flight.id),
        };

        const requestData = {
            requestId: 'flightPassengerReport',
            requestPayload: payload,
            uriParams: {
                locale,
            },
        };

        const response = yield* call(apiClientRequest, requestData);

        const filename = response.headers
            .get('Content-Disposition')
            ?.match(/filename="(.*?)"/)?.[1] || 'PassengerReport.xlsx';
        yield* downloadFile(response, filename);
        yield* put(actions.downloadPassengerReportSuccess());
    }
    catch (error) {
        logError({
            error,
            target: 'FlightListPage.downloadPassengerReport',
        });
        yield* put(actions.downloadPassengerReportError());
        const message = getApiErrorMessage(error);
        yield* put(flightListNotifications.unknownError(message));
    }
}

export function* downloadPassengerReportSaga() {
    yield* takeLatest(actions.downloadPassengerReport, downloadPassengerReport);
}
