import { ReservationOverviewInfoTablePassenger } from '@malesia/react-components/dist/src/components/Reservation/StepReservationOverview/ReservationOverviewInfo/ReservationOverviewInfoTable';
import { ReservationOverviewInfoTableSeat } from '@malesia/react-components/dist/src/components/Reservation/StepReservationOverview/ReservationOverviewInfo/ReservationOverviewInfoTable/ReservationOverviewInfoTableSeats';
import { mapTariffGroupIdToCode } from '@malesia/react-components/dist/src/components/TariffsTable/tariffGroupMapper';
import { createFullName } from '@malesia/react-components/dist/src/utils/fullName';
import { localizeMoney } from '@malesia/react-components/dist/src/utils/roundMoney';
import { verifyTariff } from '@malesia/react-components/dist/src/utils/verify';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    selectCost,
    selectPassengers, selectSelectedFlights, selectSelectedPrices,
} from '../../../../containers/ReservationBasket/reservationBasket.selectors';
import { preparePassengerSeats } from '../../../../services/ReservationCalculate/utils';
import { selectHashMapOptionsIdToOptions } from '../../StepsSummary/selectors';

export const usePreparePassengersTable = (): ReservationOverviewInfoTablePassenger[] => {
    const passengers = useSelector(selectPassengers);
    const selectedPrises = useSelector(selectSelectedPrices);
    const selectedFlights = useSelector(selectSelectedFlights);
    const cost = useSelector(selectCost);
    const optionsData = useSelector(selectHashMapOptionsIdToOptions);
    const { locale } = useIntl();

    const res = useMemo(() => passengers?.map((passenger, idx) => {

        const costForPassengers = cost?.costForPassengers;
        const prices = Object.values(selectedPrises ?? {});

        const passengerSeats = preparePassengerSeats(
            (passengers ?? [])?.map(x => x.seats),
            selectedFlights?.outbound?.id!,
            selectedFlights?.return?.id!,
        );
        const seats = passengerSeats[idx].map((seats, i): ReservationOverviewInfoTableSeat => {
            const seatPrice = costForPassengers?.[idx].flightsCosts[i].seat;
            const tariff = verifyTariff(prices?.[i]?.tariff);
            const rateTariffType = mapTariffGroupIdToCode(tariff?.group.id) ?? 'economy';
            return {
                number: seats.seatLabel!,
                price: localizeMoney(seatPrice, locale, 'BookingFlightPage/Steps/StepReservationOverview/usePreparePassengersTable/seatPrice'),
                rateTariffType,
            };
        });

        const ticketsPrice = costForPassengers?.[idx].flightsCosts.reduce((sum, flightCost) => {
            const totalCost = flightCost.base + flightCost.airportFee + flightCost.fuelFee;
            return sum + totalCost;
        }, 0) ?? 0;

        const options = Object.entries(passengers[idx].options)
            // delete false option
            .filter(([, value]) => value)
            //get optionId
            .map(([key]) => key)
            //create option text
            .map(optionId => {
                const optionConfig = optionsData[optionId]?.configValues;
                const title = optionConfig?.title?.[locale];
                const price = localizeMoney(optionConfig?.price, locale, 'BookingFlightPage/Steps/StepReservationOverview/usePreparePassengersTable/optionConfig.price');
                return title && optionConfig?.price ? `${title} CHF ${price}` : '';
            });

        const res: ReservationOverviewInfoTablePassenger = {
            name: createFullName(passenger.info.firstName, passenger.info.lastName),
            options,
            seats,
            ticketsPrice,
        };
        return res;
    }),
    [
        cost?.costForPassengers,
        locale,
        optionsData,
        passengers,
        selectedFlights,
        selectedPrises,
    ]);

    return res ?? [];
};
