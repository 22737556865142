import { AuthTokenPostRequest, UserMeGetResponse } from '@malesia/json-schema';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { RoleCode } from '../../permissions/roleCode';
import { UserInfo, UserLoginAccount, UserLoginContainerState } from './types';

export const initialState: UserLoginContainerState = {
    userGroupRoles: [],
};

export type RefreshUserDataSuccessOptions = {
    userInfo: UserMeGetResponse,
    userRoles: RoleCode[],
    userAccount: UserLoginAccount,
};

const UserLoginContainerSlice = createSlice({
    name: 'userLoginContainer',
    initialState,
    reducers: {
        login(state, action: PayloadAction<AuthTokenPostRequest>) {
            state.isLoadingLogin = true;
        },
        loginSuccess(state, action: PayloadAction<string>) {
            state.authToken = action.payload;
            state.isLoadingLogin = false;
        },
        loginError() {
            return initialState;
        },
        refreshUserData(state) {
            state.isLoadingUserData = true;
        },
        refreshUserDataSuccess(state, action: PayloadAction<RefreshUserDataSuccessOptions | undefined>) {
            state.isLoadingUserData = false;
            if (!action.payload) return;
            state.userInfo = action.payload.userInfo as UserInfo;
            state.userGroupRoles = action.payload.userRoles ?? [];
            state.account = action.payload.userAccount;
        },
        refreshUserDataError(state) {
            if (!state.userInfo) {
                return initialState;
            }
            state.isLoadingUserData = false;
        },
        logout(state) {},
        logoutSuccess(state) {
            return initialState;
        },
        setUserInfo(state, action: PayloadAction<UserMeGetResponse>) {
            state.userInfo = action.payload as UserInfo;
        },
        setUserAccount(state, action: PayloadAction<UserLoginAccount>) {
            state.account = action.payload;
        },
    },
});

export const { actions: loginActions, reducer: loginReducer, name: loginSliceKey } = UserLoginContainerSlice;
