import { call, put } from 'typed-redux-saga';
import { logError } from '../../../../utils/log';
import { datatransService, InitTransactionParams } from '../../../services/Datatrance/datatrans.service';
import { paymentNotifications } from './notification';

export function* payByDatatrans({ reservationId, amount, source, additionalProp }: InitTransactionParams) {
    try {
        const requestData: InitTransactionParams = { source, reservationId, amount, additionalProp };
        const transaction = yield* call(datatransService.paymentDatatransReservationInit, requestData);
        const result = yield* call(datatransService.showDatatransPopup, transaction.transactionId.toString());
        return result;
    }
    catch (error) {
        logError({
            error,
            target: 'ReservationPaymentPage.payByDatatrans',
        });
        yield* put(paymentNotifications.notInitializedTransaction);
    }
}
