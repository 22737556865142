import { call, put, select, takeLatest, throttle } from 'typed-redux-saga';
import { logError } from '../../../../utils/log';
import { getApiErrorMessage } from '../../../services/ApiClient';
import { getApiReservatorList } from '../../../services/reservator';
import { appNotification } from '../../App/appNotification';
import { selectReservationReservatorList, selectSelectedReservationReservator } from '../selectors';
import { auxiliaryActions } from '../slice';

function* getReservationReservatorList(action: ReturnType<typeof auxiliaryActions.getReservationReservatorList>) {
    try {
        const query = action.payload;
        const result = yield* getApiReservatorList({ query });
        yield* put(auxiliaryActions.getReservationReservatorListSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'AuxiliaryData.getReservationReservatorList',
        });
        const message = getApiErrorMessage(error);
        yield* put(appNotification.unknownError(message));
        yield* put(auxiliaryActions.getReservationReservatorListError());
    }
}

export function* getReservationReservatorListSaga() {
    yield* takeLatest(auxiliaryActions.getReservationReservatorList, getReservationReservatorList);

    function* request(action: ReturnType<typeof auxiliaryActions.searchReservationReservatorList>) {
        yield* put(auxiliaryActions.getReservationReservatorList(action.payload));
    }
    yield* throttle(1000, auxiliaryActions.searchReservationReservatorList, request);
}

function* selectReservationReservator(action: ReturnType<typeof auxiliaryActions.selectReservationReservator>) {
    try {
        const userId = action.payload;

        const selected = yield* select(selectSelectedReservationReservator);
        if (selected?.id === userId) {
            yield* put(auxiliaryActions.selectReservationReservatorSuccess(selected));
            return;
        }

        if (!userId) {
            yield* put(auxiliaryActions.selectReservationReservatorSuccess(undefined));
            return;
        }

        const list = yield* select(selectReservationReservatorList);
        const item = list.find(x => x.id === userId);
        if (item) {
            yield* put(auxiliaryActions.selectReservationReservatorSuccess(item));
            return;
        }
        const result = yield* call(getApiReservatorList, { query: '', userId });
        const resultItem = result.items[0] ?? undefined;
        if (resultItem) {
            yield* put(auxiliaryActions.selectReservationReservatorSuccess(resultItem));
            return;
        }
        logError({
            error: new Error('Not found Reservator'),
            target: 'AuxiliaryData.selectReservationReservator.notFound',
        });
        yield* put(appNotification.notFoundReservator);
        yield* put(auxiliaryActions.selectReservationReservatorError());
    }
    catch (error) {
        logError({
            error,
            target: 'AuxiliaryData.selectReservationReservator',
        });
        const message = getApiErrorMessage(error);
        yield* put(appNotification.unknownError(message));
        yield* put(auxiliaryActions.selectReservationReservatorError());
    }
}

export function* selectReservationReservatorSaga() {
    yield* takeLatest(auxiliaryActions.selectReservationReservator, selectReservationReservator);
}
