import { FormErrorsArgType } from '@malesia/react-components/dist/src/components/ValidationErrors';
import {
    ErrorObject,
} from 'ajv';

export type ValidationErrorType = {
    messageId: string,
};
export type ValidationErrorMessagesType = Record<string, ValidationErrorType>;

//todo change name resolving
export const messageIdByKeyword: ValidationErrorMessagesType = {
    maxLength: {
        messageId: 'schema/maxLength',
    },
    minLength: {
        messageId: 'schema/minLength',
    },
    minProperties: {
        messageId: 'schema/minProperties',
    },
    repeated: {
        messageId: 'schema/repeated',
    },
    notEmpty: {
        messageId: 'schema/notEmpty',
    },
    required: {
        messageId: 'schema/required',
    },
    pattern: {
        messageId: 'schema/pattern',
    },
    minimum: {
        messageId: 'schema/minimum',
    },
    maximum: {
        messageId: 'schema/maximum',
    },
    format: {
        messageId: 'schema/format',
    },
    type: {
        messageId: 'schema/type',
    },
};

// @formatter:off
export type SchemaValidationErrorConfigType = {
    generateMessageId: (values: ErrorObject) => string,
    getFieldName: (values: ErrorObject) => string,
};

export type SchemaValidationErrorsType = Record<string, SchemaValidationErrorConfigType>;
// @formatter:on

// TODO change `schema` prefix on something more custom
// TODO add config for all possible rules
// TODO split native and custom rules
// TODO think about translations usage @malesia/react-components/src/components/ValidationErrors/index.tsx
export const errorConfigByKeyword: SchemaValidationErrorsType = {
    dependencies: {
        generateMessageId: (error: ErrorObject) => `schema/${error.keyword}/property/${error.params.property}`,
        getFieldName: (error: ErrorObject) => `${error.params.missingProperty}`,
    },
    minLength: {
        generateMessageId: (error: ErrorObject) => `schema/${error.keyword}`,
        getFieldName: getFieldNameFromError,
    },
    maxLength: {
        generateMessageId: (error: ErrorObject) => `schema/${error.keyword}`,
        getFieldName: getFieldNameFromError,
    },
    pattern: {
        generateMessageId: generateMessageIdFromErrorDataPath,
        getFieldName: getFieldNameFromError,
    },
    format: {
        generateMessageId: (error: ErrorObject) => `schema/${error.keyword}/${(error.params).format}`,
        getFieldName: getFieldNameFromError,
    },
    repeated: {
        generateMessageId: generateMessageIdFromErrorDataPath,
        getFieldName: getFieldNameFromError,
    },
    notEmpty: {
        generateMessageId: generateMessageIdFromErrorDataPath,
        getFieldName: getFieldNameFromError,
    },
    required: {
        generateMessageId: (error: ErrorObject) => `schema/${error.keyword}`,
        getFieldName: (error: ErrorObject) => error.instancePath !== ''
            ? `${error.instancePath}/${(error.params).missingProperty
            }`
            : `${(error.params).missingProperty}`,
    },
    type: {
        generateMessageId: (error: ErrorObject) => `schema/${error.keyword}/${(error.params).type}`,
        getFieldName: getFieldNameFromError,
    },
    minimum: {
        generateMessageId: (error: ErrorObject) => `schema/${error.keyword}`,
        getFieldName: getFieldNameFromError,
    },
    maximum: {
        generateMessageId: (error: ErrorObject) => `schema/${error.keyword}`,
        getFieldName: getFieldNameFromError,
    },
    exclusiveMinimum: {
        generateMessageId: (error: ErrorObject) => `schema/${error.keyword}`,
        getFieldName: getFieldNameFromError,
    },
    exclusiveMaximum: {
        generateMessageId: (error: ErrorObject) => `schema/${error.keyword}`,
        getFieldName: getFieldNameFromError,
    },
};

function getFieldNameFromError(error: ErrorObject): string {
    return error.instancePath.slice(1);
}

function generateMessageIdFromErrorDataPath(error: ErrorObject): string {
    return `schema/${error.keyword}${error.instancePath}`;
}

export function formatSchemaValidationErrors(
    errors: ErrorObject[],
): FormErrorsArgType {
    return errors.reduce((acc, error) => {
        if (error.keyword === 'oneOf' || error.keyword === 'anyOf') {
            return acc;
        }

        //todo change name resolving
        const isCustomError = error.keyword.startsWith('custom/');

        if (!isCustomError && !errorConfigByKeyword[error.keyword]) {
            console.warn(
                '[MALESIA WARN]',
                `SchemaId: ${error.schema}`,
                `Schema validation config not found for the key '${error.keyword}'`,
                error.params,
            );
            return acc;
        }

        const fieldName = !isCustomError
            ? errorConfigByKeyword[error.keyword].getFieldName(error)
            : error.instancePath.slice(1);

        const item = {
            dataPath: error.instancePath.slice(1),
            // dataPath: error.dataPath,
            message: error.message,
            messageId: !isCustomError
                ? errorConfigByKeyword[error.keyword].generateMessageId(error)
                : error.keyword,
            values: error.params,
        };

        if (!!acc[fieldName]) {
            const sameErrors = acc[fieldName].filter(existingItem => {
                return (
                    existingItem.message === item.message
                    || (item.messageId !== undefined
                        && existingItem.messageId === item.messageId)
                );
            });

            if (sameErrors.length > 0) {
                return acc;
            }
        }

        const fieldErrors = !!acc[fieldName] ? [item, ...acc[fieldName]] : [item];

        return {
            ...acc,
            [fieldName]: fieldErrors,
        };
    }, {});
}

/**
 * Create new custom error
 * @param dataPath - path to field with leading slash
 * @param keyword - custom keyword
 * @param message - default message
 */
export function createCustomValidationError(
    dataPath: string,
    keyword: string,
    message?: string,
): ErrorObject {
    return {
        keyword,
        instancePath: dataPath,
        params: {
            keyword,
        },
        message,
        schemaPath: '',
    };
}

/**
 * @deprecated
 * @param errors
 */
export function prepareValidationErrors(errors: ErrorObject[]) {
    // TODO switch on the new functionality `formatSchemaValidationErrors`
    return errors.reduce((acc, error) => {
        if (error.keyword === 'oneOf' || error.keyword === 'anyOf') {
            return acc;
        }

        if (!messageIdByKeyword[error.keyword]) {
            console.warn(
                '[MALESIA WARN]',
                `Validation message not found for the key '${error.keyword}'`,
            );
        }

        const dataPath = error.instancePath;
        /*
    const dataPath =
      error.keyword === 'required' &&
      error.params['missingProperty'] !== undefined
        ? error.dataPath !== ''
          ? error.dataPath + '/' + error.params['missingProperty']
          : error.params['missingProperty']
        : error.dataPath;
     */
        const fieldName = convertErrorPathToChain(dataPath);

        // @formatter:off
        const messageId = messageIdByKeyword[error.keyword] !== undefined
            ? {
                messageId:
                    error.keyword === 'repeated' || error.keyword === 'pattern'
                        ? messageIdByKeyword[error.keyword].messageId + dataPath
                        : messageIdByKeyword[error.keyword].messageId,
            }
            : {};
        // @formatter:on

        const item = {
            fieldPath: fieldName,
            dataPath,
            values: error.params,
            message: error.message,
            ...messageId,
        };

        if (!!acc[fieldName]) {
            const sameErrors = acc[fieldName].filter(existingItem => {
                return (
                    existingItem.message === item.message
                    || (existingItem.messageId !== undefined
                        && item.messageId !== undefined
                        && existingItem.messageId === item.messageId)
                );
            });

            if (sameErrors.length > 0) {
                return acc;
            }
        }

        const fieldErrors = !!acc[fieldName] ? [item, ...acc[fieldName]] : [item];

        return {
            ...acc,
            [fieldName]: fieldErrors,
        };
    }, {});
}

/**
 * Apply `password with confirm` error if provided
 * @param errors
 * @param data
 */
export function preparePasswordValidationError(
    errors: ErrorObject[],
    data: any,
) {
    const { password, passwordConfirm } = data;
    const isPasswordWithConfirmNotEqual = password !== undefined
        && passwordConfirm !== undefined
        && password !== passwordConfirm;
    const passwordConfirmError: ErrorObject[] = isPasswordWithConfirmNotEqual
        ? [
            createCustomValidationError(
                '/passwordConfirm',
                'repeated',
                'Should be equal to password',
            ),
        ]
        : [];
    return [...errors, ...passwordConfirmError];
}

/**
 * Check that field is not empty and ad error if necessary
 * @param dataPath
 * @param data
 * @param errors
 */
export function prepareNotEmptyError(
    dataPath: string,
    data: any,
    errors: ErrorObject[] = [],
) {
    //TODO extract values from not flat structures and arrays
    const value = data[dataPath.slice(1)];
    const emptyPattern = /^\s*$/;
    const valueIsEmptyString = typeof value === 'string' && emptyPattern.test(value);
    const notEmptyError: ErrorObject[] = valueIsEmptyString
        ? [
            createCustomValidationError(
                dataPath,
                'notEmpty',
                'Value should not be empty string',
            ),
        ]
        : [];
    return [...errors, ...notEmptyError];
}

/**
 * @deprecated
 * @param path
 */
export function convertErrorPathToChain(path) {
    // TODO check chained variants
    // Known: (JSON Pointers)
    // "dataPath": "/language",
    return path.replace('/', '');
}

/**
 * @deprecated
 * @param error
 */
export function calculateDataPath(error) {
    let dataPath;

    switch (error.keyword) {
        case 'required':
            if (error.params['missingProperty'] === undefined) {
                console.warn(
                    '[MALESIA WARN]',
                    'Validation \'missingProperty\' value is undefined for the keyword \'required\'.\'',
                );
            }
            dataPath = (error.dataPath !== '' ? error.dataPath + '/' : '')
                + error.params['missingProperty'];
            break;
        case 'dependencies':
        default:
            dataPath = error.instancePath;
    }

    return dataPath;
}
