import { AccountListItem, BalanceLogAccount, BalanceLogRecord } from '@malesia/json-schema';
import { BalanceLogAmountCell } from '@malesia/react-components/dist/src/components/Accounting/BalanceLog/BalanceLogAmountCell';
import { BalanceLogBalanceCell } from '@malesia/react-components/dist/src/components/Accounting/BalanceLog/BalanceLogBalanceCell';
import { TableColumn } from '@malesia/react-components/dist/src/components/Table';
import { idColumn } from '@malesia/react-components/dist/src/components/Table/columns/idColumn';
import { DATETIME_FORMAT } from '@malesia/react-components/dist/src/utils/dateTimeFormat';
import moment from 'moment';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectCashbackPdfState } from '../../containers/CashbackPdf/selectors';
import { cashbackPdfActions } from '../../containers/CashbackPdf/slice';
import { balanceLogPermissions } from '../../permissions/adminPages/balance/balanceLog';
import { useUserPermissions } from '../../permissions/useUserPermissions';
import { balanceLogActionsColumn } from './actionsColumn';
import { selectFilterData } from './store/selectors';

const getDisplayNameFromAccount = (account?: BalanceLogAccount) => {
    return account?.user?.displayName ?? account?.virtualAccount?.name ?? 'unknown';
};

const getTransactionDirection = (data: BalanceLogRecord, account?: AccountListItem) => {
    if (!account) return undefined;

    const { type, id } = account;
    if (type === 'asset' || type === 'expense') {
        return data.debitAccount?.id === id ? 'increase' : 'decrease';
    }
    else if (type === 'revenue' || type === 'liability') {
        return data.creditAccount?.id === id ? 'increase' : 'decrease';
    }
    return undefined;
};

const getAccountBalance = (data: BalanceLogRecord, account?: BalanceLogAccount) => {
    if (!account) return undefined;
    if (account.id === data.debitAccount?.id) {
        return data.debitAccountBalance;
    }
    if (account.id === data.creditAccount?.id) {
        return data.creditAccountBalance;
    }
    return undefined;
};

export const useTableColumns = (): TableColumn<BalanceLogRecord>[] => {
    const filterData = useSelector(selectFilterData);
    const cashbackPdfState = useSelector(selectCashbackPdfState);
    const userPermissions = useUserPermissions();
    const dispatch = useDispatch();

    const { account } = filterData;

    const result = useMemo((): TableColumn<BalanceLogRecord>[] => ([
        idColumn({
            disableSortBy: true,
        }),
        {
            Header: (
                <FormattedMessage
                    id='front-app/BalanceLog/TableHeader:Debit'
                    defaultMessage='Debit'
                />
            ),
            id: 'debitAccount',
            disableSortBy: true,
            Cell: ({ row }) => getDisplayNameFromAccount(row.original.debitAccount),
        },
        {
            Header: (
                <FormattedMessage
                    id='front-app/BalanceLog/TableHeader:Credit'
                    defaultMessage='Credit'
                />
            ),
            id: 'creditAccount',
            disableSortBy: true,
            Cell: ({ row }) => getDisplayNameFromAccount(row.original.creditAccount),
        },
        {
            Header: (
                <FormattedMessage
                    id='front-app/BalanceLog/TableHeader:Amount'
                    defaultMessage='Amount'
                />
            ),
            id: 'amount',
            Cell: ({ row }) => (
                <BalanceLogAmountCell
                    amount={row.original.amount!}
                    direction={getTransactionDirection(row.original, account)}
                />
            ),
        },
        {
            Header: (
                <FormattedMessage
                    id='front-app/BalanceLog/TableHeader:Balance'
                    defaultMessage='Balance'
                />
            ),
            id: 'balance',
            hidden: !account,
            Cell: ({ row }) => {
                return (
                    <BalanceLogBalanceCell
                        balance={getAccountBalance(row.original, account)}
                    />
                );
            },
        },
        {
            Header: (
                <FormattedMessage
                    id='front-app/BalanceLog/TableHeader:Date'
                    defaultMessage='Date'
                />
            ),
            id: 'createdAt',
            Cell: ({ row }) => moment(row.original.createdAt).format(DATETIME_FORMAT),
        },
        {
            Header: (
                <FormattedMessage
                    id='front-app/BalanceLog/TableHeader:Category'
                    defaultMessage='Category'
                />
            ),
            id: 'type',
            disableSortBy: true,
            Cell: ({ row }) => row.original.type?.name ?? 'unknown',
        },
        {
            Header: (
                <FormattedMessage
                    id='front-app/BalanceLog/TableHeader:Description'
                    defaultMessage='Description'
                />
            ),
            accessor: 'title',
            disableSortBy: true,
        },
        balanceLogActionsColumn({
            cashbackPdf: {
                click: (balanceLog, _, tableRef) => {
                    dispatch(cashbackPdfActions.downloadCashbackPdf({
                        id: balanceLog.id!,
                        callback: () => tableRef.renderColumn('actions'),
                    }));
                    tableRef.renderColumn('actions');
                },
                disabled: (balanceLog) => balanceLog.type?.code !== 'cashback',
                isLoading: (balanceLog) => cashbackPdfState.isLoadingMap[balanceLog.id!],
                notAllowed: !userPermissions.has(balanceLogPermissions.cashbackPdf),
            },
        }),
    ]), [
        account,
        cashbackPdfState,
        userPermissions,
        dispatch,
    ]);

    return result;
};
