import { FlightCompany } from '@malesia/json-schema';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { FlightCompanyNewPageState } from './types';

export const initialState: FlightCompanyNewPageState = {
    loading: false,
};

const flightCompanyNewPageSlice = createSlice({
    name: 'flightCompanyNewPage',
    initialState,
    reducers: {
        createFlightCompany(state, action: PayloadAction<FlightCompany>) {
            state.loading = true;
        },
        createFlightCompanySuccess(state, action: PayloadAction<FlightCompany>) {
            state.loading = false;
        },
        createFlightCompanyError(state) {
            state.loading = false;
        },
    },
});

export const { actions, reducer, name: sliceKey } = flightCompanyNewPageSlice;
