import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { loginSaga } from './saga';
import { selectUserLoginAuthToken } from './selectors';
import { loginActions, loginReducer, loginSliceKey } from './slice';

export type LoginCredentialsType = {
    login: string,
    password: string,
};

export type Props = {
    LoginComponent: React.ElementType,
    LoggedInComponent?: React.ElementType,
    onSubmit: (data: LoginCredentialsType) => void,
};

export const UserLoginContainer = (props: Props) => {
    useInjectReducer({ key: loginSliceKey, reducer: loginReducer });
    useInjectSaga({
        key: `${loginSliceKey}Login`,
        saga: loginSaga,
    });

    const dispatch = useDispatch();
    const authToken = useSelector(selectUserLoginAuthToken);

    const { LoginComponent, LoggedInComponent, onSubmit } = props;

    const onSubmitForm = (data: LoginCredentialsType) => {
        onSubmit(data);
        dispatch(loginActions.login(data));
    };

    return !authToken ? (
        <LoginComponent onSubmit={onSubmitForm} />
    ) : LoggedInComponent ? (
        <LoggedInComponent />
    ) : null;
};
