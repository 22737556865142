import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { applySeatActionSaga } from './applySeatActionSaga';
import { clickSeatSaga } from './clickSeatSaga';
import { doubleClickSeatSaga } from './doubleClickSeatSaga';
import { aircraftTemplatePopupReducer, aircraftTemplatePopupSliceKey } from './slice';

export const useInjectAircraftTemplatePopup = () => {
    useInjectReducer({
        key: aircraftTemplatePopupSliceKey,
        reducer: aircraftTemplatePopupReducer,
    });

    useInjectSaga({
        key: `${aircraftTemplatePopupSliceKey}ClickSeat`,
        saga: clickSeatSaga,
    });

    useInjectSaga({
        key: `${aircraftTemplatePopupSliceKey}DoubleClickSeat`,
        saga: doubleClickSeatSaga,
    });

    useInjectSaga({
        key: `${aircraftTemplatePopupSliceKey}ApplySeatAction`,
        saga: applySeatActionSaga,
    });
};
