import { RatePaginatedList, TariffEntireList } from '@malesia/json-schema';
import { SortingDataType } from '@malesia/react-components/dist/src/components/Table';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { RateFilterData, RateListPageState } from './types';

export const initialState: RateListPageState = {
    initialized: {
        sorting: false,
    },
    isDirty: true,
    isLoading: true,

    rateList: {
        items: [],
        total: 0,
    },
    tariffData: {
        items: [],
    },
    filterData: {
        searchText: undefined,
        isActiveOnly: true,
        sortBy: 'name',
        sortOrder: 'ASC',
    },
    pagination: {
        page: 0,
        pageSize: 15,
    },
};

const rateListPageSlice = createSlice({
    name: 'rateListPage',
    initialState,
    reducers: {
        updateFilterData(state, action: PayloadAction<RateFilterData>) {
            state.filterData = { ...state.filterData, ...action.payload };
            state.pagination.page = initialState.pagination.page;
            state.isDirty = true;
        },
        setPage(state, action: PayloadAction<number>) {
            state.pagination.page = action.payload;
            state.isDirty = true;
        },
        setPageSize(state, action: PayloadAction<number>) {
            state.pagination.pageSize = action.payload;
            state.pagination.page = initialState.pagination.page;
            state.isDirty = true;
        },
        setSorting(state, action: PayloadAction<SortingDataType>) {
            const data = action.payload;
            state.filterData = {
                ...state.filterData,
                sortBy: data.sortBy,
                sortOrder: data.sortOrder,
            };
            state.isDirty = true;
            state.initialized.sorting = true;
        },
        getTariffList() {},
        getTariffListSuccess(state, action: PayloadAction<TariffEntireList>) {
            state.tariffData = action.payload;
        },
        getTariffListError(state) {
        },
        getRateList(state) {
            state.isLoading = true;
        },
        getRateListSuccess(state, action: PayloadAction<RatePaginatedList>) {
            state.rateList = action.payload;
            state.isLoading = false;
            state.isDirty = false;
        },
        getRateListError(state) {
            state.isLoading = false;
        },
    },
});

export const { actions, reducer, name: sliceKey } = rateListPageSlice;
