import { push } from 'connected-react-router';
import { takeLatest, put, select, take } from 'typed-redux-saga';
import { logError } from '../../../../utils/log';
import { createPassengerList } from '../../../../utils/reservation/passengerUtils';
import { addQueryParameters } from '../../../../utils/uriUtils';
import { getPublicReservationInitialState, reservationBasketActions } from '../../../containers/ReservationBasket/reservationBasket.slice';
import { BasketAirports, PublicReservationBasket } from '../../../containers/ReservationBasket/reservationBasket.types';
import { allPublicRoutePaths } from '../../../containers/Routes/allPublicRoutePaths';
import { selectAirportList } from '../../../containers/SharedData/selectors';
import { sharedActions } from '../../../containers/SharedData/slice';
import { StepName } from '../config/steps';
import { BookingQueryParameters, getBookingQueryParameters } from './queryParameters';
import { parseQueryAges, parseQueryAirports, parseQueryDates, parseQueryFlights, parseQueryTravelType } from './queryParametersParser';
import { selectOutboundFlightListData, selectReturnFlightListData } from './selectors';
import { bookingFlightActions } from './slice';

function* getAirportList() {
    const airportList = yield* select(selectAirportList);
    if (airportList.length > 0) return airportList;

    yield* put(sharedActions.getAirportList());
    const result = yield* take([
        sharedActions.getAirportListSuccess,
        sharedActions.getAirportListError,
    ]);
    if (result.type === sharedActions.getAirportListError.type) {
        return undefined;
    }
    return yield* select(selectAirportList);
}

function* getOutboundFlightList(basket: PublicReservationBasket) {
    yield* put(bookingFlightActions.getOutboundFlightList({
        departureDate: basket.dateRange.from!,
        fromAirportCode: basket.mainAirports.from?.code!,
        toAirportCode: basket.mainAirports.to?.code!,
        passengersAges: basket.passengersAges,
    }));
    const result = yield* take([
        bookingFlightActions.getOutboundFlightListSuccess,
        bookingFlightActions.getOutboundFlightListError,
    ]);
    if (result.type === bookingFlightActions.getOutboundFlightListError.type) {
        return undefined;
    }
    const list = yield* select(selectOutboundFlightListData);
    return list;
}

function* getReturnFlightList(basket: PublicReservationBasket) {
    const defaultReturn: BasketAirports = {
        from: basket.mainAirports.to,
        to: basket.mainAirports.from,
    };
    const airports = basket.travelType === 'other-return' ? basket.otherAirports : defaultReturn;
    yield* put(bookingFlightActions.getReturnFlightList({
        departureDate: basket.dateRange.from!,
        fromAirportCode: airports.from?.code!,
        toAirportCode: airports.to?.code!,
        passengersAges: basket.passengersAges,
    }));
    const result = yield* take([
        bookingFlightActions.getReturnFlightListSuccess,
        bookingFlightActions.getReturnFlightListError,
    ]);
    if (result.type === bookingFlightActions.getReturnFlightListError.type) {
        return undefined;
    }
    const list = yield* select(selectReturnFlightListData);
    return list;
}

function* apply(
    basket: PublicReservationBasket,
    parameters: BookingQueryParameters,
    stepName: StepName,
) {
    const airportList = yield* getAirportList();
    if (!airportList) return { ok: false };

    const travelType = parseQueryTravelType(parameters);
    if (travelType.ok) {
        basket.travelType = travelType.value;
    }

    const dates = parseQueryDates(parameters, stepName, basket.travelType);
    const airports = parseQueryAirports(parameters, stepName, basket.travelType, airportList);
    const ages = parseQueryAges(parameters, stepName);
    if (dates.ok) {
        basket.dateRange = dates.value;
    }
    if (airports.ok) {
        basket.mainAirports = airports.value.main;
        basket.otherAirports = airports.value.other;
    }
    if (ages.ok) {
        basket.passengersAges = ages.value;
        basket.passengers = createPassengerList(ages.value);
    }

    if (!travelType.ok) return travelType;
    if (!dates.ok) return dates;
    if (!airports.ok) return airports;
    if (!ages.ok) return ages;

    const travelSteps: StepName[] = ['travel-dates', 'select-flight-outbound', 'select-flight-return'];
    if (travelSteps.includes(stepName)) return { ok: true };

    const outboundFlightList = yield* getOutboundFlightList(basket);
    if (!outboundFlightList) return { ok: false };
    const returnFlightList = yield* getReturnFlightList(basket);
    if (!returnFlightList) return { ok: false };

    const flights = parseQueryFlights(parameters, stepName, basket.travelType, outboundFlightList, returnFlightList);
    if (!flights.ok) return flights;
    basket.selectedFlights = flights.value.flights;
    basket.selectedPrices = flights.value.prices;

    return { ok: true, error: undefined };
}

export function* applyQueryParameters(action: ReturnType<typeof bookingFlightActions.applyQueryParameters>) {
    try {
        const stepName = action.payload;
        const parameters = getBookingQueryParameters();
        if (Object.keys(parameters).length === 0) return;
        const basket = getPublicReservationInitialState();

        const { ok, error } = yield* apply(basket, parameters, stepName);
        if (error) {
            yield* put(error);
        }
        yield* put(reservationBasketActions.reset(basket));
        if (!ok) {
            const baseUrl = addQueryParameters(allPublicRoutePaths.bookingFlightTravelDates, parameters);
            yield* put(push(baseUrl));
        }
    }
    catch (error) {
        logError({
            error,
            target: 'BookingFlightPage.searchParams',
        });
    }
    finally {
        yield* put(bookingFlightActions.applyQueryParametersComplete());
    }

}

export function* searchParamsSaga() {
    yield* takeLatest(bookingFlightActions.applyQueryParameters, applyQueryParameters);
}
