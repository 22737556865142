import { FormFieldTextarea } from '@malesia/react-components/dist/src/components/Form/Field/Textarea';
import { PopupConfirm } from '@malesia/react-components/dist/src/components/Popup/Confirm';
import React, { ReactElement, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsShowCommentPopup } from '../selectors';
import { actions } from '../slice';

export function CommentPopup(): ReactElement | null {
    const isShowCommentPopup = useSelector(selectIsShowCommentPopup);

    const dispatch = useDispatch();

    const handleCommentChange = useCallback((data: string) => {
        dispatch(actions.updateComment(data));
    }, [dispatch]);

    const handleConfirmCommentPopup = useCallback(() => {
        dispatch(actions.askCommentSuccess());
    }, [dispatch]);

    const handleCancelCommentPopup = useCallback(() => {
        dispatch(actions.askCommentCancel());
    }, [dispatch]);

    return (
        <PopupConfirm
            isOpened={isShowCommentPopup}
            confirmMessage={
                <FormattedMessage
                    id='front-app/FlightPassengerListPage:EnterNoShowComment'
                    defaultMessage='Please, add comment'
                />
            }
            onConfirm={handleConfirmCommentPopup}
            onClose={handleCancelCommentPopup}
        >
            <FormFieldTextarea onChange={e => handleCommentChange(e.target.value)} />
        </PopupConfirm>
    );
}
