import { call, put, select, takeEvery } from 'typed-redux-saga';
import { downloadNamedFile } from '../../../utils/downloadFile';
import { logError } from '../../../utils/log';
import { selectLocale } from '../../containers/App/selectors';
import { apiClientRequest, getApiErrorMessage } from '../../services/ApiClient';
import { reservationPdfNotifications } from './notification';
import { reservationPdfActions } from './slice';

function* downloadOverviewPdf(action: ReturnType<typeof reservationPdfActions.downloadOverviewPdf>) {
    const { reservationId, callback } = action.payload;

    try {
        const locale: string = yield* select(selectLocale);

        const response = yield* call(apiClientRequest, {
            requestId: 'reservationTicketPdf',
            uriParams: { reservationId, locale },
        });
        yield* downloadNamedFile(response, 'Ticket.pdf');
        yield* put(reservationPdfActions.downloadOverviewPdfSuccess(reservationId));
    }
    catch (error) {
        logError({
            error,
            target: 'ReservationPdf.downloadOverviewPdf',
        });
        const message = getApiErrorMessage(error);
        yield* put(reservationPdfNotifications.unknownError(message));
        yield* put(reservationPdfActions.downloadOverviewPdfFail(reservationId));
    }
    finally {
        callback();
    }
}

export function* downloadOverviewPdfSaga() {
    yield* takeEvery(reservationPdfActions.downloadOverviewPdf, downloadOverviewPdf);
}

function* downloadInvoicePdf(action: ReturnType<typeof reservationPdfActions.downloadInvoicePdf>) {
    const { reservationId, callback } = action.payload;

    try {
        const locale: string = yield* select(selectLocale);

        const response = yield* call(apiClientRequest, {
            requestId: 'reservationInvoicePDF',
            uriParams: { reservationId, locale },
        });
        yield* downloadNamedFile(response, 'Invoice.pdf');
        yield* put(reservationPdfActions.downloadInvoicePdfSuccess(reservationId));
    }
    catch (error) {
        logError({
            error,
            target: 'ReservationPdf.downloadInvoicePdf',
        });
        const message = getApiErrorMessage(error);
        yield* put(reservationPdfNotifications.unknownError(message));
        yield* put(reservationPdfActions.downloadInvoicePdfFail(reservationId));
    }
    finally {
        callback();
    }
}

export function* downloadInvoicePdfSaga() {
    yield* takeEvery(reservationPdfActions.downloadInvoicePdf, downloadInvoicePdf);
}
