import { Option, Reservation, ReservationPassenger } from '@malesia/json-schema';
import { flatArray } from '@malesia/react-components/dist/src/utils/flatArray';

// ToDo: Fix schema for `id`, this is really exists.
type FixMeReservationPassenger = ReservationPassenger & {
    id?: number,
};

export const getUsedOptions = (
    reservation: Reservation,
): Option[] => {
    const deepOptions = reservation.passengers.map((passenger: FixMeReservationPassenger) => (
        reservation.options?.filter((x: { passenger: FixMeReservationPassenger }) => (
            x.passenger.id === passenger.id
        ))?.map(x => x.option)
    ));
    const options = flatArray(deepOptions);
    const uniqueOptions = options?.filter((x, i) => options.findIndex(y => x.id === y.id) === i);

    return uniqueOptions ?? [];
};

export const getRemovedOptions = (
    available: Option[],
    used: Option[],
): Option[] => {
    const removedOptions = used.filter(x => !available.find(y => x.id === y.id));
    return removedOptions;
};
