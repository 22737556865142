import { CustomerPaginatedList } from '@malesia/json-schema';
import { SortingDataType } from '@malesia/react-components/dist/src/components/Table';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { CustomerFilterData, CustomerListPageState } from './types';

export const initialState: CustomerListPageState = {
    initialized: {
        sorting: false,
    },
    isDirty: true,
    isLoading: true,

    customerList: {
        items: [],
        total: 0,
    },
    filterData: {
        searchText: undefined,
        isActiveOnly: true,
        sortBy: 'displayName',
        sortOrder: 'ASC',
    },
    pagination: {
        page: 0,
        pageSize: 15,
    },
};

const customerListPageSlice = createSlice({
    name: 'customerListPage',
    initialState,
    reducers: {
        setFilterData(state, action: PayloadAction<CustomerFilterData>) {
            state.filterData = action.payload;
            state.pagination.page = initialState.pagination.page;
            state.isDirty = true;
        },
        setPage(state, action: PayloadAction<number>) {
            state.pagination.page = action.payload;
            state.isDirty = true;
        },
        setPageSize(state, action: PayloadAction<number>) {
            state.pagination.pageSize = action.payload;
            state.pagination.page = initialState.pagination.page;
            state.isDirty = true;
        },
        setSorting(state, action: PayloadAction<SortingDataType>) {
            const data = action.payload;
            state.filterData = {
                ...state.filterData,
                sortBy: data.sortBy,
                sortOrder: data.sortOrder,
            };
            state.isDirty = true;
            state.initialized.sorting = true;
        },
        getCustomerList(state) {
            state.isLoading = true;
        },
        getCustomerListSuccess(state, action: PayloadAction<CustomerPaginatedList>) {
            state.customerList = action.payload;
            state.isLoading = false;
            state.isDirty = false;
        },
        getCustomerListError(state) {
            state.isLoading = false;
        },
        unmount(state) {
            state.initialized = initialState.initialized;
            state.isLoading = true;
            state.isDirty = true;
        },
    },
});

export const { actions, reducer, name: sliceKey } = customerListPageSlice;
