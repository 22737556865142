import { Manager } from '@malesia/json-schema';
import { TableColumn } from '@malesia/react-components/dist/src/components/Table';
import { balanceColumn } from '@malesia/react-components/dist/src/components/Table/columns/balanceColumn';
import { idColumn } from '@malesia/react-components/dist/src/components/Table/columns/idColumn';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { managerListPermissions } from '../../permissions/adminPages/user/managerList';
import { UserPermissions } from '../../permissions/userPermissions';
import { useUserPermissions } from '../../permissions/useUserPermissions';
import { managerActionsColumn } from './actionsColumn';

type TableColumnsOptions = {
    history: ReturnType<typeof useHistory>,
    userPermissions: UserPermissions,
};
const tableColumns = (
    { history, userPermissions }: TableColumnsOptions,
): TableColumn<Manager>[] => ([
    idColumn(),
    {
        Header: (
            <FormattedMessage
                id='front-app/ManagerListPage:ColumnName'
                defaultMessage='Name'
            />
        ),
        accessor: 'displayName',
    },
    {
        Header: (
            <FormattedMessage
                id='front-app/ManagerListPage:ColumnEmail'
                defaultMessage='Email'
            />
        ),
        accessor: 'email',
    },
    balanceColumn({
        disableSortBy: true,
    }),
    managerActionsColumn({
        editManager: {
            click: (manager) => {
                const path = generatePath(allAdminRoutePaths.userManagerEdit, { managerId: manager.id! });
                history.push(path);
            },
            notAllowed: !userPermissions.has(managerListPermissions.editManagerLink),
        },
        balanceLog: {
            click: (manager) => {
                const path = generatePath(allAdminRoutePaths.balanceLog) + `?accountId=${manager.id!}`;
                history.push(path);
            },
            notAllowed: !userPermissions.has(managerListPermissions.balanceLogLink),
        },
    }),
]);

export const useTableColumns = () => {
    const history = useHistory();
    const userPermissions = useUserPermissions();

    const result = useMemo(() => tableColumns({
        history,
        userPermissions,
    }), [
        history,
        userPermissions,
    ]);
    return result;
};
