import { PublicFlightListItem } from '@malesia/json-schema';
import { NoFlightsAvailable } from '@malesia/react-components/dist/src/components/Reservation/NoFlightsAvailable';
import { FlightTab } from '@malesia/react-components/dist/src/components/Tabs/Flight';
import { localizeMoney } from '@malesia/react-components/dist/src/utils/roundMoney';
import { verifyRate } from '@malesia/react-components/dist/src/utils/verify';
import React from 'react';
import { getAvailableTariffSeats } from '../../../../utils/flight/getPriceVariants';
import { convertDateIndexToMoment } from '../../../../utils/reservation/flightsTabs';
import { BookedFlight, SelectedFlight } from '../../../../utils/reservation/selectedFlight';
import { ReservationAllowed } from '../../ReservationNewPage/permissions';
import { TabContentItem, TabContentItemProps } from './TabContentItem';

export type CreateFlightTabOptions = {
    flightsByDate: PublicFlightListItem[],
    isRoundTrip: boolean,
    selectFlight: (data: SelectedFlight) => void,
    selectedFlight?: SelectedFlight,
    bookedFlight?: BookedFlight,
    rangeIndexDate: string,
    flightCardWithoutDetail: boolean,
    allowed: ReservationAllowed,
    locale: string,
};
export const createFlightTab = (options: CreateFlightTabOptions): FlightTab => {
    const {
        flightsByDate,
        isRoundTrip,
        selectFlight,
        selectedFlight,
        bookedFlight,
        rangeIndexDate, // date of tab
        flightCardWithoutDetail,
        allowed,
        locale,
    } = options;

    const flightsPrices = flightsByDate.map(flight => {
        const prices = verifyRate(flight.rate)!.priceList!.map((priceVariant) => {
            const { priceRound, priceStandard, fuelFee, airportFee } = priceVariant;

            const freeSeats = getAvailableTariffSeats(flight, priceVariant);
            if (freeSeats <= 0 && !allowed.flight.priceWithSoldOut) return undefined;

            return (isRoundTrip ? priceRound! : priceStandard!) + fuelFee! + airportFee!;
        });
        return prices;
    }).flat(2).filter((x): x is number => x !== undefined);

    const minimalTabPrice = Math.min(...flightsPrices);

    const isSelectedFlightInside = flightsByDate.some(x => {
        if (x.id !== selectedFlight?.flightId) return false;
        return verifyRate(x.rate)?.priceList?.some(y => y.tariff?.id === selectedFlight?.tariffId);
    });

    return {
        date: convertDateIndexToMoment(rangeIndexDate),
        isSelectedFlightInside,
        price: flightsPrices.length === 0 ? '' : `CHF ${localizeMoney(minimalTabPrice, locale, 'BookingFlightPage/StepsFlightsBlocks/createFlightTab')}`,
        content: (
            <>
                {flightsByDate.length === 0 && <NoFlightsAvailable />}
                {flightsByDate.map((flight, i) => {
                    const tabContentItemProps: TabContentItemProps = {
                        flight,
                        isRoundTrip,
                        selectFlight,
                        selectedFlight,
                        bookedFlight,
                        flightCardWithoutDetail,
                    };
                    return (
                        <TabContentItem
                            key={i}
                            {...tabContentItemProps}
                        />
                    );
                })}
            </>
        ),
    };
};
