import { Airport, PriceListItem, PublicFlightListItem } from '@malesia/json-schema';
import { NotificationType } from '@malesia/react-components/dist/src/components/Page/Notifications/Notifications';
import { PassengersAges, TravelType } from '@malesia/react-components/dist/src/components/Reservation/reservation-types';
import { DateRange } from '@malesia/react-components/dist/src/utils/dateTime';
import { validatePassengersAges } from '@malesia/react-components/dist/src/utils/reservation';
import { verifyRate } from '@malesia/react-components/dist/src/utils/verify';
import { PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { BasketAirports, BasketSelectedFlights, BasketSelectedPrices } from '../../../containers/ReservationBasket/reservationBasket.types';
import { StepName } from '../config/steps';
import { bookingNotifications } from './notification';
import { BookingQueryParameters } from './queryParameters';

export type QueryParsingResult<T> = (
    | { ok: true, value: T, error?: undefined }
    | { ok: false, value?: undefined, error: PayloadAction<NotificationType> }
);

type ParsingType = 'required' | 'optional' | 'banned';

export const parseQueryTravelType = (
    parameters: BookingQueryParameters,
): QueryParsingResult<TravelType> => {
    type Key = keyof Pick<BookingQueryParameters, 'travelType'>;
    const key: Key = 'travelType';
    const value = parameters[key];
    if (!value) return { ok: false, error: bookingNotifications.requiredQuery(key) };
    const travelTypeMap: Record<NonNullable<BookingQueryParameters[Key]>, TravelType> = {
        'return': 'return',
        'one-way': 'one-way',
        'other-return': 'other-return',
    };
    const mappedValue = travelTypeMap[value];
    if (!mappedValue) return { ok: false, error: bookingNotifications.invalidQuery(key, '"return" | "one-way" | "other-return"') };
    return { ok: true, value: mappedValue };
};

export const parseQueryDates = (
    parameters: BookingQueryParameters,
    stepName: StepName,
    travelType: TravelType,
): QueryParsingResult<DateRange> => {
    const parseDate = (
        key: keyof Pick<BookingQueryParameters, 'departureDate' | 'returnDate'>,
        parsingType: ParsingType,
    ): QueryParsingResult<string | undefined> => {
        const value = parameters[key];
        if (value === undefined) {
            if (parsingType === 'required') return { ok: false, error: bookingNotifications.requiredQuery(key) };
            return { ok: true, value: undefined };
        }
        if (parsingType === 'banned') {
            return { ok: false, error: bookingNotifications.extraQuery(key) };
        }
        const date = moment.parseZone(value);
        if (!date.isValid()) {
            return { ok: false, error: bookingNotifications.invalidQuery('departureDate', 'YYYY-MM-DD') };
        }
        return { ok: true, value: date.toISOString() };
    };

    const completeParsingType: ParsingType = stepName === 'travel-dates' ? 'optional' : 'required';
    const dates = {
        departureDate: parseDate('departureDate', completeParsingType),
        returnDate: parseDate('returnDate', travelType === 'one-way' ? 'banned' : completeParsingType),
    };

    if (!dates.departureDate.ok) return dates.departureDate;
    if (!dates.returnDate.ok) return dates.returnDate;

    return {
        ok: true,
        value: {
            from: dates.departureDate.value,
            to: dates.returnDate.value,
        },
    };
};

export const parseQueryAirports = (
    parameters: BookingQueryParameters,
    stepName: StepName,
    travelType: TravelType,
    airportList: Airport[],
): QueryParsingResult<{ main: BasketAirports, other: BasketAirports }> => {
    const parseAirport = (
        key: keyof Pick<BookingQueryParameters, 'airportFrom' | 'airportTo' | 'otherAirportFrom' | 'otherAirportTo'>,
        parsingType: ParsingType,
    ): QueryParsingResult<Airport | undefined> => {
        const value = parameters[key];
        if (value === undefined) {
            if (parsingType === 'required') return { ok: false, error: bookingNotifications.requiredQuery(key) };
            return { ok: true, value: undefined };
        }
        if (parsingType === 'banned') {
            return { ok: false, error: bookingNotifications.extraQuery(key) };
        }
        const airport = airportList.find(airport => airport.code === value);
        if (!airport) return { ok: false, error: bookingNotifications.notFoundQuery(key, value) };
        return { ok: true, value: airport };
    };

    const completeParsingType: ParsingType = stepName === 'travel-dates' ? 'optional' : 'required';
    const main = {
        from: parseAirport('airportFrom', completeParsingType),
        to: parseAirport('airportTo', completeParsingType),
    };
    const other = {
        from: parseAirport('otherAirportFrom', travelType === 'other-return' ? completeParsingType : 'banned'),
        to: parseAirport('otherAirportTo', travelType === 'other-return' ? completeParsingType : 'banned'),
    };

    if (!main.from.ok) return main.from;
    if (!main.to.ok) return main.to;
    if (!other.from.ok) return other.from;
    if (!other.to.ok) return other.to;

    return {
        ok: true,
        value: {
            main: {
                from: main.from.value,
                to: main.to.value,
            },
            other: {
                from: other.from.value,
                to: main.to.value,
            },
        },
    };
};

export const parseQueryAges = (
    parameters: BookingQueryParameters,
    stepName: StepName,
): QueryParsingResult<PassengersAges> => {
    const parseAge = (
        key: keyof Pick<BookingQueryParameters, 'adult' | 'child' | 'infant'>,
        parsingType: ParsingType,
    ): QueryParsingResult<number | undefined> => {
        const value = parameters[key];
        if (value === undefined) {
            if (parsingType === 'required') return { ok: false, error: bookingNotifications.requiredQuery(key) };
            return { ok: true, value: undefined };
        }
        if (parsingType === 'banned') {
            return { ok: false, error: bookingNotifications.extraQuery(key) };
        }
        return { ok: true, value };
    };

    const completeParsingType: ParsingType = stepName === 'travel-dates' ? 'optional' : 'required';
    const ages = {
        adult: parseAge('adult', completeParsingType),
        child: parseAge('child', completeParsingType),
        infant: parseAge('infant', completeParsingType),
    };

    if (!ages.adult.ok) return ages.adult;
    if (!ages.child.ok) return ages.child;
    if (!ages.infant.ok) return ages.infant;

    const passengersAges: PassengersAges = {
        adult: ages.adult.value ?? 0,
        child: ages.child.value ?? 0,
        infant: ages.infant.value ?? 0,
    };
    if ([ages.adult.value, ages.child.value, ages.infant.value].every(x => x === undefined)) {
        passengersAges.adult = 1;
    }

    const error = validatePassengersAges(passengersAges);
    if (error) return { ok: false, error: bookingNotifications.customError(error) };

    return {
        ok: true,
        value: passengersAges,
    };
};

export const parseQueryFlights = (
    parameters: BookingQueryParameters,
    stepName: StepName,
    travelType: TravelType,
    outboundList: PublicFlightListItem[],
    returnList: PublicFlightListItem[],
): QueryParsingResult<{ flights: BasketSelectedFlights, prices: BasketSelectedPrices }> => {
    const parseFlightId = (
        key: keyof Pick<BookingQueryParameters, 'departureFlightId' | 'returnFlightId'>,
        parsingType: ParsingType,
        list: PublicFlightListItem[],
    ): QueryParsingResult<PublicFlightListItem | undefined> => {
        const value = parameters[key];
        if (value === undefined) {
            if (parsingType === 'required') return { ok: false, error: bookingNotifications.requiredQuery(key) };
            return { ok: true, value: undefined };
        }
        if (parsingType === 'banned') {
            return { ok: false, error: bookingNotifications.extraQuery(key) };
        }
        const flight = list.find(x => x.id == value);
        if (!flight) {
            return { ok: false, error: bookingNotifications.notFoundQuery(key, `${value}`) };
        }
        return { ok: true, value: flight };
    };
    const parseTariffId = (
        key: keyof Pick<BookingQueryParameters, 'departureTariffId' | 'returnTariffId'>,
        parsingType: ParsingType,
        flight?: PublicFlightListItem,
    ): QueryParsingResult<PriceListItem | undefined> => {
        const value = parameters[key];
        if (value === undefined) {
            if (parsingType === 'required') return { ok: false, error: bookingNotifications.requiredQuery(key) };
            return { ok: true, value: undefined };
        }
        if (parsingType === 'banned') {
            return { ok: false, error: bookingNotifications.extraQuery(key) };
        }
        if (!flight) {
            return { ok: false, error: bookingNotifications.notFoundQuery(key, `${value}`) };
        }
        const price = verifyRate(flight.rate)?.priceList?.find(x => x.tariff?.id == value);
        if (!price) {
            return { ok: false, error: bookingNotifications.notFoundQuery(key, `${value}`) };
        }
        return { ok: true, value: price };
    };

    const completeParsingType: ParsingType = stepName === 'select-flight-outbound' ? 'optional' : 'required';
    const flights = {
        departure: parseFlightId('departureFlightId', completeParsingType, outboundList),
        ['return']: parseFlightId('returnFlightId', travelType === 'one-way' ? 'banned' : completeParsingType, returnList),
    };

    if (!flights.departure.ok) return flights.departure;
    if (!flights.return.ok) return flights.return;

    const tariffs = {
        departure: parseTariffId('departureTariffId', completeParsingType, flights.departure.value),
        ['return']: parseTariffId('returnTariffId', travelType === 'one-way' ? 'banned' : completeParsingType, flights.return.value),
    };

    if (!tariffs.departure.ok) return tariffs.departure;
    if (!tariffs.return.ok) return tariffs.return;

    return {
        ok: true,
        value: {
            flights: {
                outbound: flights.departure.value,
                ['return']: flights.return.value,
            },
            prices: {
                outbound: tariffs.departure.value,
                ['return']: tariffs.return.value,
            },
        },
    };
};
