import { ApiCallAllotmentSortingParams } from '@malesia/json-schema';
import { AllotmentList, AllotmentListProps } from '@malesia/react-components/dist/src/components/Allotment/List';
import { LoadingTable } from '@malesia/react-components/dist/src/components/LoadingOverlay/Table';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectList,
    selectDirty,
    selectLoading,
    selectSorting,
} from '../store/selectors';
import { actions } from '../store/slice';
import { useTableColumns } from '../tableColumns';

export const AllotmentListWithStore: SFC = () => {
    const dispatch = useDispatch();
    const tableData = useSelector(selectList);
    const sorting = useSelector(selectSorting);
    const isLoading = useSelector(selectLoading);
    const isDirty = useSelector(selectDirty);

    const tableColumns = useTableColumns();

    const setSort = useCallback((data: ApiCallAllotmentSortingParams) => {
        dispatch(actions.setSorting(data));
    }, [dispatch]);

    const allotmentListProps: AllotmentListProps = {
        tableColumns,
        tableData: tableData.items,
        sorting,
        setSort,
        setSelectedRows: (rows) => {
            dispatch(actions.setSelectedRows(rows));
        },
    };

    return (
        <LoadingTable
            isLoading={isLoading}
            isDirty={isDirty}
        >
            <AllotmentList {...allotmentListProps} />
        </LoadingTable>
    );
};
