import { debounce, put, select, takeLatest, throttle } from 'typed-redux-saga';
import { logError } from '../../../../utils/log';
import { allotmentInvoicePopupActions } from '../../../containers/AllotmentInvoicePopup/slice';
import { getApiPermittedAllotmentList } from '../../../services/allotment';
import { getApiErrorMessage } from '../../../services/ApiClient';
import { allotmentListNotifications } from './notification';
import { selectPagination, selectFilterData, selectSorting } from './selectors';
import { actions } from './slice';

function* getAllotmentList() {
    try {
        const pagination = yield* select(selectPagination);
        const sorting = yield* select(selectSorting);
        const searchValues = yield* select(selectFilterData);

        const result = yield* getApiPermittedAllotmentList({
            ...pagination,
            ...sorting,
            agentId: searchValues.agent?.id,
            flightDateInterval: {
                from: searchValues.departure?.from,
                to: searchValues.departure?.to,
            },
            invoicedStatus: searchValues.invoicedStatus,
        });
        yield* put(actions.getAllotmentListSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'AllotmentListPage.getAllotmentList',
        });
        yield* put(actions.getAllotmentListError());
        const message = getApiErrorMessage(error);
        yield* put(allotmentListNotifications.unknownError(message));
    }
}

export function* getAllotmentListSaga() {
    yield* takeLatest(actions.getAllotmentList, getAllotmentList);

    function* requestList() {
        yield* put(actions.getAllotmentList());
    }

    yield* throttle(1000, [
        actions.setPage,
        actions.setSorting,
        allotmentInvoicePopupActions.confirmPopupSuccess,
        actions.resetFilterData,
    ], requestList);

    yield* debounce(1000, [
        actions.setFilterData,
        actions.setPageSize,
    ], requestList);
}
