import { allAdminRoutePaths } from './allAdminRoutePaths';

type RouteName = keyof typeof allAdminRoutePaths;
export function getRouteNameByPath(path: string): RouteName | undefined {
    const entries = Object.entries(allAdminRoutePaths) as unknown as [RouteName, string][];
    const row = entries.find(([, value]) => value === path);
    return row?.[0];
}

export function getCurrentRouteName() {
    const currentPath = window.location.pathname;
    return getRouteNameByPath(currentPath);
}
