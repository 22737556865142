import { PermissionsUserListTable, SearchFormData } from '@malesia/react-components/dist/src/components/PermissionsPage/PermissionsUserListTable';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectIsDirty,
    selectIsLoading,
    selectSearchFormData,
    selectList,
} from '../store/selectors';
import { userPermissionsListPageActions } from '../store/slice';
import { useTableColumns } from '../tableColumns';

export const SettingsPermissionsListWithStore: SFC = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoading);
    const isDirty = useSelector(selectIsDirty);
    const formData = useSelector(selectSearchFormData);
    const userList = useSelector(selectList);

    const tableColumns = useTableColumns();

    useEffect(() => {
        dispatch(userPermissionsListPageActions.getUserList());
    }, [dispatch]);

    return (
        <PermissionsUserListTable
            isLoading={isLoading}
            isDirty={isDirty}
            searchText={formData.searchText}
            isActiveOnly={formData.isActiveOnly}
            tableColumns={tableColumns}
            tableData={userList.items}
            submitForm={(data: SearchFormData) => {
                dispatch(userPermissionsListPageActions.setFilterData(data));
            }}
            userType={formData.userType}
        />
    );
};
