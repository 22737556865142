import { LocationDescriptor } from 'history';
import React, { ReactElement } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { useUser } from '../../hooks/useUser';
import { allAdminRoutePaths } from '../Routes/allAdminRoutePaths';
import { allPublicRoutePaths } from '../Routes/allPublicRoutePaths';
import { useIsAdminSite } from 'app/permissions/useIsAdminSite';

export const canOpenPublicRoute = (path: string | undefined, userIsLoggedIn: boolean): boolean => {
    if (allPublicRoutePaths.login === path) return !userIsLoggedIn;
    if (allPublicRoutePaths.accountReservations === path) return userIsLoggedIn;
    if (allPublicRoutePaths.accountDetails === path) return userIsLoggedIn;
    return true;
};

export function OnlyPublicRoute(props: RouteProps): ReactElement {
    const { userIsLoggedIn } = useUser();
    const isAdminSite = useIsAdminSite();
    const currentLocation = useLocation<{from: LocationDescriptor}>();
    const redirectTo = currentLocation.state?.from;
    const redirectToDefault: LocationDescriptor = {
        pathname: allAdminRoutePaths.reservationList,
        state: {
            from: currentLocation,
        },
    };
    if (isAdminSite) {
        return (
            <Redirect to={redirectTo ?? redirectToDefault} />
        );
    }
    const currentPath = props.location?.pathname;
    return (
        canOpenPublicRoute(currentPath, userIsLoggedIn)
            ? <Route {...props} />
            : <Redirect to={allPublicRoutePaths.home} />
    );
}
