import { useInjectSaga } from '../../../../../utils/redux-injectors';
import { calculateCostSaga, calculationWithPassengersSaga } from './saga';

export const useInjectStepSagas = () => {
    useInjectSaga({
        key: 'userBookingProcessStep4/proceedCalculationsStep4',
        saga: calculateCostSaga,
    });

    useInjectSaga({
        key: 'userBookingProcessStep4/proceedCalculationWithPassengers',
        saga: calculationWithPassengersSaga,
    });
};
