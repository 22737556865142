import { Steps } from '@malesia/react-components/dist/src/components/Steps';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectReservationBasket } from '../../../containers/ReservationBasket/reservationBasket.selectors';
import { BookingStep, stepsConfig } from '../config/steps';

const flattenItems = (acc: BookingStep[], next: BookingStep) => [...acc, next];

type StepsBreadcrumbsProps = {
    stepName: string,
};

export const StepsBreadcrumbs: SFC<StepsBreadcrumbsProps> = (props) => {
    const history = useHistory();
    const reservationBasket = useSelector(selectReservationBasket);

    const steps = Object.values(stepsConfig).reduce<BookingStep[]>(flattenItems, []);

    const items = steps
        .filter((step: BookingStep) => {
            const { skipStepBreadcrumb } = step;
            return skipStepBreadcrumb ? !skipStepBreadcrumb(reservationBasket) : true;
        })
        .map(step => {
            const currentStepNumber = stepsConfig[props.stepName].stepNumber;
            let state: 'active' | 'not-filled' | null = null;
            if (step.stepNumber === currentStepNumber) state = 'active';
            if (step.stepNumber > currentStepNumber) state = 'not-filled';
            return {
                text: step.breadcrumbTitle,
                iconName: step.breadCrumbIcon,
                state,
            };
        });
    const onClick = (i) => {
        const clickedItem = items[i];
        const clickedStep = steps.find(step => step.breadcrumbTitle === clickedItem.text);
        history.push(clickedStep!.path);
    };

    return (
        <div className='block'>
            <Steps
                items={items}
                handleClick={onClick}
            />
        </div>
    );
};
