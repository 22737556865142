import { Pagination } from '@malesia/react-components/dist/src/components/Pagination';
import { TableFooter } from '@malesia/react-components/dist/src/components/Table/TableFooter';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPagination, selectList } from '../store/selectors';
import { actions } from '../store/slice';
import { ReservationListActionsWithStore } from './ReservationListActionsWithStore';

export const ReservationListFooterWithStore: SFC = () => {
    const dispatch = useDispatch();
    const list = useSelector(selectList);
    const pagination = useSelector(selectPagination);

    return (
        <TableFooter
            actionsSlot={<ReservationListActionsWithStore />}
            paginationSlot={
                <Pagination
                    total={list.total}
                    pageTotal={list.items.length}
                    pagination={pagination}
                    maxPageSize={500}
                    setPage={(data) => {
                        dispatch(actions.setPage(data));
                    }}
                    setPageSize={(data) => {
                        dispatch(actions.setPageSize(data));
                    }}
                />
            }
        />
    );
};
