import { BackLink } from '@malesia/react-components/dist/src/components/BackLink';
import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { ReservationHistoryJson } from '@malesia/react-components/dist/src/components/Reservation/HistoryJson';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { selectReservation, selectReservationSnapshots } from './selectors';

export const ReservationHistory: SFC = () => {
    const reservation = useSelector(selectReservation);
    const snapshots = useSelector(selectReservationSnapshots);

    return (
        <>
            <PageContentBlock>
                <PageTitle
                    text={
                        <>
                            <FormattedMessage
                                id='front-app/ReservationHistoryPage:ReservationHistory'
                                defaultMessage='Reservation history'
                            />
                            {reservation && (
                                <> ({reservation.id})</>
                            )}
                        </>
                    }
                />
                <BackLink
                    to={allAdminRoutePaths.reservationList}
                    modificators='stick-to-title'
                />
                {snapshots.length > 0 && (
                    <ReservationHistoryJson snapshots={snapshots} />
                ) || (
                    <p>
                        <FormattedMessage
                            id='front-app/ReservationHistoryPage:SnapshotsNotFound'
                            defaultMessage='Snapshots not found'
                        />
                    </p>
                )}
            </PageContentBlock>
        </>
    );
};
