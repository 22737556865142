/*
 * MalesiaUpdatePasswordPage Messages
 *
 * This contains all the text for the MalesiaUpdatePasswordPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.MalesiaUpdatePasswordPage';

export default defineMessages({
    header: {
        id: `${scope}.header`,
        defaultMessage: 'This is the MalesiaUpdatePasswordPage container!',
    },
});
