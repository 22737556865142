import { ApiCallBalanceLogFiltersWithSelectedAccount } from '@malesia/json-schema';
import { all, call, debounce, put, select, takeLatest, throttle } from 'typed-redux-saga';
import { logError } from '../../../../utils/log';
import { balanceLogPermissions } from '../../../permissions/adminPages/balance/balanceLog';
import { applyPermissionDataFilter } from '../../../permissions/permissionDataFilter';
import { selectUserPermissions } from '../../../permissions/selectUserPermissions';
import { getApiErrorMessage } from '../../../services/ApiClient';
import { getApiPermittedBalanceLogRecordList, getApiPermittedBalanceLogSummaryInfo } from '../../../services/balanceLog';
import { balanceLogNotifications } from './notifications';
import {
    selectFilterData,
    selectInitialized,
    selectPagination,
    selectSorting,
} from './selectors';
import { balanceLogActions } from './slice';

function* loadTableData() {
    try {
        const initialized = yield* select(selectInitialized);
        const can = initialized.sorting && initialized.account;
        if (!can) return;

        const filterData = yield* select(selectFilterData);
        const sort = yield* select(selectSorting);
        const pagination = yield* select(selectPagination);
        const userPermissions = yield* selectUserPermissions();

        let params: ApiCallBalanceLogFiltersWithSelectedAccount = {
            ...pagination,
            query: filterData.query || undefined,
            selectedAccountId: filterData.account?.id!,
            relatedAccountId: filterData.relatedAccount?.id ? [filterData.relatedAccount.id] : undefined,
            typeId: filterData.categories?.map(x => x.id),
            dateFrom: filterData.date?.from,
            dateTo: filterData.date?.to,
            sortBy: sort.sortBy,
            sortOrder: sort.sortOrder,
        };

        params = applyPermissionDataFilter(params, {
            selectedAccountId: userPermissions.has(balanceLogPermissions.accountFilter),
            relatedAccountId: userPermissions.has(balanceLogPermissions.relatedAccountFilter),
            typeId: userPermissions.has(balanceLogPermissions.categoryFilter),
        });

        const canInfoRequest = (): boolean => {
            if (userPermissions.has(balanceLogPermissions.getFullBalanceInfo) && !params.selectedAccountId) {
                return false;
            }
            return userPermissions.has(balanceLogPermissions.getAnyBalanceInfo);
        };

        const response = yield* all({
            list: getApiPermittedBalanceLogRecordList(params),
            summary: canInfoRequest() ? getApiPermittedBalanceLogSummaryInfo(params) : call(() => undefined),
        });

        yield* put(balanceLogActions.getBalanceLogListSuccess(response));
    }
    catch (error) {
        logError({
            error,
            target: 'BalanceLogPage.loadTableData',
        });
        yield* put(balanceLogActions.getBalanceLogListFail());
        const message = getApiErrorMessage(error);
        yield* put(balanceLogNotifications.unknownError(message));
    }
}

export function* loadTableDataSaga() {
    yield* takeLatest(
        balanceLogActions.getBalanceLogList,
        loadTableData,
    );

    function* requestLoadTableData() {
        yield* put(balanceLogActions.getBalanceLogList());
    }

    yield* throttle(1000, [
        balanceLogActions.setPage,
        balanceLogActions.setSorting,
        balanceLogActions.initAccountSuccess,
    ], requestLoadTableData);

    yield* debounce(1000, [
        balanceLogActions.setFilterData,
        balanceLogActions.setPageSize,
    ], requestLoadTableData);
}
