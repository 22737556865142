/**
 * Removes item from array (without mutation)
 * @param list - Array of any type
 * @param startFromIndex - remove starting from index
 * @param removeCount - items number to remove
 */
export function removeFromArray<T> (list: T[], startFromIndex: number, removeCount: number = 1) {
    return [
        ...list.slice(0, startFromIndex),
        ...list.slice(startFromIndex + removeCount),
    ];
}

/**
 * Replace item in array (without mutation)
 * @param list - Array of any type
 * @param replaceAtIndex - index item will be replaced at
 * @param newItem - replacement item
 */
export function replaceArrayItemByIndex<T> (list: T[], replaceAtIndex: number, newItem: T) {
    return [
        ...list.slice(0, replaceAtIndex),
        newItem,
        ...list.slice(replaceAtIndex + 1),
    ];
}

/**
 * Insert item in array (without mutation)
 * @param list - Array of any type
 * @param insertAtIndex - index item will be replaced at
 * @param insertItem - replacement item
 */
export function insertItemInArray<T> (list: T[], insertAtIndex: number, insertItem: T) {
    return [
        ...list.slice(0, insertAtIndex),
        insertItem,
        ...list.slice(insertAtIndex),
    ];
}

/**
 * Check is index presence in the array
 * @param array - target array
 * @param index - index for assert
 *
 * @return boolean
 */
export function arrayHasIndex<TYPE> (array: TYPE[], index: number): boolean {
    // eslint-disable-next-line no-prototype-builtins
    return Array.isArray(array) && array.hasOwnProperty(index);
}
