import {
    ApiCallBalanceLogAccountFilters,
    ApiCallBalanceLogFiltersWithSelectedAccount,
    BalanceLogAccountSummaryInfo,
    BalanceLogPaginatedList,
    BalanceLogRecordTypeEntireList,
} from '@malesia/json-schema';
import { call } from 'typed-redux-saga';
import { downloadFile } from '../../utils/downloadFile';
import { balanceLogPermissions } from '../permissions/adminPages/balance/balanceLog';
import { selectUserPermissions } from '../permissions/selectUserPermissions';
import { UserPermissions } from '../permissions/userPermissions';
import { apiClientRequest } from './ApiClient';

export function* getApiBalanceLogRecordList(params: ApiCallBalanceLogFiltersWithSelectedAccount) {
    const response: BalanceLogPaginatedList = yield* call(apiClientRequest, {
        requestId: 'balanceLogList',
        query: params,
    });
    return response;
}
export function* getApiUserBalanceLogRecordList(params: ApiCallBalanceLogFiltersWithSelectedAccount) {
    const response: BalanceLogPaginatedList = yield* call(apiClientRequest, {
        requestId: 'userBalanceLogList',
        query: params,
    });
    return response;
}
export function* getApiPermittedBalanceLogRecordList(params: ApiCallBalanceLogFiltersWithSelectedAccount) {
    const userPermissions: UserPermissions = yield* selectUserPermissions();

    if (userPermissions.has(balanceLogPermissions.getFullList)) {
        return yield* getApiBalanceLogRecordList(params);
    }
    if (userPermissions.has(balanceLogPermissions.getMyList)) {
        return yield* getApiUserBalanceLogRecordList(params);
    }
    // Bug. We should not open the page if there are no rights.
    throw new Error('Can not get balance log without permissions');
}

export function* getApiBalanceLogTypeList() {
    const response: BalanceLogRecordTypeEntireList = yield* call(apiClientRequest, {
        requestId: 'balanceLogTypeList',
    });
    return response;
}

export function* getApiBalanceLogSummaryInfo(query: ApiCallBalanceLogAccountFilters) {
    const response: BalanceLogAccountSummaryInfo = yield* call(apiClientRequest, {
        requestId: 'balanceLogSummaryInfo',
        query,
    });
    return response;
}
export function* getApiUserBalanceLogSummaryInfo() {
    const response: BalanceLogAccountSummaryInfo = yield* call(apiClientRequest, {
        requestId: 'userBalanceLogSummaryInfo',
    });
    return response;
}
export function* getApiPermittedBalanceLogSummaryInfo(params: ApiCallBalanceLogAccountFilters) {
    const userPermissions: UserPermissions = yield* selectUserPermissions();

    if (userPermissions.has(balanceLogPermissions.getFullBalanceInfo)) {
        return yield* getApiBalanceLogSummaryInfo(params);
    }
    if (userPermissions.has(balanceLogPermissions.getMyBalanceInfo)) {
        return yield* getApiUserBalanceLogSummaryInfo();
    }
    // Bug. We should not open the page if there are no rights.
    throw new Error('Can not get balance log summary without permissions');
}

export function* downloadApiBalanceLogOverviewPdf(query: ApiCallBalanceLogAccountFilters) {
    const response = yield* call(apiClientRequest, {
        requestId: 'balanceLogOverviewPDF',
        query,
    });

    // TODO might be better to extract to function and use wherever need to download file
    const filename = response.headers.get('Content-Disposition')
        .split(';').map(header => header.split('=').map(item => item.trim()))
        .filter(([key, value]) => key === 'filename').map(([key, value]) => value)?.[0] || 'TransactionOverview.pdf';
    yield* downloadFile(response, filename);
}
