import { call, put, select, takeLatest } from 'typed-redux-saga';
import { ApiResponseError } from '../../../api/malesia/ApiResponseError';
import JsonSchemaValidationError from '../../../api/malesia/JsonSchemaValidationError';
import { logError } from '../../../utils/log';
import { selectLocale } from '../../containers/App/selectors';
import { createCustomValidationError } from '../../containers/ValidationErrorMessage';
import { apiClientRequest } from '../../services/ApiClient';
import { recoverPasswordModalActions } from './slice';

export function* submitData(action: ReturnType<typeof recoverPasswordModalActions.submitData>) {
    const recoverPasswordRequestData = action.payload;
    const locale: string = yield* select(selectLocale);

    const requestData = {
        requestId: 'authRecoverPassword',
        requestPayload: recoverPasswordRequestData,
        uriParams: { locale },
    };

    try {
        yield* call(apiClientRequest, requestData);
        yield* put(recoverPasswordModalActions.submitSuccess());
    }
    catch (error) {
        logError({
            error,
            target: 'RecoverPasswordModal.submitData',
        });
        if (error instanceof JsonSchemaValidationError) {
            yield* put(recoverPasswordModalActions.submitValidationError([]));
        }
        if (error instanceof ApiResponseError) {
            // API returns 422 status code on email not found
            if (error.status === 422) {
                // Add custom error
                yield* put(
                    recoverPasswordModalActions.submitValidationError([
                        createCustomValidationError(
                            '/email',
                            'custom/userExists',
                            'User is not registered yet',
                        ),
                    ]),
                );
            }
        }
        yield* put(recoverPasswordModalActions.submitFailed());
    }
}

export function* submitDataSaga() {
    yield* takeLatest(recoverPasswordModalActions.submitData, submitData);
}
