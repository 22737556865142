import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { ReservationPdfState, DownloadReservationPdfOptions } from './types';

export const initialState: ReservationPdfState = {
    isLoadingOverviewMap: {},
    isLoadingInvoiceMap: {},
};

const reservationPdfSlice = createSlice({
    name: 'reservationPdf',
    initialState,
    reducers: {
        downloadOverviewPdf(state, action: PayloadAction<DownloadReservationPdfOptions>) {
            state.isLoadingOverviewMap[action.payload.reservationId] = true;
        },
        downloadOverviewPdfSuccess(state, action: PayloadAction<number | string>) {
            state.isLoadingOverviewMap[action.payload] = false;
        },
        downloadOverviewPdfFail(state, action: PayloadAction<number | string>) {
            state.isLoadingOverviewMap[action.payload] = false;
        },
        downloadInvoicePdf(state, action: PayloadAction<DownloadReservationPdfOptions>) {
            state.isLoadingInvoiceMap[action.payload.reservationId] = false;
        },
        downloadInvoicePdfSuccess(state, action: PayloadAction<number | string>) {
            state.isLoadingInvoiceMap[action.payload] = false;
        },
        downloadInvoicePdfFail(state, action: PayloadAction<number | string>) {
            state.isLoadingInvoiceMap[action.payload] = false;
        },
        unmount() {
            // Not reset. It's global loading.
        },
    },
});

export const {
    actions: reservationPdfActions,
    reducer: reservationPdfReducer,
    name: reservationPdfSliceKey,
} = reservationPdfSlice;
