import { debounce, put, select, takeLatest, throttle } from 'typed-redux-saga';
import { logError } from '../../../utils/log';
import { getApiErrorMessage } from '../../services/ApiClient';
import { GetCustomerListParams, getApiCustomerList } from '../../services/customer';
import { customerNotifications } from './notification';
import { selectInitialized, selectFilterData, selectPagination } from './selectors';
import { actions } from './slice';

export function* getCustomerList() {
    try {
        const initialized = yield* select(selectInitialized);
        const can = initialized.sorting;
        if (!can) return;

        const filterData = yield* select(selectFilterData);
        const pagination = yield* select(selectPagination);

        const params: GetCustomerListParams = {
            query: filterData.searchText,
            isActive: filterData.isActiveOnly === true ? true : undefined,
            sortBy: filterData.sortBy,
            sortOrder: filterData.sortOrder,
            page: pagination.page,
            pageSize: pagination.pageSize,
        };
        const result = yield* getApiCustomerList(params);
        yield* put(actions.getCustomerListSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'CustomerListPage.getCustomerList',
        });
        const message = getApiErrorMessage(error);
        yield* put(customerNotifications.unknownError(message));
    }
}

export function* getCustomerListSaga() {
    yield* takeLatest(actions.getCustomerList, getCustomerList);

    function* requestGetCustomerList() {
        yield* put(actions.getCustomerList());
    }

    yield* throttle(1000, [
        actions.setPage,
        actions.setSorting,
    ], requestGetCustomerList);

    yield* debounce(1000, [
        actions.setFilterData,
        actions.setPageSize,
    ], requestGetCustomerList);
}
