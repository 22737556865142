import { Option } from '@malesia/json-schema';
import { BackLink } from '@malesia/react-components/dist/src/components/BackLink';
import { LoadingPage } from '@malesia/react-components/dist/src/components/LoadingOverlay/Page';
import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { RateOptionEdit } from '@malesia/react-components/dist/src/components/Rate/Option/Edit';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import {
    getOptionTypeListSaga,
    getOptionDataSaga,
    updateOptionDataSaga,
} from './saga';
import {
    selectOptionData,
    selectLoadingOption,
    selectOptionTypeData,
    selectLoadingTypes,
} from './selectors';
import { actions, reducer, sliceKey } from './slice';

type RouteParams = {
    optionId: string,
};

type Props = RouteComponentProps<RouteParams>;

export const OptionEditPage: SFC<Props> = (props) => {
    useInjectReducer({ actions, key: sliceKey, reducer });
    useInjectSaga({
        key: `${sliceKey}OptionType`,
        saga: getOptionTypeListSaga,
    });
    useInjectSaga({ key: `${sliceKey}Get`, saga: getOptionDataSaga });
    useInjectSaga({
        key: `${sliceKey}Update`,
        saga: updateOptionDataSaga,
    });

    const optionData = useSelector(selectOptionData);
    const optionTypeData = useSelector(selectOptionTypeData);
    const loadingOption = useSelector(selectLoadingOption);
    const loadingTypes = useSelector(selectLoadingTypes);
    const isAnythingLoading = loadingOption || loadingTypes;

    const dispatch = useDispatch();

    const { match } = props;
    const { params } = match;
    const { optionId } = params;

    useEffect(() => {
        if (!optionId) return;
        dispatch(actions.getOptionData(optionId));
    }, [optionId, dispatch]);

    useEffect(() => {
        dispatch(actions.getOptionTypeList());
    }, [dispatch]);

    const onSubmitForm = (optionData: Option) => {

        if (!optionId) return;
        dispatch(actions.updateOptionData({
            optionId,
            optionData,
        }));
    };

    return (
        <PageContentBlock>
            <PageTitle
                text={
                    <FormattedMessage
                        id='front-app/OptionEditPage:EditOption'
                        defaultMessage='Edit Option'
                    />
                }
            />
            <BackLink
                to={allAdminRoutePaths.rateOptionList}
                modificators='stick-to-title'
            />
            <LoadingPage isLoading={isAnythingLoading} />
            {optionData && !isAnythingLoading && (
                <RateOptionEdit
                    typeFieldDisabled
                    defaultValues={optionData}
                    typeList={optionTypeData.items}
                    onSubmit={onSubmitForm}
                />
            )}
        </PageContentBlock>
    );
};
