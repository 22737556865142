import React from 'react';
import { FormattedMessage } from 'react-intl';
import { appNotification } from '../../../containers/App/appNotification';
import { createLocalNotification } from '../../../containers/App/utils';

const notification = createLocalNotification('BalanceLog', {
    error: (
        <FormattedMessage
            id='front-app/BalanceLog/Error:title'
            defaultMessage='Balance Log Error'
        />
    ),
    warning: (
        <FormattedMessage
            id='front-app/BalanceLog/Warning:title'
            defaultMessage='Balance Log Warning'
        />
    ),
    info: (
        <FormattedMessage
            id='front-app/BalanceLog/Info:title'
            defaultMessage='Balance Log Info'
        />
    ),
    success: (
        <FormattedMessage
            id='front-app/BalanceLog/Info:title'
            defaultMessage='Balance Log Info'
        />
    ),
});

export const balanceLogNotifications = {
    notSelectedAccount: notification.error(
        <FormattedMessage
            id='front-app/notificationMessages/loadOverview:notSetSelectedAccountId'
            defaultMessage='not selected account'
        />,
    ),
    unknownError: (details?: JSX.Element | string) => (
        notification.error(appNotification.texts.unknownError(details))
    ),
};
