import { NotificationType } from '@malesia/react-components/dist/src/components/Page/Notifications/Notifications';
import { PayloadAction } from '@reduxjs/toolkit';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { logError } from '../../../../utils/log';
import { appNotification } from '../../../containers/App/appNotification';
import { createLocalNotification } from '../../../containers/App/utils';
import { checkApiError } from '../../../services/ApiClient';

const notification = createLocalNotification('Flight', {
    error: (
        <FormattedMessage
            id='front-app/Flight/Notification/Error:title'
            defaultMessage='Flight Error'
        />
    ),
    warning: (
        <FormattedMessage
            id='front-app/Flight/Notification/Warning:title'
            defaultMessage='Flight Warning'
        />
    ),
    info: (
        <FormattedMessage
            id='front-app/Flight/Notification/Info:title'
            defaultMessage='Flight Info'
        />
    ),
    success: (
        <FormattedMessage
            id='front-app/Flight/Notification/Info:title'
            defaultMessage='Flight Info'
        />
    ),
});

export const flightNotifications = {
    checkFields: notification.warning(appNotification.texts.checkFields),
    createdSuccessfully: notification.success(appNotification.texts.createdSuccessfully),
    updatedSuccessfully: notification.success(appNotification.texts.updatedSuccessfully),
    cantDeleteAllotment: notification.error(
        <FormattedMessage
            id='front-app/Flight/Notification/Error:cantDeleteAllotment'
            defaultMessage='Allotment can not be deleted.'
        />,
    ),
    cantChangeAllotment: notification.error(
        <FormattedMessage
            id='front-app/Flight/Notification/Error:cantChangeAllotment'
            defaultMessage='Immutable properties can not be changed.'
        />,
    ),
    notHaveEnoughSeats: notification.error(
        <FormattedMessage
            id='front-app/Flight/Notification/Error:notHaveEnoughSeats'
            defaultMessage='The flight does not have enough seats'
        />,
    ),
    unknownError: (details?: JSX.Element | string) => (
        notification.error(appNotification.texts.unknownError(details))
    ),
};

export const parseFlightError = (
    rawError: unknown,
): PayloadAction<NotificationType> => {
    if (!checkApiError(rawError)) {
        logError({
            target: 'notifyFlightError.Unhandled',
            error: rawError,
        });
        return flightNotifications.unknownError();
    }
    const item = rawError.response?.errors?.[0];
    switch (item?.error) {
        case 'ERROR_ALLOTMENT_DELETED': {
            return flightNotifications.cantDeleteAllotment;
        }
        case 'ERROR_IMMUTABLE_PROPERTY_IS_CHANGED': {
            return flightNotifications.cantChangeAllotment;
        }
        case 'ERROR_ALLOTMENT_DEPENDENCY_NOT_EXIST': {
            return flightNotifications.cantChangeAllotment;
        }
        case 'ERROR_AVAILABLE_SEATS_NOT_ENOUGH': {
            return flightNotifications.notHaveEnoughSeats;
        }
        default: {
            logError({
                error: item,
                target: 'notifyFlightError.UnhandledCase',
            });
            return flightNotifications.unknownError(rawError.response.message);
        }
    }
};
