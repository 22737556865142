import { eventColumns } from '@malesia/react-components/dist/src/components/EventLog/List/tableColumns';
import { LoadingTable } from '@malesia/react-components/dist/src/components/LoadingOverlay/Table';
import { Table } from '@malesia/react-components/dist/src/components/Table';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectList,
    selectLoading,
    selectDirty,
    selectSorting,
} from '../store/selectors';
import { actions } from '../store/slice';

export const EventLogWithStore: SFC = () => {
    const list = useSelector(selectList);
    const sorting = useSelector(selectSorting);
    const isLoading = useSelector(selectLoading);
    const isDirty = useSelector(selectDirty);

    const dispatch = useDispatch();

    const columns = useMemo(() => eventColumns(), []);

    const handleSetSort = useCallback((data) => {
        dispatch(actions.setSorting(data));
    }, [dispatch]);

    return (
        <LoadingTable
            isLoading={isLoading}
            isDirty={isDirty}
        >
            <Table
                columns={columns}
                data={list.items}
                setSort={handleSetSort}
                defaultSortBy={sorting.sortBy}
                defaultSortOrder={sorting.sortOrder}
                manualSortBy={true}
            />
        </LoadingTable>
    );
};
