import { addQueryParameters, getQueryParameters, makePath } from '../../../../utils/uriUtils';
import { allAdminRoutePaths } from '../allAdminRoutePaths';

export type ReservationQueryParameters = (
    | { source: 'reservationList' }
    | { source: 'invoiceOverview' }
    | { source: 'passengerList', flightId: number }
);

const defaultBackUrl = allAdminRoutePaths.reservationList;
const getBackUrl = (params: ReservationQueryParameters): string | undefined => {
    switch (params.source) {
        case 'reservationList': {
            return undefined;
        }
        case 'invoiceOverview': {
            return allAdminRoutePaths.reservationInvoiceList;
        }
        case 'passengerList': {
            const { flightId } = params;
            if (!flightId) return undefined;
            return makePath(allAdminRoutePaths.flightPassengerList, {
                flightId,
            });
        }
        default: {
            return undefined;
        }
    }
};

export const reservation = {
    forward: (reservationId: number | undefined, parameters: ReservationQueryParameters): string => {
        const path = !reservationId ? allAdminRoutePaths.reservationNew : makePath(allAdminRoutePaths.reservationEdit, {
            reservationId,
        });
        const result = addQueryParameters(path, parameters);
        return result;
    },
    back: (highlightReservationId?: number): string => {
        const params = getQueryParameters<ReservationQueryParameters>({
            source: 'string',
            flightId: 'number',
        });
        const path = getBackUrl(params) ?? defaultBackUrl;
        const result = addQueryParameters(path, { highlightReservationId });
        return result;
    },
};
