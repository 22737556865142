import { filterObject, getObjectEntries, mapObject } from '@malesia/react-components/dist/src/utils/object';

export const toInt = (value: string | undefined) => {
    if (value === undefined) return undefined;

    if (`${Number.parseInt(value)}` === value) {
        return Number.parseInt(value);
    }

    return value;
};

export const toFloat = (value: string | undefined) => {
    if (value === undefined) return undefined;

    if (`${Number.parseFloat(value)}` === value) {
        return Number.parseFloat(value);
    }

    return value;
};

export const toNumber = (value?: string): number | undefined => {
    if (typeof value !== 'string') return undefined;
    if (value === '') return undefined;
    if (Number.isNaN(value)) return undefined;
    const numberValue = Number.parseFloat(value);
    if (Number.isNaN(numberValue)) return undefined;
    return numberValue;
};

export const makePath = (
    path: string,
    params?: Record<string, string | number | undefined>,
): string => {
    const obj = filterObject(params || {}, (x): x is any => x[1] !== undefined) as Record<string, string | number>;
    const entries = getObjectEntries(obj);
    const result = entries.reduce((acc, [key, value]) => acc.replace(`:${key}`, `${value}`), path);
    return result;
};
/** ToDo: make pure function. Move window.location.search outside */
export const getQueryParameters = <T extends Record<string, any>>(
    types: { [Key in keyof T]-?: NonNullable<T[Key]> extends number ? 'number' : 'string' },
) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const result = Object.fromEntries(urlSearchParams.entries());
    const mapped = mapObject(result, ([key, value]) => {
        if (types[key] === 'number') return toNumber(value);
        return value;
    });
    return mapped as unknown as T;
};
export const addQueryParameters = (
    path: string,
    params?: Record<string, string | number | undefined>,
) => {
    const obj = filterObject(params || {}, (x): x is any => x[1] !== undefined) as Record<string, string>;
    const searchParams = new URLSearchParams(obj);
    const hasValues = Object.keys(obj).length !== 0;
    const result = hasValues ? `${path}?${searchParams.toString()}` : path;
    return result;
};
