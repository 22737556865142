import { ReservationInvoice } from '@malesia/json-schema';
import { push } from 'connected-react-router';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../../utils/log';
import { datatransService, InitTransactionParams } from '../../../services/Datatrance/datatrans.service';
import { paymentNotifications } from '../../ReservationPaymentPage/store/notification';
import { datatransErrorPageActions } from './datatransError.slice';
import { selectReservation } from './datatransErrorPage.selectors';

export function* openPaymentPopup() {
    try {
        const reservation = yield* select(selectReservation);
        const reservationId = reservation!.id;
        const invoice = reservation!.invoice as ReservationInvoice;
        const requestData: InitTransactionParams = {
            source: 'customerReservation',
            reservationId,
            amount: invoice.total,
        };
        const transaction = yield* call(datatransService.paymentDatatransReservationInit, requestData);

        yield* call(datatransService.showDatatransPopup, transaction.transactionId);

    }
    catch (error) {
        logError({
            error,
            target: 'MalesiaFlightsPage.loadFlights',
        });
        yield* put(paymentNotifications.datatransError());
        yield* put(push('/'));
    }
}

export function* openPaymentPopupSaga() {
    yield* takeLatest(
        datatransErrorPageActions.openPaymentPopup,
        openPaymentPopup,
    );
}
