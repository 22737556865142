import { UserProfile } from '@malesia/json-schema';
import { SearchUser, SearchUserProps } from '@malesia/react-components/dist/src/components/Form/Field/SearchUser';
import { mapUserProfileToUserSearchItem } from '@malesia/react-components/dist/src/components/Form/Field/SearchUser/utils';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectReservationOwnerList, selectReservationOwnerQuery, selectSelectedReservationOwner } from '../../../../containers/AuxiliaryData/selectors';
import { auxiliaryActions } from '../../../../containers/AuxiliaryData/slice';

export const SelectUser: SFC = () => {
    const dispatch = useDispatch();

    const query = useSelector(selectReservationOwnerQuery);
    const list = useSelector(selectReservationOwnerList);
    const selectedOwner = useSelector(selectSelectedReservationOwner);

    useEffect(() => {
        dispatch(auxiliaryActions.searchReservationOwnerList(''));
    }, [dispatch]);

    const searchUserProps: SearchUserProps<UserProfile> = {
        items: list.filter(x => x),
        toItem: mapUserProfileToUserSearchItem,
        value: selectedOwner,
        setValue: (user) => {
            dispatch(auxiliaryActions.selectReservationOwner(user?.id));
        },
        query,
        setQuery: (text) => {
            dispatch(auxiliaryActions.searchReservationOwnerList(text));
        },
        label: (
            <FormattedMessage
                id='front-app/ReservationNewPage/Steps/StepBillingInformation:SelectUser'
                defaultMessage='Select user'
            />
        ),
    };

    return (
        <SearchUser {...searchUserProps} />
    );
};
