import { Agent, Allotment } from '@malesia/json-schema';
import { BackLink } from '@malesia/react-components/dist/src/components/BackLink';
import { FlightEdit, FlightEditProps } from '@malesia/react-components/dist/src/components/Flight/Edit';
import { LoadingForm } from '@malesia/react-components/dist/src/components/LoadingOverlay/Form';
import { LoadingPage } from '@malesia/react-components/dist/src/components/LoadingOverlay/Page';
import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { ValueConfig, useValueForm } from '@malesia/react-components/dist/src/hooks/valueForm';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectAgentList, selectAgentQuery } from '../../containers/AuxiliaryData/selectors';
import { auxiliaryActions } from '../../containers/AuxiliaryData/slice';
import { FlightAircraftTemplatePopup } from '../../containers/FlightAircraftTemplate/FligthAircraftTemplatePopup';
import { backLinks } from '../../containers/Routes/backLinks';
import { flightNewPermissions } from '../../permissions/adminPages/flight/flightNew';
import { useUserPermissions } from '../../permissions/useUserPermissions';
import { useFlightConfig } from './config';
import { useInjectFlightNew } from './store/inject';
import { flightNotifications } from './store/notification';
import {
    selectCreationFlightData,
    selectLoading,
    selectIsLoadingCreationFlightData,
    selectFlightFormData,
    selectRepeatingFormData,
    selectShowSettingButton,
    selectAllotments,
} from './store/selectors';
import { actions } from './store/slice';

export const FlightNewPage: SFC = () => {
    useInjectFlightNew();

    const flightFormData = useSelector(selectFlightFormData);
    const allotments = useSelector(selectAllotments);
    const repeatingFormData = useSelector(selectRepeatingFormData);
    const creationFlightData = useSelector(selectCreationFlightData);
    const showSettingsButton = useSelector(selectShowSettingButton);
    const loading = useSelector(selectLoading);
    const isLoadingCreationFlightData = useSelector(selectIsLoadingCreationFlightData);
    const agentList = useSelector(selectAgentList);
    const agentQuery = useSelector(selectAgentQuery);
    const userPermissions = useUserPermissions();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.getCreationFlightData());
        dispatch(auxiliaryActions.searchAgentList(''));
    }, [dispatch]);

    const config = useFlightConfig('new', creationFlightData);

    const addAllotmentForm = useValueForm<Agent>({
        config: (): ValueConfig<Agent> => ({
            type: 'object',
            items: agentList,
            query: agentQuery,
            setQuery: (value) => {
                dispatch(auxiliaryActions.searchAgentList(value));
            },
            hidden: !userPermissions.has(flightNewPermissions.editAllotment),
        }),
        onChange: (agent) => {
            const allotment = {
                agent,
                tariffGroup: creationFlightData.tariffGroupList.items[0],
            } as Allotment;
            dispatch(actions.setAllotmentData([...allotments, allotment]));
        },
    });

    const flightEditProps: FlightEditProps = {
        info: {
            config: config.flightConfig,
            propsValues: flightFormData,
            onChange: (data) => {
                const filteredData = config.filterFlightData(data);
                dispatch(actions.setFlightData(filteredData));
            },
            validateForm: config.validateFlight,
        },
        tariff: {
            tariffGroups: creationFlightData.tariffGroupList.items,
            config: config.seatsConfig,
        },
        templateSettings: {
            canClick: showSettingsButton,
            click: () => {
                dispatch(actions.clickSettings());
            },
        },
        allotment: {
            addAllotmentField: addAllotmentForm.field,
            removeHidden: (allotment) => (
                !!allotment.invoice
                || !userPermissions.has(flightNewPermissions.editAllotment)
            ),
            propsValues: allotments,
            onChange: (data) => {
                dispatch(actions.setAllotmentData(data));
            },
            config: config.allotmentConfig,
            allowed: userPermissions.has(flightNewPermissions.readAllotment),
        },
        repeating: {
            config: config.repeatingConfig,
            propsValues: repeatingFormData,
            onChange: (data) => {
                dispatch(actions.setRepeatingData(data));
            },
            validateForm: config.validateRepeating,
            allowed: userPermissions.has(flightNewPermissions.createFlightSequence),
        },
        submit: (isSequence: boolean) => {
            dispatch(isSequence ? actions.createFlightSequence() : actions.createFlight());
        },
        notifyIncorrectForm: () => {
            dispatch(flightNotifications.checkFields);
        },
    };

    return (
        <PageContentBlock>
            <PageTitle
                text={
                    <FormattedMessage
                        id='front-app/FlightNewPage:Title'
                        defaultMessage='New flight'
                    />
                }
            />
            <BackLink
                to={backLinks.flight.back({})}
                modificators='stick-to-title'
            />
            <LoadingPage isLoading={isLoadingCreationFlightData} />
            {!isLoadingCreationFlightData && (
                <LoadingForm isLoading={loading}>
                    <FlightEdit {...flightEditProps} />
                </LoadingForm>
            )}
            <FlightAircraftTemplatePopup />
        </PageContentBlock>
    );
};
