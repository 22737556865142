import { FlightCompanyList } from '@malesia/react-components/dist/src/components/Flight/Company/List';
import { SortingDataType } from '@malesia/react-components/dist/src/components/Table';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectIsLoading,
    selectList,
    selectFilterData,
    selectIsDirty,
} from '../selectors';
import { actions } from '../slice';
import { useTableColumns } from '../tableColumns';

export const FlightCompanyListWithStore: SFC = () => {
    const flightCompanyList = useSelector(selectList);
    const filterData = useSelector(selectFilterData);
    const isLoading = useSelector(selectIsLoading);
    const isDirty = useSelector(selectIsDirty);

    const dispatch = useDispatch();

    const handleSetSort = (data: SortingDataType) => {
        dispatch(actions.setSorting(data));
    };

    const flightCompanyListColumns = useTableColumns();

    return (
        <FlightCompanyList
            isLoading={isLoading}
            isDirty={isDirty}
            onSubmit={(data) => {
                dispatch(actions.updateFilterData(data));
            }}
            searchText={filterData.searchText}
            isActiveOnly={filterData.isActiveOnly}
            tableColumns={flightCompanyListColumns}
            tableData={flightCompanyList.items}
            sortBy={filterData.sortBy}
            sortOrder={filterData.sortOrder}
            setSort={handleSetSort}
        />
    );
};
