import { createCustomValidationError } from '../../app/containers/ValidationErrorMessage';
import { CustomValidator } from './getValidationFunction';
import { getValueFromPath } from './getValueFromPath';
import { passwordConfirmValidator } from './passwordConfirmValidator';

export function createCustomPasswordConfirmValidator<T>(instancePath: string, instanceConfirmPath: string) {
    const customValidator: CustomValidator<Partial<T>> = (data) => {
        const password = getValueFromPath(data, instancePath);
        const passwordConfirm = getValueFromPath(data, instanceConfirmPath);

        const isValid = passwordConfirmValidator(password, passwordConfirm);

        if (!isValid) {
            const error = createCustomValidationError(
                instanceConfirmPath,
                'repeated',
                'Should be equal to password',
            );

            return [error];
        }
        else {
            return [];
        }
    };

    return customValidator;
}
