import { Country, CountryEntireList } from '@malesia/json-schema';
import { call } from 'typed-redux-saga';
import { apiClientRequest } from './ApiClient';

const countryCodeOrder = [
    'CH', // Switzerland
    'KV', // Kosovo
    'FR', // France
    'DE', // Germany
    'AT', // Austria
    'IT', // Italy
    'MK', // Macedonia
];
export const sortFavoriteCountries = (arr: Country[]) => {
    const getOrderIndex = (x: Country) => {
        const index = countryCodeOrder.indexOf(x.code);
        if (index === -1) return countryCodeOrder.length;
        return index;
    };
    const result = [...arr];
    result.sort((x, y) => getOrderIndex(x) - getOrderIndex(y));
    return result;
};

export function* getApiPublicCountyList() {
    const result: CountryEntireList = yield* call(apiClientRequest, {
        requestId: 'countryListPublic',
    });
    return result;
}

export function* getApiAdminCountyList() {
    const result: CountryEntireList = yield* call(apiClientRequest, {
        requestId: 'countryEntireList',
    });
    return result;
}
