import { PublicFlightListItem } from '@malesia/json-schema';
import { FlightDirection } from '@malesia/react-components/dist/src/components/Reservation/reservation-types';
import { FlightTab } from '@malesia/react-components/dist/src/components/Tabs/Flight';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { createFlightsTabs, weekRange } from '../../../../../utils/reservation/flightsTabs';
import { BookedFlight, SelectedFlight } from '../../../../../utils/reservation/selectedFlight';
import { createFlightTab } from '../../../BookingFlightPage/StepsFlightsBlocks/createFlightTab';
import { useReservationAllowed } from '../../permissions';
import { selectReservation } from '../../store/selectors';
import { reservationNewPageActions } from '../../store/slice';

type UseAdminFlightTabsResult = {
    tabs: FlightTab[],
    activeTabIndex: number,
};
export const useAdminFlightTabs = (
    flightList: PublicFlightListItem[],
    selectedFlight: SelectedFlight | undefined,
    bookedFlight: BookedFlight | undefined,
    flightDirection: FlightDirection,
): UseAdminFlightTabsResult => {
    const { travelInfo } = useSelector(selectReservation);

    const { locale } = useIntl();
    const allowed = useReservationAllowed();
    const dispatch = useDispatch();

    const isRoundTrip = travelInfo.type === 'return';
    const travelDate = flightDirection === 'outbound' ? travelInfo.outbound.date : travelInfo.return.date;
    const flightsTabs = createFlightsTabs({
        dateISO: travelDate,
        rangeOptions: weekRange,
        flightList,
        bookedFlightId: bookedFlight?.flightId,
        allowed,
    });
    const defaultIndex = Math.min(flightsTabs.length - 1, weekRange.daysBefore);

    const selectFlight = (flight: SelectedFlight) => {
        const { flightId, tariffId } = flight;
        dispatch(reservationNewPageActions.selectFlight({
            flightDirection,
            flightId,
            tariffId,
        }));
    };

    const tabs = flightsTabs.map<FlightTab>((flightsTab) => (
        createFlightTab({
            flightsByDate: flightsTab.flights,
            isRoundTrip,
            selectFlight,
            selectedFlight,
            bookedFlight,
            rangeIndexDate: flightsTab.date,
            flightCardWithoutDetail: true,
            allowed,
            locale,
        })
    ));

    const activeTabIndex = tabs.findIndex(tab => tab.isSelectedFlightInside);

    return {
        tabs,
        activeTabIndex: activeTabIndex === -1 ? defaultIndex : activeTabIndex,
    };
};
