import {
    ReservationPassengerFlight,
    ReservationCalculatePostRequest,
    ObjectId,
    ReservationBasketCost,
} from '@malesia/json-schema';
import { SelectedFlights } from '../../../utils/reservation/selectedFlight';
import { PassengerOptions, PassengerSeats } from '../../../utils/reservation/types';
import {
    BasketCostCostForPassengerFlightCostsItem,
    BasketCostCostForPassengerOptionsCostsItem,
    BasketCostCostForPassengersItem,
    PublicBasketCost,
} from '../../containers/ReservationBasket/reservationBasket.types';

const mapApiToBasketFlightCosts = (flightsCosts: any[]): BasketCostCostForPassengerFlightCostsItem[] => (
    flightsCosts.map<BasketCostCostForPassengerFlightCostsItem>(apiFlightCostItem => ({
        ...apiFlightCostItem.price,
        flightId: apiFlightCostItem.flight.id,
    }))
);

const mapApiToBasketOptionsCosts = (apiOptionsCosts: any): BasketCostCostForPassengerOptionsCostsItem[] => (
    apiOptionsCosts
);

export const mapApiCostToBasketCost = (apiCost: ReservationBasketCost): PublicBasketCost => {
    const { passengers, total } = apiCost;
    const getCostForPassengers = () => {
        if (!passengers) return [];
        return passengers.map<BasketCostCostForPassengersItem>(costForPassenger => {
            const result: BasketCostCostForPassengersItem = {
                passenger: {
                    // fixme нужно что бы бек возвращал ageType (сейчас нет)
                    ageType: costForPassenger.passenger.ageType ?? 'adult',
                },
                flightsCosts: mapApiToBasketFlightCosts(costForPassenger.flightsCosts),
            };
            if (costForPassenger.optionsCosts) {
                result.optionsCosts = mapApiToBasketOptionsCosts(costForPassenger.optionsCosts);
            }
            return result;
        });
    };
    const costForPassengers = getCostForPassengers();
    return {
        costForPassengers,
        total,
    };
};

export const preparePassengerOptions = (options: PassengerOptions[]): ObjectId[][] => (
    options.map(optionsOfPassenger => (
        Object.keys(optionsOfPassenger)
            .filter(optionId => optionsOfPassenger[optionId])
            .map(optionId => ({ id: +optionId }))
    ))
);

export const preparePassengerSeats = (
    selectedSeats: PassengerSeats[],
    outboundFlightId: number,
    returnFlightId: number,
): ReservationPassengerFlight[][] => {
    return selectedSeats.map((seats) => {
        const arr: ReservationPassengerFlight[] = [];
        if (seats.outbound) {
            arr.push({
                flight: { id: outboundFlightId },
                seatLabel: seats.outbound,
            });
        }
        if (seats.return) {
            arr.push({
                flight: { id: returnFlightId },
                seatLabel: seats.return,
            });
        }
        return arr;
    });
};

export const mapFlightsToCostRequest = (
    flights: SelectedFlights,
): ReservationCalculatePostRequest['bookedFlights'] | undefined => {
    if (!flights.outbound) return undefined;

    const result = [] as unknown as ReservationCalculatePostRequest['bookedFlights'];
    result.push({
        flight: { id: flights.outbound.flightId },
        tariff: { id: flights.outbound.tariffId },
    });
    if (flights.return) {
        result.push({
            flight: { id: flights.return.flightId },
            tariff: { id: flights.return.tariffId },
        });
    }
    return result;
};
