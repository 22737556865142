import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../types';
import { getLocalizedString } from '../../../../utils/getLoclizedString';
import { selectLocale } from '../../../containers/App/selectors';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.permissionsPageState || initialState;

export const selectIsLoading = createSelector(
    [selectDomain],
    state => state.isLoading,
);

export const selectPermissionsPage = createSelector(
    [selectDomain],
    state => state,
);

export const selectGroupItems = createSelector(
    [selectDomain, selectLocale],
    (state, locale) => Object.keys(state.userGroups).map(groupId => ({
        value: +groupId,
        name: getLocalizedString(state.userGroups[groupId].title, locale),
    })),
);

export const selectActiveGroupId = createSelector(
    [selectDomain],
    (state) => state.activeUserGroupId,
);

export const selectAvailableRoles = createSelector(
    [selectDomain],
    (state) => state.availableRoles,
);

export const selectCurrentRoles = createSelector(
    [selectDomain],
    (state) => state.currentRoles,
);

export const selectCurrentUser = createSelector(
    [selectDomain],
    (state) => state.currentUser,
);

export const selectActiveGroup = createSelector(
    [selectDomain],
    root => root.activeUserGroupId ? root.userGroups[root.activeUserGroupId] : null,
);

export const selectVirtualAccounts = createSelector(
    [selectDomain],
    state => state.virtualAccounts,
);

export const selectCurrentVirtualAccounts = createSelector(
    [selectDomain],
    state => state.currentVirtualAccounts,
);
