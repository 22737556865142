import { getTopImage } from '../getTopImage';
import { ImprintPageDocument, ImprintPageQuery } from '../gql-types';
import { gqlClient } from '../gqlClient';

export async function loadImprintPageContent(locale: string) {
    const { data } = await gqlClient.query<ImprintPageQuery>(
        ImprintPageDocument,
        { lang: locale },
    ).toPromise();
    return {
        topImage: getTopImage(data?.imprint_page?.top_image),
        content: data?.imprint_page?.translations?.[0]?.content ?? '',
    };
}
