import { ReservationPassenger } from '@malesia/json-schema';
import { PassengersAges } from '@malesia/react-components/dist/src/components/Reservation/reservation-types';
import { AgeType } from '@malesia/react-components/dist/src/utils/ageType';
import { PassengerInfo, PassengerBasket } from './types';

export const createPassengerList = (
    passengersConfig: PassengersAges,
    prevPassengerList?: PassengerBasket[],
): PassengerBasket[] => {
    let result: PassengerBasket[] = JSON.parse(JSON.stringify(prevPassengerList ?? []));
    const count: PassengersAges = {
        adult: 0,
        child: 0,
        infant: 0,
    };
    const iterate = (ageType: AgeType) => {
        result = result.filter(x => {
            if (x.info.ageType !== ageType) return true;
            if (count[ageType] === passengersConfig[ageType]) return false;
            count[ageType]++;
            return true;
        });
        for (let i = 0; i < passengersConfig[ageType] - count[ageType]; i++) {
            result.push({
                info: {
                    ageType,
                },
                seats: {},
                options: {},
            });
        }
    };
    iterate('adult');
    iterate('child');
    iterate('infant');
    return result;
};

export const toPassengerBasket = (
    passengers: ReservationPassenger[],
): PassengerBasket[] => {
    return passengers.reduce<PassengerBasket[]>((acc, passenger) => {
        const result: PassengerBasket = {
            info: {
                ...passenger,
            },
            seats: {},
            options: {},
        };
        return [...acc, result];
    }, []);
};

export const getPassengersAgesByList = (
    passengers: PassengerInfo[],
): PassengersAges => {
    const result: PassengersAges = {
        adult: 0,
        child: 0,
        infant: 0,
    };

    if (passengers.length === 0) {
        result.adult += 1;
        return result;
    }

    for (const passenger of passengers) {
        result[passenger.ageType!] += 1;
    }

    return result;
};
