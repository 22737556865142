import { ReservationListBulkActions, ReservationListBulkActionsProps } from '@malesia/react-components/dist/src/components/Reservation/List/ReservationListBulkActions';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reservationListPermissions } from '../../../permissions/adminPages/reservation/reservationList';
import { useUserPermissions } from '../../../permissions/useUserPermissions';
import { selectSelectedRows } from '../store/selectors';
import { actions } from '../store/slice';

export const ReservationListActionsWithStore: SFC = () => {
    const selectedRows = useSelector(selectSelectedRows);
    const userPermissions = useUserPermissions();
    const dispatch = useDispatch();

    const actionsProps: ReservationListBulkActionsProps = {
        sendSms: {
            action: () => {
                dispatch(actions.openSmsNotificationPopup());
            },
            hidden: !userPermissions.has(reservationListPermissions.sendSmsNotification),
        },
        disabled: !selectedRows.length,
        hidden: !userPermissions.has(reservationListPermissions.bulkActions),
    };
    return (
        <ReservationListBulkActions {...actionsProps} />
    );
};
