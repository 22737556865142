import { FrontPageContentBlock } from '@malesia/react-components/dist/src/components/Front/Page/ContentBlock';
import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { SplitPassengerPopupWithState } from '../../containers/SplitReservation/SplitPassengerPopupWithState';
import { ReservationCancellationPopupWithStore } from './components/ReservationCancellationPopupWithStore';
import { ReservationListFilterWithStore } from './components/ReservationListFilterWithStore';
import { ReservationListFooterWithStore } from './components/ReservationListFooterWithStore';
import { ReservationListHeaderWithStore } from './components/ReservationListHeaderWithStore';
import { ReservationListWithStore } from './components/ReservationListWithStore';
import { ReservationSmsNotificationWithStore } from './components/ReservationSmsNotificationWithStore';
import { useInjectReservationList } from './store/inject';
import { actions } from './store/slice';

export const AdminReservationListPage: SFC = () => {
    useInjectReservationList();
    const dispatch = useDispatch();

    return (
        <PageContentBlock>
            <ReservationListHeaderWithStore />
            <ReservationListFilterWithStore />
            <ReservationListWithStore />
            <ReservationListFooterWithStore />
            <ReservationCancellationPopupWithStore />
            <SplitPassengerPopupWithState
                refreshList={() => dispatch(actions.getReservationList())}
            />
            <ReservationSmsNotificationWithStore />
        </PageContentBlock>
    );
};

export const PublicReservationListPage: SFC = () => {
    useInjectReservationList();

    return (
        <FrontPageContentBlock modificators='booking'>
            <ReservationListHeaderWithStore />
            <ReservationListFilterWithStore />
            <ReservationListWithStore />
            <ReservationListFooterWithStore />
        </FrontPageContentBlock>
    );
};
