import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../types';
import {
    GetSeatMapByDirection,
    seatPriceDictionaryCombiner,
    seatPriceDictionaryForDirectionCombiner,
} from '../../../../utils/reservation/seatMap';
import { selectPassengers } from '../../../containers/ReservationBasket/reservationBasket.selectors';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.bookingFlightPage || initialState;

export const selectOutboundFlightListData = createSelector(
    [selectDomain],
    state => state.outboundFlightListData,
);

export const selectOutboundFlightListLoading = createSelector(
    [selectDomain],
    state => state.outboundFlightListLoading,
);

export const selectOutboundFlightActiveTab = createSelector(
    [selectDomain],
    state => state.outboundFlightActiveTab,
);

export const selectReturnFlightListData = createSelector(
    [selectDomain],
    state => state.returnFlightListData,
);

export const selectReturnFlightListLoading = createSelector(
    [selectDomain],
    state => state.returnFlightListLoading,
);

export const selectReturnFlightActiveTab = createSelector(
    [selectDomain],
    state => state.returnFlightActiveTab,
);

export const selectSeatReservationAircraftTemplateData = createSelector(
    [selectDomain],
    state => state.seatReservationAircraftTemplateData,
);

export const selectSeatMapForDirection = createSelector(
    [selectDomain],
    (state): GetSeatMapByDirection => (
        (direction) => state.seatMaps[direction]
    ),
);
export const selectSeatMaps = createSelector(
    [selectDomain],
    state => state.seatMaps,
);

export const selectSeatPriceDictionaryForDirection = createSelector(
    [selectSeatMaps],
    seatPriceDictionaryForDirectionCombiner,
);

export const selectSeatPriceDictionary = createSelector(
    [selectSeatMaps, selectSeatPriceDictionaryForDirection],
    seatPriceDictionaryCombiner,
);

export const selectQueryApplied = createSelector(
    [selectDomain],
    state => state.queryApplied,
);

export const selectSumSeatsPrice = createSelector(
    [selectSeatPriceDictionary, selectPassengers],
    (seatsPriceDictionary, passengers) => {
        if (!passengers) return 0;
        return passengers.map(x => x.seats).reduce((acc, passengerSelectedSeats) => {
            const outboundPrice = seatsPriceDictionary?.outbound?.[passengerSelectedSeats.outbound ?? '']?.price ?? 0;
            const returnPrice = seatsPriceDictionary?.return?.[passengerSelectedSeats.return ?? '']?.price ?? 0;
            return acc + outboundPrice + returnPrice;
        }, 0);
    },
);
