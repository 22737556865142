import { AgentEdit, AgentFormType } from '@malesia/react-components/dist/src/components/Agent/Edit';
import { BackLink } from '@malesia/react-components/dist/src/components/BackLink';
import { LoadingPage } from '@malesia/react-components/dist/src/components/LoadingOverlay/Page';
import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { filterDisabledData } from '@malesia/react-components/dist/src/hooks/useForm';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useEffect, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { createCustomPasswordConfirmValidator } from '../../../utils/validation/createCustomPasswordConfirmValidator';
import { createCustomRoundValidator } from '../../../utils/validation/createCustomRoundValidator';
import { getValidationFunction } from '../../../utils/validation/getValidationFunction';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { useInjectSharedData } from '../../containers/SharedData/inject';
import { selectAdminCountryList, selectAdminCountryLoading } from '../../containers/SharedData/selectors';
import { sharedActions } from '../../containers/SharedData/slice';
import { useAgentFormConfig } from './config';
import { createAgentSaga } from './saga';
import { selectLoading } from './selectors';
import { actions, reducer, sliceKey } from './slice';

export const AgentNewPage: SFC = () => {
    useInjectSharedData();
    useInjectReducer({ actions, key: sliceKey, reducer });
    useInjectSaga({
        key: `${sliceKey}CreateAgent`,
        saga: createAgentSaga,
    });

    const countryData = useSelector(selectAdminCountryList);
    const loading = useSelector(selectLoading);
    const loadingCountries = useSelector(selectAdminCountryLoading);
    const isAnythingLoading = loading || loadingCountries;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(sharedActions.getAdminCountryList());
    }, [dispatch]);

    const formConfig = useAgentFormConfig();

    const onSubmitForm = useCallback((data: AgentFormType) => {
        const filtered = filterDisabledData(data, formConfig);
        dispatch(actions.createAgent(filtered));
    }, [formConfig, dispatch]);

    const validationFn = useMemo(() => (
        getValidationFunction(
            'file://malesiareisen.com/json/schema/project/react-front-app/pages/AgentNewPage/agentNewForm.json',
            [
                createCustomRoundValidator<AgentFormType>(['/monthlyFee', '/creditLimitTotalAmount', '/moneyMargin']),
                createCustomPasswordConfirmValidator<AgentFormType>('/password', '/passwordConfirm'),
            ],
        )
    ), []);

    return (
        <PageContentBlock>
            <PageTitle
                text={
                    <FormattedMessage
                        id='front-app/AgentNewPage:Title'
                        defaultMessage='New agent'
                    />
                }
            />
            <BackLink
                to={allAdminRoutePaths.userAgentList}
                modificators='stick-to-title'
            />
            <LoadingPage isLoading={isAnythingLoading} />
            {!isAnythingLoading && (
                <AgentEdit
                    countryList={countryData.items}
                    formConfig={formConfig}
                    validator={validationFn}
                    onSubmit={onSubmitForm}
                />
            )}
        </PageContentBlock>
    );
};
