import { useInjectReducer, useInjectSaga } from '../../../../utils/redux-injectors';
import { useInjectAuxiliaryData } from '../../../containers/AuxiliaryData/inject';
import { reservationBasketReducer, reservationBasketSliceKey } from '../../../containers/ReservationBasket/reservationBasket.slice';
import { useInjectSharedData } from '../../../containers/SharedData/inject';
import { loginReducer, loginSliceKey } from '../../../containers/UserLogin/slice';
import { stepPassengersReducer, stepPassengersSliceKey } from '../Steps/StepPassengers/slice';
import { createReservationAndStartTransactionSaga } from '../Steps/StepReservationOverview/saga';
import { stepReservationOverviewReducer, stepReservationOverviewSliceKey } from '../Steps/StepReservationOverview/slice';
import { searchParamsSaga } from './queryParametersSaga';
import { getOutboundFlightListSaga, getReturnFlightListSaga, getSeatMapsSaga } from './saga';
import { bookingFlightReducer, bookingFlightSliceKey } from './slice';

export const useInjectReducers = () => {
    useInjectSharedData();
    useInjectAuxiliaryData();
    useInjectReducer({
        key: loginSliceKey,
        reducer: loginReducer,
    });

    useInjectReducer({
        key: bookingFlightSliceKey,
        reducer: bookingFlightReducer,
    });
    useInjectSaga({
        key: `${bookingFlightSliceKey}OutboundFlight`,
        saga: getOutboundFlightListSaga,
    });
    useInjectSaga({
        key: `${bookingFlightSliceKey}ReturnFlight`,
        saga: getReturnFlightListSaga,
    });
    useInjectSaga({
        key: `${bookingFlightSliceKey}ReservationPassengerSeatByFlightDataSaga`,
        saga: getSeatMapsSaga,
    });
    useInjectSaga({
        key: `${bookingFlightSliceKey}SearchParamsSaga`,
        saga: searchParamsSaga,
    });

    useInjectReducer({
        key: reservationBasketSliceKey,
        reducer: reservationBasketReducer,
    });

    useInjectReducer({
        key: stepPassengersSliceKey,
        reducer: stepPassengersReducer,
    });

    useInjectReducer({
        key: stepReservationOverviewSliceKey,
        reducer: stepReservationOverviewReducer,
    });
    useInjectSaga({
        key: `${stepReservationOverviewSliceKey}CreateReservationAndStartTransaction`,
        saga: createReservationAndStartTransactionSaga,
    });
};
