import { ErrorObject } from 'ajv';
import { CustomValidator } from './getValidationFunction';
import { getValueFromPath } from './getValueFromPath';
import { phoneMinLengthValidator, phoneMaxLengthValidator } from './phoneValidator';

const MIN_PHONE_NUMBER_LENGTH = 6;
const MAX_PHONE_NUMBER_LENGTH = 18;

const countryCodeToMinMaxPhoneLengthMap: Record<string, { min: number, max: number }> = {
    sq: { min: 3, max: 9 },
    at: { min: 9, max: 15 },
    ch: { min: 9, max: 9 },
    de: { min: 8, max: 13 },
    fr: { min: 11, max: 11 },
    it: { min: 8, max: 11 },
    xk: { min: 8, max: 8 },
    mk: { min: 8, max: 8 },
};

const mapCountryCodeToMinLength = (dialCode?: string, countryCode?: string): number => {
    if (dialCode && countryCode && countryCodeToMinMaxPhoneLengthMap[countryCode]) {
        return countryCodeToMinMaxPhoneLengthMap[countryCode].min + dialCode.length;
    }

    return MIN_PHONE_NUMBER_LENGTH;
};
const mapCountryCodeToMaxLength = (dialCode?: string, countryCode?: string): number => {
    if (dialCode && countryCode && countryCodeToMinMaxPhoneLengthMap[countryCode]) {
        return countryCodeToMinMaxPhoneLengthMap[countryCode].max + dialCode.length;
    }

    return MAX_PHONE_NUMBER_LENGTH;
};

export function createCustomPhoneNumberValidator<T>(instancePath: string, dialCode?: string, countryCode?: string) {
    const customValidator: CustomValidator<Partial<T>> = (data) => {
        const value = getValueFromPath(data, instancePath);
        if (value === undefined) return [];

        const errors: ErrorObject[] = [];

        const minLength = mapCountryCodeToMinLength(dialCode, countryCode);
        const isMinValid = phoneMinLengthValidator(value, minLength);
        if (!isMinValid) {
            const error: ErrorObject = {
                keyword: 'minLength',
                params: { limit: minLength },
                schemaPath: 'schema/minLength',
                data,
                instancePath,
                message: `should be greater or equal: ${minLength}`,
            };
            errors.push(error);
        }

        const maxLength = mapCountryCodeToMaxLength(countryCode);
        const isMaxValid = phoneMaxLengthValidator(value, maxLength);
        if (!isMaxValid) {
            const error: ErrorObject = {
                keyword: 'maxLength',
                params: { limit: maxLength },
                schemaPath: 'schema/maxLength',
                data,
                instancePath,
                message: `should be less or equal: ${maxLength}`,
            };
            errors.push(error);
        }

        return errors;
    };

    return customValidator;
}
