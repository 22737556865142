import { ReservationPaginatedList, UserProfile } from '@malesia/json-schema';
import { push } from 'connected-react-router';
import { all, call, put, select, takeLatest } from 'typed-redux-saga';
import { getDatatransSearchParams } from '../../../../../utils/datatrans/getDatatransSearchParams';
import { logError } from '../../../../../utils/log';
import { reservationPdfActions } from '../../../../containers/ReservationPdf/slice';
import { allAdminRoutePaths } from '../../../../containers/Routes/allAdminRoutePaths';
import { backLinks } from '../../../../containers/Routes/backLinks';
import { UserLoginAccount } from '../../../../containers/UserLogin/types';
import { selectUserPermissions } from '../../../../permissions/selectUserPermissions';
import { getApiUserAccount } from '../../../../services/account';
import { datatransService } from '../../../../services/Datatrance/datatrans.service';
import { getApiPaymentAccountList } from '../../../../services/payment';
import { getApiPermittedReservationList, getApiReservationById } from '../../../../services/Reservation';
import { buildReservationListRequest } from '../../../../services/Reservation/listUtils';
import { paymentNotifications } from '../notification';
import { selectReservation } from '../selectors';
import { reservationPaymentActions } from '../slice';

/**
 * ToDo: Really??? I'm not did this. Please remove this sometime.
 * https://mywork.xiag.ch/search#88787
 */
const getAllWithheldMoney = (list: ReservationPaginatedList) => {
    const moneys = list.items.map(x => {
        const total = x.invoice?.total ?? 0;
        const paid = x.invoice?.totalPaid ?? 0;
        const result = total - paid;
        return result;
    });
    const allWithheldMoney = moneys.reduce((acc, x) => acc + x, 0);
    return allWithheldMoney;
};

function* loadOwnerAccount(userId: number) {
    const res: UserLoginAccount & { creditLimitTotalAmount?: number } = yield* getApiUserAccount(userId);
    const debitOnBalance = (
        (res.type === 'liability' || res.type === 'revenue')
            ? res.balance
            : -1 * res.balance
    );
    const creditLimit = res.creditLimitTotalAmount;
    const userPermissions = yield* selectUserPermissions();

    const request = buildReservationListRequest(
        userPermissions,
        {
            page: 0,
            pageSize: 100,
        },
        {},
        {
            owner: {
                id: userId,
            } as UserProfile,
            reservationStatus: ['created'],
        },
    );
    const reservationList = yield* getApiPermittedReservationList(request);

    const allWithheldMoney = getAllWithheldMoney(reservationList);

    yield* put(reservationPaymentActions.setOwnerAccount({
        id: res.id,
        debitOnBalance,
        creditLimit,
        allWithheldMoney,
    }));
}

function* loadInitialData(action: ReturnType<typeof reservationPaymentActions.loadInitialData>) {
    const reservationId = action.payload;
    try {
        yield* put(reservationPaymentActions.setReservationLoading(true));
        const { transactionId, status } = getDatatransSearchParams();

        if (status === 'success' && transactionId) {
            yield* call(confirmTransaction, transactionId, reservationId);
        }
        else if (status === 'error' && transactionId) {
            try {
                const transaction = yield* call(datatransService.paymentDatatransTransactionUpdate, { transactionId });
                const reason = transaction.details?.fail?.message;
                yield* put(paymentNotifications.datatransError(reason));
            }
            catch (error) {
                logError({
                    error,
                    target: 'ReservationPaymentPage.loadInitialData.datatrans',
                });
                yield* put(paymentNotifications.datatransError());
            }
        }

        yield* all([
            call(loadReservation, reservationId),
            call(loadPaymentAccounts),
        ]);
        const reservation = yield* select(selectReservation);
        yield* call(loadOwnerAccount, reservation.owner.id!);
    }
    catch (error) {
        logError({
            error,
            target: 'ReservationPaymentPage.loadInitialData.common',
        });
        yield* put(push(allAdminRoutePaths.reservationList));
    }
    finally {
        yield* put(reservationPaymentActions.setReservationLoading(false));
    }
}

export function* loadInitialDataSaga() {
    yield* takeLatest(reservationPaymentActions.loadInitialData, loadInitialData);
}

function* loadReservation(reservationId: number) {
    try {
        const response = yield* call(getApiReservationById, reservationId);
        yield* put(reservationPaymentActions.setReservation(response));
    }
    catch (error) {
        logError({
            error,
            target: 'ReservationPaymentPage.loadReservation',
        });
        yield* put(paymentNotifications.notFindReservation(reservationId));
    }
}

function* loadPaymentAccounts() {
    try {
        const response = yield* all({
            cash: getApiPaymentAccountList({ paymentType: 'cash' }),
            bank: getApiPaymentAccountList({ paymentType: 'ec' }),
            datatrans: getApiPaymentAccountList({ paymentType: 'datatrans' }),
            transfer: getApiPaymentAccountList({ paymentType: 'transfer' }),
        });
        yield* put(reservationPaymentActions.setCashAccounts(response.cash));
        yield* put(reservationPaymentActions.setBankAccounts(response.bank));
        yield* put(reservationPaymentActions.setDatatransAccounts(response.datatrans));
        yield* put(reservationPaymentActions.setTransferAccounts(response.transfer));
    }
    catch (error) {
        logError({
            error,
            target: 'ReservationPaymentPage.loadPaymentAccounts',
        });
        yield* put(paymentNotifications.notLoadedPaymentAccounts);
        throw error;
    }
}

function* confirmTransaction(transactionId: string, reservationId: number) {
    try {
        yield* call(datatransService.paymentDatatransReservationConfirm, { transactionId });
        yield* put(paymentNotifications.paymentSuccess);
        const back = backLinks.reservationPayment.back(reservationId);
        yield* put(push(back));

        const result = yield* call(getApiReservationById, reservationId);
        if (result.status === 'confirmed') {
            yield* put(reservationPdfActions.downloadOverviewPdf({
                reservationId,
                callback: () => {},
            }));
        }
    }
    catch (error) {
        logError({
            error,
            target: 'ReservationPaymentPage.confirmTransaction',
        });
        yield* put(paymentNotifications.notConfirmedTransaction);
    }
}
