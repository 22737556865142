import { getQueryParameters } from '../uriUtils';

export type DatatransQueryParameters = {
    status?: 'error' | 'success' | 'cancel',
    transactionId?: string,
};

export const getDatatransSearchParams = (): DatatransQueryParameters => {
    type DatatransParams = {
        status?: 'error' | 'success' | 'cancel',
        datatransTrxId?: string,
    };
    const { datatransTrxId, status } = getQueryParameters<DatatransParams>({
        datatransTrxId: 'string',
        status: 'string',
    });
    return {
        status,
        transactionId: datatransTrxId ? `${datatransTrxId}` : undefined,
    };
};
