import { useInjectReducer, useInjectSaga } from '../../../../utils/redux-injectors';
import {
    getFlightListSaga,
    getAirportListSaga,
    getTariffGroupListSaga,
    deleteFlightsSaga,
    unassignFlightsSaga,
    deleteFlightsSequencesSaga,
    activateFlightSaga,
    bookOutFlightSaga,
    loadFlightInfoSaga,
    setFlightCancelledSaga,
    downloadTurnoverListSaga,
    downloadPassengerReportSaga,
} from './saga';
import { actions, reducer, sliceKey } from './slice';

export const useInjectFlightList = () => {
    useInjectReducer({ actions, key: sliceKey, reducer });
    useInjectSaga({ key: sliceKey, saga: getFlightListSaga });
    useInjectSaga({
        key: `${sliceKey}Activate`,
        saga: activateFlightSaga,
    });
    useInjectSaga({
        key: `${sliceKey}Cancell`,
        saga: setFlightCancelledSaga,
    });
    useInjectSaga({
        key: `${sliceKey}Delete`,
        saga: deleteFlightsSaga,
    });
    useInjectSaga({
        key: `${sliceKey}BookOut`,
        saga: bookOutFlightSaga,
    });
    useInjectSaga({
        key: `${sliceKey}Unassign`,
        saga: unassignFlightsSaga,
    });
    useInjectSaga({
        key: `${sliceKey}DeleteSequence`,
        saga: deleteFlightsSequencesSaga,
    });
    useInjectSaga({
        key: `${sliceKey}Airport`,
        saga: getAirportListSaga,
    });
    useInjectSaga({
        key: `${sliceKey}TariffGroup`,
        saga: getTariffGroupListSaga,
    });
    useInjectSaga({ key: `${sliceKey}Info`, saga: loadFlightInfoSaga });
    useInjectSaga({ key: `${sliceKey}TurnoverList`, saga: downloadTurnoverListSaga });
    useInjectSaga({ key: `${sliceKey}PassengerReport`, saga: downloadPassengerReportSaga });
};
