import React from 'react';
import { FormattedMessage } from 'react-intl';
import { appNotification } from '../../containers/App/appNotification';
import { createLocalNotification } from '../../containers/App/utils';

const notification = createLocalNotification('Customer', {
    error: (
        <FormattedMessage
            id='front-app/Customer/Notification/Error:title'
            defaultMessage='Customer Error'
        />
    ),
    warning: (
        <FormattedMessage
            id='front-app/Customer/Notification/Warning:title'
            defaultMessage='Customer Warning'
        />
    ),
    info: (
        <FormattedMessage
            id='front-app/Customer/Notification/Info:title'
            defaultMessage='Customer Info'
        />
    ),
    success: (
        <FormattedMessage
            id='front-app/Customer/Notification/Info:title'
            defaultMessage='Customer Info'
        />
    ),
});

export const customerNotifications = {
    createdSuccessfully: notification.success(appNotification.texts.createdSuccessfully),
    updatedSuccessfully: notification.success(appNotification.texts.updatedSuccessfully),
    unknownError: (details?: JSX.Element | string) => (
        notification.error(appNotification.texts.unknownError(details))
    ),
};
