import { AircraftTemplate, Allotment, SeatsConfig } from '@malesia/json-schema';
import { FlightFormData } from '@malesia/react-components/dist/src/components/Flight/Edit/Info';
import { RepeatingFormData } from '@malesia/react-components/dist/src/components/Flight/Edit/Repeating';
import { addTmpId } from '@malesia/react-components/dist/src/utils/tmpId';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../../utils/@reduxjs/toolkit';
import { CreationFlightData } from './api';
import { FlightNewPageState } from './types';

export const initialState: FlightNewPageState = {
    flightData: {
        isActive: true,
        seatsByTariff: [],
    },
    allotments: [],
    repeatingData: {},
    creationFlightData: {
        airportList: {
            items: [],
        },
        aircraftTemplateList: {
            items: [],
            total: 0,
        },
        companyList: {
            items: [],
        },
        rateList: {
            items: [],
        },
        tariffGroupList: {
            items: [],
        },
    },
    loading: false,
    isLoadingCreationFlightData: false,
    isAircraftSeatsConfigured: false,
};

const flightNewPageSlice = createSlice({
    name: 'flightNewPage',
    initialState,
    reducers: {
        getCreationFlightData(state) {
            state.isLoadingCreationFlightData = true;
        },
        getCreationFlightDataSuccess(state, action: PayloadAction<CreationFlightData>) {
            state.creationFlightData = action.payload;
            state.flightData.seatsByTariff = action.payload.tariffGroupList.items.map((x): SeatsConfig => ({
                tariffGroup: {
                    id: x.id!,
                },
                onlineSeatsLimit: 7,
            }));
            state.isLoadingCreationFlightData = false;
        },
        getCreationFlightDataError(state) {
            state.isLoadingCreationFlightData = false;
        },
        setFlightData(state, action: PayloadAction<Partial<FlightFormData>>) {
            if (action.payload.departure !== state.flightData.departure) {
                state.repeatingData.firstDate = action.payload.departure;
            }

            state.flightData = action.payload;
            state.flightData.numberPrefix = action.payload.flightCompany ? action.payload.flightCompany.code : 'XX';
        },
        setFlightAircraftTemplate(state, action: PayloadAction<AircraftTemplate>) {
            state.flightData = {
                ...state.flightData,
                aircraftTemplate: action.payload as FlightFormData['aircraftTemplate'],
            };
        },
        setAllotmentData(state, action: PayloadAction<Allotment[]>) {
            state.allotments = action.payload.map(addTmpId);
        },
        setRepeatingData(state, action: PayloadAction<Partial<RepeatingFormData>>) {
            state.repeatingData = action.payload;
        },
        createFlight(state) {
            state.loading = true;
        },
        createFlightSuccess(state) {
            state.loading = false;
        },
        createFlightError(state) {
            state.loading = false;
        },
        createFlightSequence(state) {
            state.loading = true;
        },
        createFlightSequenceSuccess(state) {
            state.loading = false;
        },
        createFlightSequenceError(state) {
            state.loading = false;
        },
        clickSettings() {},
        setIsAircraftSeatsConfigured(state, action: PayloadAction<boolean>) {
            state.isAircraftSeatsConfigured = action.payload;
        },
    },
});

export const { actions, reducer, name: sliceKey } = flightNewPageSlice;
