import { getTopImage } from '../getTopImage';
import {
    ContactSwPageDocument, ContactSwPageQuery,
} from '../gql-types';
import { gqlClient } from '../gqlClient';

export async function loadContactSwPageContent(locale: string) {
    const { data } = await gqlClient.query<ContactSwPageQuery>(
        ContactSwPageDocument,
        { lang: locale },
    ).toPromise();
    return {
        topImage: getTopImage(data?.contact_sw?.top_image),
        content: data?.contact_sw?.translations?.[0]?.content ?? '',
    };
}
