import { PopupWrapper } from '@malesia/react-components/dist/src/components/Popup/PopupWrapper';
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from '../../../utils/redux-injectors';
import { AppLoginForm } from '../../sharedComponents/AppLoginForm';
import { recoverPasswordModalActions } from '../RecoverPasswordModal/slice';
import { selectLoginModalIsOpened } from './selectors';
import { loginModalActions, loginModalReducer, loginModalSliceKey } from './slice';

export const LoginModal = memo(function LoginModal() {
    useInjectReducer({ key: loginModalSliceKey, reducer: loginModalReducer });

    const dispatch = useDispatch();
    const isOpened = useSelector(selectLoginModalIsOpened);

    /**
   * Handle modal close button
   * Update root state
   */
    const handleModalClose = () => {
        dispatch(loginModalActions.closeModal());
    };

    /**
   * Handle forgot button
   * Close this modal and open forgot modal
   */
    const handleForgot = () => {
        dispatch(loginModalActions.closeModal());
        dispatch(recoverPasswordModalActions.openModal());
    };

    return (
        <PopupWrapper
            isOpened={isOpened}
            onClose={handleModalClose}
        >
            <AppLoginForm handleForgot={handleForgot} />
        </PopupWrapper>
    );
});
