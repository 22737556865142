import { Reservation } from '@malesia/json-schema';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { ReservationHistoryPageState, ReservationSnapshotList } from './types';

export type ActionTypeWithReservationId = {
    reservationId: number | string,
};

export const initialState: ReservationHistoryPageState = {
    loadingStatus: 'not-loaded',
    reservation: undefined,
    reservationSnapshotList: {
        items: [],
    },
};

const reservationHistoryPageSlice = createSlice({
    name: 'reservationHistoryPage',
    initialState,
    reducers: {
        startLoading(state) {
            state.loadingStatus = 'loading';
        },
        successLoading(state) {
            state.loadingStatus = 'loaded';
        },
        failLoading(state) {
            state.loadingStatus = 'not-loaded';
        },
        getReservation(state, action: PayloadAction<ActionTypeWithReservationId>) {},
        setReservation(state, action: PayloadAction<Reservation>) {
            state.reservation = action.payload;
        },
        getReservationSnapshotList(state, action: PayloadAction<ActionTypeWithReservationId>) {},
        setReservationSnapshotList(state, action: PayloadAction<ReservationSnapshotList>) {
            state.reservationSnapshotList = action.payload;
        },
    },
});

export const { actions, reducer, name: sliceKey } = reservationHistoryPageSlice;
