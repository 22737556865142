import { LoadingPage } from '@malesia/react-components/dist/src/components/LoadingOverlay/Page';
import { DesignSummary } from '@malesia/react-components/dist/src/components/Reservation/DesignSummary';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toNumber } from '../../../utils/uriUtils';
import { AdminReservationSummaryWithStore } from './components/AdminReservationSummaryWithStore';
import { ReservationPaymentWithStore } from './components/ReservationPaymentWithStore';
import { useInjectReservationPaymentPage } from './store/inject';
import { selectReservationLoading } from './store/selectors';
import { reservationPaymentActions } from './store/slice';

export const ReservationPaymentPage: SFC = () => {
    useInjectReservationPaymentPage();

    const dispatch = useDispatch();
    const routeParams = useParams<{ reservationId: string, status: string }>();

    useEffect(() => {
        const id = toNumber(routeParams.reservationId)!;
        dispatch(reservationPaymentActions.loadInitialData(id));
    }, [dispatch, routeParams.reservationId, routeParams.status]);

    const reservationLoading = useSelector(selectReservationLoading);

    if (reservationLoading) return <LoadingPage isLoading />;

    return (
        <DesignSummary
            modificators='with-sidebar'
            contentSlot={
                <ReservationPaymentWithStore />
            }
            summarySlot={
                <AdminReservationSummaryWithStore />
            }
        />
    );
};
