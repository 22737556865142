import { useInjectReducer, useInjectSaga } from '../../../../utils/redux-injectors';
import { getReservationListSaga } from './saga';
import { reducer, sliceKey, actions } from './slice';

export const useInjectReservationInvoiceList = () => {
    useInjectReducer({ actions, key: sliceKey, reducer });
    useInjectSaga({
        key: `${sliceKey}getReservationListSaga`,
        saga: getReservationListSaga,
    });
};
