import { useSelector } from 'react-redux';
import { selectUserLoginAccount, selectUserLoginIsAuthorized, selectUserLoginUserInfo } from '../containers/UserLogin/selectors';

export const useUser = () => {
    const userIsLoggedIn = useSelector(selectUserLoginIsAuthorized);
    const userInfo = useSelector(selectUserLoginUserInfo);
    const userAccount = useSelector(selectUserLoginAccount);

    //not sure
    const userName = userInfo?.displayName ?? userInfo?.email ?? '';

    return { userIsLoggedIn, userName, userInfo, userAccount };
};
