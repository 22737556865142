import { AgentPutRequest, CustomerPutRequest, ManagerPutRequest } from '@malesia/json-schema';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../../utils/@reduxjs/toolkit';
import { UserAccountPageState } from './types';

export const initialState: UserAccountPageState = {
    loading: false,
};

const userAccountPageState = createSlice({
    name: 'userAccountPageState',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        submitManager(_state, _action: PayloadAction<ManagerPutRequest>) {},
        submitAgent(_state, _action: PayloadAction<AgentPutRequest>) {},
        submitCustomer(_state, _action: PayloadAction<CustomerPutRequest>) {},
    },
});

export const {
    actions: userAccountPageActions,
    name: userAccountPageSliceKey,
    reducer: userAccountPageReducer,
} = userAccountPageState;
