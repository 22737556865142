import { useInjectReducer, useInjectSaga } from '../../../../utils/redux-injectors';
import { useInjectAuxiliaryData } from '../../../containers/AuxiliaryData/inject';
import {
    getCreationFlightDataSaga,
    createFlightSaga,
    createFlightSequenceSaga,
    setFlightDataSaga,
    clickSettingsSaga,
} from './saga';
import { actions, reducer, sliceKey } from './slice';

export const useInjectFlightNew = () => {
    useInjectAuxiliaryData();
    useInjectReducer({ actions, key: sliceKey, reducer });
    useInjectSaga({
        key: `${sliceKey}_getCreationFlightDataSaga`,
        saga: getCreationFlightDataSaga,
    });
    useInjectSaga({ key: sliceKey, saga: createFlightSaga });
    useInjectSaga({
        key: `${sliceKey}Sequence`,
        saga: createFlightSequenceSaga,
    });
    useInjectSaga({
        key: `${sliceKey}Set`,
        saga: setFlightDataSaga,
    });
    useInjectSaga({
        key: `${sliceKey}ClickSettings`,
        saga: clickSettingsSaga,
    });
};
