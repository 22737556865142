import { FlightListItem, PublicFlightListItem } from '@malesia/json-schema';
import { ReservationFlightCard } from '@malesia/react-components/dist/src/components/Reservation/FlightCard';
import { PriceVariantInfo } from '@malesia/react-components/dist/src/components/Reservation/PriceVariant';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import { TIME_FORMAT } from '@malesia/react-components/dist/src/utils/dateTimeFormat';
import moment from 'moment';
import React from 'react';
import { useIntl } from 'react-intl';
import { getPriceVariants } from '../../../../utils/flight/getPriceVariants';
import { BookedFlight, SelectedFlight } from '../../../../utils/reservation/selectedFlight';
import { makePath } from '../../../../utils/uriUtils';
import { allAdminRoutePaths } from '../../../containers/Routes/allAdminRoutePaths';
import { flightListPermissions } from '../../../permissions/adminPages/flight/flightList';
import { useUserPermissions } from '../../../permissions/useUserPermissions';
import { useReservationAllowed } from '../../ReservationNewPage/permissions';

export type TabContentItemProps = {
    flight: PublicFlightListItem & Partial<FlightListItem>,
    isRoundTrip: boolean,
    selectFlight: (data: SelectedFlight) => void,
    selectedFlight: SelectedFlight | undefined,
    bookedFlight: BookedFlight | undefined,
    flightCardWithoutDetail: boolean | undefined,
};
export const TabContentItem: SFC<TabContentItemProps> = (props) => {
    const { flight, isRoundTrip, selectFlight, selectedFlight, bookedFlight, flightCardWithoutDetail } = props;

    const allowed = useReservationAllowed();
    const userPermissions = useUserPermissions();
    const { locale } = useIntl();

    const travelTimeDuration = moment.duration(
        moment(flight.arrival).diff(moment(flight.departure)),
    );
    const travelTimeText = `${travelTimeDuration.hours()}h ${travelTimeDuration.minutes()}m`;

    const priceVariants = getPriceVariants(
        flight,
        selectedFlight,
        bookedFlight,
        allowed,
        isRoundTrip,
        locale,
    );

    const onPriceVariantClick = (info: PriceVariantInfo) => {
        const { flightId, priceId } = info;

        const isFlightMatch = flightId === selectedFlight?.flightId;
        const isPriceMatch = priceId === selectedFlight?.tariffId;

        if (isFlightMatch && isPriceMatch) return;

        selectFlight({
            flightId,
            tariffId: priceId,
        });
    };

    const passengerListActionHidden = !userPermissions.has(flightListPermissions.flightPassengerListLink);

    const onPassengerListClick = () => {

        const passengerListRoute = makePath(allAdminRoutePaths.flightPassengerList, {
            flightId: flight.id,
        });
        window.open(passengerListRoute, '_blank');
    };

    return (
        <ReservationFlightCard
            key={`rfc::${flight.origin!.code}:${flight.departure}`}
            arrivalTimeText={moment.parseZone(flight.arrival)
                .locale(locale)
                .format(TIME_FORMAT)}
            departureTimeText={moment.parseZone(flight.departure)
                .locale(locale)
                .format(TIME_FORMAT)}
            destinationAirportCode={flight.destination!.code}
            flightCompany={flight.flightCompany!.name}
            flightDurationText={travelTimeText}
            flightId={`${flight.id}`}
            flightNumber={`${flight.number}`}
            originAirportCode={flight.origin!.code}
            onPriceVariantClick={onPriceVariantClick}
            priceVariants={priceVariants}
            withoutDetails={flightCardWithoutDetail}
            flightCompanyCode={flight.flightCompany!.code}
            flightIsBookedOut={flight.isBookedOut}
            flightIsDeactivated={flight.isActive === false}
            flightStatus={flight.status}
            onPassengerListClick={onPassengerListClick}
            passengerListActionHidden={passengerListActionHidden}
        />
    );
};
