import { UserProfile } from '@malesia/json-schema';
import { createActionsColumn } from '@malesia/react-components/dist/src/components/Table/columns/actionsColumn';
import * as layouts from './actions';

export const userPermissionsActionsColumn = createActionsColumn<UserProfile, typeof layouts>({
    columnsNumber: 2,
    rowsNumber: 1,
    layouts,
    order: [
        'editUserPermissions',
    ],
});
export type UserPermissionsActionsConfig = Parameters<typeof userPermissionsActionsColumn>[0];
