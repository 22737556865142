import { getTopImage } from '../getTopImage';
import {
    SafetyPageDocument, SafetyPageQuery,
} from '../gql-types';
import { gqlClient } from '../gqlClient';

export async function loadSafetyPageContent(locale: string) {
    const { data } = await gqlClient.query<SafetyPageQuery>(
        SafetyPageDocument,
        { lang: locale },
    ).toPromise();
    return {
        topImage: getTopImage(data?.safety_page?.top_image),
        content: data?.safety_page?.translations?.[0]?.content ?? '',
    };
}
