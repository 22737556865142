/*
 * MalesiaLoginPage Messages
 *
 * This contains all the text for the MalesiaLoginPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.MalesiaLoginPage';

export default defineMessages({
    header: {
        id: `${scope}.header`,
        defaultMessage: 'This is the MalesiaLoginPage container!',
    },
});
