import { Permission } from '@malesia/react-components/dist/src/components/PermissionsPage/PermissionsEditor/PermissionsEditor';
import { IntlFormatters } from 'react-intl';
import { RoleCategoryCode, RoleCode } from './roleCode';
import { permissionTranslationsConfig } from './translationsConfig';

type PermissionTranslationConfig = {
    category: {
        code: RoleCategoryCode,
        titleTranslateKey: string,
    },
    role: {
        code: RoleCode,
        titleTranslateKey: string,
        descriptionTranslateKey: string,
    },
};
export const getPermissionTranslationConfig = (
    targetRoleCode: RoleCode,
): PermissionTranslationConfig | undefined => {
    for (const [categoryCode, categoryConfig] of Object.entries(permissionTranslationsConfig)) {
        for (const [roleCode, roleConfig] of Object.entries(categoryConfig.roles)) {
            if (targetRoleCode === roleCode) {
                return {
                    category: {
                        code: categoryCode as RoleCategoryCode,
                        titleTranslateKey: categoryConfig.titleTranslateKey,
                    },
                    role: {
                        code: roleCode,
                        titleTranslateKey: roleConfig.titleTranslateKey,
                        descriptionTranslateKey: roleConfig.descriptionTranslateKey,
                    },
                };
            }
        }
    }
    return undefined;
};

export const mapRoleCodesToPermissions = (
    roleCodes: RoleCode[],
    formatMessage: IntlFormatters['formatMessage'],
    userRoleCodes: RoleCode[],
): Permission[] => {
    return roleCodes.map(roleCode => {
        const config = getPermissionTranslationConfig(roleCode);
        if (config) {
            return {
                id: roleCode,
                title: formatMessage({
                    id: config.role.titleTranslateKey,
                    defaultMessage: roleCode,
                }),
                description: formatMessage({
                    id: config.role.descriptionTranslateKey,
                    defaultMessage: '',
                }),
                isActive: userRoleCodes?.includes(roleCode),
                groupName: formatMessage({
                    id: config.category.titleTranslateKey,
                    defaultMessage: config.category.code,
                }),
            };
        }
        else {
            return {
                id: roleCode,
                title: roleCode,
                description: '',
                isActive: userRoleCodes?.includes(roleCode),
                groupName: 'unknown category',
            };
        }
    });
};
