import { PublicFlightListItem } from '@malesia/json-schema';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../../utils/@reduxjs/toolkit';
import { SeatMaps } from '../../../../utils/reservation/types';
import { GetFlightsOnWeekArgs } from '../../../services/flight';
import { StepName } from '../config/steps';
import {
    OutboundFlightTabSettingsType,
    BookingFlightPageState, SeatReservationAircraftTemplate,
} from '../types';

export const initialState: BookingFlightPageState = {
    outboundFlightListData: [],
    outboundFlightActiveTab: 3,
    returnFlightListData: [],
    returnFlightActiveTab: 3,
    seatMaps: {},
};

const bookingFlightPageSlice = createSlice({
    name: 'bookingFlightPage',
    initialState,
    reducers: {
        reset() {
            return initialState;
        },
        getOutboundFlightList(
            state,
            action: PayloadAction<GetFlightsOnWeekArgs>,
        ) {
            state.outboundFlightListLoading = true;
        },
        getOutboundFlightListSuccess(
            state,
            action: PayloadAction<PublicFlightListItem[]>,
        ) {
            state.outboundFlightListData = action.payload;
            state.outboundFlightListLoading = false;
        },
        getOutboundFlightListError(state) {
            state.outboundFlightListLoading = false;
        },
        setOutboundFlightActiveTab(
            state,
            action: PayloadAction<OutboundFlightTabSettingsType>,
        ) {
            state.outboundFlightActiveTab = action.payload.outboundFlightActiveTab;
        },
        getReturnFlightList(
            state,
            action: PayloadAction<GetFlightsOnWeekArgs>,
        ) {
            state.returnFlightListLoading = true;
        },
        getReturnFlightListSuccess(
            state,
            action: PayloadAction<PublicFlightListItem[]>,
        ) {
            state.returnFlightListData = action.payload;
            state.returnFlightListLoading = false;
        },
        getReturnFlightListError(state) {
            state.returnFlightListLoading = false;
        },
        setSeatMaps(state, action: PayloadAction<SeatMaps>) {
            state.seatMaps = action.payload;
        },
        getSeatMaps(state) {},
        resetSeatMaps(state) {
            state.seatMaps = {};
        },
        //todo is uses? if no, then delete
        getReservationPassengerSeatByFlight(
            state,
            action: PayloadAction<{flightId: number, tariffId: number}>,
        ) {
            state.seatReservationAircraftTemplateData = undefined;
            state.reservationPassengerSeatByFlightError = false;
            state.reservationPassengerSeatByFlightLoading = true;
        },
        getReservationPassengerSeatByFlightSuccess(
            state,
            action: PayloadAction<SeatReservationAircraftTemplate>,
        ) {
            state.seatReservationAircraftTemplateData = action.payload;
            state.reservationPassengerSeatByFlightLoading = false;
        },
        getReservationPassengerSeatByFlightError(state, action: PayloadAction<any>) {
            state.reservationPassengerSeatByFlightError = action.payload;
            state.reservationPassengerSeatByFlightLoading = false;
        },
        processSeatsNotEnoughError(state, action: PayloadAction<{flight: number, tariff: number}>) {},
        applyQueryParameters(state, action: PayloadAction<StepName>) {
            state.queryApplied = false;
        },
        applyQueryParametersComplete(state) {
            state.queryApplied = true;
        },
    },
});

export const {
    actions: bookingFlightActions,
    reducer: bookingFlightReducer,
    name: bookingFlightSliceKey,
} = bookingFlightPageSlice;
