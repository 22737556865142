import { put, takeLatest, select, call, throttle } from 'typed-redux-saga';
import { logError } from '../../../../utils/log';
import { refreshUserAccount } from '../../../containers/UserLogin/saga';
import { selectMyAccountToListItem } from '../../../containers/UserLogin/selectors';
import { balanceLogPermissions } from '../../../permissions/adminPages/balance/balanceLog';
import { selectUserPermissions } from '../../../permissions/selectUserPermissions';
import { getApiAccountFreeList, getApiUserAccountListItem } from '../../../services/account';
import { getApiErrorMessage } from '../../../services/ApiClient';
import { getApiBalanceLogTypeList } from '../../../services/balanceLog';
import { balanceLogNotifications } from './notifications';
import { balanceLogActions } from './slice';

export function* initAccount(action: ReturnType<typeof balanceLogActions.initAccount>) {
    try {
        if (action.payload.target === 'my') {
            yield* call(refreshUserAccount);
            const myAccount = yield* select(selectMyAccountToListItem);
            yield* put(balanceLogActions.initAccountSuccess(myAccount));
            return;
        }

        const queryAccount = yield* getApiUserAccountListItem(action.payload.id);
        yield* put(balanceLogActions.initAccountSuccess(queryAccount));
    }
    catch (error) {
        logError({
            error,
            target: 'BalanceLogPage.loadInitialData',
        });
        const message = getApiErrorMessage(error);
        yield* put(balanceLogNotifications.unknownError(message));
    }
}

export function* loadInitialDataSaga() {
    yield* takeLatest(balanceLogActions.initAccount, initAccount);
}

function* getAccountList(action: ReturnType<typeof balanceLogActions.getAccountList>) {
    try {
        const userPermissions = yield* selectUserPermissions();
        if (!userPermissions.has(balanceLogPermissions.accountFilter)) {
            yield* put(balanceLogActions.getAccountListSuccess([]));
            return;
        }

        const accountList = yield* getApiAccountFreeList({
            query: action.payload,
        });
        yield* put(balanceLogActions.getAccountListSuccess(accountList));
    }
    catch (error) {
        logError({
            error,
            target: 'BalanceLogPage.getAccountList',
        });
        const message = getApiErrorMessage(error);
        yield* put(balanceLogNotifications.unknownError(message));
    }
}

export function* getAccountListSaga() {
    yield* throttle(1000, balanceLogActions.getAccountList, getAccountList);
}

function* getRelatedAccountList(action: ReturnType<typeof balanceLogActions.getRelatedAccountList>) {
    try {
        const userPermissions = yield* selectUserPermissions();
        if (!userPermissions.has(balanceLogPermissions.categoryFilter)) {
            yield* put(balanceLogActions.getCategoryListSuccess([]));
            return;
        }

        const relatedAccountItems = yield* getApiAccountFreeList({
            query: action.payload,
        });
        yield* put(balanceLogActions.getRelatedAccountListSuccess(relatedAccountItems));
    }
    catch (error) {
        logError({
            error,
            target: 'BalanceLogPage.getRelatedAccountList',
        });
        const message = getApiErrorMessage(error);
        yield* put(balanceLogNotifications.unknownError(message));
    }
}

export function* getRelatedAccountListSaga() {
    yield* throttle(1000, balanceLogActions.getRelatedAccountList, getRelatedAccountList);
}

function* getCategoryList() {
    try {
        const userPermissions = yield* selectUserPermissions();
        if (!userPermissions.has(balanceLogPermissions.relatedAccountFilter)) {
            yield* put(balanceLogActions.getRelatedAccountListSuccess([]));
            return;
        }

        const categories = yield* getApiBalanceLogTypeList();
        yield* put(balanceLogActions.getCategoryListSuccess(categories.items));
    }
    catch (error) {
        logError({
            error,
            target: 'BalanceLogPage.getRelatedAccountList',
        });
        const message = getApiErrorMessage(error);
        yield* put(balanceLogNotifications.unknownError(message));
    }
}

export function* getCategoryListSaga() {
    yield* throttle(1000, balanceLogActions.getCategoryList, getCategoryList);
}
