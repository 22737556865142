import { FormFieldTextarea } from '@malesia/react-components/dist/src/components/Form/Field/Textarea';
import { PanelTabItem, PanelTabs, PanelTabsProps } from '@malesia/react-components/dist/src/components/Form/PanelTabs';
import { PopupWrapper } from '@malesia/react-components/dist/src/components/Popup/PopupWrapper';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectPnl } from '../selectors';
import { actions } from '../slice';

export const PnlPopup: SFC = () => {
    const pnl = useSelector(selectPnl);

    const dispatch = useDispatch();

    const mainTab: PanelTabItem<string> = {
        id: 'MalesiaPNL',
        label: (
            <FormattedMessage
                id='front-app/FlightPassengerListPage:MalesiaPNL'
                defaultMessage='Malesia PNL ({count})'
                values={{
                    count: pnl?.malesia.total ?? 0,
                }}
            />
        ),
        Panel: () => (
            <FormFieldTextarea
                defaultValue={pnl?.malesia.pnl}
                readOnly={true}
                modificators={[
                    'none-resize',
                    'half-screen',
                ]}
            />
        ),
    };

    const externalTabs = pnl?.external.map((x): PanelTabItem<string> => ({
        id: `ExternalPnl${x.agent.id}`,
        label: `${x.agent.company} (${x.total})`,
        Panel: () => (
            <FormFieldTextarea
                defaultValue={x.pnl}
                readOnly={true}
                modificators={[
                    'none-resize',
                    'half-screen',
                ]}
            />
        ),
        hidden: !pnl?.external,
    }));

    const tabs: PanelTabItem<string>[] = [mainTab, ...(externalTabs ?? [])];
    const [selectedTabId, setSelectedTabId] = useState<string>('MalesiaPNL');
    const panelTabsProps: PanelTabsProps<string> = {
        tabs,
        selectedTabId,
        selectTab: setSelectedTabId,
    };

    return (
        <PopupWrapper
            isOpened={pnl !== undefined}
            onClose={() => dispatch(actions.clearPnlList())}
            modificators='lg'
        >
            <PanelTabs {...panelTabsProps} />
        </PopupWrapper>
    );
};
