/**
 * Key is tariffId from DB.
 * Values is a common const value which is using both design and app
 * to synchronize tariff config.
 */
import { RateTariffType } from '@malesia/json-schema';

export const rateIdToTariffType: Record<number, RateTariffType> = {
    1: 'economy' as const,
    2: 'economy' as const, // "en": "Economy (depricated)" // deprecated
    3: 'economy-plus' as const, // "en": "Economy plus" // not active
    4: 'business' as const,
};

export const DEFAULT_RATE_TARIFF_TYPE = 'economy' as const;
