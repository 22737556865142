import { AgentList } from '@malesia/react-components/dist/src/components/Agent/List';
import { SortingDataType } from '@malesia/react-components/dist/src/components/Table';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectList,
    selectIsDirty,
    selectIsLoading,
    selectFilterData,
} from '../selectors';
import { actions } from '../slice';
import { useTableColumns } from '../tableColumns';

export const AgentListWithStore: SFC = () => {
    const list = useSelector(selectList);
    const filterData = useSelector(selectFilterData);
    const isLoading = useSelector(selectIsLoading);
    const isDirty = useSelector(selectIsDirty);
    const dispatch = useDispatch();

    const handleSetSort = (data: SortingDataType) => {
        dispatch(actions.setSorting(data));
    };

    const agentListColumns = useTableColumns();

    return (
        <AgentList
            isLoading={isLoading}
            isDirty={isDirty}
            onSubmit={(data) => {
                dispatch(actions.updateSearch(data));
            }}
            searchText={filterData.searchText}
            isActiveOnly={filterData.isActiveOnly}
            tableColumns={agentListColumns}
            tableData={list.items}
            sortBy={filterData.sortBy}
            sortOrder={filterData.sortOrder}
            setSort={handleSetSort}
        />
    );
};
