import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { refreshUserDataSaga, userLogoutSaga } from '../UserLogin/saga';
import { loginReducer, loginSliceKey } from '../UserLogin/slice';
import { chargeAccountSaga } from './chargeAccount.saga';
import { appReducer, appSliceKey } from './slice';

export const useInjectApp = () => {
    useInjectReducer({ key: appSliceKey, reducer: appReducer });

    useInjectReducer({ key: loginSliceKey, reducer: loginReducer });
    useInjectSaga({
        key: `${loginSliceKey}UserLogoutSaga`,
        saga: userLogoutSaga,
    });

    useInjectSaga({
        key: 'chargeAccountSaga',
        saga: chargeAccountSaga,
    });

    useInjectSaga({
        key: `${loginSliceKey}/loadUserData`,
        saga: refreshUserDataSaga,
    });
};
