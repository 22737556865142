import { HomeSearchForm, HomeSearchFormProps } from '@malesia/react-components/dist/src/components/Front/HomeSearchForm';
import { NewsProps, News } from '@malesia/react-components/dist/src/components/Front/News/News';
import { AdvertisementsSlider, AdvertisementsSliderItem } from '@malesia/react-components/dist/src/components/Sliders/AdvertisementsSlider';
import { HomePageMainSlider, HomePageSliderItem } from '@malesia/react-components/dist/src/components/Sliders/HomePageMainSlider';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { FlightOverview } from '../../containers/FlightOverview/FlightOverview';
import { allPublicRoutePaths } from '../../containers/Routes/allPublicRoutePaths';
import { loadHomePageContent } from '../../services/directus/homePage/loadHomePageContent';
import { createBookingLink } from '../BookingFlightPage/store/queryParameters';
import { useSearchFormSettings } from './useSearchFormSettings';

export const MalesiaHomePage: SFC = () => {
    const history = useHistory();

    const {
        formConfig,
        propsValues,
        setPropsValues,
    } = useSearchFormSettings();

    const [slides, setSlides] = useState<HomePageSliderItem[]>([]);
    const [advertisementsItems, setAdvertisementsItems] = useState<AdvertisementsSliderItem[]>([]);
    const [news, setNews] = useState<NewsProps | null>(null);
    const { locale } = useIntl();

    useEffect(() => {
        loadHomePageContent(locale)
            .then(({
                specialOfferSlides,
                advertisementsCards,
                news,
            }) => {
                const mainSliderSlides = specialOfferSlides.map((item) => ({
                    image: item.image,
                    flightLabel: {
                        flightText: item.title,
                        priceText: item.price ?? '',
                    },
                    onClick: () => {
                        const url = createBookingLink(allPublicRoutePaths.bookingFlightTravelDates, {
                            travelType: 'return',
                            airportFrom: item.airports.airportFrom,
                            airportTo: item.airports.airportTo,
                        });
                        history.push(url);
                    },
                }));
                setSlides(mainSliderSlides);

                const advertisements = advertisementsCards.map(item => ({
                    image: item.image,
                    onClick: () => {
                        window.open(item.link, '_blank');
                    },
                }));
                setAdvertisementsItems(advertisements);

                if (news) {
                    const expiredAt = new Date(news.expiredAt);
                    if (expiredAt > new Date()) {
                        setNews(news);
                    }
                }
            });
    }, [history, locale]);

    const searchForm = useMemo((): HomeSearchFormProps => ({
        config: formConfig,
        propsValues,
        onChange: setPropsValues,
        submit: (form) => {
            const url = createBookingLink(allPublicRoutePaths.bookingFlightSelectFlightOutbound, {
                travelType: form.isOneWay ? 'one-way' : 'return',
                departureDate: form.departureDate,
                returnDate: form.isOneWay ? undefined : form.returnDate,
                airportFrom: form.airportFrom.code,
                airportTo: form.airportTo.code,
                adult: form.adult,
                child: form.child,
                infant: form.infant,
            });
            history.push(url);
        },
    }), [formConfig, propsValues, setPropsValues, history]);

    return (
        <>
            <HomePageMainSlider
                delay={10000}
                items={slides}
            >
                <HomeSearchForm
                    {...searchForm}
                />
            </HomePageMainSlider>
            {news && (
                <News
                    {...news}
                />
            )}
            <AdvertisementsSlider
                items={advertisementsItems}
                delay={10000}
            />
            <FlightOverview />
        </>
    );
};
