import { StepReservationOverview as ReservationOverview } from '@malesia/react-components/dist/src/components/Reservation/StepReservationOverview';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import { localizeMoney } from '@malesia/react-components/dist/src/utils/roundMoney';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectCost } from '../../../../containers/ReservationBasket/reservationBasket.selectors';
import { selectFormData, selectIsDisabledSubmit, selectIsFormValid } from './selectors';
import { stepReservationOverviewActions } from './slice';
import { usePrepareFlights } from './usePrepareFlights';
import { usePreparePassengersTable } from './usePreparePassengersTable';

export const StepReservationOverview: SFC<object, { result: 'optional' }> = () => {
    const { locale } = useIntl();
    const dispatch = useDispatch();

    const formData = useSelector(selectFormData);
    const isSubmitButtonDisabled = useSelector(selectIsDisabledSubmit);
    const isFormDataValid = useSelector(selectIsFormValid);

    const isStepFitRequirements = true;

    const handleFormDataChange = (formData: any) => {
        const formDataSanitized = {};
        Object.keys(formData || {}).forEach(key => {
            if (formData && !!formData[key]) {
                formDataSanitized[key] = formData[key];
            }
        });
        dispatch(stepReservationOverviewActions.updateFormData(formDataSanitized));
    };

    const handleSubmit = () => {
        if (isFormDataValid) {
            dispatch(
                stepReservationOverviewActions.createReservationAndStartTransaction(),
            );
        }
        return false;
    };

    const flights = usePrepareFlights();
    const passengers = usePreparePassengersTable();
    const cost = useSelector(selectCost);
    const totalCostText = localizeMoney(cost?.total, locale, 'BookingFlightPage/Steps/StepReservationOverview');

    useEffect(() => {
        dispatch(stepReservationOverviewActions.resetFormData());
    }, [dispatch]);

    if (!isStepFitRequirements) return null;
    return (
        <>
            <ReservationOverview
                flights={flights}
                passengers={passengers}
                totalPrice={totalCostText}
                formData={formData}
                isSubmitButtonDisabled={isSubmitButtonDisabled}
                onChange={handleFormDataChange}
                onSubmit={handleSubmit}
            />
            <br />
            <br />
        </>
    );
};
