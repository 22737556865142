import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.allotmentListPage || initialState;

export const selectFilterData = createSelector(
    [selectDomain],
    state => state.filterData,
);

export const selectList = createSelector(
    [selectDomain],
    state => state.table.list,
);

export const selectSelectedRows = createSelector(
    [selectDomain],
    state => state.table.selectedRows,
);

export const selectPagination = createSelector(
    [selectDomain],
    state => state.table.pagination,
);

export const selectSorting = createSelector(
    [selectDomain],
    state => state.table.sorting,
);

export const selectLoading = createSelector(
    [selectDomain],
    state => state.table.loading,
);

export const selectDirty = createSelector(
    [selectDomain],
    state => state.table.dirty,
);
