import { BackLink } from '@malesia/react-components/dist/src/components/BackLink';
import { LoadingPage } from '@malesia/react-components/dist/src/components/LoadingOverlay/Page';
import { ManagerEdit, ManagerFormType } from '@malesia/react-components/dist/src/components/Manager/Edit';
import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { filterDisabledData } from '@malesia/react-components/dist/src/hooks/useForm';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { createCustomPasswordConfirmValidator } from '../../../utils/validation/createCustomPasswordConfirmValidator';
import { getValidationFunction } from '../../../utils/validation/getValidationFunction';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { useInjectSharedData } from '../../containers/SharedData/inject';
import { selectAdminCountryList, selectAdminCountryLoading } from '../../containers/SharedData/selectors';
import { sharedActions } from '../../containers/SharedData/slice';
import { useManagerFormConfig } from './config';
import { getManagerDataSaga, updateManagerDataSaga } from './saga';
import { selectManagerData, selectLoadingManager } from './selectors';
import { actions, reducer, sliceKey } from './slice';

type RouteParams = {
    managerId: string,
};

type Props = RouteComponentProps<RouteParams>;

export const ManagerEditPage: SFC<Props> = (props) => {
    useInjectSharedData();
    useInjectReducer({ actions, key: sliceKey, reducer });
    useInjectSaga({
        key: `${sliceKey}GetManagerData`,
        saga: getManagerDataSaga,
    });
    useInjectSaga({
        key: `${sliceKey}UpdateManagerData`,
        saga: updateManagerDataSaga,
    });

    const managerData = useSelector(selectManagerData);
    const countryData = useSelector(selectAdminCountryList);
    const loadingManager = useSelector(selectLoadingManager);
    const loadingCountries = useSelector(selectAdminCountryLoading);
    const isAnythingLoading = loadingManager || loadingCountries;
    const dispatch = useDispatch();
    const { match } = props;
    const { params } = match;
    const { managerId } = params;

    useEffect(() => {
        if (!managerId) return;
        dispatch(actions.getManagerData(managerId));
    }, [managerId, dispatch]);

    useEffect(() => {
        dispatch(sharedActions.getAdminCountryList());
    }, [dispatch]);

    const formConfig = useManagerFormConfig(managerData);

    const onSubmitForm = useCallback((data: ManagerFormType) => {
        if (!managerId) return;
        dispatch(actions.updateManagerData({
            managerId,
            managerData: filterDisabledData(data, formConfig),
        }));

    }, [formConfig, dispatch, managerId]);

    const validationFn = useMemo(() => (
        getValidationFunction(
            'file://malesiareisen.com/json/schema/project/react-front-app/pages/ManagerEditPage/managerEditForm.json',
            [
                createCustomPasswordConfirmValidator<ManagerFormType>('/password', '/passwordConfirm'),
            ],
        )
    ), []);

    return (
        <PageContentBlock>
            <PageTitle
                text={
                    <FormattedMessage
                        id='front-app/ManagerEditPage:Title'
                        defaultMessage='Edit manager'
                    />
                }
            />
            <BackLink
                to={allAdminRoutePaths.userManagerList}
                modificators='stick-to-title'
            />
            <LoadingPage isLoading={isAnythingLoading} />
            {managerData && !isAnythingLoading && (
                <ManagerEdit
                    countryList={countryData.items}
                    formConfig={formConfig}
                    validator={validationFn}
                    onSubmit={onSubmitForm}
                />
            )}
        </PageContentBlock>
    );
};
