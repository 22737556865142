import {
    ApiCallFlightPassengerFilters,
    FlightDelayNotifyOperation,
    FlightStatus,
    PNLExternalEntireList,
    PNLInternal,
    FlightPassengerBulkOperation,
} from '@malesia/json-schema';
import { tariffGroupIdToCode } from '@malesia/react-components/dist/src/components/TariffsTable/tariffGroupMapper';
import * as queryString from 'query-string';
import { all, call, debounce, put, select, take, takeEvery, takeLatest } from 'typed-redux-saga';
import { downloadFile } from '../../../utils/downloadFile';
import { logError } from '../../../utils/log';
import { selectLocale } from '../../containers/App/selectors';
import { apiClientRequest, getApiErrorMessage } from '../../services/ApiClient';
import { getApiFlight, getApiFlightAircraftPassengersSeats } from '../../services/flight';
import { getApiTariffGroupList } from '../../services/tariffGroup';
import { passengerListNotifications } from './notification';
import { parsePassengerError } from './passengerListNotifications';
import {
    selectComment,
    selectFilterData,
    selectNotifying,
    selectSelectedRows,
    selectFlight,
    selectFlightId,
    selectInitialized,
    selectSeatPopupInfo,
} from './selectors';
import { actions, FlightPassengerAppearing } from './slice';
import { FlightPassengerFilterData, NotifyingFormValuesType, FlightPassengerSeatPopupInfo, AppearingComment } from './types';

// ToDo: Don't use useless `queryString` library with `[]` field name.
// Pass native object for apiClientRequest without JSON.stringify().
type FixMeFlightPassengerFilters = {
    'tariffGroups[]': ApiCallFlightPassengerFilters['tariffGroups'],
    'payment[]': ApiCallFlightPassengerFilters['payment'],
    'appearing[]': ApiCallFlightPassengerFilters['appearing'],
};

export const getQueryParamsFromSearchForm = (
    filterData: FlightPassengerFilterData,
): ApiCallFlightPassengerFilters & FixMeFlightPassengerFilters => ({
    query: filterData.searchText,
    reservationId: filterData.reservationId,
    ['tariffGroups[]']: filterData.tariffGroups,
    ['payment[]']: filterData.paymentStatuses,
    ['appearing[]']: filterData.appearingStatuses,
    sortBy: filterData.sortBy,
    sortOrder: filterData.sortOrder,
});

export const getQueryParamsFromNotifyingForm = (
    notifyingFormValues: NotifyingFormValuesType,
): FlightDelayNotifyOperation => {
    return {
        isEmailNotifyEnabled: notifyingFormValues.isEmailNotifyEnabled,
        isSmsNotifyEnabled: notifyingFormValues.isSmsNotifyEnabled,
        ...!!notifyingFormValues.minutes && { minutes: notifyingFormValues.minutes },
    };
};

export const getQueryParamsFromBulkOperation = (
    bulkOperation: FlightPassengerBulkOperation,
) => {
    return {
    // TODO need to replace on `prepareQueryParamsForPHP(filterParams);`
        'ids[]': 'ids' in bulkOperation && bulkOperation.ids,
        'excluded': 'exclude' in bulkOperation && bulkOperation.exclude,
    };
};

export function* getFlightPassengerList() {
    try {
        const initialized = yield* select(selectInitialized);
        const can = initialized.flightId && initialized.sorting;
        if (!can) return;

        const filterData = yield* select(selectFilterData);
        const flightId = yield* select(selectFlightId);
        const uriParams = { flightId };
        const query = queryString.stringify(
            getQueryParamsFromSearchForm(filterData),
            { skipNull: true, sort: false },
        );

        const result = yield* all({
            list: call(apiClientRequest, {
                requestId: 'flightPassengerList',
                uriParams, query,
            }),
            flightBookingInfo: call(apiClientRequest, {
                requestId: 'flightBookingInfoData',
                uriParams: { id: flightId },
            }),
            flightPassengersInfo: call(apiClientRequest, {
                requestId: 'flightPassengersInfoData',
                uriParams: { id: flightId },
            }),
        });

        yield* put(actions.setFlightPassengerList(result.list));
        yield* put(actions.setFlightBookingInfo(result.flightBookingInfo));
        yield* put(actions.setFlightPassengersInfo(result.flightPassengersInfo));
        yield* put(actions.getFlightPassengerListSuccess());
    }
    catch (error) {
        logError({
            error,
            target: 'FlightPassengerListPage.getFlightPassengerList',
        });
        yield* put(actions.getFlightPassengerListError());
        const message = getApiErrorMessage(error);
        yield* put(passengerListNotifications.unknownError(message));
    }
}

export function* getFlightPassengerListSaga() {
    yield* takeLatest(actions.getFlightPassengerList, getFlightPassengerList);

    function* requestGetFlightPassengerList() {
        yield* put(actions.getFlightPassengerList());
    }

    yield* takeEvery([
        actions.getFlight,
        actions.setSorting,
        actions.resetSearch,
        actions.updateFlightPassengerAppearingSuccess,
        actions.updateFlightPassengersAppearingSuccess,
        actions.updateFlightPassengerSeatSuccess,
        actions.updateFlightPassengerNotifiedSuccess,
    ], requestGetFlightPassengerList);

    yield* debounce(1000, [
        actions.updateSearch,
    ], requestGetFlightPassengerList);
}

export function* getFlight(action: ReturnType<typeof actions.getFlight>) {
    try {
        const { flightId } = action.payload;

        const flight = yield* getApiFlight(flightId);
        yield* put(actions.getFlightSuccess(flight));
    }
    catch (error) {
        logError({
            error,
            target: 'FlightPassengerListPage.getFlight',
        });
        yield* put(actions.getFlightError());
        const message = getApiErrorMessage(error);
        yield* put(passengerListNotifications.unknownError(message));
    }
}

export function* getFlightSaga() {
    yield* takeLatest(actions.getFlight, getFlight);
}

export function* setFlightDelayed(action: ReturnType<typeof actions.setFlightDelayed>) {
    try {
        const { flightId } = action.payload;

        // TODO ask for notifying here
        yield* put(actions.askNotifying());
        yield* take(actions.askNotifyingConfirm);

        const notifying = yield* select(selectNotifying);
        const query = queryString.stringify(getQueryParamsFromNotifyingForm(notifying));
        const requestData = {
            requestId: 'flightSetDelayed',
            uriParams: { id: flightId },
            query,
        };

        yield* call(apiClientRequest, requestData);
        yield* put(actions.setFlightDelayedSuccess());
        yield* put(actions.getFlight({ flightId }));
    }
    catch (error) {
        logError({
            error,
            target: 'FlightPassengerListPage.setFlightDelayed',
        });
        yield* put(actions.setFlightDelayedError());
        const message = getApiErrorMessage(error);
        yield* put(passengerListNotifications.unknownError(message));
    }
}

export function* setFlightDelayedSaga() {
    yield* takeLatest(actions.setFlightDelayed, setFlightDelayed);
}

export function* setFlightDeparted(action: ReturnType<typeof actions.setFlightDeparted>) {
    try {
        const { flightId } = action.payload;
        const status: FlightStatus = 'departed';
        const requestData = {
            requestId: 'flightSetStatus',
            uriParams: { id: flightId, status },
        };

        yield* call(apiClientRequest, requestData);
        yield* put(actions.setFlightDepartedSuccess());
        yield* put(actions.getFlight({ flightId }));
    }
    catch (error) {
        logError({
            error,
            target: 'FlightPassengerListPage.setFlightDeparted',
        });
        yield* put(actions.setFlightDepartedError());
        const message = getApiErrorMessage(error);
        yield* put(passengerListNotifications.unknownError(message));
    }
}

export function* setFlightDepartedSaga() {
    yield* takeLatest(actions.setFlightDeparted, setFlightDeparted);
}

export function* getTariffGroupListData() {
    try {
        const result = yield* getApiTariffGroupList();
        yield* put(actions.getTariffGroupListSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'FlightPassengerListPage.getTariffGroupListData',
        });
        yield* put(actions.getTariffGroupListError());
        const message = getApiErrorMessage(error);
        yield* put(passengerListNotifications.unknownError(message));
    }
}

export function* getTariffGroupListDataSaga() {
    yield* takeLatest(actions.getTariffGroupList, getTariffGroupListData);
}

export function* updateFlightPassengerAppeared(action: ReturnType<typeof actions.updateFlightPassengerAppearing>) {
    const { passengerId, appearing, callback } = action.payload;

    try {
        const appearingMap = {
            appear: { isAppeared: true },
            noShow: { isAppeared: false },
            pending: null,
        } as const;
        const appearingRequest: Partial<FlightPassengerAppearing> | null = appearingMap[appearing];

        const flightId = yield* select(selectFlightId);
        if (appearingRequest !== null && appearingRequest.isAppeared === false) {
            yield* put(actions.askComment());
            yield* take([
                actions.askCommentSuccess,
                actions.askCommentCancel,
            ]);

            const comment: AppearingComment = yield* select(selectComment);

            if (comment.isCancel) {
                yield* put(actions.updateFlightPassengerAppearingCancel(passengerId));
                callback();
                return;
            }
            if (comment.message !== undefined) {
                appearingRequest.comment = comment.message;
            }
        }

        const uriParams = { flightId, id: passengerId };
        const requestData = {
            requestId:
          appearingRequest === null
              ? 'flightPassengerAppearingDelete'
              : 'flightPassengerAppearing',
            uriParams,
            requestPayload: appearingRequest === null ? undefined : appearingRequest,
        };

        yield* call(apiClientRequest, requestData);
        yield* put(actions.updateFlightPassengerAppearingSuccess(passengerId));
    }
    catch (error) {
        logError({
            error,
            target: 'FlightPassengerListPage.updateFlightPassengerAppeared',
        });
        yield* put(actions.updateFlightPassengerAppearingError(passengerId));
    }
    finally {
        callback();
    }
}

export function* updateFlightPassengerAppearedSaga() {
    yield* takeLatest(
        actions.updateFlightPassengerAppearing,
        updateFlightPassengerAppeared,
    );
}

export function* updateFlightPassengerSeat(action: ReturnType<typeof actions.updateFlightPassengerSeat>) {
    try {
        const { reservationId, reservationPassengerId, tariff, seat } = action.payload;

        const flightId = yield* select(selectFlightId);
        const flight = (yield* select(selectFlight))!;
        if (flight.status === 'closed') return;

        yield* put(actions.setSeatPopupInfo({
            seatLabel: seat?.seatLabel,
            bookedSeatLabel: seat?.seatLabel,
            tariffGroupCode: tariffGroupIdToCode[tariff.group.id!],
            errors: {},
        }));

        yield* put(actions.getFlightSeatMap({
            flight, tariffId: tariff.id!, reservationId, reservationPassengerId,
        }));
        yield* take([
            actions.getFlightSeatMapSuccess,
            actions.getFlightSeatMapError,
        ]);
        yield* put(actions.askSeatPopup());
        yield* take(actions.askSeatPopupConfirm);

        const seatPopupInfo: FlightPassengerSeatPopupInfo = yield* select(selectSeatPopupInfo);
        const newSeatLabel = seatPopupInfo.seatLabel;

        const uriParams = { flightId, id: reservationPassengerId };
        const requestData = {
            requestId: !newSeatLabel
                ? 'flightPassengerSeatDelete'
                : 'flightPassengerSeat',
            uriParams,
            requestPayload: !newSeatLabel ? null : { seatLabel: newSeatLabel },
        };

        yield* call(apiClientRequest, requestData);
        yield* put(actions.updateFlightPassengerSeatSuccess());
    }
    catch (error) {
        logError({
            error,
            target: 'FlightPassengerListPage.updateFlightPassengerSeat',
        });
        yield* put(actions.updateFlightPassengerSeatError());
        yield* put(parsePassengerError(error));
    }
}

export function* updateFlightPassengerSeatSaga() {
    yield* takeLatest(
        actions.updateFlightPassengerSeat,
        updateFlightPassengerSeat,
    );
}

export function* updateFlightPassengerNotified(action: ReturnType<typeof actions.updateFlightPassengerNotified>) {
    try {
        const { reservationPassengerId, notifying } = action.payload;
        const flightId = yield* select(selectFlightId);
        const uriParams = { flightId, id: reservationPassengerId };

        const requestData = {
            requestId: 'flightPassengerNotifying',
            uriParams,
            requestPayload: notifying,
        };

        yield* call(apiClientRequest, requestData);
        yield* put(actions.updateFlightPassengerNotifiedSuccess());
    }
    catch (error) {
        logError({
            error,
            target: 'FlightPassengerListPage.updateFlightPassengerNotified',
        });
        yield* put(actions.updateFlightPassengerNotifiedError());
        const message = getApiErrorMessage(error);
        yield* put(passengerListNotifications.unknownError(message));
    }
}

export function* updateFlightPassengerNotifiedSaga() {
    yield* takeLatest(
        actions.updateFlightPassengerNotified,
        updateFlightPassengerNotified,
    );
}

export function* getFlightSeatMap(action: ReturnType<typeof actions.getFlightSeatMap>) {
    try {
        const { flight, reservationId, reservationPassengerId, tariffId } = action.payload;

        const result = yield* getApiFlightAircraftPassengersSeats({
            flight,
            tariffId,
            reservationId,
            reservationPassengerId,
        });
        yield* put(actions.getFlightSeatMapSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'FlightPassengerListPage.getFlightSeatMap',
        });
    }
}

export function* getFlightSeatMapSaga() {
    yield* takeLatest(actions.getFlightSeatMap, getFlightSeatMap);
}

export function* downloadPaxList(action: ReturnType<typeof actions.downloadPaxList>) {
    yield* put(actions.setPAXDownloading(true));
    const locale: string = yield* select(selectLocale);
    const { flightId, filterData } = action.payload;
    const uriParams = { flightId };
    const query = queryString.stringify(
        { ...getQueryParamsFromSearchForm(filterData), language: locale },
        { skipNull: true, sort: false },
    );

    const requestData = {
        requestId: 'flightPassengerListPDF',
        uriParams,
        query,
    };

    const response = yield* call(apiClientRequest, requestData);

    const filename = response.headers
        .get('Content-Disposition')
        ?.match(/filename="(.*?)"/)?.[1] || 'PAXList.pdf';
    yield* downloadFile(response, filename);
    yield* put(actions.setPAXDownloading(false));
}

export function* downloadPaxListSaga() {
    yield* takeLatest(actions.downloadPaxList, downloadPaxList);
}

export function* getPnlList(action: ReturnType<typeof actions.getPnlList>) {
    try {
        const { flightId } = action.payload;
        const uriParams = { flightId };

        yield* put(actions.setPNLDownloading(true));
        const malesia: PNLInternal = yield* call(apiClientRequest, {
            requestId: 'flightPassengerListPNL',
            uriParams,
        });
        const external: PNLExternalEntireList = yield* call(apiClientRequest, {
            requestId: 'flightPassengerListPNLExternal',
            uriParams,
        });
        yield* put(actions.getPnlListSuccess({
            malesia,
            external: external.items?.sort((x, y) => y.total - x.total),
        }));
    }
    catch (error) {
        logError({
            error,
            target: 'FlightPassengerListPage.getPNLList',
        });
        yield* put(actions.getPnlListError());
        const message = getApiErrorMessage(error);
        yield* put(passengerListNotifications.unknownError(message));
    }
    finally {
        yield* put(actions.setPNLDownloading(false));
    }
}

export function* getPnlListSaga() {
    yield* takeLatest(actions.getPnlList, getPnlList);
}

export function* updateFlightPassengersAppeared(action: ReturnType<typeof actions.updateFlightPassengersAppearing>) {
    try {
        const filterData = yield* select(selectFilterData);
        const selectedRows = yield* select(selectSelectedRows);

        const { flightId, appearing } = action.payload;

        const uriParams = { flightId };
        const bulkActionParams: FlightPassengerBulkOperation = {
            ids: selectedRows.map(row => row.id),
            exclude: false,
        };
        const queryParams = {
            ...getQueryParamsFromSearchForm(filterData),
            ...getQueryParamsFromBulkOperation(bulkActionParams),
            sortBy: undefined,
            sortOrder: undefined,
        };
        const requestData = {
            requestId:
                appearing === null
                    ? 'flightPassengerListAppearingDelete'
                    : 'flightPassengerListAppearing',
            uriParams,
            query: queryString.stringify(queryParams, { skipNull: true, sort: false }),
            requestPayload: appearing === null ? undefined : appearing,
        };

        yield* call(apiClientRequest, requestData);
        yield* put(actions.updateFlightPassengersAppearingSuccess());
    }
    catch (error) {
        logError({
            error,
            target: 'FlightPassengerListPage.updateFlightPassengersAppeared',
        });
        yield* put(actions.updateFlightPassengersAppearingError());
        const message = getApiErrorMessage(error);
        yield* put(passengerListNotifications.unknownError(message));
    }
}

export function* updateFlightPassengersAppearedSaga() {
    yield* takeLatest(
        actions.updateFlightPassengersAppearing,
        updateFlightPassengersAppeared,
    );
}
