import { NotificationType } from '@malesia/react-components/dist/src/components/Page/Notifications/Notifications';
import { FlightDirection } from '@malesia/react-components/dist/src/components/Reservation/reservation-types';
import { PayloadAction } from '@reduxjs/toolkit';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { appNotification } from '../../app/containers/App/appNotification';
import { createLocalNotification } from '../../app/containers/App/utils';
import { checkApiError } from '../../app/services/ApiClient';
import { logError } from '../log';

const notification = createLocalNotification('Reservation', {
    error: (
        <FormattedMessage
            id='front-app/Reservation/Error:title'
            defaultMessage='Reservation Error'
        />
    ),
    warning: (
        <FormattedMessage
            id='front-app/Reservation/Warning:title'
            defaultMessage='Reservation Warning'
        />
    ),
    info: (
        <FormattedMessage
            id='front-app/Reservation/Info:title'
            defaultMessage='Reservation Info'
        />
    ),
    success: (
        <FormattedMessage
            id='front-app/Reservation/Info:title'
            defaultMessage='Reservation Info'
        />
    ),
});

const directionTextMap = {
    outbound: (
        <FormattedMessage
            id='front-app/Reservation/FlightDirection:outbound'
            defaultMessage='outbound flight'
        />
    ),
    ['return']: (
        <FormattedMessage
            id='front-app/Reservation/FlightDirection:return'
            defaultMessage='return flight'
        />
    ),
    outboundOrReturn: (
        <FormattedMessage
            id='front-app/Reservation/FlightDirection:outboundOrReturn'
            defaultMessage='outbound or return flight'
        />
    ),
};

const getDirectionText = (direction?: FlightDirection) => {
    switch (direction) {
        case 'outbound': return directionTextMap.outbound;
        case 'return': return directionTextMap.return;
        default: return directionTextMap.outboundOrReturn;
    }
};

export const reservationNotifications = {
    checkFields: notification.warning(appNotification.texts.checkFields),
    createdSuccessfully: notification.success(appNotification.texts.createdSuccessfully),
    updatedSuccessfully: notification.success(appNotification.texts.updatedSuccessfully),
    unknownError: (details?: JSX.Element | string) => (
        notification.error(appNotification.texts.unknownError(details))
    ),
    customError: (text: JSX.Element) => notification.error(text),
};

export const parseReservationError = (
    reservationError: unknown,
    getFlightDirection: (flightId: number) => FlightDirection | undefined,
): PayloadAction<NotificationType> => {
    if (!checkApiError(reservationError)) {
        logError({
            target: 'notifyReservationError.Unhandled',
            error: reservationError,
        });
        return reservationNotifications.unknownError();
    }
    const error = reservationError.response?.errors?.[0];
    const getDirection = (flightId: number) => getDirectionText(getFlightDirection(flightId));
    switch (error?.error) {
        case 'ERROR_BOOKED_FLIGHT_SORTING_WRONG': {
            return notification.error((
                <FormattedMessage
                    id='front-app/Reservation/Error:flightSortingWrong'
                    defaultMessage='The return flight must be after the outbound flight. Please change flight dates.'
                />
            ));
        }
        case 'ERROR_BOOKED_SEATS_OCCUPIED': {
            const direction = getDirection(error.details.flight);
            const seatLabel = error.details.seatLabel ?? 'unknown';
            return notification.error((
                <FormattedMessage
                    id='front-app/Reservation/Error:seatsOccupied'
                    defaultMessage='Seat {seatLabel} is already occupied. Select an available seat on the {direction}.'
                    values={{ seatLabel, direction }}
                />
            ));
        }
        case 'ERROR_BOOKED_SEATS_NOT_UNIQUE': {
            const direction = getDirection(error.details.flight);
            const seatLabel = error.details.seatLabel ?? 'unknown';
            return notification.error((
                <FormattedMessage
                    id='front-app/Reservation/Error:seatsNotUnique'
                    defaultMessage='Seat {seatLabel} is not unique. Choose different seats in {direction} flight.'
                    values={{ seatLabel, direction }}
                />
            ));
        }
        case 'ERROR_BOOKED_DEPARTED_FLIGHT_SEATS_CHANGED': {
            const direction = getDirection(error.details.flight);
            const seatLabel = error.details.seatLabel ?? 'unknown';
            return notification.error((
                <FormattedMessage
                    id='front-app/Reservation/Error:departedFlightSeatsChanged'
                    defaultMessage="Can't change {seatLabel} seat of departed {direction} flight."
                    values={{ seatLabel, direction }}
                />
            ));
        }
        case 'ERROR_BOOKED_DEPARTED_FLIGHT_OPTIONS_CHANGED': {
            const direction = getDirection(error.details.flight);
            return notification.error((
                <FormattedMessage
                    id='front-app/Reservation/Error:departedFlightOptionsChanged'
                    defaultMessage="Can't change options of departed {direction} flight."
                    values={{ direction }}
                />
            ));
        }
        case 'ERROR_BOOKED_SEATS_NOT_BELONG_BOOKED_FLIGHTS': {
            const direction = getDirection(error.details.flight);
            return notification.error((
                <FormattedMessage
                    id='front-app/Reservation/Error:seatsNotBelongBookedFlights'
                    defaultMessage="Seat doesn't exist on selected {direction}."
                    values={{ direction }}
                />
            ));
        }
        case 'ERROR_BOOKED_CANCELLED_FLIGHT_SELECTED': {
            const direction = getDirection(error.details.flight);
            return notification.error((
                <FormattedMessage
                    id='front-app/Reservation/Error:cancelledFlightSelected'
                    defaultMessage="Can't save reservation due {direction} is cancelled."
                    values={{ direction }}
                />
            ));
        }
        case 'ERROR_BOOKED_DEPARTED_FLIGHT_ADDED': {
            const direction = getDirection(error.details.flight);
            return notification.error((
                <FormattedMessage
                    id='front-app/Reservation/Error:departedFlightAdded'
                    defaultMessage='Selected {direction} has already departed. Please select another flight.'
                    values={{ direction }}
                />
            ));
        }
        case 'ERROR_AVAILABLE_SEATS_NOT_ENOUGH': {
            const direction = getDirection(error.details.flight);
            return notification.error((
                <FormattedMessage
                    id='front-app/Reservation/Error:seatsNotEnough'
                    defaultMessage='Not enough seats available on selected {direction}. Please select another flight.'
                    values={{ direction }}
                />
            ));
        }
        case 'ERROR_BOOKED_DEPARTED_FLIGHT_REMOVED': {
            return notification.error((
                <FormattedMessage
                    id='front-app/Reservation/Error:outboundDepartedFlight'
                    defaultMessage="Departed flights can't be changed or removed."
                />
            ));
        }
        case 'ERROR_PAYMENT_DEADLINE_DATE_IN_THE_PAST': {
            return notification.error((
                <FormattedMessage
                    id='front-app/Reservation/Error:paymentDeadlineDateInThePast'
                    defaultMessage="Can't set a deadline. The deadline cannot be earlier than today."
                />
            ));
        }
        case 'ERROR_PAYMENT_TERMS_LATER_IS_NOT_ALLOWED': {
            return notification.error((
                <FormattedMessage
                    id='front-app/Reservation/Error:laterIsNotAllowed'
                    defaultMessage='Reservations with these flights cannot be paid later.'
                />
            ));
        }
        case 'ERROR_USER_IS_NO_LOGGED_IN': {
            return notification.error((
                <FormattedMessage
                    id='front-app/Reservation/Error:userIsNoLoggedIn'
                    defaultMessage='Login is required.'
                />
            ));
        }
        default: {
            logError({
                error,
                target: 'notifyReservationError.UnhandledCase',
            });
            return reservationNotifications.unknownError(reservationError.response.message);
        }
    }
};
