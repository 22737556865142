import { Button } from '@malesia/react-components/dist/src/components/Button';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { allAdminRoutePaths } from '../../../containers/Routes/allAdminRoutePaths';
import { reservationListPermissions } from '../../../permissions/adminPages/reservation/reservationList';
import { useUserPermissions } from '../../../permissions/useUserPermissions';

export const ReservationListHeaderWithStore: SFC = () => {
    const userPermissions = useUserPermissions();

    return (
        <PageTitle
            text={
                <FormattedMessage
                    id='front-app/ReservationListPage:Title'
                    defaultMessage='Reservations'
                />
            }
            rightSlot={
                <Link to={allAdminRoutePaths.reservationNew}>
                    <Button
                        icon='plus'
                        text={
                            <FormattedMessage
                                id='front-app/Reservation/List:AddNewReservation'
                                defaultMessage='New reservation'
                            />
                        }
                    />
                </Link>
            }
            rightSlotHidden={!userPermissions.has(reservationListPermissions.createLink)}
        />
    );
};
