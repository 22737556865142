import { AllotmentInvoiceEditPopup, AllotmentInvoiceEditPopupProps, allotmentInvoiceEditPopupConfig, allotmentInvoiceEditPopupEffects } from '@malesia/react-components/dist/src/components/Allotment/InvoiceEditPopup';
import { useValueForm } from '@malesia/react-components/dist/src/hooks/valueForm';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllotmentInvoicePopupAgent, selectAllotmentInvoicePopupFormData, selectAllotmentInvoicePopupLoading, selectAllotmentInvoicePopupMode, selectAllotmentInvoicePopupOpen } from './selectors';
import { allotmentInvoicePopupActions } from './slice';

export const AllotmentInvoiceEditPopupWithStore: SFC = () => {
    const dispatch = useDispatch();
    const mode = useSelector(selectAllotmentInvoicePopupMode);
    const value = useSelector(selectAllotmentInvoicePopupFormData);
    const open = useSelector(selectAllotmentInvoicePopupOpen);
    const loading = useSelector(selectAllotmentInvoicePopupLoading);
    const agent = useSelector(selectAllotmentInvoicePopupAgent);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const hash = useMemo(() => ({}), [open]);

    const {
        field,
        validateAll,
        isContainsError,
    } = useValueForm({
        value,
        config: allotmentInvoiceEditPopupConfig,
        onChange: (v) => {
            dispatch(allotmentInvoicePopupActions.setFormData(v));
        },
        validationEffects: allotmentInvoiceEditPopupEffects,
        hash,
    });

    const createTitle = (
        <FormattedMessage
            id='front-app/AllotmentInvoicePopup/Create:title'
            defaultMessage='Create Invoice for {agentName}'
            values={{
                agentName: agent?.displayName,
            }}
        />
    );
    const editTitle = (
        <FormattedMessage
            id='front-app/AllotmentInvoicePopup/Edit:title'
            defaultMessage='Edit Invoice for {agentName}'
            values={{
                agentName: agent?.displayName,
            }}
        />
    );
    const allotmentInvoiceEditPopupProps: AllotmentInvoiceEditPopupProps = {
        title: mode === 'create' ? createTitle : editTitle,
        field,
        isOpened: open,
        isLoading: loading,
        isConfirmDisabled: isContainsError,
        onConfirm: () => {
            if (!validateAll()) return;
            dispatch(allotmentInvoicePopupActions.confirmPopup());
        },
        onClose: () => {
            dispatch(allotmentInvoicePopupActions.cancelPopup());
        },
    };

    return (
        <AllotmentInvoiceEditPopup {...allotmentInvoiceEditPopupProps} />
    );
};
