import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { FlightOverviewList, FlightOverviewState } from './types';

// The initial state of the MalesiaHomePage page
export const initialState: FlightOverviewState = {
    columns: [],
    loading: false,
};

const flightOverviewSlice = createSlice({
    name: 'flightOverview',
    initialState,
    reducers: {
        getFlights(state) {
            state.loading = true;
        },
        getFlightsSuccess(state, action: PayloadAction<FlightOverviewList[]>) {
            state.loading = false;
            state.columns = action.payload;
        },
        getFlightsError(state) {
            state.loading = false;
        },
    },
});

export const { actions, reducer, name: sliceKey } = flightOverviewSlice;
