import React from 'react';
import { FormattedMessage } from 'react-intl';
import { appNotification } from '../../containers/App/appNotification';
import { createLocalNotification } from '../../containers/App/utils';

const notification = createLocalNotification('CompanyList', {
    error: (
        <FormattedMessage
            id='front-app/CompanyList/Notification/Error:title'
            defaultMessage='Company List Error'
        />
    ),
});

export const companyListNotifications = {
    unknownError: (details?: JSX.Element | string) => (
        notification.error(appNotification.texts.unknownError(details))
    ),
};
