import { AgentPutRequest } from '@malesia/json-schema';
import { AgentSelfEdit, AgentSelfEditFormData } from '@malesia/react-components/dist/src/components/Agent/SelfEdit';
import { LegacyFormConfig } from '@malesia/react-components/dist/src/hooks/useForm';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getValidationFunction } from '../../../utils/validation/getValidationFunction';
import { selectPublicCountryList } from '../../containers/SharedData/selectors';
import { sharedActions } from '../../containers/SharedData/slice';
import { useUser } from '../../hooks/useUser';
import { selectLoadingUserAccountPage } from './store/userAccount.selectors';
import { userAccountPageActions } from './store/userAccount.slice';

export const UserAccountAgent: SFC = () => {
    const isLoading = useSelector(selectLoadingUserAccountPage);
    const { userInfo } = useUser();
    const dispatch = useDispatch();
    const countryList = useSelector(selectPublicCountryList)!;

    useEffect(() => {
        dispatch(sharedActions.getPublicCountryList());
    }, [dispatch]);

    const submit = useCallback((data: AgentSelfEditFormData) => {
        const agentData: AgentPutRequest & { passwordConfirm?: string } = {
            ...data,
            id: userInfo?.id,
            language: data.language!,
        };
        if (agentData.password === '') delete agentData.password;
        if (agentData.passwordConfirm === '') delete agentData.passwordConfirm;
        dispatch(userAccountPageActions.submitAgent(agentData));
    }, [dispatch, userInfo?.id]);

    const formConfig = useMemo<LegacyFormConfig<AgentSelfEditFormData>>(() => ({
        id: { initialValue: userInfo?.id },
        email: { initialValue: userInfo?.email ?? '', modifiers: ['required'] },
        password: { initialValue: '' },
        passwordConfirm: { initialValue: '' },
        salutation: { initialValue: userInfo?.salutation, modifiers: ['required'] },
        company: { initialValue: userInfo?.company },
        firstName: { initialValue: userInfo?.firstName ?? '', modifiers: ['required'] },
        lastName: { initialValue: userInfo?.lastName ?? '', modifiers: ['required'] },
        street: { initialValue: userInfo?.street, modifiers: ['required'] },
        zip: { initialValue: userInfo?.zip, modifiers: ['required'] },
        city: { initialValue: userInfo?.city },
        country: { initialValue: userInfo?.country, modifiers: ['required'] },
        language: { initialValue: userInfo?.language, modifiers: ['required'] },
        birthday: { initialValue: userInfo?.birthday, modifiers: ['required'] },
        phoneMobile: { initialValue: userInfo?.phoneMobile, modifiers: ['required'] },
        phoneNumberFirst: { initialValue: userInfo?.phoneNumberFirst },
        phoneNumberSecond: { initialValue: userInfo?.phoneNumberSecond },
    }), [userInfo]);

    const validate = useMemo(() => getValidationFunction(
        'file://malesiareisen.com/json/schema/project/react-front-app/pages/UserAccountPage/agentForm.json',
    ), []);

    return (
        <AgentSelfEdit
            loading={isLoading}
            countryList={countryList.items}
            onSubmit={submit}
            formConfig={formConfig}
            validator={validate}
        />
    );
};
