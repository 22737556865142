import { FlightsStatus } from '../../../../utils/reservation/flightsStatus';
import { reservationNewPermissions } from '../../../permissions/adminPages/reservation/reservationNew';
import { UserPermissions } from '../../../permissions/userPermissions';
import { ReservationAllowed } from '.';

export const createNewReservationAllowed = (
    userPermissions: UserPermissions,
    selectedFlightsStatus: FlightsStatus,
) => {
    const newAllowed: ReservationAllowed = {
        travel: {
            pastDays: userPermissions.has(reservationNewPermissions.editWithDepartedFlight),
            outbound: true,
            form: true,
        },
        flight: {
            closed: userPermissions.has(reservationNewPermissions.createWithClosedFlight),
            departed: userPermissions.has(reservationNewPermissions.editWithDepartedFlight),
            priceWithSoldOut: userPermissions.userType === 'manager',
            negativeSeats: userPermissions.userType === 'manager',
        },
        passenger: {
            edit: true,
        },
        seat: {
            input: userPermissions.userType === 'manager',
            outbound: true,
            ['return']: true,
        },
        payment: {
            specificPrice: {
                changeable: true,
                visible: userPermissions.has(reservationNewPermissions.createSpecificPrice),
            },
            hidePrice: {
                changeable: true,
                visible: userPermissions.has(reservationNewPermissions.hidePriceFlag),
            },
            pay: {
                later: {
                    changeable: (
                        !selectedFlightsStatus.departIn48Hours('any')
                        || userPermissions.has(reservationNewPermissions.paymentTermsNearLater)
                    ),
                    visible: (
                        userPermissions.has(reservationNewPermissions.paymentTermsLater)
                        && userPermissions.userType === 'agent'
                    ),
                },
            },
        },
    };

    return newAllowed;
};
