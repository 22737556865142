import { FlightsStatus } from '../../../../utils/reservation/flightsStatus';
import { reservationNewPermissions } from '../../../permissions/adminPages/reservation/reservationNew';
import { UserPermissions } from '../../../permissions/userPermissions';
import { ReservationAllowed } from '.';

export const createEditReservationAllowed = (
    userPermissions: UserPermissions,
    selectedFlightsStatus: FlightsStatus,
) => {
    const editAllowed: ReservationAllowed = {
        travel: {
            pastDays: userPermissions.has(reservationNewPermissions.editWithDepartedFlight),
            outbound: (
                (
                    !selectedFlightsStatus.closed('outbound')
                    || userPermissions.has(reservationNewPermissions.updateWithClosedFlight)
                )
                && (
                    !selectedFlightsStatus.departed('outbound')
                    || userPermissions.has(reservationNewPermissions.editWithDepartedFlight)
                )
            ),
            form: (
                (
                    !selectedFlightsStatus.closed('last')
                    || userPermissions.has(reservationNewPermissions.updateWithClosedFlight)
                )
                && (
                    !selectedFlightsStatus.departed('last')
                    || userPermissions.has(reservationNewPermissions.editWithDepartedFlight)
                )
            ),
        },
        flight: {
            closed: userPermissions.has(reservationNewPermissions.updateWithClosedFlight),
            departed: userPermissions.has(reservationNewPermissions.editWithDepartedFlight),
            priceWithSoldOut: userPermissions.userType === 'manager',
            negativeSeats: userPermissions.userType === 'manager',
        },
        passenger: {
            edit: (
                (
                    !selectedFlightsStatus.closed('last')
                    || userPermissions.has(reservationNewPermissions.updateWithClosedFlight)
                )
                && (
                    !selectedFlightsStatus.departed('last')
                    || userPermissions.has(reservationNewPermissions.editWithDepartedFlight)
                )
            ),
        },
        seat: {
            input: userPermissions.userType === 'manager',
            outbound: (
                !selectedFlightsStatus.cancelled('outbound')
                && (
                    !selectedFlightsStatus.closed('outbound')
                    || userPermissions.has(reservationNewPermissions.updateWithClosedFlight)
                )
                && (
                    !selectedFlightsStatus.departed('outbound')
                    || userPermissions.has(reservationNewPermissions.editWithDepartedFlight)
                )
            ),
            ['return']: (
                !selectedFlightsStatus.cancelled('return')
                && (
                    !selectedFlightsStatus.closed('return')
                    || userPermissions.has(reservationNewPermissions.updateWithClosedFlight)
                )
                && (
                    !selectedFlightsStatus.departed('return')
                    || userPermissions.has(reservationNewPermissions.editWithDepartedFlight)
                )
            ),
        },
        payment: {
            specificPrice: {
                changeable: (
                    (
                        !selectedFlightsStatus.closed('last')
                        || userPermissions.has(reservationNewPermissions.updateWithClosedFlight)
                    )
                    && (
                        !selectedFlightsStatus.departed('last')
                        || userPermissions.has(reservationNewPermissions.editWithDepartedFlight)
                    )
                ),
                visible: userPermissions.has(reservationNewPermissions.createSpecificPrice),
            },
            hidePrice: {
                changeable: (
                    (
                        !selectedFlightsStatus.closed('last')
                        || userPermissions.has(reservationNewPermissions.updateWithClosedFlight)
                    )
                    && (
                        !selectedFlightsStatus.departed('last')
                        || userPermissions.has(reservationNewPermissions.editWithDepartedFlight)
                    )
                ),
                visible: userPermissions.has(reservationNewPermissions.hidePriceFlag),
            },
            pay: {
                later: {
                    changeable: false,
                    visible: false,
                },
            },
        },
    };

    return editAllowed;
};
