import {
    SEAT_ABSENT,
    SEAT_DISABLED,
    SEAT_RESERVED,
} from '@malesia/react-components/dist/src/components/AircraftSeatsPlan/config';
import { put, select, takeLatest } from 'typed-redux-saga';
import { checkIsSeatLabelValid, getSeatIndexes } from '../../pages/FlightEditPage/utils';
import { selectAircraftTemplatePopupActionData, selectAircraftTemplatePopupAircraftTemplateSections } from './selectors';
import { aircraftTemplatePopupActions as actions } from './slice';
export function* clickSeat(action: ReturnType<typeof actions.clickSeat>) {
    const seatLabel = action.payload;
    if (!checkIsSeatLabelValid(seatLabel)) {
        return;
    }

    const seatsActionData = yield* select(selectAircraftTemplatePopupActionData);

    switch (seatsActionData.action) {
        case 'select': {
            yield* put(actions.toggleSelectedSeat(seatLabel));
            break;
        }
        case 'disable':
        case 'reserve':
        case 'hide':
        {
            const sections = yield* select(selectAircraftTemplatePopupAircraftTemplateSections);
            if (sections) {
                const { sectionIndex, rowIndex, columnIndex } = getSeatIndexes(sections, seatLabel);

                if (sectionIndex !== undefined && rowIndex !== undefined && columnIndex !== undefined) {
                    const map = {
                        disable: SEAT_DISABLED,
                        reserve: SEAT_RESERVED,
                        hide: SEAT_ABSENT,
                    };

                    const value = map[seatsActionData.action];
                    yield* put(actions.toggleSeatValue({ sectionIndex, rowIndex, columnIndex, value }));
                }
            }

            break;
        }
    }
}

export function* clickSeatSaga() {
    yield* takeLatest(actions.clickSeat, clickSeat);
}
