import { UserProfile } from '@malesia/json-schema';
import { TableColumn } from '@malesia/react-components/dist/src/components/Table';
import { idColumn } from '@malesia/react-components/dist/src/components/Table/columns/idColumn';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { permissionsListPermissions } from '../../permissions/adminPages/settings/permissionsList';
import { useUserPermissions } from '../../permissions/useUserPermissions';
import { userPermissionsActionsColumn } from './actionsColumn';

export const useTableColumns = (): TableColumn<UserProfile>[] => {
    const history = useHistory();
    const userPermissions = useUserPermissions();
    const { locale } = useIntl();

    const result = useMemo((): TableColumn<UserProfile>[] => ([
        idColumn({
            disableSortBy: true,
        }),
        {
            Header: (
                <FormattedMessage
                    id='front-app/UserPermissionsTable:ColumnName'
                    defaultMessage='Name'
                />
            ),
            id: 'name',
            Cell: ({ row }) => {
                const user = row.original;

                return (
                    <div>
                        {user.displayName}
                    </div>
                );
            },
            disableSortBy: true,
        },
        {
            Header: (
                <FormattedMessage
                    id='front-app/UserPermissionsTable:ColumnEmail'
                    defaultMessage='Email'
                />
            ),
            accessor: 'email',
            disableSortBy: true,
        },
        {
            Header: (
                <FormattedMessage
                    id='front-app/UserPermissionsTable:ColumnUserType'
                    defaultMessage='User Type'
                />
            ),
            id: 'userType',
            disableSortBy: true,
            Cell: ({ row }) => {
                return row.original.type ?? null;
            },
        },
        {
            Header: (
                <FormattedMessage
                    id='front-app/UserPermissionsTable:ColumnPermissionsGroup'
                    defaultMessage='Permissions group'
                />
            ),
            id: 'groupTitle',
            disableSortBy: true,
            Cell: ({ row }) => {
                const user = row.original;

                return user.groupTitle?.[locale] ?? null;
            },
        },
        userPermissionsActionsColumn({
            editUserPermissions: {
                click: (user) => {
                    const path = generatePath(allAdminRoutePaths.settingsUserPermissions, {
                        userId: user.id!,
                    });
                    history.push(path);
                },
                notAllowed: !userPermissions.has(permissionsListPermissions.editLink),
            },
        }),
    ]), [
        history,
        userPermissions,
        locale,
    ]);

    return result;
};
