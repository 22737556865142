import { debounce, put, select, takeLatest, throttle } from 'typed-redux-saga';
import { logError } from '../../../../utils/log';
import { selectSelectedReservationOwner, selectSelectedReservationReservator } from '../../../containers/AuxiliaryData/selectors';
import { auxiliaryActions } from '../../../containers/AuxiliaryData/slice';
import { getApiPermittedReservationList } from '../../../services/Reservation';
import { buildReservationListRequest } from '../../../services/Reservation/listUtils';
import { reservationsListNotifications } from './notifications';
import {
    selectInitialized, selectFilterData, selectPagination, selectSorting,
} from './selectors';
import { actions } from './slice';
import { selectUserPermissions } from 'app/permissions/selectUserPermissions';
import { getApiErrorMessage } from 'app/services/ApiClient';

function* getReservationList() {
    try {
        const initialized = yield* select(selectInitialized);
        const isLastInitCallback = initialized.reservator && initialized.owner && initialized.sorting;
        if (!isLastInitCallback) return;

        const pagination = yield* select(selectPagination);
        const sorting = yield* select(selectSorting);
        const filterData = yield* select(selectFilterData);
        const userPermissions = yield* selectUserPermissions();

        const request = buildReservationListRequest(
            userPermissions,
            pagination,
            sorting,
            filterData,
        );
        const result = yield* getApiPermittedReservationList(request);

        yield* put(actions.getReservationListSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'ReservationsListPage.getReservationList',
        });
        yield* put(actions.getReservationListFail());
        const message = getApiErrorMessage(error);
        yield* put(reservationsListNotifications.unknownError(message));
    }
}

export function* getReservationListSaga() {
    yield* takeLatest(actions.getReservationList, getReservationList);

    function* requestGetReservationList() {
        yield* put(actions.getReservationList());
    }

    yield* throttle(1000, [
        actions.setPage,
        actions.setSorting,
        actions.resetFilterData,
        actions.setOwner,
        actions.setReservator,
        actions.confirmCancelReservationSuccess,
    ], requestGetReservationList);

    function* initReservator() {
        const selected = yield* select(selectSelectedReservationReservator);
        yield* put(actions.setReservator(selected));
    }
    yield* takeLatest([
        auxiliaryActions.selectReservationReservatorSuccess,
        auxiliaryActions.selectReservationReservatorError,
    ], initReservator);

    function* initOwner() {
        const selected = yield* select(selectSelectedReservationOwner);
        yield* put(actions.setOwner(selected));
    }
    yield* takeLatest([
        auxiliaryActions.selectReservationOwnerSuccess,
        auxiliaryActions.selectReservationOwnerError,
    ], initOwner);

    yield* debounce(1000, [
        actions.setFilterData,
        actions.setPageSize,
    ], requestGetReservationList);
}
