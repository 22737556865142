import { AllotmentCreateInvoiceButton } from '@malesia/react-components/dist/src/components/Allotment/List/components/CreateInvoiceButton';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import { verifyAgent } from '@malesia/react-components/dist/src/utils/verify';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { allotmentInvoicePopupActions } from '../../../containers/AllotmentInvoicePopup/slice';
import { allotmentListPermissions } from '../../../permissions/adminPages/allotment/allotmentList';
import { useUserPermissions } from '../../../permissions/useUserPermissions';
import { selectSelectedRows } from '../store/selectors';

export const AllotmentCreateInvoiceButtonWithStore: SFC<object, { result: 'optional' }> = () => {
    const dispatch = useDispatch();
    const userPermissions = useUserPermissions();
    const selectedRows = useSelector(selectSelectedRows);

    const checkCanCreateInvoice = () => {
        if (!selectedRows.length) return false;

        const agent = selectedRows[0].agent;
        const anotherAgent = selectedRows.find(x => x.agent.id !== agent.id);
        if (anotherAgent) return false;

        const invoiced = selectedRows.find(x => x.invoice?.id);
        if (invoiced) return false;
        return true;
    };

    if (!userPermissions.has(allotmentListPermissions.create)) return null;

    return (
        <AllotmentCreateInvoiceButton
            onClick={() => {
                const agent = verifyAgent(selectedRows[0].agent)!;
                dispatch(allotmentInvoicePopupActions.openCreatePopup({
                    allotments: selectedRows,
                    agent,
                }));
            }}
            disabled={!checkCanCreateInvoice()}
        />
    );
};
