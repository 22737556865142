import { ApiCallUserListFilters } from '@malesia/json-schema';
import { debounce, put, select, takeLatest, throttle } from 'typed-redux-saga';
import { logError } from '../../../../utils/log';
import { appNotification } from '../../../containers/App/appNotification';
import { getApiErrorMessage } from '../../../services/ApiClient';
import { getApiUserList } from '../../../services/user';
import { selectPagination, selectSearchFormData as selectFilterData } from './selectors';
import { userPermissionsListPageActions } from './slice';

export function* loadUsers() {
    try {
        const pagination = yield* select(selectPagination);
        const filterData = yield* select(selectFilterData);

        const filters: ApiCallUserListFilters = {
            page: pagination.page,
            pageSize: pagination.pageSize,
            isActive: filterData.isActiveOnly,
            query: filterData.searchText,
            type: filterData.userType === 'all' ? [] : [filterData.userType],
        };

        const userList = yield* getApiUserList(filters);

        yield* put(userPermissionsListPageActions.getUserListSuccess(userList));
    }
    catch (error) {
        logError({
            error,
            target: 'SettingsPermissionsListPage.loadUsers',
        });
        const message = getApiErrorMessage(error);
        yield* put(appNotification.unknownError(message));
        yield* put(userPermissionsListPageActions.getUserListError());
    }
}

export function* onChangeSaga() {
    yield* takeLatest(userPermissionsListPageActions.getUserList, loadUsers);

    function* requestGetUserList() {
        yield* put(userPermissionsListPageActions.getUserList());
    }

    yield* throttle(1000, [
        userPermissionsListPageActions.setPage,
        userPermissionsListPageActions.setSorting,
    ], requestGetUserList);

    yield* debounce(1000, [
        userPermissionsListPageActions.setFilterData,
        userPermissionsListPageActions.setPageSize,
    ], requestGetUserList);
}
