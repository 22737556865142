import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../utils/log';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { companyNotifications } from '../FlightCompanyNewPage/notification';
import { actions } from './slice';
import { apiClientRequest, getApiErrorMessage } from 'app/services/ApiClient';

export function* getFlightCompanyData(action: ReturnType<typeof actions.getFlightCompanyData>) {
    try {
        const { flightCompanyId } = action.payload;

        const requestData = {
            requestId: 'flightCompanyData',
            uriParams: { id: flightCompanyId },
        };

        const result = yield* call(apiClientRequest, requestData);
        yield* put(actions.getFlightCompanyDataSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'FlightCompanyEditPage.getFlightCompanyData',
        });
        yield* put(actions.getFlightCompanyDataError());
        const message = getApiErrorMessage(error);
        yield* put(companyNotifications.unknownError(message));
    }
}

export function* getFlightCompanyDataSaga() {
    yield* takeLatest(actions.getFlightCompanyData, getFlightCompanyData);
}

export function* updateFlightCompanyData(action: ReturnType<typeof actions.updateFlightCompanyData>) {
    try {
        const { flightCompanyId, flightCompanyData } = action.payload;

        const requestData = {
            requestId: 'flightCompanyDataUpdate',
            uriParams: { id: flightCompanyId },
            requestPayload: flightCompanyData,
        };

        const result = yield* call(apiClientRequest, requestData);
        yield* put(actions.updateFlightCompanyDataSuccess(result));
        yield* put(push(allAdminRoutePaths.flightCompanyList));
        yield* put(companyNotifications.updatedSuccessfully);
    }
    catch (error) {
        logError({
            error,
            target: 'FlightCompanyEditPage.updateFlightCompanyData',
        });
        yield* put(actions.updateFlightCompanyDataError());
        const message = getApiErrorMessage(error);
        yield* put(companyNotifications.unknownError(message));
    }
}

export function* updateFlightCompanyDataSaga() {
    yield* takeLatest(
        actions.updateFlightCompanyData,
        updateFlightCompanyData,
    );
}
