import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.customerEditPage || initialState;

export const selectCustomerData = createSelector(
    [selectDomain],
    state => state.customerData,
);

export const selectLoading = createSelector(
    [selectDomain],
    state => state.loading,
);
