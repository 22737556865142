import { ReservationSummary } from '@malesia/react-components/dist/src/components/Reservation/Summary';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectSelectedFlightVariants, selectCost } from '../../../containers/ReservationBasket/reservationBasket.selectors';
import { selectSumSeatsPrice } from '../store/selectors';
import { selectOptionsSummary } from './selectors';
import { getReservationSummaryData } from 'app/pages/BookingFlightPage/StepsSummary/utils';

type StepsSummaryProps = {
    isContentVisible: boolean,
};

export const StepsSummary: SFC<StepsSummaryProps> = (props) => {
    const { locale } = useIntl() || { locale: 'en' };

    const { isContentVisible } = props;

    const selectedFlights = useSelector(selectSelectedFlightVariants);
    const cost = useSelector(selectCost);
    const optionsSummary = useSelector(selectOptionsSummary);
    const totalSeatsCost = useSelector(selectSumSeatsPrice);

    const summaryData = useMemo(() => {
        return getReservationSummaryData({
            locale,
            selectedFlights,
            cost,
            optionsSummary,
            totalSeatsCost,
        });
    }, [locale, optionsSummary, selectedFlights, cost, totalSeatsCost]);

    return (
        <ReservationSummary
            {...summaryData}
            isContentVisible={isContentVisible}
        />
    );
};
