import { put, select, takeLatest } from 'typed-redux-saga';
import {
    selectAircraftTemplatePopupActionData,
    selectAircraftTemplatePopupAircraftTemplateSections,
    selectAircraftTemplatePopupSelectedSeats,
} from './selectors';
import { aircraftTemplatePopupActions as actions } from './slice';
import { checkIsSeatLabelValid, getNeighborSeatLabels, getSeatIndexes } from './utils';

export function* doubleClickSeat(action: ReturnType<typeof actions.doubleClickSeat>) {
    const seatLabel = action.payload;
    if (!checkIsSeatLabelValid(seatLabel)) {
        return;
    }

    const actionData = yield* select(selectAircraftTemplatePopupActionData);

    switch (actionData.action) {
        case 'select': {
            const sections = yield* select(selectAircraftTemplatePopupAircraftTemplateSections);
            if (sections) {
                const { sectionIndex, rowIndex, columnIndex } = getSeatIndexes(sections, seatLabel);
                if (sectionIndex !== undefined && rowIndex !== undefined && columnIndex !== undefined) {
                    const neighborSeatLabels = getNeighborSeatLabels(
                        sections[sectionIndex], rowIndex, columnIndex, [seatLabel],
                    );

                    const selectedSeats = yield* select(selectAircraftTemplatePopupSelectedSeats);
                    const isSeatSelected = selectedSeats.includes(seatLabel);

                    for (const neighborSeatLabel of neighborSeatLabels) {
                        yield* put(
                            isSeatSelected
                                ? actions.unSelectSeat(neighborSeatLabel)
                                : actions.selectSeat(neighborSeatLabel),
                        );
                    }
                }

            }
            break;
        }
    }
}

export function* doubleClickSeatSaga() {
    yield* takeLatest(actions.doubleClickSeat, doubleClickSeat);
}
