import { Allotment, Flight } from '@malesia/json-schema';
import { push } from 'connected-react-router';
import { call, put, select, take, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../../utils/log';
import { selectAircraftTemplatePopupAircraftTemplate } from '../../../containers/FlightAircraftTemplate/selectors';
import { aircraftTemplatePopupActions } from '../../../containers/FlightAircraftTemplate/slice';
import { backLinks } from '../../../containers/Routes/backLinks';
import { flightNewPermissions } from '../../../permissions/adminPages/flight/flightNew';
import { selectUserPermissions } from '../../../permissions/selectUserPermissions';
import { apiClientRequest, getApiErrorMessage } from '../../../services/ApiClient';
import { getApiCreationFlightData } from './api';
import { flightNotifications, parseFlightError } from './notification';
import {
    selectAllotments,
    selectFlightAircraftTemplate,
    selectFlightData,
    selectIsAircraftSeatsConfigured,
    selectRepeatingFormData,
} from './selectors';
import { actions } from './slice';

export function* prepareFlightData(
    flight: Flight,
    allotments: Allotment[] | undefined,
    isAircraftSeatsConfigured: boolean,
) {
    const userPermissions = yield* selectUserPermissions();
    const result: Flight = {
        ...flight,
        aircraftTemplate: flight.aircraftTemplate
            ? isAircraftSeatsConfigured
                ? flight.aircraftTemplate
                : { id: flight.aircraftTemplate.id! }
            : null,
        allotments: (
            userPermissions.has(flightNewPermissions.editAllotment)
                ? allotments
                : undefined
        ),
    };
    return result;
}

function* getCreationFlightData() {
    try {
        const creationFlightData = yield* getApiCreationFlightData();
        yield* put(actions.getCreationFlightDataSuccess(creationFlightData));
    }
    catch (error) {
        logError({
            error,
            target: 'FlightNewPage.getCreationFlightData',
        });
        yield* put(actions.getCreationFlightDataError());
        const message = getApiErrorMessage(error);
        yield* put(flightNotifications.unknownError(message));
    }
}

export function* getCreationFlightDataSaga() {
    yield* takeLatest(actions.getCreationFlightData, getCreationFlightData);
}

export function* setFlightData(action: ReturnType<typeof actions.setFlightData>) {
    if (!!action.payload.aircraftTemplate?.id && !action.payload.aircraftTemplate?.sections) {
        const requestData = {
            requestId: 'aircraftTemplate',
            uriParams: { id: action.payload.aircraftTemplate.id },
        };

        const result = yield* call(apiClientRequest, requestData);

        yield* put(actions.setFlightAircraftTemplate(result));
    }
}

export function* setFlightDataSaga() {
    yield* takeLatest(actions.setFlightData, setFlightData);
}

export function* createFlight() {
    try {
        const isAircraftTemplateConfigured = false;
        const flightData = yield* select(selectFlightData);
        const allotments = yield* select(selectAllotments);

        const requestPayload = yield* prepareFlightData(flightData, allotments, isAircraftTemplateConfigured);

        const requestData = {
            requestId: 'flightCreate',
            requestPayload,
        };

        const result: Flight = yield* call(apiClientRequest, requestData);
        yield* put(actions.createFlightSuccess());
        const back = backLinks.flight.back({ highlightFlightId: result.id });
        yield* put(push(back));
        yield* put(flightNotifications.createdSuccessfully);
    }
    catch (error) {
        logError({
            error,
            target: 'FlightNewPage.createFlight',
        });
        yield* put(actions.createFlightError());
        const message = parseFlightError(error);
        yield* put(message);
    }
}

export function* createFlightSaga() {
    yield* takeLatest(actions.createFlight, createFlight);
}

export function* createFlightSequence() {
    try {
        const isAircraftTemplateConfigured = yield* select(selectIsAircraftSeatsConfigured);

        const flightData = yield* select(selectFlightData);
        const allotments = yield* select(selectAllotments);
        const repeatingData = yield* select(selectRepeatingFormData);

        const requestPayload = yield* prepareFlightData(flightData, allotments, isAircraftTemplateConfigured);

        const flightSequenceData = {
            flight: requestPayload,
            repeating: repeatingData,
        };

        const requestData = {
            requestId: 'flightSequenceCreate',
            requestPayload: flightSequenceData,
        };

        const result: Flight = yield* call(apiClientRequest, requestData);
        yield* put(actions.createFlightSequenceSuccess());
        const back = backLinks.flight.back({
            highlightSequenceFlightId: result.sequence ?? undefined,
        });
        yield* put(push(back));
        yield* put(flightNotifications.createdSuccessfully);
    }
    catch (error) {
        logError({
            error,
            target: 'FlightNewPage.createFlightSequence',
        });
        yield* put(actions.createFlightSequenceError());
        const message = parseFlightError(error);
        yield* put(message);
    }
}

export function* createFlightSequenceSaga() {
    yield* takeLatest(actions.createFlightSequence, createFlightSequence);
}

function* clickSettings() {
    const currentAircraftTemplate = yield* select(selectFlightAircraftTemplate);

    if (!!currentAircraftTemplate) {
        yield* put(aircraftTemplatePopupActions.setAircraftTemplate(currentAircraftTemplate));
        yield* put(aircraftTemplatePopupActions.openModal());

        yield* take(aircraftTemplatePopupActions.confirmModal);

        const newAircraftTemplate = yield* select(selectAircraftTemplatePopupAircraftTemplate);
        if (newAircraftTemplate) {
            yield* put(actions.setFlightAircraftTemplate(newAircraftTemplate));
            yield* put(actions.setIsAircraftSeatsConfigured(true));
        }
    }
}

export function* clickSettingsSaga() {
    yield* takeLatest(actions.clickSettings, clickSettings);
}
