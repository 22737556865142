import { StepSelectFlight } from '@malesia/react-components/dist/src/components/Reservation/StepSelectFlight';
import classnames from 'classnames';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectAirportHashMap } from '../../../../containers/SharedData/selectors';
import { useAirportsFromToText } from '../../common/useAirportsFromToText';
import {
    selectBookedFlights,
    selectFlightLists,
    selectSelectedFlights,
    selectTravelInfo,
} from '../../store/selectors';
import { useAdminFlightTabs } from '../OutboundFlight/useAdminFlightTabs';

export type StepReturnFlightProps = {
    className?: string,
};

export function StepReturnFlight({ className }: StepReturnFlightProps) {
    const classNames = classnames('step-return-flight', className ?? '');

    const travelInfo = useSelector(selectTravelInfo);
    const airportHashMap = useSelector(selectAirportHashMap);
    const returnAirportsFromToText = useAirportsFromToText(
        travelInfo.return.airports,
        airportHashMap,
    );

    const flightLists = useSelector(selectFlightLists);
    const selectedFlights = useSelector(selectSelectedFlights);
    const bookedFlights = useSelector(selectBookedFlights);
    const { tabs, activeTabIndex } = useAdminFlightTabs(
        flightLists.return,
        selectedFlights.return,
        bookedFlights.return,
        'return',
    );

    const onSubmit = useCallback(() => {}, []);

    return (
        <div className={classNames}>
            <StepSelectFlight
                flightDirection='return'
                notShowSubmitButton={true}
                isSubmitButtonDisabled={false}
                onSubmit={onSubmit}
                activeTab={activeTabIndex}
                airportsFromToText={returnAirportsFromToText}
                flightLoading={flightLists.isLoading}
                flightTabs={tabs}
            />
        </div>
    );
}
