import { FlightCompany } from '@malesia/json-schema';
import { BackLink } from '@malesia/react-components/dist/src/components/BackLink';
import { FlightCompanyEdit } from '@malesia/react-components/dist/src/components/Flight/Company/Edit';
import { LoadingPage } from '@malesia/react-components/dist/src/components/LoadingOverlay/Page';
import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import {
    getFlightCompanyDataSaga,
    updateFlightCompanyDataSaga,
} from './saga';
import {
    selectLoading,
    selectFlightCompanyData,
} from './selectors';
import { actions, reducer, sliceKey } from './slice';

type RouteParams = {
    flightCompanyId: string,
};

type Props = RouteComponentProps<RouteParams>;

export const FlightCompanyEditPage: SFC<Props> = (props) => {
    useInjectReducer({ actions, key: sliceKey, reducer });
    useInjectSaga({
        key: `${sliceKey}Get`,
        saga: getFlightCompanyDataSaga,
    });
    useInjectSaga({
        key: `${sliceKey}Update`,
        saga: updateFlightCompanyDataSaga,
    });

    const flightCompanyData = useSelector(selectFlightCompanyData);
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const { match } = props;
    const { params } = match;
    const { flightCompanyId } = params;

    useEffect(() => {
        if (!flightCompanyId) return;
        dispatch(actions.getFlightCompanyData({ flightCompanyId }));
    }, [flightCompanyId, dispatch]);

    const onSubmitForm = (flightCompanyData: FlightCompany) => {
        dispatch(actions.updateFlightCompanyData({
            flightCompanyId,
            flightCompanyData,
        }));
    };

    return (
        <PageContentBlock>
            <PageTitle
                text={
                    <FormattedMessage
                        id='front-app/FlightCompanyEditPage:Title'
                        defaultMessage='Edit Airline Company'
                    />
                }
            />
            <BackLink
                to={allAdminRoutePaths.flightCompanyList}
                modificators='stick-to-title'
            />
            <LoadingPage isLoading={loading} />
            {flightCompanyData && !loading && (
                <FlightCompanyEdit
                    propsValues={flightCompanyData}
                    onSubmit={onSubmitForm}
                />
            )}
        </PageContentBlock>
    );
};
