import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../utils/log';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { companyNotifications } from './notification';
import { actions } from './slice';
import { apiClientRequest, getApiErrorMessage } from 'app/services/ApiClient';

export function* createFlightCompany(action: ReturnType<typeof actions.createFlightCompany>) {
    try {
        const flightCompanyData = action.payload;

        const requestData = {
            requestId: 'flightCompanyCreate',
            requestPayload: flightCompanyData,
        };

        const result = yield* call(apiClientRequest, requestData);
        yield* put(actions.createFlightCompanySuccess(result));
        yield* put(push(allAdminRoutePaths.flightCompanyList));
        yield* put(companyNotifications.createdSuccessfully);
    }
    catch (error) {
        logError({
            error,
            target: 'FlightCompanyNewPage.createFlightCompany',
        });
        yield* put(actions.createFlightCompanyError());
        const message = getApiErrorMessage(error);
        yield* put(companyNotifications.unknownError(message));
    }
}

export function* createFlightCompanySaga() {
    yield* takeLatest(actions.createFlightCompany, createFlightCompany);
}
