const ASSETS_URL = window.config.REACT_APP_DIRECTUS_URI! + 'assets/';

export function getImageUrl(imageId: string | number | undefined | null, width?: number, height?: number) {
    let resultUrl = '';
    if (!imageId) return resultUrl;
    resultUrl = ASSETS_URL + imageId + '?format=webp';
    if (width) resultUrl = resultUrl + `&width=${width}`;
    if (height) resultUrl = resultUrl + `&height=${height}`;
    return resultUrl;
}
