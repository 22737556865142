import { Allotment, AllotmentPaginatedList, ApiCallAllotmentSortingParams } from '@malesia/json-schema';
import { AllotmentFilterData } from '@malesia/react-components/dist/src/components/Allotment/List/Filter';
import { DATE_OUTPUT_FORMAT } from '@malesia/react-components/dist/src/utils/dateTimeFormat';
import { getWeekDayDate } from '@malesia/react-components/dist/src/utils/weekDay';
import { PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { createSlice } from '../../../../utils/@reduxjs/toolkit';
import { AllotmentListPageState } from './types';

export const initialState: AllotmentListPageState = {
    filterData: {
        departure: {
            from: moment(getWeekDayDate('monday')).format(DATE_OUTPUT_FORMAT),
            to: moment(getWeekDayDate('sunday')).format(DATE_OUTPUT_FORMAT),
        },
        invoicedStatus: ['open'],
    },
    table: {
        list: {
            items: [],
            total: 0,
        },
        pagination: {
            page: 0,
            pageSize: 30,
        },
        sorting: {
            sortBy: 'flightDate',
            sortOrder: 'ASC',
        },
        selectedRows: [],
        dirty: true,
        loading: true,
    },
};

const allotmentListPageSlice = createSlice({
    name: 'allotmentListPage',
    initialState,
    reducers: {
        setPage(state, action: PayloadAction<number>) {
            state.table.pagination.page = action.payload;
            state.table.dirty = true;
        },
        setPageSize(state, action: PayloadAction<number>) {
            state.table.pagination.pageSize = action.payload;
            state.table.pagination.page = initialState.table.pagination.page;
            state.table.dirty = true;
        },
        setSorting(state, action: PayloadAction<ApiCallAllotmentSortingParams>) {
            state.table.sorting = action.payload;
            state.table.dirty = true;
        },
        setFilterData(state, action: PayloadAction<Partial<AllotmentFilterData>>) {
            state.filterData = action.payload;
            state.table.pagination.page = initialState.table.pagination.page;
            state.table.dirty = true;
        },
        resetFilterData(state) {
            state.filterData = initialState.filterData;
            state.table.pagination.page = initialState.table.pagination.page;
            state.table.sorting = initialState.table.sorting;
            state.table.dirty = true;
        },
        getAllotmentList(state) {
            state.table.loading = true;
        },
        getAllotmentListSuccess(state, action: PayloadAction<AllotmentPaginatedList>) {
            state.table.list = action.payload;
            state.table.dirty = false;
            state.table.loading = false;
        },
        getAllotmentListError(state) {
            state.table.loading = false;
        },
        setSelectedRows(state, action: PayloadAction<Allotment[]>) {
            state.table.selectedRows = action.payload;
        },
        unmount(state) {
            return {
                ...initialState,
                filterData: state.filterData,
            };
        },
    },
});

export const { actions, reducer, name: sliceKey } = allotmentListPageSlice;
