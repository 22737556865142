import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AllotmentInvoiceEditPopupWithStore } from '../../containers/AllotmentInvoicePopup/AllotmentInvoiceEditPopupWithStore';
import { AllotmentDeleteInvoicePopupWithStore } from './components/AllotmentDeleteInvoicePopupWithStore';
import { AllotmentInvoiceListFilterWithStore } from './components/AllotmentInvoiceListFilterWithStore';
import { AllotmentInvoiceListFooterWithStore } from './components/AllotmentInvoiceListFooterWithStore';
import { AllotmentInvoiceListWithStore } from './components/AllotmentInvoiceListWithStore';
import { useInjectAllotmentInvoiceList } from './store/inject';

export const AllotmentInvoiceListPage: SFC = () => {
    useInjectAllotmentInvoiceList();

    return (
        <PageContentBlock>
            <PageTitle
                text={
                    <FormattedMessage
                        id='front-app/AllotmentInvoiceListPage:Title'
                        defaultMessage='Allotment Invoices'
                    />
                }
            />
            <AllotmentInvoiceListFilterWithStore />
            <AllotmentInvoiceListWithStore />
            <AllotmentInvoiceListFooterWithStore />
            <AllotmentDeleteInvoicePopupWithStore />
            <AllotmentInvoiceEditPopupWithStore />
        </PageContentBlock>
    );
};
