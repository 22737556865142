import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { getAgentListSaga } from './saga/agent';
import { getOptionsListSaga } from './saga/option';
import { getReservationOwnerListSaga, selectReservationOwnerSaga } from './saga/owner';
import { getReservationReservatorListSaga, selectReservationReservatorSaga } from './saga/reservator';
import { auxiliaryActions, auxiliaryReducer, auxiliarySliceKey } from './slice';

export const useInjectAuxiliaryData = () => {
    useInjectReducer({
        actions: auxiliaryActions,
        key: auxiliarySliceKey,
        reducer: auxiliaryReducer,
    });
    useInjectSaga({
        key: `${auxiliarySliceKey}getOptionsListSaga`,
        saga: getOptionsListSaga,
    });
    useInjectSaga({
        key: `${auxiliarySliceKey}getReservationOwnerListSaga`,
        saga: getReservationOwnerListSaga,
    });
    useInjectSaga({
        key: `${auxiliarySliceKey}selectReservationOwnerSaga`,
        saga: selectReservationOwnerSaga,
    });
    useInjectSaga({
        key: `${auxiliarySliceKey}getReservationReservatorListSaga`,
        saga: getReservationReservatorListSaga,
    });
    useInjectSaga({
        key: `${auxiliarySliceKey}selectReservationReservatorSaga`,
        saga: selectReservationReservatorSaga,
    });
    useInjectSaga({
        key: `${auxiliarySliceKey}searchAgentList`,
        saga: getAgentListSaga,
    });
};
