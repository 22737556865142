const apiMalesiaDomainUri = window.config.REACT_APP_API_MALESIA_URI;
const apiMalesiaVersionUri = window.config.REACT_APP_API_MALESIA_URI_VERSION;

export const propertyToCountryMap = {
    isMacedonia: 'MK',
    isAlbania: 'AL',
    isKosovo: 'KV',
    isSwitzerland: 'CH',
};
export const apiMalesiaFullUri = apiMalesiaDomainUri! + apiMalesiaVersionUri;
