import {
    useInjectReducer,
    useInjectSaga,
} from '../../../../utils/redux-injectors';
import { agentPaymentReducer } from './AgentPayment.slice';
import { onLoadSaga } from './sagas/onLoadSaga';

export function useAgentPaymentStore() {
    useInjectReducer({
        key: 'agentPaymentPage',
        reducer: agentPaymentReducer,
    });

    useInjectSaga({
        key: 'agentPaymentPage/onLoadSaga',
        saga: onLoadSaga,
    });

}
