import { FormErrorType } from '@malesia/react-components/dist/src/components/ValidationErrors';
import { ErrorObject } from 'ajv';
import { formatSchemaValidationErrors } from '../../app/containers/ValidationErrorMessage';
import { coercionDataToSchema } from './coercion';

export type CustomValidator<T> = (data: T) => ErrorObject<string, Record<string, any>, unknown>[];

export function getValidationFunction<FormDataType>(
    schemeId: string,
    customValidators?: CustomValidator<Partial<FormDataType>>[],
) {
    return (formData: Partial<FormDataType>): Partial<Record<keyof FormDataType, FormErrorType[]>> => {
        const [, , errors] = coercionDataToSchema(
            schemeId,
            formData || {},
        );
        const customErrors = customValidators?.map(validator => validator(formData));
        const allErrors = (errors ?? []).concat(customErrors?.flat() ?? []);
        if (!allErrors) return {};
        return formatSchemaValidationErrors(allErrors) as Partial<Record<keyof FormDataType, FormErrorType[]>>;
    };
}
