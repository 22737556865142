import { getTopImage } from '../getTopImage';
import {
    BeforeTravelingPageDocument,
    BeforeTravelingPageQuery,
} from '../gql-types';
import { gqlClient } from '../gqlClient';

export async function loadBeforeTravelingPageContent(locale: string) {
    const { data } = await gqlClient.query<BeforeTravelingPageQuery>(
        BeforeTravelingPageDocument,
        { lang: locale },
    ).toPromise();
    return {
        topImage: getTopImage(data?.before_traveling_page?.top_image),
        content: data?.before_traveling_page?.translations?.[0]?.content ?? '',
    };
}
