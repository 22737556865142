import { useInjectReducer, useInjectSaga } from '../../../../utils/redux-injectors';
import {
    getEntityLogListSaga,
    getEntityTypeListSaga,
    getOperationTypeListSaga,
} from './saga';
import { actions, sliceKey, reducer } from './slice';

export const useInjectEventLog = () => {
    useInjectReducer({ actions, key: sliceKey, reducer });
    useInjectSaga({
        key: `${sliceKey}getEntityLogListSaga`,
        saga: getEntityLogListSaga,
    });
    useInjectSaga({
        key: `${sliceKey}getEntityTypeListSaga`,
        saga: getEntityTypeListSaga,
    });
    useInjectSaga({
        key: `${sliceKey}getOperationTypeListSaga`,
        saga: getOperationTypeListSaga,
    });
};
