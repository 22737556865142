import { PassengerInfo } from '../../../utils/reservation/types';
import { splitReservationNotifications } from './notifications';

export const validateSplitReservation = (
    passengers: PassengerInfo[],
    selectedPassengerId: number,
) => {
    const selectedPassenger = passengers.find(x => x.id === selectedPassengerId);
    if (!selectedPassenger) {
        return splitReservationNotifications.needSelectPassenger;
    }
    if (passengers.length === 1) {
        return splitReservationNotifications.cantSlitWithOnePassenger;
    }
    const selectedPassengers = [selectedPassenger];
    const notSelectedPassengers = passengers.filter(x => x.id !== selectedPassengerId);
    const isMoreInfants = (arr: PassengerInfo[]) => {
        const infants = arr.filter(x => x.ageType === 'infant').length;
        const adults = arr.filter(x => x.ageType === 'adult').length;
        return infants > adults;
    };
    if (isMoreInfants(selectedPassengers) || isMoreInfants(notSelectedPassengers)) {
        return splitReservationNotifications.cantSplitWithMoreInfants;
    }
    return undefined;
};
