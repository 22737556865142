import { ManagerPutRequest } from '@malesia/json-schema';
import { ManagerSelfEdit, ManagerSelfEditData } from '@malesia/react-components/dist/src/components/Manager/SelfEdit';
import { LegacyFormConfig } from '@malesia/react-components/dist/src/hooks/useForm';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getValidationFunction } from '../../../utils/validation/getValidationFunction';
import { useUser } from '../../hooks/useUser';
import { useCountrySelect } from '../BookingFlightPage/Steps/StepBillingInformation/useCountrySelect';
import { selectLoadingUserAccountPage } from './store/userAccount.selectors';
import { userAccountPageActions } from './store/userAccount.slice';

export const UserAccountManager = () => {
    const isLoading = useSelector(selectLoadingUserAccountPage);
    const { userInfo } = useUser();
    const dispatch = useDispatch();
    const { countryList, getCountryByCode } = useCountrySelect();

    const submit = useCallback((data: ManagerSelfEditData) => {
        const managerData: ManagerPutRequest & { passwordConfirm?: string } = {
            ...data,
            id: userInfo?.id,
            country: getCountryByCode(data.country),
        };
        if (managerData.password === '') delete managerData.password;
        if (managerData.passwordConfirm === '') delete managerData.passwordConfirm;
        dispatch(userAccountPageActions.submitManager(managerData));
    }, [dispatch, getCountryByCode, userInfo?.id]);

    const formConfig: LegacyFormConfig<ManagerSelfEditData> = useMemo(() => ({
        email: { initialValue: userInfo?.email ?? '', required: true },
        password: { initialValue: '' },
        passwordConfirm: { initialValue: '' },
        displayName: { initialValue: userInfo?.displayName ?? '', required: true },
        language: { initialValue: userInfo?.language ?? '', required: true },
        country: { initialValue: userInfo?.country?.code ?? '', required: true },
    }), [userInfo?.country?.code, userInfo?.displayName, userInfo?.email, userInfo?.language]);

    const validate = useMemo(() => getValidationFunction('file://malesiareisen.com/json/schema/project/react-front-app/pages/UserAccountPage/managerForm.json'),
        [],
    );

    return (
        <ManagerSelfEdit
            loading={isLoading}
            countryList={countryList}
            onSubmit={submit}
            formConfig={formConfig}
            validator={validate}
        />
    );
};
