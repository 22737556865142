import { PageTabs } from '@malesia/react-components/dist/src/components/Page/Tabs';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Link,
    Redirect,
    Route,
    RouteComponentProps,
    Switch,
} from 'react-router-dom';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { useTabsState } from '../../containers/Routes/tabsState/useTabsState';
import { useFilteredAdminRoutesByPermissions } from '../../permissions/useFilteredAdminRoutesByPermissions';
import { NotFoundPage } from '../NotFoundPage';
import { ReservationEditPage } from '../ReservationEditPage';
import { ReservationHistoryPage } from '../ReservationHistoryPage';
import { ReservationInvoiceListPage } from '../ReservationInvoiceListPage';
import { AdminReservationListPage } from '../ReservationListPage';
import { ReservationNewPage } from '../ReservationNewPage';
import { ReservationPaymentPage } from '../ReservationPaymentPage';

type Props = RouteComponentProps;

const ReservationRouterPage = (props: Props) => {

    const { pathname } = location;

    const isRouteMatch = linkRoute => pathname === linkRoute;

    const tabsRoutes = useFilteredAdminRoutesByPermissions([
        {
            routeKey: 'reservationList',
            linkRoute: allAdminRoutePaths.reservationList,
            LinkComponent: (props: React.PropsWithChildren<any>) => (
                <Link to={allAdminRoutePaths.reservationList}>
                    {React.Children.only(props.children)}
                </Link>
            ),
            linkSubject: (
                <FormattedMessage
                    id='front-app/Routes/RESERVATION_STEP_TRAVEL_DATES:Title'
                    defaultMessage='Reservations'
                />
            ),
        },
        {
            routeKey: 'invoiceOverview',
            linkRoute: allAdminRoutePaths.reservationInvoiceList,
            LinkComponent: (props: React.PropsWithChildren<any>) => (
                <Link to={allAdminRoutePaths.reservationInvoiceList}>
                    {React.Children.only(props.children)}
                </Link>
            ),
            linkSubject: (
                <FormattedMessage
                    id='front-app/Routes/invoiceOverview:Title'
                    defaultMessage='Invoice Overview'
                />
            ),
        },
        {
            routeKey: 'newReservation',
            linkRoute: allAdminRoutePaths.reservationNew,
            LinkComponent: (props: React.PropsWithChildren<any>) => (
                <Link to={allAdminRoutePaths.reservationNew}>
                    {React.Children.only(props.children)}
                </Link>
            ),
            linkSubject: (
                <FormattedMessage
                    id='front-app/Routes/RESERVATION_ADD:Title'
                    defaultMessage='New Reservation'
                />
            ),
        },
    ]);

    const { firstRoute } = useTabsState(allAdminRoutePaths.reservation, tabsRoutes);

    return (
        <>
            <PageTabs
                isRouteMatch={isRouteMatch}
                routeItems={tabsRoutes}
            >
                <Switch>
                    <Route
                        exact
                        path={allAdminRoutePaths.reservationList}
                        component={AdminReservationListPage}
                    />
                    <Route
                        exact
                        path={allAdminRoutePaths.reservationInvoiceList}
                        component={ReservationInvoiceListPage}
                    />
                    <Route
                        exact
                        path={allAdminRoutePaths.reservationNew}
                        component={ReservationNewPage}
                    />
                    <Route
                        exact
                        path={allAdminRoutePaths.reservationEdit}
                        component={ReservationEditPage}
                    />
                    <Route
                        exact
                        path={allAdminRoutePaths.reservationHistory}
                        component={ReservationHistoryPage}
                    />
                    <Route
                        exact
                        path={allAdminRoutePaths.reservationPayment}
                        component={ReservationPaymentPage}
                    />
                    <Route
                        exact
                        path={allAdminRoutePaths.reservation}
                    >
                        <Redirect to={firstRoute} />
                    </Route>
                    <Route component={NotFoundPage} />
                </Switch>
            </PageTabs>
        </>
    );
};

const Memoized = memo(ReservationRouterPage);
export {
    Memoized as ReservationRouterPage,
};
