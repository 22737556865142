import { AllotmentInvoiceStatusType } from '@malesia/json-schema';
import { AllotmentInvoiceFilterStatusType } from '@malesia/react-components/dist/src/components/Allotment/InvoiceList/Filter';
import { debounce, put, select, takeLatest, throttle } from 'typed-redux-saga';
import { logError } from '../../../../utils/log';
import { allotmentInvoicePopupActions } from '../../../containers/AllotmentInvoicePopup/slice';
import { allotmentInvoiceListPermissions } from '../../../permissions/adminPages/allotment/allotmentInvoiceList';
import { selectUserPermissions } from '../../../permissions/selectUserPermissions';
import { deleteApiAllotmentInvoice, getApiAgentAllotmentAgentBalance, getApiAllotmentAgentBalance, getApiPermittedAllotmentInvoiceList } from '../../../services/allotmentInvoice';
import { getApiErrorMessage } from '../../../services/ApiClient';
import { allotmentInvoiceListNotifications } from './notification';
import { selectDeleteInvoiceSelected, selectPagination, selectFilterData, selectSorting } from './selectors';
import { actions } from './slice';

export function* getAllotmentInvoiceList() {
    try {
        const pagination = yield* select(selectPagination);
        const sorting = yield* select(selectSorting);
        const searchValues = yield* select(selectFilterData);

        const apiStatusesMap: Record<AllotmentInvoiceFilterStatusType, AllotmentInvoiceStatusType[]> = {
            unpaid: ['pending', 'overDeadline'],
            overDeadline: ['overDeadline'],
            paid: ['paid', 'overpaid'],
        };
        const paymentStatus = searchValues.status
            ?.map(x => apiStatusesMap[x])
            .flat(1)
            .filter((x, i, arr) => arr.indexOf(x) === i);
        const result = yield* getApiPermittedAllotmentInvoiceList({
            ...pagination,
            ...sorting,
            agentId: searchValues.agent?.id,
            paymentStatus,
        });
        yield* put(actions.getAllotmentInvoiceListSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'AllotmentListPage.getAllotmentList',
        });
        yield* put(actions.getAllotmentInvoiceListError());
        const message = getApiErrorMessage(error);
        yield* put(allotmentInvoiceListNotifications.unknownError(message));
    }
}

export function* getAllotmentInvoiceListSaga() {
    yield* takeLatest(actions.getAllotmentInvoiceList, getAllotmentInvoiceList);

    function* requestList() {
        yield* put(actions.getAllotmentInvoiceList());
    }

    yield* throttle(1000, [
        actions.setPage,
        actions.setSorting,
        actions.confirmDeleteInvoicePopupSuccess,
        allotmentInvoicePopupActions.confirmPopupSuccess,
        actions.resetFilterData,
    ], requestList);

    yield* debounce(1000, [
        actions.setFilterData,
        actions.setPageSize,
    ], requestList);
}

function* confirmDeleteInvoicePopup(action: ReturnType<typeof actions.confirmDeleteInvoicePopup>) {
    try {
        const invoice = (yield* select(selectDeleteInvoiceSelected))!;

        yield* deleteApiAllotmentInvoice(invoice.id);
        yield* put(actions.confirmDeleteInvoicePopupSuccess());
        yield* put(allotmentInvoiceListNotifications.invoiceDeletedSuccessfully);
        yield* put(actions.getAgentBalance(invoice.agent.id));
    }
    catch (error) {
        logError({
            error,
            target: 'AllotmentListPage.confirmDeleteInvoicePopup',
        });
        yield* put(actions.confirmDeleteInvoicePopupError());
        const message = getApiErrorMessage(error);
        yield* put(allotmentInvoiceListNotifications.unknownError(message));
    }
}

export function* confirmDeleteInvoicePopupSaga() {
    yield* takeLatest(actions.confirmDeleteInvoicePopup, confirmDeleteInvoicePopup);
}

function* getAgentBalance(action: ReturnType<typeof actions.getAgentBalance>) {
    try {
        const userPermissions = yield* selectUserPermissions();

        if (userPermissions.has(allotmentInvoiceListPermissions.getList)) {
            const agentId = action.payload;
            if (!agentId) {
                yield* put(actions.getAgentBalanceSuccess(undefined));
                return;
            }
            const balance = yield* getApiAllotmentAgentBalance(agentId);
            yield* put(actions.getAgentBalanceSuccess(balance));
            return;
        }
        if (userPermissions.has(allotmentInvoiceListPermissions.getAgentList)) {
            const balance = yield* getApiAgentAllotmentAgentBalance();
            yield* put(actions.getAgentBalanceSuccess(balance));
            return;
        }
        throw new Error('Can not get allotment agent balance without permissions');
    }
    catch (error) {
        logError({
            error,
            target: 'AllotmentListPage.getAgentBalance',
        });
        yield* put(actions.getAgentBalanceFail());
        const message = getApiErrorMessage(error);
        yield* put(allotmentInvoiceListNotifications.unknownError(message));
    }
}

export function* getAgentBalanceSaga() {
    yield* takeLatest(actions.getAgentBalance, getAgentBalance);
}
