import { Flight } from '@malesia/json-schema';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.flightNewPage || initialState;

export const selectFlightData = createSelector(
    [selectDomain],
    (state): Flight => {
        const { numberPrefix, number, ...flightData } = state.flightData;

        return { ...flightData, number: `${numberPrefix} ${number}` } as Flight;
    },
);

export const selectFlightFormData = createSelector(
    [selectDomain],
    state => state.flightData,
);

export const selectAllotments = createSelector(
    [selectDomain],
    state => state.allotments,
);

export const selectRepeatingFormData = createSelector(
    [selectDomain],
    state => state.repeatingData,
);

export const selectCreationFlightData = createSelector(
    [selectDomain],
    state => state.creationFlightData,
);

export const selectLoading = createSelector(
    [selectDomain],
    state => state.loading,
);

export const selectIsLoadingCreationFlightData = createSelector(
    [selectDomain],
    state => state.isLoadingCreationFlightData,
);
export const selectShowSettingButton = createSelector(
    [selectDomain],
    (state) => !!(state.flightData?.aircraftTemplate?.sections?.length),
);

export const selectFlightAircraftTemplate = createSelector(
    [selectDomain],
    (state) => state.flightData?.aircraftTemplate,
);

export const selectIsAircraftSeatsConfigured = createSelector(
    [selectDomain],
    (state) => state.isAircraftSeatsConfigured,
);
