import { FlightPassengerListBulkActions, FlightPassengerListBulkActionsProps } from '@malesia/react-components/dist/src/components/Flight/Passenger/List/BulkActions';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flightPassengerListPermissions } from '../../permissions/adminPages/flight/flightPassengerList';
import { useUserPermissions } from '../../permissions/useUserPermissions';
import { selectFlight, selectSelectedRows } from './selectors';
import { actions } from './slice';

export const FlightPassengerBulkActions: SFC<object, { result: 'optional' }> = () => {
    const selectedRows = useSelector(selectSelectedRows);
    const flight = useSelector(selectFlight);
    const userPermissions = useUserPermissions();

    const flightId = flight?.id!;

    const dispatch = useDispatch();
    const updatePassengersAppeared = (isAppeared?: boolean) => {
        dispatch(
            actions.updateFlightPassengersAppearing({
                flightId,
                appearing: isAppeared === undefined ? null : { isAppeared },
            }),
        );
    };

    const flightPassengerListBulkActions: FlightPassengerListBulkActionsProps = {
        markAppeared: () => updatePassengersAppeared(true),
        markNotAppeared: () => updatePassengersAppeared(false),
        markPending: () => updatePassengersAppeared(undefined),
        disabled: !selectedRows.length,
    };

    if (!userPermissions.has(flightPassengerListPermissions.bulkActions)) return null;

    return (
        <FlightPassengerListBulkActions {...flightPassengerListBulkActions} />
    );
};
