import { Button } from '@malesia/react-components/dist/src/components/Button';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { flightPassengerListPermissions } from '../../../permissions/adminPages/flight/flightPassengerList';
import { useUserPermissions } from '../../../permissions/useUserPermissions';
import { selectFlight } from '../selectors';
import { actions } from '../slice';

export function UpdateFlightDelayed(): ReactElement | null {
    const flight = useSelector(selectFlight);
    const userPermissions = useUserPermissions();

    const dispatch = useDispatch();

    if (!flight) return null;
    if (flight.status === 'departed') return null;

    const renderDeparted = (): ReactElement | null => {
        if (!userPermissions.has(flightPassengerListPermissions.updateFlightDeparted)) return null;

        return (
            <Button
                onClick={() => {
                    dispatch(actions.setFlightDeparted({ flightId: flight.id! }));
                }}
                modificators={['ascetic']}
                text={
                    <FormattedMessage
                        id='front-app/FlightPassengerListPage:DepartedTitle'
                        defaultMessage='Departed'
                    />
                }
            />
        );
    };

    const renderDelayed = (): ReactElement | null => {
        if (!userPermissions.has(flightPassengerListPermissions.updateFlightDelayed)) return null;

        return (
            <Button
                onClick={() => {
                    dispatch(actions.setFlightDelayed({ flightId: flight.id! }));
                }}
                modificators={['ascetic']}
                text={
                    <FormattedMessage
                        id='front-app/FlightPassengerListPage:DelayedTitle'
                        defaultMessage='Delayed'
                    />
                }
            />
        );
    };

    return (
        flight.isDelayed ? renderDeparted() : renderDelayed()
    );
}
