import { CustomerPutRequest } from '@malesia/json-schema';
import { CustomerSelfEdit, CustomerSelfFormType } from '@malesia/react-components/dist/src/components/Customer/SelfEdit';
import { LegacyFormConfig } from '@malesia/react-components/dist/src/hooks/useForm';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getValidationFunction } from '../../../utils/validation/getValidationFunction';
import { selectPublicCountryList } from '../../containers/SharedData/selectors';
import { sharedActions } from '../../containers/SharedData/slice';
import { useUser } from '../../hooks/useUser';
import { userAccountPageActions } from './store/userAccount.slice';

export const UserAccountCustomer: SFC = () => {
    const { userInfo } = useUser();
    const dispatch = useDispatch();
    const countryList = useSelector(selectPublicCountryList)!;

    useEffect(() => {
        dispatch(sharedActions.getPublicCountryList());
    }, [dispatch]);

    const submit = useCallback((data: CustomerSelfFormType) => {
        const customerData: CustomerPutRequest & { passwordConfirm?: string } = {
            ...data,
            id: userInfo?.id,
            language: data.language!,
        };
        if (customerData.password === '') delete customerData.password;
        if (customerData.passwordConfirm === '') delete customerData.passwordConfirm;
        dispatch(userAccountPageActions.submitCustomer(customerData));
    }, [dispatch, userInfo?.id]);

    const formConfig: LegacyFormConfig<CustomerSelfFormType> = useMemo(() => ({
        id: { initialValue: userInfo?.id },
        email: { initialValue: userInfo?.email ?? '', modifiers: ['required'] },
        password: { initialValue: '' },
        passwordConfirm: { initialValue: '' },
        salutation: { initialValue: userInfo?.salutation, modifiers: ['required'] },
        company: { initialValue: userInfo?.company },
        firstName: { initialValue: userInfo?.firstName ?? '', modifiers: ['required'] },
        lastName: { initialValue: userInfo?.lastName ?? '', modifiers: ['required'] },
        street: { initialValue: userInfo?.street, modifiers: ['required'] },
        zip: { initialValue: userInfo?.zip, modifiers: ['required'] },
        city: { initialValue: userInfo?.city, modifiers: ['required'] },
        country: { initialValue: userInfo?.country, modifiers: ['required'] },
        language: { initialValue: userInfo?.language, modifiers: ['required'] },
        birthday: { initialValue: userInfo?.birthday ?? '', modifiers: ['required'] },
        phoneMobile: { initialValue: userInfo?.phoneMobile, modifiers: ['required'] },
        phoneNumberFirst: { initialValue: userInfo?.phoneNumberFirst },
        phoneNumberSecond: { initialValue: userInfo?.phoneNumberSecond },
    }), [userInfo]);

    const validate = useMemo(() => (
        getValidationFunction('file://malesiareisen.com/json/schema/project/react-front-app/pages/UserAccountPage/customerForm.json')
    ), []);

    return (
        <CustomerSelfEdit
            onSubmit={submit}
            countryList={countryList.items}
            formConfig={formConfig}
            validator={validate}
        />
    );
};
