import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { CustomerListFooterWithStore } from './components/CustomerListFooterWithStore';
import { CustomerListHeaderWithStore } from './components/CustomerListHeaderWithStore';
import { CustomerListWithStore } from './components/CustomerListWithStore';
import { getCustomerListSaga } from './saga';
import { reducer, sliceKey, actions } from './slice';

export const CustomerListPage: SFC = () => {
    useInjectReducer({ actions, key: sliceKey, reducer });
    useInjectSaga({ key: sliceKey, saga: getCustomerListSaga });

    return (
        <PageContentBlock>
            <CustomerListHeaderWithStore />
            <CustomerListWithStore />
            <CustomerListFooterWithStore />
        </PageContentBlock>
    );
};
