import { User } from '@malesia/json-schema';
import { RoleCode } from './roleCode';

export const calculateUserRoles = (user: User): RoleCode[] => {
    const unique = (array: string[]) => array.filter((x, index) => (
        array.indexOf(x) === index
    ));
    const add = (a: string[], b: string[]) => (
        a.concat(b)
    );
    const subtract = (a: string[], b: string[]) => b.length === 0 ? a : a.filter(x => (
        !b.includes(x)
    ));
    const userGroupRoles = unique(user.group?.roles ?? []);
    const allowedRoles = unique(user.allowedSpecialRoles ?? []);
    const deniedRoles = unique(user.deniedSpecialRoles ?? []);

    const groupAndAllowedUnion = unique(add(userGroupRoles, allowedRoles));
    const result = subtract(groupAndAllowedUnion, deniedRoles);
    return result as RoleCode[];
};
