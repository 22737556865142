import { BackLink } from '@malesia/react-components/dist/src/components/BackLink';
import { CustomerEdit, CustomerFormType } from '@malesia/react-components/dist/src/components/Customer/Edit';
import { LoadingPage } from '@malesia/react-components/dist/src/components/LoadingOverlay/Page';
import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { filterDisabledData } from '@malesia/react-components/dist/src/hooks/useForm';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { createCustomPasswordConfirmValidator } from '../../../utils/validation/createCustomPasswordConfirmValidator';
import { getValidationFunction } from '../../../utils/validation/getValidationFunction';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { useInjectSharedData } from '../../containers/SharedData/inject';
import { selectAdminCountryList, selectAdminCountryLoading } from '../../containers/SharedData/selectors';
import { sharedActions } from '../../containers/SharedData/slice';
import { useCustomerFormConfig } from './config';
import { createCustomerSaga } from './saga';
import { selectLoading } from './selectors';
import { reducer, sliceKey, actions } from './slice';

export const CustomerNewPage: SFC = () => {
    useInjectSharedData();
    useInjectReducer({ actions, key: sliceKey, reducer });
    useInjectSaga({
        key: `${sliceKey}CreateCustomer`,
        saga: createCustomerSaga,
    });

    const countryData = useSelector(selectAdminCountryList);
    const loading = useSelector(selectLoading);
    const loadingCountries = useSelector(selectAdminCountryLoading);
    const isAnythingLoading = loading || loadingCountries;
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(sharedActions.getAdminCountryList());
    }, [dispatch]);

    const formConfig = useCustomerFormConfig();

    const onSubmitForm = useCallback((data: CustomerFormType) => {
        const customerData = filterDisabledData(data, formConfig);
        dispatch(actions.createCustomer(customerData));
    }, [formConfig, dispatch]);

    const validationFn = useMemo(() => (
        getValidationFunction(
            'file://malesiareisen.com/json/schema/project/react-front-app/pages/CustomerNewPage/customerNewForm.json',
            [
                createCustomPasswordConfirmValidator<CustomerFormType>('/password', '/passwordConfirm'),
            ],
        )
    ), []);

    return (
        <PageContentBlock>
            <PageTitle
                text={
                    <FormattedMessage
                        id='front-app/CustomerNewPage:Title'
                        defaultMessage='New customer'
                    />
                }
            />
            <BackLink
                to={allAdminRoutePaths.userCustomerList}
                modificators='stick-to-title'
            />
            <LoadingPage isLoading={isAnythingLoading} />
            {!isAnythingLoading && (
                <CustomerEdit
                    countryList={countryData.items}
                    formConfig={formConfig}
                    validator={validationFn}
                    onSubmit={onSubmitForm}
                />
            )}
        </PageContentBlock>
    );
};
