import { Reservation } from '@malesia/json-schema';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../../utils/@reduxjs/toolkit';

export type DatatransErrorPageState = {
    reservation?: Reservation,
};

export const initialState: DatatransErrorPageState = {};

const datatransErrorSlice = createSlice({
    name: 'datatransErrorPage',
    initialState,
    reducers: {
        loadInitialData() {
        },
        setReservation(state, action: PayloadAction<Reservation>) {
            state.reservation = action.payload;
        },
        openPaymentPopup() {
        },
        updateTransactionStatus() {
        },
    },
});

export const {
    actions: datatransErrorPageActions,
    reducer: datatransErrorPageReducer,
    name: datatransErrorPageSliceName,
} = datatransErrorSlice;
