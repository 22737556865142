import { FrontPageContentBlock } from '@malesia/react-components/dist/src/components/Front/Page/ContentBlock';
import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useUserPermissions } from '../../permissions/useUserPermissions';
import { useUserAccountPageStore } from './store/useUserAccountPageStore';
import { UserAccountAgent } from './UserAccountAgent';
import { UserAccountCustomer } from './UserAccountCustomer';
import { UserAccountManager } from './UserAccountManager';

const UserAccountPage: SFC = () => {
    useUserAccountPageStore();
    const userPermissions = useUserPermissions();
    const getComponent = () => {
        if (!userPermissions.userType) {
            return (
                <FormattedMessage
                    id='front-app/UserAccountPage:NoProfileToEdit'
                    defaultMessage='There is not user profile to edit'
                />
            );
        }
        const map = {
            manager: <UserAccountManager />,
            agent: <UserAccountAgent />,
            customer: <UserAccountCustomer />,
        };
        const component = map[userPermissions.userType];
        if (component) return component;
        return (
            <FormattedMessage
                id='front-app/UserAccountPage:NotAllowedProfileToEdit'
                defaultMessage='The profile can not be edit'
            />
        );
    };

    return (
        <>
            <PageTitle
                text={
                    <FormattedMessage
                        id='front-app/UserAccountPage:title'
                        defaultMessage='My Account'
                    />
                }
            />
            {getComponent()}
        </>
    );
};

export const AdminUserAccountPage: SFC = () => {
    return (
        <PageContentBlock>
            <UserAccountPage />
        </PageContentBlock>
    );
};

export const PublicUserAccountPage: SFC = () => {
    return (
        <FrontPageContentBlock modificators={['booking']}>
            <UserAccountPage />
        </FrontPageContentBlock>
    );
};
