import React from 'react';
import { FormattedMessage } from 'react-intl';
import { appNotification } from '../../../containers/App/appNotification';
import { createLocalNotification } from '../../../containers/App/utils';

const notification = createLocalNotification('ReservationPayment', {
    error: (
        <FormattedMessage
            id='front-app/Payment/Notification/Error:title'
            defaultMessage='Payment Error'
        />
    ),
    warning: (
        <FormattedMessage
            id='front-app/Payment/Notification/Warning:title'
            defaultMessage='Payment Warning'
        />
    ),
    info: (
        <FormattedMessage
            id='front-app/Payment/Notification/Info:title'
            defaultMessage='Payment Info'
        />
    ),
    success: (
        <FormattedMessage
            id='front-app/Payment/Notification/Info:title'
            defaultMessage='Payment Info'
        />
    ),
});

export const paymentNotificationMessages = {
    paymentSuccess: (
        <FormattedMessage
            id='front-app/notificationMessages/payment:success'
            defaultMessage='Payment successfully'
        />
    ),
    issueWithPayNow: (
        <FormattedMessage
            id='front-app/notificationMessages/payment:issue'
            defaultMessage='There was an issue with your payment. Please try again.'
        />
    ),
    notLoadedPaymentAccounts: (
        <FormattedMessage
            id='front-app/notificationMessages/payment:notLoadedPaymentAccounts'
            defaultMessage='not loaded payment accounts'
        />
    ),
    bigPartialAmount: (
        <FormattedMessage
            id='front-app/notificationMessages/payment:bigPartialAmount'
            defaultMessage='partial amount should be less than price to pay'
        />
    ),
    notSelectedBankAccount: (
        <FormattedMessage
            id='front-app/notificationMessages/payment:notSelectedBankAccount'
            defaultMessage='not selected ec account'
        />
    ),
    notSelectedCashAccount: (
        <FormattedMessage
            id='front-app/notificationMessages/payment:notSelectedCashAccount'
            defaultMessage='not selected cash account'
        />
    ),
    notSelectedTransferAccount: (
        <FormattedMessage
            id='front-app/notificationMessages/payment:notSelectedTransferAccount'
            defaultMessage='not selected transfer account'
        />
    ),
    notEnoughAmountOnAccount: (
        <FormattedMessage
            id='front-app/notificationMessages/payment:notEnoughAmountOnAccount'
            defaultMessage='Not enough money on account.'
        />
    ),
    unexpectedPaymentMethod: (
        <FormattedMessage
            id='front-app/notificationMessages/payment:unexpectedPaymentMethod'
            defaultMessage='Unexpected payment method.'
        />
    ),
};

export const paymentNotifications = {
    paymentSuccess: notification.success(paymentNotificationMessages.paymentSuccess),
    issueWithPayNow: notification.error(paymentNotificationMessages.issueWithPayNow),
    notLoadedPaymentAccounts: notification.error(paymentNotificationMessages.notLoadedPaymentAccounts),
    notFindReservation: (id: string | number) => notification.error(
        <FormattedMessage
            id='front-app/notificationMessages/payment:notFindReservation'
            defaultMessage='not find reservation {id}'
            values={{ id }}
        />,
    ),
    bigPartialAmount: notification.error(paymentNotificationMessages.bigPartialAmount),
    notSelectedBankAccount: notification.warning(paymentNotificationMessages.notSelectedBankAccount),
    notSelectedCashAccount: notification.warning(paymentNotificationMessages.notSelectedCashAccount),
    notSelectedTransferAccount: notification.warning(paymentNotificationMessages.notSelectedTransferAccount),
    notEnoughAmountOnAccount: notification.warning(paymentNotificationMessages.notEnoughAmountOnAccount),
    unexpectedPaymentMethod: notification.error(paymentNotificationMessages.unexpectedPaymentMethod),
    datatransSuccess: notification.success(
        <FormattedMessage
            id='front-app/datatransSuccess:thankYou'
            defaultMessage='thank you for payment'
        />,
    ),
    notCompleted: notification.warning(
        <FormattedMessage
            id='front-app/notificationMessages/payment:notCompleted'
            defaultMessage='Warning: The payment was not completed. Please pay within 15 minutes..'
        />,
    ),
    notConfirmedTransaction: notification.error(
        <FormattedMessage
            id='front-app/notificationMessages/payment:notConfirmedTransaction'
            defaultMessage='transaction is not confirmed'
        />,
    ),
    notInitializedTransaction: notification.error(
        <FormattedMessage
            id='front-app/notificationMessages/payment:notInitializedTransaction'
            defaultMessage='not initialized transaction'
        />,
    ),
    notFoundTransaction: notification.error(
        <FormattedMessage
            id='front-app/datatransErrorPage:notFoundId'
            defaultMessage='no such transaction exists, the reservation has probably expired'
        />,
    ),
    expired: notification.warning(
        <FormattedMessage
            id='front-app/datatransSuccess:expired'
            defaultMessage='Reservation expired.'
        />,
    ),
    onCancel: (durationInMinutes: string = '15') => notification.warning(
        <FormattedMessage
            id='front-app/datatransSuccess:onCancel'
            defaultMessage='The payment was not completed. Please pay within {durationInMinutes} minutes.'
            values={{ durationInMinutes }}
        />,
    ),
    datatransError: (message?: string) => notification.error(message ?? (
        <FormattedMessage
            id='front-app/datatransErrorPage:datatransError'
            defaultMessage='Your payment could not be processed. Please try again.'
        />
    )),
    unknownError: (details?: JSX.Element | string) => (
        notification.error(appNotification.texts.unknownError(details))
    ),
};
