import { call, select, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../utils/log';
import { datatransService } from '../../services/Datatrance/datatrans.service';
import { getCurrentRouteName } from '../Routes/getRouteNameByPath';
import { selectUserLoginId } from '../UserLogin/selectors';
import { appActions } from './slice';

function* chargeAccount(action: ReturnType<typeof appActions.chargeAccount>) {
    try {
        const amount = action.payload;
        const agentId = yield* select(selectUserLoginId);
        const routForReturn = getCurrentRouteName();

        const { transactionId } = yield* call(datatransService.paymentDatatransAgentInit, {
            amount,
            agentId,
            source: 'agentAccount',
            additionalProp: routForReturn,
        });
        yield* call(datatransService.showDatatransPopup, transactionId);

    }
    catch (error) {
        logError({
            error,
            target: 'App.chargeAccount',
        });
    }
}

export function* chargeAccountSaga() {
    yield* takeLatest(appActions.chargeAccount, chargeAccount);
}
