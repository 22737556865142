import { Date, Time } from '@malesia/react-components/dist/src/components/DateTime';
import { MultiLanguage } from '@malesia/react-components/dist/src/components/MultiLanguage';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectFlight } from '../selectors';

export function PassengerListTitle(): ReactElement | null {
    const flight = useSelector(selectFlight);

    return (
        <PageTitle
            text={
                <>
                    <FormattedMessage
                        id='front-app/FlightPassengerListPage:Title'
                        defaultMessage='Passenger List'
                    />
                    {' '}
                    {flight && (
                        <>
                            {flight.number},{' '}
                            {flight.departure && (
                                <Date
                                    value={flight.departure}
                                    isLocalTime={true}
                                />
                            )}{' '}
                            {flight.departure && (
                                <Time
                                    value={flight.departure}
                                    isLocalTime={true}
                                />
                            )},{' '}
                            {flight.origin!.name && (
                                <MultiLanguage
                                    value={flight.origin!.name}
                                />
                            )}{' '}
                            -{' '}
                            {flight.destination!.name && (
                                <MultiLanguage
                                    value={flight.destination!.name}
                                />
                            )}
                        </>
                    )}
                </>
            }
        />
    );
}
