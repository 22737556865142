import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../utils/log';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { rateNotifications } from '../RateNewPage/notification';
import { actions } from './slice';
import { apiClientRequest, getApiErrorMessage } from 'app/services/ApiClient';

export function* getTariffList() {
    try {
        const result = yield* call(apiClientRequest, {
            requestId: 'tariffEntireList',
        });
        yield* put(actions.getTariffListSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'RateEditPage.getTariffList',
        });
        yield* put(actions.getTariffListError());
        const message = getApiErrorMessage(error);
        yield* put(rateNotifications.unknownError(message));
    }
}

export function* getTariffListSaga() {
    yield* takeLatest(actions.getTariffList, getTariffList);
}

export function* getOptionList() {
    try {
        const result = yield* call(apiClientRequest, {
            requestId: 'optionEntireList',
        });
        yield* put(actions.getOptionListSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'RateEditPage.getOptionList',
        });
        yield* put(actions.getOptionListError());
        const message = getApiErrorMessage(error);
        yield* put(rateNotifications.unknownError(message));
    }
}

export function* getOptionListSaga() {
    yield* takeLatest(actions.getOptionList, getOptionList);
}

export function* getRateData(action: ReturnType<typeof actions.getRateData>) {
    try {
        const rateId = action.payload;

        const result = yield* call(apiClientRequest, {
            requestId: 'rateData',
            uriParams: { id: rateId },
        });
        yield* put(actions.getRateDataSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'RateEditPage.getRateData',
        });
        yield* put(actions.getRateDataError());
        const message = getApiErrorMessage(error);
        yield* put(rateNotifications.unknownError(message));
    }
}

export function* getRateDataSaga() {
    yield* takeLatest(actions.getRateData, getRateData);
}

export function* updateRateData(action: ReturnType<typeof actions.updateRateData>) {
    try {
        const { rateData, rateId } = action.payload;

        yield* call(apiClientRequest, {
            requestId: 'rateUpdate',
            uriParams: { id: rateId },
            requestPayload: rateData,
        });
        yield* put(actions.updateRateDataSuccess());
        yield* put(push(allAdminRoutePaths.rateList));
        yield* put(rateNotifications.updatedSuccessfully);
    }
    catch (error) {
        logError({
            error,
            target: 'RateEditPage.updateRateData',
        });
        yield* put(actions.updateRateDataError());
        const message = getApiErrorMessage(error);
        yield* put(rateNotifications.unknownError(message));
    }
}

export function* updateRateDataSaga() {
    yield* takeLatest(actions.updateRateData, updateRateData);
}
