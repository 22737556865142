import { AircraftTemplateGenericPaginatedList, AirportEntireList, FlightCompanyEntireList, RateEntireList, TariffGroupEntireList } from '@malesia/json-schema';
import { all } from 'typed-redux-saga';
import { apiClientRequest } from '../../../services/ApiClient';

export type CreationFlightData = {
    airportList: AirportEntireList,
    aircraftTemplateList: AircraftTemplateGenericPaginatedList,
    companyList: FlightCompanyEntireList,
    rateList: RateEntireList,
    tariffGroupList: TariffGroupEntireList,
};
export function* getApiCreationFlightData() {
    const result: CreationFlightData = yield* all({
        airportList: apiClientRequest({ requestId: 'airportEntireList' }),
        aircraftTemplateList: apiClientRequest({ requestId: 'aircraftTemplateList' }),
        companyList: apiClientRequest({ requestId: 'flightCompanyEntireList' }),
        rateList: apiClientRequest({ requestId: 'rateEntireList' }),
        tariffGroupList: apiClientRequest({ requestId: 'tariffGroupEntireList' }),
    });
    result.rateList.items = result.rateList.items.sort((x, y) => x.name.localeCompare(y.name));
    return result;
}
