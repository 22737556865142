import { FreePaymentAdvancedPostRequest } from '@malesia/json-schema';
import { call } from 'typed-redux-saga';
import { apiClientRequest } from '../ApiClient';

export type PaymentFreeTypeListItem = {
    id: number,
    code: string,
    name: string,
};
function* getPaymentFreeTypeList() {
    const requestData = {
        requestId: 'paymentFreeTypeList',
    };
    const response: { items: PaymentFreeTypeListItem[] } = yield* call(apiClientRequest, requestData);
    return response;
}

function* postPaymentFreeAdvanced(data: FreePaymentAdvancedPostRequest) {
    const requestData = {
        requestId: 'paymentFreeAdvancedCreate',
        requestPayload: data,
    };
    type PaymentResult = { id: string };
    const response: PaymentResult = yield* call(apiClientRequest, requestData);
    return response;
}

function* postPaymentFreeBasic(data: FreePaymentAdvancedPostRequest) {
    const requestData = {
        requestId: 'paymentFreeBasicCreate',
        requestPayload: data,
    };
    const response = yield* call(apiClientRequest, requestData);
    return response;
}

export const paymentFreeService = {
    getPaymentFreeTypeList,
    postPaymentFreeAdvanced,
    postPaymentFreeBasic,
};
