import { UserGroup } from '@malesia/json-schema';
import keyBy from 'lodash/keyBy';
import { all, call, put, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../../../utils/log';
import { userPermissionsPermissions } from '../../../../permissions/adminPages/settings/userPermissions';
import { calculateUserRoles } from '../../../../permissions/calculateUserRoles';
import { selectUserPermissions } from '../../../../permissions/selectUserPermissions';
import { UserPermissions } from '../../../../permissions/userPermissions';
import { getApiErrorMessage } from '../../../../services/ApiClient';
import {
    getApiUser,
    getApiUserGroupList,
    getApiUserRoleList,
    getApiUserVirtualAccounts,
} from '../../../../services/user';
import { getApiVirtualAccounts } from '../../../../services/VirtualAccount/virtualAccount.service';
import { userPermissionsNotifications } from '../../notification';
import { permissionsPageActions } from '../slice';

function* onLoad(action: ReturnType<typeof permissionsPageActions.onLoad>) {
    try {
        const userId = action.payload;
        const userPermissions: UserPermissions = yield* selectUserPermissions();
        const can = {
            readUserRoles: userPermissions.has(userPermissionsPermissions.readUserRoles),
            readUserVirtualAccounts: userPermissions.has(userPermissionsPermissions.readUserVirtualAccounts),
        };

        const result = yield* all({
            groups: can.readUserRoles ? call(getApiUserGroupList) : call(() => []),
            roles: can.readUserRoles ? call(getApiUserRoleList) : call(() => []),
            user: call(getApiUser, userId),
            userVirtualAccounts: can.readUserVirtualAccounts ? call(getApiUserVirtualAccounts, userId) : call(() => []),
            virtualAccounts: can.readUserVirtualAccounts ? call(getApiVirtualAccounts) : call(() => []),
        });
        const groupsMap = keyBy(result.groups, 'id') as Record<number, UserGroup>;
        const sortedRoles = result.roles.sort((x, y) => x.localeCompare(y));
        const userGroupId = can.readUserRoles ? result.user?.group?.id : undefined;
        yield* put(permissionsPageActions.setUserGroups(groupsMap));
        yield* put(permissionsPageActions.setAvailableRoles(sortedRoles));
        yield* put(permissionsPageActions.setCurrentUser(result.user));
        yield* put(permissionsPageActions.setActiveUserGroupId(userGroupId));
        yield* put(permissionsPageActions.setCurrentRoles(calculateUserRoles(result.user)));
        yield* put(permissionsPageActions.setVirtualAccounts(result.virtualAccounts));
        yield* put(permissionsPageActions.setCurrentVirtualAccounts(result.userVirtualAccounts.map(uva => uva.id)));
        yield* put(permissionsPageActions.onLoadSuccess());
    }
    catch (error) {
        logError({
            error,
            target: 'SettingsUserPermissionsPage.onLoad',
        });
        yield* put(permissionsPageActions.onLoadError());
        const message = getApiErrorMessage(error);
        yield* put(userPermissionsNotifications.unknownError(message));
    }
}

export function* onLoadSaga() {
    yield* takeLatest(permissionsPageActions.onLoad, onLoad);
}
