import { permissionMaker } from '../../permissionExpression';

const { role } = permissionMaker;

const createFlightSequence = role('ROLE_FLIGHT_CREATE_SEQUENCE');
const editAllotment = role('ROLE_ALLOTMENT_EDIT');
const readAllotment = role('ROLE_ALLOTMENT_READ');
const readAllotmentCost = role('ROLE_ALLOTMENT_READ_COST_PER_SEAT');
const readCostPerSeat = role('ROLE_FLIGHT_READ_COST_PER_SEAT');
const createCostPerSeat = role('ROLE_FLIGHT_CREATE_COST_PER_SEAT');
const updateCostPerSeat = role('ROLE_FLIGHT_UPDATE_COST_PER_SEAT');
const readSeatNumber = role('ROLE_FLIGHT_READ_SEAT_NUMBER');
const createSeatNumber = role('ROLE_FLIGHT_CREATE_SEAT_NUMBER');
const updateSeatNumber = role('ROLE_FLIGHT_UPDATE_SEAT_NUMBER');

export const flightNewPermissions = {
    createFlightSequence,
    editAllotment,
    readAllotment,
    readAllotmentCost,
    readCostPerSeat,
    createCostPerSeat,
    updateCostPerSeat,
    readSeatNumber,
    createSeatNumber,
    updateSeatNumber,
};
