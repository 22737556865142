import { ManagerFormType } from '@malesia/react-components/dist/src/components/Manager/Edit';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { ManagerNewPageState } from './types';

export const initialState: ManagerNewPageState = {
    loading: false,
};

const managerNewPageSlice = createSlice({
    name: 'managerNewPage',
    initialState,
    reducers: {
        createManager(state, action: PayloadAction<ManagerFormType>) {
            state.loading = true;
        },
        createManagerSuccess(state) {
            state.loading = false;
        },
        createManagerError(state) {
            state.loading = false;
        },
    },
});

export const { actions, reducer, name: sliceKey } = managerNewPageSlice;
