export function phoneMinLengthValidator(value: string, minLength: number) {
    const length = (value.match(/\d/g) || []).length;

    return length >= minLength;
}

export function phoneMaxLengthValidator(value: string, maxLength: number) {
    const length = (value.match(/\d/g) || []).length;

    return length <= maxLength;
}
