import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../utils/log';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { apiClientRequest, getApiErrorMessage } from '../../services/ApiClient';
import { mapCustomerToPostRequest } from '../../services/customer';
import { customerNotifications } from './notification';
import { actions } from './slice';

export function* createCustomer(action: ReturnType<typeof actions.createCustomer>) {
    try {
        const requestPayload = mapCustomerToPostRequest(action.payload);

        yield* call(apiClientRequest, {
            requestId: 'customerCreate',
            requestPayload,
        });
        yield* put(actions.createCustomerSuccess());
        yield* put(push(allAdminRoutePaths.userCustomerList));
        yield* put(customerNotifications.createdSuccessfully);
    }
    catch (error) {
        logError({
            error,
            target: 'CustomerNewPage.createCustomer',
        });
        yield* put(actions.createCustomerError());
        const message = getApiErrorMessage(error);
        yield* put(customerNotifications.unknownError(message));
    }
}

export function* createCustomerSaga() {
    yield* takeLatest(actions.createCustomer, createCustomer);
}
