import { getQueryParameters } from '../../../utils/uriUtils';

export type AllotmentInvoiceListQueryParameters = {
    highlightAllotmentInvoiceId?: number,
};

export const useQueryParameters = () => {
    const {
        highlightAllotmentInvoiceId,
    } = getQueryParameters<AllotmentInvoiceListQueryParameters>({
        highlightAllotmentInvoiceId: 'number',
    });

    return {
        highlightAllotmentInvoiceId,
    };
};
