import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.agentEditPage || initialState;

export const selectAgentData = createSelector(
    [selectDomain],
    state => state.agentData,
);

export const selectLoadingAgent = createSelector(
    [selectDomain],
    state => state.loadingAgent,
);
