import { FrontPageLayout } from '@malesia/react-components/dist/src/components/Front/Page/Layout';
import { LoadingPage } from '@malesia/react-components/dist/src/components/LoadingOverlay/Page';
import { AdminPageLayout } from '@malesia/react-components/dist/src/components/Page/Layout';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useUser } from '../../hooks/useUser';
import { LoginModal } from '../../modals/LoginModal';
import { loginModalActions } from '../../modals/LoginModal/slice';
import { RecoverPasswordModal } from '../../modals/RecoverPasswordModal';
import { useFilteredAdminRoutesByPermissions } from '../../permissions/useFilteredAdminRoutesByPermissions';
import { useIsAdminSite } from '../../permissions/useIsAdminSite';
import { useUserPermissions } from '../../permissions/useUserPermissions';
import { appAdminRoutes, tabRoutesAdminSiteMap } from '../Routes/appAdminRoutes';
import { appPublicRoutes, tabRoutesPublicSiteMap } from '../Routes/appPublicRoutes';
import { canOpenPublicRoute } from '../UserLogin/OnlyPublicRoute';
import { selectIsLoadingUserInfo, selectUserLoginAccount } from '../UserLogin/selectors';
import { loginActions } from '../UserLogin/slice';
import { AppMetaHelmet } from './AppMetaHelmet';
import { AppRoutes } from './AppRoutes';
import { AppWrapper } from './AppWrapper';
import { useInjectApp } from './inject';
import { selectLocale } from './selectors';
import { appActions } from './slice';

export const App: SFC = () => {
    useInjectApp();
    const { userIsLoggedIn, userName } = useUser();
    const userPermissions = useUserPermissions();
    const isAdminSite = useIsAdminSite();
    const isLoadingUserData = useSelector(selectIsLoadingUserInfo);
    const userAccount = useSelector(selectUserLoginAccount);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loginActions.refreshUserData());
    }, [dispatch]);

    const filteredAppPublicRoutes = appPublicRoutes.filter(x => (
        canOpenPublicRoute(x.linkRoute, userIsLoggedIn)
    ));
    const filteredAppAdminRoutes = useFilteredAdminRoutesByPermissions(appAdminRoutes);
    // Get routes mapping
    const routeItems = isAdminSite
        ? filteredAppAdminRoutes
        : filteredAppPublicRoutes;
    const tabRoutesMap = useMemo(() => (
        isAdminSite ? tabRoutesAdminSiteMap : tabRoutesPublicSiteMap
    ), [isAdminSite]);

    // Configure routes matching callback
    const location = useLocation();
    const isRouteMatch = useCallback(linkRoute => {
        return (
            linkRoute === location.pathname.slice(0, linkRoute.length)
            || linkRoute === tabRoutesMap[location.pathname]
        );
    }, [location.pathname, tabRoutesMap]);

    const locale = useSelector(selectLocale);

    const handleLogin = () => {
        dispatch(loginModalActions.openModal());
    };
    const handleLogout = () => {
        dispatch(loginActions.logout());
    };

    const changeLocale = (language: string) => {
        dispatch(appActions.setLocale(language));
    };

    const onCharge = useCallback((v: number) => {
        dispatch(appActions.chargeAccount(v));
    }, [dispatch]);

    return (
        <AppWrapper>
            <AppMetaHelmet />
            <LoadingPage
                isLoading={isLoadingUserData}
                loadingMessage=''
            />
            {!isAdminSite && !isLoadingUserData && (
                <FrontPageLayout
                    isRouteMatch={isRouteMatch}
                    routeItems={routeItems}
                    onLanguageChange={changeLocale}
                    selectedLanguage={locale}
                    handleLogin={handleLogin}
                    handleLogout={handleLogout}
                    userIsLoggedIn={userIsLoggedIn}
                >
                    <AppRoutes />
                </FrontPageLayout>
            )}
            {isAdminSite && !isLoadingUserData && (
                <AdminPageLayout
                    isRouteMatch={isRouteMatch}
                    routeItems={routeItems}
                    onLanguageChange={changeLocale}
                    selectedLanguage={locale}
                    handleLogout={handleLogout}
                    userName={userName}
                    userBalance={userAccount?.balance ?? 0}
                    navDisabled={false}
                    showCharge={userPermissions.userType === 'agent'}
                    onConfirmCharge={onCharge}
                >
                    <AppRoutes />
                </AdminPageLayout>
            )}
            {!userIsLoggedIn && (
                <>
                    <LoginModal />
                    <RecoverPasswordModal />
                </>
            )}
        </AppWrapper>
    );
};
