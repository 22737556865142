import { UserProfile } from '@malesia/json-schema';
import { AdminBillingFormData } from '@malesia/react-components/dist/src/components/Reservation/BillingInformation/AdminBillingInformation';
import { LegacyFormConfig } from '@malesia/react-components/dist/src/hooks/useForm';
import { mapObject } from '@malesia/react-components/dist/src/utils/object';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedReservationOwner } from '../../../../containers/AuxiliaryData/selectors';
import { selectUserLoginUserInfo } from '../../../../containers/UserLogin/selectors';
import { UserInfo } from '../../../../containers/UserLogin/types';
import { reservationNewPermissions } from '../../../../permissions/adminPages/reservation/reservationNew';
import { UserPermissions } from '../../../../permissions/userPermissions';
import { useUserPermissions } from '../../../../permissions/useUserPermissions';
import { selectBookedBillingInformation, selectMode } from '../../store/selectors';

type Config = LegacyFormConfig<AdminBillingFormData>;
const getDefaultConfig = (): Config => ({
    company: { initialValue: '' },
    salutation: { initialValue: '', required: true },
    lastName: { initialValue: '', required: true },
    firstName: { initialValue: '', required: true },
    email: { initialValue: '', required: true },
    street: { initialValue: '', required: true },
    zip: { initialValue: '', required: true },
    city: { initialValue: '', required: true },
    country: { initialValue: '', required: true },
    birthday: { initialValue: '', required: true },
    phoneNumberMobile: { initialValue: '', required: true },
    phoneNumberFirst: { initialValue: '' },
    phoneNumberSecond: { initialValue: '' },
    isBankInformation: { initialValue: false },
    isBookingInformation: { initialValue: false },
    isPostInformation: { initialValue: false },
    isCreateAccount: { initialValue: false },
    language: { initialValue: '', required: true },
});

const addOwnerToInitialValue = (
    owner: Partial<UserProfile>,
    formConfig: Config,
): Config => {
    if (owner.salutation) formConfig.salutation.initialValue = owner.salutation;
    if (owner.firstName) formConfig.firstName.initialValue = owner.firstName;
    if (owner.lastName) formConfig.lastName.initialValue = owner.lastName;
    if (owner.birthday) formConfig.birthday.initialValue = owner.birthday;
    if (owner.street) formConfig.street.initialValue = owner.street;
    if (owner.zip) formConfig.zip.initialValue = owner.zip;
    if (owner.city) formConfig.city.initialValue = owner.city;
    if (owner.country) formConfig.country.initialValue = owner.country.code;
    if (owner.company) formConfig.company.initialValue = owner.company;
    if (owner.email) formConfig.email.initialValue = owner.email;
    if (owner.language) formConfig.language.initialValue = owner.language;
    if (owner.phoneMobile) formConfig.phoneNumberMobile.initialValue = owner.phoneMobile;
    if (owner.phoneNumberSecond) formConfig.phoneNumberSecond.initialValue = owner.phoneNumberSecond;
    if (owner.phoneNumberFirst) formConfig.phoneNumberFirst.initialValue = owner.phoneNumberFirst;
    return formConfig;
};

const addUserInfoToInitialValue = (
    userInfo: UserInfo,
    formConfig: Config,
): LegacyFormConfig<AdminBillingFormData> => {
    if (userInfo.salutation) formConfig.salutation.initialValue = userInfo.salutation;
    if (userInfo.firstName) formConfig.firstName.initialValue = userInfo.firstName;
    if (userInfo.lastName) formConfig.lastName.initialValue = userInfo.lastName;
    if (userInfo.birthday) formConfig.birthday.initialValue = userInfo.birthday;
    if (userInfo.street) formConfig.street.initialValue = userInfo.street;
    if (userInfo.zip) formConfig.zip.initialValue = userInfo.zip;
    if (userInfo.city) formConfig.city.initialValue = userInfo.city;
    if (userInfo.country) formConfig.country.initialValue = userInfo.country.code;
    if (userInfo.company) formConfig.company.initialValue = userInfo.company;
    if (userInfo.email) formConfig.email.initialValue = userInfo.email;
    if (userInfo.language) formConfig.language.initialValue = userInfo.language;
    if (userInfo.phoneMobile) formConfig.phoneNumberMobile.initialValue = userInfo.phoneMobile;
    if (userInfo.phoneNumberSecond) formConfig.phoneNumberSecond.initialValue = userInfo.phoneNumberSecond;
    if (userInfo.phoneNumberFirst) formConfig.phoneNumberFirst.initialValue = userInfo.phoneNumberFirst;
    return formConfig;
};

const addDisabledToFormConfig = (
    formConfig: Config,
    full: boolean,
): Config => (
    mapObject(formConfig, ([_, value]) => ({
        ...value,
        disabled: (value.initialValue || full) ? true : value.disabled,
    }))
);

const configurePermissions = (
    config: Config,
    userPermissions: UserPermissions,
    mode: 'new' | 'edit',
): Config => {
    const hiddenMap: Partial<Record<keyof Config, boolean>> = {
        isCreateAccount: !userPermissions.has(reservationNewPermissions.createAccountFlag) || mode === 'edit',
        isBookingInformation: !userPermissions.has(reservationNewPermissions.createSmsFlag),
        isPostInformation: !userPermissions.has(reservationNewPermissions.createSmsFlag),
        isBankInformation: !userPermissions.has(reservationNewPermissions.createSmsFlag),
    };

    let result = config;
    result = mapObject(result, ([key, value]) => ({
        ...value,
        hidden: hiddenMap[key] || value.hidden,
    }));
    return result;
};

export const useConfig = () => {
    const mode = useSelector(selectMode);
    const bookedBillingInformation = useSelector(selectBookedBillingInformation);
    const selectedOwner = useSelector(selectSelectedReservationOwner);
    const userInfo = useSelector(selectUserLoginUserInfo);
    const userPermissions = useUserPermissions();

    const formConfig = useMemo(() => {
        const defaultConfig = getDefaultConfig();
        let config = defaultConfig;
        if (userPermissions.has(reservationNewPermissions.selectBillingUser)) {
            config = selectedOwner ? addOwnerToInitialValue(selectedOwner, defaultConfig) : defaultConfig;
            if (selectedOwner) {
                config.isCreateAccount.hidden = true;
                config.email.disabled = true;
            }
            if (mode === 'edit') {
                Object.entries(bookedBillingInformation!).forEach(([key, value]) => {
                    config[key].initialValue = value;
                });
                config = addDisabledToFormConfig(config, true);
            }
        }
        else {
            config = userInfo
                ? addUserInfoToInitialValue(userInfo, defaultConfig)
                : defaultConfig;
            config = addDisabledToFormConfig(config, false);
        }
        config = configurePermissions(config, userPermissions, mode);
        return config;
    }, [userPermissions, selectedOwner, userInfo, bookedBillingInformation, mode]);

    return formConfig;
};
