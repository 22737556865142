import { ContentPage } from '@malesia/react-components/dist/src/components/ContentPage/ContentPage';
import { FlightsOverview } from '@malesia/react-components/dist/src/components/FlightsOverview';
import { HtmlBlock } from '@malesia/react-components/dist/src/components/Front/HtmlBlock/HtmlBlock';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import useSWR from 'swr';
import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { useFlightOverviewCardWithClickHandler } from '../../hooks/useFlightOverviewCardWithClickHandler/useFlightOverviewCardWithClickHandler';
import { loadFlightsPageContent } from '../../services/directus/flightsPage/loadFlightsPageContent';
import { getFlightListSaga } from './saga';
import { selectFlightList } from './selectors';
import { actions, reducer } from './slice';

export const MalesiaFlightsPage: SFC = () => {
    useInjectReducer({ key: 'malesiaFlightPage', reducer });
    useInjectSaga({ key: 'malesiaFlightPage', saga: getFlightListSaga });
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.getFlightList());
    }, [dispatch]);
    const flights = useSelector(selectFlightList);
    const flightsWithHandler = useFlightOverviewCardWithClickHandler(flights);

    const { locale } = useIntl();
    const { data } = useSWR([locale, 'loadFlightsPageContent'], loadFlightsPageContent);

    return (
        <ContentPage
            image={data?.topImage}
        >
            <HtmlBlock content={data?.content ?? ''} />
            <FlightsOverview
                flightColumns={flightsWithHandler}
                minDesktopCard
                isPageBlock={false}
            />
        </ContentPage>
    );
};
