import { call, put, select, takeEvery } from 'typed-redux-saga';
import { downloadNamedFile } from '../../../utils/downloadFile';
import { logError } from '../../../utils/log';
import { apiClientRequest, getApiErrorMessage } from '../../services/ApiClient';
import { selectLocale } from '../App/selectors';
import { cashbackPdfNotifications } from './notifications';
import { cashbackPdfActions } from './slice';

function* downloadCashbackPdf(action: ReturnType<typeof cashbackPdfActions.downloadCashbackPdf>) {
    const transactionId = action.payload.id;
    try {
        const locale: string = yield* select(selectLocale);

        const requestData = {
            requestId: 'balanceLogCashbackPdf',
            uriParams: { transactionId, locale },
        };

        const response = yield* call(apiClientRequest, requestData);
        yield* downloadNamedFile(response, 'Cashback.pdf');
        yield* put(cashbackPdfActions.downloadCashbackPdfSuccess(transactionId));
    }
    catch (error) {
        logError({
            error,
            target: 'CashbackPdf.downloadCashbackPdf',
        });
        yield* put(cashbackPdfActions.downloadCashbackPdfFail(transactionId));
        const message = getApiErrorMessage(error);
        yield* put(cashbackPdfNotifications.unknownError(message));
    }
    finally {
        action.payload.callback();
    }
}

export function* downloadCashbackPdfSaga() {
    yield* takeEvery(cashbackPdfActions.downloadCashbackPdf, downloadCashbackPdf);
}
