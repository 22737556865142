import { ReservationListItem } from '@malesia/json-schema';
import { reservationInvoiceColumns } from '@malesia/react-components/dist/src/components/Reservation/InvoiceList/tableColumns';
import { TableColumn } from '@malesia/react-components/dist/src/components/Table';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { backLinks } from '../../containers/Routes/backLinks';
import { reservationListPermissions } from '../../permissions/adminPages/reservation/reservationList';
import { useUserPermissions } from '../../permissions/useUserPermissions';

export const useInvoiceOverviewColumns = (): TableColumn<ReservationListItem>[] => {
    const userPermissions = useUserPermissions();
    const history = useHistory();

    const columns = useMemo<TableColumn<ReservationListItem>[]>(() => {
        const result = reservationInvoiceColumns({
            actions: {
                editReservation: {
                    click: (reservation) => {
                        const link = backLinks.reservation.forward(reservation.id!, {
                            source: 'invoiceOverview',
                        });
                        history.push(link);
                    },
                    // Agent can't update reservation by design. If need than resolve permissions by own reservation like payReservation.
                    notAllowed: !userPermissions.has(reservationListPermissions.editLink) || !(userPermissions.userType === 'manager'),
                },
                payReservation: {
                    click: (reservation) => {
                        const path = backLinks.reservationPayment.forward(reservation.id!, {
                            source: 'invoiceOverview',
                        });
                        history.push(path);
                    },
                    notAllowed: (
                        !userPermissions.has(reservationListPermissions.payMyReservationLink)
                        && !userPermissions.has(reservationListPermissions.payOtherReservationLink)
                    ),
                    hidden: (reservation) => {
                        const canPay = (
                            reservation.owner!.id === userPermissions.userId
                                ? userPermissions.has(reservationListPermissions.payMyReservationLink)
                                : userPermissions.has(reservationListPermissions.payOtherReservationLink)
                        );
                        if (!canPay) return true;

                        const paid = reservation.invoice?.status === 'paid';
                        return paid;
                    },
                },
            },
        });
        return result;
    }, [
        history,
        userPermissions,
    ]);
    return columns;
};
