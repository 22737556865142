import { AuthRecoverPasswordPostRequest } from '@malesia/json-schema';
import {
    RecoverPasswordForm,
    RecoverPasswordFormChangeEvent,
    RecoverPasswordFormData,
    RecoverPasswordFormErrors,
} from '@malesia/react-components/dist/src/components/Auth/RecoverPasswordForm';
import { PopupWrapper } from '@malesia/react-components/dist/src/components/Popup/PopupWrapper';
import { ErrorObject } from 'ajv';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getValidationErrors } from '../../../api/malesia';
import {
    useInjectReducer,
    useInjectSaga,
} from '../../../utils/redux-injectors';
import { coercionDataToSchema } from '../../../utils/validation/coercion';
import {
    formatSchemaValidationErrors,
    prepareNotEmptyError,
} from '../../containers/ValidationErrorMessage';
import { submitDataSaga } from './saga';
import {
    selectRecoverPasswordModalFormValidationErrors,
    selectRecoverPasswordModalFormIsLoading,
    selectRecoverPasswordModalIsOpened,
    selectRecoverPasswordModalFormResultIsSuccessful,
    selectRecoverPasswordModalFormUntouchedFields,
} from './selectors';
import { recoverPasswordModalActions, reducer, sliceKey } from './slice';

export const RecoverPasswordModal = memo(() => {
    useInjectReducer({ key: sliceKey, reducer });
    useInjectSaga({
        key: `${sliceKey}SubmitDataSaga`,
        saga: submitDataSaga,
    });

    const dispatch = useDispatch();
    const isOpened = useSelector(selectRecoverPasswordModalIsOpened);
    const formValidationErrors = useSelector(
        selectRecoverPasswordModalFormValidationErrors,
    );
    const formUntouchedFields = useSelector(
        selectRecoverPasswordModalFormUntouchedFields,
    );
    const changedUntouchedFields = Array.from(formUntouchedFields);
    const formIsLoading = useSelector(selectRecoverPasswordModalFormIsLoading);
    const formResultIsSuccessful = useSelector(
        selectRecoverPasswordModalFormResultIsSuccessful,
    );

    /**
   * Maps validation errors from ErrorObject[] to RecoverPasswordFormErrors
   * @param formValidationErrors
   */
    const mapValidationErrorsToFormErrors = (
        formValidationErrors: ErrorObject[],
    ): RecoverPasswordFormErrors => {
        return formatSchemaValidationErrors(formValidationErrors);
    };

    const displayedErrors = mapValidationErrorsToFormErrors(formValidationErrors);

    /**
   * Handle modal close button
   * Update root state
   */
    const handleModalClose = () => {
        dispatch(recoverPasswordModalActions.closeModal());
    };

    /**
   * Validate form data
   * @param formData
   */
    const validateForm = (formData: RecoverPasswordFormData): boolean => {
        const schemaValidationErrors = getValidationErrors(
            'file://malesiareisen.com/json/schema/project/api/call/auth/recoverPassword/post/request.json',
            formData,
        );
        const allErrors = [
            ...(schemaValidationErrors ?? []),
            ...prepareNotEmptyError('/email', formData),
        ];
        const isValid = allErrors.length === 0;

        dispatch(
            recoverPasswordModalActions.updateValidationStatus({
                formIsValid: isValid,
                formUntouchedFields: changedUntouchedFields,
                formValidationErrors: allErrors,
            }),
        );

        return isValid;
    };

    /**
   * Handle form field change event
   * @param event
   */
    const handleFormChange = (event: RecoverPasswordFormChangeEvent) => {
    // eslint-disable-next-line no-unused-vars
        const { fieldName, formData } = event;
        // Touch field
        if (changedUntouchedFields.indexOf(fieldName) >= 0) {
            changedUntouchedFields.splice(
                changedUntouchedFields.indexOf(fieldName),
                1,
            );
        }
    // Validation on typing disabled
    // validateForm(formData);
    };

    /**
   * Handle form submit
   * @param formData
   */
    const handleFormSubmit = (formData: RecoverPasswordFormData) => {
        if (!validateForm(formData)) {
            return;
        }
        const { email } = formData;
        const postData: AuthRecoverPasswordPostRequest = {
            email,
        };

        const [isValid, updatedData] = coercionDataToSchema(
            'file://malesiareisen.com/json/schema/project/api/call/auth/recoverPassword/post/request.json',
            postData,
        );

        if (isValid) {
            dispatch(recoverPasswordModalActions.submitData(updatedData));
        }
    };

    return (
        <PopupWrapper
            isOpened={isOpened}
            onClose={handleModalClose}
        >
            {!formResultIsSuccessful && (
                <RecoverPasswordForm
                    formErrors={displayedErrors}
                    isSubmitButtonDisabled={formIsLoading}
                    onChange={handleFormChange}
                    onSubmit={handleFormSubmit}
                />
            )}
            {formResultIsSuccessful && (
                <>
                    <p>
                        <strong>
                            <FormattedMessage
                                id='front-app/RecoverPasswordModal/SuccessTitle:text'
                                defaultMessage='Success!'
                            />
                        </strong>
                    </p>
                    <p>
                        <FormattedMessage
                            id='front-app/RecoverPasswordModal/SuccessMessage:text'
                            defaultMessage='You will receive a recovery link shortly.'
                        />
                    </p>
                </>
            )}
        </PopupWrapper>
    );
});
