import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../utils/log';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { optionNotifications } from '../OptionNewPage/notification';
import { actions } from './slice';
import { apiClientRequest, getApiErrorMessage } from 'app/services/ApiClient';

export function* getOptionTypeList() {
    try {
        const result = yield* call(apiClientRequest, {
            requestId: 'optionTypeEntireList',
        });
        yield* put(actions.getOptionTypeListSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'OptionEditPage.getOptionTypeList',
        });
        yield* put(actions.getOptionTypeListError());
        const message = getApiErrorMessage(error);
        yield* put(optionNotifications.unknownError(message));
    }
}

export function* getOptionTypeListSaga() {
    yield* takeLatest(actions.getOptionTypeList, getOptionTypeList);
}

export function* getOptionData(action: ReturnType<typeof actions.getOptionData>) {
    try {
        const optionId = action.payload;
        const result = yield* call(apiClientRequest, {
            requestId: 'optionData',
            uriParams: { id: optionId },
        });
        yield* put(actions.getOptionDataSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'OptionEditPage.getOptionData',
        });
        yield* put(actions.getOptionDataError());
        const message = getApiErrorMessage(error);
        yield* put(optionNotifications.unknownError(message));
    }
}

export function* getOptionDataSaga() {
    yield* takeLatest(actions.getOptionData, getOptionData);
}

export function* updateOptionData(action: ReturnType<typeof actions.updateOptionData>) {
    try {
        const { optionData, optionId } = action.payload;

        yield* call(apiClientRequest, {
            requestId: 'optionUpdate',
            uriParams: { id: optionId },
            requestPayload: optionData,
        });
        yield* put(actions.updateOptionDataSuccess());
        yield* put(push(allAdminRoutePaths.rateOptionList));
        yield* put(optionNotifications.updatedSuccessfully);
    }
    catch (error) {
        logError({
            error,
            target: 'OptionEditPage.updateOptionData',
        });
        yield* put(actions.updateOptionDataError());
        const message = getApiErrorMessage(error);
        yield* put(optionNotifications.unknownError(message));
    }
}

export function* updateOptionDataSaga() {
    yield* takeLatest(actions.updateOptionData, updateOptionData);
}
