import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { permissionMaker } from '../permissionExpression';
import { PaginatedAdminRoutePermissions } from '.';

const { some, every, anyone } = permissionMaker;

const reservationNew = some(
    'ROLE_FLIGHT_LIST',
    'ROLE_FLIGHT_AGENT_FLIGHT_LIST',
);
const reservationEdit = every(
    'ROLE_RESERVATION_READ',
    'ROLE_RESERVATION_UPDATE',
    some(
        'ROLE_FLIGHT_LIST',
        'ROLE_FLIGHT_AGENT_FLIGHT_LIST',
    ),
);
const reservationHistory = every(
    'ROLE_RESERVATION_READ',
    'ROLE_RESERVATION_SNAPSHOT_LIST',
);
const reservationList = anyone();
const reservationPayment = every(
    'ROLE_RESERVATION_INVOICE_ADD_PAYMENT',
    'ROLE_ACCOUNT_LIST_PAYMENT',
);
const invoiceOverview = every(
    reservationList,
    'ROLE_RESERVATION_INVOICE_SET_PAYMENT_TERMS_BILL',
);

export const reservation: PaginatedAdminRoutePermissions['reservation'] = {
    [allAdminRoutePaths.reservationNew]: reservationNew,
    [allAdminRoutePaths.reservationEdit]: reservationEdit,
    [allAdminRoutePaths.reservationHistory]: reservationHistory,
    [allAdminRoutePaths.reservationList]: reservationList,
    [allAdminRoutePaths.reservationPayment]: reservationPayment,
    [allAdminRoutePaths.reservationInvoiceList]: invoiceOverview,
    [allAdminRoutePaths.reservation]: reservationList,
};
