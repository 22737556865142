import { OptionPaginatedList } from '@malesia/json-schema';
import { SortingDataType } from '@malesia/react-components/dist/src/components/Table';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { OptionFilterData, OptionListPageState } from './types';

export const initialState: OptionListPageState = {
    initialized: {
        sorting: false,
    },
    isDirty: true,
    isLoading: true,

    optionList: {
        items: [],
        total: 0,
    },
    filterData: {
        searchText: undefined,
        isActiveOnly: true,
        sortBy: 'code',
        sortOrder: 'ASC',
    },
    pagination: {
        page: 0,
        pageSize: 15,
    },
};

const optionListPageSlice = createSlice({
    name: 'optionListPage',
    initialState,
    reducers: {
        setFilterData(state, action: PayloadAction<OptionFilterData>) {
            state.filterData = action.payload;
            state.pagination.page = initialState.pagination.page;
            state.isDirty = true;
        },
        setPage(state, action: PayloadAction<number>) {
            state.pagination.page = action.payload;
            state.isDirty = true;
        },
        setPageSize(state, action: PayloadAction<number>) {
            state.pagination.pageSize = action.payload;
            state.pagination.page = initialState.pagination.page;
            state.isDirty = true;
        },
        setSorting(state, action: PayloadAction<SortingDataType>) {
            const data = action.payload;
            state.filterData = {
                ...state.filterData,
                sortBy: data.sortBy,
                sortOrder: data.sortOrder,
            };
            state.isDirty = true;
            state.initialized.sorting = true;
        },
        getOptionList(state) {
            state.isLoading = true;
        },
        getOptionListSuccess(state, action: PayloadAction<OptionPaginatedList>) {
            state.optionList = action.payload;
            state.isLoading = false;
            state.isDirty = false;
        },
        getOptionListError(state) {
            state.isLoading = false;
        },
        unmount(state) {
            state.initialized = initialState.initialized;
            state.isLoading = true;
            state.isDirty = true;
        },
    },
});

export const { actions, reducer, name: sliceKey } = optionListPageSlice;
