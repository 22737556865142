import { AccountingBalanceLogFilter, AccountingBalanceLogFilterData, AccountingBalanceLogFilterProps, accountingBalanceLogFilterConfig } from '@malesia/react-components/dist/src/components/Accounting/BalanceLog/Filter';
import { AccountingBalanceLogInfo, AccountingBalanceLogInfoProps } from '@malesia/react-components/dist/src/components/Accounting/BalanceLog/Info';
import { ValueConfig, useValueForm } from '@malesia/react-components/dist/src/hooks/valueForm';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getQueryParameters } from '../../../../utils/uriUtils';
import { balanceLogPermissions } from '../../../permissions/adminPages/balance/balanceLog';
import { useUserPermissions } from '../../../permissions/useUserPermissions';
import {
    selectAccountQuery,
    selectAccountsList,
    selectCategoryList,
    selectChangeAmount,
    selectFilterData,
    selectOverviewPdfLoading,
    selectRelatedAccountQuery,
    selectRelatedAccountsList,
} from '../store/selectors';
import { balanceLogActions } from '../store/slice';
import { InitAccountArgs } from '../store/types';

export const AccountingBalanceLogFilterWithStore: SFC = () => {
    const dispatch = useDispatch();
    const userPermissions = useUserPermissions();

    useEffect(() => {
        dispatch(balanceLogActions.getAccountList(''));
        dispatch(balanceLogActions.getRelatedAccountList(''));
        dispatch(balanceLogActions.getCategoryList());
    }, [dispatch]);

    useEffect(() => {
        const { accountId } = getQueryParameters<{ accountId: number }>({
            accountId: 'number',
        });
        const args: InitAccountArgs = accountId === undefined
            ? { target: 'my' }
            : { target: 'query', id: accountId };
        dispatch(balanceLogActions.initAccount(args));
    }, [dispatch]);

    const categoriesItems = useSelector(selectCategoryList);
    const accountSearchValue = useSelector(selectAccountQuery);
    const accountItems = useSelector(selectAccountsList);
    const filterData = useSelector(selectFilterData);
    const relatedAccountSearchValue = useSelector(selectRelatedAccountQuery);
    const relatedAccountItems = useSelector(selectRelatedAccountsList);
    const changeAmount = useSelector(selectChangeAmount);
    const overviewPdfLoading = useSelector(selectOverviewPdfLoading);

    const {
        field,
    } = useValueForm({
        value: filterData,
        onChange: (newFilterData) => {
            dispatch(balanceLogActions.setFilterData(newFilterData));
        },
        config: [accountingBalanceLogFilterConfig, (): ValueConfig<AccountingBalanceLogFilterData> => ({
            shape: {
                account: {
                    items: accountItems,
                    query: accountSearchValue,
                    setQuery: (query) => {
                        dispatch(balanceLogActions.getAccountList(query));
                    },
                    hidden: !userPermissions.has(balanceLogPermissions.accountFilter),
                },
                relatedAccount: {
                    items: relatedAccountItems,
                    query: relatedAccountSearchValue,
                    setQuery: (query) => {
                        dispatch(balanceLogActions.getRelatedAccountList(query));
                    },
                    hidden: !userPermissions.has(balanceLogPermissions.relatedAccountFilter),
                },
                categories: {
                    items: categoriesItems,
                    hidden: !userPermissions.has(balanceLogPermissions.categoryFilter),
                },
            },
        })],
    });

    const accountingBalanceLogInfoProps: AccountingBalanceLogInfoProps = {
        balance: filterData.account?.balance,
        changeAmount,
        onClickOverview: () => {
            dispatch(balanceLogActions.downloadOverviewPdf());
        },
        overviewPdfLoading,
        balanceInfoHidden: !userPermissions.has(balanceLogPermissions.getAnyBalanceInfo),
        transactionOverviewHidden: !userPermissions.has(balanceLogPermissions.overviewPdf),
    };

    const accountingBalanceLogFilterProps: AccountingBalanceLogFilterProps = {
        field,
        onClickReset: () => {
            dispatch(balanceLogActions.resetFilterData());
            dispatch(balanceLogActions.initAccount({ target: 'my' }));
        },
        submit: () => {
            dispatch(balanceLogActions.getBalanceLogList());
        },
        infoSlot: (
            <AccountingBalanceLogInfo {...accountingBalanceLogInfoProps} />
        ),
    };

    return (
        <AccountingBalanceLogFilter {...accountingBalanceLogFilterProps} />
    );
};
