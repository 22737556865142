import { StepSelectFlight } from '@malesia/react-components/dist/src/components/Reservation/StepSelectFlight';
import classnames from 'classnames';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectAirportHashMap } from '../../../../containers/SharedData/selectors';
import { useAirportsFromToText } from '../../common/useAirportsFromToText';
import {
    selectBookedFlights,
    selectFlightLists,
    selectSelectedFlights,
    selectTravelInfo,
} from '../../store/selectors';
import { useAdminFlightTabs } from './useAdminFlightTabs';

export type StepOutboundFlightProps = {
    className?: string,
};

export function StepOutboundFlight({ className }: StepOutboundFlightProps) {
    const classNames = classnames('step-outbound-flight', className ?? '');

    const travelInfo = useSelector(selectTravelInfo);
    const airportHashMap = useSelector(selectAirportHashMap);
    const outboundAirportsFromToText = useAirportsFromToText(
        travelInfo.outbound.airports,
        airportHashMap,
    );

    const flightLists = useSelector(selectFlightLists);
    const selectedFlights = useSelector(selectSelectedFlights);
    const bookedFlights = useSelector(selectBookedFlights);
    const { tabs, activeTabIndex } = useAdminFlightTabs(
        flightLists.outbound,
        selectedFlights.outbound,
        bookedFlights.outbound,
        'outbound',
    );
    const onSubmit = useCallback(() => {}, []);

    return (
        <div className={classNames}>
            <StepSelectFlight
                flightDirection='outbound'
                isSubmitButtonDisabled={false}
                onSubmit={onSubmit}
                activeTab={activeTabIndex}
                airportsFromToText={outboundAirportsFromToText}
                flightLoading={flightLists.isLoading}
                flightTabs={tabs}
                notShowSubmitButton={true}
            />
        </div>
    );
}
