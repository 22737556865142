import { ReservationCalculatePostResponse } from '@malesia/json-schema';
import { ReservationPassengerOptionChangeData } from '@malesia/react-components/dist/src/components/Reservation/PassengersList';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { removeFromArray, replaceArrayItemByIndex } from '../../../utils/arrayFunctions';
import { ResetDirectionSeatsOptions, ResetSeatOptions, SelectSeatOptions } from '../../../utils/reservation/seats/useSeatsSelectionPopup';
import { PassengerInfo, PassengerSeats } from '../../../utils/reservation/types';
import { mapApiCostToBasketCost } from '../../services/ReservationCalculate/utils';
import {
    PublicReservationBasket,
    BasketBillingInformation,
    BasketSelectedFlights,
    BasketSelectedPrices,
} from './reservationBasket.types';

export const initialState: PublicReservationBasket = {
    travelType: 'return',
    dateRange: {},
    mainAirports: {},
    otherAirports: {},
    passengers: [],
    passengersAges: {
        adult: 1,
        child: 0,
        infant: 0,
    },
    selectedFlights: {},
    selectedPrices: {},
};
export const getPublicReservationInitialState = (): PublicReservationBasket => (
    JSON.parse(JSON.stringify(initialState))
);

const reservationBasketSlice = createSlice({
    name: 'reservationBasket',
    initialState,
    reducers: {
        reset(state, action: PayloadAction<PublicReservationBasket | undefined>) {
            return action.payload ?? initialState;
        },
        setBillingInformation(state, action: PayloadAction<BasketBillingInformation>) {
            state.billingInformation = action.payload;
        },
        updateBasket(state, action: PayloadAction<Partial<PublicReservationBasket>>) {
            return {
                ...state,
                ...action.payload,
            };
        },
        updateCost(state, action: PayloadAction<ReservationCalculatePostResponse>) {
            state.cost = mapApiCostToBasketCost(action.payload.cost!);
            state.passengers.forEach((x, i) => {
                const item = action.payload.cost!.passengers[i];
                if (!item) return;
                const { passenger } = item;
                x.info.ageType = passenger.ageType;
            });
        },
        resetFlightData(state) {
            state.selectedFlights = {};
            state.selectedPrices = {};
            state.passengers.forEach(x => {
                x.seats = {};
                x.options = {};
            });
            delete state.cost;
        },
        setSelectedFlights(state, action: PayloadAction<BasketSelectedFlights>) {
            state.selectedFlights = action.payload;
            state.passengers.forEach(x => {
                x.seats = {};
                x.options = {};
            });
        },
        resetSelectedFlights(state, action: PayloadAction<(keyof BasketSelectedFlights)[]>) {
            const directions = action.payload;
            if (directions.includes('return') && state.selectedFlights?.return) {
                state.selectedFlights.return = undefined;
            }
            if (directions.includes('outbound') && state.selectedFlights?.outbound) {
                state.selectedFlights.outbound = undefined;
            }
        },
        setSelectedPrices(state, action: PayloadAction<BasketSelectedPrices>) {
            state.selectedPrices = action.payload;
        },
        addPassenger(state) {
            const { passengers } = state;
            state.passengers = [...passengers, {
                info: {
                    ageType: 'adult',
                    firstName: '',
                    lastName: '',
                    birthday: '',
                    gender: undefined,
                },
                seats: {},
                options: {},
            }];
        },
        updatePassengerAtPosition(
            state,
            action: PayloadAction<UpdatePassengerAtPositionType>,
        ) {
            const { passengerData, position } = action.payload;
            const { passengers } = state;
            const passenger = passengers[position];
            state.passengers = replaceArrayItemByIndex(
                passengers,
                position,
                {
                    ...passenger,
                    info: passengerData,
                    seats: passengerData.ageType === 'infant' ? {} : passenger.seats,
                },
            );
        },
        removePassengerAtPosition(state, action: PayloadAction<number>) {
            const position = action.payload;
            const { passengers } = state;
            state.passengers = removeFromArray(passengers, position);
        },
        setPassengerOptions(
            state,
            action: PayloadAction<ReservationPassengerOptionChangeData>,
        ) {
            const { isChecked, optionId, passengerIdx } = action.payload;
            const { passengers } = state;
            const passenger = passengers[passengerIdx];

            const options = {
                ...passenger.options,
                [optionId]: isChecked,
            };

            state.passengers = replaceArrayItemByIndex(
                passengers,
                passengerIdx,
                {
                    ...passenger,
                    options,
                },
            );
        },
        resetSeat(state, action: PayloadAction<ResetSeatOptions>) {
            const { passengerIdx, flightDirection } = action.payload;
            const { passengers } = state;
            const passenger = passengers[passengerIdx];

            const updatedPassengerSeats: PassengerSeats = {
                ...passenger.seats,
                [flightDirection]: undefined,
            };

            state.passengers = replaceArrayItemByIndex(
                passengers,
                passengerIdx,
                {
                    ...passenger,
                    seats: updatedPassengerSeats,
                },
            );
        },
        resetDirectionSeats(state, action: PayloadAction<ResetDirectionSeatsOptions>) {
            const { flightDirection } = action.payload;
            const { passengers } = state;

            passengers.forEach(passenger => {
                const updatedPassengerSeats: PassengerSeats = {
                    ...passenger.seats,
                    [flightDirection]: undefined,
                };
                passenger.seats = updatedPassengerSeats;
            });
        },
        selectSeat(state, action: PayloadAction<SelectSeatOptions>) {
            const { passengerIdx, flightDirection, seatLabel } = action.payload;
            const { passengers } = state;
            const passenger = passengers[passengerIdx];

            const updatedPassengerSeats: PassengerSeats = {
                ...passenger.seats,
                [flightDirection]: seatLabel,
            };

            state.passengers = replaceArrayItemByIndex(
                passengers,
                passengerIdx,
                {
                    ...passenger,
                    seats: updatedPassengerSeats,
                },
            );
        },
    },
});

export const {
    actions: reservationBasketActions,
    reducer: reservationBasketReducer,
    name: reservationBasketSliceKey,
} = reservationBasketSlice;

export type UpdatePassengerAtPositionType = {
    passengerData: PassengerInfo,
    position: number,
};
