import { PageTabs } from '@malesia/react-components/dist/src/components/Page/Tabs';
import { RouteItem } from '@malesia/react-components/dist/src/components/types';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Link,
    Redirect,
    Route,
    RouteComponentProps,
    Switch,
} from 'react-router-dom';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { useTabsState } from '../../containers/Routes/tabsState/useTabsState';
import { useFilteredAdminRoutesByPermissions } from '../../permissions/useFilteredAdminRoutesByPermissions';
import { AgentEditPage } from '../AgentEditPage';
import { AgentListPage } from '../AgentListPage';
import { AgentNewPage } from '../AgentNewPage';
import { CustomerEditPage } from '../CustomerEditPage';
import { CustomerListPage } from '../CustomerListPage';
import { CustomerNewPage } from '../CustomerNewPage';
import { ManagerEditPage } from '../ManagerEditPage';
import { ManagerListPage } from '../ManagerListPage';
import { ManagerNewPage } from '../ManagerNewPage';
import { NotFoundPage } from '../NotFoundPage';

const UserRouterPage: SFC<RouteComponentProps> = (props) => {
    const { location } = props;
    const { pathname } = location;

    const isRouteMatch = linkRoute => pathname === linkRoute;

    const userRoutes: RouteItem[] = useFilteredAdminRoutesByPermissions([
        {
            routeKey: 'managerList',
            linkRoute: allAdminRoutePaths.userManagerList,
            LinkComponent: (props: React.PropsWithChildren<any>) => (
                <Link to={allAdminRoutePaths.userManagerList}>
                    {React.Children.only(props.children)}
                </Link>
            ),
            linkSubject: (
                <FormattedMessage
                    id='front-app/Routes/MANAGER_LIST:Title'
                    defaultMessage='Managers'
                />
            ),
        },
        {
            routeKey: 'agentList',
            linkRoute: allAdminRoutePaths.userAgentList,
            LinkComponent: (props: React.PropsWithChildren<any>) => (
                <Link to={allAdminRoutePaths.userAgentList}>
                    {React.Children.only(props.children)}
                </Link>
            ),
            linkSubject: (
                <FormattedMessage
                    id='front-app/Routes/AGENT_LIST:Title'
                    defaultMessage='Agents'
                />
            ),
        },
        {
            routeKey: 'passengerList',
            linkRoute: allAdminRoutePaths.userCustomerList,
            LinkComponent: (props: React.PropsWithChildren<any>) => (
                <Link to={allAdminRoutePaths.userCustomerList}>
                    {React.Children.only(props.children)}
                </Link>
            ),
            linkSubject: (
                <FormattedMessage
                    id='front-app/Routes/CUSTOMER_LIST:Title'
                    defaultMessage='Customers'
                />
            ),
        },
    ]);

    const { firstRoute } = useTabsState(allAdminRoutePaths.user, userRoutes);

    return (
        <PageTabs
            isRouteMatch={isRouteMatch}
            routeItems={userRoutes}
        >
            <Switch>
                <Route
                    exact
                    path={allAdminRoutePaths.userManagerList}
                    component={ManagerListPage}
                />
                <Route
                    exact
                    path={allAdminRoutePaths.userManagerEdit}
                    component={ManagerEditPage}
                />
                <Route
                    exact
                    path={allAdminRoutePaths.userManagerNew}
                    component={ManagerNewPage}
                />
                <Route
                    exact
                    path={allAdminRoutePaths.userAgentList}
                    component={AgentListPage}
                />
                <Route
                    exact
                    path={allAdminRoutePaths.userAgentNew}
                    component={AgentNewPage}
                />
                <Route
                    exact
                    path={allAdminRoutePaths.userAgentEdit}
                    component={AgentEditPage}
                />
                <Route
                    exact
                    path={allAdminRoutePaths.userCustomerList}
                    component={CustomerListPage}
                />
                <Route
                    exact
                    path={allAdminRoutePaths.userCustomerNew}
                    component={CustomerNewPage}
                />
                <Route
                    exact
                    path={allAdminRoutePaths.userCustomerEdit}
                    component={CustomerEditPage}
                />
                <Route
                    exact
                    path={allAdminRoutePaths.user}
                >
                    <Redirect to={firstRoute} />
                </Route>
                <Route component={NotFoundPage} />
            </Switch>
        </PageTabs>
    );
};

const Memoized = memo(UserRouterPage);
export {
    Memoized as UserRouterPage,
};
