import { PassengerItemDataType } from '@malesia/react-components/dist/src/components/Reservation/PassengersList/Item';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../../../utils/@reduxjs/toolkit';
import { StepPassengersState } from './types';

export const initialState: StepPassengersState = {
    passengersData: [],
    isValidationErrors: false,
    //
    loading: false,
    error: false,
};

const stepPassengersSlice = createSlice({
    name: 'stepPassengers',
    initialState,
    reducers: {
        reset() {
            return initialState;
        },
        calculateBasketWithUpdatePassengers(state) {},
        requestCalculateCost(state, action: PayloadAction<() => void>) {},
        calculateCost(state, action: PayloadAction<() => void>) {},
        calculateCostSuccess() {},
        calculateCostError() {},
        setPassengersData(state, action: PayloadAction<PassengerItemDataType[]>) {
            state.passengersData = action.payload;
        },
    },
});

export const {
    actions: stepPassengersActions,
    reducer: stepPassengersReducer,
    name: stepPassengersSliceKey,
} = stepPassengersSlice;
