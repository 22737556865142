import {
    debounce,
    put,
    select,
    takeLatest,
    throttle,
} from 'typed-redux-saga';
import { logError } from '../../../../utils/log';
import { selectUserPermissions } from '../../../permissions/selectUserPermissions';
import { getApiPermittedReservationList } from '../../../services/Reservation';
import { buildReservationListRequest } from '../../../services/Reservation/listUtils';
import {
    selectFilterData,
    selectPagination,
    selectSorting,
    selectInitialized,
} from './selectors';
import { actions } from './slice';

function* getReservationList() {
    try {
        const initialized = yield* select(selectInitialized);
        const can = initialized.sorting;
        if (!can) return;

        const userPermissions = yield* selectUserPermissions();
        const pagination = yield* select(selectPagination);
        const sorting = yield* select(selectSorting);
        const formData = yield* select(selectFilterData);

        const request = buildReservationListRequest(
            userPermissions,
            pagination,
            sorting,
            {
                ...formData,
                reservationStatus: ['created', 'reserved', 'confirmed'],
                paymentTerms: ['bill'],
            },
        );

        const result = yield* getApiPermittedReservationList(request);
        yield* put(actions.getReservationListSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'InvoiceOverviewListPage.getReservationsList',
        });
        yield* put(actions.getReservationListFail());
    }
}

export function* requestGetReservationsList() {
    yield* put(actions.getReservationList());
}

export function* getReservationListSaga() {
    yield* takeLatest(actions.getReservationList, getReservationList);

    yield* throttle(1000, [
        actions.setPage,
        actions.setSorting,
    ], requestGetReservationsList);

    yield* debounce(1000, [
        actions.setFilterData,
        actions.setPageSize,
    ], requestGetReservationsList);
}
