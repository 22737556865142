import { CustomerFormType } from '@malesia/react-components/dist/src/components/Customer/Edit';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { CustomerNewPageState } from './types';

export const initialState: CustomerNewPageState = {
    loading: false,
};

const customerNewPageSlice = createSlice({
    name: 'customerNewPage',
    initialState,
    reducers: {
        createCustomer(state, action: PayloadAction<CustomerFormType>) {
            state.loading = true;
        },
        createCustomerSuccess(state) {
            state.loading = false;
        },
        createCustomerError(state) {
            state.loading = false;
        },
    },
});

export const { actions, reducer, name: sliceKey } = customerNewPageSlice;
