import { RoleCode } from './roleCode';

type PermissionExpressionMap = {
    anyone: { type: 'anyone' },
    some: { type: 'some', permissions: PermissionExpression[] },
    every: { type: 'every', permissions: PermissionExpression[] },
    role: RoleCode,
    nobody: undefined,
};
type K = keyof PermissionExpressionMap;
export type PermissionExpression<Key extends K = K> = PermissionExpressionMap[Key];

export const permissionMaker = {
    anyone: (): PermissionExpression<'anyone'> => ({ type: 'anyone' }),
    some: (
        one: PermissionExpression,
        two: PermissionExpression,
        ...rest: PermissionExpression[]
    ): PermissionExpression<'some'> => ({
        type: 'some',
        permissions: [one, two, ...rest],
    }),
    every: (
        one: PermissionExpression,
        two: PermissionExpression,
        ...rest: PermissionExpression[]
    ): PermissionExpression<'every'> => ({
        type: 'every',
        permissions: [one, two, ...rest],
    }),
    role: (permission: RoleCode): PermissionExpression<'role'> => permission,
    nobody: (): PermissionExpression<'nobody'> => undefined,
};
export type PermissionMaker = typeof permissionMaker;
