import { PublicFlightListItem } from '@malesia/json-schema';
import { DATE_FORMAT } from '@malesia/react-components/dist/src/utils/dateTimeFormat';
import moment, { Moment } from 'moment';
import { ReservationAllowed } from '../../app/pages/ReservationNewPage/permissions';
import { isClosedFlight, isDepartedFlight } from './flightsStatus';

export type FlightsTab = {
    date: string,
    flights: PublicFlightListItem[],
};

const getDateIndex = (date: Moment | string | Date) => {
    return moment.parseZone(date).format(DATE_FORMAT);
};

export const convertDateIndexToMoment = (dateIndex: string): Moment => {
    return moment.parseZone(dateIndex, DATE_FORMAT);
};

export type RangeOptions = {
    daysBefore: number,
    daysAfter: number,
};
export const weekRange: RangeOptions = {
    daysBefore: 3,
    daysAfter: 3,
};
// ToDo: Use DateRange<Moment> from new date picker
export const createDateRange = (
    dateISO: string | undefined,
    rangeOptions: RangeOptions,
): [Moment, Moment] => {
    const start = moment.parseZone(dateISO).subtract(rangeOptions.daysBefore, 'days').startOf('day');
    const end = moment.parseZone(dateISO).add(rangeOptions.daysAfter, 'days').startOf('day');
    return [start, end];
};
const createDateRangeArray = (
    dateISO: string | undefined,
    rangeOptions: RangeOptions,
): string[] => {
    const [start, end] = createDateRange(dateISO, rangeOptions);

    const periodDays = end.diff(start, 'days');
    const result: string[] = [];
    for (let i = 0; i <= periodDays; i++) {
        result.push(getDateIndex(start));
        start.add(1, 'days');
    }
    return result;
};

export type CreateDateRangeMapOptions = {
    dateISO: string | undefined,
    rangeOptions: RangeOptions,
    flightList: PublicFlightListItem[],
    bookedFlightId?: number,
    allowed: ReservationAllowed,
};
export const createFlightsTabs = (
    options: CreateDateRangeMapOptions,
): FlightsTab[] => {
    const {
        dateISO,
        rangeOptions,
        flightList,
        bookedFlightId,
        allowed,
    } = options;

    const getFlightDateIndex = (targetFlight: PublicFlightListItem) => {
        const localFlightDate = moment.parseZone(targetFlight.departure);
        return getDateIndex(localFlightDate);
    };
    const bookedFlight = flightList.find(x => x.id === bookedFlightId);
    const isBookedFinished = bookedFlight && (
        (
            isClosedFlight(bookedFlight)
            && !allowed.flight.closed
        )
        || (
            isDepartedFlight(bookedFlight)
            && !allowed.flight.departed
        )
    );
    if (isBookedFinished) {
        const date = getFlightDateIndex(bookedFlight);
        return [{
            date,
            flights: [bookedFlight],
        }];
    }

    if (!dateISO) {
        return [];
    }
    const flightsTabs = createDateRangeArray(dateISO, rangeOptions).map<FlightsTab>(date => ({
        date,
        flights: [],
    }));
    flightList.forEach((flight) => {
        const flightDate = getFlightDateIndex(flight);
        const index = flightsTabs.findIndex(x => x.date === flightDate);
        if (index === -1) return;
        flightsTabs[index].flights.push(flight);
    });
    return flightsTabs;
};
