import { ManagerPaginatedList } from '@malesia/json-schema';
import { SortingDataType } from '@malesia/react-components/dist/src/components/Table';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { ManagerFilterData, ManagerListPageState } from './types';

export const initialState: ManagerListPageState = {
    initialized: {
        sorting: false,
    },
    isDirty: true,
    isLoading: true,

    managerList: {
        items: [],
        total: 0,
    },
    filterData: {
        searchText: undefined,
        isActiveOnly: true,
        sortBy: 'displayName',
        sortOrder: 'ASC',
    },
    pagination: {
        page: 0,
        pageSize: 15,
    },
};

const managerListPageSlice = createSlice({
    name: 'managerListPage',
    initialState,
    reducers: {
        updateFilterData(state, action: PayloadAction<ManagerFilterData>) {
            state.filterData = { ...state.filterData, ...action.payload };
            state.isDirty = true;
        },
        setPage(state, action: PayloadAction<number>) {
            state.pagination.page = action.payload;
            state.isDirty = true;
        },
        setPageSize(state, action: PayloadAction<number>) {
            state.pagination.pageSize = action.payload;
            state.pagination.page = initialState.pagination.page;
            state.isDirty = true;
        },
        setSorting(state, action: PayloadAction<SortingDataType>) {
            const data = action.payload;
            state.filterData = {
                ...state.filterData,
                sortBy: data.sortBy,
                sortOrder: data.sortOrder,
            };
            state.isDirty = true;
            state.initialized.sorting = true;
        },
        getManagerList(state) {
            state.isLoading = true;
        },
        getManagerListSuccess(state, action: PayloadAction<ManagerPaginatedList>) {
            state.managerList = action.payload;
            state.isLoading = false;
            state.isDirty = false;
        },
        getManagerListError(state) {
            state.isLoading = false;
        },
    },
});

export const { actions, reducer, name: sliceKey } = managerListPageSlice;
