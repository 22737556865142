import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => {
    return state.malesiaUpdatePasswordPage || initialState;
};

export const selectMalesiaUpdatePasswordPageFormValidationErrors = createSelector(
    [selectDomain],
    malesiaUpdatePasswordPage => malesiaUpdatePasswordPage.formValidationErrors,
);

export const selectMalesiaUpdatePasswordPageFormUntouchedFields = createSelector(
    [selectDomain],
    malesiaUpdatePasswordPage => malesiaUpdatePasswordPage.formUntouchedFields,
);

export const selectMalesiaUpdatePasswordPageFormIsValid = createSelector(
    [selectDomain],
    malesiaUpdatePasswordPage => malesiaUpdatePasswordPage.formIsValid,
);

export const selectMalesiaUpdatePasswordPageFormIsLoading = createSelector(
    [selectDomain],
    malesiaUpdatePasswordPage => malesiaUpdatePasswordPage.formIsLoading,
);

export const selectMalesiaUpdatePasswordPageFormResultIsSuccessful = createSelector(
    [selectDomain],
    malesiaUpdatePasswordPage => malesiaUpdatePasswordPage.formResultIsSuccessful,
);
