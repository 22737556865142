import { ReservationSummaryProps } from '@malesia/react-components/dist/src/components/Reservation/Summary';
import { PriceItemType } from '@malesia/react-components/dist/src/components/Reservation/Summary/Info/PriceBlock';
import { AgeType } from '@malesia/react-components/dist/src/utils/ageType';
import { localizeMoney } from '@malesia/react-components/dist/src/utils/roundMoney';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { getFlightRowParts } from '../../../../utils/reservation/flightUtils';
import { FlightVariants } from '../../../../utils/reservation/selectedFlight';
import { getCostByAge } from '../../../containers/ReservationBasket/reservationBasket.selectors';
import { PublicBasketCost } from '../../../containers/ReservationBasket/reservationBasket.types';
import { allPublicRoutePaths } from '../../../containers/Routes/allPublicRoutePaths';
import { passengerAgeType } from './labels';

export type GetReservationSummaryDataParamsType = {
    locale: string,
    selectedFlights: FlightVariants,
    cost: PublicBasketCost | undefined,
    optionsSummary: PriceForPassengersOptions[],
    totalSeatsCost: number,
};

export type ReservationSummaryDataType = Omit<ReservationSummaryProps, 'modificators' | 'isContentVisible'>;

export type PriceForPassengersOptions = {
    totalPrice: number,
    count: number,
    option: {configValues: {title: Record<string, string>}},
};

export const getReservationSummaryData = (
    params: GetReservationSummaryDataParamsType,
): ReservationSummaryDataType => {
    const { locale, selectedFlights, cost, totalSeatsCost } = params;

    const passengersByAge = getCostByAge(cost);

    const flightFirstLabelsParts = getFlightRowParts(selectedFlights.outbound, locale);
    const flightFirst = {
        airportsTitle: flightFirstLabelsParts.airportsTitle,
        EditFlightActionWrapper: (props: React.PropsWithChildren<any>) => (
            <Link to={allPublicRoutePaths.bookingFlightSelectFlightOutbound}>
                {React.Children.only(props.children)}
            </Link>
        ),
        flightSummary: [
            flightFirstLabelsParts.flightNumber,
            flightFirstLabelsParts.flightDate,
            flightFirstLabelsParts.flightTime,
            flightFirstLabelsParts.tariffName,
        ].join(' '),
        rateTariffType: flightFirstLabelsParts.rateTariffType,
    };

    const flightSecondLabelsParts = getFlightRowParts(selectedFlights.return, locale);
    const flightSecond = {
        airportsTitle: flightSecondLabelsParts.airportsTitle,
        EditFlightActionWrapper: (props: React.PropsWithChildren<any>) => (
            <Link to={allPublicRoutePaths.bookingFlightSelectFlightReturn}>
                {React.Children.only(props.children)}
            </Link>
        ),
        flightSummary: [
            flightSecondLabelsParts.flightNumber,
            flightSecondLabelsParts.flightDate,
            flightSecondLabelsParts.flightTime,
            flightSecondLabelsParts.tariffName,
        ].join(' '),
        rateTariffType: flightSecondLabelsParts.rateTariffType,
    };

    const flightSecondVariant = selectedFlights?.return ? [flightSecond] : [];
    const flights = !!selectedFlights?.outbound
        ? [...[flightFirst], ...flightSecondVariant].filter(Boolean)
        : [];

    const formatToPassengersTickets = (
        priceForPassengerAge: {ageType: AgeType, totalPrice: number, count: number },
    ): PriceItemType => {
        const { totalPrice, count, ageType } = priceForPassengerAge;
        return {
            labelText: (
                <>
                    {count}&nbsp;x&nbsp;
                    {passengerAgeType[ageType]({ count })}
                </>
            ),
            priceText: `CHF ${localizeMoney(totalPrice, locale, 'BookingFlightPage/StepsSummary/formatToPassengersTickets')}`,
        };
    };

    const passengersTicketsCostByAge = (Object.keys(passengersByAge) as AgeType[])
        .filter(age => passengersByAge[age].count !== 0)
        .map(age => ({
            count: passengersByAge[age].count,
            ageType: age,
            totalPrice: passengersByAge[age].total,
        }));
    const passengersTickets = passengersTicketsCostByAge.map(formatToPassengersTickets);
    const totalTicketsCost = passengersTicketsCostByAge.reduce((res, costItem) => {
        return res + costItem.totalPrice;
    }, 0);

    const formatToPassengersOptions = (
        priceForPassengersOptions: PriceForPassengersOptions,
        locale,
    ): PriceItemType | null => {
        const { totalPrice, count, option } = priceForPassengersOptions;
        const configValues = option?.configValues;
        if (count && configValues?.title?.[locale]) {
            return {
                labelText: (
                    <>
                        {count}&nbsp;x&nbsp;
                        {configValues.title[locale]}
                    </>
                ),
                priceText: `CHF ${localizeMoney(totalPrice, locale, 'BookingFlightPage/StepsSummary/formatToPassengersOptions')}`,
            };
        }
        else {
            return null;
        }
    };

    const optionsTotalCost = params.optionsSummary.reduce(
        (res, optionsSummaryItem) => res + optionsSummaryItem.totalPrice,
        0,
    );
    const bookingOptions = params.optionsSummary
        .map(optionSummaryItem => formatToPassengersOptions(optionSummaryItem, locale))
        .filter(v => !!v) as PriceItemType[];

    const seats = (totalSeatsCost && [
        {
            labelText: (
                <FormattedMessage
                    id='front-app/BookingFlightPage/StepsSummary/Seats/TotalSeats:label'
                    defaultMessage='Total seats'
                />
            ),
            priceText: `CHF ${localizeMoney(totalSeatsCost, locale, 'BookingFlightPage/StepsSummary/seats')}`,
        },
    ])
    || undefined;

    const totalCost = totalTicketsCost + totalSeatsCost + optionsTotalCost;
    const totalPriceText = `CHF ${localizeMoney(totalCost, locale, 'BookingFlightPage/StepsSummary/totalPriceText')}`;

    return {
        bookingOptions,
        flights,
        passengersTickets,
        seats,
        totalPriceText,
    };
};
