import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => {
    return state.stepReservationOverview || initialState;
};

export const selectLoading = createSelector(
    [selectDomain],
    state => state.loading,
);

export const selectFormData = createSelector(
    [selectDomain],
    state => state.formData,
);

export const selectCreatedReservationData = createSelector(
    [selectDomain],
    state => state.createdReservationData,
);

export const selectError = createSelector(
    [selectDomain],
    state => state.error,
);

export const selectErrorNotEnoughSeatsErrorCode = createSelector(
    [selectDomain],
    state => state.errorNotEnoughSeatsErrorCode,
);

export const selectErrorSeatsOccupiedErrorCode = createSelector(
    [selectDomain],
    state => state.errorSeatsOccupiedErrorCode,
);

export const selectIsFormValid = createSelector(
    [selectDomain],
    state => {
        const getFormValidationResults = (formData: any) => {
            return !!formData && formData.isAgree;
            // TODO switch on the schema
            // return coercionDataToSchema(
            //   'file://malesiareisen.com/json/schema/project/react-components/form/billingInformationForm.json',
            //   formData || {},
            // );
        };
        return getFormValidationResults(state.formData);
    },
);

export const selectIsDisabledSubmit = createSelector(
    [selectDomain, selectIsFormValid],
    (state, isFormValid) => {
        return state.loading || !isFormValid;
    },
);
