import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PassengerBasket } from '../../../../../utils/reservation/types';
import { selectReservationBasket } from '../../../../containers/ReservationBasket/reservationBasket.selectors';

// ToDo: Resolve this bug by any correct way.
type FixMeReservationPassenger = PassengerBasket & {
    firstName?: string,
    lastName?: string,
};

export const useStepRequirementsForStepBillingInformation = () => {
    const {
        passengers,
        selectedFlights,
        selectedPrices,
        travelType,
    } = useSelector(selectReservationBasket);

    // noinspection UnnecessaryLocalVariableJS
    const isStepFitRequirements: boolean = useMemo(() => {
        const stepRequiredReservationValues = [
            passengers,
            (passengers && passengers.length > 0) || undefined,
            // ToDo: Fix this line. May be remove? May be passenger.info.firstName?
            !(passengers && passengers.map((passenger: FixMeReservationPassenger) => (
                passenger.firstName && passenger.lastName
            )).some(item => item === undefined)),
            selectedFlights?.outbound,
            selectedPrices?.outbound,
            travelType,
        ];
        return !stepRequiredReservationValues.some(v => v === undefined);
    }, [passengers, selectedFlights?.outbound, selectedPrices?.outbound, travelType]);

    return isStepFitRequirements;
};
