import { getTopImage } from '../getTopImage';
import {
    BaggagePageDocument,
    BaggagePageQuery,
} from '../gql-types';
import { gqlClient } from '../gqlClient';

export async function loadBaggagePageContent(locale: string) {
    const { data } = await gqlClient.query<BaggagePageQuery>(
        BaggagePageDocument,
        { lang: locale },
    ).toPromise();
    return {
        topImage: getTopImage(data?.baggage?.top_image),
        content: data?.baggage?.translations?.[0]?.content ?? '',
    };
}
