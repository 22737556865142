import { getQueryParameters } from '../../../utils/uriUtils';

export type FlightListQueryParameters = {
    highlightFlightId?: number,
    highlightSequenceFlightId?: number,
};

export const useQueryParameters = () => {
    const {
        highlightFlightId,
        highlightSequenceFlightId,
    } = getQueryParameters<FlightListQueryParameters>({
        highlightFlightId: 'number',
        highlightSequenceFlightId: 'number',
    });

    return {
        highlightFlightId,
        highlightSequenceFlightId,
    };
};
