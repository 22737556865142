import { DateRange } from '@malesia/react-components/dist/src/utils/dateTime';

export const mapRequestInterval = (value?: DateRange): DateRange | undefined => {
    if (!value) return undefined;
    if (!value.from && !value.to) return undefined;
    const from = value.from ? value.from : undefined;
    const to = value.to ? value.to : undefined;
    return { from, to };
};
export const mapRequestArray = <T>(array?: T[]) => {
    if (!array || array.length === 0) return undefined;
    return array;
};
