import { PriceListItem, PublicFlightListItem } from '@malesia/json-schema';
import { FlightDirection } from '@malesia/react-components/dist/src/components/Reservation/reservation-types';

export type SelectedFlight = {
    flightId: number,
    tariffId: number,
};
export type SelectedFlights = {
    outbound?: SelectedFlight,
    return?: SelectedFlight,
};
export type SelectedFlightWithDirection = SelectedFlight & {
    flightDirection: FlightDirection,
};
export type FlightVariant = {
    flight: PublicFlightListItem,
    price: PriceListItem,
};
export type FlightVariants = {
    outbound?: FlightVariant,
    return?: FlightVariant,
};
export type BookedFlight = {
    flightId: number,
    tariffId: number,
    seatLabels: string[],
};
export type BookedFlights = {
    outbound?: BookedFlight,
    return?: BookedFlight,
};

const findFlight = (
    target?: SelectedFlight,
    list?: PublicFlightListItem[],
): FlightVariant | undefined => {
    const id = target?.flightId;
    if (!id) return undefined;
    const flight = list?.find(x => x.id === id);
    if (!flight) return undefined;
    const tariffId = target?.tariffId;
    if (!tariffId) return undefined;
    const price = flight.rate.priceList!.find(price => price.tariff?.id === tariffId);
    if (!price) return undefined;
    return { flight, price };
};

type FlightLists = {
    outbound: PublicFlightListItem[],
    return: PublicFlightListItem[],
};
export const findFlights = (
    targets: SelectedFlights,
    lists: FlightLists,
): FlightVariants => ({
    outbound: findFlight(targets.outbound, lists.outbound),
    ['return']: findFlight(targets.return, lists.return),
});
