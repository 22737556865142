import { call, put, select, takeLatest } from 'typed-redux-saga';
import { ApiResponseError } from '../../../api/malesia/ApiResponseError';
import JsonSchemaValidationError from '../../../api/malesia/JsonSchemaValidationError';
import { logError } from '../../../utils/log';
import { selectLocale } from '../../containers/App/selectors';
import { createCustomValidationError } from '../../containers/ValidationErrorMessage';
import { actions } from './slice';
import { apiClientRequest } from 'app/services/ApiClient';

export function* submitData(action: ReturnType<typeof actions.submitData>) {
    try {
        const updatePasswordRequestData = action.payload;
        const locale: string = yield* select(selectLocale);
        const requestData = {
            requestId: 'authUpdatePassword',
            requestPayload: updatePasswordRequestData,
            uriParams: { locale },
        };

        yield* call(apiClientRequest, requestData);
        yield* put(actions.submitSuccess());
    }
    catch (error) {
        logError({
            error,
            target: 'MalesiaUpdatePasswordPage.submitData',
        });
        if (error instanceof JsonSchemaValidationError) {
            console.error('[MALESIA ERROR]', error);
            yield* put(actions.submitValidationError([]));
        }
        if (error instanceof ApiResponseError) {
            // API returns 422 status code on invalid token
            if (error.status === 422) {
                // Add custom error
                yield* put(
                    actions.submitValidationError([
                        createCustomValidationError(
                            '/recoveryToken',
                            'custom/validToken',
                            'Recovery token expired or invalid',
                        ),
                    ]),
                );
            }
        }
        yield* put(actions.submitFailed());
    }
}

export function* submitDataSaga() {
    yield* takeLatest(actions.submitData, submitData);
}
