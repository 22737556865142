import { BackLink } from '@malesia/react-components/dist/src/components/BackLink';
import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { DesignSummary } from '@malesia/react-components/dist/src/components/Reservation/DesignSummary';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { selectReservationBasket } from '../../containers/ReservationBasket/reservationBasket.selectors';
import { PublicReservationBasket } from '../../containers/ReservationBasket/reservationBasket.types';
import { BookingStep, ReservationStepsConfig, StepName, stepsConfig } from './config/steps';
import { StepsBreadcrumbs } from './StepsBreadcrumbs';
import { StepsSummary } from './StepsSummary';
import { useInjectReducers } from './store/inject';
import { selectQueryApplied } from './store/selectors';
import { bookingFlightActions } from './store/slice';

export type RouteParams = {
    stepName: StepName,
};

export type BookingFlightPageProps = RouteComponentProps<RouteParams> & {
    isPageTitleHide?: boolean,
};

export const BookingFlightPage: SFC<BookingFlightPageProps, { result: 'optional' }> = (props) => {
    const { isPageTitleHide } = props;
    const { stepName } = props.match.params;

    useInjectReducers();
    const history = useHistory();
    const dispatch = useDispatch();
    const reservationBasket = useSelector(selectReservationBasket);
    const queryApplied = useSelector(selectQueryApplied);

    useEffect(() => {
        // Once on first load page
        dispatch(bookingFlightActions.applyQueryParameters(stepName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const nextStepConfig = useMemo(() => {
        const getNextStepConfig = (
            currentStepName: keyof ReservationStepsConfig,
            reservationBasket: Partial<PublicReservationBasket>,
        ): BookingStep | undefined => {
            if (!currentStepName || !stepsConfig[currentStepName]?.nextStep) {
                return undefined;
            }
            const { nextStep } = stepsConfig[currentStepName];
            if (!nextStep) {
                return undefined;
            }
            const nextStepName = typeof nextStep === 'function' ? nextStep(reservationBasket) : nextStep;
            return nextStepName ? stepsConfig[nextStepName] : undefined;
        };

        return getNextStepConfig(stepName, reservationBasket);
    }, [reservationBasket, stepName]);

    const onStepSubmit = useCallback(() => {
        if (nextStepConfig) {
            const nextStepPath = nextStepConfig.path;
            history.push(nextStepPath);
        }
        else {
            console.warn('FINAL STEP SUBMIT');
        }
    }, [history, nextStepConfig]);

    if (!stepsConfig[stepName]) {
        return null;
    }

    const { Component: StepComponent, previousStep } = stepsConfig[stepName];

    const previousStepName = typeof previousStep === 'function'
        ? previousStep(reservationBasket)
        : previousStep;

    const pageTitleText = (
        <FormattedMessage
            id='front-app/BookingFlightPage/Page:Title'
            defaultMessage='New Reservation'
        />
    );

    if (!stepName || !queryApplied) return null;

    return (
        <PageContentBlock modificators={stepsConfig[stepName].designModifiers}>
            <DesignSummary
                modifiersStep='no-padding'
                contentSlot={
                    <>
                        {!isPageTitleHide && <PageTitle text={pageTitleText} />}
                        <StepsBreadcrumbs stepName={stepName} />
                        <StepComponent
                            handleStepSubmit={onStepSubmit}
                            reservationData={reservationBasket}
                        />
                        {previousStepName && (
                            <BackLink
                                to={previousStepName ? stepsConfig[previousStepName].path : undefined}
                                modificators='reservation-bottom'
                            />
                        )}
                    </>
                }
                summarySlot={
                    stepsConfig[stepName]?.isStepsSummaryHidden ? undefined : (
                        <StepsSummary
                            isContentVisible={stepsConfig[stepName].stepNumber !== 1}
                        />
                    )
                }
            />
        </PageContentBlock>
    );
};
