import { FormReservationTravelDateType } from '@malesia/react-components/dist/src/components/Reservation/reservation-types';
import { StepTravelDate } from '@malesia/react-components/dist/src/components/Reservation/StepTravelDate';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPassengerList } from '../../../../../utils/reservation/passengerUtils';
import { selectReservationBasket } from '../../../../containers/ReservationBasket/reservationBasket.selectors';
import { reservationBasketActions } from '../../../../containers/ReservationBasket/reservationBasket.slice';
import { PublicReservationBasket } from '../../../../containers/ReservationBasket/reservationBasket.types';
import { selectAvailabilityAirportsMap, selectAirportList, selectDefaultMainAirport } from '../../../../containers/SharedData/selectors';
import { sharedActions } from '../../../../containers/SharedData/slice';

const defaultDateRangeIsoToField = <T extends Partial<PublicReservationBasket>>(input: T) => {
    const dateRange = input?.dateRange;
    if (dateRange && 'to' in dateRange) {
        const { to, ...dateRangeWithoutTo } = dateRange;
        return {
            ...input,
            dateRange: dateRangeWithoutTo,
        };
    }
    return input;
};

type StepTravelProps = {
    handleStepSubmit: () => void,
};
export const StepTravel: SFC<StepTravelProps, { result: 'optional' }> = (props) => {
    const { handleStepSubmit } = props;

    const reservationBasket = useSelector(selectReservationBasket);
    const defaultMainAirport = useSelector(selectDefaultMainAirport);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(reservationBasketActions.resetFlightData());
    }, [dispatch]);

    useEffect(() => {
        dispatch(sharedActions.getAirportList());
    }, [dispatch]);

    const airportListData = useSelector(selectAirportList);
    const airportAvailabilityMap = useSelector(selectAvailabilityAirportsMap);

    const convertFormReservationTravelDateToBasket = (data: Omit<FormReservationTravelDateType, 'passengersConfig'>): Partial<PublicReservationBasket> => {
        const { flightMainAirports, flightOtherAirports, ...otherData } = data;
        return {
            mainAirports: flightMainAirports,
            otherAirports: flightOtherAirports,
            ...otherData,
        } as Partial<PublicReservationBasket>;
    };

    const handleSubmit = (data: FormReservationTravelDateType) => {
        const { passengersConfig, ...travelData } = data;
        let basket = convertFormReservationTravelDateToBasket(travelData);

        if (basket.travelType === 'one-way' && 'dateRange' in basket) {
            basket = defaultDateRangeIsoToField(basket);
        }

        const stepData: Partial<PublicReservationBasket> = {
            ...basket,
        };

        stepData.passengers = createPassengerList(data.passengersConfig, reservationBasket.passengers);
        stepData.passengers.forEach(x => {
            x.options = {};
            x.seats = {};
        });
        stepData.passengersAges = data.passengersConfig;

        dispatch(reservationBasketActions.resetSelectedFlights(['outbound', 'return']));
        dispatch(reservationBasketActions.updateBasket(stepData));

        handleStepSubmit();
    };

    const defaultDateRange = {
        from: undefined,
        to: undefined,
    };

    const travelDateReservationData: FormReservationTravelDateType = {
        flightMainAirports: {
            from: reservationBasket.mainAirports.from ?? defaultMainAirport.from,
            to: reservationBasket.mainAirports.to ?? defaultMainAirport.to,
        },
        flightOtherAirports: {
            from: reservationBasket.otherAirports.from ?? defaultMainAirport.to,
            to: reservationBasket.otherAirports.to ?? defaultMainAirport.from,
        },
        dateRange: reservationBasket.dateRange ?? defaultDateRange,
        travelType: reservationBasket.travelType ?? 'return',
        passengersConfig: reservationBasket.passengersAges,
    };

    if (!airportListData || airportListData.length === 0) return null;
    return (
        <StepTravelDate
            airportList={airportListData}
            airportsAvailabilityMap={airportAvailabilityMap}
            onSubmit={handleSubmit}
            reservationData={travelDateReservationData}
        />
    );
};
