import { createSelector } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';
import { RootState } from '../../../../types';

const selectRoot = (state: RootState) => state;

export const selectOptionsSummary = createSelector(
    [selectRoot],
    (rootState) => {
        const optionsList = rootState.auxiliaryData?.option.list.items || [];
        const optionsMap = keyBy(optionsList, 'id');
        const { passengers } = rootState.reservationBasket;
        return Object.keys(optionsMap)
            .map(optionId => {
                const option = optionsMap[optionId];
                const count = passengers.reduce((acc, passenger) => {
                    if (passenger.options[optionId]) return acc + 1;
                    return acc;
                }, 0);
                return {
                    totalPrice: count * option.configValues.price,
                    count,
                    option,
                };
            })
            .filter(({ count }) => !!count);
    },
);

export const selectHashMapOptionsIdToOptions = createSelector(
    [selectRoot],
    rootState => {
        const optionsList = rootState.auxiliaryData?.option.list.items || [];
        return keyBy(optionsList, 'id');
    },
);
