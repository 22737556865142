import { AccountListItem, AccountPaginatedList, UserType } from '@malesia/json-schema';
import { call } from 'typed-redux-saga';
import { UserLoginAccount } from '../containers/UserLogin/types';
import { newTransactionPermissions } from '../permissions/adminPages/balance/newTransaction';
import { selectUserPermissions } from '../permissions/selectUserPermissions';
import { apiClientRequest } from './ApiClient';

export type AccountAssignedTypeItem = 'user' | 'virtual' | UserType;
export type VirtualAssignedTypeItem = 'datatrans' | 'ec' | 'bank' | 'cash';

export type GetAccountFreeListParams = {
    query?: string,
    accountAssignedType?: AccountAssignedTypeItem[],
    virtualAssignedType?: VirtualAssignedTypeItem[],
    isActive?: boolean,
    sortBy?: string,
    sortOrder?: string,
};
export function* getApiAccountFreeList(params: GetAccountFreeListParams) {
    const {
        isActive = true,
        sortBy = 'displayName',
        sortOrder = 'ASC',
        ...restParams
    } = params;

    const response: AccountPaginatedList = yield* apiClientRequest({
        requestId: 'accountFreeList',
        query: {
            ...restParams,
            isActive,
            sortBy,
            sortOrder,
        },
    });
    return response.items;
}

export function* getApiPermittedAccountFreeList(params: GetAccountFreeListParams) {
    const userPermissions = yield* selectUserPermissions();

    if (userPermissions.has(newTransactionPermissions.accountListAccountant)) {
        return yield* getApiAccountFreeList(params);
    }
    const hasValues = <T>(array?: T[]): array is T[] => Array.isArray(array) && array.length > 0;
    if (hasValues(params.accountAssignedType)) {
        params.accountAssignedType = params.accountAssignedType.filter(x => (
            (x !== 'manager' || userPermissions.has(newTransactionPermissions.accountManagerList))
            && (x !== 'virtual' || userPermissions.has(newTransactionPermissions.accountVirtualList))
        ));
        if (!hasValues(params.accountAssignedType)) {
            return [];
        }
    }
    if (!userPermissions.has(newTransactionPermissions.accountVirtualList)) {
        params.virtualAssignedType = undefined;
    }
    return yield* getApiAccountFreeList(params);
}

export function* getApiUserAccount(userId: number) {
    const result: UserLoginAccount = yield* call(apiClientRequest, {
        requestId: 'userAccount',
        uriParams: {
            id: userId,
        },
    });
    return result;
}

export function* getApiUserAccountListItem(userId: number) {
    const account = yield* getApiUserAccount(userId);
    const result: AccountListItem = {
        id: account?.id ?? 0,
        balance: account?.balance ?? 0,
        debitOnBalance: account.debitOnBalance,
        type: account.type,
        user: {
            id: account.user?.id!,
            displayName: account.user?.displayName!,
            email: account.user?.email!,
            type: account.user?.type!,
            birthday: account.user?.birthday,
        },
    };
    return result;
}
