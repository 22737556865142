import { NewTransactionAdvancedFormType } from '@malesia/react-components/dist/src/components/Accounting/NewTransaction/NewTransactionAdvanced';
import { NewTransactionPreset, NewTransactionPresetProps } from '@malesia/react-components/dist/src/components/Accounting/NewTransaction/NewTransactionPreset';
import { getAccountListItemDisplayName } from '@malesia/react-components/dist/src/components/Form/Field/SearchAccount/utils';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetAccountFreeListParams } from '../../../services/account';
import { selectPaymentTypeList } from '../store/selectors';
import { newTransactionActions } from '../store/slice';
import { useTabInfo } from '../useTabInfo';

const debitRequest: GetAccountFreeListParams = {
    accountAssignedType: ['virtual'],
    virtualAssignedType: ['bank'],
};
const creditRequest: GetAccountFreeListParams = {
    accountAssignedType: ['manager', 'user'],
};

export const DepositsTab: SFC = () => {
    const dispatch = useDispatch();

    const { validateForm, config } = useTabInfo({
        debitRequest,
        creditRequest,
    });

    const paymentTypeList = useSelector(selectPaymentTypeList);
    const initValues = useMemo((): Partial<NewTransactionAdvancedFormType> => ({
        category: paymentTypeList.find(x => x.value === 'settlement'),
    }), [paymentTypeList]);

    const submit = useCallback((data: NewTransactionAdvancedFormType) => {
        const debitName = getAccountListItemDisplayName(data.debit);
        const creditName = getAccountListItemDisplayName(data.credit);
        dispatch(newTransactionActions.submitAdvancedForm({
            ...data,
            description: `Deposit of ${data.amount} CHF from ${debitName} on ${creditName}. ${data.description}`,
        }));
    }, [dispatch]);

    const newTransactionPresetProps: NewTransactionPresetProps = {
        config,
        validateForm,
        propsValues: initValues,
        submit,
    };
    return (
        <NewTransactionPreset {...newTransactionPresetProps} />
    );
};
