import { getQueryParameters } from '../../../utils/uriUtils';

export type PassengerListQueryParameters = {
    highlightReservationId?: number,
};

export const useQueryParameters = () => {
    const {
        highlightReservationId,
    } = getQueryParameters<PassengerListQueryParameters>({
        highlightReservationId: 'number',
    });

    return {
        highlightReservationId,
    };
};
