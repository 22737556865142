import { PanelTabs } from '@malesia/react-components/dist/src/components/Form/PanelTabs';
import { LoadingOverlay } from '@malesia/react-components/dist/src/components/LoadingOverlay';
import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectCashbackPdf } from '../../containers/CashbackPdf/inject';
import { useInjectAccountingNewTransaction } from './store/inject';
import { selectIsSubmitting } from './store/selectors';
import { newTransactionActions } from './store/slice';
import { useNewTransactionTabs } from './tabs';

const className = 'accounting-new-transaction-page';

export const AccountingNewTransactionPage: SFC = () => {
    useInjectCashbackPdf();
    useInjectAccountingNewTransaction();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(newTransactionActions.loadPaymentTypeList());
    }, [dispatch]);

    const isSubmitting = useSelector(selectIsSubmitting);

    const tabs = useNewTransactionTabs();
    const [selectedTabId, setSelectedTabId] = useState(tabs[0]?.id);

    return (
        <div className={className}>
            <PageContentBlock>
                <PageTitle
                    text={
                        <FormattedMessage
                            id='front-app/Routes/NewTransaction:title'
                            defaultMessage='New Transaction'
                        />
                    }
                />
                <LoadingOverlay
                    isLoading={isSubmitting}
                    loadingMessage={<></>}
                >
                    <PanelTabs
                        tabs={tabs}
                        selectedTabId={selectedTabId}
                        selectTab={setSelectedTabId}
                    />
                </LoadingOverlay>
                {/* ToDo: Hack for dropdown */}
                <div style={{ height: '250px' }} />
            </PageContentBlock>
        </div>
    );
};
