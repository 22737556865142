import { ApiCallBalanceLogFiltersWithSelectedAccount } from '@malesia/json-schema';
import { put, select, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../../utils/log';
import { getApiErrorMessage } from '../../../services/ApiClient';
import { downloadApiBalanceLogOverviewPdf } from '../../../services/balanceLog';
import { balanceLogNotifications } from './notifications';
import { selectFilterData, selectSorting } from './selectors';
import { balanceLogActions } from './slice';

function* downloadOverviewPdf() {
    try {
        const filterData = yield* select(selectFilterData);
        const sort = yield* select(selectSorting);
        if (!filterData.account?.id) {
            yield* put(balanceLogNotifications.notSelectedAccount);
            yield* put(balanceLogActions.downloadOverviewPdfFail());
            return;
        }

        const params: ApiCallBalanceLogFiltersWithSelectedAccount = {
            selectedAccountId: filterData.account.id,
            query: filterData.query || undefined,
            relatedAccountId: filterData.relatedAccount?.id ? [filterData.relatedAccount.id] : undefined,
            typeId: filterData.categories?.map(x => x.id),
            dateFrom: filterData.date?.from,
            dateTo: filterData.date?.to,
            sortBy: sort.sortBy,
            sortOrder: sort.sortOrder,
        };
        yield* downloadApiBalanceLogOverviewPdf(params);
        yield* put(balanceLogActions.downloadOverviewPdfSuccess());
    }
    catch (error) {
        logError({
            error,
            target: 'BalanceLogPage.downloadOverviewPdf',
        });
        const message = getApiErrorMessage(error);
        yield* put(balanceLogNotifications.unknownError(message));
        yield* put(balanceLogActions.downloadOverviewPdfFail());
    }
}

export function* downloadOverviewPdfSaga() {
    yield* takeLatest(balanceLogActions.downloadOverviewPdf, downloadOverviewPdf);
}
