import { LoadingPage } from '@malesia/react-components/dist/src/components/LoadingOverlay/Page';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReservationForm } from './ReservationForm';
import { useInjectReservationPageStore } from './store/inject';
import { selectIsReservationLoading } from './store/selectors';
import { reservationNewPageActions } from './store/slice';

export const ReservationNewPage = function ReservationNewPage() {
    useInjectReservationPageStore();
    const isReservationLoading = useSelector(selectIsReservationLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(reservationNewPageActions.loadNewReservation());
    }, [dispatch]);

    return (
        isReservationLoading ? <LoadingPage isLoading /> : <ReservationForm />
    );
};
