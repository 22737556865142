import { Country, ManagerPostRequest, ManagerPutRequest } from '@malesia/json-schema';
import { ManagerFormType } from '@malesia/react-components/dist/src/components/Manager/Edit';
import { call } from 'typed-redux-saga';
import { apiClientRequest } from '../ApiClient';

export function mapManagerToPostRequest(data: ManagerFormType): ManagerPostRequest {
    return {
        email: data.email,
        language: data.language as unknown as string,
        password: data.password as unknown as string,
        displayName: data.displayName,
        country: data.country as unknown as Country,
        creditLimitTotalAmount: data.creditLimitTotalAmount ? Number(data.creditLimitTotalAmount) : undefined,
        isActive: data.isActive,
    };
}

export function mapManagerToPutRequest(data: ManagerFormType): ManagerPutRequest {
    return {
        email: data.email,
        language: data.language as unknown as string,
        ...data.password ? { password: data.password } : {},
        displayName: data.displayName,
        country: data.country as unknown as Country,
        creditLimitTotalAmount: data.creditLimitTotalAmount ? Number(data.creditLimitTotalAmount) : undefined,
        isActive: data.isActive,
    };
}

function* updateManager(managerData: ManagerPutRequest) {
    const requestData = {
        requestId: 'managerDataUpdate',
        uriParams: { id: managerData.id! },
        requestPayload: managerData,
    };
    const response = yield* call(apiClientRequest, requestData);
    return response;
}

export const managerService = {
    updateManager,
};
