import { FlightOverviewItem, FlightColumn } from '@malesia/react-components/dist/src/components/FlightsOverview';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { FlightOverviewList } from '../../containers/FlightOverview/types';
import { allPublicRoutePaths } from '../../containers/Routes/allPublicRoutePaths';
import { createBookingLink } from '../../pages/BookingFlightPage/store/queryParameters';

export const useFlightOverviewCardWithClickHandler = (flights: FlightOverviewList[]) => {
    const history = useHistory();

    const flightsWithCardClickHandler: FlightColumn[] = useMemo(() => {
        return flights.map(flightColumn => {
            const cardWithHandlerList: FlightOverviewItem[] = flightColumn.items.map(flight => {
                return {
                    value: flight,
                    onClick: () => {
                        const url = createBookingLink(allPublicRoutePaths.bookingFlightTravelDates, {
                            travelType: 'return',
                            departureDate: flight.departure,
                            airportFrom: flight.origin.code,
                            airportTo: flight.destination.code,
                        });
                        history.push(url);
                    },
                };
            });
            return {
                ...flightColumn,
                items: cardWithHandlerList,
            };
        });
    }, [flights, history]);

    return flightsWithCardClickHandler;
};
