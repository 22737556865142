import { NotifyingSettings } from '@malesia/react-components/dist/src/components/NotifyingSettings';
import { PopupConfirm } from '@malesia/react-components/dist/src/components/Popup/Confirm';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectNotifying, selectShowNotifyingPopup } from '../store/selectors';
import { actions } from '../store/slice';

export const NotifyingConfirmPopup = () => {
    const dispatch = useDispatch();

    const showNotifyingPopup = useSelector(selectShowNotifyingPopup);
    const notifying = useSelector(selectNotifying);

    const onNotifyingChange = data => {
        dispatch(actions.updateNotifying(data));
    };

    return (
        <PopupConfirm
            isOpened={showNotifyingPopup}
            onClose={() => dispatch(actions.askNotifyingCancel())}
            onConfirm={() => dispatch(actions.askNotifyingConfirm())}
            confirmMessage={
                <FormattedMessage
                    id='front-app/FlightEditPage:ConfirmNotifying'
                    defaultMessage='Would you like to inform your passengers about this change?'
                />
            }
        >
            <NotifyingSettings
                notifying={notifying}
                onChange={onNotifyingChange}
            />
        </PopupConfirm>
    );
};
