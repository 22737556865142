import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.reservationSamePassengers || initialState;

export const selectIsLoading = createSelector(
    [selectDomain],
    (state) => state.isLoading,
);

export const selectIsOpen = createSelector(
    [selectDomain],
    (state) => state.isOpen,
);

export const selectSamePassengers = createSelector(
    [selectDomain],
    (state) => state.samePassengers,
);
