import { defineMessages } from 'react-intl';

export const messages = defineMessages({
    title: {
        id: 'front-app/NotFoundPage:title',
        defaultMessage: 'Not Found',
    },
    description: {
        id: 'front-app/NotFoundPage:description',
        defaultMessage: 'Page is not found.',
    },
    header: {
        id: 'front-app/NotFoundPage:header',
        defaultMessage: 'Page is not found.',
    },
});
