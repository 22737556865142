import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import {
    FlightPlanColumn,
    MalesiaFlightPageState,
} from './types';

export const initialState: MalesiaFlightPageState = {
    loading: false,
    flightList: [],
};
export const malesiaFlightPageSlice = createSlice({
    name: 'malesiaFlightPage',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        getFlightList(state) {
        },
        getFlightListSuccess(state, action: PayloadAction<FlightPlanColumn[]>) {
            state.flightList = action.payload;
        },
    },
});

export const { actions, reducer, name: sliceKey } = malesiaFlightPageSlice;
