import { RouteItem } from '@malesia/react-components/dist/src/components/types';
import { AdminRoutePath } from '../containers/Routes/allAdminRoutePaths';
import { getAdminRoutePermission } from './adminRoutes';
import { useUserPermissions } from './useUserPermissions';

export const useFilteredAdminRoutesByPermissions = (
    routeItems: RouteItem[],
): RouteItem[] => {
    const userPermissions = useUserPermissions();
    const filteredRouteItems = routeItems.filter(route => {
        const linkRoute = route.linkRoute as AdminRoutePath;
        const permission = getAdminRoutePermission(() => linkRoute);
        const result = userPermissions.has(permission);
        return result;
    });
    return filteredRouteItems;
};
