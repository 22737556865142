import { MultiLanguage } from '@malesia/json-schema';
import { PaymentFormData } from '@malesia/react-components/dist/src/components/Payment/Form';
import { getAvailableAccountMoneyForPayment, getPaymentTransferTotal } from '@malesia/react-components/dist/src/components/Payment/Form/utils';
import { PriceItemType } from '@malesia/react-components/dist/src/components/Reservation/Summary/Info/PriceBlock';
import { AgeType } from '@malesia/react-components/dist/src/utils/ageType';
import { roundMoney, localizeMoney } from '@malesia/react-components/dist/src/utils/roundMoney';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../types';
import { Selector } from '../../../../types/Selector';
import { getLocalizedString } from '../../../../utils/getLoclizedString';
import { createSummaryFlights, SummaryFlightVariants } from '../../../../utils/reservation/summaryFlightCombiner';
import { selectLocale } from '../../../containers/App/selectors';
import { selectNativeUserPermissions } from '../../../containers/UserLogin/selectors';
import { PassengerConfig } from '../../../services/flight';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.reservationPaymentPage || initialState;

export const selectReservationLoading = createSelector(
    [selectDomain],
    root => root.reservation.loading,
);

export const selectReservation = createSelector(
    [selectDomain],
    root => root.reservation.data!,
);

export const selectPaymentLoading = createSelector(
    [selectDomain],
    root => root.payment.loading,
);

export const selectOwner = createSelector(
    [selectDomain],
    root => root.ownerAccount,
);

export const selectOwnerAvailableAccountMoney = createSelector(
    [selectOwner],
    (owner) => getAvailableAccountMoneyForPayment({
        balance: owner?.debitOnBalance ?? 0,
        creditLimit: owner?.creditLimit,
        allWithheldMoney: owner?.allWithheldMoney,
    }),
);

export const selectPaymentData = createSelector(
    [selectDomain, selectOwnerAvailableAccountMoney],
    (root, availableAccountMoney): PaymentFormData => ({
        ...root.payment.data,
        account: availableAccountMoney,
    }),
);

export const selectCashAccounts = createSelector(
    [selectDomain],
    root => root.cashAccounts.items,
);

export const selectBankAccounts = createSelector(
    [selectDomain],
    root => root.bankAccounts.items,
);

export const selectDatatransAccounts = createSelector(
    [selectDomain],
    root => root.datatransAccounts.items,
);

export const selectTransferAccounts = createSelector(
    [selectDomain],
    root => root.transferAccounts.items,
);

export const selectTotalPrice = createSelector(
    [selectReservation],
    (reservation) => {
        return reservation.invoice?.total ?? 0;
    },
);

export const selectPriceToPay = createSelector(
    [selectReservation],
    (reservation) => {
        return roundMoney((reservation.invoice?.total ?? 0) - (reservation.invoice?.totalPaid ?? 0));
    },
);

export const selectTransferTotal = createSelector(
    [selectOwner, selectPriceToPay, selectPaymentData],
    (owner, priceToPay, data) => getPaymentTransferTotal({
        priceToPay,
        balance: owner?.debitOnBalance ?? 0,
        creditLimit: owner?.creditLimit,
        allWithheldMoney: owner?.allWithheldMoney,
        partialPayment: data.partialPayment,
        payByAccount: data.paymentMethod === 'account',
    }),
);

export const selectSelectedFlightVariants = createSelector(
    [selectReservation],
    ({ flights }): SummaryFlightVariants => ({
        outbound: !flights[0] ? undefined : {
            flight: flights[0].flight,
            price: { tariff: flights[0].tariff },
        },
        ['return']: !flights[1] ? undefined : {
            flight: flights[1].flight,
            price: { tariff: flights[1].tariff },
        },
    }) as SummaryFlightVariants,
);

export const selectSummaryFlights = createSelector(
    [selectSelectedFlightVariants, selectLocale, selectNativeUserPermissions],
    (selectedFlights, locale, userPermissions) => (
        createSummaryFlights('public', selectedFlights, locale, userPermissions)
    ),
);

export const selectTotalSeatsCost: Selector<number | undefined> = createSelector(
    [selectReservation],
    (reservation) => reservation.invoice?.passengers.reduce((total, passenger) => {
        const costForPassenger = passenger.flights.reduce((cost, flight) => {
            return cost + flight.seatPrice;
        }, 0);
        return total + costForPassenger;
    }, 0),
);

export const selectTotalSeatsCount: Selector<number | undefined> = createSelector(
    [selectReservation],
    reservation => {
        return reservation.seats?.length;
    },
);

type Option = { code: string, configValues: { title: MultiLanguage } };

// todo add test
export const selectSummaryOptions: Selector<PriceItemType[]> = createSelector(
    [selectReservation, selectLocale],
    (reservation, locale) => {
        const bookedOptions: any[] = [];
        for (const passenger of reservation.invoice?.passengers) {
            bookedOptions.push(passenger.options);
        }
        const allOptions: { option: Option, price: number }[] = bookedOptions.flat();

        const optionsMap: Record<string, { count: number, option: Option, price: number }> = {};
        for (const option of allOptions) {
            if (!optionsMap[option.option.code]) {
                optionsMap[option.option.code] = {
                    count: 1,
                    option: option.option,
                    price: option.price,
                };
            }
            else {
                optionsMap[option.option.code].count++;
            }
        }

        return Object.values(optionsMap).map(row => ({
            labelText: `${row.count}x ${getLocalizedString(row.option.configValues.title, locale)}`,
            priceText: `CHF ${localizeMoney(row.count * row.price, locale, 'ReservationPaymentPage/reservationPayment.selectors/selectSummaryOptions')}`,
        }));
    },
);

// todo add test
export const selectTicketsPriceByAge: Selector<PriceItemType[]> = createSelector(
    [selectReservation, selectLocale],
    (reservation, locale) => {
        const totalByPassengers = reservation.invoice?.passengers.map(passenger => passenger.total);
        const passengerConfigs = reservation.passengers.reduce<PassengerConfig[]>((acc, passenger) => {
            return [...acc, { ageType: passenger.ageType }];
        }, []);
        const ageTypeWithPrice = passengerConfigs.map(
            (ageType, index) => ({ ...ageType, price: totalByPassengers[index] }),
        );

        const countAgeType: Record<string, { ageType: AgeType, count: number, price: number }> = {};
        for (const item of ageTypeWithPrice) {
            const row = countAgeType[item.ageType];
            if (!!row) {
                row.count++;
                row.price += item.price;
            }
            else {
                countAgeType[item.ageType] = {
                    ageType: item.ageType,
                    count: 1,
                    price: item.price,
                };
            }
        }

        return Object.values(countAgeType).map(item => {
            //todo add translations
            // const ageText = passengerAgeType[item.ageType]({ count: item.count });
            return {
                labelText: `${item.ageType} x ${item.count}`,
                priceText: `CHF ${localizeMoney(item.price, locale, 'ReservationPaymentPage/reservationPayment.selectors/selectSummaryOptions')}`,
            };
        });
    },
);
