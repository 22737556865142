import { AgentEdit, AgentFormType } from '@malesia/react-components/dist/src/components/Agent/Edit';
import { BackLink } from '@malesia/react-components/dist/src/components/BackLink';
import { LoadingPage } from '@malesia/react-components/dist/src/components/LoadingOverlay/Page';
import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { filterDisabledData } from '@malesia/react-components/dist/src/hooks/useForm';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { createCustomPasswordConfirmValidator } from '../../../utils/validation/createCustomPasswordConfirmValidator';
import { createCustomRoundValidator } from '../../../utils/validation/createCustomRoundValidator';
import { getValidationFunction } from '../../../utils/validation/getValidationFunction';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { useInjectSharedData } from '../../containers/SharedData/inject';
import { selectAdminCountryList, selectAdminCountryLoading } from '../../containers/SharedData/selectors';
import { sharedActions } from '../../containers/SharedData/slice';
import { useAgentFormConfig } from './config';
import { getAgentDataSaga, updateAgentDataSaga } from './saga';
import { selectAgentData, selectLoadingAgent } from './selectors';
import { actions, reducer, sliceKey } from './slice';

type RouteParams = {
    agentId: string,
};

type Props = RouteComponentProps<RouteParams>;

export const AgentEditPage: SFC<Props> = (props) => {
    useInjectSharedData();
    useInjectReducer({ actions, key: sliceKey, reducer });
    useInjectSaga({
        key: `${sliceKey}GetAgentData`,
        saga: getAgentDataSaga,
    });
    useInjectSaga({
        key: `${sliceKey}UpdateAgentData`,
        saga: updateAgentDataSaga,
    });

    const agentData = useSelector(selectAgentData);
    const countryData = useSelector(selectAdminCountryList);
    const loadingAgent = useSelector(selectLoadingAgent);
    const loadingCountries = useSelector(selectAdminCountryLoading);
    const isAnythingLoading = loadingAgent || loadingCountries;

    const dispatch = useDispatch();

    const { match } = props;
    const { params } = match;
    const { agentId } = params;

    useEffect(() => {
        if (!agentId) return;
        dispatch(actions.getAgentData(agentId));
    }, [agentId, dispatch]);

    useEffect(() => {
        dispatch(sharedActions.getAdminCountryList());
    }, [dispatch]);

    const formConfig = useAgentFormConfig(agentData);

    const onSubmitForm = (data: AgentFormType) => {
        if (!agentId) return;
        dispatch(actions.updateAgentData({
            agentId,
            agentData: filterDisabledData(data, formConfig),
        }));
    };

    const validationFn = useMemo(() => (
        getValidationFunction(
            'file://malesiareisen.com/json/schema/project/react-front-app/pages/AgentEditPage/agentEditForm.json',
            [
                createCustomRoundValidator<AgentFormType>(['/monthlyFee', '/creditLimitTotalAmount', '/moneyMargin']),
                createCustomPasswordConfirmValidator<AgentFormType>('/password', '/passwordConfirm'),
            ],
        )
    ), []);

    return (
        <PageContentBlock>
            <PageTitle
                text={
                    <FormattedMessage
                        id='front-app/AgentEditPage:EditAgent'
                        defaultMessage='Edit Agent'
                    />
                }
            />
            <BackLink
                to={allAdminRoutePaths.userAgentList}
                modificators='stick-to-title'
            />
            <LoadingPage isLoading={isAnythingLoading} />
            {agentData && !isAnythingLoading && (
                <AgentEdit
                    countryList={countryData.items}
                    formConfig={formConfig}
                    validator={validationFn}
                    onSubmit={onSubmitForm}
                />
            )}
        </PageContentBlock>
    );
};
