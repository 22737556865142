import de from './de.json';
import en from './en.json';
import fr from './fr.json';
import sq from './sq.json';

export default {
    de,
    en,
    fr,
    sq,
};
