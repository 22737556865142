import { put, takeEvery } from 'typed-redux-saga';
import { downloadNamedFile } from '../../../utils/downloadFile';
import { logError } from '../../../utils/log';
import { getApiPermittedAllotmentInvoicePdf } from '../../services/allotmentInvoice';
import { getApiErrorMessage } from '../../services/ApiClient';
import { allotmentInvoicePdfNotifications } from './notifications';
import { allotmentInvoicePdfActions } from './slice';

function* downloadAllotmentInvoicePdf(
    action: ReturnType<typeof allotmentInvoicePdfActions.downloadAllotmentInvoicePdf>,
) {
    const invoiceId = action.payload.id;
    try {
        const response = yield* getApiPermittedAllotmentInvoicePdf(invoiceId);
        yield* downloadNamedFile(response, 'AllotmentInvoice.pdf');
        yield* put(allotmentInvoicePdfActions.downloadAllotmentInvoicePdfSuccess(invoiceId));
    }
    catch (error) {
        logError({
            error,
            target: 'AllotmentInvoicePdf.downloadAllotmentInvoice',
        });
        yield* put(allotmentInvoicePdfActions.downloadAllotmentInvoicePdfFail(invoiceId));
        const message = getApiErrorMessage(error);
        yield* put(allotmentInvoicePdfNotifications.unknownError(message));
    }
    finally {
        action.payload.callback();
    }
}

export function* downloadAllotmentInvoicePdfSaga() {
    yield* takeEvery(allotmentInvoicePdfActions.downloadAllotmentInvoicePdf, downloadAllotmentInvoicePdf);
}
