import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'typed-redux-saga';
import { getDatatransSearchParams } from '../../../../utils/datatrans/getDatatransSearchParams';
import { logError } from '../../../../utils/log';
import { datatransService } from '../../../services/Datatrance/datatrans.service';
import { paymentNotifications } from '../../ReservationPaymentPage/store/notification';
import { datatransErrorPageActions } from './datatransError.slice';

function* onLoadSuccessPage() {
    const { transactionId } = getDatatransSearchParams();

    if (!transactionId) {
        yield* put(paymentNotifications.notFoundTransaction);
        yield* put(push('/'));
        return;
    }

    try {
        yield* call(datatransService.paymentDatatransReservationConfirm, { transactionId });
        yield* put(paymentNotifications.datatransSuccess);
    }
    catch (error) {
        logError({
            error,
            target: 'MalesiaDatatransErrorPage.onLoadSuccessPage',
        });
        yield* put(paymentNotifications.notFoundTransaction);
    }
    finally {
        yield* put(push('/'));
    }
}

export function* onLoadSuccessPageSaga() {
    yield* takeLatest(datatransErrorPageActions.updateTransactionStatus, onLoadSuccessPage);
}
