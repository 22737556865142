import { NewTransactionAdvancedFormType } from '@malesia/react-components/dist/src/components/Accounting/NewTransaction/NewTransactionAdvanced';
import { NewTransactionPreset, NewTransactionPresetProps } from '@malesia/react-components/dist/src/components/Accounting/NewTransaction/NewTransactionPreset';
import { getAccountListItemDisplayName } from '@malesia/react-components/dist/src/components/Form/Field/SearchAccount/utils';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetAccountFreeListParams } from '../../../services/account';
import { selectPaymentTypeList } from '../store/selectors';
import { newTransactionActions } from '../store/slice';
import { useTabInfo } from '../useTabInfo';

const debitRequest: GetAccountFreeListParams = {
    accountAssignedType: ['agent', 'customer'],
};
const creditRequest: GetAccountFreeListParams = {
};

export const CashbackTab: SFC = () => {
    const dispatch = useDispatch();

    const { myAccount, validateForm, config } = useTabInfo({
        debitRequest,
        creditRequest,
        creditDisabled: true,
    });

    const paymentTypeList = useSelector(selectPaymentTypeList);
    const initValues = useMemo((): Partial<NewTransactionAdvancedFormType> => ({
        credit: myAccount,
        category: paymentTypeList.find(x => x.value === 'cashback'),
    }), [myAccount, paymentTypeList]);

    const submit = useCallback((data: NewTransactionAdvancedFormType) => {
        const debitName = getAccountListItemDisplayName(data.debit);
        const creditName = getAccountListItemDisplayName(data.credit);
        dispatch(newTransactionActions.submitAdvancedForm({
            ...data,
            description: `${data.amount} CHF cashback for ${debitName} (${data.debit?.id}) by ${creditName}. ${data.description}`,
        }));
    }, [dispatch]);

    const newTransactionPresetProps: NewTransactionPresetProps = {
        config,
        validateForm,
        propsValues: initValues,
        submit,
    };
    return (
        <NewTransactionPreset {...newTransactionPresetProps} />
    );
};
