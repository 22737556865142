import { BalanceLogRecord } from '@malesia/json-schema';
import { createActionsColumn } from '@malesia/react-components/dist/src/components/Table/columns/actionsColumn';
import * as layouts from './actions';

export const balanceLogActionsColumn = createActionsColumn<BalanceLogRecord, typeof layouts>({
    columnsNumber: 2,
    rowsNumber: 1,
    layouts,
    order: [
        'cashbackPdf',
    ],
});
export type BalanceLogActionsConfig = Parameters<typeof balanceLogActionsColumn>[0];
