import { select, call } from 'typed-redux-saga';
import { selectPermissionsData } from '../containers/UserLogin/selectors';
import { createUserPermissions } from './userPermissions';

function* selectUserPermissionsWithoutCall() {
    const data = yield* select(selectPermissionsData);
    const { userRoles, userType, userId } = data;
    const userPermissions = createUserPermissions(userRoles, userType, userId);
    return userPermissions;
}

export function* selectUserPermissions() {
    return yield* call(selectUserPermissionsWithoutCall);
}
