import { PageTabs } from '@malesia/react-components/dist/src/components/Page/Tabs';
import { RouteItem } from '@malesia/react-components/dist/src/components/types';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Link, Redirect,
    Route,
    RouteComponentProps,
    Switch,
} from 'react-router-dom';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { useTabsState } from '../../containers/Routes/tabsState/useTabsState';
import { useFilteredAdminRoutesByPermissions } from '../../permissions/useFilteredAdminRoutesByPermissions';
import { AccountingNewTransactionPage } from '../AccountingNewTransactionPage';
import { BalanceLogPage } from '../BalanceLogPage';
import { NotFoundPage } from '../NotFoundPage';

const InvoiceRouterPage = ({ location }: RouteComponentProps) => {

    const { pathname } = location;

    const isRouteMatch = linkRoute => pathname === linkRoute;

    const invoiceRoutes: RouteItem[] = useFilteredAdminRoutesByPermissions([
        {
            routeKey: 'balanceLog',
            linkRoute: allAdminRoutePaths.balanceLog,
            LinkComponent: (props: React.PropsWithChildren<any>) => (
                <Link to={allAdminRoutePaths.balanceLog}>
                    {React.Children.only(props.children)}
                </Link>
            ),
            linkSubject: (
                <FormattedMessage
                    id='front-app/Routes/BalanceLog:Title'
                    defaultMessage='Balance log'
                />
            ),
        },
        {
            routeKey: 'newTransaction',
            linkRoute: allAdminRoutePaths.newTransaction,
            LinkComponent: (props: React.PropsWithChildren<any>) => (
                <Link to={allAdminRoutePaths.newTransaction}>
                    {React.Children.only(props.children)}
                </Link>
            ),
            linkSubject: (
                <FormattedMessage
                    id='front-app/Routes/NewTransaction:title'
                    defaultMessage='New Transaction'
                />
            ),
        },
    ]);

    const { firstRoute } = useTabsState(allAdminRoutePaths.invoice, invoiceRoutes);

    return (
        <>
            <PageTabs
                isRouteMatch={isRouteMatch}
                routeItems={invoiceRoutes}
            >
                <Switch>
                    <Route
                        exact
                        path={allAdminRoutePaths.balanceLog}
                        component={BalanceLogPage}
                    />
                    <Route
                        exact
                        path={allAdminRoutePaths.newTransaction}
                        component={AccountingNewTransactionPage}
                    />
                    <Route
                        exact
                        path={allAdminRoutePaths.invoice}
                    >
                        <Redirect to={firstRoute} />
                    </Route>
                    <Route component={NotFoundPage} />
                </Switch>
            </PageTabs>
        </>
    );
};

const Memoized = memo(InvoiceRouterPage);
export {
    Memoized as InvoiceRouterPage,
};
