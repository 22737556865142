import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { ManagerListFooterWithStore } from './components/ManagerListFooterWithStore';
import { ManagerListHeaderWithStore } from './components/ManagerListHeaderWithStore';
import { ManagerListWithStore } from './components/ManagerListWithStore';
import { getManagerListSaga } from './saga';
import { actions, reducer, sliceKey } from './slice';

export const ManagerListPage: SFC = () => {
    useInjectReducer({ actions, key: sliceKey, reducer });
    useInjectSaga({ key: sliceKey, saga: getManagerListSaga });

    return (
        <PageContentBlock>
            <ManagerListHeaderWithStore />
            <ManagerListWithStore />
            <ManagerListFooterWithStore />
        </PageContentBlock>
    );
};
