import { NewTransactionAdvancedFormType } from '@malesia/react-components/dist/src/components/Accounting/NewTransaction/NewTransactionAdvanced';
import { ValidationFn } from '@malesia/react-components/dist/src/components/ValidationErrors';
import { FormConfig } from '@malesia/react-components/dist/src/hooks/useForm';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getValidationFunction } from '../../../utils/validation/getValidationFunction';
import { selectMyAccountToListItem } from '../../containers/UserLogin/selectors';
import { GetAccountFreeListParams } from '../../services/account';
import { selectCreditAccountList, selectDebitAccountList, selectPaymentTypeList } from './store/selectors';
import { newTransactionActions } from './store/slice';

export type UseTabInfoOptions = {
    debitRequest: GetAccountFreeListParams,
    creditRequest: GetAccountFreeListParams,
    debitWithMyAccount?: boolean,
    creditWithMyAccount?: boolean,
    debitDisabled?: boolean,
    creditDisabled?: boolean,
};
export const useTabInfo = (options: UseTabInfoOptions) => {
    const {
        debitRequest, creditRequest,
        debitWithMyAccount, creditWithMyAccount,
        debitDisabled, creditDisabled,
    } = options;

    const dispatch = useDispatch();

    const debitAccountList = useSelector(selectDebitAccountList);
    const creditAccountList = useSelector(selectCreditAccountList);
    const paymentTypeList = useSelector(selectPaymentTypeList);
    const myAccount = useSelector(selectMyAccountToListItem);

    const validateForm = useMemo((): ValidationFn<NewTransactionAdvancedFormType> => {
        const v = getValidationFunction(
            'file://malesiareisen.com/json/schema/project/react-front-app/pages/AccountingNewTransactionPage/newTransactionAdvancedForm.json',
        );
        return (data) => v({
            ...data,
            category: paymentTypeList.find(type => type.value === data.category?.value)?.id!,
        });
    }, [paymentTypeList]);

    useEffect(() => {
        return () => {
            dispatch(newTransactionActions.resetDebitAndCreditList());
        };
    }, [dispatch]);

    useEffect(() => {
        if (debitDisabled) return;
        dispatch(newTransactionActions.searchDebitAccount({
            ...debitRequest,
            query: '',
        }));
    }, [dispatch, debitRequest, debitDisabled]);
    useEffect(() => {
        if (creditDisabled) return;
        dispatch(newTransactionActions.searchCreditAccount({
            ...creditRequest,
            query: '',
        }));
    }, [dispatch, creditRequest, creditDisabled]);

    const config = useMemo((): FormConfig<NewTransactionAdvancedFormType> => ({
        debit: {
            items: debitWithMyAccount ? [myAccount, ...debitAccountList] : debitAccountList,
            search: (input) => {
                dispatch(newTransactionActions.searchDebitAccount({
                    ...debitRequest,
                    query: input,
                }));
            },
            restrictions: {
                required: true,
            },
            disabled: debitDisabled,
        },
        credit: {
            items: creditWithMyAccount ? [myAccount, ...creditAccountList] : creditAccountList,
            search: (input) => {
                dispatch(newTransactionActions.searchCreditAccount({
                    ...creditRequest,
                    query: input,
                }));
            },
            restrictions: {
                required: true,
            },
            disabled: creditDisabled,
        },
        amount: {
            restrictions: {
                required: true,
            },
        },
        category: {
            items: paymentTypeList,
            restrictions: {
                required: true,
            },
        },
        description: {
            restrictions: {
                required: true,
            },
        },
    }), [
        dispatch,
        debitAccountList, creditAccountList, paymentTypeList,
        myAccount, debitWithMyAccount, creditWithMyAccount,
        debitDisabled, creditDisabled,
        debitRequest, creditRequest,
    ]);

    return {
        myAccount,
        validateForm,
        config,
    };
};
