import { Option } from '@malesia/json-schema';
import { PassengerItemDataType, PassengerListItemForm } from '@malesia/react-components/dist/src/components/Reservation/PassengersList/Item';
import { PassengerOption } from '@malesia/react-components/dist/src/components/Reservation/PassengersList/Options';
import { localizeMoney } from '@malesia/react-components/dist/src/utils/roundMoney';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PassengerBasket } from './types';

const toPassengerOption = (
    passenger: PassengerBasket,
    option: Option,
    locale: string,
) => {
    const { id, configValues } = option;
    const { price, description, title } = configValues;

    const priceLabel = price
        ? ` (+ CHF ${localizeMoney(price, locale, 'utils/Reservation/optionUtils/toPassengerOption')})`
        : '';

    const isChecked = passenger.options[id!] ?? false;
    const result: PassengerOption = {
        id: id!,
        title: `${title[locale]}${priceLabel}`,
        tooltip: description?.[locale]!,
        isChecked,
    };
    return result;
};

export const prepareFormPassengersData = (
    passengers: PassengerBasket[],
    optionList: Option[],
    removedOptionList: Option[],
    locale: string,
): PassengerItemDataType[] => {

    return passengers.map<PassengerItemDataType>((passenger) => {
        let total = 0;
        const mapPassengerOption = (option: Option): PassengerOption => {
            const passengerOption = toPassengerOption(passenger, option, locale);
            const { price } = option.configValues;
            const { isChecked } = passengerOption;
            total = total + (isChecked ? price : 0);
            return passengerOption;
        };
        const mapRemovedOption = (option: PassengerOption): PassengerOption => ({
            ...option,
            tooltip: (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: option.tooltip as string }} />
                    <div style={{ width: '100%', borderTop: '1px solid gray', margin: '6px 0px' }} />
                    <div>
                        <FormattedMessage
                            id='front-app/Reservation/Options:RemovedOption'
                            defaultMessage='The option is no longer available on outbound flight. If you remove this option, the option will not be available for reservation.'
                        />
                    </div>
                </div>
            ),
            warning: true,
        });
        const passengerOptions = [
            ...optionList.map(mapPassengerOption),
            ...removedOptionList
                .filter(x => passenger.options[x.id!])
                .map(mapPassengerOption)
                .map(mapRemovedOption),
        ];

        const result: PassengerItemDataType = {
            formData: passenger.info as PassengerListItemForm,
            passengerOptions,
            passengerOptionsPriceTotalText: (
                total > 0
                    ? `${localizeMoney(total, locale, 'utils/Reservation/optionUtils/prepareFormPassengersData')}`
                    : ''
            ),
        };
        return result;
    });
};
