import { BackLink } from '@malesia/react-components/dist/src/components/BackLink';
import { LoadingOverlay } from '@malesia/react-components/dist/src/components/LoadingOverlay';
import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import {
    Permissions,
    PermissionsTabKey,
} from '@malesia/react-components/dist/src/components/PermissionsPage/Permissions/Permissions';
import { PermissionsPageLayout } from '@malesia/react-components/dist/src/components/PermissionsPage/PermissionsPageLayout/PermissionsPageLayout';
import { PermissionsPageLayoutActions } from '@malesia/react-components/dist/src/components/PermissionsPage/PermissionsPageLayoutActions/PermissionsPageLayoutActions';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, {
    ChangeEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { RoleCode } from '../../permissions/roleCode';
import { useSettingsFormConfig } from './config';
import { useInjectUserPermissions } from './store/inject';
import {
    selectActiveGroupId,
    selectAvailableRoles,
    selectCurrentRoles,
    selectCurrentUser,
    selectCurrentVirtualAccounts,
    selectGroupItems,
    selectIsLoading,
    selectVirtualAccounts,
} from './store/selectors';
import { permissionsPageActions } from './store/slice';
import { mapRoleCodesToPermissions } from 'app/permissions/translationUtils';

type RouteParams = {
    userId: string,
};
type Props = RouteComponentProps<RouteParams>;

export const SettingsUserPermissionsPage: SFC<Props> = ({ match }) => {
    useInjectUserPermissions();

    const dispatch = useDispatch();
    const { formatMessage } = useIntl();

    useEffect(() => {
        dispatch(permissionsPageActions.onLoad(+match.params.userId));
    }, [dispatch, match.params.userId]);

    const isLoading = useSelector(selectIsLoading);
    const groupItems = useSelector(selectGroupItems);
    const groupValue = useSelector(selectActiveGroupId);
    const availableRoles = useSelector(selectAvailableRoles);
    const currentRoles = useSelector(selectCurrentRoles);
    const currentUser = useSelector(selectCurrentUser);
    const virtualAccounts = useSelector(selectVirtualAccounts);
    const currentVirtualAccounts = useSelector(selectCurrentVirtualAccounts);
    const { defaultTab, disabledTabs, hiddenTabs, canSave } = useSettingsFormConfig();

    const [activeTab, setActiveTab] = useState<PermissionsTabKey>(defaultTab);

    const onChangeRole = (permissionId: string, value: boolean) => {
        // fixme what is it? delete after tests
        // let newPermission: Permission[] = [...userPermissions];
        // let currentItem = newPermission.find(p => p.id === permissionId);
        // if(currentItem) currentItem.isActive = value;
        dispatch(
            value
                ? permissionsPageActions.addCurrentRole(permissionId as RoleCode)
                : permissionsPageActions.deleteCurrentRole(permissionId as RoleCode));
    };

    const onChangeVirtualAccounts = (id: string, value: boolean) => {
        const virtualAccountId = +id;
        dispatch(
            value
                ? permissionsPageActions.addCurrentVirtualAccount(virtualAccountId)
                : permissionsPageActions.deleteCurrentVirtualAccount(virtualAccountId),
        );
    };

    const userRoles = useMemo(() => {
        const permissions = mapRoleCodesToPermissions(availableRoles, formatMessage, currentRoles);
        const uncategorizedRoles = permissions.filter(p => p.groupName === 'unknown category').map(p => p.id).join('\n\n');
        if (uncategorizedRoles) console.warn('uncategorizedRoles\n', uncategorizedRoles);
        return permissions;
    }, [availableRoles, currentRoles, formatMessage]);

    const userVirtualAccounts = useMemo(() => {
        return virtualAccounts.map(va => ({
            id: va.id.toString(),
            title: va.name,
            description: null,
            isActive: currentVirtualAccounts?.includes(va.id),
            groupName: null,
        }));
    }, [currentVirtualAccounts, virtualAccounts]);

    const onChangeGroup = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
        dispatch(permissionsPageActions.setActiveUserGroupId(+e.target.value));
    }, [dispatch]);

    const submit = useCallback(() => {
        dispatch(permissionsPageActions.onSubmit());
    }, [dispatch]);

    const restore = useCallback(() => {
        dispatch(permissionsPageActions.restore());
    }, [dispatch]);

    return (
        <PageContentBlock>
            <PermissionsPageLayout>
                <div>
                    <PageTitle
                        text={(
                            <FormattedMessage
                                id='front-app/SettingsUserPermissionPage:title'
                                defaultMessage='Update permissions ({name})'
                                values={{
                                    name: currentUser?.displayName ?? '',
                                }}
                            />
                        )}
                    />
                    <BackLink
                        to={allAdminRoutePaths.settingsPermissions}
                        modificators='stick-to-title'
                    />
                </div>
                <LoadingOverlay
                    isLoading={isLoading}
                    modificators='top'
                >
                    <Permissions
                        title={
                            <FormattedMessage
                                id='front-app/SettingsUserPermissionPage:t'
                                defaultMessage='Permissions'
                            />
                        }
                        groupItems={groupItems}
                        groupLabel={
                            <FormattedMessage
                                id='front-app/SettingsUserPermissionPage:l'
                                defaultMessage='Permission Group'
                            />
                        }
                        groupValue={groupValue?.toString()}
                        onChangeGroup={onChangeGroup}
                        activeTab={activeTab}
                        hiddenTabs={hiddenTabs}
                        disabledTabs={disabledTabs}
                        rolesButtonLabel={
                            <FormattedMessage
                                id='front-app/SettingsUserPermissionPage:f'
                                defaultMessage='Feature'
                            />
                        }
                        virtualAccountsButtonLabel={
                            <FormattedMessage
                                id='front-app/SettingsUserPermissionPage:v'
                                defaultMessage='Virtual Accounts'
                            />
                        }
                        onChangeTab={setActiveTab}
                        roles={userRoles}
                        onChangeRole={onChangeRole}
                        virtualAccounts={userVirtualAccounts}
                        onChangeVirtualAccount={onChangeVirtualAccounts}
                        onRestore={restore}
                    />
                    <PermissionsPageLayoutActions
                        onClick={submit}
                        hidden={!canSave}
                    />
                </LoadingOverlay>
            </PermissionsPageLayout>
        </PageContentBlock>
    );
};
