import {
    ObjectId,
    ReservationFlight,
    ReservationPassenger,
    ReservationPassengerFlight,
    ReservationPassengerWithAgeType,
    ReservationPutRequest as ReservationPostRequestSchema,
    ReservationPostRequest as ReservationPutRequestSchema,
} from '@malesia/json-schema';
import { AdminBillingFormData } from '@malesia/react-components/dist/src/components/Reservation/BillingInformation/AdminBillingInformation';
import { verifyTariff } from '@malesia/react-components/dist/src/utils/verify';
import { SelectedFlight } from '../../../utils/reservation/selectedFlight';
import { PassengerOptions, PassengerBasket, PassengerSeats } from '../../../utils/reservation/types';
import {
    PublicReservationBasket,
    BasketSelectedFlights,
    BasketSelectedPrices,
} from '../../containers/ReservationBasket/reservationBasket.types';
import { AdminReservationBasket } from '../../pages/ReservationNewPage/types';

// ToDo: Split post & put requests. Resolve passengers field strange contract.
export type ReservationPostRequest = Omit<ReservationPutRequestSchema & ReservationPostRequestSchema, 'passengers'> & {
    passengers: (ReservationPassenger | ReservationPassengerWithAgeType)[],
};
export type AdminBasket = AdminReservationBasket & {
    paymentTerms: ReservationPostRequest['paymentTerms'],
    paymentDeadline?: string,
};

const mapPassengersToRequestSeats = (
    basketSeats: PassengerSeats[],
    outboundFlightId: number,
    returnFlightId: number,
): ReservationPassengerFlight[][] => {
    return basketSeats.map(seats => {
        const arr: ReservationPassengerFlight[] = [];
        if (seats.outbound) {
            arr.push({
                flight: { id: outboundFlightId },
                seatLabel: seats.outbound,
            });
        }
        if (seats.return) {
            arr.push({
                flight: { id: returnFlightId },
                seatLabel: seats.return,
            });
        }
        return arr;
    });
};

const mapPassengersToRequestOptions = (
    basketPassengersOptions: PassengerOptions[],
): ObjectId[][] => (
    basketPassengersOptions.map(passengerOptions => (
        Object.entries(passengerOptions)
            .filter(([, value]) => value)
            .map(([key]) => ({ id: +key }))
    ))
);

const mapPassengerToPostRequest = (passenger: PassengerBasket): ReservationPostRequest['passengers'][number] => ({
    firstName: passenger.info.firstName!,
    lastName: passenger.info.lastName!,
    birthday: passenger.info.birthday!,
    gender: passenger.info.gender!,
});

const composeReservationFlight = (
    basketFlights: BasketSelectedFlights | undefined,
    basketPrices: BasketSelectedPrices | undefined,
): ReservationFlight[] => {
    if (!basketFlights || !basketPrices) {
        throw new Error('in basket selectedFlights or selectedPrices is undefined');
    }
    const flights = Object.values(basketFlights);
    const tariffIds = Object.values(basketPrices).map(pricesItem => verifyTariff(pricesItem.tariff)!.id!);

    return flights.map((basketFlight, idx) => {
        return {
            flight: { id: basketFlight.id },
            tariff: { id: tariffIds[idx] },
        };
    });
};

export const mapPublicReservationToPostRequest = (basket: PublicReservationBasket): ReservationPostRequest => {
    const bookedFlights: ReservationFlight[] = composeReservationFlight(basket.selectedFlights, basket.selectedPrices);
    const passengers = basket.passengers.map(mapPassengerToPostRequest);
    const billingInformation: ReservationPostRequest['billingInformation'] = basket.billingInformation;
    const passengersOptions = mapPassengersToRequestOptions(basket.passengers.map(x => x.options));
    const passengersSeats = mapPassengersToRequestSeats(
        basket.passengers.map(x => x.seats),
        basket.selectedFlights?.outbound?.id!,
        basket.selectedFlights?.return?.id!,
    );
    const paymentTerms = null;

    return {
        bookedFlights: bookedFlights as ReservationPostRequest['bookedFlights'],
        passengers,
        billingInformation,
        passengersOptions,
        passengersSeats,
        paymentTerms,
    };
};

const mapAdminBillingToPostRequest = (
    { phoneNumberMobile, ...billing }: AdminBillingFormData,
): ReservationPostRequest['billingInformation'] => ({
    ...billing,
    country: billing.country ? { code: billing.country } : undefined,
    phoneMobile: phoneNumberMobile,
} as ReservationPostRequest['billingInformation']);

const mapReservationFlightToApiReservationFlight = (flight: SelectedFlight): ReservationFlight => ({
    flight: { id: flight.flightId },
    tariff: { id: flight.tariffId },
});

const mapReservationFlightToBookedFlight = (
    flights: AdminBasket['selectedFlights'],
): ReservationPostRequest['bookedFlights'] => {
    const result: [ReservationFlight, ...ReservationFlight[]] = [
        mapReservationFlightToApiReservationFlight(flights.outbound!),
    ];
    if (flights.return) {
        result.push(mapReservationFlightToApiReservationFlight(flights.return));
    }
    return result;
};

export const filterUpdateBillingInformation = (data?: AdminBillingFormData): AdminBillingFormData | undefined => {
    if (!data) return undefined;
    const allowedProperties: (keyof AdminBillingFormData)[] = [
        'isBankInformation',
        'isBookingInformation',
        'isPostInformation',
    ];
    const entries = Object.entries(data);
    const filtered = entries.filter(([key]) => allowedProperties.some(x => x === key));
    return Object.fromEntries(filtered) as AdminBillingFormData;
};

export const mapAdminReservationToPostRequest = (data: AdminBasket): ReservationPostRequest => {
    const bookedFlights = mapReservationFlightToBookedFlight(data.selectedFlights);
    const passengers: ReservationPostRequest['passengers'] = data.passengers.map(mapPassengerToPostRequest);
    const billingInformation: ReservationPostRequest['billingInformation'] = (
        data.billingInformation ? mapAdminBillingToPostRequest(data.billingInformation) : {}
    );
    const passengersOptions = mapPassengersToRequestOptions(data.passengers.map(x => x.options));
    const passengersSeats = mapPassengersToRequestSeats(
        data.passengers.map(x => x.seats),
        data.selectedFlights.outbound?.flightId!,
        data.selectedFlights.return?.flightId!,
    );

    const result: ReservationPostRequest = {
        bookedFlights,
        passengers,
        passengersOptions,
        passengersSeats,
        paymentTerms: data.paymentTerms,
        paymentDeadline: data.paymentDeadline,
        isSpecificPrice: data.payment.specificPrice !== undefined,
        total: data.payment.specificPrice,
        updateFee: data.payment.updateFee,
        priceNeedsToBeHidden: data.payment.hidePrice,
        comment: data.comment,
        ...{
            billingInformation,
        },
    };
    return result;
};
