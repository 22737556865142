import { ContentPage } from '@malesia/react-components/dist/src/components/ContentPage/ContentPage';
import { CardList } from '@malesia/react-components/dist/src/components/Front/CardList/CardList';
import { HtmlBlock } from '@malesia/react-components/dist/src/components/Front/HtmlBlock/HtmlBlock';
import { TeamMemberCard } from '@malesia/react-components/dist/src/components/Front/TeamMemberCard';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { useIntl } from 'react-intl';
import useSWR from 'swr';
import { loadManagementPageContent } from '../../services/directus/managementPage/loadManagementPageContent';

export const MalesiaAboutUsPage: SFC = () => {
    const { locale } = useIntl();
    const { data } = useSWR(
        [locale, 'loadManagementPageContent'],
        loadManagementPageContent,
    );

    return (
        <>
            <ContentPage
                image={data?.topImage}
            >
                <HtmlBlock content={data?.content ?? ''} />
                <CardList
                    minWidth='400px'
                    minWidthMobile='320px'
                >
                    {data?.cards?.map(card => (
                        <TeamMemberCard
                            key={card.title}
                            email={card.email}
                            phone={card.phone}
                            image={card.avatar}
                            position={card.role}
                            name={card.title}
                            phoneTitle={card.phoneTitle}
                            emailTitle={card.emailTitle}
                        />
                    ))}
                </CardList>
            </ContentPage>
        </>
    );
};
