import { all, call, put, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../utils/log';
import { actions } from './slice';
import { apiClientRequest } from 'app/services/ApiClient';

export function* getReservationSnapshotListData(action: ReturnType<typeof actions.getReservationSnapshotList>) {
    try {
        const { reservationId } = action.payload;

        const requestData = { requestId: 'reservation', uriParams: { id: reservationId } };
        const requestSnapshotsData = { requestId: 'reservationSnapshotList', uriParams: { id: reservationId } };

        yield* put(actions.startLoading());
        const [reservation, reservationSnapshots] = yield* all([
            call(apiClientRequest, requestData),
            call(apiClientRequest, requestSnapshotsData),
        ]);
        yield* put(actions.setReservation(reservation));
        yield* put(actions.setReservationSnapshotList(reservationSnapshots));
        yield* put(actions.successLoading());
    }
    catch (error) {
        logError({
            error,
            target: 'ReservationHistoryPage.getReservationSnapshotListData',
        });
        yield* put(actions.failLoading());
    }
}

export function* getReservationSnapshotListSaga() {
    yield* takeLatest(actions.getReservationSnapshotList, getReservationSnapshotListData);
}
