import { Agent } from '@malesia/json-schema';
import { AgentFormType } from '@malesia/react-components/dist/src/components/Agent/Edit';
import { LegacyFormConfig, readonlyFormConfig } from '@malesia/react-components/dist/src/hooks/useForm';
import { mapObject } from '@malesia/react-components/dist/src/utils/object';
import { useMemo } from 'react';
import { agentEditPermissions } from '../../permissions/adminPages/user/agentEdit';
import { UserPermissions } from '../../permissions/userPermissions';
import { useUserPermissions } from '../../permissions/useUserPermissions';

type Config = LegacyFormConfig<AgentFormType>;
const createConfig = (
    agentData: Agent | undefined,
): Config => ({
    id: { initialValue: agentData?.id },
    email: { initialValue: agentData?.email ?? '', modifiers: ['required'] },
    password: { initialValue: '' },
    passwordConfirm: { initialValue: '' },
    salutation: { initialValue: agentData?.salutation, modifiers: ['required'] },
    company: { initialValue: agentData?.company },
    firstName: { initialValue: agentData?.firstName ?? '', modifiers: ['required'] },
    lastName: { initialValue: agentData?.lastName ?? '', modifiers: ['required'] },
    street: { initialValue: agentData?.street, modifiers: ['required'] },
    zip: { initialValue: agentData?.zip, modifiers: ['required'] },
    city: { initialValue: agentData?.city },
    country: { initialValue: agentData?.country, modifiers: ['required'] },
    language: { initialValue: agentData?.language, modifiers: ['required'] },
    birthday: { initialValue: agentData?.birthday, modifiers: ['required'] },
    phoneMobile: { initialValue: agentData?.phoneMobile, modifiers: ['required'] },
    phoneNumberFirst: { initialValue: agentData?.phoneNumberFirst },
    phoneNumberSecond: { initialValue: agentData?.phoneNumberSecond },
    monthlyFee: { initialValue: agentData?.monthlyFee },
    creditLimitTotalAmount: { initialValue: agentData?.creditLimitTotalAmount },
    marginType: { initialValue: agentData?.marginType!, modifiers: ['required'] },
    moneyMargin: { initialValue: agentData?.moneyMargin, modifiers: ['required'] },
    percentMargin: { initialValue: agentData?.percentMargin, modifiers: ['required'] },
    isActive: { initialValue: agentData?.isActive },
});

const configurePermissions = (
    config: Config,
    userPermissions: UserPermissions,
): Config => {
    const updateForm = userPermissions.has(agentEditPermissions.updateForm);
    const updateIsActive = userPermissions.has(agentEditPermissions.updateIsActive);
    const updateFee = userPermissions.has(agentEditPermissions.updateFee);
    const updateCreditLimit = userPermissions.has(agentEditPermissions.updateCreditLimit);
    const updateMargin = userPermissions.has(agentEditPermissions.updateMargin);

    const hiddenMap: Partial<Record<keyof Config, boolean>> = {
        monthlyFee: !updateFee,
        creditLimitTotalAmount: !updateCreditLimit,
        marginType: !updateMargin,
        moneyMargin: !updateMargin,
        percentMargin: !updateMargin,
        isActive: !updateIsActive,
    };

    let result = config;
    if (!updateForm) {
        result = readonlyFormConfig(result);
    }
    result = mapObject(result, ([key, value]) => ({
        ...value,
        hidden: hiddenMap[key] || value.hidden,
    }));
    return result;
};

export const useAgentFormConfig = (
    agentData: Agent | undefined,
): Config => {
    const userPermissions = useUserPermissions();
    const result = useMemo<Config>(() => {
        const config = createConfig(agentData);
        return configurePermissions(config, userPermissions);
    }, [agentData, userPermissions]);
    return result;
};
