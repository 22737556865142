import { EventLogFilter } from '@malesia/react-components/dist/src/components/EventLog/List/Filter';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectEntityTypeList,
    selectOperationTypeList,
    selectFilterData,
} from '../store/selectors';
import { actions } from '../store/slice';

export const EventLogFilterWithStore: SFC = () => {
    const filterData = useSelector(selectFilterData);
    const entityTypeList = useSelector(selectEntityTypeList);
    const operationTypeList = useSelector(selectOperationTypeList);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.getEntityTypeList());
        dispatch(actions.getOperationTypeList());
    }, [dispatch]);

    const handleChangeSearch = useCallback((data: string) => {
        dispatch(actions.setFilterData({ search: data }));
    }, [dispatch]);

    const handleChangeDateFrom = useCallback((data) => {
        dispatch(actions.setFilterData({ fromDate: data }));
    }, [dispatch]);

    const handleChangeDateTo = useCallback((data) => {
        dispatch(actions.setFilterData({ toDate: data }));
    }, [dispatch]);

    const handleChangeEntityType = useCallback((data) => {
        dispatch(actions.setFilterData({ entityType: data }));
    }, [dispatch]);

    const handleChangeOperationType = useCallback((data) => {
        dispatch(actions.setFilterData({ operationType: data }));
    }, [dispatch]);

    const handleSubmit = useCallback(() => {
        dispatch(actions.getEntityLogList());
    }, [dispatch]);

    return (
        <EventLogFilter
            data={filterData}
            entityTypeItems={entityTypeList.items}
            operationTypeItems={operationTypeList.items}
            onChangeSearch={handleChangeSearch}
            onChangeFromDate={handleChangeDateFrom}
            onChangeToDate={handleChangeDateTo}
            onChangeEntityType={handleChangeEntityType}
            onChangeOperationType={handleChangeOperationType}
            onSubmit={handleSubmit}
        />
    );
};
