import { BackLink } from '@malesia/react-components/dist/src/components/BackLink';
import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { AdminAddReservationLayout } from '@malesia/react-components/dist/src/components/Reservation/AdminAddReservationLayout/AdminAddReservationLayout';
import { DesignSummary } from '@malesia/react-components/dist/src/components/Reservation/DesignSummary';
import { StickyContent } from '@malesia/react-components/dist/src/components/StickyContent/StickyContent';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { backLinks } from '../../containers/Routes/backLinks';
import { ReservationNewPageSummary } from './ReservationNewPageSummary/ReservationNewPageSummary';
import { StepOutboundFlight } from './Steps/OutboundFlight/OutboundFlight';
import { SeatReservation } from './Steps/SeatReservation/SeatReservation';
import { StepBillingInformation } from './Steps/StepBillingInformation/StepBillingInformation';
import { StepReturnFlight } from './Steps/StepFlightReturn/StepFlightReturn';
import { StepPassengers } from './Steps/StepPassengers/StepPassengers';
import { StepPayment } from './Steps/StepPayment';
import { StepTravelDate } from './Steps/TravelDate/TravelDate';
import {
    selectMode,
    selectEditable,
    selectOutboundFlightIsVisible,
    selectReturnFlightIsVisible,
    selectStepBillingIsVisible,
    selectStepPassengersIsVisible,
    selectStepPaymentIsVisible,
    selectStepSeatReservationIsVisible,
} from './store/selectors';

export const ReservationForm: SFC = () => {
    const mode = useSelector(selectMode);
    const editable = useSelector(selectEditable);
    const stepSelectOutboundIsVisible = useSelector(selectOutboundFlightIsVisible);
    const stepSelectReturnFlightStepIsVisible = useSelector(selectReturnFlightIsVisible);
    const stepBillingIsVisible = useSelector(selectStepBillingIsVisible);
    const stepPassengersIsVisible = useSelector(selectStepPassengersIsVisible);
    const stepSeatReservationIsVisible = useSelector(selectStepSeatReservationIsVisible);
    const stepPaymentIsVisible = useSelector(selectStepPaymentIsVisible);

    const validateBillingRef = useRef(() => false);
    const validatePassengersRef = useRef(() => false);
    const validateSteps = useCallback(() => {
        const isBillingValid = validateBillingRef.current();
        const isPassengersValid = validatePassengersRef.current();
        return (isBillingValid || mode === 'edit') && isPassengersValid;
    }, [mode]);

    const newTitle = (
        <FormattedMessage
            id='front-app/ReservationPage:NewTitle'
            defaultMessage='New reservation'
        />
    );
    const editTitle = (
        <FormattedMessage
            id='front-app/ReservationPage:EditTitle'
            defaultMessage='Edit reservation'
        />
    );

    return (
        <PageContentBlock modificators='flight-reservation'>
            <DesignSummary
                modificators='with-sidebar'
                contentSlot={
                    <>
                        <PageTitle
                            text={mode === 'new' ? newTitle : editTitle}
                        />
                        <BackLink
                            to={backLinks.reservation.back()}
                            modificators='stick-to-title'
                        />
                        {!editable ? undefined : (
                            <AdminAddReservationLayout>
                                <StepTravelDate />
                                {stepSelectOutboundIsVisible && (
                                    <StepOutboundFlight />
                                )
                                }
                                {stepSelectReturnFlightStepIsVisible && (
                                    <StepReturnFlight />
                                )}
                                {stepBillingIsVisible && (
                                    <StepBillingInformation
                                        validateFormRef={validateBillingRef}
                                    />
                                )}
                                {stepPassengersIsVisible && (
                                    <StepPassengers
                                        validateFormRef={validatePassengersRef}
                                    />
                                )}
                                {stepSeatReservationIsVisible && (
                                    <SeatReservation />
                                )}
                                {stepPaymentIsVisible && (
                                    <StepPayment
                                        validatePrevSteps={validateSteps}
                                    />
                                )}
                            </AdminAddReservationLayout>
                        )}
                    </>
                }
                summarySlot={!editable ? undefined : (
                    <StickyContent>
                        <ReservationNewPageSummary />
                    </StickyContent>
                )}
            />
        </PageContentBlock>
    );
};
