import { AccountListItem } from '@malesia/json-schema';
import { NewTransactionAdvancedFormType } from '@malesia/react-components/dist/src/components/Accounting/NewTransaction/NewTransactionAdvanced';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../../utils/@reduxjs/toolkit';
import { GetAccountFreeListParams } from '../../../services/account';
import { NewTransactionState } from './types';

export const initialState: NewTransactionState = {
    isSubmitting: false,
    debitAccountList: [],
    creditAccountList: [],
    paymentTypeList: [],
};

const newTransactionSlice = createSlice({
    name: 'newTransaction',
    initialState,
    reducers: {
        loadPaymentTypeList() {},
        submitAdvancedForm(state, action: PayloadAction<NewTransactionAdvancedFormType>) {
            state.isSubmitting = true;
        },
        searchDebitAccount(state, action: PayloadAction<GetAccountFreeListParams>) {},
        searchCreditAccount(state, action: PayloadAction<GetAccountFreeListParams>) {},
        resetDebitAndCreditList(state) {
            state.creditAccountList = [];
            state.debitAccountList = [];
        },
        setDebitAccountList(state, action: PayloadAction<AccountListItem[]>) {
            state.debitAccountList = action.payload;
        },
        setCreditAccountList(state, action: PayloadAction<AccountListItem[]>) {
            state.creditAccountList = action.payload;
        },
        setPaymentTypeList(state, action: PayloadAction<NewTransactionState['paymentTypeList']>) {
            state.paymentTypeList = action.payload;
        },
    },
});

export const {
    actions: newTransactionActions,
    reducer: newTransactionReducer,
    name: newTransactionSliceKey,
} = newTransactionSlice;
