import { addQueryParameters, getQueryParameters, makePath } from '../../../../utils/uriUtils';
import { allAdminRoutePaths } from '../allAdminRoutePaths';

/**
 * ToDo: Rework datatrans navigation.
 * Allowed only source and additionalProp by datatrans implementation restrictions.
 * I think need remove source & additionalProp from request and caching by datatrans api.
 * Implement navigation after datatrans by locale storage.
 * Rename additionalProp to flightId!
 */
export type ReservationPaymentQueryParameters = (
    | { source: 'reservationList' }
    | { source: 'invoiceOverview' }
    | { source: 'passengerList', additionalProp: number }
);

const defaultBackUrl = allAdminRoutePaths.reservationList;
const getBackUrl = (params: ReservationPaymentQueryParameters): string | undefined => {
    switch (params.source) {
        case 'reservationList': {
            return undefined;
        }
        case 'invoiceOverview': {
            return allAdminRoutePaths.reservationInvoiceList;
        }
        case 'passengerList': {
            const { additionalProp } = params;
            if (!additionalProp) return undefined;
            return makePath(allAdminRoutePaths.flightPassengerList, {
                flightId: additionalProp,
            });
        }
        default: {
            return undefined;
        }
    }
};

export const reservationPayment = {
    forward: (reservationId: number, parameters: ReservationPaymentQueryParameters): string => {
        const path = makePath(allAdminRoutePaths.reservationPayment, {
            reservationId,
        });
        const result = addQueryParameters(path, parameters);
        return result;
    },
    back: (highlightReservationId?: number): string => {
        const params = getQueryParameters<ReservationPaymentQueryParameters>({
            source: 'string',
            additionalProp: 'number',
        });
        const path = getBackUrl(params) ?? defaultBackUrl;
        const result = addQueryParameters(path, { highlightReservationId });
        return result;
    },
};
