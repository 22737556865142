import { Button } from '@malesia/react-components/dist/src/components/Button';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { allAdminRoutePaths } from '../../../containers/Routes/allAdminRoutePaths';
import { rateListPermissions } from '../../../permissions/adminPages/rate/rateList';
import { useUserPermissions } from '../../../permissions/useUserPermissions';

export const RateListHeaderWithStore: SFC = () => {
    const userPermissions = useUserPermissions();

    return (
        <PageTitle
            text={
                <FormattedMessage
                    id='front-app/rateListPage:Title'
                    defaultMessage='Rates'
                />
            }
            rightSlot={
                <Link to={allAdminRoutePaths.rateNew}>
                    <Button
                        icon='plus'
                        text={
                            <FormattedMessage
                                id='front-app/Rate/List:AddNewRate'
                                defaultMessage='Add new rate'
                            />
                        }
                    />
                </Link>
            }
            rightSlotHidden={!userPermissions.has(rateListPermissions.createLink)}
        />
    );
};
