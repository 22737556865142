import { getImageUrl } from '../getImageUrl';
import { getTopImage } from '../getTopImage';
import { HistoryPageDocument, HistoryPageQuery } from '../gql-types';
import { gqlClient } from '../gqlClient';

export async function loadHistoryPageContent(locale: string) {
    const { data } = await gqlClient.query<HistoryPageQuery>(
        HistoryPageDocument,
        { lang: locale },
    ).toPromise();
    return {
        topImage: getTopImage(data?.history_page?.top_image),
        content: data?.history_page?.translations?.[0]?.content ?? '',
        cards: data?.history_card?.map(card => {
            return {
                image: getImageUrl(card?.photo?.id),
                sortYear: card?.sort_year ?? 0,
                title: card?.title ?? '',
                direction: card?.translations?.[0]?.direction ?? '',
                period: card?.translations?.[0]?.period ?? '',
            };
        }) ?? [],
        milestones: data?.milestones?.map(milestone => milestone!.value as number),
    };
}
