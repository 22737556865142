import { AgeType } from '@malesia/react-components/dist/src/utils/ageType';

export const validatePassengersByAge = (data: { ageType?: AgeType }[]): boolean => {
    let adults: number = 0;
    let children: number = 0;
    let infants: number = 0;
    for (const passenger of data) {
        switch (passenger.ageType) {
            case 'adult':
                adults++;
                break;
            case 'child':
            // eslint-disable-next-line no-unused-vars
                children++;
                break;
            case 'infant':
                infants++;
                break;
            default:
                throw new Error('AgeType must be "adult" | "child" | "infant"');
        }
    }
    if (adults < infants) return false;
    return true;
};
