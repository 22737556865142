import { createActionLayout } from '@malesia/react-components/dist/src/components/Table/columns/actionsColumn/actionLayout';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const reservationList = createActionLayout({
    text: (
        <FormattedMessage
            id='front-app/AgentListPage:showReservationsTitle'
            defaultMessage='Show reservations'
        />
    ),
    iconName: 'check-list',
});
