import { getImageUrl } from '../getImageUrl';
import {
    HomePageContentDocument,
    HomePageContentQuery,
} from '../gql-types';
import { gqlClient } from '../gqlClient';

export const loadHomePageContent = async (locale: string) => {
    const { data } = await gqlClient.query<HomePageContentQuery>(
        HomePageContentDocument,
        { lang: locale },
    ).toPromise();

    const specialOfferSlides = data?.spesical_offer?.map(apiSlide => {
        const from = apiSlide?.origin?.translations?.[0]?.title;
        const to = apiSlide?.destination?.translations?.[0]?.title;
        const airportFrom = apiSlide?.origin?.code ?? undefined;
        const airportTo = apiSlide?.destination?.code ?? undefined;
        return {
            title: `${from} - ${to}`,
            price: apiSlide?.translations?.[0]?.price_offer ?? null,
            image: getImageUrl(apiSlide?.image?.id, 1920, 1280),
            airports: { airportFrom, airportTo },
        };
    }) ?? [];

    const advertisementsCards = data?.advertisements_card?.map(apiCard => {
        return {
            image: getImageUrl(apiCard?.image?.id, 1100, 500),
            link: apiCard?.link ?? '',
        };
    }) ?? [];

    const newsContent = data?.news_block?.active_news?.translations?.[0]?.content;
    const blockTitle = data?.news_block?.translations?.[0]?.title;
    const newsTitle = data?.news_block?.active_news?.translations?.[0]?.title ?? undefined;
    const newsExpiredAt = data?.news_block?.active_news?.expiredAt;
    const news = newsContent && blockTitle && newsExpiredAt ? {
        blockTitle,
        content: newsContent,
        newsTitle,
        expiredAt: newsExpiredAt,
    } : null;

    return { specialOfferSlides, advertisementsCards, news };
};
