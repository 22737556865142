import { AllotmentDeleteInvoicePopup, AllotmentDeleteInvoicePopupProps } from '@malesia/react-components/dist/src/components/Allotment/InvoiceList/components/DeleteInvoicePopup';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDeleteInvoiceLoading, selectDeleteInvoiceOpen, selectDeleteInvoiceSelected } from '../store/selectors';
import { actions } from '../store/slice';

export const AllotmentDeleteInvoicePopupWithStore: SFC = () => {
    const dispatch = useDispatch();
    const open = useSelector(selectDeleteInvoiceOpen);
    const loading = useSelector(selectDeleteInvoiceLoading);
    const invoice = useSelector(selectDeleteInvoiceSelected);

    const allotmentCreateInvoicePopupProps: AllotmentDeleteInvoicePopupProps = {
        invoice,
        isOpened: open,
        isLoading: loading,
        onConfirm: () => {
            dispatch(actions.confirmDeleteInvoicePopup());
        },
        onClose: () => {
            dispatch(actions.cancelDeleteInvoicePopup());
        },
    };

    return (
        <AllotmentDeleteInvoicePopup
            key={`${open}`}
            {...allotmentCreateInvoicePopupProps}
        />
    );
};
