import { ApiCallEntityLogFilters } from '@malesia/json-schema';
import { debounce, put, select, takeLatest, throttle } from 'typed-redux-saga';
import { logError } from '../../../../utils/log';
import { getApiEntityLog, getApiEntityTypeList, getApiOperationTypeList } from '../../../services/entityLog';
import { mapRequestInterval } from '../../../services/utils';
import { selectPagination, selectFilterData, selectSorting } from './selectors';
import { actions } from './slice';

export function* getEntityLogList() {
    try {
        const filterData = yield* select(selectFilterData);
        const pagination = yield* select(selectPagination);
        const sorting = yield* select(selectSorting);

        const requestData: ApiCallEntityLogFilters = {
            ...pagination,
            ...sorting,
            query: filterData.search,
            dateInterval: mapRequestInterval({
                from: filterData.fromDate,
                to: filterData.toDate,
            }),
            entityType: filterData.entityType,
            operationType: filterData.operationType,
        };

        const result = yield* getApiEntityLog(requestData);
        yield* put(actions.getEntityLogListSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'EventLogPage.getEntityLogList',
        });
        yield* put(actions.getEntityLogListError());
    }
}

export function* getEntityLogListSaga() {
    yield* takeLatest(actions.getEntityLogList, getEntityLogList);

    function* requestGetEntityLogList() {
        yield* put(actions.getEntityLogList());
    }

    yield* throttle(1000, [
        actions.setPage,
        actions.setSorting,
    ], requestGetEntityLogList);

    yield* debounce(1000, [
        actions.setFilterData,
        actions.setPageSize,
    ], requestGetEntityLogList);
}

export function* getEntityTypeList() {
    try {
        const result = yield* getApiEntityTypeList();
        yield* put(actions.getEntityTypeListSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'EventLogPage.getEntityTypeList',
        });
        yield* put(actions.getEntityTypeListError());
    }
}

export function* getEntityTypeListSaga() {
    yield* takeLatest(actions.getEntityTypeList, getEntityTypeList);
}

export function* getOperationTypeList() {
    try {
        const result = yield* getApiOperationTypeList();
        yield* put(actions.getOperationTypeListSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'EventLogPage.getOperationTypeList',
        });
        yield* put(actions.getOperationTypeListError());
    }
}

export function* getOperationTypeListSaga() {
    yield* takeLatest(actions.getOperationTypeList, getOperationTypeList);
}
