import { getAdminRoutePermission } from '../../adminRoutes';
import { permissionMaker } from '../../permissionExpression';

const { role, every, some, nobody, anyone } = permissionMaker;

const getFullList = role('ROLE_RESERVATION_LIST');
const getMyList = anyone();
const reservatorFilter = every(
    'ROLE_RESERVATION_USER_LIST',
    'ROLE_RESERVATION_PASSENGER_LIST_FILTERS',
);
const ownerFilter = every(
    every(
        'ROLE_AGENT_LIST',
        'ROLE_CUSTOMER_LIST',
        'ROLE_USER_READ_ACCOUNT',
    ),
    'ROLE_RESERVATION_PASSENGER_LIST_FILTERS',
);
const createLink = getAdminRoutePermission((x) => x.reservationNew);
const editLink = getAdminRoutePermission((x) => x.reservationEdit);
const splitReservation = role('ROLE_RESERVATION_SPLIT');
const historyLink = getAdminRoutePermission((x) => x.reservationHistory);

const fullyPaidInvoicePdf = anyone();
/** Also partly */
const notPaidInvoicePdf = role('ROLE_RESERVATION_INVOICE_PDF_BEFORE_PAID');
const invoicePdf = anyone();

const fullyPaidTicketPdf = anyone();
const partlyPaidTicketPdf = role('ROLE_RESERVATION_TICKET_PDF_BEFORE_PAID');
const notPaidTicketPdf = nobody();
const ticketPdf = some(fullyPaidTicketPdf, partlyPaidTicketPdf, notPaidTicketPdf);

const cancel = role('ROLE_RESERVATION_CANCEL');
const cancelWithDepartedFlight = every(
    'ROLE_RESERVATION_CANCEL',
    'ROLE_RESERVATION_EDIT_DEPARTED_FLIGHT',
);
const payMyReservationLink = getAdminRoutePermission((x) => x.reservationPayment);
const payOtherReservationLink = every(
    getAdminRoutePermission((x) => x.reservationPayment),
    'ROLE_USER_READ_ACCOUNT',
    'ROLE_RESERVATION_READ',
);
const sendSmsNotification = role('ROLE_ADMIN');
const bulkActions = sendSmsNotification;

export const reservationListPermissions = {
    getFullList,
    getMyList,
    reservatorFilter,
    ownerFilter,
    createLink,
    editLink,
    splitReservation,
    historyLink,
    fullyPaidInvoicePdf,
    notPaidInvoicePdf,
    invoicePdf,
    fullyPaidTicketPdf,
    partlyPaidTicketPdf,
    notPaidTicketPdf,
    ticketPdf,
    cancel,
    cancelWithDepartedFlight,
    payMyReservationLink,
    payOtherReservationLink,
    sendSmsNotification,
    bulkActions,
};
