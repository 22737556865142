import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectReservationBasket } from '../../../../containers/ReservationBasket/reservationBasket.selectors';

export const useStepRequirements = () => {
    const { selectedFlights, selectedPrices, travelType } = useSelector(selectReservationBasket);

    return useMemo(() => {
        const stepRequiredReservationValues = [
            selectedFlights?.outbound,
            selectedPrices?.outbound,
            travelType,
        ];

        return stepRequiredReservationValues.indexOf(undefined) === -1;
    }, [selectedFlights?.outbound, selectedPrices?.outbound, travelType]);
};
