import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.allotmentInvoicePopup || initialState;

export const selectAllotmentInvoicePopupMode = createSelector(
    [selectDomain],
    state => state.mode,
);

export const selectAllotmentInvoicePopupFormData = createSelector(
    [selectDomain],
    state => state.formData,
);

export const selectAllotmentInvoicePopupOpen = createSelector(
    [selectDomain],
    state => state.open,
);

export const selectAllotmentInvoicePopupLoading = createSelector(
    [selectDomain],
    state => state.loading,
);

export const selectAllotmentInvoicePopupAllotments = createSelector(
    [selectDomain],
    state => state.allotments,
);

export const selectAllotmentInvoicePopupAgent = createSelector(
    [selectDomain],
    state => state.agent,
);

export const selectAllotmentInvoicePopupInvoice = createSelector(
    [selectDomain],
    state => state.invoice,
);
