import { PriceListItem, RateListItem, Tariff } from '@malesia/json-schema';
import { TableColumn } from '@malesia/react-components/dist/src/components/Table';
import { idColumn } from '@malesia/react-components/dist/src/components/Table/columns/idColumn';
import { localizeMoney } from '@malesia/react-components/dist/src/utils/roundMoney';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { rateListPermissions } from '../../permissions/adminPages/rate/rateList';
import { UserPermissions } from '../../permissions/userPermissions';
import { useUserPermissions } from '../../permissions/useUserPermissions';
import { rateActionsColumn } from './actionsColumn';

type TableColumnsOptions = {
    locale?: string,
    tariffs?: Tariff[],
    history: ReturnType<typeof useHistory>,
    userPermissions: UserPermissions,
};
const tableColumns = (
    { locale, tariffs = [], history, userPermissions }: TableColumnsOptions,
): TableColumn<RateListItem>[] => ([
    idColumn(),
    {
        Header: (
            <FormattedMessage
                id='front-app/RateListPage:ColumnName'
                defaultMessage='Name'
            />
        ),
        accessor: 'name',
    },
    ...tariffs.map((tariff: Tariff, index) => {
        return {
            Header:
                (locale && tariff.name[locale]) || tariff.name[Object.keys(tariff.name)[0]],
            id: `tariff${index}`,
            Cell: ({ row }) => {
                const rate = row.original;

                const { locale } = useIntl();
                const priceListItem: PriceListItem | undefined = rate.priceList
                    ?.filter(item => item.tariff.id === tariff.id)
                    .pop();

                const valueIncludingFees = priceListItem
                    ? priceListItem.priceStandard! + priceListItem.fuelFee! + priceListItem.airportFee!
                    : undefined;
                return priceListItem ? `${localizeMoney(valueIncludingFees, locale, 'RateListPage')} CHF` : '';
            },
        };
    }),
    rateActionsColumn({
        editRate: {
            click: (rate) => {
                const path = generatePath(allAdminRoutePaths.rateEdit, {
                    rateId: rate.id,
                });
                history.push(path);
            },
            notAllowed: !userPermissions.has(rateListPermissions.editLink),
        },
    }),
]);

type UseTableColumnsOptions = {
    locale?: string,
    tariffs?: Tariff[],
};
export const useTableColumns = (options: UseTableColumnsOptions): TableColumn<RateListItem>[] => {
    const { locale, tariffs } = options;

    const history = useHistory();
    const userPermissions = useUserPermissions();

    const result = useMemo(() => tableColumns({
        locale,
        tariffs,
        history,
        userPermissions,
    }), [
        locale,
        tariffs,
        history,
        userPermissions,
    ]);

    return result;
};
