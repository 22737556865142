import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    selectPassengers,
    selectSelectedFlightVariants,
    selectTravelType,
} from '../../../../containers/ReservationBasket/reservationBasket.selectors';

export const useStepSeatsReservationRequirements = () => {
    const selectedFlights = useSelector(selectSelectedFlightVariants);
    const travelType = useSelector(selectTravelType);
    const passengers = useSelector(selectPassengers);

    const stepRequiredReservationValues = useMemo(() => [
        passengers,
        (passengers && passengers.length > 0) || undefined,
        !(passengers && passengers
            .map(passenger => passenger.info.firstName && passenger.info.lastName)
            .some(item => item === undefined)
        ) || undefined,
        selectedFlights.outbound,
        travelType,
    ], [passengers, selectedFlights.outbound, travelType]);

    return stepRequiredReservationValues.indexOf(undefined) === -1;

};
