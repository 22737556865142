import { RateList } from '@malesia/react-components/dist/src/components/Rate/List';
import { SortingDataType } from '@malesia/react-components/dist/src/components/Table';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocale } from '../../../containers/App/selectors';
import {
    selectList,
    selectIsLoading,
    selectFilterData,
    selectTariffData,
    selectIsDirty,
} from '../selectors';
import { actions } from '../slice';
import { useTableColumns } from '../tableColumns';

export const RateListWithStore: SFC = () => {
    const rateList = useSelector(selectList);
    const tariffData = useSelector(selectTariffData);
    const filterData = useSelector(selectFilterData);
    const isLoading = useSelector(selectIsLoading);
    const isDirty = useSelector(selectIsDirty);

    const dispatch = useDispatch();

    const locale = useSelector(selectLocale);

    useEffect(() => {
        dispatch(actions.getTariffList());
    }, [dispatch]);

    const rateListColumns = useTableColumns({
        locale,
        tariffs: tariffData?.items || [],
    });

    const handleSetSort = (data: SortingDataType) => {
        dispatch(actions.setSorting(data));
    };

    return (
        <RateList
            isLoading={isLoading}
            isDirty={isDirty}
            onSubmit={(data) => {
                dispatch(actions.updateFilterData(data));
            }}
            searchText={filterData.searchText}
            isActiveOnly={filterData.isActiveOnly}
            tableColumns={rateListColumns}
            tableData={rateList?.items}
            sortBy={filterData.sortBy}
            sortOrder={filterData.sortOrder}
            setSort={handleSetSort}
        />
    );
};
