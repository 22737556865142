import { useInjectReducer, useInjectSaga } from '../../../../utils/redux-injectors';
import { useInjectReservationPdf } from '../../../containers/ReservationPdf/inject';
import { createPaymentSaga } from './sagas/createPayment.saga';
import { loadInitialDataSaga } from './sagas/loadInitalData.saga';
import { reservationPaymentActions, reservationPaymentReducer, reservationPaymentSliceName } from './slice';

export const useInjectReservationPaymentPage = () => {
    useInjectReservationPdf();
    useInjectReducer({
        actions: reservationPaymentActions,
        key: reservationPaymentSliceName,
        reducer: reservationPaymentReducer,
    });

    useInjectSaga({
        key: 'reservationPayment.loadInitialData',
        saga: loadInitialDataSaga,
    });
    useInjectSaga({
        key: 'reservationPayment.createPaymentSaga',
        saga: createPaymentSaga,
    });
};
