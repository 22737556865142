import { NotificationType } from '@malesia/react-components/dist/src/components/Page/Notifications/Notifications';
import { mapLocaleToLocaleCode } from '@malesia/react-components/dist/src/utils/locale';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { AppState } from './types';

export const initialState: AppState = {
    loading: false,
    error: false,
    locale: mapLocaleToLocaleCode(localStorage.getItem('selectedLocale') ?? navigator.language),
    notifications: [],
};

const appContainerSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setLocale(state, action: PayloadAction<string>) {
            state.locale = action.payload;
            localStorage.setItem('selectedLocale', action.payload);
        },
        addNotification(state, action: PayloadAction<NotificationType>) {
            (state.notifications as NotificationType[]).push(action.payload);
        },
        deleteNotification(state, action: PayloadAction<string>) {
            const notificationId = action.payload;
            state.notifications = state.notifications.filter(notification => notification.id !== notificationId);
        },
        chargeAccount(state, action: PayloadAction<number>) {},
    },
});

export const {
    actions: appActions,
    reducer: appReducer,
    name: appSliceKey,
} = appContainerSlice;
