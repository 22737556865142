import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.newTransaction || initialState;

export const selectDebitAccountList = createSelector(
    [selectDomain],
    state => state.debitAccountList,
);

export const selectCreditAccountList = createSelector(
    [selectDomain],
    state => state.creditAccountList,
);

export const selectIsSubmitting = createSelector(
    [selectDomain],
    root => root.isSubmitting,
);

export const selectPaymentTypeList = createSelector(
    [selectDomain],
    state => state.paymentTypeList,
);
