import { call } from 'typed-redux-saga';

export function* downloadFile(response: Response, filename: string) {
    const blob = yield* call([response, response.blob]);
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    link.click();
    URL.revokeObjectURL(url);
}

export function* downloadNamedFile(response: Response, filename: string) {
    const contentFilename = response.headers.get('Content-Disposition')!
        .split(';').map(header => header.split('=').map(item => item.trim()))
        .filter(([key, value]) => key === 'filename').map(([key, value]) => value)?.[0];
    yield* downloadFile(response, contentFilename || filename);
}
