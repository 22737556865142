import { createSelector } from '@reduxjs/toolkit';
import { RouterRootState } from 'connected-react-router';
import { RootState } from '../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => {
    return state.global || initialState;
};

export const selectLocale = createSelector(
    [selectDomain],
    state => state.locale,
);

export const selectRoot = createSelector(
    [(state: RouterRootState<unknown>) => state],
    rootState => rootState,
);

export const selectNotifications = createSelector(
    [selectDomain],
    state => state.notifications,
);
