import { NotificationType } from '@malesia/react-components/dist/src/components/Page/Notifications/Notifications';
import { PayloadAction } from '@reduxjs/toolkit';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { logError } from '../../../utils/log';
import { checkApiError } from '../../services/ApiClient';
import { appNotification } from '../App/appNotification';
import { createLocalNotification } from '../App/utils';

const notification = createLocalNotification('AllotmentInvoicePopup', {
    error: (
        <FormattedMessage
            id='front-app/AllotmentInvoicePopup/Error:title'
            defaultMessage='Allotment Invoice Popup Error'
        />
    ),
    info: (
        <FormattedMessage
            id='front-app/AllotmentInvoicePopup/Info:title'
            defaultMessage='Allotment Invoice Popup Info'
        />
    ),
    success: (
        <FormattedMessage
            id='front-app/AllotmentInvoicePopup/Info:title'
            defaultMessage='Allotment Invoice Popup Info'
        />
    ),
});

export const allotmentInvoicePopupNotifications = {
    invoiceCreatedSuccessfully: notification.success(
        <FormattedMessage
            id='front-app/AllotmentInvoicePopup/Info:invoiceCreatedSuccessfully'
            defaultMessage='Invoice created successfully'
        />,
    ),
    invoiceUpdatedSuccessfully: notification.success(
        <FormattedMessage
            id='front-app/AllotmentInvoicePopup/Info:invoiceUpdatedSuccessfully'
            defaultMessage='Invoice updated successfully'
        />,
    ),
    paymentDeadlineEarlierInvoiceDate: notification.error(
        <FormattedMessage
            id='front-app/AllotmentInvoicePopup/Error:paymentDeadlineEarlierInvoiceDate'
            defaultMessage='Payment deadline is earlier than invoice date.'
        />,
    ),
    discountMoreDebt: notification.error(
        <FormattedMessage
            id='front-app/AllotmentInvoicePopup/Error:discountMoreDebt'
            defaultMessage='Discount is more than invoice debt.'
        />,
    ),
    unknownError: (details?: JSX.Element | string) => (
        notification.error(appNotification.texts.unknownError(details))
    ),
};

export const parseAllotmentError = (
    rawError: unknown,
): PayloadAction<NotificationType> => {
    if (!checkApiError(rawError)) {
        logError({
            target: 'notifyFlightError.Unhandled',
            error: rawError,
        });
        return allotmentInvoicePopupNotifications.unknownError();
    }
    const item = rawError.response?.errors?.[0];
    switch (item?.error) {
        case 'ERROR_PAYMENT_DEADLINE_EARLIER_INVOICE_DATE': {
            return allotmentInvoicePopupNotifications.paymentDeadlineEarlierInvoiceDate;
        }
        case 'ERROR_DISCOUNT_MORE_INVOICE_DEBT': {
            return allotmentInvoicePopupNotifications.discountMoreDebt;
        }
        default: {
            logError({
                error: item,
                target: 'notifyFlightError.UnhandledCase',
            });
            return allotmentInvoicePopupNotifications.unknownError(rawError.response.message);
        }
    }
};
