import React from 'react';
import { FormattedMessage } from 'react-intl';
import { appNotification } from '../../containers/App/appNotification';
import { createLocalNotification } from '../../containers/App/utils';

const notification = createLocalNotification('CustomerList', {
    error: (
        <FormattedMessage
            id='front-app/CustomerList/Notification/Error:title'
            defaultMessage='Customer List Error'
        />
    ),
});

export const customerNotifications = {
    unknownError: (details?: JSX.Element | string) => (
        notification.error(appNotification.texts.unknownError(details))
    ),
};
