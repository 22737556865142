import { NotificationType } from '@malesia/react-components/dist/src/components/Page/Notifications/Notifications';
import { PayloadAction } from '@reduxjs/toolkit';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { logError } from '../../../utils/log';
import { appNotification } from '../../containers/App/appNotification';
import { createLocalNotification } from '../../containers/App/utils';
import { checkApiError } from '../../services/ApiClient';

const notification = createLocalNotification('PassengerList', {
    error: (
        <FormattedMessage
            id='front-app/PassengerList/Notification/Error:title'
            defaultMessage='Passenger List Error'
        />
    ),
    warning: (
        <FormattedMessage
            id='front-app/PassengerList/Notification/Warning:title'
            defaultMessage='Passenger List Warning'
        />
    ),
    info: (
        <FormattedMessage
            id='front-app/PassengerList/Notification/Info:title'
            defaultMessage='Passenger List Info'
        />
    ),
    success: (
        <FormattedMessage
            id='front-app/PassengerList/Notification/Info:title'
            defaultMessage='Passenger List Info'
        />
    ),
});

export const passengerNotifications = {
    unknownError: (details?: JSX.Element | string) => (
        notification.error(appNotification.texts.unknownError(details))
    ),
};

export const parsePassengerError = (
    passengerError: unknown,
): PayloadAction<NotificationType> => {
    if (!checkApiError(passengerError)) {
        logError({
            target: 'notifyReservationError.Unhandled',
            error: passengerError,
        });
        return passengerNotifications.unknownError();
    }
    const error = passengerError.response?.errors?.[0];
    switch (error?.error) {
        case 'ERROR_SEAT_LABEL_NOT_UNIQUE': {
            return notification.error((
                <FormattedMessage
                    id='front-app/FlightPassengerMessages:SeatAlreadySold'
                    defaultMessage='Error: This seat is already sold. Please select another one.'
                />
            ));
        }
        default: {
            logError({
                error,
                target: 'notifyReservationError.UnhandledCase',
            });
            return passengerNotifications.unknownError(passengerError.response.message);
        }
    }
};
