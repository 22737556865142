import { useSelector } from 'react-redux';
import { selectUserLoginIsAuthorized } from '../containers/UserLogin/selectors';
import { useUserPermissions } from './useUserPermissions';

export const useIsAdminSite = () => {
    const userIsLoggedIn = useSelector(selectUserLoginIsAuthorized);
    const userPermissions = useUserPermissions();

    return userPermissions.isAdminSiteAccess && userIsLoggedIn;
};
