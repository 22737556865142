import { useInjectReducer, useInjectSaga } from '../../../../utils/redux-injectors';
import { useInjectAllotmentInvoicePdf } from '../../../containers/AllotmentInvoicePdf/inject';
import { useInjectAllotmentInvoicePopup } from '../../../containers/AllotmentInvoicePopup/inject';
import { useInjectAuxiliaryData } from '../../../containers/AuxiliaryData/inject';
import { getAllotmentListSaga } from './saga';
import { actions, reducer, sliceKey } from './slice';

export const useInjectAllotmentList = () => {
    useInjectAuxiliaryData();
    useInjectAllotmentInvoicePdf();
    useInjectAllotmentInvoicePopup();
    useInjectReducer({ actions, key: sliceKey, reducer });
    useInjectSaga({
        key: `${sliceKey}getAllotmentListSaga`,
        saga: getAllotmentListSaga,
    });
};
