import { LoadingOverlay } from '@malesia/react-components/dist/src/components/LoadingOverlay';
import { PopupWrapper } from '@malesia/react-components/dist/src/components/Popup/PopupWrapper';
import { ReservationCancellationPopup } from '@malesia/react-components/dist/src/components/Reservation/CancellationPopup';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCancelPopupLoading, selectCancelPopupOpen, selectCancelPopupReservation } from '../store/selectors';
import { actions } from '../store/slice';

export const ReservationCancellationPopupWithStore: SFC<object, { result: 'optional' }> = () => {
    const dispatch = useDispatch();
    const open = useSelector(selectCancelPopupOpen);
    const loading = useSelector(selectCancelPopupLoading);
    const reservation = useSelector(selectCancelPopupReservation);

    const handleCloseCancelPopup = useCallback(() => {
        dispatch(actions.closeCancelReservation());
    }, [dispatch]);

    const handleConfirmCancelPopup = useCallback((fee: number) => {
        dispatch(actions.confirmCancelReservation(fee));
    }, [dispatch]);

    if (!reservation) return null;

    return (
        <PopupWrapper
            isOpened={open}
            onClose={handleCloseCancelPopup}
        >
            <LoadingOverlay isLoading={loading}>
                <ReservationCancellationPopup
                    onCancel={handleCloseCancelPopup}
                    onConfirm={handleConfirmCancelPopup}
                    total={reservation.invoice?.total ?? 0}
                    outboundFlight={reservation.flights!.outbound}
                    outboundPassengers={reservation.passengers ?? []}
                    returnFlight={reservation.flights?.return}
                    returnPassengers={reservation.passengers ?? []}
                />
            </LoadingOverlay>
        </PopupWrapper>
    );
};
