import { getAdminRoutePermission } from '../../adminRoutes';
import { permissionMaker } from '../../permissionExpression';

const { role, anyone } = permissionMaker;

const markAppearing = role('ROLE_FLIGHT_PASSENGER_MARKING');
const fullyPaidInvoicePdf = anyone();

const reservationPaymentLink = getAdminRoutePermission((x) => x.reservationPayment);
const editReservationLink = getAdminRoutePermission((x) => x.reservationEdit);

const updateFlightDelayed = role('ROLE_FLIGHT_DELAYED');
const updateFlightDeparted = role('ROLE_FLIGHT_STATUS');
const updateFlightPassengerNotified = role('ROLE_FLIGHT_PASSENGER_MARKING');

const updateFlightPassengerSeat = role('ROLE_FLIGHT_PASSENGER_SEAT');
const bulkActions = role('ROLE_FLIGHT_PASSENGER_LIST_BULK_ACTIONS');

export const flightPassengerListPermissions = {
    markAppearing,
    fullyPaidInvoicePdf,
    reservationPaymentLink,
    editReservationLink,
    updateFlightDelayed,
    updateFlightDeparted,
    updateFlightPassengerNotified,
    updateFlightPassengerSeat,
    bulkActions,
};
