import { SeatInputPopup, SeatInputPopupProps } from '@malesia/react-components/dist/src/components/Reservation/AircraftSeatsSelection/SeatInputPopup';
import { SeatSelectionPopup, SeatSelectionPopupProps } from '@malesia/react-components/dist/src/components/Reservation/AircraftSeatsSelection/SeatSelectionPopup';
import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeBookedSeatSections } from '../../../../utils/reservation/seatMap';
import { coercionDataToSchema } from '../../../../utils/validation/coercion';
import { formatSchemaValidationErrors } from '../../../containers/ValidationErrorMessage';
import {
    selectAircraftTemplate,
    selectIsShowSeatPopup,
    selectSeatPopupInfo,
} from '../selectors';
import { actions } from '../slice';

export function PassengerSeatSelectionPopup(): ReactElement | null {
    const aircraftTemplate = useSelector(selectAircraftTemplate);
    const isShowSeatPopup = useSelector(selectIsShowSeatPopup);
    const seatPopupInfo = useSelector(selectSeatPopupInfo);

    const dispatch = useDispatch();

    // TODO think about moving this logic to saga (because use the selected seatLabel)
    const confirmSeatInput = (seatLabel?: string) => {
        if (!seatLabel) {
            dispatch(actions.askSeatPopupConfirm(undefined));
            return;
        }
        const [validation, , errors] = coercionDataToSchema(
            'file://malesiareisen.com/json/schema/project/api/model/reservationPassengerFlight.json',
            { seatLabel },
        );
        const allErrors = errors ?? [];
        if (validation && allErrors.length === 0) {
            dispatch(actions.askSeatPopupConfirm(seatLabel));
        }
        else {
            dispatch(actions.setSeatLabelErrors(formatSchemaValidationErrors(allErrors)));
        }
    };

    const close = () => {
        dispatch(actions.askSeatPopupCancel());
    };

    if (!isShowSeatPopup) return null;
    if (aircraftTemplate) {
        const seatSelectionPopupProps: SeatSelectionPopupProps = {
            passengers: [{
                id: 0,
                seatLabel: seatPopupInfo.seatLabel,
            }],
            selectedPassengerId: 0,
            sections: removeBookedSeatSections(aircraftTemplate.sections, [seatPopupInfo.bookedSeatLabel]),
            tariffGroupCode: seatPopupInfo.tariffGroupCode,
            resetSeat: () => dispatch(actions.askSeatPopupConfirm(undefined)),
            selectSeat: (seatLabel) => dispatch(actions.askSeatPopupConfirm(seatLabel)),
            close,
        };
        return <SeatSelectionPopup {...seatSelectionPopupProps} />;
    }
    const seatInputPopupProps: SeatInputPopupProps = {
        seatLabel: seatPopupInfo.seatLabel,
        errors: seatPopupInfo.errors,
        selectSeat: confirmSeatInput,
        close,
    };
    return <SeatInputPopup {...seatInputPopupProps} />;
}
