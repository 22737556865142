import { AuthUpdatePasswordPostRequest } from '@malesia/json-schema';
import {
    UpdatePasswordForm,
    UpdatePasswordFormData,
    UpdatePasswordFormChangeEvent,
    UpdatePasswordFormErrors,
} from '@malesia/react-components/dist/src/components/Auth/UpdatePasswordForm';
import { FrontPageContentBlock } from '@malesia/react-components/dist/src/components/Front/Page/ContentBlock';
import { FrontPageImage } from '@malesia/react-components/dist/src/components/Front/Page/MainImage';
import { FrontPageTitle } from '@malesia/react-components/dist/src/components/Front/Page/Title';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import { ErrorObject } from 'ajv';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { getValidationErrors } from '../../../api/malesia';
import {
    useInjectReducer,
    useInjectSaga,
} from '../../../utils/redux-injectors';
import { coercionDataToSchema } from '../../../utils/validation/coercion';
import {
    formatSchemaValidationErrors,
    prepareNotEmptyError,
    preparePasswordValidationError,
} from '../../containers/ValidationErrorMessage';
import messages from './messages';
import { submitDataSaga } from './saga';
import {
    selectMalesiaUpdatePasswordPageFormIsLoading,
    selectMalesiaUpdatePasswordPageFormResultIsSuccessful,
    selectMalesiaUpdatePasswordPageFormUntouchedFields,
    selectMalesiaUpdatePasswordPageFormValidationErrors,
} from './selectors';
import { actions, reducer, sliceKey } from './slice';

type RouteParams = {
    recoveryToken: string,
};

type Props = RouteComponentProps<RouteParams>;

export const MalesiaUpdatePasswordPage: SFC<Props> = (props) => {
    useInjectReducer({ key: sliceKey, reducer });
    useInjectSaga({
        key: `${sliceKey}SubmitDataSaga`,
        saga: submitDataSaga,
    });

    const dispatch = useDispatch();

    // Extract recovery token from Url
    const { match } = props;
    const { params } = match;
    const { recoveryToken } = params;

    const formValidationErrors = useSelector(
        selectMalesiaUpdatePasswordPageFormValidationErrors,
    );
    const formUntouchedFields = useSelector(
        selectMalesiaUpdatePasswordPageFormUntouchedFields,
    );
    const changedUntouchedFields = Array.from(formUntouchedFields);
    const formIsLoading = useSelector(
        selectMalesiaUpdatePasswordPageFormIsLoading,
    );
    const formResultIsSuccessful = useSelector(
        selectMalesiaUpdatePasswordPageFormResultIsSuccessful,
    );

    /**
   * Maps validation errors from ErrorObject[] to RecoverPasswordFormErrors
   * @param formValidationErrors
   */
    const mapValidationErrorsToFormErrors = (
        formValidationErrors: ErrorObject[],
    ): UpdatePasswordFormErrors => {
        return formatSchemaValidationErrors(formValidationErrors);
    };

    const displayedErrors = mapValidationErrorsToFormErrors(formValidationErrors);
    const tokenIsInvalid = !!displayedErrors['recoveryToken'];

    /**
   * Validate form data
   * @param formData
   */
    const validateForm = (formData: UpdatePasswordFormData) => {
        const { password, passwordConfirm } = formData;
        const validationData = {
            password,
            passwordConfirm,
        };
        const schemaValidationErrors = getValidationErrors(
            '/json/schema/mixins/passwordWithConfirm.json',
            validationData,
        );

        const allErrors = [
            ...(schemaValidationErrors ?? []),
            ...prepareNotEmptyError('/password', formData),
            ...prepareNotEmptyError('/passwordConfirm', formData),
            ...preparePasswordValidationError([], validationData),
        ];

        const isValid = allErrors.length === 0;

        dispatch(
            actions.updateValidationStatus({
                formIsValid: isValid,
                formUntouchedFields: changedUntouchedFields,
                formValidationErrors: allErrors,
            }),
        );
        return isValid;
    };

    /**
   * Handle form field change event
   * @param event
   */
    const handleFormChange = (event: UpdatePasswordFormChangeEvent) => {
    // eslint-disable-next-line no-unused-vars
        const { fieldName, formData } = event;
        // Touch field
        if (changedUntouchedFields.indexOf(fieldName) >= 0) {
            changedUntouchedFields.splice(
                changedUntouchedFields.indexOf(fieldName),
                1,
            );
        }
    // Validation on typing disabled
    // validateForm(formData);
    };

    /**
   * Handle form submit
   * @param formData
   */
    const handleFormSubmit = (formData: UpdatePasswordFormData) => {
        if (!validateForm(formData)) {
            return;
        }
        const { password } = formData;
        const postData: AuthUpdatePasswordPostRequest = {
            recoveryToken,
            password,
        };

        const [isValid, updatedData, errors] = coercionDataToSchema(
            'file://malesiareisen.com/json/schema/project/api/call/auth/updatePassword/post/request.json',
            postData,
        );

        if (isValid) {
            dispatch(actions.submitData(updatedData));
        }
        else {
            dispatch(actions.submitValidationError(errors || []));
        }
    };

    /**
   * Return component
   */
    return (
        <>
            <>
                <FrontPageImage src='/images/front-page-image-history.jpg' />
                <FrontPageContentBlock>
                    <FrontPageTitle
                        text={
                            <FormattedMessage
                                {...messages.header}
                                defaultMessage='Reset password'
                            />
                        }
                    />
                    {!tokenIsInvalid && (
                        <>
                            {!formResultIsSuccessful && (
                                <UpdatePasswordForm
                                    formErrors={displayedErrors}
                                    isSubmitButtonDisabled={formIsLoading}
                                    onChange={handleFormChange}
                                    onSubmit={handleFormSubmit}
                                />
                            )}
                            {formResultIsSuccessful && (
                                <p>
                                    <FormattedMessage
                                        id='front-app/MalesiaUpdatePasswordPage/SuccessMessage:text'
                                        defaultMessage='Your password has been successfully updated!'
                                    />
                                </p>
                            )}
                        </>
                    )}
                    {tokenIsInvalid && (
                        <p>
                            <FormattedMessage
                                id='front-app/MalesiaUpdatePasswordPage/InvalidTokenMessage:text'
                                defaultMessage='This recovery link is invalid or expired. Please try to get a new link.'
                            />
                        </p>
                    )}
                </FrontPageContentBlock>
            </>
        </>
    );
};
