import { Pagination } from '@malesia/react-components/dist/src/components/Pagination';
import { TableFooter } from '@malesia/react-components/dist/src/components/Table/TableFooter';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectList, selectPagination } from '../selectors';
import { actions } from '../slice';

export const CustomerListFooterWithStore: SFC = () => {
    const list = useSelector(selectList);
    const pagination = useSelector(selectPagination);

    const dispatch = useDispatch();

    return (
        <TableFooter
            paginationSlot={
                <Pagination
                    total={list.total}
                    pageTotal={list.items.length}
                    pagination={pagination}
                    maxPageSize={50}
                    setPage={(data) => {
                        dispatch(actions.setPage(data));
                    }}
                    setPageSize={(data) => {
                        dispatch(actions.setPageSize(data));
                    }}
                />
            }
        />
    );
};
