import { Agent, Allotment } from '@malesia/json-schema';
import { BackLink } from '@malesia/react-components/dist/src/components/BackLink';
import { FlightEdit, FlightEditProps } from '@malesia/react-components/dist/src/components/Flight/Edit';
import { LoadingForm } from '@malesia/react-components/dist/src/components/LoadingOverlay/Form';
import { LoadingPage } from '@malesia/react-components/dist/src/components/LoadingOverlay/Page';
import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { ValueConfig, useValueForm } from '@malesia/react-components/dist/src/hooks/valueForm';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { selectAgentList, selectAgentQuery } from '../../containers/AuxiliaryData/selectors';
import { auxiliaryActions } from '../../containers/AuxiliaryData/slice';
import { FlightAircraftTemplatePopup } from '../../containers/FlightAircraftTemplate/FligthAircraftTemplatePopup';
import { backLinks } from '../../containers/Routes/backLinks';
import { flightNewPermissions } from '../../permissions/adminPages/flight/flightNew';
import { useUserPermissions } from '../../permissions/useUserPermissions';
import { useFlightConfig } from '../FlightNewPage/config';
import { flightNotifications } from '../FlightNewPage/store/notification';
import { AircraftChangingConfirmPopup } from './components/AircraftChangingConfirmPopup';
import { NotifyingConfirmPopup } from './components/NotifyingConfirmPopup';
import { useInjectFlightEdit } from './store/inject';
import {
    selectCreationFlightData,
    selectIsLoadingCreationFlightData,
    selectOldFlightData,
    selectFlightFormData,
    selectLoading,
    selectShowSettingButton,
    selectAllotments,
} from './store/selectors';
import { actions } from './store/slice';

export type RouteParams = {
    flightId: string,
};
export type FlightEditPageProps = RouteComponentProps<RouteParams>;

export const FlightEditPage: SFC<FlightEditPageProps> = (props) => {
    useInjectFlightEdit();

    const oldFlightData = useSelector(selectOldFlightData);
    const creationFlightData = useSelector(selectCreationFlightData);
    const isLoadingCreationFlightData = useSelector(selectIsLoadingCreationFlightData);
    const flightFormData = useSelector(selectFlightFormData);
    const allotments = useSelector(selectAllotments);
    const loading = useSelector(selectLoading);
    const agentList = useSelector(selectAgentList);
    const agentQuery = useSelector(selectAgentQuery);
    const userPermissions = useUserPermissions();

    const showSettingsButton = useSelector(selectShowSettingButton);
    const dispatch = useDispatch();

    const { match } = props;
    const { params } = match;
    const { flightId } = params;

    useEffect(() => {
        dispatch(actions.getCreationFlightData());
        dispatch(auxiliaryActions.searchAgentList(''));
    }, [dispatch]);

    useEffect(() => {
        if (!flightId) return;
        dispatch(actions.getFlightData(flightId));
    }, [flightId, dispatch]);

    const config = useFlightConfig('edit', creationFlightData, oldFlightData);

    const addAllotmentForm = useValueForm<Agent>({
        config: (): ValueConfig<Agent> => ({
            type: 'object',
            items: agentList,
            query: agentQuery,
            setQuery: (value) => {
                dispatch(auxiliaryActions.searchAgentList(value));
            },
            hidden: !userPermissions.has(flightNewPermissions.editAllotment),
        }),
        onChange: (agent) => {
            const allotment = {
                agent,
                tariffGroup: creationFlightData.tariffGroupList.items[0],
            } as Allotment;
            dispatch(actions.setAllotmentData([...allotments, allotment]));
        },
    });

    const flightEditProps: FlightEditProps = {
        info: {
            config: config.flightConfig,
            propsValues: flightFormData,
            onChange: (data) => {
                dispatch(actions.setFlightData(data));
            },
            validateForm: config.validateFlight,
            allowedDays: {
                departure: oldFlightData?.departure,
                arrival: oldFlightData?.arrival,
            },
        },
        allotment: {
            addAllotmentField: addAllotmentForm.field,
            removeHidden: (allotment) => (
                !!allotment.invoice
                || !userPermissions.has(flightNewPermissions.editAllotment)
            ),
            config: config.allotmentConfig,
            propsValues: allotments,
            onChange: (data) => {
                dispatch(actions.setAllotmentData(data));
            },
            allowed: userPermissions.has(flightNewPermissions.readAllotment),
        },
        templateSettings: {
            click: () => {
                dispatch(actions.clickSettings());
            },
            canClick: showSettingsButton,
        },
        tariff: {
            tariffGroups: creationFlightData.tariffGroupList.items,
            config: config.seatsConfig,
        },
        repeating: {
            allowed: false,
        },
        submit: () => {
            dispatch(actions.requestUpdateFlightData(flightId));
        },
        notifyIncorrectForm: () => {
            dispatch(flightNotifications.checkFields);
        },
    };

    return (
        <PageContentBlock>
            <PageTitle
                text={
                    <FormattedMessage
                        id='front-app/FlightEditPage:Title'
                        defaultMessage='Edit flight'
                    />
                }
            />
            <BackLink
                to={backLinks.flight.back({})}
                modificators='stick-to-title'
            />
            <LoadingPage isLoading={isLoadingCreationFlightData} />
            {!isLoadingCreationFlightData && flightFormData && (
                <LoadingForm isLoading={loading}>
                    <FlightEdit {...flightEditProps} />
                </LoadingForm>
            )}
            <FlightAircraftTemplatePopup />
            <NotifyingConfirmPopup />
            <AircraftChangingConfirmPopup />
        </PageContentBlock>
    );
};
