import { BalanceLogHeader } from '@malesia/react-components/dist/src/components/Accounting/BalanceLog/BalanceLogHeader';
import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { AccountingBalanceLogFilterWithStore } from './components/AccountingBalanceLogFilterWithStore';
import { AccountingBalanceLogFooterWithStore } from './components/AccountingBalanceLogFooterWithStore';
import { AccountingBalanceLogWithStore } from './components/AccountingBalanceLogWithStore';
import { useInjectBalanceLog } from './store/inject';
import { selectFilterData } from './store/selectors';

export const BalanceLogPage: SFC = () => {
    useInjectBalanceLog();

    const filterData = useSelector(selectFilterData);

    return (
        <PageContentBlock>
            <BalanceLogHeader
                title={
                    <FormattedMessage
                        id='front-app/BalanceLogPage:Title'
                        defaultMessage='Balance log'
                    />
                }
                type={filterData.account?.type}
            />
            <AccountingBalanceLogFilterWithStore />
            <AccountingBalanceLogWithStore />
            <AccountingBalanceLogFooterWithStore />
        </PageContentBlock>
    );
};
