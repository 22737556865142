import React from 'react';
import { FormattedMessage } from 'react-intl';
import { appNotification } from '../../../containers/App/appNotification';
import { createLocalNotification } from '../../../containers/App/utils';

const notification = createLocalNotification('AllotmentInvoiceList', {
    error: (
        <FormattedMessage
            id='front-app/AllotmentInvoiceList/Notification/Error:title'
            defaultMessage='Allotment Invoice List Error'
        />
    ),
    success: (
        <FormattedMessage
            id='front-app/AllotmentInvoiceList/Notification/Info:title'
            defaultMessage='Allotment Invoice List Info'
        />
    ),
});

export const allotmentInvoiceListNotifications = {
    invoiceDeletedSuccessfully: notification.success((
        <FormattedMessage
            id='front-app/AllotmentInvoiceList/Info:invoiceDeletedSuccessfully'
            defaultMessage='Invoice deleted successfully'
        />
    )),
    unknownError: (details?: JSX.Element | string) => (
        notification.error(appNotification.texts.unknownError(details))
    ),
};
