import {
    AccountPaginatedList, AllotmentInvoicePaymentPostRequest, ApiCallAccountPaymentListFilters,
    ReservationInvoicePaymentPostRequest,
} from '@malesia/json-schema';
import { call } from 'typed-redux-saga';
import { apiClientRequest } from './ApiClient';

export function* getApiPaymentAccountList(query: ApiCallAccountPaymentListFilters) {
    const response: AccountPaginatedList = yield* call(apiClientRequest, {
        requestId: 'accountPaymentList',
        query,
    });
    return response;
}

export function* createApiReservationPaymentInvoice(requestPayload: ReservationInvoicePaymentPostRequest) {
    const response = yield* call(apiClientRequest, {
        requestId: 'paymentInvoice',
        requestPayload,
    });
    return response;
}

export function* createApiAllotmentInvoicePayment(requestPayload: AllotmentInvoicePaymentPostRequest) {
    const response = yield* call(apiClientRequest, {
        requestId: 'allotmentInvoicePaymentCreate',
        requestPayload,
    });
    return response;
}
