export const allPublicPaginatedRoutePaths = {
    booking: {
        bookingFlight: '/booking/flight/:stepName',
        bookingFlightPassengers: '/booking/flight/passengers',
        bookingFlightSeatReservation: '/booking/flight/seat-reservation',
        bookingFlightSelectFlightOutbound: '/booking/flight/select-flight-outbound',
        bookingFlightSelectFlightReturn: '/booking/flight/select-flight-return',
        bookingFlightTravelDates: '/booking/flight/travel-dates',
        bookingFlightBillingInformation: '/booking/flight/billing-information',
        bookingFlightReservationOverview: '/booking/flight/reservation-overview',
        bookingCar: '/booking/car',
        bookingHotel: '/booking/hotel',
        datatransError: '/datatrans/error',
        datatransCancel: '/datatrans/cancel',
        datatransSuccess: '/datatrans/success',
    },
    aboutUs: {
        aboutUs: '/about-us',
        history: '/about-us/history',
        imprint: '/about-us/imprint',
        location: '/about-us/location',
        partners: '/about-us/partners',
        dataProtection: '/about-us/data-protection',
        termsConditions: '/about-us/terms-and-conditions',
    },
    information: {
        information: '/information',
        baggage: '/information/baggage',
        health: '/information/health',
        safety: '/information/safety',
    },
    flights: {
        flights: '/flights',
    },
    contact: {
        contact: '/contact',
        contactKosovo: '/contact/kosovo',
        contactMacedonia: '/contact/macedonia',
    },
    account: {
        accountReservations: '/account/reservations',
        accountDetails: '/account/details',
    },
    specific: {
        home: '/', // without routeMapping config
        deny: '/deny',
        login: '/login',
    },
} as const;
type ValueOf<T> = T[keyof T];
type PaginatedPaths = typeof allPublicPaginatedRoutePaths;
export type PublicRoutePage = keyof PaginatedPaths;
export type PublicPaginatedPath<Page extends PublicRoutePage> = ValueOf<PaginatedPaths[Page]> & string;

export const allPublicRoutePaths = {
    ...allPublicPaginatedRoutePaths.booking,
    ...allPublicPaginatedRoutePaths.aboutUs,
    ...allPublicPaginatedRoutePaths.information,
    ...allPublicPaginatedRoutePaths.flights,
    ...allPublicPaginatedRoutePaths.contact,
    ...allPublicPaginatedRoutePaths.account,
    ...allPublicPaginatedRoutePaths.specific,
} as const;
export type PublicRoutePath = ValueOf<typeof allPublicRoutePaths>;
