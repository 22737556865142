import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => {
    return state.auxiliaryData || initialState;
};

export const selectOptionList = createSelector(
    [selectDomain],
    (state) => state.option.list.items,
);

export const selectReservationOwnerQuery = createSelector(
    [selectDomain],
    (state) => state.reservationOwner.query,
);

export const selectReservationOwnerList = createSelector(
    [selectDomain],
    (state) => state.reservationOwner.list.items,
);

export const selectSelectedReservationOwner = createSelector(
    [selectDomain],
    (state) => state.reservationOwner.selected,
);

export const selectReservationReservatorQuery = createSelector(
    [selectDomain],
    (state) => state.reservationReservator.query,
);

export const selectReservationReservatorList = createSelector(
    [selectDomain],
    (state) => state.reservationReservator.list.items,
);

export const selectSelectedReservationReservator = createSelector(
    [selectDomain],
    (state) => state.reservationReservator.selected,
);

export const selectAgentList = createSelector(
    [selectDomain],
    (state) => state.agent.list.items,
);

export const selectAgentQuery = createSelector(
    [selectDomain],
    (state) => state.agent.query,
);
