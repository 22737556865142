import { AccountPaginatedList, Reservation } from '@malesia/json-schema';
import { PaymentFormData } from '@malesia/react-components/dist/src/components/Payment/Form';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../../utils/@reduxjs/toolkit';
import { ReservationPaymentPageState } from './types';

export const initialState: ReservationPaymentPageState = {
    reservation: {
        loading: true,
    },
    payment: {
        loading: false,
        data: {},
    },
    cashAccounts: { total: 0, items: [] },
    bankAccounts: { total: 0, items: [] },
    datatransAccounts: { total: 0, items: [] },
    transferAccounts: { total: 0, items: [] },
};

const reservationPaymentSlice = createSlice({
    name: 'reservationPaymentPage',
    initialState,
    reducers: {
        loadInitialData(state, action: PayloadAction<number>) {
        },
        setReservationLoading(state, action: PayloadAction<boolean>) {
            state.reservation.loading = action.payload;
        },
        setReservation(state, action: PayloadAction<Reservation>) {
            state.reservation.data = action.payload;
        },
        setPaymentData(state, action: PayloadAction<PaymentFormData>) {
            state.payment.data = action.payload;
        },
        setCashAccounts(state, action: PayloadAction<AccountPaginatedList>) {
            state.cashAccounts = action.payload;
            if (action.payload.items.length > 0) {
                state.payment.data.cash = action.payload.items[0];
            }
        },
        setBankAccounts(state, action: PayloadAction<AccountPaginatedList>) {
            state.bankAccounts = action.payload;
            if (action.payload.items.length > 0) {
                state.payment.data.bank = action.payload.items[0];
            }
        },
        setDatatransAccounts(state, action: PayloadAction<AccountPaginatedList>) {
            state.datatransAccounts = action.payload;
        },
        setTransferAccounts(state, action: PayloadAction<AccountPaginatedList>) {
            state.transferAccounts = action.payload;
            if (action.payload.items.length > 0) {
                state.payment.data.transfer = action.payload.items[0];
            }
        },
        setOwnerAccount(state, action: PayloadAction<ReservationPaymentPageState['ownerAccount']>) {
            state.ownerAccount = action.payload;
        },
        createPayment(state) {
            state.payment.loading = true;
        },
        createPaymentSuccess(state) {
            state.payment.loading = false;
        },
        createPaymentCancel(state) {
            state.payment.loading = false;
        },
        createPaymentError(state) {
            state.payment.loading = false;
        },
    },
});

export const {
    actions: reservationPaymentActions,
    reducer: reservationPaymentReducer,
    name: reservationPaymentSliceName,
} = reservationPaymentSlice;
