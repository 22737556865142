import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.managerEditPage || initialState;

export const selectManagerData = createSelector(
    [selectDomain],
    state => state.managerData,
);

export const selectLoadingManager = createSelector(
    [selectDomain],
    state => state.loadingManager,
);
