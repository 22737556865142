import { PaymentFormMethod, PaymentFormData } from '@malesia/react-components/dist/src/components/Payment/Form';
import { useDispatch, useSelector } from 'react-redux';
import { paymentNotifications } from './store/notification';
import { selectPriceToPay } from './store/selectors';

export const useValidatePaymentForm = () => {
    const priceToPay = useSelector(selectPriceToPay);
    const dispatch = useDispatch();

    return (data: PaymentFormData, transferTotal: number): boolean => {
        if (priceToPay < transferTotal) {
            dispatch(paymentNotifications.bigPartialAmount);
            return false;
        }

        const defaultValidator = () => {
            dispatch(paymentNotifications.unexpectedPaymentMethod);
            return false;
        };
        const methodValidators: Record<PaymentFormMethod, () => boolean> = {
            cash: () => {
                if (data.cash) return true;
                dispatch(paymentNotifications.notSelectedCashAccount);
                return false;
            },
            bank: defaultValidator,
            datatrans: defaultValidator,
            transfer: () => {
                if (data.transfer) return true;
                dispatch(paymentNotifications.notSelectedTransferAccount);
                return false;
            },
            account: defaultValidator,
        };

        const validate = methodValidators[data.paymentMethod ?? ''] ?? defaultValidator;

        return validate();
    };
};
