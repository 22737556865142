import { AircraftSeatsPlanSection } from '@malesia/react-components/dist/src/components/AircraftSeatsPlan';
import { mapTariffGroupIdToCode } from '@malesia/react-components/dist/src/components/TariffsTable/tariffGroupMapper';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => {
    return state.aircraftTemplatePopup || initialState;
};

export const selectAircraftTemplatePopupIsOpened = createSelector(
    [selectDomain],
    aircraftTemplatePopup => aircraftTemplatePopup.isOpened,
);

export const selectAircraftTemplatePopupAircraftTemplate = createSelector(
    [selectDomain],
    aircraftTemplatePopup => aircraftTemplatePopup.aircraftTemplate,
);

export const selectAircraftTemplatePopupAircraftTemplateSections = createSelector(
    [selectDomain],
    aircraftTemplatePopup => aircraftTemplatePopup.aircraftTemplate?.sections?.map((section) => {
        return {
            ...section,
            tariffGroupCode: mapTariffGroupIdToCode(section.tariffGroup.id),
            rowsWithExitDoors: section.rowsWithExitDoors ?? [],
        } as AircraftSeatsPlanSection;
    }),
);

export const selectAircraftTemplatePopupSelectedSeats = createSelector(
    [selectDomain],
    aircraftTemplatePopup => aircraftTemplatePopup.selectedSeats,
);

export const selectAircraftTemplatePopupActionData = createSelector(
    [selectDomain],
    aircraftTemplatePopup => aircraftTemplatePopup.actionData,
);
