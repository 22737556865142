import { Option } from '@malesia/json-schema';
import { ReservationPassengerOptionChangeData } from '@malesia/react-components/dist/src/components/Reservation/PassengersList';
import { PassengerListItemForm, PassengerItemDataType } from '@malesia/react-components/dist/src/components/Reservation/PassengersList/Item';
import { FormErrorType } from '@malesia/react-components/dist/src/components/ValidationErrors';
import {
    ActionCreatorWithoutPayload,
    ActionCreatorWithPayload,
} from '@reduxjs/toolkit';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Selector } from '../../types/Selector';
import { prepareFormPassengersData } from './optionUtils';
import { PassengerInfo, PassengerBasket } from './types';

type UseFormDataArgs = {
    localeSelector: Selector<string>,
    selectOptionList: Selector<Option[]>,
    removedOptionList: Option[],
    passengersSelector: Selector<PassengerBasket[]>,
    updatePassengerAtPositionAction:
    ActionCreatorWithPayload<{passengerData: PassengerInfo, position: number}, string>,
    addPassengerAction: ActionCreatorWithoutPayload,
    removePassengerAtPositionAction: ActionCreatorWithPayload<number, string>,
    setPassengerOptionsAction:
    ActionCreatorWithPayload<{passengerIdx: number, optionId: number, isChecked: boolean}, string>,
};
export const usePassengerForm = (args: UseFormDataArgs) => {
    const {
        localeSelector,
        selectOptionList,
        removedOptionList,
        passengersSelector,
        updatePassengerAtPositionAction,
        addPassengerAction,
        removePassengerAtPositionAction,
        setPassengerOptionsAction,
    } = args;

    const dispatch = useDispatch();
    const locale = useSelector(localeSelector);
    const optionList = useSelector(selectOptionList);
    const passengers = useSelector(passengersSelector);

    const passengersData = useMemo(() => (
        prepareFormPassengersData(passengers, optionList, removedOptionList, locale)
    ), [passengers, optionList, removedOptionList, locale]);

    const [formErrors, setFormErrors] = useState<(Record<string, FormErrorType[]> | undefined)[]>([]);

    const passengersDataWithErrors: PassengerItemDataType[] = useMemo(() => (
        passengersData.map((passengersItemData, idx): PassengerItemDataType => ({
            ...passengersItemData,
            formErrors: formErrors[idx] || undefined,
        }))
    ), [passengersData, formErrors]);

    const handleFormDataChange = (
        passengerData: Partial<PassengerListItemForm>,
        position: number,
    ) => {
        dispatch(updatePassengerAtPositionAction({
            passengerData,
            position,
        }));
    };

    const handleAddPassengerClick = () => {
        dispatch(addPassengerAction());
    };

    const handleRemovePassengerClick = (position: number) => {
        dispatch(removePassengerAtPositionAction(position));
    };

    const handlePassengerOptionChange = (data: ReservationPassengerOptionChangeData) => {
        dispatch(setPassengerOptionsAction(data));
    };

    return {
        passengersDataWithErrors,
        passengersData,
        setFormErrors,
        handleFormDataChange,
        handleAddPassengerClick,
        handleRemovePassengerClick,
        handlePassengerOptionChange,
    };
};
