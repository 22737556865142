/* Auto generated. Use only yarn generate-permissions */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { EditableRoleCategoryCode, EditableRoleCode } from './roleCode';

export type RoleTranslationConfig = {
    titleTranslateKey: string,
    descriptionTranslateKey: string,
};
export type RoleCategoryTranslationConfig<CategoryCode extends EditableRoleCategoryCode> = {
    titleTranslateKey: string,
    roles: Record<EditableRoleCode<CategoryCode>, RoleTranslationConfig>,
};
export type PermissionTranslationsConfig = {
    [CategoryCode in EditableRoleCategoryCode]: RoleCategoryTranslationConfig<CategoryCode>;
};

export const permissionTranslationsConfig: PermissionTranslationsConfig = {
    account: {
        titleTranslateKey: 'front-app/Permissions/CategoryTitle:account' || (
            <FormattedMessage
                id='front-app/Permissions/CategoryTitle:account'
                defaultMessage=''
            />
        ),
        roles: {
            ROLE_ACCOUNT_LIST: {
                titleTranslateKey: 'front-app/Roles/accountList:title',
                descriptionTranslateKey: 'front-app/Roles/accountList:description' || (
                    <FormattedMessage
                        id='front-app/Roles/accountList:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_ACCOUNT_LIST_ACCOUNTANT: {
                titleTranslateKey: 'front-app/Roles/accountListAccountant:title',
                descriptionTranslateKey: 'front-app/Roles/accountListAccountant:description' || (
                    <FormattedMessage
                        id='front-app/Roles/accountListAccountant:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_ACCOUNT_LIST_FREE: {
                titleTranslateKey: 'front-app/Roles/accountListFree:title',
                descriptionTranslateKey: 'front-app/Roles/accountListFree:description' || (
                    <FormattedMessage
                        id='front-app/Roles/accountListFree:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_ACCOUNT_LIST_FREE_EXTENDED: {
                titleTranslateKey: 'front-app/Roles/accountListFreeExtended:title',
                descriptionTranslateKey: 'front-app/Roles/accountListFreeExtended:description' || (
                    <FormattedMessage
                        id='front-app/Roles/accountListFreeExtended:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_ACCOUNT_VIRTUAL_LIST_FREE: {
                titleTranslateKey: 'front-app/Roles/accountVirtualListFree:title',
                descriptionTranslateKey: 'front-app/Roles/accountVirtualListFree:description' || (
                    <FormattedMessage
                        id='front-app/Roles/accountVirtualListFree:description'
                        defaultMessage=''
                    />
                ),
            },
        },
    },
    agent: {
        titleTranslateKey: 'front-app/Permissions/CategoryTitle:agent' || (
            <FormattedMessage
                id='front-app/Permissions/CategoryTitle:agent'
                defaultMessage=''
            />
        ),
        roles: {
            ROLE_AGENT_CREATE: {
                titleTranslateKey: 'front-app/Roles/agentCreate:title',
                descriptionTranslateKey: 'front-app/Roles/agentCreate:description' || (
                    <FormattedMessage
                        id='front-app/Roles/agentCreate:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_AGENT_DELETE: {
                titleTranslateKey: 'front-app/Roles/agentDelete:title',
                descriptionTranslateKey: 'front-app/Roles/agentDelete:description' || (
                    <FormattedMessage
                        id='front-app/Roles/agentDelete:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_AGENT_LIST: {
                titleTranslateKey: 'front-app/Roles/agentList:title',
                descriptionTranslateKey: 'front-app/Roles/agentList:description' || (
                    <FormattedMessage
                        id='front-app/Roles/agentList:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_AGENT_READ: {
                titleTranslateKey: 'front-app/Roles/agentRead:title',
                descriptionTranslateKey: 'front-app/Roles/agentRead:description' || (
                    <FormattedMessage
                        id='front-app/Roles/agentRead:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_AGENT_UPDATE: {
                titleTranslateKey: 'front-app/Roles/agentUpdate:title',
                descriptionTranslateKey: 'front-app/Roles/agentUpdate:description' || (
                    <FormattedMessage
                        id='front-app/Roles/agentUpdate:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_AGENT_UPDATE_CREDIT_LIMIT: {
                titleTranslateKey: 'front-app/Roles/agentUpdateCreditLimit:title',
                descriptionTranslateKey: 'front-app/Roles/agentUpdateCreditLimit:description' || (
                    <FormattedMessage
                        id='front-app/Roles/agentUpdateCreditLimit:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_AGENT_UPDATE_FEE: {
                titleTranslateKey: 'front-app/Roles/agentUpdateFee:title',
                descriptionTranslateKey: 'front-app/Roles/agentUpdateFee:description' || (
                    <FormattedMessage
                        id='front-app/Roles/agentUpdateFee:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_AGENT_UPDATE_IS_ACTIVE: {
                titleTranslateKey: 'front-app/Roles/agentUpdateIsActive:title',
                descriptionTranslateKey: 'front-app/Roles/agentUpdateIsActive:description' || (
                    <FormattedMessage
                        id='front-app/Roles/agentUpdateIsActive:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_AGENT_UPDATE_MARGIN: {
                titleTranslateKey: 'front-app/Roles/agentUpdateMargin:title',
                descriptionTranslateKey: 'front-app/Roles/agentUpdateMargin:description' || (
                    <FormattedMessage
                        id='front-app/Roles/agentUpdateMargin:description'
                        defaultMessage=''
                    />
                ),
            },
        },
    },
    aircraftTemplate: {
        titleTranslateKey: 'front-app/Permissions/CategoryTitle:aircraftTemplate' || (
            <FormattedMessage
                id='front-app/Permissions/CategoryTitle:aircraftTemplate'
                defaultMessage=''
            />
        ),
        roles: {
            ROLE_AIRCRAFT_TEMPLATE_GENERIC_LIST: {
                titleTranslateKey: 'front-app/Roles/aircraftTemplateGenericList:title',
                descriptionTranslateKey: 'front-app/Roles/aircraftTemplateGenericList:description' || (
                    <FormattedMessage
                        id='front-app/Roles/aircraftTemplateGenericList:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_AIRCRAFT_TEMPLATE_GENERIC_READ: {
                titleTranslateKey: 'front-app/Roles/aircraftTemplateGenericRead:title',
                descriptionTranslateKey: 'front-app/Roles/aircraftTemplateGenericRead:description' || (
                    <FormattedMessage
                        id='front-app/Roles/aircraftTemplateGenericRead:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_AIRCRAFT_TEMPLATE_GENERIC_UPDATE: {
                titleTranslateKey: 'front-app/Roles/aircraftTemplateGenericUpdate:title',
                descriptionTranslateKey: 'front-app/Roles/aircraftTemplateGenericUpdate:description' || (
                    <FormattedMessage
                        id='front-app/Roles/aircraftTemplateGenericUpdate:description'
                        defaultMessage=''
                    />
                ),
            },
        },
    },
    allotment: {
        titleTranslateKey: 'front-app/Permissions/CategoryTitle:allotment' || (
            <FormattedMessage
                id='front-app/Permissions/CategoryTitle:allotment'
                defaultMessage=''
            />
        ),
        roles: {
            ROLE_ALLOTMENT_AGENT_ALLOTMENT_LIST: {
                titleTranslateKey: 'front-app/Roles/allotmentAgentAllotmentList:title',
                descriptionTranslateKey: 'front-app/Roles/allotmentAgentAllotmentList:description' || (
                    <FormattedMessage
                        id='front-app/Roles/allotmentAgentAllotmentList:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_ALLOTMENT_EDIT: {
                titleTranslateKey: 'front-app/Roles/allotmentEdit:title',
                descriptionTranslateKey: 'front-app/Roles/allotmentEdit:description' || (
                    <FormattedMessage
                        id='front-app/Roles/allotmentEdit:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_ALLOTMENT_INVOICE_EDIT: {
                titleTranslateKey: 'front-app/Roles/allotmentInvoiceEdit:title',
                descriptionTranslateKey: 'front-app/Roles/allotmentInvoiceEdit:description' || (
                    <FormattedMessage
                        id='front-app/Roles/allotmentInvoiceEdit:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_ALLOTMENT_INVOICE_READ: {
                titleTranslateKey: 'front-app/Roles/allotmentInvoiceRead:title',
                descriptionTranslateKey: 'front-app/Roles/allotmentInvoiceRead:description' || (
                    <FormattedMessage
                        id='front-app/Roles/allotmentInvoiceRead:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_ALLOTMENT_LIST: {
                titleTranslateKey: 'front-app/Roles/allotmentList:title',
                descriptionTranslateKey: 'front-app/Roles/allotmentList:description' || (
                    <FormattedMessage
                        id='front-app/Roles/allotmentList:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_ALLOTMENT_READ: {
                titleTranslateKey: 'front-app/Roles/allotmentRead:title',
                descriptionTranslateKey: 'front-app/Roles/allotmentRead:description' || (
                    <FormattedMessage
                        id='front-app/Roles/allotmentRead:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_ALLOTMENT_READ_COST_PER_SEAT: {
                titleTranslateKey: 'front-app/Roles/allotmentReadCostPerSeat:title',
                descriptionTranslateKey: 'front-app/Roles/allotmentReadCostPerSeat:description' || (
                    <FormattedMessage
                        id='front-app/Roles/allotmentReadCostPerSeat:description'
                        defaultMessage=''
                    />
                ),
            },
        },
    },
    customer: {
        titleTranslateKey: 'front-app/Permissions/CategoryTitle:customer' || (
            <FormattedMessage
                id='front-app/Permissions/CategoryTitle:customer'
                defaultMessage=''
            />
        ),
        roles: {
            ROLE_CUSTOMER_CREATE: {
                titleTranslateKey: 'front-app/Roles/customerCreate:title',
                descriptionTranslateKey: 'front-app/Roles/customerCreate:description' || (
                    <FormattedMessage
                        id='front-app/Roles/customerCreate:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_CUSTOMER_DELETE: {
                titleTranslateKey: 'front-app/Roles/customerDelete:title',
                descriptionTranslateKey: 'front-app/Roles/customerDelete:description' || (
                    <FormattedMessage
                        id='front-app/Roles/customerDelete:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_CUSTOMER_LIST: {
                titleTranslateKey: 'front-app/Roles/customerList:title',
                descriptionTranslateKey: 'front-app/Roles/customerList:description' || (
                    <FormattedMessage
                        id='front-app/Roles/customerList:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_CUSTOMER_READ: {
                titleTranslateKey: 'front-app/Roles/customerRead:title',
                descriptionTranslateKey: 'front-app/Roles/customerRead:description' || (
                    <FormattedMessage
                        id='front-app/Roles/customerRead:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_CUSTOMER_UPDATE: {
                titleTranslateKey: 'front-app/Roles/customerUpdate:title',
                descriptionTranslateKey: 'front-app/Roles/customerUpdate:description' || (
                    <FormattedMessage
                        id='front-app/Roles/customerUpdate:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_CUSTOMER_UPDATE_IS_ACTIVE: {
                titleTranslateKey: 'front-app/Roles/customerUpdateIsActive:title',
                descriptionTranslateKey: 'front-app/Roles/customerUpdateIsActive:description' || (
                    <FormattedMessage
                        id='front-app/Roles/customerUpdateIsActive:description'
                        defaultMessage=''
                    />
                ),
            },
        },
    },
    entityLog: {
        titleTranslateKey: 'front-app/Permissions/CategoryTitle:entityLog' || (
            <FormattedMessage
                id='front-app/Permissions/CategoryTitle:entityLog'
                defaultMessage=''
            />
        ),
        roles: {
            ROLE_ENTITY_LOG_LIST: {
                titleTranslateKey: 'front-app/Roles/entityLogList:title',
                descriptionTranslateKey: 'front-app/Roles/entityLogList:description' || (
                    <FormattedMessage
                        id='front-app/Roles/entityLogList:description'
                        defaultMessage=''
                    />
                ),
            },
        },
    },
    flight: {
        titleTranslateKey: 'front-app/Permissions/CategoryTitle:flight' || (
            <FormattedMessage
                id='front-app/Permissions/CategoryTitle:flight'
                defaultMessage=''
            />
        ),
        roles: {
            ROLE_FLIGHT_ACTIVE: {
                titleTranslateKey: 'front-app/Roles/flightActive:title',
                descriptionTranslateKey: 'front-app/Roles/flightActive:description' || (
                    <FormattedMessage
                        id='front-app/Roles/flightActive:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_FLIGHT_AGENT_FLIGHT_LIST: {
                titleTranslateKey: 'front-app/Roles/flightAgentFlightList:title',
                descriptionTranslateKey: 'front-app/Roles/flightAgentFlightList:description' || (
                    <FormattedMessage
                        id='front-app/Roles/flightAgentFlightList:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_FLIGHT_BOOKED_OUT: {
                titleTranslateKey: 'front-app/Roles/flightBookedOut:title',
                descriptionTranslateKey: 'front-app/Roles/flightBookedOut:description' || (
                    <FormattedMessage
                        id='front-app/Roles/flightBookedOut:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_FLIGHT_CREATE: {
                titleTranslateKey: 'front-app/Roles/flightCreate:title',
                descriptionTranslateKey: 'front-app/Roles/flightCreate:description' || (
                    <FormattedMessage
                        id='front-app/Roles/flightCreate:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_FLIGHT_CREATE_COST_PER_SEAT: {
                titleTranslateKey: 'front-app/Roles/flightCreateCostPerSeat:title',
                descriptionTranslateKey: 'front-app/Roles/flightCreateCostPerSeat:description' || (
                    <FormattedMessage
                        id='front-app/Roles/flightCreateCostPerSeat:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_FLIGHT_CREATE_SEAT_NUMBER: {
                titleTranslateKey: 'front-app/Roles/flightCreateSeatNumber:title',
                descriptionTranslateKey: 'front-app/Roles/flightCreateSeatNumber:description' || (
                    <FormattedMessage
                        id='front-app/Roles/flightCreateSeatNumber:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_FLIGHT_CREATE_SEQUENCE: {
                titleTranslateKey: 'front-app/Roles/flightCreateSequence:title',
                descriptionTranslateKey: 'front-app/Roles/flightCreateSequence:description' || (
                    <FormattedMessage
                        id='front-app/Roles/flightCreateSequence:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_FLIGHT_DELETE: {
                titleTranslateKey: 'front-app/Roles/flightDelete:title',
                descriptionTranslateKey: 'front-app/Roles/flightDelete:description' || (
                    <FormattedMessage
                        id='front-app/Roles/flightDelete:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_FLIGHT_LIST: {
                titleTranslateKey: 'front-app/Roles/flightList:title',
                descriptionTranslateKey: 'front-app/Roles/flightList:description' || (
                    <FormattedMessage
                        id='front-app/Roles/flightList:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_FLIGHT_READ: {
                titleTranslateKey: 'front-app/Roles/flightRead:title',
                descriptionTranslateKey: 'front-app/Roles/flightRead:description' || (
                    <FormattedMessage
                        id='front-app/Roles/flightRead:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_FLIGHT_READ_COST_PER_SEAT: {
                titleTranslateKey: 'front-app/Roles/flightReadCostPerSeat:title',
                descriptionTranslateKey: 'front-app/Roles/flightReadCostPerSeat:description' || (
                    <FormattedMessage
                        id='front-app/Roles/flightReadCostPerSeat:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_FLIGHT_READ_SEAT_NUMBER: {
                titleTranslateKey: 'front-app/Roles/flightReadSeatNumber:title',
                descriptionTranslateKey: 'front-app/Roles/flightReadSeatNumber:description' || (
                    <FormattedMessage
                        id='front-app/Roles/flightReadSeatNumber:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_FLIGHT_READ_TURNOVER_INFO: {
                titleTranslateKey: 'front-app/Roles/flightReadTurnoverInfo:title',
                descriptionTranslateKey: 'front-app/Roles/flightReadTurnoverInfo:description' || (
                    <FormattedMessage
                        id='front-app/Roles/flightReadTurnoverInfo:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_FLIGHT_STATUS: {
                titleTranslateKey: 'front-app/Roles/flightStatus:title',
                descriptionTranslateKey: 'front-app/Roles/flightStatus:description' || (
                    <FormattedMessage
                        id='front-app/Roles/flightStatus:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_FLIGHT_UPDATE: {
                titleTranslateKey: 'front-app/Roles/flightUpdate:title',
                descriptionTranslateKey: 'front-app/Roles/flightUpdate:description' || (
                    <FormattedMessage
                        id='front-app/Roles/flightUpdate:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_FLIGHT_UPDATE_COST_PER_SEAT: {
                titleTranslateKey: 'front-app/Roles/flightUpdateCostPerSeat:title',
                descriptionTranslateKey: 'front-app/Roles/flightUpdateCostPerSeat:description' || (
                    <FormattedMessage
                        id='front-app/Roles/flightUpdateCostPerSeat:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_FLIGHT_UPDATE_SEAT_NUMBER: {
                titleTranslateKey: 'front-app/Roles/flightUpdateSeatNumber:title',
                descriptionTranslateKey: 'front-app/Roles/flightUpdateSeatNumber:description' || (
                    <FormattedMessage
                        id='front-app/Roles/flightUpdateSeatNumber:description'
                        defaultMessage=''
                    />
                ),
            },
        },
    },
    flightCompany: {
        titleTranslateKey: 'front-app/Permissions/CategoryTitle:flightCompany' || (
            <FormattedMessage
                id='front-app/Permissions/CategoryTitle:flightCompany'
                defaultMessage=''
            />
        ),
        roles: {
            ROLE_FLIGHT_COMPANY_CREATE: {
                titleTranslateKey: 'front-app/Roles/flightCompanyCreate:title',
                descriptionTranslateKey: 'front-app/Roles/flightCompanyCreate:description' || (
                    <FormattedMessage
                        id='front-app/Roles/flightCompanyCreate:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_FLIGHT_COMPANY_DELETE: {
                titleTranslateKey: 'front-app/Roles/flightCompanyDelete:title',
                descriptionTranslateKey: 'front-app/Roles/flightCompanyDelete:description' || (
                    <FormattedMessage
                        id='front-app/Roles/flightCompanyDelete:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_FLIGHT_COMPANY_LIST: {
                titleTranslateKey: 'front-app/Roles/flightCompanyList:title',
                descriptionTranslateKey: 'front-app/Roles/flightCompanyList:description' || (
                    <FormattedMessage
                        id='front-app/Roles/flightCompanyList:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_FLIGHT_COMPANY_READ: {
                titleTranslateKey: 'front-app/Roles/flightCompanyRead:title',
                descriptionTranslateKey: 'front-app/Roles/flightCompanyRead:description' || (
                    <FormattedMessage
                        id='front-app/Roles/flightCompanyRead:description'
                        defaultMessage=''
                    />
                ),
            },
        },
    },
    manager: {
        titleTranslateKey: 'front-app/Permissions/CategoryTitle:manager' || (
            <FormattedMessage
                id='front-app/Permissions/CategoryTitle:manager'
                defaultMessage=''
            />
        ),
        roles: {
            ROLE_MANAGER_EDIT: {
                titleTranslateKey: 'front-app/Roles/managerEdit:title',
                descriptionTranslateKey: 'front-app/Roles/managerEdit:description' || (
                    <FormattedMessage
                        id='front-app/Roles/managerEdit:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_MANAGER_LIST: {
                titleTranslateKey: 'front-app/Roles/managerList:title',
                descriptionTranslateKey: 'front-app/Roles/managerList:description' || (
                    <FormattedMessage
                        id='front-app/Roles/managerList:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_MANAGER_READ: {
                titleTranslateKey: 'front-app/Roles/managerRead:title',
                descriptionTranslateKey: 'front-app/Roles/managerRead:description' || (
                    <FormattedMessage
                        id='front-app/Roles/managerRead:description'
                        defaultMessage=''
                    />
                ),
            },
        },
    },
    passenger: {
        titleTranslateKey: 'front-app/Permissions/CategoryTitle:passenger' || (
            <FormattedMessage
                id='front-app/Permissions/CategoryTitle:passenger'
                defaultMessage=''
            />
        ),
        roles: {
            ROLE_FLIGHT_DELAYED: {
                titleTranslateKey: 'front-app/Roles/flightDelayed:title',
                descriptionTranslateKey: 'front-app/Roles/flightDelayed:description' || (
                    <FormattedMessage
                        id='front-app/Roles/flightDelayed:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_FLIGHT_PASSENGER_LIST: {
                titleTranslateKey: 'front-app/Roles/flightPassengerList:title',
                descriptionTranslateKey: 'front-app/Roles/flightPassengerList:description' || (
                    <FormattedMessage
                        id='front-app/Roles/flightPassengerList:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_FLIGHT_PASSENGER_LIST_BULK_ACTIONS: {
                titleTranslateKey: 'front-app/Roles/flightPassengerListBulkActions:title',
                descriptionTranslateKey: 'front-app/Roles/flightPassengerListBulkActions:description' || (
                    <FormattedMessage
                        id='front-app/Roles/flightPassengerListBulkActions:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_FLIGHT_PASSENGER_MARKING: {
                titleTranslateKey: 'front-app/Roles/flightPassengerMarking:title',
                descriptionTranslateKey: 'front-app/Roles/flightPassengerMarking:description' || (
                    <FormattedMessage
                        id='front-app/Roles/flightPassengerMarking:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_FLIGHT_PASSENGER_SEAT: {
                titleTranslateKey: 'front-app/Roles/flightPassengerSeat:title',
                descriptionTranslateKey: 'front-app/Roles/flightPassengerSeat:description' || (
                    <FormattedMessage
                        id='front-app/Roles/flightPassengerSeat:description'
                        defaultMessage=''
                    />
                ),
            },
        },
    },
    payment: {
        titleTranslateKey: 'front-app/Permissions/CategoryTitle:payment' || (
            <FormattedMessage
                id='front-app/Permissions/CategoryTitle:payment'
                defaultMessage=''
            />
        ),
        roles: {
            ROLE_ACCOUNT_LIST_PAYMENT: {
                titleTranslateKey: 'front-app/Roles/accountListPayment:title',
                descriptionTranslateKey: 'front-app/Roles/accountListPayment:description' || (
                    <FormattedMessage
                        id='front-app/Roles/accountListPayment:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_INVOICE_ADD_PARTIAL_PAYMENT: {
                titleTranslateKey: 'front-app/Roles/reservationInvoiceAddPartialPayment:title',
                descriptionTranslateKey: 'front-app/Roles/reservationInvoiceAddPartialPayment:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationInvoiceAddPartialPayment:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_INVOICE_ADD_PAYMENT: {
                titleTranslateKey: 'front-app/Roles/reservationInvoiceAddPayment:title',
                descriptionTranslateKey: 'front-app/Roles/reservationInvoiceAddPayment:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationInvoiceAddPayment:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_INVOICE_ADD_PAYMENT_TYPE_ACCOUNT: {
                titleTranslateKey: 'front-app/Roles/reservationInvoiceAddPaymentTypeAccount:title',
                descriptionTranslateKey: 'front-app/Roles/reservationInvoiceAddPaymentTypeAccount:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationInvoiceAddPaymentTypeAccount:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_INVOICE_ADD_PAYMENT_TYPE_BANK: {
                titleTranslateKey: 'front-app/Roles/reservationInvoiceAddPaymentTypeBank:title',
                descriptionTranslateKey: 'front-app/Roles/reservationInvoiceAddPaymentTypeBank:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationInvoiceAddPaymentTypeBank:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_INVOICE_ADD_PAYMENT_TYPE_CASH: {
                titleTranslateKey: 'front-app/Roles/reservationInvoiceAddPaymentTypeCash:title',
                descriptionTranslateKey: 'front-app/Roles/reservationInvoiceAddPaymentTypeCash:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationInvoiceAddPaymentTypeCash:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_INVOICE_ADD_PAYMENT_TYPE_TRANSFER: {
                titleTranslateKey: 'front-app/Roles/reservationInvoiceAddPaymentTypeTransfer:title',
                descriptionTranslateKey: 'front-app/Roles/reservationInvoiceAddPaymentTypeTransfer:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationInvoiceAddPaymentTypeTransfer:description'
                        defaultMessage=''
                    />
                ),
            },
        },
    },
    rate: {
        titleTranslateKey: 'front-app/Permissions/CategoryTitle:rate' || (
            <FormattedMessage
                id='front-app/Permissions/CategoryTitle:rate'
                defaultMessage=''
            />
        ),
        roles: {
            ROLE_RATE_CREATE: {
                titleTranslateKey: 'front-app/Roles/rateCreate:title',
                descriptionTranslateKey: 'front-app/Roles/rateCreate:description' || (
                    <FormattedMessage
                        id='front-app/Roles/rateCreate:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RATE_DELETE: {
                titleTranslateKey: 'front-app/Roles/rateDelete:title',
                descriptionTranslateKey: 'front-app/Roles/rateDelete:description' || (
                    <FormattedMessage
                        id='front-app/Roles/rateDelete:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RATE_LIST: {
                titleTranslateKey: 'front-app/Roles/rateList:title',
                descriptionTranslateKey: 'front-app/Roles/rateList:description' || (
                    <FormattedMessage
                        id='front-app/Roles/rateList:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RATE_READ: {
                titleTranslateKey: 'front-app/Roles/rateRead:title',
                descriptionTranslateKey: 'front-app/Roles/rateRead:description' || (
                    <FormattedMessage
                        id='front-app/Roles/rateRead:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RATE_UPDATE: {
                titleTranslateKey: 'front-app/Roles/rateUpdate:title',
                descriptionTranslateKey: 'front-app/Roles/rateUpdate:description' || (
                    <FormattedMessage
                        id='front-app/Roles/rateUpdate:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_OPTION_CREATE: {
                titleTranslateKey: 'front-app/Roles/reservationOptionCreate:title',
                descriptionTranslateKey: 'front-app/Roles/reservationOptionCreate:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationOptionCreate:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_OPTION_UPDATE: {
                titleTranslateKey: 'front-app/Roles/reservationOptionUpdate:title',
                descriptionTranslateKey: 'front-app/Roles/reservationOptionUpdate:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationOptionUpdate:description'
                        defaultMessage=''
                    />
                ),
            },
        },
    },
    reservation: {
        titleTranslateKey: 'front-app/Permissions/CategoryTitle:reservation' || (
            <FormattedMessage
                id='front-app/Permissions/CategoryTitle:reservation'
                defaultMessage=''
            />
        ),
        roles: {
            ROLE_RESERVATION_CREATE_ACCOUNT: {
                titleTranslateKey: 'front-app/Roles/reservationCreateAccount:title',
                descriptionTranslateKey: 'front-app/Roles/reservationCreateAccount:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationCreateAccount:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_CREATE_CLOSED_FLIGHT: {
                titleTranslateKey: 'front-app/Roles/reservationCreateClosedFlight:title',
                descriptionTranslateKey: 'front-app/Roles/reservationCreateClosedFlight:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationCreateClosedFlight:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_CREATE_COMMENT: {
                titleTranslateKey: 'front-app/Roles/reservationCreateComment:title',
                descriptionTranslateKey: 'front-app/Roles/reservationCreateComment:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationCreateComment:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_CREATE_SMS_FLAG: {
                titleTranslateKey: 'front-app/Roles/reservationCreateSmsFlag:title',
                descriptionTranslateKey: 'front-app/Roles/reservationCreateSmsFlag:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationCreateSmsFlag:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_INVOICE_SET_PAYMENT_TERMS_AIRPORT: {
                titleTranslateKey: 'front-app/Roles/reservationInvoiceSetPaymentTermsAirport:title',
                descriptionTranslateKey: 'front-app/Roles/reservationInvoiceSetPaymentTermsAirport:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationInvoiceSetPaymentTermsAirport:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_INVOICE_SET_PAYMENT_TERMS_BILL: {
                titleTranslateKey: 'front-app/Roles/reservationInvoiceSetPaymentTermsBill:title',
                descriptionTranslateKey: 'front-app/Roles/reservationInvoiceSetPaymentTermsBill:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationInvoiceSetPaymentTermsBill:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_INVOICE_SET_PAYMENT_TERMS_LATER: {
                titleTranslateKey: 'front-app/Roles/reservationInvoiceSetPaymentTermsLater:title',
                descriptionTranslateKey: 'front-app/Roles/reservationInvoiceSetPaymentTermsLater:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationInvoiceSetPaymentTermsLater:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_INVOICE_SET_PAYMENT_TERMS_LATER_NEAR_TO_DEPARTURE: {
                titleTranslateKey: 'front-app/Roles/reservationInvoiceSetPaymentTermsLaterNearToDeparture:title',
                descriptionTranslateKey: 'front-app/Roles/reservationInvoiceSetPaymentTermsLaterNearToDeparture:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationInvoiceSetPaymentTermsLaterNearToDeparture:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_INVOICE_SET_SPECIFIC_PRICE: {
                titleTranslateKey: 'front-app/Roles/reservationInvoiceSetSpecificPrice:title',
                descriptionTranslateKey: 'front-app/Roles/reservationInvoiceSetSpecificPrice:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationInvoiceSetSpecificPrice:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_READ: {
                titleTranslateKey: 'front-app/Roles/reservationRead:title',
                descriptionTranslateKey: 'front-app/Roles/reservationRead:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationRead:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_UPDATE: {
                titleTranslateKey: 'front-app/Roles/reservationUpdate:title',
                descriptionTranslateKey: 'front-app/Roles/reservationUpdate:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationUpdate:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_UPDATE_CLOSED_FLIGHT: {
                titleTranslateKey: 'front-app/Roles/reservationUpdateClosedFlight:title',
                descriptionTranslateKey: 'front-app/Roles/reservationUpdateClosedFlight:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationUpdateClosedFlight:description'
                        defaultMessage=''
                    />
                ),
            },
        },
    },
    reservationList: {
        titleTranslateKey: 'front-app/Permissions/CategoryTitle:reservationList' || (
            <FormattedMessage
                id='front-app/Permissions/CategoryTitle:reservationList'
                defaultMessage=''
            />
        ),
        roles: {
            ROLE_RESERVATION_CANCEL: {
                titleTranslateKey: 'front-app/Roles/reservationCancel:title',
                descriptionTranslateKey: 'front-app/Roles/reservationCancel:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationCancel:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_EDIT_DEPARTED_FLIGHT: {
                titleTranslateKey: 'front-app/Roles/reservationEditDepartedFlight:title',
                descriptionTranslateKey: 'front-app/Roles/reservationEditDepartedFlight:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationEditDepartedFlight:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_HIDE_PRICE_FLAG: {
                titleTranslateKey: 'front-app/Roles/reservationHidePriceFlag:title',
                descriptionTranslateKey: 'front-app/Roles/reservationHidePriceFlag:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationHidePriceFlag:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_INVOICE_PDF_BEFORE_PAID: {
                titleTranslateKey: 'front-app/Roles/reservationInvoicePdfBeforePaid:title',
                descriptionTranslateKey: 'front-app/Roles/reservationInvoicePdfBeforePaid:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationInvoicePdfBeforePaid:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_LIST: {
                titleTranslateKey: 'front-app/Roles/reservationList:title',
                descriptionTranslateKey: 'front-app/Roles/reservationList:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationList:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_PASSENGER_LIST_FILTERS: {
                titleTranslateKey: 'front-app/Roles/reservationPassengerListFilters:title',
                descriptionTranslateKey: 'front-app/Roles/reservationPassengerListFilters:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationPassengerListFilters:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_SNAPSHOT_LIST: {
                titleTranslateKey: 'front-app/Roles/reservationSnapshotList:title',
                descriptionTranslateKey: 'front-app/Roles/reservationSnapshotList:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationSnapshotList:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_SPLIT: {
                titleTranslateKey: 'front-app/Roles/reservationSplit:title',
                descriptionTranslateKey: 'front-app/Roles/reservationSplit:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationSplit:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_TICKET_PDF_BEFORE_PAID: {
                titleTranslateKey: 'front-app/Roles/reservationTicketPdfBeforePaid:title',
                descriptionTranslateKey: 'front-app/Roles/reservationTicketPdfBeforePaid:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationTicketPdfBeforePaid:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_RESERVATION_USER_LIST: {
                titleTranslateKey: 'front-app/Roles/reservationUserList:title',
                descriptionTranslateKey: 'front-app/Roles/reservationUserList:description' || (
                    <FormattedMessage
                        id='front-app/Roles/reservationUserList:description'
                        defaultMessage=''
                    />
                ),
            },
        },
    },
    tariff: {
        titleTranslateKey: 'front-app/Permissions/CategoryTitle:tariff' || (
            <FormattedMessage
                id='front-app/Permissions/CategoryTitle:tariff'
                defaultMessage=''
            />
        ),
        roles: {
            ROLE_TARIFF_LIST: {
                titleTranslateKey: 'front-app/Roles/tariffList:title',
                descriptionTranslateKey: 'front-app/Roles/tariffList:description' || (
                    <FormattedMessage
                        id='front-app/Roles/tariffList:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_TARIFF_READ: {
                titleTranslateKey: 'front-app/Roles/tariffRead:title',
                descriptionTranslateKey: 'front-app/Roles/tariffRead:description' || (
                    <FormattedMessage
                        id='front-app/Roles/tariffRead:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_TARIFF_UPDATE: {
                titleTranslateKey: 'front-app/Roles/tariffUpdate:title',
                descriptionTranslateKey: 'front-app/Roles/tariffUpdate:description' || (
                    <FormattedMessage
                        id='front-app/Roles/tariffUpdate:description'
                        defaultMessage=''
                    />
                ),
            },
        },
    },
    transaction: {
        titleTranslateKey: 'front-app/Permissions/CategoryTitle:transaction' || (
            <FormattedMessage
                id='front-app/Permissions/CategoryTitle:transaction'
                defaultMessage=''
            />
        ),
        roles: {
            ROLE_BALANCE_LOG_RECORD_ADVANCED_PAYMENT: {
                titleTranslateKey: 'front-app/Roles/balanceLogRecordAdvancedPayment:title',
                descriptionTranslateKey: 'front-app/Roles/balanceLogRecordAdvancedPayment:description' || (
                    <FormattedMessage
                        id='front-app/Roles/balanceLogRecordAdvancedPayment:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_BALANCE_LOG_RECORD_BASIC_PAYMENT_CASHBACK: {
                titleTranslateKey: 'front-app/Roles/balanceLogRecordBasicPaymentCashback:title',
                descriptionTranslateKey: 'front-app/Roles/balanceLogRecordBasicPaymentCashback:description' || (
                    <FormattedMessage
                        id='front-app/Roles/balanceLogRecordBasicPaymentCashback:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_BALANCE_LOG_RECORD_BASIC_PAYMENT_PREPAID_DEPOSIT: {
                titleTranslateKey: 'front-app/Roles/balanceLogRecordBasicPaymentPrepaidDeposit:title',
                descriptionTranslateKey: 'front-app/Roles/balanceLogRecordBasicPaymentPrepaidDeposit:description' || (
                    <FormattedMessage
                        id='front-app/Roles/balanceLogRecordBasicPaymentPrepaidDeposit:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_BALANCE_LOG_RECORD_BASIC_PAYMENT_SETTLEMENT: {
                titleTranslateKey: 'front-app/Roles/balanceLogRecordBasicPaymentSettlement:title',
                descriptionTranslateKey: 'front-app/Roles/balanceLogRecordBasicPaymentSettlement:description' || (
                    <FormattedMessage
                        id='front-app/Roles/balanceLogRecordBasicPaymentSettlement:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_BALANCE_LOG_RECORD_LIST: {
                titleTranslateKey: 'front-app/Roles/balanceLogRecordList:title',
                descriptionTranslateKey: 'front-app/Roles/balanceLogRecordList:description' || (
                    <FormattedMessage
                        id='front-app/Roles/balanceLogRecordList:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_BALANCE_LOG_RECORD_OVERVIEW_PDF: {
                titleTranslateKey: 'front-app/Roles/balanceLogRecordOverviewPdf:title',
                descriptionTranslateKey: 'front-app/Roles/balanceLogRecordOverviewPdf:description' || (
                    <FormattedMessage
                        id='front-app/Roles/balanceLogRecordOverviewPdf:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_BALANCE_LOG_RECORD_SUMMARY_INFO: {
                titleTranslateKey: 'front-app/Roles/balanceLogRecordSummaryInfo:title',
                descriptionTranslateKey: 'front-app/Roles/balanceLogRecordSummaryInfo:description' || (
                    <FormattedMessage
                        id='front-app/Roles/balanceLogRecordSummaryInfo:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_BALANCE_LOG_RECORD_TYPE_LIST: {
                titleTranslateKey: 'front-app/Roles/balanceLogRecordTypeList:title',
                descriptionTranslateKey: 'front-app/Roles/balanceLogRecordTypeList:description' || (
                    <FormattedMessage
                        id='front-app/Roles/balanceLogRecordTypeList:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_BALANCE_LOG_RECORD_USER_RECORDS_LIST: {
                titleTranslateKey: 'front-app/Roles/balanceLogRecordUserRecordsList:title',
                descriptionTranslateKey: 'front-app/Roles/balanceLogRecordUserRecordsList:description' || (
                    <FormattedMessage
                        id='front-app/Roles/balanceLogRecordUserRecordsList:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_BALANCE_LOG_RECORD_USER_RECORDS_LIST_FILTERS: {
                titleTranslateKey: 'front-app/Roles/balanceLogRecordUserRecordsListFilters:title',
                descriptionTranslateKey: 'front-app/Roles/balanceLogRecordUserRecordsListFilters:description' || (
                    <FormattedMessage
                        id='front-app/Roles/balanceLogRecordUserRecordsListFilters:description'
                        defaultMessage=''
                    />
                ),
            },
        },
    },
    user: {
        titleTranslateKey: 'front-app/Permissions/CategoryTitle:user' || (
            <FormattedMessage
                id='front-app/Permissions/CategoryTitle:user'
                defaultMessage=''
            />
        ),
        roles: {
            ROLE_USER_LIST: {
                titleTranslateKey: 'front-app/Roles/userList:title',
                descriptionTranslateKey: 'front-app/Roles/userList:description' || (
                    <FormattedMessage
                        id='front-app/Roles/userList:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_USER_READ_ACCOUNT: {
                titleTranslateKey: 'front-app/Roles/userReadAccount:title',
                descriptionTranslateKey: 'front-app/Roles/userReadAccount:description' || (
                    <FormattedMessage
                        id='front-app/Roles/userReadAccount:description'
                        defaultMessage=''
                    />
                ),
            },
            ROLE_USER_VIRTUAL_ACCOUNTS_LIST: {
                titleTranslateKey: 'front-app/Roles/userVirtualAccountsList:title',
                descriptionTranslateKey: 'front-app/Roles/userVirtualAccountsList:description' || (
                    <FormattedMessage
                        id='front-app/Roles/userVirtualAccountsList:description'
                        defaultMessage=''
                    />
                ),
            },
        },
    },
};
