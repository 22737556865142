import { observeRoundedMoney, roundMoney } from '@malesia/react-components/dist/src/utils/roundMoney';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.balanceLogState || initialState;

export const selectFilterData = createSelector(
    [selectDomain],
    state => state.filterData,
);

export const selectList = createSelector(
    [selectDomain],
    state => state.table.list,
);

export const selectPagination = createSelector(
    [selectDomain],
    state => state.table.pagination,
);

export const selectSorting = createSelector(
    [selectDomain],
    state => state.table.sorting,
);

export const selectInitialized = createSelector(
    [selectDomain],
    state => state.table.initialized,
);

export const selectLoading = createSelector(
    [selectDomain],
    state => state.table.loading,
);

export const selectDirty = createSelector(
    [selectDomain],
    state => state.table.dirty,
);

export const selectCategoryList = createSelector(
    [selectDomain],
    state => state.category.list,
);

export const selectAccountsList = createSelector(
    [selectDomain],
    state => state.account.list,
);

export const selectAccountQuery = createSelector(
    [selectDomain],
    state => state.account.query,
);

export const selectRelatedAccountsList = createSelector(
    [selectDomain],
    state => state.relatedAccount.list,
);

export const selectRelatedAccountQuery = createSelector(
    [selectDomain],
    state => state.relatedAccount.query,
);

export const selectChangeAmount = createSelector(
    [selectDomain],
    state => {
        const { summary } = state.table;
        if (!summary) return 0;
        const finalBalance = observeRoundedMoney(summary.finalBalance!, 'BalanceLogPage/store/selectors/finalBalance');
        const initialBalance = observeRoundedMoney(summary.initialBalance!, 'BalanceLogPage/store/selectors/initialBalance');

        return roundMoney(finalBalance - initialBalance);
    },
);

export const selectOverviewPdfLoading = createSelector(
    [selectDomain],
    state => state.overviewPdf.loading,
);
