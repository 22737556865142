/* Malesia API config */
import { ApiRequestMapType } from './types';

export const apiConfig: ApiRequestMapType = {
    '_default': {
        pathUri: '',
        schema: {
            request: null,
            response: '/json/schema/generic.json',
        },
        method: 'GET',
    },
    'accountFreeList': {
        pathUri: 'account/free-list',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/model/accountPaginatedList.json',
        },
        method: 'GET',
    },
    'accountList': {
        pathUri: 'account/list',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/model/accountPaginatedList.json',
        },
        method: 'GET',
    },
    'accountPaymentList': {
        pathUri: 'account/payment-list',
        schema: {
            request: null, // TODO check schemas existence
            response: null,
        },
        method: 'GET',
    },
    'allotmentInvoiceCreate': {
        pathUri: 'allotment/invoice',
        schema: {
            request: null,
            response: null,
        },
        method: 'POST',
    },
    'allotmentInvoiceUpdate': {
        pathUri: 'allotment/invoice/{invoiceId}',
        schema: {
            request: null,
            response: null,
        },
        method: 'PUT',
    },
    'allotmentInvoice': {
        pathUri: 'allotment/invoice/{invoiceId}',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'allotmentInvoicePaymentCreate': {
        pathUri: 'payment/allotment/invoice',
        schema: {
            request: null,
            response: null,
        },
        method: 'POST',
    },
    'allotmentInvoiceDelete': {
        pathUri: 'allotment/invoice/{invoiceId}',
        schema: {
            request: null,
            response: null,
        },
        method: 'DELETE',
    },
    'allotmentInvoiceList': {
        pathUri: 'allotment/invoice/list',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'agentAllotmentInvoiceList': {
        pathUri: 'agent/allotment/invoice/list',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'allotmentInvoicePdf': {
        pathUri: 'allotment/invoice/{invoiceId}/pdf/{locale}',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'agentAllotmentInvoicePdf': {
        pathUri: 'agent/allotment/invoice/{invoiceId}/pdf/{locale}',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'allotmentList': {
        pathUri: 'allotment/list',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'agentAllotmentList': {
        pathUri: 'agent/allotment/list',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'allotmentAgentBalance': {
        pathUri: 'allotment/invoice/agent/{agentId}/balance',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'agentAllotmentAgentBalance': {
        pathUri: 'agent/allotment/invoice/balance',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'authToken': {
        pathUri: 'auth/token',
        schema: {
            request: 'file://malesiareisen.com/json/schema/project/api/call/auth/token/post/request.json',
            response: 'file://malesiareisen.com/json/schema/project/api/call/auth/token/post/response.json',
        },
        method: 'POST',
    },
    'authTokenDelete': {
        pathUri: 'auth/token',
        schema: {
            request: null,
            response: null,
        },
        method: 'DELETE',
    },
    'authRecoverPassword': {
        pathUri: 'auth/recover-password/{locale}',
        schema: {
            request: 'file://malesiareisen.com/json/schema/project/api/call/auth/recoverPassword/post/request.json',
            response: null,
        },
        method: 'POST',
    },
    'authUpdatePassword': {
        pathUri: 'auth/update-password/{locale}',
        schema: {
            request: 'file://malesiareisen.com/json/schema/project/api/call/auth/updatePassword/post/request.json',
            response: null,
        },
        method: 'POST',
    },
    'balanceLogList': {
        pathUri: 'balance-log/list',
        schema: {
            request: null,
            // request: 'file://malesiareisen.com/json/schema/project/api/call/balanceLog/filters.json',
            response: null,
            // response: 'file://malesiareisen.com/json/schema/project/api/model/balanceLogPaginatedList.json',
        },
        method: 'GET',
    },
    'userBalanceLogList': {
        pathUri: 'user/me/balance-log/list',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'balanceLogCashbackPdf': {
        pathUri: 'balance-log/{transactionId}/cashback/pdf/{locale}',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'balanceLogSummaryInfo': {
        pathUri: 'balance-log/summary-info',
        schema: {
            request: null,
            // request: 'file://malesiareisen.com/json/schema/project/api/call/balanceLog/filters.json',
            response: null,
            // response: 'file://malesiareisen.com/json/schema/project/api/model/balanceLogPaginatedList.json',
        },
        method: 'GET',
    },
    'userBalanceLogSummaryInfo': {
        pathUri: 'user/me/balance-log/summary-info',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'balanceLogTypeList': {
        pathUri: 'balance-log/type/list',
        schema: {
            request: null,
            // request: 'file://malesiareisen.com/json/schema/project/api/call/balanceLog/filters.json',
            // response: null,
            response: 'file://malesiareisen.com/json/schema/project/api/model/balanceLogRecordTypeEntireList.json',
        },
        method: 'GET',
    },
    'balanceLogOverviewPDF': {
        pathUri: 'balance-log/overview/pdf',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'countryEntireList': {
        pathUri: 'country/entire-list',
        schema: {
            request: null, // TODO check schemas existence
            response: 'file://malesiareisen.com/json/schema/project/api/model/countryEntireList.json',
        },
        method: 'GET',
    },
    'countryListPublic': {
        pathUri: 'public/country/list',
        schema: {
            request: null, // TODO check schemas existence
            response: 'file://malesiareisen.com/json/schema/project/api/model/countryEntireList.json',
        },
        method: 'GET',
    },
    'tariffEntireList': {
        pathUri: 'tariff/entire-list',
        schema: {
            request: null, // TODO check schemas existence
            response: 'file://malesiareisen.com/json/schema/project/api/model/tariffEntireList.json',
        },
        method: 'GET',
    },
    'tariffGroupEntireList': {
        pathUri: 'tariff-group/entire-list',
        schema: {
            request: null, // TODO check schemas existence
            response: 'file://malesiareisen.com/json/schema/project/api/model/tariffGroupEntireList.json',
        },
        method: 'GET',
    },
    'agentCreate': {
        pathUri: 'agent',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/model/agent.json',
        },
        method: 'POST',
    },
    'agentData': {
        pathUri: 'agent/{id}',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/model/agent.json',
        },
        method: 'GET',
    },
    'agentDataUpdate': {
        pathUri: 'agent/{id}',
        schema: {
            request: null,
            // request: 'file://malesiareisen.com/json/schema/project/api/model/agent.json',
            response: 'file://malesiareisen.com/json/schema/project/api/model/agent.json',
        },
        method: 'PUT',
    },
    'agentList': {
        pathUri: 'agent/list',
        schema: {
            request: null, // TODO check schemas existence
            response: 'file://malesiareisen.com/json/schema/project/api/model/agentPaginatedList.json',
        },
        method: 'GET',
    },
    'agentDelete': {
        pathUri: 'agent/{id}',
        schema: {
            request: null, // TODO check schemas existence
            response: null,
        },
        method: 'DELETE',
    },
    'aircraftTemplateList': {
        pathUri: 'aircraft-template-generic/list',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/model/aircraftTemplateGenericPaginatedList.json',
        },
        method: 'GET',
    },
    'aircraftTemplate': {
        pathUri: 'aircraft-template-generic/{id}',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/model/aircraftTemplate.json',
        },
        method: 'GET',
    },
    'airportList': {
        pathUri: 'airport/list',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/model/airportPaginatedList.json',
        },
        method: 'GET',
    },
    'airportEntireList': {
        pathUri: 'airport/entire-list',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/model/airportEntireList.json',
        },
        method: 'GET',
    },
    'entityLogList': {
        pathUri: 'entity-log/list',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'entityLogEntityTypeList': {
        pathUri: 'entity-log/entity-type/list',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'entityLogOperationTypeList': {
        pathUri: 'entity-log/operation-type/list',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'flightsMap': {
        pathUri: 'airport/flights-map',
        schema: {
            request: null,
            response: null, //fixme add schema
        },
        method: 'GET',
    },
    'flightCompanyList': {
        pathUri: 'flight-company/list',
        schema: {
            request: null, // TODO check schemas existence
            response: 'file://malesiareisen.com/json/schema/project/api/model/flightCompanyPaginatedList.json',
        },
        method: 'GET',
    },
    'flightCompanyEntireList': {
        pathUri: 'flight-company/entire-list',
        schema: {
            request: null, // TODO check schemas existence
            response: 'file://malesiareisen.com/json/schema/project/api/model/flightCompanyEntireList.json',
        },
        method: 'GET',
    },
    'flightCompanyDelete': {
        pathUri: 'flight-company/{id}',
        schema: {
            request: null, // TODO check schemas existence
            response: null,
        },
        method: 'DELETE',
    },
    'flightCompanyCreate': {
        pathUri: 'flight-company',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/model/flightCompany.json',
        },
        method: 'POST',
    },
    'flightData': {
        pathUri: 'flight/{id}',
        schema: {
            request: null, // TODO check schemas existence
            response: 'file://malesiareisen.com/json/schema/project/api/model/flight.json',
        },
        method: 'GET',
    },
    'flightBookingInfoData': {
        pathUri: 'flight/{id}/booking-info',
        schema: {
            request: null, // TODO check schemas existence
            response: 'file://malesiareisen.com/json/schema/project/api/model/flightBookingInfo.json',
        },
        method: 'GET',
    },
    'flightTurnoverInfo': {
        pathUri: 'flight/{id}/turnover-info',
        schema: {
            request: null, // TODO check schemas existence
            response: 'file://malesiareisen.com/json/schema/project/api/model/flightTurnoverInfo.json',
        },
        method: 'GET',
    },
    'flightPassengersInfoData': {
        pathUri: 'flight/{id}/passengers-info',
        schema: {
            request: null, // TODO check schemas existence
            response: 'file://malesiareisen.com/json/schema/project/api/model/flightPassengersInfo.json',
        },
        method: 'GET',
    },
    'flightDataUpdate': {
        pathUri: 'flight/{id}',
        schema: {
            request: null,
            // request: 'file://malesiareisen.com/json/schema/project/api/model/agent.json',
            response: 'file://malesiareisen.com/json/schema/project/api/model/flight.json',
        },
        method: 'PUT',
    },
    'flightCompanyData': {
        pathUri: 'flight-company/{id}',
        schema: {
            request: null, // TODO check schemas existence
            response: 'file://malesiareisen.com/json/schema/project/api/model/flightCompany.json',
        },
        method: 'GET',
    },
    'flightCompanyDataUpdate': {
        pathUri: 'flight-company/{id}',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/model/flightCompany.json',
        },
        method: 'PUT',
    },
    'flightCreate': {
        pathUri: 'flight',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/model/flight.json',
        },
        method: 'POST',
    },
    'flightSequenceCreate': {
        pathUri: 'flight/sequence',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/model/flight.json',
        },
        method: 'POST',
    },
    'flightTurnoverListPDF': {
        pathUri: 'flight/turnover/list/pdf',
        schema: {
            request: null, // TODO check schemas existence
            response: null,
        },
        method: 'POST',
    },
    'flightPassengerReport': {
        pathUri: 'flight/passenger-report/{locale}',
        schema: {
            request: null, // TODO check schemas existence
            response: null,
        },
        method: 'POST',
    },
    'flightPassengerAppearing': {
        pathUri: 'flight/{flightId}/passenger/{id}/appearing',
        schema: {
            request: 'file://malesiareisen.com/json/schema/project/api/model/flightPassengerAppearing.json',
            response: null, // TODO add schema to json api project
        },
        method: 'PUT',
    },
    'flightPassengerAppearingDelete': {
        pathUri: 'flight/{flightId}/passenger/{id}/appearing',
        schema: {
            request: null,
            response: null, // TODO add schema to json api project
        },
        method: 'DELETE',
    },
    'flightPassengerSeat': {
        pathUri: 'flight/{flightId}/passenger/{id}/seat',
        schema: {
            request: null,
            response: null, // TODO add schema to json api project
        },
        method: 'PUT',
    },
    'flightPassengerSeatDelete': {
        pathUri: 'flight/{flightId}/passenger/{id}/seat',
        schema: {
            request: null,
            response: null, // TODO add schema to json api project
        },
        method: 'DELETE',
    },
    'flightPassengerNotifying': {
        pathUri: 'flight/{flightId}/passenger/{id}/notifying',
        schema: {
            request: 'file://malesiareisen.com/json/schema/project/api/model/flightPassengerNotifying.json',
            response: null, // TODO add schema to json api project
        },
        method: 'PUT',
    },
    'flightPassengerList': {
        pathUri: 'flight/{flightId}/passenger/list',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/model/flightPassengerEntireList.json',
        },
        method: 'GET',
    },
    'flightPassengerListPDF': {
        pathUri: 'flight/{flightId}/passenger/list/pdf',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'flightPassengerListPNL': {
        pathUri: 'flight/{flightId}/passenger/list/pnl',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'flightPassengerListPNLExternal': {
        pathUri: 'flight/{flightId}/passenger/list/pnl-external',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'flightPassengerListAppearing': {
        pathUri: 'flight/{flightId}/passenger/list/appearing',
        schema: {
            request: null,
            response: null,
        },
        method: 'PUT',
    },
    'flightPassengerListAppearingDelete': {
        pathUri: 'flight/{flightId}/passenger/list/appearing',
        schema: {
            request: null,
            response: null,
        },
        method: 'DELETE',
    },
    'flightList': {
        pathUri: 'flight/list',
        schema: {
            request: null, // TODO check schemas existence
            response: 'file://malesiareisen.com/json/schema/project/api/model/flightPaginatedList.json',
        },
        method: 'GET',
    },
    'agentFlightList': {
        pathUri: 'agent/flight/list',
        schema: {
            request: null, // TODO check schemas existence
            response: null,
        },
        method: 'GET',
    },
    'flightListDelete': {
        pathUri: 'flight/list',
        schema: {
            request: null, // TODO fix schema: 'file://malesiareisen.com/json/schema/project/api/model/flightBulkOperation.json',
            response: null,
        },
        method: 'DELETE',
    },
    'flightListUnassign': {
        pathUri: 'flight/list/sequence/assign',
        schema: {
            request: null, // TODO fix schema: 'file://malesiareisen.com/json/schema/project/api/model/flightBulkOperation.json',
            response: null,
        },
        method: 'DELETE',
    },
    'flightListDeleteSequence': {
        pathUri: 'flight/list/sequence',
        schema: {
            request: null, // TODO fix schema: 'file://malesiareisen.com/json/schema/project/api/model/flightBulkOperation.json',
            response: null,
        },
        method: 'DELETE',
    },
    'flightListActivate': {
        pathUri: 'flight/list/active',
        schema: {
            request: null, // TODO fix schema: 'file://malesiareisen.com/json/schema/project/api/model/flightBulkOperation.json',
            response: null,
        },
        method: 'PUT',
    },
    'flightListDeactivate': {
        pathUri: 'flight/list/active',
        schema: {
            request: null, // TODO fix schema: 'file://malesiareisen.com/json/schema/project/api/model/flightBulkOperation.json',
            response: null,
        },
        method: 'DELETE',
    },
    'flightSetBookedOut': {
        pathUri: 'flight/{id}/booked-out',
        schema: {
            request: null,
            response: null,
        },
        method: 'PUT',
    },
    'flightUnsetBookedOut': {
        pathUri: 'flight/{id}/booked-out',
        schema: {
            request: null,
            response: null,
        },
        method: 'DELETE',
    },
    'flightSetDelayed': {
        pathUri: 'flight/{id}/delayed',
        schema: {
            request: null,
            response: null,
        },
        method: 'PUT',
    },
    'flightSetNotDelayed': {
        pathUri: 'flight/{id}/delayed',
        schema: {
            request: null,
            response: null,
        },
        method: 'DELETE',
    },
    'flightSetStatus': {
        pathUri: 'flight/{id}/status/{status}',
        schema: {
            request: null,
            response: null,
        },
        method: 'PUT',
    },
    'managerList': {
        pathUri: 'manager/list',
        schema: {
            request: null, // TODO check schemas existence
            response: 'file://malesiareisen.com/json/schema/project/api/model/managerPaginatedList.json',
        },
        method: 'GET',
    },
    'managerDelete': {
        pathUri: 'manager/{id}',
        schema: {
            request: null, // TODO check schemas existence
            response: null,
        },
        method: 'DELETE',
    },
    'managerCreate': {
        pathUri: 'manager',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/model/manager.json',
        },
        method: 'POST',
    },
    'managerData': {
        pathUri: 'manager/{id}',
        schema: {
            request: null, // TODO check schemas existence
            response: 'file://malesiareisen.com/json/schema/project/api/model/manager.json',
        },
        method: 'GET',
    },
    'managerDataUpdate': {
        pathUri: 'manager/{id}',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/model/manager.json',
        },
        method: 'PUT',
    },
    'customerList': {
        pathUri: 'customer/list',
        schema: {
            request: null, // TODO check schemas existence
            response: 'file://malesiareisen.com/json/schema/project/api/model/customerPaginatedList.json',
        },
        method: 'GET',
    },
    'customerDelete': {
        pathUri: 'customer/{id}',
        schema: {
            request: null, // TODO check schemas existence
            response: null,
        },
        method: 'DELETE',
    },
    'customerCreate': {
        pathUri: 'customer',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/model/customer.json',
        },
        method: 'POST',
    },
    'customerData': {
        pathUri: 'customer/{id}',
        schema: {
            request: null, // TODO check schemas existence
            response: 'file://malesiareisen.com/json/schema/project/api/model/customer.json',
        },
        method: 'GET',
    },
    'customerDataUpdate': {
        pathUri: 'customer/{id}',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/model/customer.json',
        },
        method: 'PUT',
    },
    'rateList': {
        pathUri: 'rate/list',
        schema: {
            request: null, // TODO check schemas existence
            response: 'file://malesiareisen.com/json/schema/project/api/model/ratePaginatedList.json',
        },
        method: 'GET',
    },
    'rateEntireList': {
        pathUri: 'rate/entire-list',
        schema: {
            request: null, // TODO check schemas existence
            response: 'file://malesiareisen.com/json/schema/project/api/model/rateEntireList.json',
        },
        method: 'GET',
    },
    'rateCreate': {
        pathUri: 'rate',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/model/rate.json',
        },
        method: 'POST',
    },
    'rateData': {
        pathUri: 'rate/{id}',
        schema: {
            request: null, // TODO check schemas existence
            response: 'file://malesiareisen.com/json/schema/project/api/model/rate.json',
        },
        method: 'GET',
    },
    'rateUpdate': {
        pathUri: 'rate/{id}',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/model/rate.json',
        },
        method: 'PUT',
    },
    'optionList': {
        pathUri: 'option/list',
        schema: {
            request: null, // TODO check schemas existence
            response: 'file://malesiareisen.com/json/schema/project/api/model/optionPaginatedList.json',
        },
        method: 'GET',
    },
    'optionEntireList': {
        pathUri: 'option/entire-list',
        schema: {
            request: null, // TODO check schemas existence
            response: 'file://malesiareisen.com/json/schema/project/api/model/optionEntireList.json',
        },
        method: 'GET',
    },
    'optionTypeEntireList': {
        pathUri: 'option/type/entire-list',
        schema: {
            request: null, // TODO check schemas existence
            response: 'file://malesiareisen.com/json/schema/project/api/model/optionTypeEntireList.json',
        },
        method: 'GET',
    },
    'optionCreate': {
        pathUri: 'option',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/model/option.json',
        },
        method: 'POST',
    },
    'optionData': {
        pathUri: 'option/{id}',
        schema: {
            request: null, // TODO check schemas existence
            response: 'file://malesiareisen.com/json/schema/project/api/model/option.json',
        },
        method: 'GET',
    },
    'optionUpdate': {
        pathUri: 'option/{id}',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/model/option.json',
        },
        method: 'PUT',
    },
    'paymentDatatransTransaction': {
        pathUri: 'payment/datatrans/transaction/{id}',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/call/datatransTransaction/get/response.json',
        },
        method: 'GET',
    },
    'transactionReservation': {
        pathUri: 'transaction/{transactionId}/reservation',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'paymentDatatransTransactionConfirm': {
        pathUri: 'payment/datatrans/transaction/confirm',
        schema: {
            request: null,
            // request: 'file://malesiareisen.com/json/schema/project/api/call/datatransTransaction/reservation/confirm/post/request.json',
            response: null,
            // response: 'file://malesiareisen.com/json/schema/project/api/call/datatransTransaction/reservation/confirm/post/response.json',
        },
        method: 'POST',
    },
    'paymentDatatransReservationInit': {
        pathUri: 'payment/datatrans/reservation/init',
        schema: {
            request: 'file://malesiareisen.com/json/schema/project/api/call/datatransTransaction/reservation/init/post/request.json',
            response: null,
            // response: 'file://malesiareisen.com/json/schema/project/api/call/datatransTransaction/reservation/init/post/response.json',
        },
        method: 'POST',
    },
    'paymentDatatransTransactionUpdate': {
        pathUri: 'payment/datatrans/transaction/update',
        schema: {
            request: 'file://malesiareisen.com/json/schema/project/api/call/datatransTransaction/statusUpdate/post/request.json',
            response: null,
            // response: 'file://malesiareisen.com/json/schema/project/api/call/datatransTransaction/statusUpdate/post/response.json',
        },
        method: 'POST',
    },
    'paymentInvoice': {
        pathUri: 'payment/reservation/invoice',
        schema: {
            request: 'file://malesiareisen.com/json/schema/project/api/call/payment/reservationInvoice/post/request.json',
            response: null,
        },
        method: 'POST',
    },
    'publicFlightList': {
        pathUri: 'public/flight/list',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/model/publicFlightPaginatedList.json',
        },
        method: 'GET',
    },
    'paymentDatatransAgentInit': {
        pathUri: 'payment/datatrans/agent/init',
        schema: {
            request: 'file://malesiareisen.com/json/schema/project/api/call/datatransTransaction/agent/init/post/request.json',
            response: 'file://malesiareisen.com/json/schema/project/api/call/datatransTransaction/agent/init/post/response.json',
        },
        method: 'POST',
    },
    'paymentFreeTypeList': {
        pathUri: 'payment/free/type/list',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'paymentFreeAdvancedCreate': {
        pathUri: 'payment/free/advanced',
        schema: {
            request: 'file://malesiareisen.com/json/schema/project/api/call/payment/free/post/requestAdvanced.json',
            response: null,
        },
        method: 'POST',
    },
    'paymentFreeBasicCreate': {
        pathUri: 'payment/free/basic',
        schema: {
            request: 'file://malesiareisen.com/json/schema/project/api/call/payment/free/post/requestAdvanced.json',
            response: null,
        },
        method: 'POST',
    },
    'publicOptionAttachList': {
        pathUri: 'public/option/attach/list',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/model/optionPaginatedList.json',
        },
        method: 'GET',
    },
    'publicReservationPassengerSeatByFlight': {
        pathUri: 'public/flight/{id}/aircraft-passengers-seats',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/model/aircraftTemplate.json',
        },
        method: 'GET',
    },
    'publicReservationTransactionStatus': {
        pathUri:
      'public/reservation/{reservationId}/transaction/{transactionId}/status',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/call/public/reservation/transaction/status/get/response.json',
        },
        method: 'GET',
    },
    'reservation': {
        pathUri: 'reservation/{id}',
        schema: {
            request: null,
            // request: 'file://malesiareisen.com/json/schema/project/api/call/reservation/get/request.json',
            response: null,
            // response: 'file://malesiareisen.com/json/schema/project/api/model/reservationListItem.json',
        },
        method: 'GET',
    },
    'reservationCalculate': {
        pathUri: 'reservation/calculate',
        schema: {
            request: 'file://malesiareisen.com/json/schema/project/api/call/reservation/calculate/post/request.json',
            response: 'file://malesiareisen.com/json/schema/project/api/call/reservation/calculate/post/response.json',
        },
        method: 'POST',
    },
    'reservationCancel': {
        pathUri: 'reservation/{id}/cancel',
        schema: {
            request: null,
            response: null,
        },
        method: 'POST',
    },
    'reservationPost': {
        pathUri: 'reservation',
        schema: {
            request: 'file://malesiareisen.com/json/schema/project/api/call/reservation/post/request.json',
            response: 'file://malesiareisen.com/json/schema/project/api/call/reservation/post/response.json',
        },
        method: 'POST',
    },
    'reservationPut': {
        pathUri: 'reservation/{id}',
        schema: {
            request: null,
            // request: 'file://malesiareisen.com/json/schema/project/api/call/reservation/post/request.json',
            response: 'file://malesiareisen.com/json/schema/project/api/call/reservation/post/response.json',
        },
        method: 'PUT',
    },
    'splitReservation': {
        pathUri: 'reservation/{id}/split',
        schema: {
            request: null,
            response: null,
        },
        method: 'POST',
    },
    'reservationList': {
        pathUri: 'reservation/list',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'reservationListNotificationPut': {
        pathUri: 'reservation/list/notification',
        schema: {
            request: null,
            response: null,
        },
        method: 'PUT',
    },
    'userReservationList': {
        pathUri: 'user/me/reservation/list',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'reservationSnapshotList': {
        pathUri: 'reservation/{id}/snapshot/list',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'reservationTicketPdf': {
        pathUri: 'reservation/{reservationId}/ticket/pdf/{locale}',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'reservationInvoicePDF': {
        pathUri: 'reservation/{reservationId}/invoice/pdf/{locale}',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'reservationUserList': {
        pathUri: 'reservation/user/list',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'reservationPassengerList': {
        pathUri: 'reservation/passenger/list',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'getUser': {
        pathUri: 'user/{id}',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/model/user.json',
        },
        method: 'GET',
    },
    'updateUser': {
        pathUri: 'user/{id}',
        schema: {
            request: 'file://malesiareisen.com/json/schema/project/api/call/user/put/request.json',
            response: 'file://malesiareisen.com/json/schema/project/api/model/user.json',
        },
        method: 'PUT',
    },
    'userAccount': {
        pathUri: 'user/{id}/account',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },
    'userGroupList': {
        pathUri: 'user/group/list',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/call/user/groups/get/response.json',
        },
        method: 'GET',
    },
    'userRoleList': {
        pathUri: 'user/role/list',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/call/user/roles/get/response.json',
        },
        method: 'GET',
    },
    'userRoles': {
        pathUri: 'user/{id}/roles',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/call/user/roles/get/response.json',
        },
        method: 'GET',
    },
    'userList': {
        pathUri: 'user/list',
        schema: {
            request: null, // TODO check schemas existence
            response: 'file://malesiareisen.com/json/schema/project/api/model/userProfilePaginatedList.json',
        },
        method: 'GET',
    },
    'userMe': {
        pathUri: 'user/me',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/call/user/me/get/response.json',
        },
        method: 'GET',
    },
    'userVirtualAccounts': {
        pathUri: 'user/{id}/virtual-accounts',
        schema: {
            request: null,
            response: 'file://malesiareisen.com/json/schema/project/api/call/virtualAccount/list/get/response.json',
        },
        method: 'GET',
    },
    'userAssignVirtualAccounts': {
        pathUri: 'user/{id}/virtual-accounts',
        schema: {
            request: 'file://malesiareisen.com/json/schema/project/api/call/virtualAccount/post/request.json',
            response: null,
        },
        method: 'POST',
    },
    'virtualAccounts': {
        pathUri: 'virtual-account/list',
        schema: {
            request: null,
            response: null,
        },
        method: 'GET',
    },

    'datatrans-startTransaction': {
        pathUri: 'public/payment/datatrans/start',
        schema: {
            request: 'file://malesiareisen.com/json/schema/project/api/call/public/payment/datatrans/start/post/request.json',
            response: 'file://malesiareisen.com/json/schema/project/api/call/public/payment/datatrans/start/post/response.json',
        },
        method: 'POST',
    },
};
