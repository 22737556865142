import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { PassengerInfo } from '../../../utils/reservation/types';
import { SplitReservationState } from './types';

export type OpenSplitReservationOptions = {
    reservationId: number,
    passengerId?: number,
};

export const initialState: SplitReservationState = {
    reservationId: -1,
    passengers: [],
    isLoading: true,
    isOpen: false,
};

export type SplitReservationOptions = {
    passengerId: number,
    callback: () => void,
};

const splitReservationSlice = createSlice({
    name: 'splitReservation',
    initialState,
    reducers: {
        openPopup(state, action: PayloadAction<OpenSplitReservationOptions>) {
            const { reservationId, passengerId } = action.payload;
            state.isOpen = true,
            state.reservationId = reservationId;
            state.defaultPassengerId = passengerId;
        },
        openPopupSuccess(state, action: PayloadAction<PassengerInfo[]>) {
            state.isLoading = false;
            state.passengers = action.payload;
        },
        openPopupError(state) {
            state.isLoading = false;
        },
        closePopup() {
            return initialState;
        },
        splitReservation(state, action: PayloadAction<SplitReservationOptions>) {
            state.isLoading = true;
        },
        splitReservationSuccess() {
            return initialState;
        },
        splitReservationError(state) {
            state.isLoading = false;
        },
    },
});

export const {
    actions: splitReservationActions,
    reducer: splitReservationReducer,
    name: splitReservationSliceKey,
} = splitReservationSlice;
