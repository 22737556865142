import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../utils/log';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { apiClientRequest, getApiErrorMessage } from '../../services/ApiClient';
import { mapManagerToPutRequest } from '../../services/Manager/manager.service';
import { managerNotifications } from '../ManagerNewPage/notification';
import { actions } from './slice';

export function* getManagerData(action: ReturnType<typeof actions.getManagerData>) {
    try {
        const managerId = action.payload;

        const requestData = {
            requestId: 'managerData',
            uriParams: { id: managerId },
        };

        const result = yield* call(apiClientRequest, requestData);
        yield* put(actions.getManagerDataSuccess(result));
    }
    catch (error) {
        logError({
            error,
            target: 'ManagerEditPage.getManagerData',
        });
        yield* put(actions.getManagerDataError(error));
        const message = getApiErrorMessage(error);
        yield* put(managerNotifications.unknownError(message));
    }
}

export function* getManagerDataSaga() {
    yield* takeLatest(actions.getManagerData, getManagerData);
}

export function* updateManagerData(action: ReturnType<typeof actions.updateManagerData>) {
    try {
        const { managerId, managerData } = action.payload;

        yield* call(apiClientRequest, {
            requestId: 'managerDataUpdate',
            uriParams: { id: managerId },
            requestPayload: mapManagerToPutRequest(managerData),
        });
        yield* put(actions.updateManagerDataSuccess());
        yield* put(push(allAdminRoutePaths.userManagerList));
        yield* put(managerNotifications.updatedSuccessfully);
    }
    catch (error) {
        logError({
            error,
            target: 'ManagerEditPage.updateManagerData',
        });
        yield* put(actions.updateManagerDataError());
        const message = getApiErrorMessage(error);
        yield* put(managerNotifications.unknownError(message));
    }
}

export function* updateManagerDataSaga() {
    yield* takeLatest(actions.updateManagerData, updateManagerData);
}
