import { AllotmentInvoiceListItem, AllotmentInvoicePaginatedList, ApiCallAllotmentInvoiceSortingParams } from '@malesia/json-schema';
import { AllotmentInvoiceFilterData } from '@malesia/react-components/dist/src/components/Allotment/InvoiceList/Filter';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../../utils/@reduxjs/toolkit';
import { AllotmentInvoiceListPageState } from './types';

export const initialState: AllotmentInvoiceListPageState = {
    filterData: {
        status: ['unpaid'],
    },
    table: {
        list: {
            items: [],
            total: 0,
        },
        pagination: {
            page: 0,
            pageSize: 10,
        },
        sorting: {
            sortBy: 'invoiceDate',
            sortOrder: 'DESC',
        },
        dirty: true,
        loading: true,
    },
    deleteInvoice: {},
};

const allotmentListPageSlice = createSlice({
    name: 'allotmentInvoiceListPage',
    initialState,
    reducers: {
        setPage(state, action: PayloadAction<number>) {
            state.table.pagination.page = action.payload;
            state.table.dirty = true;
        },
        setPageSize(state, action: PayloadAction<number>) {
            state.table.pagination.pageSize = action.payload;
            state.table.pagination.page = initialState.table.pagination.page;
            state.table.dirty = true;
        },
        setSorting(state, action: PayloadAction<ApiCallAllotmentInvoiceSortingParams>) {
            state.table.sorting = action.payload;
            state.table.dirty = true;
        },
        setFilterData(state, action: PayloadAction<Partial<AllotmentInvoiceFilterData>>) {
            state.filterData = action.payload;
            state.table.pagination.page = initialState.table.pagination.page;
            state.table.dirty = true;
        },
        resetFilterData(state) {
            state.filterData = initialState.filterData;
            state.table.pagination.page = initialState.table.pagination.page;
            state.table.dirty = true;
        },
        getAllotmentInvoiceList(state) {
            state.table.loading = true;
        },
        getAllotmentInvoiceListSuccess(state, action: PayloadAction<AllotmentInvoicePaginatedList>) {
            state.table.list = action.payload;
            state.table.dirty = false;
            state.table.loading = false;
        },
        getAllotmentInvoiceListError(state) {
            state.table.loading = false;
        },
        openDeleteInvoicePopup(state, action: PayloadAction<AllotmentInvoiceListItem>) {
            state.deleteInvoice.invoice = action.payload;
            state.deleteInvoice.open = true;
        },
        cancelDeleteInvoicePopup(state) {
            state.deleteInvoice = initialState.deleteInvoice;
        },
        confirmDeleteInvoicePopup(state) {
            state.deleteInvoice.loading = true;
        },
        confirmDeleteInvoicePopupSuccess(state) {
            state.deleteInvoice = initialState.deleteInvoice;
        },
        confirmDeleteInvoicePopupError(state) {
            state.deleteInvoice.loading = false;
        },
        getAgentBalance(state, action: PayloadAction<number | undefined>) {
            state.agentBalance = initialState.agentBalance;
        },
        getAgentBalanceSuccess(state, action: PayloadAction<number | undefined>) {
            state.agentBalance = action.payload;
        },
        getAgentBalanceFail(state) {
            state.agentBalance = initialState.agentBalance;
        },
        unmount(state) {
            return {
                ...initialState,
                filterData: state.filterData,
            };
        },
    },
});

export const {
    actions,
    reducer,
    name: sliceKey,
} = allotmentListPageSlice;
