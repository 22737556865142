import { AirportEntireList, AirportSearchQuery } from '@malesia/json-schema';
import { call } from 'typed-redux-saga';
import { apiClientRequest } from './ApiClient';

export function* getApiAirportList(params?: AirportSearchQuery) {
    const requestData = {
        requestId: 'airportEntireList',
        query: params ?? '',
    };
    const response: AirportEntireList = yield* call(apiClientRequest, requestData);
    return response;
}
