import { PassengersAges, TravelType } from '@malesia/react-components/dist/src/components/Reservation/reservation-types';
import { AdminStepTravelDateForm } from '@malesia/react-components/dist/src/components/Reservation/StepTravelDate/AdminStepTravelDate';
import { createPassengerList } from '../../../../../utils/reservation/passengerUtils';
import { AdminReservationBasket, TravelFlight } from '../../types';

export const createAdminReservationBasketByTravel = (
    formData: AdminStepTravelDateForm,
    travelType: TravelType,
    prevReservation: AdminReservationBasket,
): AdminReservationBasket => {
    const returnTravelMap: Record<TravelType, TravelFlight | undefined> = {
        'one-way': {},
        'other-return': {
            date: formData.returning,
            airports: {
                originAirportCode: formData.otherFromAirportCode,
                destinationAirportCode: formData.otherToAirportCode,
            },
        },
        'return': {
            date: formData.returning,
            airports: {
                originAirportCode: formData.toAirportCode,
                destinationAirportCode: formData.fromAirportCode,
            },
        },
    };

    const ageConfig: PassengersAges = {
        adult: formData.adults,
        child: formData.children,
        infant: formData.infants,
    };
    const passengers = createPassengerList(ageConfig, prevReservation.passengers);

    return {
        travelInfo: {
            type: travelType,
            outbound: {
                date: formData.departing,
                airports: {
                    originAirportCode: formData.fromAirportCode,
                    destinationAirportCode: formData.toAirportCode,
                },
            },
            ['return']: returnTravelMap[travelType] ?? {},
        },
        selectedFlights: prevReservation.selectedFlights,
        bookedFlights: prevReservation.bookedFlights,
        bookedOptionList: prevReservation.bookedOptionList,
        passengers,
        passengersAges: {
            adult: formData.adults,
            child: formData.children,
            infant: formData.infants,
        },
        payment: {
            existingPayments: [],
            paymentTerms: prevReservation.payment.paymentTerms,
            paymentDeadline: prevReservation.payment.paymentDeadline,
            specificPrice: prevReservation.payment.specificPrice,
        },
    };
};
