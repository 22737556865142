import { ReservationInvoice } from '@malesia/json-schema';
import { getLocation, push } from 'connected-react-router';
import moment from 'moment';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { getDatatransSearchParams } from '../../../../utils/datatrans/getDatatransSearchParams';
import { logError } from '../../../../utils/log';
import { selectRoot } from '../../../containers/App/selectors';
import { allPublicRoutePaths } from '../../../containers/Routes/allPublicRoutePaths';
import { datatransService } from '../../../services/Datatrance/datatrans.service';
import { paymentNotifications } from '../../ReservationPaymentPage/store/notification';
import { datatransErrorPageActions } from './datatransError.slice';

function* loadInitialData() {
    try {
        const { transactionId } = getDatatransSearchParams();

        if (!transactionId) {
            yield* put(paymentNotifications.notFoundTransaction);
            yield* put(push('/'));
            return;
        }

        const rootState = yield* select(selectRoot);
        if (getLocation(rootState).pathname == allPublicRoutePaths.datatransError) {
            const transaction = yield* call(datatransService.paymentDatatransTransactionUpdate, { transactionId });
            const reason = transaction.details?.fail?.message;
            yield* put(paymentNotifications.datatransError(reason));
        }

        const reservation = yield* call(datatransService.getDatatransTransactionReservation, transactionId);
        yield* put(datatransErrorPageActions.setReservation(reservation));

        if (getLocation(rootState).pathname == allPublicRoutePaths.datatransCancel) {
            const invoice = reservation.invoice as ReservationInvoice;
            const deadLine = moment(invoice.paymentDeadline);
            const now = moment();

            if (now.isAfter(deadLine)) {
                yield* put(paymentNotifications.expired);
                yield* put(push('/'));
                return;
            }
            else {
                const duration = moment.utc(deadLine.diff(now)).format('mm');
                yield* put(paymentNotifications.onCancel(duration));
            }
        }
    }
    catch (error) {
        logError({
            error,
            target: 'MalesiaDatatransErrorPage.loadInitialData',
        });

        yield* put(paymentNotifications.notFoundTransaction);
        yield* put(push('/'));
    }
}

export function* loadInitialDataSaga() {
    yield* takeLatest(datatransErrorPageActions.loadInitialData, loadInitialData);
}
