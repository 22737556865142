import { calculateAgeTypeOnFlight } from '@malesia/react-components/dist/src/utils/ageType';
import { put, select, takeLatest } from 'typed-redux-saga';
import { selectPassengers, selectSelectedFlightVariants } from './selectors';
import { reservationNewPageActions } from './slice';

export function* setSelectedPassenger(action: ReturnType<typeof reservationNewPageActions.setSelectedPassenger>) {
    const { id, ...passengerData } = action.payload;
    const passengers = yield* select(selectPassengers);
    const flights = yield* select(selectSelectedFlightVariants);
    const outbound = flights.outbound?.flight;

    let emptyPassengerPosition = passengers.findIndex(
        passenger => {
            return !passenger.info.firstName && !passenger.info.lastName;
        },
    );

    if (emptyPassengerPosition === -1) {
        emptyPassengerPosition = passengers.length;
        yield* put(reservationNewPageActions.addPassenger());
    }

    const ageType = calculateAgeTypeOnFlight(passengerData.birthday, outbound?.departure);
    yield* put(reservationNewPageActions.updatePassengerAtPosition({
        passengerData: { ageType, ...passengerData },
        position: emptyPassengerPosition,
    }));
}

export function* setSelectedPassengerSaga() {
    yield* takeLatest(reservationNewPageActions.setSelectedPassenger, setSelectedPassenger);
}
