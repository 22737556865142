import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { checkSamePassengerSaga } from './saga';
import { reservationSamePassengersActions, reservationSamePassengersReducer, reservationSamePassengersSliceKey } from './slice';

export const useInjectReservationSamePassengers = () => {
    useInjectReducer({
        actions: reservationSamePassengersActions,
        key: reservationSamePassengersSliceKey,
        reducer: reservationSamePassengersReducer,
    });
    useInjectSaga({
        key: `${reservationSamePassengersSliceKey}CheckSamePassenger`,
        saga: checkSamePassengerSaga,
    });
};
