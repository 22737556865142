import { FileMalesiareisenComJsonSchemaProjectApiCallReservationSortingParamsDefJson, ReservationListItem } from '@malesia/json-schema';
import { LoadingTable } from '@malesia/react-components/dist/src/components/LoadingOverlay/Table';
import { Table, SortingDataType, TableProps } from '@malesia/react-components/dist/src/components/Table';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectLoading,
    selectDirty,
    selectList,
    selectSorting,
} from '../store/selectors';
import { actions } from '../store/slice';
import { useInvoiceOverviewColumns } from '../tableColumns';

export const ReservationInvoiceListWithStore = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectLoading);
    const isDirty = useSelector(selectDirty);
    const reservationList = useSelector(selectList);
    const sorting = useSelector(selectSorting);

    // eslint-disable-next-line max-len
    const handleSetSort = useCallback((data: FileMalesiareisenComJsonSchemaProjectApiCallReservationSortingParamsDefJson) => {
        dispatch(actions.setSorting({
            sortBy: data.sortBy,
            sortOrder: data.sortOrder,
        }));
    }, [dispatch]);

    const columns = useInvoiceOverviewColumns();

    const tableProps: TableProps<ReservationListItem> = {
        columns,
        data: reservationList?.items ?? [],
        defaultSortBy: sorting.sortBy,
        defaultSortOrder: sorting.sortOrder,
        manualSortBy: true,
        // Todo make ReactTableFCProps['sorting'] compatible with enum'ed sortBy
        setSort: handleSetSort as (data: SortingDataType) => void,
    };

    return (
        <LoadingTable
            isLoading={isLoading}
            isDirty={isDirty}
        >
            <Table {...tableProps} />
        </LoadingTable>
    );
};
