export class ApiResponseError extends Error {
    public status: number;
    public statusText: string;
    public response: Response;
    public request?: unknown;

    constructor(response: Response, request?: unknown) {
        super(response.statusText);
        this.status = response.status;
        this.statusText = response.statusText;
        this.response = response;
        this.request = request;
    }
}

// API returns 401 status code on invalid credentials
export const checkInvalidCredentials = (error: unknown) => (
    error instanceof ApiResponseError && error.status === 401
);
