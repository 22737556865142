import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../types';
import { initialState } from './userAccount.slice';

const selectDomain = (state: RootState) => {
    return state.userAccountPageState || initialState;
};

export const selectLoadingUserAccountPage = createSelector(
    [selectDomain],
    state => state.loading,
);
