import { AdminBillingFormData } from '@malesia/react-components/dist/src/components/Reservation/BillingInformation/AdminBillingInformation';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { put, select, takeLatest } from 'typed-redux-saga';
import { selectUserLoginUserInfo } from '../../../containers/UserLogin/selectors';
import { initialState, reservationNewPageActions } from './slice';

function* loadNewReservation() {
    const user = (yield* select(selectUserLoginUserInfo))!;

    const billingInformation = omitBy({
        country: user.country?.code,
        phoneNumberMobile: user.phoneMobile,
        phoneNumberFirst: user.phoneNumberFirst,
        phoneNumberSecond: user.phoneNumberSecond,
        language: user.language,
        salutation: user.salutation,
        company: user.company,
        firstName: user.firstName,
        email: user.email,
        birthday: user.birthday,
        street: user.street,
        zip: user.zip,
        city: user.city,
    }, isNil) as Partial<AdminBillingFormData>;
    // fixme typecast because userInfo != billingInformation
    const reservation = {
        ...initialState.reservation,
        billingInformation: billingInformation as AdminBillingFormData,
    };
    yield* put(reservationNewPageActions.loadNewReservationSuccess(reservation));
}

export function* loadNewReservationSaga() {
    yield* takeLatest(reservationNewPageActions.loadNewReservation, loadNewReservation);
}
