import { push } from 'connected-react-router';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../../../utils/log';
import { parseReservationError } from '../../../../../utils/reservation/notifyReservationError';
import { selectReservationBasket } from '../../../../containers/ReservationBasket/reservationBasket.selectors';
import { reservationBasketActions } from '../../../../containers/ReservationBasket/reservationBasket.slice';
import { PublicReservationBasket } from '../../../../containers/ReservationBasket/reservationBasket.types';
import { allPublicRoutePaths } from '../../../../containers/Routes/allPublicRoutePaths';
import { datatransService, Transaction } from '../../../../services/Datatrance/datatrans.service';
import { createApiReservation } from '../../../../services/Reservation';
import { mapPublicReservationToPostRequest } from '../../../../services/Reservation/utils';
import { bookingFlightActions } from '../../store/slice';
import { stepPassengersActions } from '../StepPassengers/slice';
import { stepReservationOverviewActions } from '../StepReservationOverview/slice';

export function* createReservationDataAndStartTransaction() {
    const basket: PublicReservationBasket = yield* select(selectReservationBasket);
    try {
        const mappedData = mapPublicReservationToPostRequest(basket);
        const reservation = yield* call(createApiReservation, mappedData);
        const requestData = { reservationId: reservation.id, amount: reservation.invoice!.total, source: 'customerReservation' };
        const transaction: Transaction = yield* call(datatransService.paymentDatatransReservationInit, requestData);
        yield* call(resetReservationState);
        const popupResult = yield* call(datatransService.showDatatransPopup, transaction.transactionId.toString());
        if (popupResult === 'closed') {
            const route = `${allPublicRoutePaths.datatransCancel}?datatransTrxId=${transaction.transactionId}&status=cancel&source=customerReservation`;
            yield* put(push(route));
        }
    }
    catch (error) {
        logError({
            error,
            target: 'BookingFlightPage.createReservationDataAndStartTransaction',
        });
        yield* put(parseReservationError(error, (flightId) => {
            if (flightId === undefined) return undefined;
            if (basket.selectedFlights?.outbound?.id === flightId) return 'outbound';
            if (basket.selectedFlights?.return?.id === flightId) return 'outbound';
            return undefined;
        }));
    }
    finally {
        yield* put(stepReservationOverviewActions.resetLoading());
    }
}

export function* resetReservationState() {
    yield* put(reservationBasketActions.reset());
    yield* put(bookingFlightActions.reset());
    yield* put(stepPassengersActions.reset());
    yield* put(stepReservationOverviewActions.reset());
}

export function* createReservationAndStartTransactionSaga() {
    yield* takeLatest(
        stepReservationOverviewActions.createReservationAndStartTransaction,
        createReservationDataAndStartTransaction,
    );
}
