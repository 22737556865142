import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { permissionMaker } from '../permissionExpression';
import { PaginatedAdminRoutePermissions } from '.';

const { role, every } = permissionMaker;

const rateListDependencies = role('ROLE_TARIFF_LIST');
const rateOptionEdit = role('ROLE_RESERVATION_OPTION_UPDATE');
const rateOptionList = every(
    'ROLE_RATE_LIST',
    rateListDependencies,
);
const rateOptionNew = role('ROLE_RESERVATION_OPTION_CREATE');
const rateEdit = every(
    'ROLE_RATE_READ',
    'ROLE_RATE_UPDATE',
);
const rateList = every(
    'ROLE_RATE_LIST',
    rateListDependencies,
);
const rateNew = role('ROLE_RATE_CREATE');

export const rate: PaginatedAdminRoutePermissions['rate'] = {
    [allAdminRoutePaths.rateOptionEdit]: rateOptionEdit,
    [allAdminRoutePaths.rateOptionList]: rateOptionList,
    [allAdminRoutePaths.rateOptionNew]: rateOptionNew,
    [allAdminRoutePaths.rateEdit]: rateEdit,
    [allAdminRoutePaths.rateList]: rateList,
    [allAdminRoutePaths.rateNew]: rateNew,
    [allAdminRoutePaths.rate]: rateList,
};
