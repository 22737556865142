import { NewTransactionTabId } from '@malesia/react-components/dist/src/components/Accounting/NewTransaction';
import { PanelTabItem } from '@malesia/react-components/dist/src/components/Form/PanelTabs';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { newTransactionPermissions } from '../../permissions/adminPages/balance/newTransaction';
import { useUserPermissions } from '../../permissions/useUserPermissions';
import { AdvancedTab } from './tabs/advanced';
import { CashbackTab } from './tabs/cashback';
import { ChargeAccountTab } from './tabs/chargeAccount';
import { DepositsTab } from './tabs/deposits';

export const useNewTransactionTabs = () => {
    const userPermissions = useUserPermissions();

    const tabs: PanelTabItem<NewTransactionTabId>[] = [
        {
            id: 'cashback',
            label: (
                <FormattedMessage
                    id='front-app/Routes/NewTransaction/Simple:cashback'
                    defaultMessage='Cashback'
                />
            ),
            Panel: CashbackTab,
            hidden: !userPermissions.has(newTransactionPermissions.cashbackPayment),
        },
        {
            id: 'chargeAccount',
            label: (
                <FormattedMessage
                    id='front-app/Routes/NewTransaction/Simple:chargeAccount'
                    defaultMessage='Charge account'
                />
            ),
            Panel: ChargeAccountTab,
            hidden: !userPermissions.has(newTransactionPermissions.chargeAccountPayment),
        },
        {
            id: 'deposits',
            label: (
                <FormattedMessage
                    id='front-app/Routes/NewTransaction/Simple:deposits'
                    defaultMessage='Deposits'
                />
            ),
            Panel: DepositsTab,
            hidden: !userPermissions.has(newTransactionPermissions.depositsPayment),
        },
        {
            id: 'advanced',
            label: (
                <FormattedMessage
                    id='front-app/Routes/NewTransaction/Advanced:title'
                    defaultMessage='Advanced'
                />
            ),
            Panel: AdvancedTab,
            hidden: !userPermissions.has(newTransactionPermissions.advancedPayment),
        },
    ];

    return tabs;
};
