import { MultiLanguage } from '@malesia/json-schema';

export function getLocalizedString(textCollection: MultiLanguage, locale: string): string {
    const result = textCollection[locale];
    if (result !== undefined) {
        return result;
    }
    console.warn(`no localization for locale: ${locale}`);
    return textCollection['en'] ?? '';
}
