import { put, select, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../../utils/log';
import { getApiErrorMessage } from '../../../services/ApiClient';
import { sendApiReservationNotification } from '../../../services/Reservation';
import { reservationsListNotifications } from './notifications';
import {
    selectNotificationPopupFormData,
    selectSelectedRows,
} from './selectors';
import { actions } from './slice';

function* confirmSmsNotification() {
    try {
        const reservations = yield* select(selectSelectedRows);
        const data = yield* select(selectNotificationPopupFormData);

        yield* sendApiReservationNotification(reservations.map(x => x.id), data);
        yield* put(actions.confirmSmsNotificationSuccess());
        yield* put(reservationsListNotifications.notificationSuccess);
    }
    catch (error) {
        logError({
            error,
            target: 'ReservationsListPage.confirmSmsNotification',
        });
        yield* put(actions.confirmSmsNotificationFail());
        const message = getApiErrorMessage(error);
        yield* put(reservationsListNotifications.unknownError(message));
    }
}

export function* confirmSmsNotificationSaga() {
    yield* takeLatest(actions.confirmSmsNotification, confirmSmsNotification);
}
