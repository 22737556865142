import { ContentPage } from '@malesia/react-components/dist/src/components/ContentPage/ContentPage';
import { CompanyCardTree } from '@malesia/react-components/dist/src/components/Front/CompanyCard/Tree';
import { HtmlBlock } from '@malesia/react-components/dist/src/components/Front/HtmlBlock/HtmlBlock';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { useIntl } from 'react-intl';
import useSWR from 'swr';
import { loadHistoryPageContent } from '../../services/directus/historyPage/loadHistoryPageContent';

export const MalesiaHistoryPage: SFC = () => {
    const { locale } = useIntl();
    const { data } = useSWR([locale, 'loadHistoryPageContent'], loadHistoryPageContent);

    return (
        <>
            <ContentPage
                image={data?.topImage}
            >
                <HtmlBlock content={data?.content ?? ''} />
                <CompanyCardTree
                    cards={data?.cards ?? []}
                    milestones={data?.milestones}
                />
            </ContentPage>
        </>
    );
};
