import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { permissionMaker } from '../permissionExpression';
import { PaginatedAdminRoutePermissions } from '.';

const { some, every } = permissionMaker;

const balanceLog = some(
    'ROLE_BALANCE_LOG_RECORD_LIST',
    'ROLE_BALANCE_LOG_RECORD_USER_RECORDS_LIST',
);
const newTransaction = every(
    some(
        'ROLE_BALANCE_LOG_RECORD_BASIC_PAYMENT_CASHBACK',
        'ROLE_BALANCE_LOG_RECORD_BASIC_PAYMENT_PREPAID_DEPOSIT',
        'ROLE_BALANCE_LOG_RECORD_BASIC_PAYMENT_SETTLEMENT',
        'ROLE_BALANCE_LOG_RECORD_ADVANCED_PAYMENT',
    ),
    'ROLE_BALANCE_LOG_RECORD_TYPE_LIST',
    'ROLE_ACCOUNT_LIST',
    'ROLE_ACCOUNT_LIST_FREE',
);

export const balance: PaginatedAdminRoutePermissions['balance'] = {
    [allAdminRoutePaths.balanceLog]: balanceLog,
    [allAdminRoutePaths.newTransaction]: newTransaction,
    [allAdminRoutePaths.invoice]: balanceLog,
};
