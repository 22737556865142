import { ApiCallReservationFilters, FileMalesiareisenComJsonSchemaProjectApiCallReservationSortingParamsDefJson } from '@malesia/json-schema';
import { PaginationData } from '@malesia/react-components/dist/src/components/Pagination';
import { ReservationInvoiceListFilterData } from '@malesia/react-components/dist/src/components/Reservation/InvoiceList/Filter/types';
import { ReservationListFilterData } from '@malesia/react-components/dist/src/components/Reservation/List/Filter/types';
import { reservationListPermissions } from '../../permissions/adminPages/reservation/reservationList';
import { UserPermissions } from '../../permissions/userPermissions';
import { mapRequestArray, mapRequestInterval } from '../utils';

type ReservationHiddenFilters = {
    // eslint-disable-next-line no-unused-vars
    [Key in keyof ApiCallReservationFilters]?: boolean
};
export const getReservationListHiddenFilters = (
    userPermissions: UserPermissions,
): ReservationHiddenFilters => ({
    reservationStatus: userPermissions.userType === 'customer',
    paymentStatus: userPermissions.userType === 'customer',
    createdAtInterval: userPermissions.userType === 'customer',
    paymentDateInterval: userPermissions.userType === 'customer',
    reservatorId: !userPermissions.has(reservationListPermissions.reservatorFilter),
    ownerId: !userPermissions.has(reservationListPermissions.ownerFilter),
});

export type ReservationListCommonFilterData = (
    & ReservationListFilterData
    & ReservationInvoiceListFilterData
);
export const buildReservationListRequest = (
    userPermissions: UserPermissions,
    pagination: PaginationData,
    sorting: FileMalesiareisenComJsonSchemaProjectApiCallReservationSortingParamsDefJson,
    form: ReservationListCommonFilterData,
): ApiCallReservationFilters => {
    const hiddenFilters = getReservationListHiddenFilters(userPermissions);
    const filters: ApiCallReservationFilters = {
        query: form.query || undefined,
        isActive: form.isActive || undefined,
        reservationStatus: mapRequestArray(form.reservationStatus),
        paymentStatus: mapRequestArray(form.paymentStatus),
        flightDateInterval: mapRequestInterval(form.flightDateInterval),
        createdAtInterval: mapRequestInterval(form.createdAtInterval),
        paymentDateInterval: mapRequestInterval(form.paymentDateInterval),
        deadlineDateInterval: mapRequestInterval(form.deadlineDateInterval),
        paymentTerms: mapRequestArray(form.paymentTerms),
        reservatorId: form.reservator?.id,
        ownerId: form.owner?.id,
    };
    const filteredFilters = Object.keys(filters).reduce<ApiCallReservationFilters>((acc, key) => ({
        ...acc,
        [key]: hiddenFilters[key] ? undefined : filters[key],
    }), {});

    return {
        ...pagination,
        ...sorting,
        ...filteredFilters,
    };
};
