import { AdminReservationSummary, CommentFormType } from '@malesia/react-components/dist/src/components/Reservation/Summary/Admin';
import { PriceItemType } from '@malesia/react-components/dist/src/components/Reservation/Summary/Info/PriceBlock';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import { localizeMoney } from '@malesia/react-components/dist/src/utils/roundMoney';
import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { PassengerBasket } from '../../../../utils/reservation/types';
import { messages } from '../messages';
import {
    selectPassengersTickets,
    selectSummaryFlights,
    selectSummaryOptions,
    selectTotalCost,
    selectSummaryTotalSeats,
    selectSummaryTotalSeatsCount,
    selectPassengers,
    selectReservationComment,
    selectBookedCost,
} from '../store/selectors';
import { reservationNewPageActions } from '../store/slice';
import { useCommentFormConfig } from './config';

const toPassengersForCost = (passengers: PassengerBasket[]) => passengers.map(passenger => ({
    ...passenger,
    info: {
        ...passenger.info,
        ageType: undefined,
    },
}));
const isEqualPassengersForCost = (x: PassengerBasket[], y: PassengerBasket[]) => (
    isEqual(toPassengersForCost(x), toPassengersForCost(y))
);

export const ReservationNewPageSummary: SFC = () => {

    const dispatch = useDispatch();
    const { locale, formatMessage } = useIntl();

    const flights = useSelector(selectSummaryFlights);
    const passengersTickets = useSelector(selectPassengersTickets);
    const totalCost = useSelector(selectTotalCost);
    const bookedCost = useSelector(selectBookedCost);
    const bookingOptions = useSelector(selectSummaryOptions);
    const passengersForCost = useSelector(selectPassengers, isEqualPassengersForCost);
    const totalSeatsCost = useSelector(selectSummaryTotalSeats);
    const totalSeatsCount = useSelector(selectSummaryTotalSeatsCount);
    const comment = useSelector(selectReservationComment);

    const totalSeats = useMemo((): PriceItemType[] => totalSeatsCount ? [{
        labelText: formatMessage(messages.seatsLabel, { total: totalSeatsCount }),
        priceText: `CHF ${localizeMoney(totalSeatsCost, locale, 'ReservationNewPageSummary/totalSeats')}`,
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }] : [], [formatMessage, totalSeatsCost, totalSeatsCount, locale]);

    useEffect(() => {
        dispatch(reservationNewPageActions.requestCalculateCost());
    }, [dispatch, flights, passengersForCost]);

    const totalText = `CHF ${localizeMoney(totalCost, locale, 'ReservationNewPageSummary/totalText')}`;
    const bookedText = bookedCost ? `CHF ${localizeMoney(bookedCost, locale, 'ReservationNewPageSummary/bookedText')}` : undefined;

    const formConfig = useCommentFormConfig({ comment });
    const handleFormOnChange = useCallback((data: Partial<CommentFormType>) => {
        dispatch(reservationNewPageActions.setReservationComment(data.comment));
    }, [dispatch]);

    return (
        <AdminReservationSummary
            formConfig={formConfig}
            formOnChange={handleFormOnChange}
            bookingOptions={bookingOptions}
            flights={flights}
            passengersTickets={passengersTickets}
            seats={totalSeats}
            totalPriceText={totalText}
            bookedPriceText={bookedText}
        />
    );
};
