import { ReservationInvoice, ReservationListItem } from '@malesia/json-schema';
import { reservationColumns } from '@malesia/react-components/dist/src/components/Reservation/List/tableColumns';
import { TableColumn } from '@malesia/react-components/dist/src/components/Table';
import { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createFlightsStatus } from '../../../utils/reservation/flightsStatus';
import { makePath } from '../../../utils/uriUtils';
import { selectIsLoadingInvoiceMap, selectIsLoadingOverviewMap } from '../../containers/ReservationPdf/selectors';
import { reservationPdfActions } from '../../containers/ReservationPdf/slice';
import { ReservationPdfState } from '../../containers/ReservationPdf/types';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { backLinks } from '../../containers/Routes/backLinks';
import { splitReservationActions } from '../../containers/SplitReservation/slice';
import { reservationListPermissions } from '../../permissions/adminPages/reservation/reservationList';
import { useUserPermissions } from '../../permissions/useUserPermissions';
import { actions } from './store/slice';

type TableRef = {
    isLoadingOverviewMap: ReservationPdfState['isLoadingOverviewMap'],
    isLoadingInvoiceMap: ReservationPdfState['isLoadingInvoiceMap'],
};

const paidStatuses: ReservationInvoice['status'][] = [
    'paid',
    'overpaid',
];

export const useReservationColumns = (): TableColumn<ReservationListItem>[] => {
    const userPermissions = useUserPermissions();
    const isLoadingOverviewMap = useSelector(selectIsLoadingOverviewMap);
    const isLoadingInvoiceMap = useSelector(selectIsLoadingInvoiceMap);
    const history = useHistory();
    const dispatch = useDispatch();

    const tableRef: TableRef = {
        isLoadingOverviewMap,
        isLoadingInvoiceMap,
    };
    const ref = useRef<TableRef>(tableRef);
    ref.current = tableRef;

    const columns = useMemo<TableColumn<ReservationListItem>[]>(() => {
        const isCustomer = userPermissions.userType === 'customer';
        const result = reservationColumns({
            select: {
                hidden: !userPermissions.has(reservationListPermissions.bulkActions),
            },
            id: {
                hidden: isCustomer,
            },
            status: {
                hidden: isCustomer,
            },
            owner: {
                hidden: isCustomer,
            },
            reservator: {
                hidden: isCustomer,
            },
            actions: {
                editReservation: {
                    click: (reservation) => {
                        const link = backLinks.reservation.forward(reservation.id!, {
                            source: 'reservationList',
                        });
                        history.push(link);
                    },
                    disabled: (reservation) => reservation.status === 'cancelled',
                    // Agent can't update reservation by design. If need than resolve permissions by own reservation like payReservation.
                    notAllowed: !userPermissions.has(reservationListPermissions.editLink) || !(userPermissions.userType === 'manager'),
                },
                payReservation: {
                    click: (reservation) => {
                        const link = backLinks.reservationPayment.forward(reservation.id!, {
                            source: 'reservationList',
                        });
                        history.push(link);
                    },
                    notAllowed: (
                        !userPermissions.has(reservationListPermissions.payMyReservationLink)
                        && !userPermissions.has(reservationListPermissions.payOtherReservationLink)
                    ),
                    disabled: (reservation) => {
                        const { owner, status } = reservation;
                        const canPay = (
                            owner!.id === userPermissions.userId
                                ? userPermissions.has(reservationListPermissions.payMyReservationLink)
                                : userPermissions.has(reservationListPermissions.payOtherReservationLink)
                        );
                        if (!canPay) return true;

                        if (status === 'cancelled') return true;

                        const paid = reservation.invoice?.status === 'paid';
                        return paid;
                    },
                },
                cancelReservation: {
                    click: (reservation) => {
                        dispatch(actions.openCancelReservation(reservation));
                    },
                    notAllowed: !userPermissions.has(reservationListPermissions.cancel),
                    disabled: (reservation) => {
                        const { status, flights } = reservation;
                        if (status === 'cancelled') return true;

                        if (userPermissions.has(reservationListPermissions.cancelWithDepartedFlight)) {
                            return false;
                        }
                        const flightsStatus = createFlightsStatus(flights.outbound, flights.return);
                        const flightsDeparted = flightsStatus.closed('any') || flightsStatus.departed('any');
                        return flightsDeparted;
                    },
                },
                ticket: {
                    click: (reservation, _, tableRef) => {
                        dispatch(reservationPdfActions.downloadOverviewPdf({
                            reservationId: reservation.id!,
                            callback: () => tableRef.renderColumn('actions'),
                        }));
                        tableRef.renderColumn('actions');
                    },
                    isLoading: (reservation) => (
                        ref.current.isLoadingOverviewMap[reservation.id]
                    ),
                    notAllowed: !userPermissions.has(reservationListPermissions.ticketPdf),
                    disabled: (reservation) => {
                        const { status } = reservation.invoice!;
                        if (paidStatuses.includes(status)) {
                            return !userPermissions.has(reservationListPermissions.fullyPaidTicketPdf);
                        }
                        if (status === 'partly') {
                            return !userPermissions.has(reservationListPermissions.partlyPaidTicketPdf);
                        }
                        return !userPermissions.has(reservationListPermissions.notPaidTicketPdf);
                    },
                },
                invoice: {
                    click: (reservation, _, tableRef) => {
                        dispatch(reservationPdfActions.downloadInvoicePdf({
                            reservationId: reservation.id!,
                            callback: () => tableRef.renderColumn('actions'),
                        }));
                        tableRef.renderColumn('actions');
                    },
                    isLoading: (reservation) => (
                        ref.current.isLoadingInvoiceMap[reservation.id]
                    ),
                    notAllowed: !userPermissions.has(reservationListPermissions.invoicePdf),
                    disabled: (reservation) => {
                        const { status } = reservation.invoice!;
                        if (paidStatuses.includes(status)) {
                            return !userPermissions.has(reservationListPermissions.fullyPaidInvoicePdf);
                        }
                        return !userPermissions.has(reservationListPermissions.notPaidInvoicePdf);
                    },
                },
                splitReservation: {
                    click: (reservation) => {
                        dispatch(splitReservationActions.openPopup({
                            reservationId: reservation.id,
                        }));
                    },
                    notAllowed: !userPermissions.has(reservationListPermissions.splitReservation),
                    disabled: (reservation) => {
                        const { status, flights } = reservation;
                        if (status === 'cancelled') return true;

                        const flightsStatus = createFlightsStatus(flights.outbound, flights.return);
                        const flightsFinished = (
                            flightsStatus.cancelled('last')
                            || flightsStatus.departed('last')
                            || flightsStatus.closed('last')
                        );
                        if (flightsFinished) return true;

                        const canSplitPassengers = reservation.passengers.length > 1;
                        return !canSplitPassengers;
                    },
                },
                historyReservation: {
                    click: (reservation) => {
                        const path = makePath(allAdminRoutePaths.reservationHistory, {
                            reservationId: reservation.id!,
                        });
                        history.push(path);
                    },
                    notAllowed: !userPermissions.has(reservationListPermissions.historyLink),
                },
            },
        });
        return result;
    }, [
        history,
        dispatch,
        userPermissions,
    ]);
    return columns;
};
