import { CountryEntireList } from '@malesia/json-schema';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { AirportAvailabilityMap, AirportListSuccess, SharedDataState } from './types';

export const initialState: SharedDataState = {
    airport: {
        list: {
            items: [],
        },
        availabilityMap: {} as AirportAvailabilityMap,
    },
    publicCountry: {
        list: {
            items: [],
        },
    },
    adminCountry: {
        list: {
            items: [],
        },
    },
};

const SharedDataSlice = createSlice({
    name: 'sharedData',
    initialState,
    reducers: {
        getAirportList(state) {
            state.airport.loading = true;
        },
        getAirportListSuccess(state, action: PayloadAction<AirportListSuccess>) {
            state.airport.list = action.payload.airportList;
            state.airport.availabilityMap = action.payload.airportAvailabilityMap;
            state.airport.loading = false;
        },
        getAirportListError(state) {
            state.airport.loading = false;
        },

        getPublicCountryList(state) {
            state.publicCountry.loading = true;
        },
        getPublicCountryListSuccess(state, action: PayloadAction<CountryEntireList>) {
            state.publicCountry.list = action.payload;
            state.publicCountry.loading = false;
        },
        getPublicCountryListError(state) {
            state.publicCountry.loading = false;
        },

        getAdminCountryList(state) {
            state.adminCountry.loading = true;
        },
        getAdminCountryListSuccess(state, action: PayloadAction<CountryEntireList>) {
            state.adminCountry.list = action.payload;
            state.adminCountry.loading = false;
        },
        getAdminCountryListError(state) {
            state.adminCountry.loading = false;
        },

        unmount() {
            // Do nothing. Global session state
        },
    },
});

export const {
    actions: sharedActions,
    reducer: sharedReducer,
    name: sharedSliceKey,
} = SharedDataSlice;
