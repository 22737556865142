import { Customer } from '@malesia/json-schema';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { CustomerEditPageState, UpdateCustomerOptions } from './types';

export const initialState: CustomerEditPageState = {
    customerData: undefined,
    loading: false,
};

const customerEditPageSlice = createSlice({
    name: 'customerEditPage',
    initialState,
    reducers: {
        getCustomerData(state, action: PayloadAction<string>) {
            state.loading = true;
        },
        getCustomerDataSuccess(state, action: PayloadAction<Customer>) {
            state.customerData = action.payload;
            state.loading = false;
        },
        getCustomerDataError(state) {
            state.loading = false;
        },
        updateCustomerData(state, action: PayloadAction<UpdateCustomerOptions>) {
            state.loading = true;
        },
        updateCustomerDataSuccess(state, action: PayloadAction<Customer>) {
            state.customerData = action.payload;
            state.loading = false;
        },
        updateCustomerDataError(state) {
            state.loading = false;
        },
    },
});

export const { actions, reducer, name: sliceKey } = customerEditPageSlice;
