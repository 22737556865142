import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { Helmet } from 'react-helmet-async';

export const AppMetaHelmet: SFC = () => {
    return (
        <Helmet
            titleTemplate='Malesia Reisen: %s'
            defaultTitle='Malesia Reisen'
        >
            <meta
                name='description'
                content='Malesia Reisen'
            />
        </Helmet>
    );
};
