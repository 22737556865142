import React from 'react';
import { FormattedMessage } from 'react-intl';

type CountValues = {
    count: string | number,
};

// TODO move translation to the `react-components` project

export const passengerAgeType = {
    adult: (values: CountValues) => (
        <FormattedMessage
            id='front-app/BookingFlightPage/StepsSummary/passengerAgeType:adult'
            defaultMessage='{count, plural,
                    =0 {}
                    one {Adult}
                    other {Adults}}'
            values={values}
        />
    ),
    child: (values: CountValues) => (
        <FormattedMessage
            id='front-app/BookingFlightPage/StepsSummary/passengerAgeType:child'
            defaultMessage='{count, plural,
                    =0 {}
                    one {Child}
                    other {Children}}'
            values={values}
        />
    ),
    infant: (values: CountValues) => (
        <FormattedMessage
            id='front-app/BookingFlightPage/StepsSummary/passengerAgeType:infant'
            defaultMessage='{count, plural,
                    =0 {}
                    one {Infant}
                    other {Infants}}'
            values={values}
        />
    ),
};
