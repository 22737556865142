import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => {
    return state.loginForm || initialState;
};

export const selectLoginFormValidationErrors = createSelector(
    [selectDomain],
    loginForm => loginForm.formValidationErrors,
);

export const selectLoginFormUntouchedFields = createSelector(
    [selectDomain],
    loginForm => loginForm.formUntouchedFields,
);

export const selectLoginFormIsValid = createSelector(
    [selectDomain],
    loginForm => loginForm.formIsValid,
);
