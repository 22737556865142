import { ManagerList } from '@malesia/react-components/dist/src/components/Manager/List';
import { SortingDataType } from '@malesia/react-components/dist/src/components/Table';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectIsDirty,
    selectIsLoading,
    selectList,
    selectFilterData,
} from '../selectors';
import { actions } from '../slice';
import { useTableColumns } from '../tableColumns';

export const ManagerListWithStore: SFC = () => {
    const managerList = useSelector(selectList);
    const filterData = useSelector(selectFilterData);
    const isLoading = useSelector(selectIsLoading);
    const isDirty = useSelector(selectIsDirty);
    const dispatch = useDispatch();

    const handleSetSort = (data: SortingDataType) => {
        dispatch(actions.setSorting(data));
    };

    const managerListColumns = useTableColumns();

    return (
        <ManagerList
            isLoading={isLoading}
            isDirty={isDirty}
            onSubmit={(data) => {
                dispatch(actions.updateFilterData(data));
            }}
            searchText={filterData.searchText}
            isActiveOnly={filterData.isActiveOnly}
            tableColumns={managerListColumns}
            tableData={managerList.items}
            sortBy={filterData.sortBy}
            sortOrder={filterData.sortOrder}
            setSort={handleSetSort}
        />
    );
};
