import { PublicFlightListItem } from '@malesia/json-schema';
import { getOneWayFlightMinPrice } from '@malesia/react-components/dist/src/components/FlightsOverview/FlightOverviewFlightCard/utils';
import moment, { Moment } from 'moment';
import { FlightOverviewList } from './types';

export const getCheapestFlights = (flightList: PublicFlightListItem[], itemCount: number): PublicFlightListItem[] => {
    return flightList
        .sort((a, b) => moment(a.departure).valueOf() - moment(b.departure).valueOf())
        .sort((a, b) => getOneWayFlightMinPrice(a) - getOneWayFlightMinPrice(b))
        .slice(0, itemCount);
};

export const getTomorrowFlights = (flightList: PublicFlightListItem[], today: Moment): PublicFlightListItem[] => {
    const tomorrow = today.clone().add(1, 'days');
    const filtered = flightList.filter(flight => moment.parseZone(flight.departure).isSame(tomorrow, 'day'));
    return filtered;
};

const getAfterTomorrowFlights = (flightList: PublicFlightListItem[], today: Moment): PublicFlightListItem[] => {
    const afterTomorrow = today.clone().add(2, 'days');
    const filtered = flightList.filter(flight => moment.parseZone(flight.departure).isSame(afterTomorrow, 'day'));
    return filtered;
};

export const flightListToFlightColumns = (
    flightList: PublicFlightListItem[],
    today: Moment,
    maxRow: number = 100,
): FlightOverviewList[] => {
    const tomorrow = today.clone().add(1, 'day');
    const afterTomorrow = today.clone().add(2, 'days');

    const tomorrowColumn: FlightOverviewList = {
        title: 'Tomorrow',
        date: tomorrow.toDate(),
        items: getTomorrowFlights(flightList, today).slice(0, maxRow),
    };
    const in2DaysColumn: FlightOverviewList = {
        title: 'in 2 days',
        date: afterTomorrow.toDate(),
        items: getAfterTomorrowFlights(flightList, today).slice(0, maxRow),
    };

    const cheapestColumn: FlightOverviewList = {
        title: 'CHEAPEST',
        withDate: true,
        items: getCheapestFlights(flightList, maxRow),
    };

    return [cheapestColumn, tomorrowColumn, in2DaysColumn];
};

export const flightsWithPriceItemListHasAvailableSeats = (
    flightList: PublicFlightListItem[],
): PublicFlightListItem[] => {
    return flightList.map(flight => {
        const { priceList } = flight.rate;
        const filteredPriceList = priceList!.filter(priceItem => priceItem.hasAvailableSeats);
        return {
            ...flight,
            rate: {
                ...flight.rate,
                priceList: filteredPriceList,
            },
        };
    }).filter(x => x.rate.priceList.length);
};
