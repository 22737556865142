export const allAdminPaginatedRoutePaths = {
    balance: {
        invoice: '/invoice',
        balanceLog: '/invoice/balance-log',
        newTransaction: '/invoice/new-transaction',
    },
    allotment: {
        allotment: '/allotment',
        allotmentList: '/allotment/list',
        allotmentInvoiceList: '/allotment/invoiceList',
        allotmentPayment: '/allotment/:allotmentInvoiceId/payment',
    },
    flight: {
        flight: '/flight',
        flightCompanyEdit: '/flight/company/edit/:flightCompanyId',
        flightCompanyList: '/flight/company/list',
        flightCompanyNew: '/flight/company/new',
        flightEdit: '/flight/edit/:flightId',
        flightList: '/flight/list',
        flightNew: '/flight/new',
        flightPassengerList: '/flight/:flightId/passenger/list',
    },
    reservation: {
        reservation: '/reservation',
        reservationNew: '/reservation/new',
        reservationEdit: '/reservation/edit/:reservationId',
        reservationHistory: '/reservation/history/:reservationId',
        reservationList: '/reservation/list',
        reservationPayment: '/reservation/:reservationId/payment',
        reservationInvoiceList: '/reservation/invoiceOverview',
    },
    user: {
        user: '/user',
        userAgentEdit: '/user/agent/edit/:agentId',
        userAgentList: '/user/agent/list',
        userAgentNew: '/user/agent/new',
        userCustomerEdit: '/user/customer/edit/:customerId',
        userCustomerList: '/user/customer/list',
        userCustomerNew: '/user/customer/new',
        userManagerEdit: '/user/manager/edit/:managerId',
        userManagerList: '/user/manager/list',
        userManagerNew: '/user/manager/new',
    },
    rate: {
        rate: '/rate',
        rateEdit: '/rate/edit/:rateId',
        rateList: '/rate/list',
        rateNew: '/rate/new',
        rateOptionEdit: '/rate/option/edit/:optionId',
        rateOptionList: '/rate/option/list',
        rateOptionNew: '/rate/option/new',
    },
    settings: {
        settings: '/settings',
        settingsAccount: '/settings/account',
        settingsPermissions: '/settings/permissions',
        settingsUserPermissions: '/settings/user/:userId/permissions',
        settingsEventLog: '/settings/entity-log/list',
    },
    specific: {
        agentPayment: '/agent/payment',
        updatePassword: '/updatePassword/:recoveryToken',
    },
} as const;
type ValueOf<T> = T[keyof T];
type PaginatedPaths = typeof allAdminPaginatedRoutePaths;
export type AdminRoutePage = keyof PaginatedPaths;
export type PaginatedPath<Page extends AdminRoutePage> = ValueOf<PaginatedPaths[Page]> & string;

export const allAdminRoutePaths = {
    ...allAdminPaginatedRoutePaths.balance,
    ...allAdminPaginatedRoutePaths.flight,
    ...allAdminPaginatedRoutePaths.reservation,
    ...allAdminPaginatedRoutePaths.user,
    ...allAdminPaginatedRoutePaths.allotment,
    ...allAdminPaginatedRoutePaths.rate,
    ...allAdminPaginatedRoutePaths.settings,
    ...allAdminPaginatedRoutePaths.specific,
} as const;
export type AdminRoutePath = ValueOf<typeof allAdminRoutePaths>;
