import { Country } from '@malesia/json-schema';
import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectPublicCountryList } from '../../../../containers/SharedData/selectors';
import { sharedActions } from '../../../../containers/SharedData/slice';

export const useCountrySelect = () => {
    const countryListData = useSelector(selectPublicCountryList);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(sharedActions.getPublicCountryList());
    }, [dispatch]);

    const { locale } = useIntl();

    const countryList: {name: string, value: string}[] = useMemo(() => {
        return countryListData?.items.map(item => ({
            name: item.name?.[locale ] ?? '',
            value: item.code,
        })) ?? [];
    }, [countryListData, locale]);

    const getCountryByCode = useCallback((code: string | undefined): Country | undefined => {
        if (!code) return;
        return countryListData?.items.find(item => item.code === code);
    }, [countryListData]);

    return { countryList, getCountryByCode };
};
