import { ContentPage } from '@malesia/react-components/dist/src/components/ContentPage/ContentPage';
import { HtmlBlock } from '@malesia/react-components/dist/src/components/Front/HtmlBlock/HtmlBlock';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { useIntl } from 'react-intl';
import useSWR from 'swr';
import { loadTermsPageContent } from '../../services/directus/termsPage/loadTermsPageContent';

export const MalesiaTermsConditionsPage: SFC = () => {
    const { locale } = useIntl();
    const { data } = useSWR([locale, 'loadTermsPageContent'], loadTermsPageContent);

    return (
        <>
            <ContentPage
                image={data?.topImage}
            >
                <HtmlBlock content={data?.content ?? ''} />
            </ContentPage>
        </>
    );
};
