import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createLocalNotification } from '../../../containers/App/utils';

const notification = createLocalNotification('Booking', {
    error: (
        <FormattedMessage
            id='front-app/BookingFlightPage/Notification/Error:title'
            defaultMessage='Booking Error'
        />
    ),
    warning: (
        <FormattedMessage
            id='front-app/BookingFlightPage/Notification/Warning:title'
            defaultMessage='Booking Warning'
        />
    ),
    info: (
        <FormattedMessage
            id='front-app/BookingFlightPage/Notification/Info:title'
            defaultMessage='Booking Info'
        />
    ),
});

export const bookingNotifications = {
    noAvailableFlights: notification.warning(
        <FormattedMessage
            id='front-app/notificationMessages/Booking:noAvailableFlights'
            defaultMessage='There are no available flights for search.'
        />,
    ),
    requiredQuery: (fieldName: string) => notification.error(
        <FormattedMessage
            id='front-app/notificationMessages/Booking:requiredQuery'
            defaultMessage='The "{fieldName}" query parameter is required'
            values={{ fieldName }}
        />,
    ),
    extraQuery: (fieldName: string) => notification.error(
        <FormattedMessage
            id='front-app/notificationMessages/Booking:extraQuery'
            defaultMessage='The "{fieldName}" query parameter is not allowed'
            values={{ fieldName }}
        />,
    ),
    invalidQuery: (fieldName: string, validFormat: string) => notification.error(
        <FormattedMessage
            id='front-app/notificationMessages/Booking:invalidQuery'
            defaultMessage='Invalid query parameter "{fieldName}". Use value like: {validFormat}'
            values={{ fieldName, validFormat }}
        />,
    ),
    notFoundQuery: (fieldName: string, value: string) => notification.error(
        <FormattedMessage
            id='front-app/notificationMessages/Booking:notFoundAirport'
            defaultMessage='Element with query "{fieldName}={value}" not found. Choose a different value.'
            values={{ fieldName, value }}
        />,
    ),
    customError: (text: JSX.Element) => notification.error(text),
};
