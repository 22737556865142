import { PassengerWithSeat } from '@malesia/react-components/dist/src/components/Reservation/AircraftSeatsSelection/Passenger';
import { SeatInputPopup, SeatInputPopupProps } from '@malesia/react-components/dist/src/components/Reservation/AircraftSeatsSelection/SeatInputPopup';
import { SeatSelectionPopup, SeatSelectionPopupProps } from '@malesia/react-components/dist/src/components/Reservation/AircraftSeatsSelection/SeatSelectionPopup';
import { SeatsSelectionPopup, SeatsSelectionPopupProps } from '@malesia/react-components/dist/src/components/Reservation/AircraftSeatsSelection/SeatsSelectionPopup';
import { OpenSeatSelectionPopupOptions } from '@malesia/react-components/dist/src/components/Reservation/PassengersSeatsOnFlights';
import { FlightDirection } from '@malesia/react-components/dist/src/components/Reservation/reservation-types';
import { checkMobilePlatform } from '@malesia/react-components/dist/src/utils/mobilePlatform';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { formatSchemaValidationErrors } from '../../../app/containers/ValidationErrorMessage';
import { Selector } from '../../../types/Selector';
import { coercionDataToSchema } from '../../validation/coercion';
import { GetSeatMapByDirection } from '../seatMap';
import { PassengerBasket } from '../types';

export type SelectSeatOptions = {
    passengerIdx: number,
    flightDirection: FlightDirection,
    seatLabel: string,
};
export type ResetSeatOptions = {
    passengerIdx: number,
    flightDirection: FlightDirection,
};
export type ResetDirectionSeatsOptions = {
    flightDirection: FlightDirection,
};

type SeatMapPopupComponentArgs = {
    selectPassengers: Selector<PassengerBasket[]>,
    selectSeatMapForDirection: Selector<GetSeatMapByDirection>,
    resetSeat: ActionCreatorWithPayload<ResetSeatOptions, string>,
    resetDirectionSeats: ActionCreatorWithPayload<ResetDirectionSeatsOptions, string>,
    selectSeat: ActionCreatorWithPayload<SelectSeatOptions, string>,
};

const defaultOptions: OpenSeatSelectionPopupOptions = {
    flightDirection: 'outbound',
    tariffGroupCode: 'economy',
    selectedPassengerId: 0,
};
export const useSeatsSelectionPopup = (args: SeatMapPopupComponentArgs) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [options, setOptions] = useState<OpenSeatSelectionPopupOptions>(defaultOptions);
    const { flightDirection, tariffGroupCode, selectedPassengerId } = options;
    const openSeatsSelectionPopup = (newOptions: OpenSeatSelectionPopupOptions) => {
        setOptions(newOptions);
        setIsOpen(true);
    };
    const closeSeatSelectionPopup = () => {
        setIsOpen(false);
        setErrors({});
        setOptions(defaultOptions);
    };

    const passengers = useSelector(args.selectPassengers);

    const seatReservationAircraftTemplateData = useSelector(
        args.selectSeatMapForDirection,
    )(flightDirection);

    const dispatch = useDispatch();

    const resetSeat = (passengerIdx: number, direction?: FlightDirection) => {
        dispatch(args.resetSeat({
            passengerIdx,
            flightDirection: direction ?? flightDirection,
        }));
    };

    const resetSeats = () => {
        dispatch(args.resetDirectionSeats({
            flightDirection,
        }));
    };

    const setSeat = (seatLabel, passengerIdx) => {
        dispatch(args.selectSeat({
            passengerIdx,
            flightDirection,
            seatLabel,
        }));
    };

    const [errors, setErrors] = useState({});
    const selectSeat = (seatLabel: string | undefined, passengerIdx: number) => {
        setErrors({});

        if (!seatLabel) {
            resetSeat(passengerIdx);
            closeSeatSelectionPopup();

            return;
        }

        const [validation, , errors] = coercionDataToSchema(
            'file://malesiareisen.com/json/schema/project/react-front-app/pages/reservation/add/seatLabel.json',
            { seatLabel },
        );
        const allErrors = errors ?? [];

        if (validation && allErrors.length === 0) {
            setSeat(seatLabel, passengerIdx);
            closeSeatSelectionPopup();
        }
        else {
            setErrors(formatSchemaValidationErrors(allErrors));
        }
    };

    const passengersWithSeats = passengers.reduce<PassengerWithSeat[]>((acc, passenger, idx) => {
        if (passenger.info.ageType === 'infant') return acc;
        const seatLabel = passenger.seats[flightDirection];

        const passengerText = (
            <FormattedMessage
                id='front-app/Reservation:passengerNumber'
                defaultMessage='Passenger {number}'
                values={{
                    number: idx + 1,
                }}
            />
        );
        const { firstName, lastName } = passenger.info;
        const passengerName = [firstName, lastName].filter(Boolean).join(' ');

        const result: PassengerWithSeat = {
            id: idx,
            seatLabel,
            passengerName: passengerName || passengerText,
        };
        return [
            ...acc,
            result,
        ];
    }, []);

    const renderSeatsSelectionPopup = () => {
        if (!isOpen) return null;
        if (!seatReservationAircraftTemplateData) {
            const seatInputPopupProps: SeatInputPopupProps = {
                seatLabel: passengersWithSeats.find(x => x.id === selectedPassengerId)?.seatLabel,
                errors,
                selectSeat: (seatLabel) => selectSeat(seatLabel, selectedPassengerId),
                close: closeSeatSelectionPopup,
            };
            return (
                <SeatInputPopup {...seatInputPopupProps} />
            );
        }
        const seatSelectionPopupProps: SeatsSelectionPopupProps = {
            passengers: passengersWithSeats,
            selectedPassengerId,
            tariffGroupCode,
            resetSeat,
            resetSeats,
            selectSeat: setSeat,
            confirm: closeSeatSelectionPopup,
            sections: seatReservationAircraftTemplateData.sections,
            close: closeSeatSelectionPopup,
        };
        const seatSelectionPopupMobileProps: SeatSelectionPopupProps = {
            ...seatSelectionPopupProps,
            selectSeat: (seatLabel) => setSeat(seatLabel, selectedPassengerId),
            resetSeat: () => resetSeat(selectedPassengerId),
        };
        const isMobile = checkMobilePlatform();
        return (
            isMobile
                ? <SeatSelectionPopup {...seatSelectionPopupMobileProps} />
                : <SeatsSelectionPopup {...seatSelectionPopupProps} />
        );
    };

    return {
        renderSeatsSelectionPopup,
        openSeatsSelectionPopup,
        resetSeat,
    };
};
