import { PassengersSeatsOnFlights, PassengersSeatsOnFlightsProps } from '@malesia/react-components/dist/src/components/Reservation/PassengersSeatsOnFlights';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import { localizeMoney } from '@malesia/react-components/dist/src/utils/roundMoney';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useSeatsByPassenger } from '../../../../../utils/reservation/seats/useSeatsByPassenger';
import { useSeatsSelectionPopup } from '../../../../../utils/reservation/seats/useSeatsSelectionPopup';
import { useReservationAllowed } from '../../permissions';
import {
    selectPassengers,
    selectSeatMapForDirection,
    selectSeatPriceDictionary,
    selectSelectedFlights,
    selectSelectedFlightVariants,
    selectSummaryTotalSeats,
} from '../../store/selectors';
import { reservationNewPageActions } from '../../store/slice';

export const SeatReservation: SFC = () => {
    const dispatch = useDispatch();

    const { locale } = useIntl();
    const totalSeatsCost = useSelector(selectSummaryTotalSeats);
    const selectedFlights = useSelector(selectSelectedFlights);
    const allowed = useReservationAllowed();

    useEffect(() => {
        dispatch(reservationNewPageActions.getSeatMaps());
    }, [dispatch, selectedFlights]);

    const { seatsByPassenger } = useSeatsByPassenger({
        selectSelectedFlightVariants,
        selectPassengers,
        selectSeatPriceDictionary,
        canSeatInput: allowed.seat.input,
        outboundDisabled: !allowed.seat.outbound,
        returnDisabled: !allowed.seat.return,
    });

    const { renderSeatsSelectionPopup, openSeatsSelectionPopup, resetSeat } = useSeatsSelectionPopup({
        selectPassengers,
        selectSeatMapForDirection,
        resetSeat: reservationNewPageActions.resetSeat,
        resetDirectionSeats: reservationNewPageActions.resetDirectionSeats,
        selectSeat: reservationNewPageActions.selectPassengerSeat,
    });

    const passengersSeatsOnFlightsProps: PassengersSeatsOnFlightsProps = {
        resetSeat,
        seatsByPassenger,
        totalPrice: localizeMoney(totalSeatsCost, locale, 'ReservationNewPage/SeatReservation'),
        openSeatsSelectionPopup,
        modificators: ['middle'],
        submitHidden: true,
    };
    return (
        <>
            <PassengersSeatsOnFlights {...passengersSeatsOnFlightsProps} />
            {renderSeatsSelectionPopup()}
        </>
    );
};
