import { AirportCode } from '@malesia/react-components/dist/src/types';
import { getObjectEntries, getObjectKeys } from '@malesia/react-components/dist/src/utils/object';
import { createSelector } from '@reduxjs/toolkit';
import { AirportWithLocaleName, RootState } from '../../../types';
import { BasketAirports } from '../ReservationBasket/reservationBasket.types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => {
    return state.sharedData || initialState;
};

const selectLocale = (state: RootState) => {
    return state.global.locale || 'en';
};

export const selectPublicCountryList = createSelector(
    [selectDomain],
    sharedData => sharedData.publicCountry.list,
);

export const selectAdminCountryList = createSelector(
    [selectDomain],
    sharedData => sharedData.adminCountry.list,
);

export const selectAdminCountryLoading = createSelector(
    [selectDomain],
    sharedData => sharedData.adminCountry.loading,
);

export const selectAirportList = createSelector(
    [selectDomain],
    sharedData => sharedData.airport.list.items,
);

export const selectAvailabilityAirportsMap = createSelector(
    [selectDomain],
    sharedData => sharedData.airport.availabilityMap,
);

export const selectDefaultMainAirport = createSelector(
    [selectAirportList],
    (airportList) => {
        const initAirports: BasketAirports = {
            from: airportList.find(airport => airport.code === 'ZRH')!,
            to: airportList.find(airport => airport.code === 'PRN'),
        };
        return initAirports;
    },
);

export const selectAirportHashMap = createSelector(
    [selectAirportList, selectLocale],
    (airportList, locale) => {
        return airportList.reduce<Record<AirportCode, AirportWithLocaleName>>((acc, airport) => {
            return {
                ...acc,
                [airport.code]: {
                    ...airport,
                    name: airport.name?.[locale]!,
                },
            };
        }, {} as Record<AirportCode, AirportWithLocaleName>);
    },
);

export const selectMapAvailabilityToAirports = createSelector(
    [selectAirportHashMap, selectAvailabilityAirportsMap],
    (airportMap, availabilityMap): Record<AirportCode, AirportWithLocaleName[]> => {
        const entries = getObjectEntries(availabilityMap)
            .map(([key, codeList]) => [
                key,
                codeList.map(code => airportMap[code]),
            ]);
        return Object.fromEntries(entries);
    },
);

export const selectAvailableAirports = createSelector(
    [selectAirportHashMap, selectAvailabilityAirportsMap],
    (airportMap, availabilityMap): AirportWithLocaleName[] => {
        return getObjectKeys(availabilityMap).map(code => airportMap[code]);
    },
);
