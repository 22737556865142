import { ErrorObject } from 'ajv';
import { CustomValidator } from './getValidationFunction';
import { getValueFromPath } from './getValueFromPath';

function validateDataByPath<T>(data: Partial<T>, path: string) {
    let isValid = false;
    const value = getValueFromPath(data, path);

    if (value !== undefined && value !== null) {
        isValid = true;
    }

    if (!isValid) {
        const error: ErrorObject = {
            keyword: 'custom/required',
            params: {},
            schema: 'custom/required',
            schemaPath: 'custom/required',
            data,
            instancePath: path,
            message: 'should be defined',
        };
        return [error];
    }

    return [];
}

export function createCustomRequiredValidator<T>(instancePath: string | string[]) {
    const customValidator: CustomValidator<Partial<T>> = (data) => {
        return Array.isArray(instancePath)
            ? instancePath.map(path => validateDataByPath(data, path)).flat()
            : validateDataByPath(data, instancePath).flat();
    };

    return customValidator;
}
