import { LoadingPage } from '@malesia/react-components/dist/src/components/LoadingOverlay/Page';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toNumber } from '../../../utils/uriUtils';
import { ReservationForm } from '../ReservationNewPage/ReservationForm';
import { useInjectReservationPageStore } from '../ReservationNewPage/store/inject';
import { selectIsReservationLoading } from '../ReservationNewPage/store/selectors';
import { reservationNewPageActions } from '../ReservationNewPage/store/slice';

export const ReservationEditPage = function ReservationEditPage() {
    useInjectReservationPageStore();
    const isReservationLoading = useSelector(selectIsReservationLoading);
    const dispatch = useDispatch();

    const routeParams = useParams<{ reservationId: string }>();
    const reservationId = toNumber(routeParams.reservationId)!;
    useEffect(() => {
        dispatch(reservationNewPageActions.loadEditReservation(reservationId));
    }, [dispatch, reservationId]);

    return (
        isReservationLoading ? <LoadingPage isLoading /> : <ReservationForm />
    );
};
