import {
    ApiUserGroupsGetResponse, ApiUserRolesGetResponse,
    UserPutRequest as ApiUserPutRequest,
    User,
    UserProfilePaginatedList,
    ApiCallUserListFilters,
} from '@malesia/json-schema';
import { call } from 'typed-redux-saga';
import { apiClientRequest } from './ApiClient';
import { RoleCode } from 'app/permissions/roleCode';

export function* getApiUserGroupList() {
    const requestData = {
        requestId: 'userGroupList',
    };
    const response: ApiUserGroupsGetResponse = yield* call(apiClientRequest, requestData);
    return response;
}

export function* getApiUserRoleList() {
    const requestData = {
        requestId: 'userRoleList',
    };
    const response: ApiUserRolesGetResponse = yield* call(apiClientRequest, requestData);
    const result = response as RoleCode[];
    return result;
}

export function* getApiUserRoles(userId: number) {
    const requestData = {
        requestId: 'userRoles',
        uriParams: {
            id: userId,
        },
    };
    const response: RoleCode[] = yield* call(apiClientRequest, requestData);
    return response;
}

export function* getApiUser(userId: number) {
    const requestData = {
        requestId: 'getUser',
        uriParams: {
            id: userId,
        },
    };
    const response: User = yield* call(apiClientRequest, requestData);
    return response;
}

export type VirtualAccount = {
    id: number,
    isActive?: boolean,
    type: 'general' | 'system' | 'bank' | 'cash',
    code?: null | string,
    name: string,
};

export function* getApiUserVirtualAccounts(userId: number) {
    const requestData = {
        requestId: 'userVirtualAccounts',
        uriParams: {
            id: userId,
        },
    };
    const response: VirtualAccount[] = yield* call(apiClientRequest, requestData);
    return response;
}

export function* updateApiUser(user: ApiUserPutRequest) {
    const requestPayload = { ...user };
    const requestData = {
        requestId: 'updateUser',
        uriParams: {
            id: user.id!,
        },
        requestPayload,
    };
    const response: User = yield* call(apiClientRequest, requestData);
    return response;
}

export function* assignApiVirtualAccounts(userId: number, virtualAccountsIds: number[]) {
    const requestData = {
        requestId: 'userAssignVirtualAccounts',
        uriParams: {
            id: userId,
        },
        requestPayload: virtualAccountsIds,
    };
    const response: VirtualAccount[] = yield* call(apiClientRequest, requestData);
    return response;
}

export function* getApiUserList(filters: ApiCallUserListFilters) {
    const response: UserProfilePaginatedList = yield* call(apiClientRequest, {
        requestId: 'userList',
        query: filters,
    });
    return response;
}
