import { PayloadAction } from '@reduxjs/toolkit';
import { ApiErrorType } from '../../../../../api/malesia';
import { API_ERROR_CODE } from '../../../../../api/malesia/ErrorCode';
import { createSlice } from '../../../../../utils/@reduxjs/toolkit';
import { StepReservationOverviewState } from './types';

export const initialState: StepReservationOverviewState = {
    formData: undefined,
    createdReservationData: undefined,
    errorNotEnoughSeatsErrorCode: false,
    errorSeatsOccupiedErrorCode: false,
    error: false,
    loading: false,
};

const stepReservationOverviewSlice = createSlice({
    name: 'stepReservationOverview',
    initialState,
    reducers: {
        reset() {
            return initialState;
        },
        createReservationAndStartTransaction(state) {
            state.loading = true;
        },
        createReservationAndStartTransactionSuccess(state,
            action: PayloadAction<{
                createdReservationData: StepReservationOverviewState['createdReservationData'],
            }>,
        ) {
            state.loading = false;
            state.createdReservationData = action.payload.createdReservationData;
        },
        createReservationAndStartTransactionError(state, action: PayloadAction<ApiErrorType>) {
            const error = action.payload;
            const { code } = error;
            state.loading = false;
            state.error = error;
            state.errorNotEnoughSeatsErrorCode = code === API_ERROR_CODE.NOT_ENOUGH_SEATS_ERROR_CODE;
            state.errorSeatsOccupiedErrorCode = code === API_ERROR_CODE.SEATS_OCCUPIED_ERROR_CODE;
        },
        updateFormData(state, action: PayloadAction<any>) {
            state.formData = action.payload;
        },
        resetFormData(state) {
            state.formData = { isAgree: false };
        },
        resetStepError(
            state,
            // action,
        ) {
            state.error = false;
            state.errorNotEnoughSeatsErrorCode = false;
            state.errorSeatsOccupiedErrorCode = false;
        },
        resetErrorSeatsOccupiedErrorCode(state) {
            state.errorSeatsOccupiedErrorCode = false;
        },
        postPublicReservationSuccess(
            state,
            action: PayloadAction<any>,
        ) {
            state.createdReservationData = action.payload;
            state.loading = false;
        },
        postPublicReservationError(state, action: PayloadAction<any>) {
            state.error = action.payload;
            state.loading = false;
        },
        resetLoading(state) {
            state.loading = false;
        },
    },
});

export const {
    actions: stepReservationOverviewActions,
    reducer: stepReservationOverviewReducer,
    name: stepReservationOverviewSliceKey,
} = stepReservationOverviewSlice;
