import { OptionPaginatedList, UserProfile, UserProfilePaginatedList, AgentPaginatedList } from '@malesia/json-schema';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { AuxiliaryDataState, GetOptionsListOptions } from './types';

export const initialState: AuxiliaryDataState = {
    option: {
        list: {
            total: 0,
            items: [],
        },
    },
    reservationOwner: {
        list: {
            total: 0,
            items: [],
        },
    },
    reservationReservator: {
        list: {
            total: 0,
            items: [],
        },
    },
    agent: {
        query: '',
        list: {
            total: 0,
            items: [],
        },
    },
};

const AuxiliaryDataSlice = createSlice({
    name: 'auxiliaryData',
    initialState,
    reducers: {
        getOptionsList(state, action: PayloadAction<GetOptionsListOptions>) {
            state.option.loading = true;
        },
        getOptionsListSuccess(state, action: PayloadAction<OptionPaginatedList>) {
            state.option.list = action.payload;
            state.option.loading = false;
        },
        getOptionsListError(state) {
            state.option.loading = false;
        },

        searchReservationOwnerList(state, action: PayloadAction<string>) {
            state.reservationOwner.loading = true;
            state.reservationOwner.query = action.payload;
        },
        getReservationOwnerList(state, action: PayloadAction<string>) {
            state.reservationOwner.loading = true;
        },
        getReservationOwnerListSuccess(state, action: PayloadAction<UserProfilePaginatedList>) {
            state.reservationOwner.list = action.payload;
            state.reservationOwner.loading = false;
        },
        getReservationOwnerListError(state) {
            state.reservationOwner.loading = false;
        },

        selectReservationOwner(state, action: PayloadAction<number | undefined>) {
        },
        selectReservationOwnerSuccess(state, action: PayloadAction<UserProfile | undefined>) {
            state.reservationOwner.selected = action.payload;
        },
        selectReservationOwnerError(state) {
        },

        searchReservationReservatorList(state, action: PayloadAction<string>) {
            state.reservationReservator.loading = true;
            state.reservationReservator.query = action.payload;
        },
        getReservationReservatorList(state, action: PayloadAction<string>) {
            state.reservationReservator.loading = true;
        },
        getReservationReservatorListSuccess(state, action: PayloadAction<UserProfilePaginatedList>) {
            state.reservationReservator.list = action.payload;
            state.reservationReservator.loading = false;
        },
        getReservationReservatorListError(state) {
            state.reservationReservator.loading = false;
        },

        selectReservationReservator(state, action: PayloadAction<number | undefined>) {
        },
        selectReservationReservatorSuccess(state, action: PayloadAction<UserProfile | undefined>) {
            state.reservationReservator.selected = action.payload;
        },
        selectReservationReservatorError(state) {
        },

        searchAgentList(state, action: PayloadAction<string>) {
            state.agent.loading = true;
            state.agent.query = action.payload;
        },
        getAgentList(state, action: PayloadAction<string>) {
            state.agent.loading = true;
        },
        getAgentListSuccess(state, action: PayloadAction<AgentPaginatedList>) {
            state.agent.list = action.payload;
            state.agent.loading = false;
        },
        getAgentListError(state) {
            state.agent.loading = false;
        },
    },
});

export const {
    actions: auxiliaryActions,
    reducer: auxiliaryReducer,
    name: auxiliarySliceKey,
} = AuxiliaryDataSlice;
