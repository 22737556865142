import {
    useInjectReducer,
    useInjectSaga,
} from '../../../../utils/redux-injectors';
import { datatransErrorPageReducer, datatransErrorPageSliceName } from './datatransError.slice';
import { loadInitialDataSaga } from './loadInitialData.saga';
import { onLoadSuccessPageSaga } from './onLoadSuccessPage.saga';
import { openPaymentPopupSaga } from './openPaymentPopup.saga';

export function useDatatransStore() {
    useInjectReducer({ key: datatransErrorPageSliceName, reducer: datatransErrorPageReducer });
    useInjectSaga({ key: 'loadInitialData', saga: loadInitialDataSaga });
    useInjectSaga({ key: 'openPaymentPopupSaga', saga: openPaymentPopupSaga });
    useInjectSaga({ key: 'onLoadSuccessPageSaga', saga: onLoadSuccessPageSaga });
}
