import { useInjectReducer, useInjectSaga } from '../../../../utils/redux-injectors';
import { useInjectAuxiliaryData } from '../../../containers/AuxiliaryData/inject';
import { useInjectReservationPdf } from '../../../containers/ReservationPdf/inject';
import { useInjectSplitReservation } from '../../../containers/SplitReservation/inject';
import { confirmCancelReservationSaga } from './confirmCancelReservation.saga';
import { confirmSmsNotificationSaga } from './confirmSmsNotification.saga';
import { getReservationListSaga } from './saga';
import { actions, reducer, sliceKey } from './slice';

export const useInjectReservationList = () => {
    useInjectAuxiliaryData();
    useInjectReservationPdf();
    useInjectSplitReservation();
    useInjectReducer({ actions, key: sliceKey, reducer });
    useInjectSaga({
        key: `${sliceKey}getReservationListSaga`,
        saga: getReservationListSaga,
    });
    useInjectSaga({
        key: `${sliceKey}confirmCancelReservationSaga`,
        saga: confirmCancelReservationSaga,
    });
    useInjectSaga({
        key: `${sliceKey}confirmSendNotificationForSelectedSaga`,
        saga: confirmSmsNotificationSaga,
    });
};
