import { AccountListItem, BalanceLogRecordTypeDef } from '@malesia/json-schema';
import { AccountingBalanceLogFilterData } from '@malesia/react-components/dist/src/components/Accounting/BalanceLog/Filter';
import { SortingDataType } from '@malesia/react-components/dist/src/components/Table';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../../utils/@reduxjs/toolkit';
import { BalanceLogState, GetBalanceLogListSuccessArgs, InitAccountArgs } from './types';

export const initialState: BalanceLogState = {
    filterData: {},
    table: {
        list: {
            total: 0,
            items: [],
        },
        pagination: {
            page: 0,
            pageSize: 10,
        },
        sorting: {
            sortBy: 'createdAt',
            sortOrder: 'DESC',
        },

        initialized: {},
        loading: true,
        dirty: true,
    },
    account: {
        list: [],
    },
    relatedAccount: {
        list: [],
    },
    category: {
        list: [],
    },
    overviewPdf: {},
};

const balanceLogSlice = createSlice({
    name: 'balanceLogState',
    initialState,
    reducers: {
        initAccount(state, action: PayloadAction<InitAccountArgs>) {
        },
        initAccountSuccess(state, action: PayloadAction<AccountListItem>) {
            state.filterData.account = action.payload;
            state.table.initialized.account = true;
        },
        getBalanceLogList(state) {
            state.table.loading = true;
        },
        getBalanceLogListSuccess(state, action: PayloadAction<GetBalanceLogListSuccessArgs>) {
            state.table.list = action.payload.list;
            state.table.summary = action.payload.summary;
            state.table.loading = false;
            state.table.dirty = false;
        },
        getBalanceLogListFail(state) {
            state.table.loading = false;
        },
        setPage(state, action: PayloadAction<number>) {
            state.table.pagination.page = action.payload;
            state.table.dirty = true;
        },
        setPageSize(state, action: PayloadAction<number>) {
            state.table.pagination.pageSize = action.payload;
            state.table.pagination.page = initialState.table.pagination.page;
            state.table.dirty = true;
        },
        setSorting(state, action: PayloadAction<SortingDataType>) {
            state.table.sorting = action.payload;
            state.table.initialized.sorting = true;
            state.table.dirty = true;
        },
        setFilterData(state, action: PayloadAction<AccountingBalanceLogFilterData>) {
            state.filterData = action.payload;
            state.table.pagination.page = initialState.table.pagination.page;
            state.table.dirty = true;
        },
        resetFilterData(state) {
            state.filterData = initialState.filterData;
            state.table.pagination.page = initialState.table.pagination.page;
            state.table.dirty = true;
        },
        getAccountList(state, action: PayloadAction<string>) {
            state.account.query = action.payload;
        },
        getAccountListSuccess(state, action: PayloadAction<AccountListItem[]>) {
            state.account.list = action.payload;
        },
        getRelatedAccountList(state, action: PayloadAction<string>) {
            state.relatedAccount.query = action.payload;
        },
        getRelatedAccountListSuccess(state, action: PayloadAction<AccountListItem[]>) {
            state.relatedAccount.list = action.payload;
        },
        getCategoryList() {},
        getCategoryListSuccess(state, action: PayloadAction<BalanceLogRecordTypeDef[]>) {
            state.category.list = action.payload;
        },
        downloadOverviewPdf(state) {
            state.overviewPdf.loading = true;
        },
        downloadOverviewPdfSuccess(state) {
            state.overviewPdf.loading = false;
        },
        downloadOverviewPdfFail(state) {
            state.overviewPdf.loading = false;
        },
    },
});

export const {
    actions: balanceLogActions,
    reducer: balanceLogReducer,
    name: balanceLogSliceKey,
} = balanceLogSlice;
