import { useInjectReducer, useInjectSaga } from '../../../../utils/redux-injectors';
import { useInjectAllotmentInvoicePdf } from '../../../containers/AllotmentInvoicePdf/inject';
import { useInjectAllotmentInvoicePopup } from '../../../containers/AllotmentInvoicePopup/inject';
import { useInjectAuxiliaryData } from '../../../containers/AuxiliaryData/inject';
import { confirmDeleteInvoicePopupSaga, getAllotmentInvoiceListSaga, getAgentBalanceSaga } from './saga';
import { actions, reducer, sliceKey } from './slice';

export const useInjectAllotmentInvoiceList = () => {
    useInjectAuxiliaryData();
    useInjectAllotmentInvoicePdf();
    useInjectAllotmentInvoicePopup();
    useInjectReducer({ actions, key: sliceKey, reducer });
    useInjectSaga({
        key: `${sliceKey}getAllotmentInvoiceListSaga`,
        saga: getAllotmentInvoiceListSaga,
    });
    useInjectSaga({
        key: `${sliceKey}confirmDeleteInvoicePopupSaga`,
        saga: confirmDeleteInvoicePopupSaga,
    });
    useInjectSaga({
        key: `${sliceKey}loadAgentBalanceSaga`,
        saga: getAgentBalanceSaga,
    });
};
