import {
    AgentPaginatedList,
    AgentPostRequest,
    AgentPutRequest,
} from '@malesia/json-schema';
import { AgentFormType } from '@malesia/react-components/dist/src/components/Agent/Edit';
import { call } from 'typed-redux-saga';
import { apiClientRequest } from './ApiClient';

export type GetAgentListParams = {
    query?: string,
    name?: string,
    page?: number,
    pageSize?: number,
    isActive?: boolean,
    sortBy?: string,
    sortOrder?: string,
    countries?: string[],
};
export function* getApiAgentList(params: GetAgentListParams) {
    const requestData = {
        requestId: 'agentList',
        query: params,
    };
    const response: AgentPaginatedList = yield* call(apiClientRequest, requestData);
    return response;
}

export function* updateApiAgent(user: AgentPutRequest) {
    const requestData = {
        requestId: 'agentDataUpdate',
        uriParams: { id: user.id! },
        requestPayload: user,
    };

    return yield* call(apiClientRequest, requestData);
}

export const mapAgentToPostRequest = (data: AgentFormType): AgentPostRequest => {
    return {
        email: data.email,
        language: data.language!,
        password: data.password!,
        birthday: data.birthday!,
        firstName: data.firstName,
        lastName: data.lastName,
        company: data.company,
        phoneMobile: data.phoneMobile!,
        phoneNumberFirst: data.phoneNumberFirst,
        phoneNumberSecond: data.phoneNumberSecond,
        street: data.street!,
        zip: data.zip!,
        city: data.city,
        country: data.country!,
        monthlyFee: data.monthlyFee ? Number(data.monthlyFee) : undefined,
        creditLimitTotalAmount: data.creditLimitTotalAmount ? Number(data.creditLimitTotalAmount) : undefined,
        moneyMargin: data.moneyMargin ? Number(data.moneyMargin) : undefined,
        percentMargin: data.percentMargin ? Number(data.percentMargin) : undefined,
        marginType: data.marginType,
        salutation: data.salutation!,
        isActive: data.isActive,
    };
};

export const mapAgentToPutRequest = (data: AgentFormType): AgentPutRequest => {
    return {
        email: data.email,
        language: data.language!,
        ...data.password ? { password: data.password } : {},
        birthday: data.birthday!,
        firstName: data.firstName,
        lastName: data.lastName,
        company: data.company,
        phoneMobile: data.phoneMobile!,
        phoneNumberFirst: data.phoneNumberFirst,
        phoneNumberSecond: data.phoneNumberSecond,
        street: data.street!,
        zip: data.zip!,
        city: data.city,
        country: data.country!,
        monthlyFee: data.monthlyFee ? Number(data.monthlyFee) : undefined,
        creditLimitTotalAmount: data.creditLimitTotalAmount ? Number(data.creditLimitTotalAmount) : undefined,
        moneyMargin: data.moneyMargin ? Number(data.moneyMargin) : undefined,
        percentMargin: data.percentMargin ? Number(data.percentMargin) : undefined,
        marginType: data.marginType,
        salutation: data.salutation!,
        isActive: data.isActive,
    };
};
