import { OptionEntireList, Rate, TariffEntireList } from '@malesia/json-schema';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { RateNewPageState } from './types';

export const initialState: RateNewPageState = {
    tariffData: {
        items: [],
    },
    optionData: {
        items: [],
    },
    loading: false,
    loadingTariffs: false,
    loadingOptions: false,
};

const rateNewPageSlice = createSlice({
    name: 'rateNewPage',
    initialState,
    reducers: {
        getTariffListData(state) {
            state.loadingTariffs = true;
        },
        getTariffListDataSuccess(state, action: PayloadAction<TariffEntireList>) {
            state.tariffData = action.payload;
            state.loadingTariffs = false;
        },
        getTariffListDataError(state) {
            state.loadingTariffs = false;
        },
        getOptionListData(state) {
            state.loadingOptions = true;
        },
        getOptionListDataSuccess(state, action: PayloadAction<OptionEntireList>) {
            state.optionData = action.payload;
            state.loadingOptions = false;
        },
        getOptionListDataError(state) {
            state.loadingOptions = false;
        },
        createRate(state, action: PayloadAction<Partial<Rate>>) {
            state.loading = true;
        },
        createRateSuccess(state) {
            state.loading = false;
        },
        createRateError(state) {
            state.loading = false;
        },
    },
});

export const { actions, reducer, name: sliceKey } = rateNewPageSlice;
