import { getImageUrl } from '../getImageUrl';
import { getTopImage } from '../getTopImage';
import { ManagementPageDocument, ManagementPageQuery } from '../gql-types';
import { gqlClient } from '../gqlClient';

export async function loadManagementPageContent(locale: string) {
    const { data } = await gqlClient.query<ManagementPageQuery>(
        ManagementPageDocument,
        { lang: locale },
    ).toPromise();
    return {
        topImage: getTopImage(data?.management_page?.top_image),
        content: data?.management_page?.translations?.[0]?.content ?? '',
        cards: data?.management_card?.map(card => {
            return {
                avatar: getImageUrl(card?.avatar?.id),
                title: card?.display_name ?? '',
                role: card?.translations?.[0]?.role ?? '',
                phoneTitle: card?.translations?.[0]?.phone_title ?? '',
                phone: card?.phone ?? '',
                emailTitle: card?.translations?.[0]?.email_title ?? '',
                email: card?.email ?? '',
            };
        }) ?? [],
    };
}
