import { call } from 'typed-redux-saga';
import { AirportAvailabilityMap } from '../containers/SharedData/types';
import { apiClientRequest } from './ApiClient';

export function* getApiFlightsMap() {
    const response: AirportAvailabilityMap = yield* call(apiClientRequest, {
        requestId: 'flightsMap',
    });
    return response;
}
