import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export type DenyPageProps = {
    className?: string,
};

export function DenyPage({ className }: DenyPageProps) {
    const classNames = classnames('deny-page', className ?? '');

    return (
        <div className={classNames}>
            <FormattedMessage
                id='front-app/DenyPage:Title'
                defaultMessage="You don't have permissions for this page"
            />
        </div>
    );
}
