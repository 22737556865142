import { createActionLayout } from '@malesia/react-components/dist/src/components/Table/columns/actionsColumn/actionLayout';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const editRate = createActionLayout({
    text: (
        <FormattedMessage
            id='front-app/RateListPage:editTitle'
            defaultMessage='Edit rate'
        />
    ),
    iconName: 'edit',
});
