import { Customer } from '@malesia/json-schema';
import { createActionsColumn } from '@malesia/react-components/dist/src/components/Table/columns/actionsColumn';
import * as layouts from './actions';

export const customerActionsColumn = createActionsColumn<Customer, typeof layouts>({
    columnsNumber: 3,
    rowsNumber: 1,
    layouts,
    order: [
        'editCustomer',
        'balanceLog',
        'reservationList',
    ],
});
export type CustomerActionsConfig = Parameters<typeof customerActionsColumn>[0];
