import React from 'react';
import { FormattedMessage } from 'react-intl';
import { appNotification } from '../../../containers/App/appNotification';
import { createLocalNotification } from '../../../containers/App/utils';
import { paymentNotificationMessages } from '../../ReservationPaymentPage/store/notification';

const notification = createLocalNotification('AllotmentPayment', {
    error: (
        <FormattedMessage
            id='front-app/AllotmentPayment/Notification/Error:title'
            defaultMessage='Allotment Payment Error'
        />
    ),
    warning: (
        <FormattedMessage
            id='front-app/AllotmentPayment/Notification/Warning:title'
            defaultMessage='Allotment Payment Warning'
        />
    ),
    info: (
        <FormattedMessage
            id='front-app/AllotmentPayment/Notification/Info:title'
            defaultMessage='Allotment Payment Info'
        />
    ),
    success: (
        <FormattedMessage
            id='front-app/AllotmentPayment/Notification/Info:title'
            defaultMessage='Allotment Payment Info'
        />
    ),
});

export const paymentNotifications = {
    paymentSuccess: notification.success(paymentNotificationMessages.paymentSuccess),
    issueWithPayNow: notification.error(paymentNotificationMessages.issueWithPayNow),
    notLoadedPaymentAccounts: notification.error(paymentNotificationMessages.notLoadedPaymentAccounts),
    notFoundAllotmentInvoice: (id: number) => notification.error(
        <FormattedMessage
            id='front-app/notificationMessages/payment:notFoundAllotmentInvoice'
            defaultMessage='not found allotment invoice {id}'
            values={{ id }}
        />,
    ),
    bigPartialAmount: notification.error(paymentNotificationMessages.bigPartialAmount),
    notSelectedBankAccount: notification.warning(paymentNotificationMessages.notSelectedBankAccount),
    notSelectedCashAccount: notification.warning(paymentNotificationMessages.notSelectedCashAccount),
    notSelectedTransferAccount: notification.warning(paymentNotificationMessages.notSelectedTransferAccount),
    notEnoughAmountOnAccount: notification.warning(paymentNotificationMessages.notEnoughAmountOnAccount),
    unexpectedPaymentMethod: notification.error(paymentNotificationMessages.unexpectedPaymentMethod),
    unknownError: (details?: JSX.Element | string) => (
        notification.error(appNotification.texts.unknownError(details))
    ),
};
