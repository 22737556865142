import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { permissionMaker } from '../permissionExpression';
import { PaginatedAdminRoutePermissions } from '.';

const { some, every, role } = permissionMaker;

const flightEditDependencies = every(
    'ROLE_FLIGHT_COMPANY_LIST',
    'ROLE_RATE_LIST',
    'ROLE_AIRCRAFT_TEMPLATE_GENERIC_LIST',
);
const flightCompanyEdit = every(
    'ROLE_FLIGHT_COMPANY_READ',
    'ROLE_ADMIN', // MAL-34 'ROLE_FLIGHT_COMPANY_UPDATE',
);
const flightCompanyList = role('ROLE_FLIGHT_COMPANY_LIST');
const flightCompanyNew = role('ROLE_FLIGHT_COMPANY_CREATE');
const flightEdit = every(
    'ROLE_FLIGHT_READ',
    'ROLE_FLIGHT_UPDATE',
    flightEditDependencies,
);
const flightList = some('ROLE_FLIGHT_LIST', 'ROLE_FLIGHT_AGENT_FLIGHT_LIST');
const flightNew = every(
    'ROLE_FLIGHT_CREATE',
    flightEditDependencies,
);
const flightPassengerList = role('ROLE_FLIGHT_PASSENGER_LIST');

export const flight: PaginatedAdminRoutePermissions['flight'] = {
    [allAdminRoutePaths.flightCompanyEdit]: flightCompanyEdit,
    [allAdminRoutePaths.flightCompanyList]: flightCompanyList,
    [allAdminRoutePaths.flightCompanyNew]: flightCompanyNew,
    [allAdminRoutePaths.flightEdit]: flightEdit,
    [allAdminRoutePaths.flightList]: flightList,
    [allAdminRoutePaths.flightNew]: flightNew,
    [allAdminRoutePaths.flightPassengerList]: flightPassengerList,
    [allAdminRoutePaths.flight]: some(
        flightList,
        flightCompanyList,
    ),
};
