import { Rate } from '@malesia/json-schema';
import { BackLink } from '@malesia/react-components/dist/src/components/BackLink';
import { LoadingPage } from '@malesia/react-components/dist/src/components/LoadingOverlay/Page';
import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { RateEdit, FormRateEditDataType } from '@malesia/react-components/dist/src/components/Rate/Edit';
import { FormErrorType } from '@malesia/react-components/dist/src/components/ValidationErrors';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { coercionDataToSchema } from '../../../utils/validation/coercion';
import { createCustomRoundValidator } from '../../../utils/validation/createCustomRoundValidator';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { formatSchemaValidationErrors } from '../../containers/ValidationErrorMessage';
import {
    getTariffListSaga,
    getOptionListSaga,
    getRateDataSaga,
    updateRateDataSaga,
} from './saga';
import {
    selectRateData,
    selectLoading,
    selectTariffData,
    selectLoadingTariffs,
    selectOptionData,
    selectLoadingOptions,
} from './selectors';
import { actions, reducer, sliceKey } from './slice';

type RouteParams = {
    rateId: string,
};

type Props = RouteComponentProps<RouteParams>;

export const RateEditPage: SFC<Props> = (props) => {
    useInjectReducer({ actions, key: sliceKey, reducer });
    useInjectSaga({
        key: `${sliceKey}Tariff`,
        saga: getTariffListSaga,
    });
    useInjectSaga({
        key: `${sliceKey}Option`,
        saga: getOptionListSaga,
    });
    useInjectSaga({ key: `${sliceKey}Get`, saga: getRateDataSaga });
    useInjectSaga({
        key: `${sliceKey}Update`,
        saga: updateRateDataSaga,
    });

    const [formErrors, setFormErrors] = useState<Record<string, FormErrorType[]>>({});
    const rateData = useSelector(selectRateData);
    const tariffData = useSelector(selectTariffData);
    const optionData = useSelector(selectOptionData);
    const loading = useSelector(selectLoading);
    const loadingTariffs = useSelector(selectLoadingTariffs);
    const loadingOptions = useSelector(selectLoadingOptions);
    const isAnythingLoading = loading || loadingTariffs || loadingOptions;

    const dispatch = useDispatch();

    const { match } = props;
    const { params } = match;
    const { rateId } = params;

    useEffect(() => {
        if (!rateId) return;
        dispatch(actions.getRateData(rateId));
    }, [rateId, dispatch]);

    useEffect(() => {
        dispatch(actions.getTariffList());
    }, [dispatch]);

    useEffect(() => {
        dispatch(actions.getOptionList());
    }, [dispatch]);

    const onSubmitForm = (data: FormRateEditDataType) => {
        const { rateData } = data;

        // TODO add special schema for form validation and displaying required
        const [validation, updatedData, errors] = coercionDataToSchema(
            'file://malesiareisen.com/json/schema/project/api/call/rate/put/request.json',
            rateData,
        );

        const roundValidator = createCustomRoundValidator<Rate>([
            '/priceList/0/priceStandard',
            '/priceList/0/priceRound',
            '/priceList/0/airportFee',
            '/priceList/0/fuelFee',
            '/priceList/1/priceStandard',
            '/priceList/1/priceRound',
            '/priceList/1/airportFee',
            '/priceList/1/fuelFee',
        ]);

        const allErrors = (errors ?? []).concat(roundValidator(rateData));

        if (validation && allErrors.length === 0) {
            if (!rateId) return;
            dispatch(actions.updateRateData({
                rateId,
                rateData: updatedData,
            }));
        }
        else {
            setFormErrors(formatSchemaValidationErrors(allErrors));
        }
    };

    // TODO add special schema for form validation and displaying required
    const requiredFields = [
        ...['name', 'priceList'],
        ...['tariff',
            'priceStandard',
            'priceRound',
            'priceChildPercent',
            'priceInfantPercent',
            'airportFee',
            'fuelFee'].map(
            requiredField => `priceList/${requiredField}`,
        ),
    ];

    return (
        <PageContentBlock>
            <PageTitle
                text={
                    <FormattedMessage
                        id='front-app/RateEditPage:EditRate'
                        defaultMessage='Edit Rate'
                    />
                }
            />
            <BackLink
                to={allAdminRoutePaths.rateList}
                modificators='stick-to-title'
            />
            <LoadingPage isLoading={isAnythingLoading} />
            {rateData && !isAnythingLoading && (
                <RateEdit
                    rateData={rateData}
                    tariffList={tariffData.items}
                    optionList={optionData.items}
                    requiredFields={requiredFields}
                    formErrors={formErrors}
                    onSubmit={onSubmitForm}
                />
            )}
        </PageContentBlock>
    );
};
