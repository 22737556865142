import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPermissionsData } from '../containers/UserLogin/selectors';
import { createUserPermissions, UserPermissions } from './userPermissions';

export const useUserPermissions = (): UserPermissions => {
    const data = useSelector(selectPermissionsData);
    const { userRoles, userType, userId } = data;
    const userPermissions = useMemo(() => (
        createUserPermissions(userRoles, userType, userId)
    ), [
        userRoles,
        userType,
        userId,
    ]);
    return userPermissions;
};
