import { ReservationFlight } from '@malesia/json-schema';
import { FlightDirection } from '@malesia/react-components/dist/src/components/Reservation/reservation-types';
import { AgeType } from '@malesia/react-components/dist/src/utils/ageType';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../types';
import { FlightVariants } from '../../../utils/reservation/selectedFlight';
import { initialState } from './reservationBasket.slice';
import { PublicBasketCost } from './reservationBasket.types';

const selectDomain = (state: RootState) => state.reservationBasket || initialState;

const selectRoot = (state: RootState) => state;

export const selectReservationBasket = createSelector(
    [selectDomain, selectRoot],
    (reservationBasket, root) => {
        const billingInformation = {
            ...reservationBasket.billingInformation,
            language: root.global.locale ?? 'de',
        };
        return {
            ...reservationBasket,
            billingInformation,
        };
    },
);

export const selectTravelType = createSelector(
    [selectDomain],
    reservationBasket => reservationBasket.travelType,
);

export const selectSelectedFlights = createSelector(
    [selectDomain],
    reservationBasket => reservationBasket.selectedFlights,
);

export const selectSelectedPrices = createSelector(
    [selectDomain],
    reservationBasket => reservationBasket.selectedPrices,
);

export const selectSelectedFlightVariants = createSelector(
    [selectSelectedFlights, selectSelectedPrices],
    (selectedFlights, selectedPrices): FlightVariants => ({
        outbound: !(selectedFlights.outbound && selectedPrices.outbound) ? undefined : {
            flight: selectedFlights.outbound,
            price: selectedPrices.outbound,
        },
        ['return']: !(selectedFlights.return && selectedPrices.return) ? undefined : {
            flight: selectedFlights.return,
            price: selectedPrices.return,
        },
    }),
);

export const selectPassengers = createSelector(
    [selectDomain],
    reservationBasket => reservationBasket.passengers,
);

export const selectPassengersAges = createSelector(
    [selectDomain],
    reservationBasket => reservationBasket.passengersAges,
);

export const selectOtherAirports = createSelector(
    [selectDomain],
    reservationBasket => reservationBasket.otherAirports,
);
export const selectCost = createSelector(
    [selectDomain],
    reservationBasket => reservationBasket.cost,
);

export const selectBookedFlights = createSelector(
    [selectSelectedFlights, selectSelectedPrices],
    (flights, prices): ReservationFlight[] => {
        if (!flights || !prices) return [];
        const getFlight = (flightDirection: FlightDirection): ReservationFlight | undefined => {
            const flightId = flights[flightDirection]?.id;
            const tariffId = prices[flightDirection]?.tariff?.id;
            if (!flightId || !tariffId) return undefined;
            return {
                flight: { id: flightId },
                tariff: { id: tariffId },
            };
        };
        const outboundFlight = getFlight('outbound');
        if (!outboundFlight) return [];
        const returnFlight = getFlight('return');
        if (!returnFlight) return [outboundFlight];
        return [outboundFlight, returnFlight];
    },
);

type CostByAge = { count: number, total: number };
export const getCostByAge = (basketCost: PublicBasketCost | undefined): Record<AgeType, CostByAge> => {
    const costList = basketCost?.costForPassengers ?? [];
    const result: Record<AgeType, CostByAge> = {
        adult: {
            count: 0,
            total: 0,
        },
        child: {
            count: 0,
            total: 0,
        },
        infant: {
            count: 0,
            total: 0,
        },
    };
    for (const costItem of costList) {
        const prevCount = result[costItem.passenger.ageType!].count;
        const prevTotal = result[costItem.passenger.ageType!].total;
        const totalCostForPassenger = costItem.flightsCosts
            .reduce((total, flightCost) => {
                const { base, fuelFee, airportFee, seat } = flightCost;
                const sum = (base ?? 0) + (fuelFee ?? 0) + (airportFee ?? 0) + (seat ?? 0);
                return total + sum;
            }, 0);
        result[costItem.passenger.ageType!] = {
            count: prevCount + 1,
            total: prevTotal + totalCostForPassenger,
        };
    }
    return result;
};

export const selectBillingInformation = createSelector(
    [selectDomain],
    basket => basket.billingInformation,
);
