import { AllotmentListFilter, AllotmentListFilterProps, AllotmentFilterData, allotmentListFilterConfig, allotmentListFilterEffects } from '@malesia/react-components/dist/src/components/Allotment/List/Filter';
import { ValueConfig, useValueForm } from '@malesia/react-components/dist/src/hooks/valueForm';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAgentList, selectAgentQuery } from '../../../containers/AuxiliaryData/selectors';
import { auxiliaryActions } from '../../../containers/AuxiliaryData/slice';
import { allotmentListPermissions } from '../../../permissions/adminPages/allotment/allotmentList';
import { useUserPermissions } from '../../../permissions/useUserPermissions';
import { selectFilterData } from '../store/selectors';
import { actions } from '../store/slice';

export const AllotmentListFilterWithStore: SFC = () => {
    const dispatch = useDispatch();
    const userPermissions = useUserPermissions();

    const value = useSelector(selectFilterData);
    const agentQuery = useSelector(selectAgentQuery);
    const agentList = useSelector(selectAgentList);

    const hashRef = useRef({});

    useEffect(() => {
        if (!userPermissions.has(allotmentListPermissions.getList)) return;

        dispatch(auxiliaryActions.searchAgentList(''));
    }, [dispatch, userPermissions]);

    const {
        field,
    } = useValueForm({
        value,
        config: [allotmentListFilterConfig, (): ValueConfig<AllotmentFilterData> => ({
            shape: {
                agent: {
                    items: agentList,
                    query: agentQuery,
                    setQuery: (text) => {
                        if (!userPermissions.has(allotmentListPermissions.getList)) return;
                        dispatch(auxiliaryActions.searchAgentList(text));
                    },
                    hidden: !userPermissions.has(allotmentListPermissions.getList),
                },
            },
        })],
        onChange: (v) => {
            dispatch(actions.setFilterData(v));
        },
        validationEffects: allotmentListFilterEffects,
        hash: hashRef.current,
    });
    const allotmentListFilterProps: AllotmentListFilterProps = {
        field,
        reset: () => {
            dispatch(actions.resetFilterData());
            if (userPermissions.has(allotmentListPermissions.getList)) {
                dispatch(auxiliaryActions.searchAgentList(''));
            }
            hashRef.current = {};
        },
    };

    return (
        <AllotmentListFilter {...allotmentListFilterProps} />
    );
};
