import { call, put, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../../utils/log';
import { refreshUserInfo } from '../../../containers/UserLogin/saga';
import { updateApiAgent } from '../../../services/agent';
import { getApiErrorMessage } from '../../../services/ApiClient';
import { myAccountNotifications } from '../notification';
import { userAccountPageActions } from './userAccount.slice';

function* submitAgent(action: ReturnType<typeof userAccountPageActions.submitAgent>) {
    try {
        yield* put(userAccountPageActions.setLoading(true));
        yield* call(updateApiAgent, action.payload);
        yield* call(refreshUserInfo);
        yield* put(myAccountNotifications.updatedSuccessfully);
    }
    catch (error) {
        logError({
            error,
            target: 'UserAccountPage.submitAgent',
        });
        const message = getApiErrorMessage(error);
        yield* put(myAccountNotifications.unknownError(message));
    }
    finally {
        yield* put(userAccountPageActions.setLoading(false));
    }
}

export function* submitAgentSaga() {
    yield* takeLatest(userAccountPageActions.submitAgent, submitAgent);
}
