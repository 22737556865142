import { createActionLayout } from '@malesia/react-components/dist/src/components/Table/columns/actionsColumn/actionLayout';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const cashbackPdf = createActionLayout({
    text: (
        <FormattedMessage
            id='front-app/BalanceLogPage:cashbackPdf'
            defaultMessage='Cashback Pdf'
        />
    ),
    iconName: 'invoice-dollar',
});
