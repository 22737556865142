import { TravelType } from '@malesia/react-components/dist/src/components/Reservation/reservation-types';
import { addQueryParameters, getQueryParameters } from '../../../../utils/uriUtils';

export type BookingQueryParameters = {
    travelType?: TravelType,
    departureDate?: string,
    returnDate?: string,
    airportFrom?: string,
    airportTo?: string,
    otherAirportFrom?: string,
    otherAirportTo?: string,
    adult?: number,
    child?: number,
    infant?: number,
    departureFlightId?: number,
    departureTariffId?: number,
    returnFlightId?: number,
    returnTariffId?: number,
};

export const getBookingQueryParameters = () => getQueryParameters<BookingQueryParameters>({
    travelType: 'string',
    departureDate: 'string',
    returnDate: 'string',
    airportFrom: 'string',
    airportTo: 'string',
    otherAirportFrom: 'string',
    otherAirportTo: 'string',
    adult: 'number',
    child: 'number',
    infant: 'number',
    departureFlightId: 'number',
    departureTariffId: 'number',
    returnFlightId: 'number',
    returnTariffId: 'number',
});

export const createBookingLink = (link: string, params: BookingQueryParameters): string => {
    const fixedParams: BookingQueryParameters = {
        ...params,
        departureDate: params.departureDate?.split('T')[0],
        returnDate: params.returnDate?.split('T')[0],
    };
    return addQueryParameters(link, fixedParams);
};
