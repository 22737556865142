import { ContentPage } from '@malesia/react-components/dist/src/components/ContentPage/ContentPage';
import { HtmlBlock } from '@malesia/react-components/dist/src/components/Front/HtmlBlock/HtmlBlock';
import { PartnerCardList } from '@malesia/react-components/dist/src/components/Front/PartnerCard/List';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { useIntl } from 'react-intl';
import useSWR from 'swr';
import { loadPartnersPageContent } from '../../services/directus/partnersPage/loadPartnersPageContent';

export const MalesiaPartnersPage: SFC = () => {
    const { locale } = useIntl();
    const { data } = useSWR(
        [locale, 'loadPartnersPageContent'],
        loadPartnersPageContent,
    );
    return (
        <>
            <ContentPage
                image={data?.topImage}
            >
                <HtmlBlock content={data?.content ?? ''} />
                <PartnerCardList cards={data?.cards ?? []} />
            </ContentPage>
        </>
    );
};
