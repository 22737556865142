import { Button } from '@malesia/react-components/dist/src/components/Button';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { allAdminRoutePaths } from '../../../containers/Routes/allAdminRoutePaths';
import { optionListPermissions } from '../../../permissions/adminPages/rate/optionList';
import { useUserPermissions } from '../../../permissions/useUserPermissions';

export const OptionListHeaderWithStore: SFC = () => {
    const userPermissions = useUserPermissions();

    return (
        <PageTitle
            text={
                <FormattedMessage
                    id='front-app/RateOptionListPage:Title'
                    defaultMessage='Options'
                />
            }
            rightSlot={
                <Link to={allAdminRoutePaths.rateOptionNew}>
                    <Button
                        icon='plus'
                        text={
                            <FormattedMessage
                                id='front-app/Agent/List:AddNewOption'
                                defaultMessage='Add new option'
                            />
                        }
                    />
                </Link>
            }
            rightSlotHidden={!userPermissions.has(optionListPermissions.createLink)}
        />
    );
};
