import { FormFieldDate } from '@malesia/react-components/dist/src/components/Form/Field/Date';
import { PopupConfirm } from '@malesia/react-components/dist/src/components/Popup/Confirm';
import { bookingNextYearsCount } from '@malesia/react-components/dist/src/utils/dateTime';
import { DATE_OUTPUT_FORMAT } from '@malesia/react-components/dist/src/utils/dateTimeFormat';
import moment from 'moment';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsOpenPaymentDeadlinePopup, selectSelectedFlightVariants } from '../../store/selectors';
import { reservationNewPageActions } from '../../store/slice';

const bookedStyle = { boxShadow: 'inset 0 0 0 1px red' };

export function DeadlinePopup(): ReactElement {
    const dispatch = useDispatch();

    const isOpenPaymentDeadlinePopup = useSelector(selectIsOpenPaymentDeadlinePopup);
    const selectedFlights = useSelector(selectSelectedFlightVariants);
    const outbound = selectedFlights.outbound?.flight;

    const departureDate = useMemo(() => moment(outbound?.departure), [outbound]);
    const lastDay = useMemo(() => moment(outbound?.departure).subtract(1, 'days').startOf('day'), [outbound]);
    const firstDay = useMemo(() => moment().add(1, 'days').startOf('day'), []);
    const [paymentDeadline, setPaymentDeadline] = useState<string>();
    useEffect(() => {
        const defaultDate = moment().add(14, 'days');
        let deadline = defaultDate;
        deadline = deadline.isBefore(lastDay) ? deadline : lastDay;
        deadline = deadline.isAfter(firstDay) ? deadline : firstDay;
        setPaymentDeadline(deadline.format(DATE_OUTPUT_FORMAT));
    }, [lastDay, firstDay]);

    const confirm = () => {
        dispatch(reservationNewPageActions.closeDeadlinePopup());
        dispatch(reservationNewPageActions.createReservation({ paymentType: 'bill', paymentDeadline }));
    };

    const close = () => {
        dispatch(reservationNewPageActions.closeDeadlinePopup());
    };

    return (
        <PopupConfirm
            isOpened={!!isOpenPaymentDeadlinePopup}
            onConfirm={confirm}
            onClose={close}
            modificators={['lg', 'no-scrolling']}
            isConfirmDisabled={!paymentDeadline}
            confirmMessage={
                <FormattedMessage
                    id='front-app/ReservationNewPage/DeadlinePopup:Title'
                    defaultMessage='Deadline'
                />
            }
        >
            <div
                style={{
                    gap: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <FormattedMessage
                    id='front-app/ReservationNewPage/DeadlinePopup:Description'
                    defaultMessage='Select until when the reservation needs to be paid.'
                />
                <FormFieldDate
                    modifiers={{ booked: departureDate.toDate() }}
                    modifiersStyles={{ booked: bookedStyle }}
                    modificators={['required']}
                    label={(
                        <FormattedMessage
                            id='front-app/ReservationNewPage/DeadlinePopup:InvoiceDeadline'
                            defaultMessage='Invoice deadline'
                        />
                    )}
                    setValue={setPaymentDeadline}
                    value={paymentDeadline}
                    restrictions={{
                        disabledBeforeDay: firstDay.toISOString(),
                        pastYearsCount: 0,
                        nextYearsCount: bookingNextYearsCount,
                    }}
                />
            </div>
        </PopupConfirm>
    );
}
