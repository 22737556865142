import { IconKey } from '@malesia/react-components/dist/src/components/Icon';
import { ClassModificators } from '@malesia/react-components/dist/src/utils/classFunctions';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PublicReservationBasket } from '../../../containers/ReservationBasket/reservationBasket.types';
import { allPublicRoutePaths } from '../../../containers/Routes/allPublicRoutePaths';
import { StepBillingInformation } from '../Steps/StepBillingInformation';
import { StepFlightOutbound } from '../Steps/StepFlightOutbound';
import { StepFlightReturn } from '../Steps/StepFlightReturn';
import { StepPassengers } from '../Steps/StepPassengers';
import { StepReservationOverview } from '../Steps/StepReservationOverview';
import { StepSeatReservation } from '../Steps/StepSeatReservation';
import { StepTravel } from '../Steps/StepTravelDate';

const getIsSkippedSeatsReservationStep = (reservationBasket: Partial<PublicReservationBasket>): boolean => {
    const { selectedFlights } = reservationBasket;
    return !Object.values(selectedFlights || {})
        .some(flightListSelection => !!flightListSelection?.aircraftTemplate?.id);
};

export type StepName = (
    | 'travel-dates'
    | 'select-flight-outbound'
    | 'select-flight-return'
    | 'passengers'
    | 'seat-reservation'
    | 'billing-information'
    | 'reservation-overview'
);

export type BookingStep = {
    breadcrumbTitle: string | JSX.Element,
    breadCrumbIcon: IconKey,
    Component: React.ComponentType<any>,
    designModifiers: ClassModificators,
    isStepsSummaryHidden?: boolean,
    nextStep: string | null | ((data: Partial<PublicReservationBasket>) => string),
    path: string,
    previousStep: string | null | ((data: Partial<PublicReservationBasket>) => string),
    skipStepBreadcrumb?: (data: Partial<PublicReservationBasket>) => boolean,
    stepNumber: number,
};

export type ReservationStepsConfig = Record<StepName, BookingStep>;

export const stepsConfig: ReservationStepsConfig = {
    'travel-dates': {
        stepNumber: 1,
        path: allPublicRoutePaths.bookingFlightTravelDates,
        breadCrumbIcon: 'calendar-mark',
        breadcrumbTitle: (
            <FormattedMessage
                id='front-app/BookingFlightPage/StepsBreadcrumbs:TravelDates'
                defaultMessage='Travel Dates'
            />
        ),
        Component: StepTravel,
        isStepsSummaryHidden: true,
        designModifiers: 'travel-dates',
        previousStep: null,
        nextStep: 'select-flight-outbound',
    },
    'select-flight-outbound': {
        stepNumber: 2,
        path: allPublicRoutePaths.bookingFlightSelectFlightOutbound,
        breadCrumbIcon: 'airplane',
        breadcrumbTitle: (
            <FormattedMessage
                id='front-app/BookingFlightPage/StepsBreadcrumbs:OutboundFlight'
                defaultMessage='Outbound Flight'
            />
        ),
        Component: StepFlightOutbound,
        designModifiers: 'flight-reservation',
        previousStep: 'travel-dates',
        nextStep: (reservationBasket: Partial<PublicReservationBasket>) => {
            return reservationBasket.travelType === 'one-way'
                ? 'passengers'
                : 'select-flight-return';
        },
    },
    'select-flight-return': {
        stepNumber: 3,
        path: allPublicRoutePaths.bookingFlightSelectFlightReturn,
        breadCrumbIcon: 'airplane-return',
        breadcrumbTitle: (
            <FormattedMessage
                id='front-app/BookingFlightPage/StepsBreadcrumbs:ReturnFlight'
                defaultMessage='Return Flight'
            />
        ),
        skipStepBreadcrumb: (reservationBasket: Partial<PublicReservationBasket>) => {
            return reservationBasket.travelType === 'one-way';
        },
        Component: StepFlightReturn,
        designModifiers: 'flight-reservation',
        previousStep: 'select-flight-outbound',
        nextStep: 'passengers',
    },
    'passengers': {
        stepNumber: 4,
        path: allPublicRoutePaths.bookingFlightPassengers,
        breadCrumbIcon: 'passengers',
        breadcrumbTitle: (
            <FormattedMessage
                id='front-app/BookingFlightPage/StepsBreadcrumbs:Passengers'
                defaultMessage='Passengers'
            />
        ),
        Component: StepPassengers,
        designModifiers: 'flight-reservation',
        previousStep: (reservationBasket: Partial<PublicReservationBasket>) => {
            return reservationBasket.travelType === 'one-way'
                ? 'select-flight-outbound'
                : 'select-flight-return';
        },
        nextStep: (reservationBasket: Partial<PublicReservationBasket>) => {
            const isSkippedSeatReservation = getIsSkippedSeatsReservationStep(reservationBasket);
            return isSkippedSeatReservation
                ? 'billing-information'
                : 'seat-reservation';
        },
    },
    'seat-reservation': {
        stepNumber: 5,
        path: allPublicRoutePaths.bookingFlightSeatReservation,
        breadCrumbIcon: 'seat',
        breadcrumbTitle: (
            <FormattedMessage
                id='front-app/BookingFlightPage/StepsBreadcrumbs:SeatReservation'
                defaultMessage='Seat reservation'
            />
        ),
        Component: StepSeatReservation,
        designModifiers: 'flight-reservation',
        previousStep: 'passengers',
        nextStep: 'billing-information',
    },
    'billing-information': {
        stepNumber: 6,
        path: allPublicRoutePaths.bookingFlightBillingInformation,
        breadCrumbIcon: 'address',
        breadcrumbTitle: (
            <FormattedMessage
                id='front-app/BookingFlightPage/StepsBreadcrumbs:BillingInformation'
                defaultMessage='Billing Information'
            />
        ),
        Component: StepBillingInformation,
        designModifiers: 'flight-reservation',
        previousStep: (reservationBasket: Partial<PublicReservationBasket>) => {
            const isSkippedSeatReservation = getIsSkippedSeatsReservationStep(reservationBasket);
            return isSkippedSeatReservation
                ? 'passengers'
                : 'seat-reservation';
        },
        nextStep: 'reservation-overview',
    },
    'reservation-overview': {
        stepNumber: 7,
        path: allPublicRoutePaths.bookingFlightReservationOverview,
        breadCrumbIcon: 'money',
        breadcrumbTitle: (
            <FormattedMessage
                id='front-app/BookingFlightPage/StepsBreadcrumbs:Payment'
                defaultMessage='Payment'
            />
        ),
        Component: StepReservationOverview,
        isStepsSummaryHidden: true,
        designModifiers: 'flight-reservation',
        previousStep: 'billing-information',
        nextStep: null,
    },
};
