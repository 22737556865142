import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { permissionMaker } from '../permissionExpression';
import { PaginatedAdminRoutePermissions } from '.';

const { anyone } = permissionMaker;

export const specific: PaginatedAdminRoutePermissions['specific'] = {
    [allAdminRoutePaths.agentPayment]: anyone(),
    [allAdminRoutePaths.updatePassword]: anyone(),
};
