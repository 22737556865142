import { Rate } from '@malesia/json-schema';
import { BackLink } from '@malesia/react-components/dist/src/components/BackLink';
import { LoadingPage } from '@malesia/react-components/dist/src/components/LoadingOverlay/Page';
import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { PageTitle } from '@malesia/react-components/dist/src/components/Page/Title';
import { RateEdit, FormRateEditDataType } from '@malesia/react-components/dist/src/components/Rate/Edit';
import { FormErrorType } from '@malesia/react-components/dist/src/components/ValidationErrors';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useEffect, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { coercionDataToSchema } from '../../../utils/validation/coercion';
import { createCustomRoundValidator } from '../../../utils/validation/createCustomRoundValidator';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { formatSchemaValidationErrors } from '../../containers/ValidationErrorMessage';
import {
    createRateSaga,
    getTariffListDataSaga,
    getOptionListDataSaga,
} from './saga';
import {
    selectTariffData,
    selectOptionData,
    selectLoading,
    selectLoadingTariffs,
    selectLoadingOptions,
} from './selectors';
import { actions, reducer, sliceKey } from './slice';

export const RateNewPage: SFC = () => {
    useInjectReducer({ actions, key: sliceKey, reducer });
    useInjectSaga({
        key: `${sliceKey}Tariff`,
        saga: getTariffListDataSaga,
    });
    useInjectSaga({
        key: `${sliceKey}Option`,
        saga: getOptionListDataSaga,
    });
    useInjectSaga({ key: sliceKey, saga: createRateSaga });

    const [formErrors, setFormErrors] = useState<Record<string, FormErrorType[]>>({});
    const tariffData = useSelector(selectTariffData);
    const optionData = useSelector(selectOptionData);
    const loading = useSelector(selectLoading);
    const loadingTariffs = useSelector(selectLoadingTariffs);
    const loadingOptions = useSelector(selectLoadingOptions);
    const isAnythingLoading = loading || loadingTariffs || loadingOptions;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.getTariffListData());
        dispatch(actions.getOptionListData());
    }, [dispatch]);

    const onSubmitForm = useCallback(
        (data: FormRateEditDataType) => {
            const { rateData } = data;

            // TODO add special schema for form validation and displaying required
            const [validation, updatedData, errors] = coercionDataToSchema(
                'file://malesiareisen.com/json/schema/project/api/call/rate/post/request.json',
                rateData,
            );

            const roundValidator = createCustomRoundValidator<Rate>(tariffData.items.map((_, idx) => ([
                `/priceList/${idx}/priceStandard`,
                `/priceList/${idx}/priceRound`,
                `/priceList/${idx}/airportFee`,
                `/priceList/${idx}/fuelFee`,
            ])).flat());

            const allErrors = (errors ?? []).concat(roundValidator(rateData));

            if (validation && allErrors.length === 0) {
                dispatch(actions.createRate(updatedData));
            }
            else {
                setFormErrors(formatSchemaValidationErrors(allErrors));
            }
        },
        [tariffData, dispatch],
    );

    // TODO add special schema for form validation and displaying required
    const requiredFields = [
        ...['name', 'priceList'],
        ...['tariff',
            'priceStandard',
            'priceRound',
            'priceChildPercent',
            'priceInfantPercent',
            'airportFee',
            'fuelFee'].map(
            requiredField => `priceList/${requiredField}`,
        ),
    ];

    return (
        <PageContentBlock>
            <PageTitle
                text={
                    <FormattedMessage
                        id='front-app/RateNewPage:Title'
                        defaultMessage='New rate'
                    />
                }
            />
            <BackLink
                to={allAdminRoutePaths.rateList}
                modificators='stick-to-title'
            />
            <LoadingPage isLoading={isAnythingLoading} />
            {!isAnythingLoading && (
                <RateEdit
                    requiredFields={requiredFields}
                    formErrors={formErrors}
                    tariffList={tariffData.items}
                    optionList={optionData.items}
                    onSubmit={onSubmitForm}
                />
            )}
        </PageContentBlock>
    );
};
