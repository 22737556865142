import { Flight } from '@malesia/json-schema';
import { FlightPassengerAppearingOption } from '@malesia/react-components/dist/src/components/Flight/Passenger/List/actions';
import { flightPassengerColumns, FlightPassengerColumnsConfig } from '@malesia/react-components/dist/src/components/Flight/Passenger/List/tableColumns';
import { splitActionConfig } from '@malesia/react-components/dist/src/components/Table/columns/actionsColumn/splitAction';
import { limitAges } from '@malesia/react-components/dist/src/utils/ageType';
import { MutableRefObject, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';
import { selectIsLoadingOverviewMap } from '../../containers/ReservationPdf/selectors';
import { reservationPdfActions } from '../../containers/ReservationPdf/slice';
import { ReservationPdfState } from '../../containers/ReservationPdf/types';
import { backLinks } from '../../containers/Routes/backLinks';
import { flightPassengerListPermissions } from '../../permissions/adminPages/flight/flightPassengerList';
import { UserPermissions } from '../../permissions/userPermissions';
import { useUserPermissions } from '../../permissions/useUserPermissions';
import { selectIsLoadingAppearingMap } from './selectors';
import { actions as sagaActions } from './slice';

type TableRef = {
    isLoadingOverviewMap: ReservationPdfState['isLoadingOverviewMap'],
    isLoadingAppearingMap: Record<string | number, FlightPassengerAppearingOption | undefined>,
};

type CreateActionsOptions = {
    flightId: number,
    userPermissions: UserPermissions,
    history: ReturnType<typeof useHistory>,
    dispatch: Dispatch,
    ref: MutableRefObject<TableRef>,
};
const createActions = ({
    flightId,
    userPermissions,
    history,
    dispatch,
    ref,
}: CreateActionsOptions): FlightPassengerColumnsConfig['actions'] => ({
    editReservation: {
        click: (flightPassenger) => {
            const link = backLinks.reservation.forward(flightPassenger.reservation.id, {
                source: 'passengerList',
                flightId,
            });
            history.push(link);
        },
        notAllowed: !userPermissions.has(flightPassengerListPermissions.editReservationLink),
    },
    payAndTicketReservation: splitActionConfig({
        getState: (flightPassenger) => flightPassenger.reservation?.isPaid ? 'ticket' : 'pay',
        pay: {
            click: (flightPassenger) => {
                const link = backLinks.reservationPayment.forward(flightPassenger.reservation.id, {
                    source: 'passengerList',
                    additionalProp: flightId,
                });
                history.push(link);
            },
            notAllowed: !userPermissions.has(flightPassengerListPermissions.reservationPaymentLink),
        },
        ticket: {
            click: (flightPassenger, _, tableRef) => {
                dispatch(reservationPdfActions.downloadOverviewPdf({
                    reservationId: flightPassenger.reservation.id,
                    callback: () => tableRef.renderColumn('actions'),
                }));
                tableRef.renderColumn('actions');
            },
            isLoading: (flightPassenger) => (
                ref.current.isLoadingOverviewMap[flightPassenger.reservation.id]
            ),
            notAllowed: !userPermissions.has(flightPassengerListPermissions.fullyPaidInvoicePdf),
        },
    }),
    flightPassengerAppearing: {
        getState: (flightPassenger) => {
            if (flightPassenger.isAppeared === true) return 'appear';
            if (flightPassenger.isAppeared === false) return 'noShow';
            return 'pending';
        },
        click: (flightPassenger, option, tableRef) => {
            dispatch(
                sagaActions.updateFlightPassengerAppearing({
                    passengerId: flightPassenger.id,
                    appearing: option,
                    callback: () => tableRef.renderColumn('actions'),
                }),
            );
            tableRef.renderColumn('actions');
        },
        isLoading: (flightPassenger, option) => (
            ref.current.isLoadingAppearingMap[flightPassenger.id] === option
        ),
        disabled: (flightPassenger) => (
            !!ref.current.isLoadingAppearingMap[flightPassenger.id]
        ),
        notAllowed: !userPermissions.has(flightPassengerListPermissions.markAppearing),
    },
});

export type UseTableColumnsOptions = {
    flight: Flight | undefined,
    flightId: number,
};
export const useTableColumns = (options: UseTableColumnsOptions) => {
    const { flightId, flight } = options;

    const userPermissions = useUserPermissions();
    const dispatch = useDispatch();
    const history = useHistory();

    const isLoadingOverviewMap = useSelector(selectIsLoadingOverviewMap);
    const isLoadingAppearingMap = useSelector(selectIsLoadingAppearingMap);
    const tableRef: TableRef = {
        isLoadingOverviewMap,
        isLoadingAppearingMap,
    };
    const ref = useRef<TableRef>(tableRef);
    ref.current = tableRef;

    const result = useMemo(() => {
        if (!flight) return [];
        const actions = createActions({
            flightId,
            dispatch,
            history,
            userPermissions,
            ref,
        });
        return flightPassengerColumns({
            select: {
                hidden: !userPermissions.has(flightPassengerListPermissions.bulkActions),
            },
            lastName: {},
            seat: {
                select: (flightPassenger) => {
                    dispatch(sagaActions.updateFlightPassengerSeat({
                        reservationId: flightPassenger.reservation.id,
                        reservationPassengerId: flightPassenger.id,
                        tariff: flightPassenger.tariff,
                        seat: (
                            flightPassenger.seatLabel
                                ? { seatLabel: flightPassenger.seatLabel }
                                : null
                        ),
                    }));
                },
                disabled: (flightPassenger) => (
                    flightPassenger.isAppeared
                    || ['departed', 'cancelled', 'closed'].includes(flight.status!)
                    || (flightPassenger.age && flightPassenger.age < limitAges.infant)
                    || !userPermissions.has(flightPassengerListPermissions.updateFlightPassengerSeat)
                ),
            },
            price: {
                countClick: (flightPassenger) => {
                    const reservationId = flightPassenger.reservation.id;
                    dispatch(
                        sagaActions.updateSearch({
                            reservationId,
                        }),
                    );
                },
            },
            flights: {
                flightId: flight.id!,
            },
            notified: {
                change: (reservationPassenger, value) => {
                    dispatch(
                        sagaActions.updateFlightPassengerNotified({
                            reservationPassengerId: reservationPassenger.id,
                            notifying: { isNotified: value },
                        }),
                    );
                },
                disabled: !userPermissions.has(flightPassengerListPermissions.updateFlightPassengerNotified),
                hidden: !flight.isDelayed,
            },
            actions,
        });
    }, [
        flightId,
        flight,
        userPermissions,
        dispatch,
        history,
    ]);

    return result;
};
