import { call } from 'typed-redux-saga';
import { apiClientRequest } from '../ApiClient';
import { VirtualAccount } from '../user';

export function* getApiVirtualAccounts() {
    const requestData = {
        requestId: 'virtualAccounts',
    };
    const response: VirtualAccount[] = yield* call(apiClientRequest, requestData);
    return response;
}
