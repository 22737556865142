import { getTopImage } from '../getTopImage';
import {
    FlightsPageDocument, FlightsPageQuery,
} from '../gql-types';
import { gqlClient } from '../gqlClient';

export async function loadFlightsPageContent(locale: string) {
    const { data } = await gqlClient.query<FlightsPageQuery>(
        FlightsPageDocument,
        { lang: locale },
    ).toPromise();
    return {
        topImage: getTopImage(data?.flights_page?.top_image),
        content: data?.flights_page?.translations?.[0]?.content ?? '',
    };
}
