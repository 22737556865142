import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { downloadAllotmentInvoicePdfSaga } from './saga';
import { allotmentInvoicePdfActions, allotmentInvoicePdfReducer, allotmentInvoicePdfSliceKey } from './slice';

export const useInjectAllotmentInvoicePdf = () => {
    useInjectReducer({
        actions: allotmentInvoicePdfActions,
        key: allotmentInvoicePdfSliceKey,
        reducer: allotmentInvoicePdfReducer,
    });
    useInjectSaga({
        key: `${allotmentInvoicePdfSliceKey}downloadAllotmentInvoicePdfSaga`,
        saga: downloadAllotmentInvoicePdfSaga,
    });
};
