import { Option } from '@malesia/json-schema';
import { PassengersList } from '@malesia/react-components/dist/src/components/Reservation/PassengersList';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import { validatePassengersAges } from '@malesia/react-components/dist/src/utils/reservation';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPassengersAgesByList } from '../../../../../utils/reservation/passengerUtils';
import { usePassengerForm } from '../../../../../utils/reservation/usePassengerForm';
import { selectLocale } from '../../../../containers/App/selectors';
import { selectOptionList } from '../../../../containers/AuxiliaryData/selectors';
import { auxiliaryActions } from '../../../../containers/AuxiliaryData/slice';
import { selectPassengers, selectSelectedFlightVariants } from '../../../../containers/ReservationBasket/reservationBasket.selectors';
import { reservationBasketActions } from '../../../../containers/ReservationBasket/reservationBasket.slice';
import { bookingNotifications } from '../../store/notification';
import { useInjectStepSagas } from './inject';
import { stepPassengersActions } from './slice';
import { useStartFetchOptionsList } from './useStartFetchOptionsList';
import { useStepRequirements } from './useStepRequirements';
import { useValidators } from './useValidators';

const removedOptionList: Option[] = [];

export type StepPassengersProps = {
    handleStepSubmit: () => void,
};

export const StepPassengers: SFC<StepPassengersProps, { result: 'optional' }> = (props) => {
    const { handleStepSubmit } = props;

    useInjectStepSagas();
    const dispatch = useDispatch();

    const selectedFlights = useSelector(selectSelectedFlightVariants);
    useStartFetchOptionsList(
        selectedFlights,
        auxiliaryActions.getOptionsList,
    );

    const isStepFitRequirements = useStepRequirements();

    const {
        setFormErrors,
        passengersDataWithErrors,
        passengersData,
        handleFormDataChange,
        handleAddPassengerClick,
        handleRemovePassengerClick,
        handlePassengerOptionChange,
    } = usePassengerForm({
        localeSelector: selectLocale,
        passengersSelector: selectPassengers,
        selectOptionList,
        removedOptionList,
        updatePassengerAtPositionAction: reservationBasketActions.updatePassengerAtPosition,
        addPassengerAction: reservationBasketActions.addPassenger,
        removePassengerAtPositionAction: reservationBasketActions.removePassengerAtPosition,
        setPassengerOptionsAction: reservationBasketActions.setPassengerOptions,
    });

    const { validateForm } = useValidators(
        setFormErrors,
        'file://malesiareisen.com/json/schema/project/react-front-app/pages/BookingFlightPage/Steps/StepPassengers/passengerListItemForm.json',
    );

    const handlePassengersListConfirm = async () => {
        const isValid = validateForm(passengersData);
        if (!isValid) return;
        const agesError = validatePassengersAges(getPassengersAgesByList(passengersData.map(x => x.formData)));
        if (agesError) {
            dispatch(bookingNotifications.customError(agesError));
            return;
        }
        dispatch(stepPassengersActions.setPassengersData(passengersData));
        dispatch(stepPassengersActions.requestCalculateCost(handleStepSubmit));
    };

    useEffect(() => {
        isStepFitRequirements && dispatch(stepPassengersActions.calculateBasketWithUpdatePassengers());
    }, [dispatch, isStepFitRequirements, passengersData]);

    if (!isStepFitRequirements) return null;
    return (
        <PassengersList
            isSubmitButtonDisabled={false}
            onAddPassengerClick={handleAddPassengerClick}
            onPassengerDataChange={handleFormDataChange}
            onPassengerOptionChange={handlePassengerOptionChange}
            onPassengersListConfirm={handlePassengersListConfirm}
            onRemovePassengerClick={handleRemovePassengerClick}
            passengersData={passengersDataWithErrors}
        />
    );
};
