import { AuthTokenPostResponse, UserMeGetResponse } from '@malesia/json-schema';
import { getLocation, push } from 'connected-react-router';
import { all, call, put, select, takeLatest } from 'typed-redux-saga';
import { checkInvalidCredentials } from '../../../api/malesia/ApiResponseError';
import { logError } from '../../../utils/log';
import { getApiUserAccount } from '../../services/account';
import { getApiUserRoles } from '../../services/user';
import { appNotification } from '../App/appNotification';
import { selectRoot } from '../App/selectors';
import { allPublicRoutePaths } from '../Routes/allPublicRoutePaths';
import { selectUserLoginId, selectUserLoginIsAuthorized } from './selectors';
import { loginActions } from './slice';
import { loginModalActions } from 'app/modals/LoginModal/slice';
import { apiClientRequest } from 'app/services/ApiClient';
import { actions as loginFormActions } from 'app/sharedComponents/AppLoginForm/slice';

export function* handleExpireToken() {
    // Force logout
    yield* put(loginActions.logoutSuccess());
    yield* put(appNotification.expiredToken);
    // ToDo: Use next proposed code if it's correct
    // const router = yield* select(getRouter);
    // const location = yield* select(getLocation);
    const rootState = yield* select(selectRoot);
    const location = getLocation(rootState);

    if (rootState.router.action === 'PUSH') return;
    // ToDo: Where this saved location.state is used?
    // Maybe can remove state property?
    yield* put(push({ pathname: allPublicRoutePaths.login, state: { from: location } }));
}

function* getUserInfo() {
    const userInfo: UserMeGetResponse = yield* call(apiClientRequest, {
        requestId: 'userMe',
    });
    return userInfo;
}

export function* refreshUserInfo() {
    const userInfo = yield* call(getUserInfo);
    yield* put(loginActions.setUserInfo(userInfo));
}

export function* refreshUserAccount() {
    const userId = yield* select(selectUserLoginId);
    const userAccount = yield* call(getApiUserAccount, userId!);
    yield* put(loginActions.setUserAccount(userAccount));
}

function* refreshUserData() {
    try {
        const isAuthorized = yield* select(selectUserLoginIsAuthorized);
        if (!isAuthorized) {
            yield* put(loginActions.refreshUserDataSuccess());
            return;
        }

        const userInfo = yield* call(getUserInfo);
        const { userRoles, userAccount } = yield* all({
            userRoles: call(getApiUserRoles, userInfo.id!),
            userAccount: call(getApiUserAccount, userInfo.id!),
        });
        yield* put(loginActions.refreshUserDataSuccess({
            userInfo,
            userRoles,
            userAccount,
        }));
    }
    catch (error) {
        logError({
            error,
            target: 'UserLogin.refreshUserData',
        });
        yield* put(loginActions.refreshUserDataError());
    }
}

export function* refreshUserDataSaga() {
    yield* takeLatest(loginActions.refreshUserData, refreshUserData);
}

export function* login(action: ReturnType<typeof loginActions.login>) {
    try {
        const loginCredentials = action.payload;

        const result: AuthTokenPostResponse = yield* call(apiClientRequest, {
            requestId: 'authToken',
            requestPayload: loginCredentials,
            skipAutoLogout: true,
        });
        yield* put(loginFormActions.reset());
        yield* put(loginModalActions.closeModal());
        yield* put(loginActions.loginSuccess(result.token));
        yield* put(loginActions.refreshUserData());
    }
    catch (error) {
        logError({
            error,
            target: 'UserLogin.login.getAuthToken',
        });
        if (checkInvalidCredentials(error)) {
            yield* put(appNotification.incorrectLogin);
        }
        yield* put(loginActions.loginError());
    }
}

export function* loginSaga() {
    yield* takeLatest(loginActions.login, login);
}

export function* logout() {
    try {
        yield* call(apiClientRequest, {
            requestId: 'authTokenDelete',
        });
        yield* put(loginActions.logoutSuccess());
        yield* put(push(allPublicRoutePaths.home));
        window.location.reload();
    }
    catch (error) {
        logError({
            error,
            target: 'UserLogin.deleteAuthToken',
        });
        // We don't care about the result because it is not processable
    }
}

export function* userLogoutSaga() {
    yield* takeLatest(loginActions.logout, logout);
}
