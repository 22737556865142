import { PageContentBlock } from '@malesia/react-components/dist/src/components/Page/ContentBlock';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { AgentListFooterWithStore } from './components/AgentListFooterWithStore';
import { AgentListHeaderWithStore } from './components/AgentListHeaderWithStore';
import { AgentListWithStore } from './components/AgentListWithStore';
import { getAgentListSaga } from './saga';
import { actions, reducer, sliceKey } from './slice';

export const AgentListPage: SFC = () => {
    useInjectReducer({ actions, key: sliceKey, reducer });
    useInjectSaga({ key: sliceKey, saga: getAgentListSaga });

    return (
        <PageContentBlock>
            <AgentListHeaderWithStore />
            <AgentListWithStore />
            <AgentListFooterWithStore />
        </PageContentBlock>
    );
};
