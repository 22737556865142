import { BillingInformation, BillingFormData } from '@malesia/react-components/dist/src/components/Reservation/BillingInformation/BillingInformation';
import { BillingInformationLogin, BillingInformationLoginProps } from '@malesia/react-components/dist/src/components/Reservation/BillingInformation/Login';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import { pickBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createCustomPhoneNumberValidator } from '../../../../../utils/validation/createCustomPhoneNumberValidator';
import { getValidationFunction } from '../../../../../utils/validation/getValidationFunction';
import { reservationBasketActions } from '../../../../containers/ReservationBasket/reservationBasket.slice';
import { selectPublicCountryList } from '../../../../containers/SharedData/selectors';
import { UserLoginContainer } from '../../../../containers/UserLogin';
import { selectUserLoginAuthToken } from '../../../../containers/UserLogin/selectors';
import { useBillingFormConfig } from './useBillingFormConfig';
import { useCountrySelect } from './useCountrySelect';
import { useStepRequirementsForStepBillingInformation } from './useStepRequirementsForStepBillingInformation';

type StepBillingInformationProps = {
    handleStepSubmit: () => void,
};

export const StepBillingInformation: SFC<StepBillingInformationProps> = (props) => {
    const dispatch = useDispatch();

    const router = useHistory();
    const countryListData = useSelector(selectPublicCountryList);
    const authToken = useSelector(selectUserLoginAuthToken);

    const {
        config: billingFormConfig,
        configName,
    } = useBillingFormConfig();

    if (router) {
        router.location = { ...router.location };
    }

    const isStepFitRequirements = useStepRequirementsForStepBillingInformation();

    const { countryList } = useCountrySelect();

    const [phoneMobileMeta, setPhoneMobileMeta] = useState<{ dialCode?: string, countryCode?: string }>();
    const [phoneNumberFirstMeta, setPhoneNumberFirstMeta] = useState<{ dialCode?: string, countryCode?: string }>();
    const [phoneNumberSecondMeta, setPhoneNumberSecondMeta] = useState<{ dialCode?: string, countryCode?: string }>();

    // not sure about this way
    billingFormConfig.phoneMobile.onChangeCallback = (v, d: unknown) => {
        setPhoneMobileMeta(d as object);
    };
    billingFormConfig.phoneNumberFirst.onChangeCallback = (v, d: unknown) => {
        setPhoneNumberFirstMeta(d as object);
    };
    billingFormConfig.phoneNumberSecond.onChangeCallback = (v, d: unknown) => {
        setPhoneNumberSecondMeta(d as object);
    };

    const validate = useMemo(() => getValidationFunction(
        'file://malesiareisen.com/json/schema/project/react-front-app/pages/BookingFlightPage/Steps/StepBillingInformation/billingForm.json',
        [
            createCustomPhoneNumberValidator<BillingFormData>('/phoneMobile', phoneMobileMeta?.dialCode, phoneMobileMeta?.countryCode),
            createCustomPhoneNumberValidator<BillingFormData>('/phoneNumberFirst', phoneNumberFirstMeta?.dialCode, phoneNumberFirstMeta?.countryCode),
            createCustomPhoneNumberValidator<BillingFormData>('/phoneNumberSecond', phoneNumberSecondMeta?.dialCode, phoneNumberSecondMeta?.countryCode),
        ],
    ),
    [phoneMobileMeta, phoneNumberFirstMeta, phoneNumberSecondMeta],
    );

    const handleSubmit = (formData: BillingFormData) => {
        const countryList = countryListData!.items;
        const data = pickBy(formData, Boolean);
        dispatch(reservationBasketActions.setBillingInformation({
            ...data,
            country: data.country ? countryList?.find(country => country.code === data.country) : undefined,
        }));
        props.handleStepSubmit();
    };

    const LoginComponent = (props: BillingInformationLoginProps) => (
        !authToken && <BillingInformationLogin {...props} />
    ) || null;

    const UserLoginWrapped = () => (!!LoginComponent && (
        <UserLoginContainer
            LoginComponent={LoginComponent}
            onSubmit={() => {
                console.log('UserLoginContainer submit');
            }}
        />
    )) || null;

    return (
        <>
            {isStepFitRequirements && (
                <BillingInformation
                    key={configName}
                    countryList={countryList}
                    formConfig={billingFormConfig}
                    validator={validate}
                    isUserLoggedIn={!!authToken}
                    LoginComponent={UserLoginWrapped}
                    submit={handleSubmit}
                />
            )}
        </>
    );
};
