import { PayloadAction } from '@reduxjs/toolkit';
import { ErrorObject } from 'ajv';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { LoginFormState } from './types';

/**
 * The initial state
 */
export const initialState: LoginFormState = {
    formIsValid: false,
    formValidationErrors: [],
    formUntouchedFields: ['login', 'password'],
};

/**
 * State slice
 */
const loginFormSlice = createSlice({
    name: 'loginForm',
    initialState,
    reducers: {
        updateValidationStatus(state, action: PayloadAction<any>) {
            state.formIsValid = action.payload.formIsValid;
            state.formUntouchedFields = action.payload.formUntouchedFields;
            state.formValidationErrors = action.payload.formValidationErrors;
        },
        reset() {
            return initialState;
        },
        submitValidationError(state, action: PayloadAction<ErrorObject[]>) {
            state.formIsValid = false;
            state.formValidationErrors = action.payload;
        },
    },
});

export const { actions, reducer, name: sliceKey } = loginFormSlice;
