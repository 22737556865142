// noinspection UnnecessaryLocalVariableJS

import { BillingFormData } from '@malesia/react-components/dist/src/components/Reservation/BillingInformation/BillingInformation';
import { LegacyFormConfig } from '@malesia/react-components/dist/src/hooks/useForm';
import pickBy from 'lodash/pickBy';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectBillingInformation } from '../../../../containers/ReservationBasket/reservationBasket.selectors';
import { selectUserLoginUserInfo } from '../../../../containers/UserLogin/selectors';
import { UserInfo } from '../../../../containers/UserLogin/types';

export function useBillingFormConfig() {
    const userInfo: Partial<UserInfo> | undefined = useSelector(selectUserLoginUserInfo);

    const billingInfo = useSelector(selectBillingInformation);

    const data = useMemo(() => {
        //@ts-ignore
        const countryCode = billingInfo?.country?.code;
        return {
            ...billingInfo,
            country: countryCode,
        };
    }, [billingInfo]);

    const configWithBilling = useMemo(() => createConfig(data ?? {}), [data]);

    const configWithUserInfo = useMemo(() => {
        const billingFormData = {
            email: userInfo?.email,
            salutation: userInfo?.salutation,
            lastName: userInfo?.lastName,
            firstName: userInfo?.firstName,
            phoneMobile: userInfo?.phoneMobile?.replace(' ', ''),
            phoneNumberFirst: userInfo?.phoneNumberFirst?.replace(' ', ''),
            phoneNumberSecond: userInfo?.phoneNumberSecond?.replace(' ', ''),
            street: userInfo?.street,
            zip: userInfo?.zip,
            city: userInfo?.city,
            country: userInfo?.country?.code,
            birthday: userInfo?.birthday,
            company: userInfo?.company,
        };
        const sanitizedFormData = pickBy<BillingFormData>(billingFormData, Boolean);
        const config = createConfig(sanitizedFormData);
        return config;
    }, [userInfo]);

    if (billingInfo) return { config: configWithBilling, configName: 'configWithBilling' };
    if (!userInfo) return { config: getDefaultConfig(), configName: 'defaultConfig' };

    return {
        config: configWithUserInfo,
        configName: 'configWithUserInfo',
    };
}

function getDefaultConfig(): LegacyFormConfig<BillingFormData> {
    return {
        salutation: { initialValue: '' },
        company: { initialValue: '' },
        lastName: { initialValue: '', required: true },
        firstName: { initialValue: '', required: true },
        email: { initialValue: '', required: true },
        street: { initialValue: '', required: true },
        zip: { initialValue: '', required: true },
        city: { initialValue: '', required: true },
        country: { initialValue: 'CH', required: true },
        // isSendSms: { initialValue: false },
        birthday: { initialValue: '', required: true },
        phoneMobile: { initialValue: '', required: true },
        phoneNumberFirst: { initialValue: '' },
        phoneNumberSecond: { initialValue: '' },
        password: { initialValue: '' },
        passwordConfirm: { initialValue: '' },
    };
}

function createConfig(values) {
    const formConfig = getDefaultConfig();
    for (const [key, value] of Object.entries(values)) {
        if (value && key in formConfig) formConfig[key].initialValue = value;
    }
    return formConfig;
}
