import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { ReservationHistory } from './ReservationHistory';
import { getReservationSnapshotListSaga } from './saga';
import { selectLoadingStatus } from './selectors';
import { actions, reducer, sliceKey } from './slice';

export const ReservationHistoryPage: SFC<object, { result: 'optional' }> = () => {
    useInjectReducer({ key: sliceKey, reducer });
    useInjectSaga({
        key: `${sliceKey}List`,
        saga: getReservationSnapshotListSaga,
    });

    const dispatch = useDispatch();
    const routeParams = useParams<{reservationId: string}>();

    const loadingStatus = useSelector(selectLoadingStatus);

    useEffect(() => {
        const reservationId = routeParams.reservationId;
        dispatch(actions.getReservationSnapshotList({ reservationId }));
    }, [dispatch, routeParams.reservationId]);

    return (
        loadingStatus === 'loaded' ? <ReservationHistory /> : null
    );
};
