import { put, select, takeEvery } from 'typed-redux-saga';
import { logError } from '../../../utils/log';
import { createReservation } from '../../pages/ReservationNewPage/store/loadEditReservation.saga';
import { getApiErrorMessage } from '../../services/ApiClient';
import { getApiReservationById, splitApiReservation } from '../../services/Reservation';
import { splitReservationNotifications } from './notifications';
import { selectSplitReservationState } from './selectors';
import { splitReservationActions } from './slice';
import { SplitReservationState } from './types';

function* openPopup(action: ReturnType<typeof splitReservationActions.openPopup>) {
    try {
        const { reservationId } = action.payload;

        const response = yield* getApiReservationById(reservationId);
        const reservation = createReservation(response);
        const passengers = reservation.passengers.map(x => x.info);
        yield* put(splitReservationActions.openPopupSuccess(passengers));
    }
    catch (error) {
        logError({
            error,
            target: 'SplitReservation.openPopup',
        });
        yield* put(splitReservationActions.openPopupError());
        const message = getApiErrorMessage(error);
        yield* put(splitReservationNotifications.unknownError(message));
    }
}

export function* openPopupSaga() {
    yield* takeEvery(splitReservationActions.openPopup, openPopup);
}

function* splitReservation(action: ReturnType<typeof splitReservationActions.splitReservation>) {
    try {
        const splitReservationState: SplitReservationState = yield* select(selectSplitReservationState);
        const { reservationId } = splitReservationState;
        const { passengerId, callback } = action.payload;

        yield* splitApiReservation({
            reservationId,
            passengerId,
        });
        yield* put(splitReservationActions.splitReservationSuccess());
        yield* put(splitReservationNotifications.reservationSplitSuccessfully);
        callback();
    }
    catch (error) {
        logError({
            error,
            target: 'SplitReservation.splitReservation',
        });
        yield* put(splitReservationActions.splitReservationError());
        const message = getApiErrorMessage(error);
        yield* put(splitReservationNotifications.unknownError(message));
    }
}

export function* splitReservationSaga() {
    yield* takeEvery(splitReservationActions.splitReservation, splitReservation);
}
