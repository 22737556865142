import { FlightCompany } from '@malesia/json-schema';
import { createActionsColumn } from '@malesia/react-components/dist/src/components/Table/columns/actionsColumn';
import * as layouts from './actions';

export const flightCompanyActionsColumn = createActionsColumn<FlightCompany, typeof layouts>({
    columnsNumber: 2,
    rowsNumber: 1,
    layouts,
    order: [
        'editFlightCompany',
    ],
});
export type FlightCompanyActionsConfig = Parameters<typeof flightCompanyActionsColumn>[0];
