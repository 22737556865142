import { Manager } from '@malesia/json-schema';
import { ManagerFormType } from '@malesia/react-components/dist/src/components/Manager/Edit';
import { LegacyFormConfig, readonlyFormConfig } from '@malesia/react-components/dist/src/hooks/useForm';
import { useMemo } from 'react';
import { managerEditPermissions } from '../../permissions/adminPages/user/managerEdit';
import { UserPermissions } from '../../permissions/userPermissions';
import { useUserPermissions } from 'app/permissions/useUserPermissions';

type Config = LegacyFormConfig<ManagerFormType>;
const createConfig = (
    managerData: Manager | undefined,
): Config => ({
    id: { initialValue: managerData?.id },
    email: { initialValue: managerData?.email ?? '', modifiers: ['required'] },
    password: { initialValue: '' },
    passwordConfirm: { initialValue: '' },
    displayName: { initialValue: managerData?.displayName ?? '', modifiers: ['required'] },
    country: { initialValue: managerData?.country, modifiers: ['required'] },
    language: { initialValue: managerData?.language, modifiers: ['required'] },
    creditLimitTotalAmount: { initialValue: managerData?.creditLimitTotalAmount },
    isActive: { initialValue: managerData?.isActive },
});

const configurePermissions = (
    config: Config,
    userPermissions: UserPermissions,
): Config => {
    const updateForm = userPermissions.has(managerEditPermissions.updateForm);

    let result = config;
    if (!updateForm) {
        result = readonlyFormConfig(result);
    }
    return result;

};

export const useManagerFormConfig = (
    managerData: Manager | undefined,
): Config => {
    const userPermissions = useUserPermissions();
    const result = useMemo<Config>(() => {
        const config = createConfig(managerData);
        return configurePermissions(config, userPermissions);
    }, [managerData, userPermissions]);
    return result;
};
