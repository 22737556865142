import {
    ApiCallReservationFilters,
    Reservation, ReservationPaginatedList,
    ReservationPassengerPaginatedList,
} from '@malesia/json-schema';
import { NotificationFormData } from '@malesia/react-components/dist/src/components/Reservation/List/Notification';
import { call } from 'typed-redux-saga';
import { reservationListPermissions } from '../../permissions/adminPages/reservation/reservationList';
import { selectUserPermissions } from '../../permissions/selectUserPermissions';
import { apiClientRequest } from '../ApiClient';
import { ReservationPostRequest } from './utils';

export function* getApiReservationList(requestPayload: ApiCallReservationFilters) {
    const response: ReservationPaginatedList = yield* call(apiClientRequest, {
        requestId: 'reservationList',
        requestPayload,
    });
    return response;
}
export function* getApiMyReservationList(requestPayload: ApiCallReservationFilters) {
    const response: ReservationPaginatedList = yield* call(apiClientRequest, {
        requestId: 'userReservationList',
        requestPayload,
    });
    return response;
}

export function* getApiPermittedReservationList(requestPayload: ApiCallReservationFilters) {
    const userPermissions = yield* selectUserPermissions();

    if (userPermissions.has(reservationListPermissions.getFullList)) {
        return yield* getApiReservationList(requestPayload);
    }
    if (userPermissions.has(reservationListPermissions.getMyList)) {
        return yield* getApiMyReservationList(requestPayload);
    }
    throw new Error('Can not get reservation list without permissions');
}

export function* createApiReservation(data: ReservationPostRequest) {
    const response: Reservation = yield* call(apiClientRequest, {
        requestId: 'reservationPost',
        requestPayload: data,
    });
    return response;
}

export function* getApiReservationById(reservationId: string | number) {
    const response: Reservation = yield* call(apiClientRequest, {
        requestId: 'reservation',
        uriParams: {
            id: reservationId,
        },
    });
    return response;
}

export function* updateApiReservation(id: number, requestPayload: ReservationPostRequest) {
    const response: Reservation = yield* call(apiClientRequest, {
        requestId: 'reservationPut',
        requestPayload,
        uriParams: {
            id,
        },
    });
    return response;
}

export function* cancelApiReservation(id: number, fee: number) {
    const response: Reservation = yield* call(apiClientRequest, {
        requestId: 'reservationCancel',
        requestPayload: { fee },
        uriParams: {
            id,
        },
    });

    return response;
}

export function* sendApiReservationNotification(idList: number[], notificationData: NotificationFormData) {
    const response: Reservation = yield* call(apiClientRequest, {
        requestId: 'reservationListNotificationPut',
        requestPayload: { ids: idList, content: notificationData.content },
    });

    return response;
}

export function* getApiPassengerList(query: string, userId?: number) {
    const response: ReservationPassengerPaginatedList = yield* call(apiClientRequest, {
        requestId: 'reservationPassengerList',
        query: {
            query,
            ownerId: userId,
        },
    });

    return response.items;
}

export type SplitReservationOptions = {
    reservationId: number,
    passengerId: number,
};
export type SplitReservationResponse = {
    reservationIdList: [number, number],
};
export function* splitApiReservation(options: SplitReservationOptions) {
    const { passengerId, reservationId } = options;
    const response: SplitReservationResponse = yield* call(apiClientRequest, {
        requestId: 'splitReservation',
        requestPayload: {
            passengerId,
        },
        uriParams: {
            id: reservationId,
        },
    });
    return response;
}
