import { FrontPageContentBlock } from '@malesia/react-components/dist/src/components/Front/Page/ContentBlock';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { BookingFlightPageProps, BookingFlightPage, RouteParams } from '../BookingFlightPage';

export type BookingFlightRouterPageProps = RouteComponentProps<RouteParams>;

export const BookingFlightRouterPage: SFC<BookingFlightRouterPageProps> = (props) => {
    const bookingFlightPageProps: BookingFlightPageProps = {
        ...props,
        isPageTitleHide: true,
    };
    return (
        <FrontPageContentBlock modificators={['booking']}>
            <BookingFlightPage {...bookingFlightPageProps} />
        </FrontPageContentBlock>
    );
};
