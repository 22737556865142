import { RateTariffType, Airport, PublicFlightListItem } from '@malesia/json-schema';
import { fMultilanguage } from '@malesia/react-components/dist/src/components/MultiLanguage';
import { rateTypeConfigMap } from '@malesia/react-components/dist/src/components/Reservation/PriceVariant/rateTypeMapper';
import { TariffGroupCode, tariffGroupIdToCode } from '@malesia/react-components/dist/src/components/TariffsTable/tariffGroupMapper';
import { DATE_FORMAT, TIME_FORMAT } from '@malesia/react-components/dist/src/utils/dateTimeFormat';
import moment from 'moment';
import { DEFAULT_RATE_TARIFF_TYPE, rateIdToTariffType } from '../../app/pages/BookingFlightPage/utils/StepFlight/rate';
import { FlightVariant } from './selectedFlight';

type CommonType = {
    airportsTitle: string,
    airportsFromToByName: string,
    tariffName: string,
    flightDate: string,
    flightTime: string,
    flightNumber: string,
};
const createCommon = (
    selectedFlight: PublicFlightListItem | undefined,
    tariffName: string,
    locale: string,
): CommonType => {
    const { origin, destination, number, departure } = selectedFlight || {};

    const getAirportName = (airport: Airport) => fMultilanguage(airport.name, locale);
    const airportsTitle = (origin && destination) ? `${origin.code} - ${destination.code}` : '';
    const airportsFromToByName = (origin && destination) ? `${getAirportName(origin)} - ${getAirportName(destination)}` : '';
    const flightDate = (departure && moment.parseZone(departure).format(DATE_FORMAT)) || '';
    const flightTime = (departure && moment.parseZone(departure).format(TIME_FORMAT)) || '';
    const flightNumber = number || '';

    return {
        airportsTitle,
        airportsFromToByName,
        tariffName,
        flightDate,
        flightTime,
        flightNumber,
    };
};

export type FlightRowPartsType = CommonType & {
    rateTariffType: RateTariffType,
};
export const getFlightRowParts = (
    data: FlightVariant | undefined,
    locale: string,
): FlightRowPartsType => {
    const { flight, price } = data || {};

    const tariffId = price?.tariff?.id;

    if (tariffId && Object.keys(rateIdToTariffType).indexOf(`${tariffId}`) === -1) {
        console.error(
            `[MALESIA ERROR] Tariff with id '${tariffId}' is not configured in the app.`,
        );
    }

    const rateTariffType = tariffId
        ? rateIdToTariffType[tariffId]
        : DEFAULT_RATE_TARIFF_TYPE;

    const tariffName = tariffId ? rateTypeConfigMap[rateIdToTariffType[tariffId]].title : '';

    return {
        ...createCommon(flight, tariffName, locale),
        rateTariffType,
    };
};

export type FlightLabelsParts = CommonType & {
    tariffGroupCode: TariffGroupCode,
};
export const getFlightLabelParts = (
    data: FlightVariant,
    locale: string,
): FlightLabelsParts => {
    const { flight, price } = data;

    const tariffGroupId = price.tariff?.group.id ?? 0;
    const tariffGroupCode = tariffGroupIdToCode[tariffGroupId];

    if (!tariffGroupCode) {
        console.error(
            `[MALESIA ERROR] TariffGroup with id '${tariffGroupId}' is not configured in the app.`,
        );
    }

    const tariffName = price.tariff?.name?.[locale] ?? '';

    return {
        ...createCommon(flight, tariffName, locale),
        tariffGroupCode,
    };
};
