import { AuthRecoverPasswordPostRequest } from '@malesia/json-schema';
import { PayloadAction } from '@reduxjs/toolkit';
import { ErrorObject } from 'ajv';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { RecoverPasswordModalState } from './types';

export const initialState: RecoverPasswordModalState = {
    isOpened: false,
    formIsValid: false,
    formIsLoading: false,
    formValidationErrors: [],
    formResultIsSuccessful: false,
    formUntouchedFields: ['email'],
};

const recoverPasswordModalSlice = createSlice({
    name: 'recoverPasswordModal',
    initialState,
    reducers: {
        openModal(state) {
            state.isOpened = true;
        },
        closeModal(state) {
            return initialState;
        },
        updateValidationStatus(state, action: PayloadAction<any>) {
            state.formIsValid = action.payload.formIsValid;
            state.formUntouchedFields = action.payload.formUntouchedFields;
            state.formValidationErrors = action.payload.formValidationErrors;
        },
        submitData(state, action: PayloadAction<AuthRecoverPasswordPostRequest>) {
            state.formIsLoading = true;
        },
        submitSuccess(state) {
            state.formIsLoading = false;
            state.formResultIsSuccessful = true;
        },
        submitValidationError(state, action: PayloadAction<ErrorObject[]>) {
            state.formIsLoading = false;
            state.formIsValid = false;
            state.formValidationErrors = action.payload;
        },
        submitFailed(state) {
            state.formIsLoading = false;
        },
    },
});

export const { actions: recoverPasswordModalActions, reducer, name: sliceKey } = recoverPasswordModalSlice;
