import { LoadingTable } from '@malesia/react-components/dist/src/components/LoadingOverlay/Table';
import { Table, SortingDataType } from '@malesia/react-components/dist/src/components/Table';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectDirty,
    selectList,
    selectLoading,
    selectSorting,
} from '../store/selectors';
import { balanceLogActions } from '../store/slice';
import { useTableColumns } from '../tableColumns';

export const AccountingBalanceLogWithStore: SFC = () => {
    const tableColumns = useTableColumns();

    const dispatch = useDispatch();

    const isLoading = useSelector(selectLoading);
    const isDirty = useSelector(selectDirty);
    const list = useSelector(selectList);
    const sort = useSelector(selectSorting);

    const setSort = useCallback((data: SortingDataType) => {
        dispatch(balanceLogActions.setSorting(data));
    }, [dispatch]);

    return (
        <LoadingTable
            isLoading={isLoading}
            isDirty={isDirty}
        >
            <Table
                columns={tableColumns}
                data={list.items}
                setSort={setSort}
                defaultSortBy={sort.sortBy}
                defaultSortOrder={sort.sortOrder}
                manualSortBy={true}
            />
        </LoadingTable>
    );
};
