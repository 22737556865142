import {
    AllotmentInvoiceListFilter,
    allotmentInvoiceListFilterConfig,
    AllotmentInvoiceListFilterProps,
    AllotmentInvoiceFilterData,
} from '@malesia/react-components/dist/src/components/Allotment/InvoiceList/Filter';
import { checkSubPath, useValueForm, ValueConfig, ValuePath } from '@malesia/react-components/dist/src/hooks/valueForm';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAgentList, selectAgentQuery } from '../../../containers/AuxiliaryData/selectors';
import { auxiliaryActions } from '../../../containers/AuxiliaryData/slice';
import { selectUserLoginUserInfo } from '../../../containers/UserLogin/selectors';
import { allotmentInvoiceListPermissions } from '../../../permissions/adminPages/allotment/allotmentInvoiceList';
import { useUserPermissions } from '../../../permissions/useUserPermissions';
import { selectAgentBalance, selectFilterData } from '../store/selectors';
import { actions } from '../store/slice';

export const AllotmentInvoiceListFilterWithStore: SFC = () => {
    const dispatch = useDispatch();
    const userPermissions = useUserPermissions();

    const hashRef = useRef({});
    const value = useSelector(selectFilterData);
    const agentQuery = useSelector(selectAgentQuery);
    const agentList = useSelector(selectAgentList);
    const agentBalance = useSelector(selectAgentBalance);
    const filterData = useSelector(selectFilterData);
    const myUser = useSelector(selectUserLoginUserInfo);

    useEffect(() => {
        dispatch(actions.getAgentBalance(filterData.agent?.id));
        // disable filterData dependency
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (!userPermissions.has(allotmentInvoiceListPermissions.getList)) return;

        dispatch(auxiliaryActions.searchAgentList(''));
    }, [dispatch, userPermissions]);

    const {
        field,
    } = useValueForm({
        value,
        config: [allotmentInvoiceListFilterConfig, (): ValueConfig<AllotmentInvoiceFilterData> => ({
            shape: {
                agent: {
                    items: agentList,
                    query: agentQuery,
                    setQuery: (text) => {
                        if (!userPermissions.has(allotmentInvoiceListPermissions.getList)) return;
                        dispatch(auxiliaryActions.searchAgentList(text));
                    },
                    hidden: !userPermissions.has(allotmentInvoiceListPermissions.getList),
                },
            },
        })],
        onChange: (v, path) => {
            dispatch(actions.setFilterData(v));

            const agentPath: ValuePath<AllotmentInvoiceFilterData> = ['agent'];
            if (checkSubPath(agentPath, path)) {
                dispatch(actions.getAgentBalance(v.agent?.id));
            }
        },
        hash: hashRef.current,
    });

    const allotmentListFilterProps: AllotmentInvoiceListFilterProps = {
        field,
        reset: () => {
            dispatch(actions.resetFilterData());
            if (userPermissions.has(allotmentInvoiceListPermissions.getList)) {
                dispatch(auxiliaryActions.searchAgentList(''));
            }
            hashRef.current = {};
        },
        balance: {
            agentName: (
                userPermissions.has(allotmentInvoiceListPermissions.getList)
                    ? value.agent?.displayName
                    : myUser?.displayName
            ),
            outstandingAmount: agentBalance,
        },
    };

    return (
        <AllotmentInvoiceListFilter {...allotmentListFilterProps} />
    );
};
