import { PopupConfirm } from '@malesia/react-components/dist/src/components/Popup/Confirm';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectAircraftChangeConfirmationPassengers, selectShowAircraftChangeConfirmationPopup } from '../store/selectors';
import { actions } from '../store/slice';

export const AircraftChangingConfirmPopup = () => {
    const dispatch = useDispatch();

    const showAircraftChangeConfirmationPopup = useSelector(selectShowAircraftChangeConfirmationPopup);
    const aircraftChangeConfirmationPassengers = useSelector(selectAircraftChangeConfirmationPassengers);

    return (
        <PopupConfirm
            isOpened={showAircraftChangeConfirmationPopup}
            onClose={() => dispatch(actions.aksAircraftChangeConfirmationCancel())}
            onConfirm={() => dispatch(actions.aksAircraftChangeConfirmationConfirm())}
            confirmMessage={
                <FormattedMessage
                    id='front-app/FlightEditPage:ConfirmAircraftChange'
                    defaultMessage='Aircraft template has changed. All assigned seats will be removed.'
                />
            }
        >
            <div>
                <ul>
                    {aircraftChangeConfirmationPassengers.map((item, idx) => (
                        <li key={idx}>{item.lastName}, {item.firstName} ({item.seatLabel})</li>
                    ))}
                </ul>
            </div>
        </PopupConfirm>
    );
};
