import { TravelType } from '@malesia/react-components/dist/src/components/Reservation/reservation-types';
import { AdminStepTravelDateForm } from '@malesia/react-components/dist/src/components/Reservation/StepTravelDate/AdminStepTravelDate';
import { LegacyFormConfig } from '@malesia/react-components/dist/src/hooks/useForm';
import { reservationValidationText } from '@malesia/react-components/dist/src/utils/reservation';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AirportWithLocaleName } from '../../../../../types';
import { reservationNotifications } from '../../../../../utils/reservation/notifyReservationError';
import { getValidationFunction } from '../../../../../utils/validation/getValidationFunction';
import { selectAvailableAirports, selectMapAvailabilityToAirports } from '../../../../containers/SharedData/selectors';
import { sharedActions } from '../../../../containers/SharedData/slice';
import {
    selectPassengersAges,
    selectTravelInfo,
    selectTravelType,
} from '../../store/selectors';

type FormFields = keyof LegacyFormConfig<AdminStepTravelDateForm>;

const getConfigForTab = (
    tab: TravelType,
    formConfig: LegacyFormConfig<AdminStepTravelDateForm>,
): LegacyFormConfig<AdminStepTravelDateForm> => {
    const requiredFieldsForTab: Record<TravelType, FormFields[]> = {
        'return': ['returning'],
        'one-way': [],
        'other-return': ['returning', 'otherToAirportCode', 'otherFromAirportCode'],
    };
    return addRequiredFieldsToConfig(requiredFieldsForTab[tab], formConfig);
};

const addRequiredFieldsToConfig = (
    fields: FormFields[],
    formConfig: LegacyFormConfig<AdminStepTravelDateForm>,
): LegacyFormConfig<AdminStepTravelDateForm> => {
    const entries = (Object.entries(formConfig) as [keyof AdminStepTravelDateForm, any])
        .map(([key, value]) => {
            if (fields.includes(key)) {
                return [key, { ...value, required: true }];
            }
            else {
                return [key, value];
            }
        });
    return Object.fromEntries(entries);
};

const schemas: Record<TravelType, string> = {
    'return': 'file://malesiareisen.com/json/schema/project/react-front-app/pages/reservation/add/travelDates__return.json',
    'one-way': 'file://malesiareisen.com/json/schema/project/react-front-app/pages/reservation/add/travelDates__one-way.json',
    'other-return': 'file://malesiareisen.com/json/schema/project/react-front-app/pages/reservation/add/travelDates__other-return.json',
};

export const useAdminStepTravelDateForm = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(sharedActions.getAirportList());
    }, [dispatch]);

    const airportList = useSelector(selectAvailableAirports);

    const mapAirportsListToSelectItem = (airportList: AirportWithLocaleName[]) => airportList.map(airport => ({
        value: airport.code,
        name: airport.name,
    }));

    const airports = useMemo(() => mapAirportsListToSelectItem(airportList), [airportList]);

    const availabilityMap = useSelector(selectMapAvailabilityToAirports);
    const availabilityMapRef = useRef(availabilityMap);

    useEffect(() => {
        availabilityMapRef.current = availabilityMap;
    }, [availabilityMap]);

    const [fromList, setFromList] = useState(airports);
    const [toList, setToList] = useState(airports);
    const [otherFromList, setOtherFromList] = useState(airports);
    const [otherToList, setOtherToList] = useState(airports);

    const getAirportAvailabilityList = (code: string) => {
        const availabilityMap = availabilityMapRef.current;
        const items = availabilityMap[code] ?? [];
        return mapAirportsListToSelectItem(items);
    };

    const onChangeFromAirportCode = (code: string) => {
        const airports = getAirportAvailabilityList(code);
        setToList(airports);
    };
    const onChangeOtherFromAirportCode = (code: string) => {
        const airports = getAirportAvailabilityList(code);
        setOtherToList(airports);
    };

    // AdminStepTravelDateForm
    const initTravelType = useSelector(selectTravelType);

    const [travelType, setTravelType] = useState<TravelType>(initTravelType);

    const validate = useMemo(() => {
        switch (travelType) {
            case 'return':
                return getValidationFunction<AdminStepTravelDateForm>(schemas['return']);
            case 'one-way':
                return getValidationFunction<AdminStepTravelDateForm>(schemas['one-way']);
            case 'other-return':
                return getValidationFunction<AdminStepTravelDateForm>(schemas['other-return']);
            default:
                throw new Error('unknown travel type');
        }
    }, [travelType]);

    const travelInfo = useSelector(selectTravelInfo);
    const passengersAges = useSelector(selectPassengersAges);

    const formConfig: LegacyFormConfig<AdminStepTravelDateForm> = {
        fromAirportCode: {
            initialValue: travelInfo.outbound.airports?.originAirportCode ?? 'ZRH',
            required: true,
            onChangeCallback: onChangeFromAirportCode,
        },
        toAirportCode: { initialValue: travelInfo.outbound.airports?.destinationAirportCode ?? 'PRN', required: true },
        otherFromAirportCode: { initialValue: travelInfo.return.airports?.originAirportCode ?? '', onChangeCallback: onChangeOtherFromAirportCode },
        otherToAirportCode: { initialValue: travelInfo.return.airports?.destinationAirportCode ?? '' },
        departing: { initialValue: travelInfo.outbound.date ?? '', required: true },
        returning: { initialValue: travelInfo.return.date ?? '' },
        adults: { initialValue: passengersAges.adult },
        children: { initialValue: passengersAges.child },
        infants: { initialValue: passengersAges.infant },
    };

    const config = useMemo(() => (
        getConfigForTab(travelType, formConfig)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [
        travelType,
        travelInfo.outbound.date, travelInfo.return.date,
        passengersAges.adult, passengersAges.child, passengersAges.infant,
    ]);

    useEffect(() => {
        setFromList(airports);
        setToList(getAirportAvailabilityList(config.fromAirportCode.initialValue));
        setOtherFromList(airports);
        setOtherToList(getAirportAvailabilityList(config.otherFromAirportCode.initialValue));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [airports]);

    const validator = (formData: Partial<AdminStepTravelDateForm>) => {
        const validationResult = validate(formData);
        const isInfantError = (formData.infants && !formData.adults)
      || ((formData.infants && formData.adults) && (formData.infants > formData.adults));
        if (isInfantError) {
            validationResult.infants = [{
                message: 'infant error',
                messageId: 'infant error messageId',
                values: {},
            }];
            dispatch(reservationNotifications.customError(reservationValidationText.tooManyInfants));
        }
        return validationResult;
    };

    return {
        validator,
        config,
        setTravelType,
        travelType,
        airportFromList: fromList,
        airportToList: toList,
        otherAirportFromList: otherFromList,
        otherAirportToList: otherToList,
    };
};
