import { Option } from '@malesia/json-schema';
import { optionColumns } from '@malesia/react-components/dist/src/components/Rate/Option/List/tableColumns';
import { TableColumn } from '@malesia/react-components/dist/src/components/Table';
import { useMemo } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { allAdminRoutePaths } from '../../containers/Routes/allAdminRoutePaths';
import { optionListPermissions } from '../../permissions/adminPages/rate/optionList';
import { useUserPermissions } from '../../permissions/useUserPermissions';

export const useTableColumns = (): TableColumn<Option>[] => {
    const userPermissions = useUserPermissions();
    const history = useHistory();

    const result = useMemo(() => optionColumns({
        actions: {
            editOption: {
                click: (option) => {
                    const path = generatePath(allAdminRoutePaths.rateOptionEdit, {
                        optionId: option.id!,
                    });
                    history.push(path);
                },
                notAllowed: !userPermissions.has(optionListPermissions.editLink),
            },
        },
    }), [
        userPermissions,
        history,
    ]);

    return result;
};
