import { PaymentFormData, PaymentFormMethod } from '@malesia/react-components/dist/src/components/Payment/Form';
import { useDispatch, useSelector } from 'react-redux';
import { paymentNotifications } from './store/notification';
import { selectOwnerAvailableAccountMoney, selectPriceToPay } from './store/selectors';

export const useValidatePaymentForm = () => {
    const availableAccountMoney = useSelector(selectOwnerAvailableAccountMoney);
    const priceToPay = useSelector(selectPriceToPay);
    const dispatch = useDispatch();

    return (data: PaymentFormData, transferTotal: number): boolean => {
        if (priceToPay < transferTotal) {
            dispatch(paymentNotifications.bigPartialAmount);
            return false;
        }

        const methodValidators: Record<PaymentFormMethod, () => boolean> = {
            cash: () => {
                if (data.cash) return true;
                dispatch(paymentNotifications.notSelectedCashAccount);
                return false;
            },
            bank: () => {
                if (data.bank) return true;
                dispatch(paymentNotifications.notSelectedBankAccount);
                return false;
            },
            datatrans: () => true,
            transfer: () => {
                if (data.transfer) return true;
                dispatch(paymentNotifications.notSelectedTransferAccount);
                return false;
            },
            account: () => {
                if (availableAccountMoney < transferTotal) {
                    dispatch(paymentNotifications.notEnoughAmountOnAccount);
                    return false;
                }
                return true;
            },
        };
        const defaultValidator = () => {
            dispatch(paymentNotifications.unexpectedPaymentMethod);
            return false;
        };
        const validate = methodValidators[data.paymentMethod ?? ''] ?? defaultValidator;

        return validate();
    };
};
