import { Flight } from '@malesia/json-schema';
import { AircraftSeatsPlanSection } from '@malesia/react-components/dist/src/components/AircraftSeatsPlan';
import { checkSeatAbsent } from '@malesia/react-components/dist/src/components/AircraftSeatsPlan/config';
import { DATETIME_FORMAT } from '@malesia/react-components/dist/src/utils/dateTimeFormat';
import moment from 'moment-timezone';

export const isNeedToAskNotifying = (first: Flight, second: Flight): boolean => {
    const standardize = (value?: string) => (
        value ? moment.parseZone(value).format(DATETIME_FORMAT) : undefined
    );
    if (standardize(first.departure) !== standardize(second.departure)) return true;
    return false;
};

export const isNeedToAskAircraftConfirmation = (oldFlightData: Flight, newFlightData: Flight): boolean => {
    return oldFlightData.aircraftTemplate?.id !== newFlightData.aircraftTemplate?.id;
};

export const checkIsSeatLabelValid = (seatLabel: string) => {
    return /^([0-9]+)([A-Z]+)$/.test(seatLabel);
};

export const getSeatIndexes = (sections: AircraftSeatsPlanSection[], seatLabel: string) => {
    const matches = seatLabel.match(/^([0-9]+)([A-Z]+)$/);
    if (matches) {
        const row = matches[1];
        const column = matches[2];

        for (const sectionIndex in sections) {
            if (sections[sectionIndex].rowLabels.includes(row)) {
                const rowIndex = sections[sectionIndex].rowLabels.indexOf(row);
                const columnIndex = sections[sectionIndex].columnLabels.indexOf(column);

                return { sectionIndex: sectionIndex as unknown as number, rowIndex, columnIndex };
            }
        }
    }

    return { };
};

export const getNeighborSeatLabels = (
    section: AircraftSeatsPlanSection,
    rowIndex: number,
    columnIndex: number,
    neighbors: string[],
) => {
    if (checkSeatAbsent(section.seats[rowIndex][columnIndex])) {
        return [];
    }

    // check top neighbors
    if (
        section.seats?.[rowIndex - 1]?.[columnIndex] !== undefined
        && !checkSeatAbsent(section.seats[rowIndex - 1][columnIndex])
    ) {
        const topNeighborLabel = `${section.rowLabels[rowIndex - 1]}${section.columnLabels[columnIndex]}`;

        if (!neighbors.includes(topNeighborLabel)) {
            neighbors.push(topNeighborLabel);

            const topNeighborNeighbors = getNeighborSeatLabels(
                section, rowIndex - 1, columnIndex, neighbors,
            );
            neighbors.push(...topNeighborNeighbors);
        }
    }
    // check bottom neighbors
    if (
        section.seats?.[rowIndex + 1]?.[columnIndex] !== undefined
        && !checkSeatAbsent(section.seats[rowIndex + 1][columnIndex])
    ) {
        const bottomNeighborLabel = `${section.rowLabels[rowIndex + 1]}${section.columnLabels[columnIndex]}`;

        if (!neighbors.includes(bottomNeighborLabel)) {
            neighbors.push(bottomNeighborLabel);

            const bottomNeighborNeighbors = getNeighborSeatLabels(
                section, rowIndex + 1, columnIndex, neighbors,
            );
            neighbors.push(...bottomNeighborNeighbors);
        }
    }
    // check left neighbors
    if (
        section.seats?.[rowIndex]?.[columnIndex - 1] !== undefined
        && !checkSeatAbsent(section.seats[rowIndex][columnIndex - 1])
    ) {
        const leftNeighborLabel = `${section.rowLabels[rowIndex]}${section.columnLabels[columnIndex - 1]}`;

        if (!neighbors.includes(leftNeighborLabel)) {
            neighbors.push(leftNeighborLabel);

            const leftNeighborNeighbors = getNeighborSeatLabels(
                section, rowIndex, columnIndex - 1, neighbors,
            );
            neighbors.push(...leftNeighborNeighbors);
        }
    }
    // check right neighbors
    if (
        section.seats?.[rowIndex]?.[columnIndex + 1] !== undefined
        && !checkSeatAbsent(section.seats[rowIndex][columnIndex + 1])
    ) {
        const rightNeighborLabel = `${section.rowLabels[rowIndex]}${section.columnLabels[columnIndex + 1]}`;

        if (!neighbors.includes(rightNeighborLabel)) {
            neighbors.push(rightNeighborLabel);

            const rightNeighborNeighbors = getNeighborSeatLabels(
                section, rowIndex, columnIndex + 1, neighbors,
            );
            neighbors.push(...rightNeighborNeighbors);
        }
    }

    // TODO rework with without excluding doubles
    return neighbors.reduce((carry, current) => {
        if (!carry.includes(current)) {
            carry.push(current);
        }
        return carry;
    }, [] as string[]);
};
