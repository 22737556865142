import { useInjectReducer, useInjectSaga } from '../../../utils/redux-injectors';
import { downloadInvoicePdfSaga, downloadOverviewPdfSaga } from './saga';
import { reservationPdfActions, reservationPdfReducer, reservationPdfSliceKey } from './slice';

export const useInjectReservationPdf = () => {
    useInjectReducer({
        actions: reservationPdfActions,
        key: reservationPdfSliceKey,
        reducer: reservationPdfReducer,
    });
    useInjectSaga({
        key: `${reservationPdfSliceKey}downloadOverviewPdf`,
        saga: downloadOverviewPdfSaga,
    });
    useInjectSaga({
        key: `${reservationPdfSliceKey}downloadInvoicePdf`,
        saga: downloadInvoicePdfSaga,
    });
};
