import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getQueryParameters } from '../../../utils/uriUtils';
import { auxiliaryActions } from '../../containers/AuxiliaryData/slice';

export type ReservationQueryParameters = {
    ownerId?: number,
    reservatorId?: number,
    highlightReservationId?: number,
};

export const useQueryParameters = () => {
    const dispatch = useDispatch();

    const {
        ownerId,
        reservatorId,
        highlightReservationId,
    } = getQueryParameters<ReservationQueryParameters>({
        ownerId: 'number',
        reservatorId: 'number',
        highlightReservationId: 'number',
    });

    useEffect(() => {
        dispatch(auxiliaryActions.selectReservationOwner(ownerId));
    }, [ownerId, dispatch]);

    useEffect(() => {
        dispatch(auxiliaryActions.selectReservationReservator(reservatorId));
    }, [reservatorId, dispatch]);

    return {
        ownerId,
        reservatorId,
        highlightReservationId,
    };
};
