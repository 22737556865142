import { ReservationInvoicePassenger, ReservationPassenger } from '@malesia/json-schema';
import { ReservationOverviewInfoFlight } from '@malesia/react-components/dist/src/components/Reservation/StepReservationOverview/ReservationOverviewInfo/ReservationOverviewInfoFlights/ReservationOverviewInfoFlightsCard';
import { ReservationOverviewInfoTablePassenger } from '@malesia/react-components/dist/src/components/Reservation/StepReservationOverview/ReservationOverviewInfo/ReservationOverviewInfoTable';
import { ReservationOverviewInfoTableSeat } from '@malesia/react-components/dist/src/components/Reservation/StepReservationOverview/ReservationOverviewInfo/ReservationOverviewInfoTable/ReservationOverviewInfoTableSeats';
import { mapTariffGroupIdToCode } from '@malesia/react-components/dist/src/components/TariffsTable/tariffGroupMapper';
import { DATETIME_FORMAT } from '@malesia/react-components/dist/src/utils/dateTimeFormat';
import { createFullName } from '@malesia/react-components/dist/src/utils/fullName';
import { localizeMoney } from '@malesia/react-components/dist/src/utils/roundMoney';
import { createSelector } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';
import moment from 'moment';
import { RootState } from '../../../../types';
import { initialState } from './datatransError.slice';

const apiOptionToText = (apiOption, locale: string): string => {
    if (!apiOption) return '';
    return `${apiOption.option.configValues.title[locale]} ${apiOption.price} CHF`;
};

const selectDomain = (state: RootState) => state.datatransErrorPage || initialState;
const selectLocale = (state: RootState) => state.global.locale;

export const selectReservation = createSelector(
    [selectDomain],
    state => state.reservation,
);

type OverviewData = {
    flights: ReservationOverviewInfoFlight[],
    total: string,
    passengers: ReservationOverviewInfoTablePassenger[],
};

// ToDo: I sure thats `flights & options` fields are undefined. We have a bug?
// Fix schema for `id`, this is really exists.
type FixMeReservationPassenger = ReservationPassenger & {
    id?: number,
    flights?: ReservationInvoicePassenger['flights'],
    options?: ReservationInvoicePassenger['options'],
};
export const selectOverviewData = createSelector(
    [selectReservation, selectLocale],
    (reservation, locale): OverviewData => {

        // we have reservation.passengers and reservation.invoice.passengers
        // will merge it
        const passengersDictionary = keyBy(reservation?.passengers, 'id');
        reservation?.invoice?.passengers.forEach((passenger) => {
            passengersDictionary[passenger.reservationPassengerId] = {
                ...passengersDictionary[passenger.reservationPassengerId],
                ...passenger,
            };
        });

        const flightsOverview = reservation?.flights
            .map((flight): ReservationOverviewInfoFlight => {
                return {
                    flightNumber: flight.flight.number,
                    rateTariffType: mapTariffGroupIdToCode(flight.tariff.group.id) ?? 'economy',
                    originAirportName: flight.flight.origin.name[locale],
                    destinationAirportName: flight.flight.destination.name[locale],
                    departureDateTimes: moment.parseZone(flight.flight.departure).format(DATETIME_FORMAT),
                };
            }) ?? [];

        const passengers = Object.values(passengersDictionary)
            .map((passenger: FixMeReservationPassenger): ReservationOverviewInfoTablePassenger => {
                const seats = reservation?.flights.map(flight => {
                    const seat = reservation?.seats?.find(
                        seat => seat.flight?.id === flight.flight.id && seat.reservationPassenger!.id === passenger.id,
                    );

                    const rateTariffType = mapTariffGroupIdToCode(flight.tariff.group.id) ?? 'economy';

                    const invoicePassengers = reservation?.invoice?.passengers ?? [];
                    const invoicePassenger = invoicePassengers.find(
                        ip => ip.reservationPassengerId === passenger.id,
                    ) ?? [];
                    const invoicePassengerFlights = invoicePassenger.flights;
                    const price = invoicePassengerFlights.find(
                        ipf => ipf.flight.id === flight.flight.id,
                    )?.seatPrice ?? 0;

                    if (!seat?.seatLabel) return;

                    return {
                        rateTariffType,
                        number: seat?.seatLabel ?? '',
                        price: localizeMoney(price, locale, 'MalesiaDatatransErrorPage/store/datatransErrorPage.selectors'),
                    };
                }).filter(v => v !== undefined) ?? [];

                return {
                    name: createFullName(passenger.firstName, passenger.lastName),
                    ticketsPrice: passenger.flights?.reduce((acc, flight) => acc + flight.ticketPrice!, 0)!,
                    seats: seats as ReservationOverviewInfoTableSeat[],
                    options: passenger.options!.map((option) => apiOptionToText(option, locale)),
                };
            });

        return {
            flights: flightsOverview,
            total: reservation?.invoice?.total,
            passengers,
        };
    },
);
