import { put, select, takeLatest } from 'typed-redux-saga';
import { logError } from '../../../../utils/log';
import { reservationNotifications } from '../../../../utils/reservation/notifyReservationError';
import { selectSelectedReservationOwner } from '../../../containers/AuxiliaryData/selectors';
import { auxiliaryActions } from '../../../containers/AuxiliaryData/slice';
import { getApiUserAccount } from '../../../services/account';
import { getApiErrorMessage } from '../../../services/ApiClient';
import { reservationNewPageActions } from './slice';

function* getSelectedOwnerAccount() {
    try {
        const owner = yield* select(selectSelectedReservationOwner);
        if (!owner) {
            yield* put(reservationNewPageActions.getSelectedOwnerAccountError());
            return;
        }
        const selectedOwnerAccount = yield* getApiUserAccount(owner.id!);
        yield* put(reservationNewPageActions.getSelectedOwnerAccountSuccess(selectedOwnerAccount));
    }
    catch (error) {
        logError({
            error,
            target: 'ReservationNewPage.getSelectedOwnerAccount',
        });
        const message = getApiErrorMessage(error);
        yield* put(reservationNotifications.unknownError(message));
        yield* put(reservationNewPageActions.getSelectedOwnerAccountError());
    }
}

export function* setGetSelectedOwnerAccountSaga() {
    yield* takeLatest(
        auxiliaryActions.selectReservationOwnerSuccess,
        getSelectedOwnerAccount,
    );
}
