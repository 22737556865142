import { ContentPage } from '@malesia/react-components/dist/src/components/ContentPage/ContentPage';
import { CardList } from '@malesia/react-components/dist/src/components/Front/CardList/CardList';
import { HtmlBlock } from '@malesia/react-components/dist/src/components/Front/HtmlBlock/HtmlBlock';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import useSWR from 'swr';
import { loadCarPageContent } from '../../services/directus/carPage/loadCarPageContent';

export const MalesiaBookingCarPage: SFC = () => {
    const { locale } = useIntl();
    const { data } = useSWR([locale, 'loadCarPageContent'], loadCarPageContent);
    const styles = useRef<React.CSSProperties>({
        width: '100%',
        objectFit: 'contain',
    });
    return (
        <>
            <ContentPage
                image={data?.topImage}
            >
                <HtmlBlock content={data?.description ?? ''} />
                <CardList
                    minWidth='200px'
                    maxWidth='200px'
                >
                    {data?.cards.map(card => (
                        <a
                            href={card.link}
                            key={card.image}
                        >
                            <img
                                src={card.image}
                                alt='card'
                                loading='lazy'
                                style={styles.current}
                            />
                        </a>
                    ))}
                </CardList>
            </ContentPage>
        </>
    );
};
