import React from 'react';
import { FormattedMessage } from 'react-intl';
import { appNotification } from '../../containers/App/appNotification';
import { createLocalNotification } from '../../containers/App/utils';

const notification = createLocalNotification('FlightList', {
    error: (
        <FormattedMessage
            id='front-app/FlightList/Notification/Error:title'
            defaultMessage='Flight List Error'
        />
    ),
    warning: (
        <FormattedMessage
            id='front-app/FlightList/Notification/Warning:title'
            defaultMessage='Flight List Warning'
        />
    ),
    info: (
        <FormattedMessage
            id='front-app/FlightList/Notification/Info:title'
            defaultMessage='Flight List Info'
        />
    ),
    success: (
        <FormattedMessage
            id='front-app/FlightList/Notification/Info:title'
            defaultMessage='Flight List Info'
        />
    ),
});

export const flightListNotifications = {
    notDeletedFlights: notification.warning(
        <FormattedMessage
            id='front-app/FlightListPage:NotDeletedFlights'
            defaultMessage="The selected flight(s) couldn't be deleted because of existing reservations."
        />,
    ),
    unknownError: (details?: JSX.Element | string) => (
        notification.error(appNotification.texts.unknownError(details))
    ),
};
