/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { BackLink } from '@malesia/react-components/dist/src/components/BackLink';
import componentsTranslations from '@malesia/react-components/dist/src/translations';
import { SFC } from '@malesia/react-components/dist/src/types/react';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { createClient, Provider as UrqlProvider } from 'urql';
import appTranslations from '../../../translations';
import { AppNotifications } from '../Notifications';
import { selectLocale } from './selectors';

const messages = Object.keys(appTranslations).reduce((acc, id) => {
    return {
        ...acc,
        [id]: {
            ...componentsTranslations[id],
            ...appTranslations[id],
        },
    };
}, {});

export const gqlClient = createClient({
    url: window.config.REACT_APP_DIRECTUS_URI! + 'graphql',
});

export const AppWrapper: SFC<object, { children: 'optional' }> = (props) => {
    const locale = useSelector(selectLocale);

    // ToDo: Remove this hack
    BackLink.Link = Link as any;

    return (
        <UrqlProvider value={gqlClient}>
            <IntlProvider
                locale={locale}
                messages={messages[locale]}
            >
                <AppNotifications />
                {props.children}
            </IntlProvider>
        </UrqlProvider>
    );
};
