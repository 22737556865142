import { getTopImage } from '../getTopImage';
import {
    HealthPageDocument, HealthPageQuery,
} from '../gql-types';
import { gqlClient } from '../gqlClient';

export async function loadHealthPageContent(locale: string) {
    const { data } = await gqlClient.query<HealthPageQuery>(
        HealthPageDocument,
        { lang: locale },
    ).toPromise();
    return {
        topImage: getTopImage(data?.health_page?.top_image),
        content: data?.health_page?.translations?.[0]?.content ?? '',
    };
}
