import { FreePaymentAdvancedPostRequest } from '@malesia/json-schema';
import { PaymentTypeItem, NewTransactionAdvancedFormType } from '@malesia/react-components/dist/src/components/Accounting/NewTransaction/NewTransactionAdvanced';
import { push } from 'connected-react-router';
import { put, takeEvery, select } from 'typed-redux-saga';
import { logError } from '../../../../../utils/log';
import { cashbackPdfActions } from '../../../../containers/CashbackPdf/slice';
import { allAdminRoutePaths } from '../../../../containers/Routes/allAdminRoutePaths';
import { getApiErrorMessage } from '../../../../services/ApiClient';
import { paymentFreeService } from '../../../../services/PaymentFree/paymentFree.service';
import { newTransactionNotifications } from '../notifications';
import { selectPaymentTypeList } from '../selectors';
import { newTransactionActions } from '../slice';

export const mapFormPaymentToRequest = (
    data: Partial<NewTransactionAdvancedFormType>,
    paymentTypeList: PaymentTypeItem[],
): FreePaymentAdvancedPostRequest => ({
    debitAccountId: data.debit?.id!,
    creditAccountId: data.credit?.id!,
    amount: data.amount!,
    typeId: paymentTypeList.find(type => type.value === data.category?.value)?.id!,
    description: data.description,
});

function* submitAdvancedForm(action: ReturnType<typeof newTransactionActions.submitAdvancedForm>) {
    try {
        const data = action.payload;
        const paymentTypeList: PaymentTypeItem[] = yield* select(selectPaymentTypeList);

        const request = mapFormPaymentToRequest(data, paymentTypeList);
        const response = yield* paymentFreeService.postPaymentFreeAdvanced(request);
        yield* put(newTransactionNotifications.paymentDone);
        yield* put(push(allAdminRoutePaths.balanceLog));
        if (data.category.value === 'cashback') {
            yield* put(cashbackPdfActions.downloadCashbackPdf({
                id: response.id,
                callback: () => {},
            }));
        }
    }
    catch (error) {
        logError({
            error,
            target: 'AccountingNewTransactionPage.submitAdvancedForm',
        });
        const message = getApiErrorMessage(error);
        yield* put(newTransactionNotifications.unknownError(message));
    }
}

export function* submitAdvancedFormSaga() {
    yield* takeEvery(
        newTransactionActions.submitAdvancedForm,
        submitAdvancedForm,
    );
}
